import React, { FC, useState } from 'react';
import { IndexResponse, TFormInputs, TransferPayment } from '../types';
import { UnClosedResults } from '@/components/pages/PhotographerPayments/Transfers/IndexPage/Results/UnClosedResults/UnClosedResults';
import { ClosedResults } from '@/components/pages/PhotographerPayments/Transfers/IndexPage/Results/ClosedResults/ClosedResults';
import { TransferredResults } from '@/components/pages/PhotographerPayments/Transfers/IndexPage/Results/TransferedResults/TransferredResults';
import {
  TRANSFER_STATUS_CLOSED,
  TRANSFER_STATUS_TRANSFERRED,
  TRANSFER_STATUS_UNCLOSED,
} from '@/ts/photographerPaymentTransfers/status_constants';
import { UseFormReturn } from 'react-hook-form';

export const Results: FC<{
  response: IndexResponse;
  mutate: () => void;
  statusWatch: number;
  methods: UseFormReturn<TFormInputs>;
  setErrors: (errors: string[]) => void;
}> = ({ response, mutate, statusWatch, methods, setErrors }) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  if (!response.data.payments || response.data.payments.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }

  const isAllChecked = () => {
    return checkedIds.length === response.data.payments.length;
  };

  const changeAllCheckBox = (allChecked: boolean) => {
    allChecked
      ? setCheckedIds(response.data.payments.map((payment) => payment.id))
      : setCheckedIds([]);
  };

  const isCheckedRow = (payment: TransferPayment) => {
    return checkedIds.find((row: number) => row === payment.id) !== undefined;
  };

  const changeCheckBoxRow = (payment: TransferPayment) => {
    return (checked: boolean) =>
      checked
        ? setCheckedIds([...checkedIds, payment.id])
        : setCheckedIds(checkedIds.filter((row: number) => row !== payment.id));
  };

  return (
    <>
      {statusWatch === TRANSFER_STATUS_UNCLOSED && (
        <UnClosedResults
          response={response}
          isAllChecked={isAllChecked()}
          changeAllCheckBox={changeAllCheckBox}
          isCheckedRow={isCheckedRow}
          changeCheckBoxRow={changeCheckBoxRow}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          mutate={mutate}
          setErrors={setErrors}
        />
      )}

      {statusWatch === TRANSFER_STATUS_CLOSED && (
        <ClosedResults
          response={response}
          isAllChecked={isAllChecked()}
          changeAllCheckBox={changeAllCheckBox}
          isCheckedRow={isCheckedRow}
          changeCheckBoxRow={changeCheckBoxRow}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          methods={methods}
          mutate={mutate}
        />
      )}

      {statusWatch === TRANSFER_STATUS_TRANSFERRED && (
        <TransferredResults
          response={response}
          isAllChecked={isAllChecked()}
          changeAllCheckBox={changeAllCheckBox}
          isCheckedRow={isCheckedRow}
          changeCheckBoxRow={changeCheckBoxRow}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          mutate={mutate}
        />
      )}
    </>
  );
};
