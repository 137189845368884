import { TTarget } from './types';

const STATUS_DRAFT = 1;
const STATUS_VERIFIED = 2;
const STATUS_UNAPPROVED = 3;
const STATUS_APPROVED = 4;
const STATUS_CLAIMED = 5;
const STATUS_PAID = 6;

const statuses: Record<number, string> = {
  [STATUS_DRAFT]: 'draft',
  [STATUS_VERIFIED]: 'verified',
  [STATUS_UNAPPROVED]: 'unapproved',
  [STATUS_APPROVED]: 'approved',
  [STATUS_CLAIMED]: 'claimed',
  [STATUS_PAID]: 'paid',
};

const PHOTO_TYPE_SNAP = 1;

// status 判定
const canApprove = (status: number): boolean => status === STATUS_VERIFIED;

const canPay = (status: number, target: TTarget): boolean => {
  if (target === 'PARTNER') {
    return status === STATUS_CLAIMED || status === STATUS_PAID;
  }
  return (
    status === STATUS_CLAIMED ||
    status === STATUS_PAID ||
    status === STATUS_UNAPPROVED
  );
};

// 処理用の判定
/**
 * 承認申請可否
 * @param status
 * @param isPdfSaved number | null HTMLの場合null
 * @returns boolean
 */
const canRequestApprove = (
  status: number,
  isPdfSaved: number | null
): boolean =>
  canApprove(status) && (isPdfSaved === null || isPdfSaved === Number(true));
const canSetPaidAt = (
  status: number,
  bankAccountHasMistake: number | null,
  target: TTarget
): boolean => {
  if (target === 'PARTNER') {
    return (
      canPay(status, target) &&
      bankAccountHasMistake !== null &&
      !bankAccountHasMistake
    );
  }
  return (
    canPay(status, target) &&
    (bankAccountHasMistake === null || !bankAccountHasMistake)
  );
};

export {
  STATUS_DRAFT,
  STATUS_VERIFIED,
  STATUS_UNAPPROVED,
  STATUS_APPROVED,
  STATUS_CLAIMED,
  STATUS_PAID,
  statuses,
  PHOTO_TYPE_SNAP,
  canApprove,
  canPay,
  canRequestApprove,
  canSetPaidAt,
};
