import { fetcher } from '@/ts/fetch';
import { toMessages } from '@/ts/useApi';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { MailLog } from './types';

export type SearchParams = {
  email: string;
};

type UseMailLogSearchReturn = {
  loading: boolean;
  errorMessages?: string[];
  logs?: MailLog[];
  searchParams?: SearchParams;
  handleSubmit: SubmitHandler<SearchParams>;
};

export const useMailLogSearch = (): UseMailLogSearchReturn => {
  const history = useHistory();
  const { search: queryString } = useLocation();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [logs, setLogs] = useState<MailLog[]>();

  const handleSubmit = useCallback<SubmitHandler<SearchParams>>(
    (values: SearchParams) => {
      setLogs(undefined);
      const queryString = values.email ? `email=${values.email}` : null;

      history.push(`/mail_log?${queryString}`);
    },
    [history]
  );

  const [{ loading }, handleSearch] = useAsyncFn(async () => {
    if (!searchParams || !searchParams.email) {
      return;
    }

    try {
      const response = await fetcher<MailLog[]>(
        `/api/mail_log/search?email=${searchParams.email}`
      );
      setLogs(response);
      setErrorMessages(undefined);
    } catch (e: unknown) {
      setErrorMessages(toMessages(e));
    }
  }, [searchParams]);

  useAsync(async () => {
    if (!searchParams || !searchParams.email) {
      return;
    }

    await handleSearch();
  }, [handleSearch, searchParams]);

  useEffect(() => {
    const params = new URLSearchParams(queryString);
    const email = params.get('email');

    setSearchParams({
      email: email || '',
    });
  }, [queryString]);

  return {
    loading,
    errorMessages,
    logs,
    searchParams,
    handleSubmit,
  };
};
