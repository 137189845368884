import React, { useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { STATUS_DRAFT, STATUS_UNAPPROVED, STATUS_VERIFIED } from '../constants';

const SubmitButtons: React.FC = React.memo(() => {
  const { setValue } = useFormContext();
  const submitButton = useRef<HTMLButtonElement>(null);
  const setStatus = useCallback(
    (status: number) => {
      setValue('status', status);
      submitButton.current?.click();
    },
    [setValue]
  );

  // NOTE: react-hook-formは同名複数のsubmitに対応してなかったのでsetValueで処理する
  return (
    <div className="u-align_center">
      <button type="submit" className="is-hidden" ref={submitButton} />
      <button
        className="c-btn_large c-btn_primary c-btn_primary__isArrow c-incentivePayment_actionButton"
        type="button"
        onClick={() => setStatus(STATUS_DRAFT)}
      >
        <span>下書き保存</span>
      </button>
      <button
        className="c-btn_large c-btn_manage c-btn_manage__isArrow c-incentivePayment_actionButton"
        type="button"
        onClick={() => setStatus(STATUS_VERIFIED)}
      >
        <span>確認済み</span>
      </button>
      <button
        className="c-btn_large c-btn_manage c-btn_manage__isArrow c-incentivePayment_actionButton"
        type="button"
        onClick={() => setStatus(STATUS_UNAPPROVED)}
      >
        <span>承認依頼を出す</span>
      </button>
    </div>
  );
});

export default SubmitButtons;
