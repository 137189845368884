import React, { useState, ChangeEventHandler } from 'react';
import dayjs from 'dayjs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TextInput, TextArea } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import { errorToast, successToast } from '@/ts/toast';
import { fetcher, toMessages } from '@/ts/useApi';
import Download from '../Download';
import { TContainer, TFormInputs, TMail } from '../types';

const SendApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSendFormProps = {
  eventno: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  data: TContainer;
};

const SendForm: React.FC<TSendFormProps> = React.memo(
  ({ eventno, validator, data }) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isSalesMail, setIsSalesMail] = useState(!!data.salesMail);
    const [photographerMails, setPhotographerMails] = useState(
      data.photographerMails
    );
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = (ev) => {
      const newMails: TMail = {};
      for (const mail of Object.keys(photographerMails)) {
        newMails[mail] = photographerMails[mail];
        if (ev.target.dataset.mail === mail) {
          newMails[mail] = ev.target.checked;
        }
      }
      setPhotographerMails(newMails);
    };
    const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
      if (!isSalesMail) {
        formData.salesMail = null;
      }
      formData.photographerMails = photographerMails;
      if (window.confirm('メールを送付します。よろしいですか？')) {
        try {
          await fetcher(`/api/events/${eventno}/samplebook/mail/send`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          successToast('送信しました');
          history.push(`/events/${eventno}`);
        } catch (e) {
          setErrorMessages(toMessages(e));
          errorToast('エラーが発生しました');
        }
      }
    };
    const dateFormat = (date: string, format: string) =>
      dayjs(date).format(format);

    const preConfirmation = data.isPreConfirmation
      ? [
          `${dateFormat(data.photographingday, 'M月D日')}に撮影いたしました`,
          `下記イベントの事前確認が開始いたしましたので`,
          `本メールにて事前確認用サンプルブックのデータをお送りいたします。`,
          ``,
          `イベント名：「${data.eventname}」`,
        ].join('\n')
      : [
          `${dateFormat(data.photographingday, 'M月D日')}に撮影いたしました`,
          `下記イベントのサンプルブックのデータがご準備できました。`,
          `責任者様の画面にログイン後、閲覧・ダウンロードをお願いいたします。`,
          ``,
          `イベント名：「${data.eventname}」`,
          ``,
          `責任者様ログインURL：https://8122.jp/?action_user_FastViewer=t&eventno=${eventno}`,
        ].join('\n');

    const mailContent = [
      data.societyName,
      `ご担当者様`,
      ``,
      `いつもお世話になっております。`,
      `サポートグループでございます。`,
      ``,
      preConfirmation,
      ``,
      `今後とも「はいチーズ！フォト」をよろしくお願いいたします。`,
    ].join('\n');

    const defaultValues = {
      subject: `【はいチーズ!フォト】${dayjs(data.photographingday).format(
        'M/D'
      )}撮影「${data.eventname}」サンプルブックについて`,
      leaderMail: data.leaderMail,
      salesMail: data.salesMail,
      photographerMails: data.photographerMails,
      mailContent: mailContent,
    };
    const methods = useForm<TFormInputs>({ defaultValues });

    return (
      <FormProvider {...methods}>
        <SendApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>ファイル</label>
            </li>
            <li className="c-dataValue">
              <Download s3TmpUrl={data.s3TmpUrl} />
              <span style={{ fontSize: '1.2rem' }}>
                ※ダウンロード出来ない場合、画面の再読込みで再度ダウンロード可能となります
              </span>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>宛先</label>
            </li>
            <li className="c-dataValue">
              <div className="l-flex nowrap u-mgb_xs">
                {data.leaderMail ? (
                  <span
                    className="t-bgBox_org u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <${data.leaderMail}>`}</span>
                ) : (
                  <span
                    className="t-bgBox_gray u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <none>`}</span>
                )}
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>CC</label>
            </li>
            <li className="c-dataValue">
              <div className="nowrap u-mgb_xs">
                <label className="c-checkboxLabel">
                  <input
                    name={`salesMail`}
                    className={`c-checkbox`}
                    type="checkbox"
                    defaultChecked={!!data.salesMail}
                    disabled={!data.salesMail}
                    onChange={() => {
                      setIsSalesMail((c) => !c);
                    }}
                  />
                  <span className="c-label_checkbox small">
                    {`担当営業 <${data.salesMail ?? '設定なし'}>`}
                  </span>
                </label>
              </div>
              {Object.keys(photographerMails).length <= 0 ? (
                <div className="l-flex nowrap u-mgb_xs">
                  <label className="c-checkboxLabel">
                    <span className="c-label_checkbox small">
                      担当カメラマン &lt;設定なし&gt;
                    </span>
                  </label>
                </div>
              ) : (
                Object.keys(photographerMails).map((mail, idx) => (
                  <div key={idx} className="nowrap u-mgb_xs">
                    <label className="c-checkboxLabel">
                      <input
                        type="checkbox"
                        name={`photographerMails[]`}
                        className={`c-checkbox`}
                        defaultChecked={true}
                        onChange={onChangeCheck}
                        data-mail={mail}
                      />
                      <span className="c-label_checkbox small">
                        {`担当カメラマン <${mail}>`}
                      </span>
                    </label>
                  </div>
                ))
              )}
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>イベント</label>
            </li>
            <li className="c-dataValue">
              <div className="l-flex nowrap u-mgb_xs">
                <Link to={`/events/${eventno}`} target="_blank">
                  {eventno}
                </Link>
                <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
                <span>{data.eventname}</span>
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>件名</label>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="subject"
                validator={validator}
                additionalClassName="p-flyer_titleInput"
              />
            </li>
          </ul>

          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">本文</li>
            <li className="c-dataValue">
              <TextArea
                name="mailContent"
                validator={validator}
                rows={20}
                cols={10}
              />
            </li>
          </ul>
          <p className="u-align_center l-flex_center_line">
            <Link
              className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-linkLabel"
              to={`/events/${eventno}`}
            >
              キャンセル
            </Link>
            {data.leaderMail ? (
              <input
                className="c-btn_large c-btn_edit is-arrow c-input_submit"
                type="submit"
                value={'送信する'}
              />
            ) : (
              <span className="u-fz_xs u-mgl_m c-alert_text">
                責任者が設定されていません
              </span>
            )}
            {data.dateSentMail && (
              <span className="u-fz_xs u-mgl_m">
                送信日時：
                {dayjs(data.dateSentMail).format('YYYY-MM-DD HH:mm')}
              </span>
            )}
          </p>
        </form>
      </FormProvider>
    );
  }
);

export default SendForm;
