import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { TPagination } from '@/components/shared/Paginator/types';
import {
  TValidationRule,
  TValidator,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import Paginator from '@/components/shared/Paginator/App';
import { renderError, fetcher } from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import SearchForm from './SearchForm';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { TAllSelectableCheck, TAllSelectableChecks } from '../../../../types';
import {
  TEvent,
  TEventPhotographersCheck,
  TEventPhotographersResponse,
  TFormInputs,
  TFormItems,
  TPhotographer,
  TRequestPhotographer,
} from './types';
import SearchResultItem, {
  PhotographerHeaderItems,
  PhotographerItems,
} from './SearchResultItem';
import Accordion from '../../../shared/Accordion/App';
import { TPhotographerShort } from '../types';
import Event from './Event';
import {
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { numberOfArranged } from '../requestPhotographer';
import {
  isCheckedAllChecks,
  toggleCheck,
  toggleCheckAll,
} from '../../../../ts/toggleAllSelectableCheckboxes';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const Photographers: React.FC<{
  photographers: TPhotographer[];
  allSelectableChecks: TAllSelectableChecks;
  setAllSelectableChecks: React.Dispatch<
    React.SetStateAction<TAllSelectableChecks>
  >;
  societyRequestPhotographers: TPhotographerShort[];
}> = React.memo(
  ({
    photographers,
    allSelectableChecks,
    setAllSelectableChecks,
    societyRequestPhotographers,
  }) => {
    const onChangeCheckAll: ChangeEventHandler = useCallback(() => {
      toggleCheckAll(setAllSelectableChecks);
    }, [setAllSelectableChecks]);
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        toggleCheck(e.target, setAllSelectableChecks);
      },
      [setAllSelectableChecks]
    );
    return (
      <table className="c-indexList u-fz_s">
        <thead>
          <tr>
            <th className="u-align_center">
              <label className="c-checkbox_listbox">
                <input
                  type="checkbox"
                  className="c-checkbox_list"
                  name="check-all"
                  value="all"
                  checked={allSelectableChecks.checkedAll}
                  onChange={onChangeCheckAll}
                />
                {/* NOTE: レイアウトの都合で必要な空のelement */}
                <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
              </label>
            </th>
            <PhotographerHeaderItems />
          </tr>
        </thead>
        <tbody>
          {photographers.map((p) => (
            <SearchResultItem
              key={p.id}
              photographer={p}
              onChangeCheck={onChangeCheck}
              check={allSelectableChecks.checks.find((c) => c.id === p.id)}
              societyRequestPhotographers={societyRequestPhotographers}
            />
          ))}
        </tbody>
      </table>
    );
  }
);

const List: React.FC<{
  event: TEvent;
  photographers: TPhotographer[];
  queryString: string;
  pagination: TPagination | null;
  validator: TValidator;
  loadPhotographers: (path: string) => void;
  setEventPhotographersChecks: React.Dispatch<
    React.SetStateAction<TEventPhotographersCheck[]>
  >;
}> = React.memo(
  ({
    event,
    photographers,
    queryString,
    pagination,
    validator,
    loadPhotographers,
    setEventPhotographersChecks,
  }) => {
    const [allSelectableChecks, setAllSelectableChecks] =
      useState<TAllSelectableChecks>(() => {
        const checks = photographers.map((p) => ({
          id: p.id,
          // 親でチェック済みと保持している値を入れる
          checked: (event.selectedPhotographerIds ?? []).some(
            (pId) => pId === p.id
          ),
          disabled: false,
        }));
        return {
          checkedAll: isCheckedAllChecks(checks),
          checks,
        };
      });
    const path = `/api/arrange_photographer/events/${event.id}/photographers`;

    useEffect(() => {
      // checkboxの初期化処理
      setAllSelectableChecks((currentValue) => {
        const newChecks: TAllSelectableCheck[] = photographers.map((p) => ({
          id: p.id,
          checked: currentValue.checks.some((c) => c.checked && c.id === p.id),
          disabled: false,
        }));
        // チェック済み && 取ってきたカメラマン一覧にない値は消さずにチェックボックス非表示で保持する
        const checkedChecks: TAllSelectableCheck[] = currentValue.checks
          .filter((c) => c.checked && !photographers.some((p) => p.id === c.id))
          .map((c) => ({
            ...c,
            hide: true,
          }));
        const checks = checkedChecks.concat(newChecks);
        return {
          checkedAll: isCheckedAllChecks(checks),
          checks,
        };
      });
    }, [photographers, event.selectedPhotographerIds]);

    useEffect(() => {
      // 親にカメラマンの選択状態を渡す
      setEventPhotographersChecks((eventPhotographersChecks) =>
        eventPhotographersChecks.map((epc) =>
          epc.eventId === event.id
            ? {
                eventId: epc.eventId,
                photographerChecks: allSelectableChecks.checks.map((c) => ({
                  // NOTE: 一覧にないが選択されているカメラマンの情報は元の値から取得
                  ...(epc.photographerChecks.find((pc) => pc.id === c.id) ??
                    {}),
                  ...c,
                  ...photographers.find((p) => p.id === c.id)!,
                  isDoingArrange: 1,
                })),
              }
            : epc
        )
      );
    }, [
      setEventPhotographersChecks,
      event.id,
      allSelectableChecks.checks,
      photographers,
    ]);

    return (
      <>
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <div className="c-pagination_upper">
                <Paginator
                  pagination={pagination}
                  currentPath={path}
                  queryString={queryString}
                  onChangePath={loadPhotographers}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !photographers.length ? (
            <HasNoResult />
          ) : (
            <Photographers
              photographers={photographers}
              allSelectableChecks={allSelectableChecks}
              setAllSelectableChecks={setAllSelectableChecks}
              societyRequestPhotographers={event.societyRequestPhotographers}
            />
          )}
          <Paginator
            pagination={pagination}
            currentPath={path}
            queryString={queryString}
            onChangePath={loadPhotographers}
          />
        </div>
      </>
    );
  }
);

const ArrangedPhotographers: React.FC<{
  photographers: TRequestPhotographer[];
  societyRequestPhotographers: TPhotographerShort[];
}> = React.memo(({ photographers, societyRequestPhotographers }) =>
  photographers.length ? (
    <table className="c-indexList u-mgt_s u-fz_s">
      <thead>
        <tr>
          <th>状態</th>
          <PhotographerHeaderItems />
        </tr>
      </thead>
      <tbody>
        {photographers.map((p) => (
          <tr key={p.id}>
            {/* NOTE: 慣らし運転の間名前を旧に合わせておく */}
            <td className="c-indexList_column_s">
              {p.isTrainee
                ? '研修'
                : p.isTemporary
                ? '仮'
                : p.requestStatusName}
            </td>
            <PhotographerItems
              photographer={p}
              societyRequestPhotographers={societyRequestPhotographers}
            />
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>(カメラマンがアサインされていません。)</p>
  )
);

const isSelectedPhotographersChanged = (event: TEvent) => {
  const orgSelectedPhotographerIds = event.recommendedPhotographers.map(
    (rp) => rp.id
  );
  const stateSelectedPhotographerIds = event.selectedPhotographerIds ?? [];
  return (
    JSON.stringify(stateSelectedPhotographerIds) !==
    JSON.stringify(orgSelectedPhotographerIds)
  );
};

const PhotographerList: React.FC<{
  event: TEvent;
  formItems: TFormItems;
  rules: Record<string, TValidationRule | never>;
  setEventPhotographersChecks: React.Dispatch<
    React.SetStateAction<TEventPhotographersCheck[]>
  >;
}> = React.memo(({ event, formItems, rules, setEventPhotographersChecks }) => {
  const apiPath = `/api/arrange_photographer/events/${event.id}/photographers`;
  // NOTE: カメラマン選択変更済み）抽出条件なし（※システムではどのカメラマンを選んだかわからないため）
  // NOTE: カメラマンの選択変更なし）相カメのみ＆自動アサイン可能
  const defaultQueryString = isSelectedPhotographersChanged(event)
    ? ''
    : '?types[]=0&isOnlyAutoArrangementTarget=1';

  const { data, error, mutate } =
    useJsonApiForSearchForm<TEventPhotographersResponse>(
      apiPath,
      defaultQueryString
    );
  const [validator, setValidator] = useState<TValidatorResponse>(
    data?.validator ?? {
      rules,
      messages: {},
      hasError: false,
    }
  );

  // NOTE: component単位でreloadするのでstateにしておく
  const [queryString, setQueryString] = useState(defaultQueryString);
  const queryParams = convertQueryToFormData(queryString, validator);

  const loadPhotographers = useCallback(
    async (path: string) => {
      try {
        const {
          validator,
          data: photographers,
          pagination,
        } = await fetcher<TEventPhotographersResponse>(path);
        if (validator.hasError) {
          errorToastWithValidatorMessages(
            'データの取得に失敗しました',
            validator.messages
          );
          setValidator(validator);
          return;
        }
        mutate((response) =>
          response
            ? {
                ...response,
                data: photographers,
                pagination,
              }
            : undefined
        );
        setQueryString(path.replace(apiPath, ''));
      } catch (error) {
        errorToast('エラーが発生しました');
      }
    },
    [mutate, apiPath]
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(apiPath, queryString, formData);
      loadPhotographers(newPath);
    },
    [queryString, loadPhotographers, apiPath]
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const { data: photographers, pagination } = data;

  return (
    <div className="c-index p-eventPhotographerList">
      <div className="l-center_wrap">
        <Event event={event} />
        <div className="c-frame">
          <h5 className="c-section_title u-fz_m">
            アサイン済みカメラマン(
            {numberOfArranged(event.arrangedPhotographers)} /{' '}
            {event.numberOfRequiredPhotographers})
          </h5>
          <Accordion>
            <ArrangedPhotographers
              photographers={event.arrangedPhotographers}
              societyRequestPhotographers={event.societyRequestPhotographers}
            />
          </Accordion>
        </div>
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <List
          event={event}
          photographers={photographers}
          queryString={queryString}
          validator={validator}
          pagination={pagination}
          loadPhotographers={loadPhotographers}
          setEventPhotographersChecks={setEventPhotographersChecks}
        />
      </div>
    </div>
  );
});

export default PhotographerList;
