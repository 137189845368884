import React from 'react';
import { Link } from 'react-router-dom';
import { Society, Contract } from './types';

// 契約状態：契約中
const STATUS_UNDER_CONTRACT = 2;

const SocietiesList: React.FC<{ societies: Society[] }> = (props) => (
  <ul className="c-indexList p-groupsList">
    {props.societies.map((society: Society) => (
      <li className="c-indexListItem" key={society.societyId}>
        <div className="l-flex is-sp_input">
          <div className="u-mgr_s">
            <div className="c-idLink_hasBg p-groups_id">
              <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
              {society.societyId}
            </div>
          </div>
          <div className="p-groupsList_detailBox">
            <div className="l-flex_between p-groupsList_upper">
              <div className="p-groupsList_names">
                <Link
                  className="c-textlink"
                  to={`/societies/${society.societyId}`}
                >
                  <h4 className="c-textOmit">{society.societyName}</h4>
                </Link>
              </div>
              <Link
                className="c-btn_rectangle c-btn_edit"
                to={`/societies/${society.societyId}/edit`}
              >
                <i className="c-icon c-icon__xsmall c-icon_edit" />
              </Link>
            </div>
            <ul className="l-flex p-groupsList_info">
              <li className="p-groupsList_info_address">
                <i className="c-icon_map u-mgr_xs"></i>
                <span>
                  {society.prefectureName}
                  {society.city}
                  {society.address}
                </span>
              </li>
              <li className="p-groupsList_info_other">
                <i className="c-icon_tel u-mgr_xs"></i>
                <span>{society.telephone}</span>
              </li>
              <li className="p-groupsList_info_other">
                <i className="c-icon_printOffice u-mgr_xs"></i>
                <span>{society.fax}</span>
              </li>
              <li>
                <i className="c-icon_cameraman u-mgr_xs"></i>
                <span>{society.photographerName}</span>
              </li>
            </ul>
            <div className="p-groupsList_contract">
              {society.contracts.map((contract: Contract, index: number) => (
                <React.Fragment key={index}>
                  <ul
                    className={`l-flex p-groupsList_contractStatus ${
                      contract.contractStatus > STATUS_UNDER_CONTRACT
                        ? 'p-groupsList_contractCancell'
                        : ''
                    }`}
                  >
                    <li>
                      <div
                        className={`u-fz_s c-textOmit t-textColor_sub ${
                          contract.contractStatus > STATUS_UNDER_CONTRACT
                            ? 'p-groupsList_contractCancell'
                            : ''
                        }`}
                      >
                        NO.{index + 1}
                      </div>
                    </li>
                    <li className="p-groupsLabel_operation">
                      <span
                        className={`c-statusLabel c-statusLabel__${
                          contract.contractStatus === STATUS_UNDER_CONTRACT
                            ? 'positive'
                            : 'negative'
                        }${
                          contract.contractStatus > STATUS_UNDER_CONTRACT
                            ? ' p-groupsList_contractCancell'
                            : ''
                        }`}
                      >
                        {contract.contractStatusName}
                      </span>
                    </li>
                    <li>契約種類：{contract.contractTypeName}</li>
                    <li className="p-groupsList_info_other">
                      <i className="c-icon_person u-mgr_xs"></i>
                      <span>{contract.salesDepartmentStaffName}</span>
                    </li>
                  </ul>
                  <div
                    className={`l-flex_end is-sp_input ${
                      contract.contractStatus > STATUS_UNDER_CONTRACT
                        ? 'p-groupsList_contractCancell'
                        : ''
                    }`}
                  >
                    <div className="p-groupsList_contractInfo l-flex">
                      <dl className="l-flex">
                        <dt
                          className={`p-groupsList_contractInfoCaption ${
                            contract.contractStatus > STATUS_UNDER_CONTRACT
                              ? 'p-groupsList_contractCancell'
                              : ''
                          }`}
                        >
                          登録日
                        </dt>
                        <dd>
                          <span className="p-groupsList_contractInfoValue has-separate">
                            {contract.concludeDate}
                          </span>
                        </dd>
                      </dl>
                      <dl className="l-flex">
                        <dt
                          className={`p-groupsList_contractInfoCaption ${
                            contract.contractStatus > STATUS_UNDER_CONTRACT
                              ? 'p-groupsList_contractCancell'
                              : ''
                          }`}
                        >
                          認証タイプ
                        </dt>
                        <dd>
                          <span className="p-groupsList_contractInfoValue has-separate">
                            {contract.authTypeName}
                          </span>
                        </dd>
                      </dl>
                      <dl className="l-flex">
                        <dt
                          className={`p-groupsList_contractInfoCaption ${
                            contract.contractStatus > STATUS_UNDER_CONTRACT
                              ? 'p-groupsList_contractCancell'
                              : ''
                          }`}
                        >
                          事前確認
                        </dt>
                        <dd>
                          <span className="p-groupsList_contractInfoValue">
                            {contract.confirmationCheckName}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

export default SocietiesList;
