import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Paginator from '@/components/shared/Paginator/App';
import { TPagination } from '@/components/shared/Paginator/types';

import { fetcher } from '../../../../ts/useApi';
import {
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TUseraccount } from '../../types';
import { TUseraccountsResponse, TCertificationkeyUseraccount } from '../types';
import Useraccount from '../Useraccount';
import { mergeUseraccounts } from '../mergeUseraccounts';
import DownloadUseraccountCsv from './DownloadUseraccountsCsv';

const UseraccountsToShow: React.FC<{
  id: number;
  keyString: string;
  numberOfUsers: number;
  certificationkeyUseraccounts: TCertificationkeyUseraccount[];
  useraccounts: TUseraccount[];
  pagination: TPagination;
  isRequiredAcceptanceByLeader: boolean;
}> = React.memo(
  ({
    id,
    keyString,
    numberOfUsers,
    certificationkeyUseraccounts: orgCertificationkeyUseraccounts,
    useraccounts: orgUseraccount,
    pagination: orgPagination,
    isRequiredAcceptanceByLeader,
  }) => {
    const [certificationkeyUseraccounts, setCertificationkeyUseraccounts] =
      useState<TCertificationkeyUseraccount[]>(orgCertificationkeyUseraccounts);
    const [useraccounts, setUseraccounts] =
      useState<TUseraccount[]>(orgUseraccount);
    const [pagination, setPagination] = useState<TPagination>(orgPagination);

    // list refetch
    const paginatorOnChange = async (path: string) => {
      try {
        const response = await fetcher<TUseraccountsResponse>(path);
        if (response.validator.hasError) {
          errorToastWithValidatorMessages(
            'データの取得に失敗しました',
            response.validator.messages
          );
        } else {
          setCertificationkeyUseraccounts(
            response.data.certificationkeyUseraccounts
          );
          setUseraccounts(response.data.useraccounts);
          setPagination(response.pagination);
        }
      } catch (error) {
        errorToast('エラーが発生しました');
      }
    };
    return (
      <div className="c-frame">
        <div className="l-flex_between_center u-mgb_s">
          <h2 className="c-section_title">
            紐付け済み会員一覧
            <small className="u-mgl_xs">
              (アクティブ登録者数 {numberOfUsers}人)
            </small>
          </h2>
          {/* NOTE: レイアウト用のdiv */}
          <div>
            <DownloadUseraccountCsv
              id={id}
              keyString={keyString}
              canDownload={!!useraccounts.length}
              isRequiredAcceptanceByLeader={isRequiredAcceptanceByLeader}
            />
            <Link
              to={`/certificationkeys/${id}/useraccounts`}
              className="c-btn_rectangle c-btn_edit"
            >
              <span>詳細表示</span>
            </Link>
          </div>
        </div>
        {useraccounts.length ? (
          <>
            <table className="c-indexList">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ユーザ名</th>
                  <th>メールアドレス</th>
                  <th>電話番号</th>
                  <th>登録状況</th>
                  <th>責任者承認状況</th>
                  <th>責任者承認氏名</th>
                  <th>生徒名</th>
                  <th>生徒との関係</th>
                  <th>クラス名</th>
                  <th>キー登録日時</th>
                </tr>
              </thead>
              <tbody>
                {mergeUseraccounts(
                  useraccounts,
                  certificationkeyUseraccounts,
                  isRequiredAcceptanceByLeader
                ).map((u) => (
                  <tr key={u.id}>
                    <Useraccount mergedUseraccount={u} />
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginator
              pagination={pagination}
              currentPath={`/api/certificationkeys/${id}/useraccounts`}
              queryString=""
              onChangePath={paginatorOnChange}
            />
          </>
        ) : (
          <>
            <hr className="u-line_plane" />
            <div className="c-emptyState_box">会員が登録されていません</div>
          </>
        )}
      </div>
    );
  }
);

const Useraccounts: React.FC<{
  id: number;
  keyString: string;
  numberOfUsers: number;
  certificationkeyUseraccounts: TCertificationkeyUseraccount[];
  useraccounts: TUseraccount[];
  pagination: TPagination;
  canShow: boolean;
  isRequiredAcceptanceByLeader: boolean;
}> = React.memo(({ canShow, ...props }) => {
  if (!canShow) {
    return (
      <div className="c-frame">
        <div className="l-flex_between_center">
          <h2 className="u-mgb_s c-section_title">紐付け済み会員一覧</h2>
        </div>
        <hr className="u-line_plane" />
        <div className="c-emptyState_box">権限がないため表示できません</div>
      </div>
    );
  }

  return <UseraccountsToShow {...props} />;
});

export default Useraccounts;
