import React from 'react';
import StatusLabel from '../../../shared/IncentivePayments/StatusLabel';
import dayjs from 'dayjs';
import ChangeType from '../../../shared/IncentivePayments/Show/ChangeType';

const Header: React.FC<{
  type: 'HTML' | 'PDF';
  canEdit: boolean;
  incentivePaymentId: number;
  societyName: string;
  planName: string;
  isBulkDelivery: number;
  status: number;
  statusName: string;
  issuedOn: string;
}> = React.memo(
  ({
    type,
    canEdit,
    incentivePaymentId,
    societyName,
    planName,
    isBulkDelivery,
    status,
    statusName,
    issuedOn,
  }) => (
    <>
      <div className="l-flex_between_center">
        <div className="u-mgb_s">
          <h4 className="c-section_title u-fz_l u-mgt_s">
            {societyName}様（{planName}プラン）
            {!!isBulkDelivery && (
              <span className="c-label c-incentivePayment_label__bulkDelivery">
                一括配送対象
              </span>
            )}
          </h4>
          <div>
            <StatusLabel status={status} statusName={statusName} />
            <span>
              <strong>{dayjs(issuedOn).format('YYYY年M月度')}</strong>
            </span>
          </div>
        </div>
        <div className="l-flex_end">
          <div className="u-mgr_m">
            {canEdit && (
              <ChangeType
                target="TEACHER"
                typeToChange={type === 'HTML' ? 'PDF' : 'HTML'}
                incentivePaymentId={incentivePaymentId}
              />
            )}
          </div>
          <div className="t-bgBox_gray u-fz_s u-mgr_s">
            <i className="c-icon_blank u-mgr_xs u-fz_xs" />
            {/* TODO: Link to 売れ行き集計 */}
            <a
              href="/"
              className="c-textlink is-disabled"
              // href="{{ OwnerHelper::kanriUrl($incentivePayment->salesSearchParamsForOwner()) }}"
              target="_blank"
            >
              売れ行き集計を表示
            </a>
          </div>
        </div>
      </div>
      <hr className="u-line_plane" />
    </>
  )
);

export default Header;
