import React from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { TConstants } from '@/components/pages/Events/Show/Photography/types';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';

export const Default: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  return (
    <>
      <EnvironmentType
        validator={validator}
        photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
      />

      <hr className="u-line_plane" />
      <NapPhoto validator={validator} />
    </>
  );
};
