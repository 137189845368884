import React, { useCallback } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { TextInput, TextArea } from '@/components/shared/Form/Inputs';
import {
  TValidatorResponse,
  TValidationRule,
} from '@/components/shared/Form/types';
import { postJson } from '../../../../ts/useApi';
import { TMailForm } from './types';
import { asyncProcessStartToast } from '../../../../ts/toast';

const MailForm: React.FC<{
  id: number;
  defaultFrom: string;
  userMailaddress: string;
  validationRules: Record<string, TValidationRule | never>;
}> = React.memo(({ id, defaultFrom, userMailaddress, validationRules }) => {
  const methods = useForm<TMailForm>();
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<TMailForm> = useCallback(
    (formData) => {
      if (
        !window.confirm(
          '写真閲覧キーに紐づくユーザーへメールを一括送信します。よろしいですか？'
        )
      ) {
        return;
      }
      postJson(`/api/certificationkeys/${id}/send_mail`, formData);
      // NOTE: 完了に時間がかかる場合があるため、完了を待たず終了
      asyncProcessStartToast('メール一括送信');
    },
    [id]
  );

  methods.setValue('from', defaultFrom);

  const validator: TValidatorResponse = {
    messages: {},
    hasError: false,
    rules: validationRules,
  };

  return (
    <div className="c-frame">
      <h2 className="u-mgb_m c-section_title">
        紐付け済み会員にメールを一括送信
      </h2>
      <hr className="u-line_plane" />
      <FormProvider {...methods}>
        <form
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <ul className="l-flex u-mgb_s">
            <li className="c-dataLabel c-label_line">
              <label>件名</label>
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="subject"
                placeholder="件名"
                validator={validator}
              />
            </li>
          </ul>
          <ul className="l-flex u-mgb_s">
            <li className="c-dataLabel c-label_line">
              <label>送信者メールアドレス</label>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="from"
                placeholder="送信者メールアドレス"
                validator={validator}
                additionalClassName="c-input_txtarea is-disabled c-input_plane__small"
              />
            </li>
          </ul>
          <ul className="l-flex u-mgb_s">
            <li className="c-dataLabel c-label_line">
              <label>本文</label>
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <TextArea name="body" placeholder="本文" validator={validator} />
            </li>
          </ul>
          <div className="t-bgBox_gray">
            ・お知らせメールを受信しない設定にしている方には送られません。
            <br />
            ・送信者メールアドレスは、 {defaultFrom} が使用されます。
            <br />
            ・控えとして、 {defaultFrom} と {userMailaddress}{' '}
            にも同じ内容が送信されます。
          </div>
          <div className="u-align_center u-mgt_s u-mgb_xs">
            <button
              type="submit"
              className="c-btn_large c-btn_primary c-input_submit"
            >
              送信
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default MailForm;
