import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import styles from './Summary.module.scss';
import { useJsonApi } from '@/ts/useJsonApi';
import {
  SummaryPaymentItem,
  SummaryResponse,
  TOrganizationBankAccount,
} from '@/components/pages/PhotographerPayments/Transfers/Summary/types';
import { useParams } from 'react-router-dom';
import { renderError } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { dateFormatFromISO8601, numberFormat } from '@/ts/formatTools';
import { TRANSFER_STATUS_TRANSFERRED } from '@/ts/photographerPaymentTransfers/status_constants';
import clsx from 'clsx';

const INDIVIDUAL_ORGANIZATION_TYPE = 1;

const TransferDetail: React.FC<{ paymentItems: SummaryPaymentItem[] }> = ({
  paymentItems,
}) => {
  return (
    <div className={`${styles.colColorTable} ${styles.transferDetailTable}`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>日付</TableCell>
            <TableCell rowSpan={2}>項目</TableCell>
            <TableCell colSpan={4}>取引内容</TableCell>
            <TableCell rowSpan={2}>税率</TableCell>
            <TableCell rowSpan={2}>
              金額
              <br />
              (税込)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>カメラマン</TableCell>
            <TableCell>団体名</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>備考</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentItems.map((item) => (
            <TableRow>
              <TableCell>{dateFormatFromISO8601(item.tradingDate)}</TableCell>
              <TableCell>{item.categoryName}</TableCell>
              <TableCell>{item.photographerName}</TableCell>
              <TableCell>{item.societyName}</TableCell>
              <TableCell>{item.eventName}</TableCell>
              <TableCell>
                <span className="u-pre_wrap">
                  {item.externalMemo?.length > 50
                    ? `${item.externalMemo.substring(0, 50)}...`
                    : item.externalMemo}
                </span>
              </TableCell>
              <TableCell>
                {item.consumptionTaxPercent === 0
                  ? '非課税'
                  : `${item.consumptionTaxPercent}%`}
              </TableCell>
              <TableCell>
                <div className="u-align_right">{numberFormat(item.price)}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
const TransferDescription: React.FC<{ response: SummaryResponse }> = ({
  response,
}) => {
  const isTransferred: boolean =
    response.data.transferStatus === TRANSFER_STATUS_TRANSFERRED;
  return (
    <div className="l-flex_between l-col_24">
      <div>
        <div className="l-flex">
          {!isTransferred && (
            <div className={styles.paymentStatusLogo}>支払前</div>
          )}
          <h2 className="c-page_title u-mgb_m">{`支払通知書 ${dateFormatFromISO8601(
            response.data.tradingDateThreshold,
            '',
            'MM月'
          )} 分 ${!isTransferred ? '(確認用)' : ''}`}</h2>
        </div>
        <h2
          className={`${styles.texBorder} c-page_title u-mgb_m l-flex_between`}
        >
          <span className="u-mgr_l u-mgl_s">
            {response.data.photographerOrganization.name}
          </span>
          {response.data.photographerOrganization.organizationType ===
          INDIVIDUAL_ORGANIZATION_TYPE
            ? '様'
            : '御中'}
        </h2>
        <p className="u-mgb_s">
          {response.data.photographerOrganization.businessRegistrationNumber &&
            `適格請求書発行事業者登録番号: ${
              response.data.photographerOrganization
                .businessRegistrationNumber || ''
            }`}
        </p>
        {response.data.transferStatus === TRANSFER_STATUS_TRANSFERRED ? (
          <>
            <p>下記の通り支払い申し上げます。</p>
            <p>内容に相違がある場合は、支払日から1ヶ月以内にご連絡ください。</p>
            <p className="u-mgb_m">
              次月以降の支払いにて調整させていただきます。
            </p>
          </>
        ) : (
          <>
            <p>下記の通り支払いを予定しております。</p>
            <p className="u-mgb_m">
              通知後、3営業日以内に連絡がない場合確認済みといたします。
            </p>
          </>
        )}
        <h2 className={`${styles.texBorder} u-mgb_s`}>
          <span className="u-mgr_l">支払金額合計</span>
          &yen;{numberFormat(response.data.payment)}-
        </h2>
        <p>
          {`支払日 ${
            response.data.transferredAt
              ? dateFormatFromISO8601(
                  response.data.transferredAt,
                  '',
                  'YYYY年MM月DD日'
                )
              : '****年**月**日'
          }`}
        </p>
      </div>
      <div>
        <p className="u-mgb_l">
          {response.data.transferStatus === TRANSFER_STATUS_TRANSFERRED ? (
            <>
              {`発行日 ${dateFormatFromISO8601(
                response.data.transferredAt,
                '',
                'YYYY年MM月DD日'
              )}`}
            </>
          ) : (
            <>
              {`通知日 ${dateFormatFromISO8601(
                response.data.closedAt,
                '',
                'YYYY年MM月DD日'
              )}`}
            </>
          )}
        </p>
        <p className="u-mgb_s">千株式会社</p>
        <p>〒102-0094</p>
        <p>東京都千代田区紀尾井町1番3号</p>
        <p className="u-mgb_s">東京ガーデンテラス紀尾井町 紀尾井タワー14F</p>
        <p>お問い合わせ先</p>
        <p>担当：手配チーム</p>
        <p>TEL：050-1744-4638</p>
      </div>
    </div>
  );
};
const TotalPriceTaxDetails: React.FC<{ response: SummaryResponse }> = ({
  response,
}) => {
  return (
    <div className={styles.colColorTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>税率区分</TableCell>
            <TableCell>金額(税抜)</TableCell>
            <TableCell>消費税額</TableCell>
            <TableCell>金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.consumptionTaxes.map((item) => (
            <TableRow>
              <TableCell>
                {item.percent === 0 ? '非課税対象' : `${item.percent}%対象`}
              </TableCell>
              <TableCell>
                <div className="u-align_right">
                  {numberFormat(item.sum - item.tax)}
                </div>
              </TableCell>
              <TableCell>
                <div className="u-align_right">
                  {item.percent === 0 ? '' : numberFormat(item.tax)}
                </div>
              </TableCell>
              <TableCell>
                <div className="u-align_right">{numberFormat(item.sum)}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
const TotalPrices: React.FC<{ response: SummaryResponse }> = ({ response }) => {
  return (
    <div className={styles.rowColorTable}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>小計(税込)</TableCell>
            <TableCell>
              <div className="u-align_right">
                {numberFormat(response.data.price)}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>うち消費税額</TableCell>
            <TableCell>
              <div className="u-align_right">
                {numberFormat(response.data.consumptionTax)}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>源泉徴収額</TableCell>
            <TableCell>
              <div className="u-align_right">
                {response.data.withholdingTax
                  ? numberFormat(response.data.withholdingTax * -1)
                  : 0}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>合計(税込)</TableCell>
            <TableCell>
              <div className="u-align_right">
                {numberFormat(response.data.payment)}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
const OrganizationBankAccount: React.FC<{
  organizationBankAccount: TOrganizationBankAccount;
}> = ({ organizationBankAccount }) => {
  return (
    <div className={styles.colColorTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>銀行名</TableCell>
            <TableCell>支店名</TableCell>
            <TableCell>口座種別</TableCell>
            <TableCell>口座番号</TableCell>
            <TableCell>口座名義</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{organizationBankAccount.bankName}</TableCell>
            <TableCell>{organizationBankAccount.branchName}</TableCell>
            <TableCell>{organizationBankAccount.typeName}</TableCell>
            <TableCell>{organizationBankAccount.number}</TableCell>
            <TableCell>{organizationBankAccount.holderName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const Summary: React.FC = () => {
  const { transferId } = useParams<{ transferId: string }>();
  const { data: response, error } = useJsonApi<SummaryResponse>(
    '/api/photographer_payments/transfers/' + transferId
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <div className={`${styles.contentWrap}`}>
      <div className={clsx(styles.content, 'c-frame')}>
        <div className="l-col_wrap l-flex">
          <TransferDescription response={response} />
          <div className="l-col_12 u-mgt_m u-pdr_s u-mgt_l">
            <TotalPrices response={response} />
          </div>
          <div className="l-col_12">
            <h3 className="u-mgb_s">税率別内訳</h3>
            <TotalPriceTaxDetails response={response} />
          </div>
          <div className="l-col_24 u-mgt_l">
            <h3 className="u-mgb_s">振込先口座情報</h3>
            <OrganizationBankAccount
              organizationBankAccount={response.data.organizationBankAccount}
            />
          </div>
          {response.data.paymentItemsIncludingWithholdingTax.length !== 0 && (
            <div className="l-col_24 u-mgt_l">
              <h3 className="u-mgb_s">内訳(源泉徴収対象)</h3>
              <TransferDetail
                paymentItems={response.data.paymentItemsIncludingWithholdingTax}
              />
            </div>
          )}
          {response.data.paymentItemsExcludingWithholdingTax.length !== 0 && (
            <div className="l-col_24 u-mgt_l">
              <h3 className="u-mgb_s">内訳(源泉徴収対象外)</h3>
              <TransferDetail
                paymentItems={response.data.paymentItemsExcludingWithholdingTax}
              />
            </div>
          )}
          {response.data.carryOveredItemsIncludingWithholdingTax.length !==
            0 && (
            <div className="l-col_24 u-mgt_l">
              <h3 className="u-mgb_s">繰越分 内訳(源泉徴収対象)</h3>
              <TransferDetail
                paymentItems={
                  response.data.carryOveredItemsIncludingWithholdingTax
                }
              />
            </div>
          )}
          {response.data.carryOveredItemsExcludingWithholdingTax.length !==
            0 && (
            <div className="l-col_24 u-mgt_l">
              <h3 className="u-mgb_s">繰越分 内訳(源泉徴収対象外)</h3>
              <TransferDetail
                paymentItems={
                  response.data.carryOveredItemsExcludingWithholdingTax
                }
              />
            </div>
          )}
          <div className="u-mgt_m">
            {response.data.carryOveredReason && (
              <>
                <h4>繰越理由</h4>
                {response.data.carryOveredReason}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
