import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type TransportationProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const Transportation: React.FC<TransportationProps> = ({
  methods,
  validator,
}) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasTransportation' && type === 'change') {
        if (value.hasTransportation !== 1) {
          setValue(
            'isPhotographerTransportationOnboardPermitted',
            ID_UNCONFIRMED
          );
          setValue('transportationSenNote', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.transportation.name}
      id={LIST_ITEMS.photography.transportation.id}
    >
      <RadioBoxes
        name="hasTransportation"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasTransportation') === 1 && (
        <>
          <div className="t-textColor_sub">カメラマンの同乗</div>
          <RadioBoxes
            name="isPhotographerTransportationOnboardPermitted"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: '可能' },
              { key: 0, value: '不可' },
            ]}
          />
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="transportationSenNote"
            validator={validator}
            placeholder="交通手段の詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};
