import { kanriUrl, ownerUrl } from '../../ts/url';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useAuthInfo } from '../AuthProvider';
import { RiCameraLine } from 'react-icons/ri';

const MenuList: React.FC = () => {
  const authInfo = useAuthInfo();
  const tehaiListfromDay = dayjs().subtract(1, 'year').format('YYYY-MM-DD');

  // TODO:リンクどうするかは後ほど検討するのでいまは適当なリンクに
  return (
    <ul className="p-side_menuList">
      {authInfo.authSales && (
        <li className="p-side_menuListItem">
          <span className="p-side_menuList_title">
            <i className="p-side_icon p-side_icon__salesman"></i>営業
          </span>
          <ul className="p-side_child_menuList">
            <li className="p-side_child_menuListItem">
              <Link
                className="p-side_child_linkTxt"
                to={`/sales?${QueryString.stringify({
                  primarySales: authInfo.id,
                  dateFrom: dayjs().startOf('month').format('YYYY-MM-DD'),
                  dateTo: dayjs().format('YYYY-MM-DD'),
                })}`}
              >
                今月の売上（直販・先生プラン）
              </Link>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_SALESsearch: 'true',
                  eigyoownerno: authInfo.id.toString(),
                  date_from: dayjs().startOf('month').format('YYYY-MM-DD'),
                  date_to: dayjs().format('YYYY-MM-DD'),
                  outputmode: 'html',
                })}
              >
                今月の売上（パートナー）
              </a>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  eigyoownerno: authInfo.id.toString(),
                  photographingday_from: dayjs().format('YYYY-MM-DD'),
                })}
              >
                手配リスト
              </a>
            </li>
          </ul>
        </li>
      )}
      {!!authInfo.photographerId && (
        <li className="p-side_menuListItem">
          <span className="p-side_menuList_title">
            <i className="p-side_icon p-side_icon__photographer"></i>社カメ
          </span>
          <ul className="p-side_child_menuList">
            <li className="p-side_child_menuListItem">
              <Link
                className="p-side_child_linkTxt"
                to={`/societies/?${QueryString.stringify({
                  'primaryPhotographer[]': authInfo.photographerId,
                })}`}
              >
                担当団体リスト
              </Link>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  photographingday_from: 'today',
                  tanto_photographer_id: authInfo.photographerId.toString(),
                })}
              >
                担当の手配リスト
              </a>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  photographingday_from: 'today',
                  photographer_id: authInfo.photographerId.toString(),
                })}
              >
                自分の手配リスト
              </a>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  photographingday_from: tehaiListfromDay,
                  photographingday_to: 'today',
                  photographer_id: authInfo.photographerId.toString(),
                  sortbydesc: '1',
                })}
              >
                自分の過去分手配リスト
              </a>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={
                  kanriUrl({
                    cam: authInfo.photographerId.toString(),
                  }) + '#tabs-shuho'
                }
              >
                週報
              </a>
            </li>
            <li className="p-side_child_menuListItem">
              <a
                className="p-side_child_linkTxt"
                href={kanriUrl({
                  action_owner_PHOTOGRAPHERpreferredschedule: 'true',
                  photographer_id: authInfo.photographerId.toString(),
                })}
              >
                自分の希望スケジュール
              </a>
            </li>
          </ul>
        </li>
      )}
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="c-icon_partner u-mgr_s"></i>直販・先生
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/corporations">
              法人一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/societies">
              団体一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <Link
              className="p-side_child_linkTxt"
              to="/teacher_incentive_payments"
            >
              先生請求明細
            </Link>
          </li>
        </ul>
      </li>
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="p-side_icon p-side_icon__events"></i>イベント
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/events">
              イベント一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={ownerUrl('events/requests/')}
            >
              申請リスト
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({
                action_owner_SHINSEItehailist2: 'true',
                photographingday_from: 'today',
              })}
            >
              手配リスト
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/convertinfos/unbundled">
              アップ済みフォルダ
            </Link>
          </li>
        </ul>
      </li>
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="p-side_icon">
            <RiCameraLine />
          </i>
          カメラマン
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/photographers">
              カメラマン一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <Link
              className="p-side_child_linkTxt"
              to="/photographer_organizations"
            >
              カメラマン組織一覧
            </Link>
          </li>
          {authInfo.authTehai && (
            <li className="p-side_child_menuListItem">
              <Link
                className="p-side_child_linkTxt"
                to="/photographer_payments"
              >
                報酬管理
              </Link>
            </li>
          )}
          {authInfo.authPhotographerPaymentsClose && (
            <li className="p-side_child_menuListItem">
              <Link
                className="p-side_child_linkTxt"
                to="/photographer_payments/monthly_closings"
              >
                月締め一覧
              </Link>
            </li>
          )}
          {(authInfo.authTehai || authInfo.authAdmin) && (
            <li className="p-side_child_menuListItem">
              <Link
                className="p-side_child_linkTxt"
                to="/photographer_payments/transfers"
              >
                支払一覧
              </Link>
            </li>
          )}
        </ul>
      </li>
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="c-icon_partner u-mgr_s"></i>パートナー
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/partners">
              パートナー一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/groups">
              パートナー団体一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <Link
              className="p-side_child_linkTxt"
              to="/partner_incentive_payments"
            >
              パートナー請求明細
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({
                action_owner_MEISAIlistforadmin: 'true',
              })}
            >
              パートナー請求明細｜〜2019/06
            </a>
          </li>
        </ul>
      </li>
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="p-side_icon p-side_icon__users"></i>会員
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <a className="p-side_child_linkTxt" href={ownerUrl('users')}>
              会員検索
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({
                action_owner_ORDERlist: 'true',
              })}
            >
              注文検索
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({ action_owner_PARTNERkey: 'true' })}
            >
              写真閲覧キー登録推移
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <Link className="p-side_child_linkTxt" to="/certificationkeys">
              写真閲覧キー一覧
            </Link>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({ action_owner_SYSTEMmaillog: 'true' })}
            >
              メールログ検索
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({
                action_owner_SYSTEMmailsuppressionlist: 'true',
              })}
            >
              サプレッションリスト
            </a>
          </li>
        </ul>
      </li>
      <li className="p-side_menuListItem">
        <span className="p-side_menuList_title">
          <i className="p-side_icon p-side_icon__others"></i>その他
        </span>
        <ul className="p-side_child_menuList">
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({ action_owner_SALESsearch: 'true' })}
            >
              売れ行き集計（パートナー）
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({ action_owner_PHOTOsearch: 'true' })}
            >
              写真検索
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={kanriUrl({
                action_owner_PHOTOGRAPHERPAYSLIPinput: 'true',
              })}
            >
              カメラマン支給明細
            </a>
          </li>
          <li className="p-side_child_menuListItem">
            <a
              className="p-side_child_linkTxt"
              href={ownerUrl('coupons/tickets')}
            >
              クーポン一覧
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default MenuList;
