import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  putJson,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { useParams } from 'react-router-dom';
import { Form } from '../Form';
import {
  TEditResponse,
  TSaveResponse,
  TFormInputs,
  TPhotographerOrganization,
  BackButton,
} from '../Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';
import { useHistory } from 'react-router';
import zenginCode from 'zengin-code';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TEditResponse>(
    '/api/photographer_organizations/' + id + '/edit'
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);
  const history = useHistory();
  const onSubmit = usePreventDuplicateCall(async (formData) => {
    try {
      const saveResponse = (await putJson<TSaveResponse>(
        '/api/photographer_organizations/' + id,
        formData
      )) as TSaveResponse;
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('更新しました');
      history.push(
        `/photographer_organizations/${saveResponse.photographerOrganizationId}`
      );
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  });

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const photographerOrganization: TPhotographerOrganization =
    data.photographerOrganization;
  const matchRegisteredZenginCode =
    zenginCode[photographerOrganization.bankId]?.name ===
      photographerOrganization.bankName &&
    zenginCode[photographerOrganization.bankId]?.branches?.[
      photographerOrganization.branchId
    ]?.name === photographerOrganization.branchName;
  const defaultValue: Partial<TFormInputs> = {
    name: photographerOrganization.name,
    type: photographerOrganization.type,
    status: photographerOrganization.status,
    corporate_number: photographerOrganization.corporateNumber,
    business_registration_number:
      photographerOrganization.businessRegistrationNumber,
    business_registration_check_date:
      photographerOrganization.businessRegistrationCheckDate,
    business_registration_trade_name:
      photographerOrganization.businessRegistrationTradeName,
    business_registration_create_date:
      photographerOrganization.businessRegistrationCreateDate,
    business_registration_update_date:
      photographerOrganization.businessRegistrationUpdateDate,
    business_registration_disposal_date:
      photographerOrganization.businessRegistrationDisposalDate,
    business_registration_expire_date:
      photographerOrganization.businessRegistrationExpireDate,
    prefecture_master_no: photographerOrganization.prefectureMasterNo,
    internal_memo: photographerOrganization.internalMemo,
    bank_id: photographerOrganization.bankId,
    bank_name: photographerOrganization.bankName,
    branch_id: photographerOrganization.branchId,
    branch_name: photographerOrganization.branchName,
    account_number: photographerOrganization.accountNumber,
    account_holder: photographerOrganization.accountHolder,
    account_type: photographerOrganization.accountType,
    bank_id_manual_input:
      !matchRegisteredZenginCode && photographerOrganization.bankId !== '',
  };

  const backButton: BackButton = {
    name: 'キャンセル',
    url: `/photographer_organizations/` + id,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">カメラマン組織 - 編集</span>
      </h3>
      <div className="l-center_wrap">
        <Form
          formItems={data.formItems}
          onSubmit={onSubmit}
          validator={validator}
          defaultValue={defaultValue}
          errorMessages={errorMessages}
          backButton={backButton}
        />
      </div>
    </div>
  );
};

export default App;
