import { TValidator } from '@/components/shared/Form/types';
import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  InnerTable,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import { TextInput } from '@/components/shared/Form/Inputs';
import { FiPlus } from 'react-icons/fi';
import { Alert } from '@/components/shared/Alert';
import clsx from 'clsx';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type TeacherInChargeOfPhotographyProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const TeacherInChargeOfPhotography: React.FC<TeacherInChargeOfPhotographyProps> =
  ({ methods, validator }) => {
    const teachers = methods.watch('teachersInChargeOfPhotography');

    const addTeacher = () => {
      const currentTeachers = methods.getValues(
        'teachersInChargeOfPhotography'
      );
      methods.setValue('teachersInChargeOfPhotography', [
        ...currentTeachers,
        '',
      ]);
    };

    const deleteTeacher = (index: number) => {
      const currentTeachers = methods.getValues(
        'teachersInChargeOfPhotography'
      );
      methods.setValue(
        'teachersInChargeOfPhotography',
        currentTeachers.filter((_, i) => i !== index)
      );
    };

    return (
      <PhotographyAccordion
        title={LIST_ITEMS.event.teachersInChargeOfPhotography.name}
        id={LIST_ITEMS.event.teachersInChargeOfPhotography.id}
      >
        <Alert type="warning" title="詳細確認と当日担当者が異なる場合">
          <p>
            両方の担当者名を記載した上で、後ろに（）で記載をしてください。
            <br />
            ・山田花子先生（事前の詳細確認）
            <br />
            ・山本太郎先生（当日打ち合わせ担当の先生）
          </p>
        </Alert>
        <InnerTable>
          <InnerTableHead>
            {teachers.map((_, index) => (
              <InnerTableRow key={index}>
                <InnerTableCell>
                  <span>なまえ</span>
                  <TextInput
                    name={`teachersInChargeOfPhotography.${index}`}
                    validator={validator}
                  />
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_center">
                    <button
                      type="button"
                      className={clsx(
                        'c-btn_small c-btn_delete c-btn_middle_wide',
                        { 'is-disabled': index === 0 }
                      )}
                      onClick={() => deleteTeacher(index)}
                    >
                      削除
                    </button>
                  </div>
                </InnerTableCell>
              </InnerTableRow>
            ))}
          </InnerTableHead>
        </InnerTable>
        <button
          type="button"
          className="c-btn c-btn_large c-btn_gray_outline u-mgt_s"
          onClick={addTeacher}
        >
          <FiPlus />
          追加
        </button>
      </PhotographyAccordion>
    );
  };
