import React from 'react';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { isContractTypeTeacher } from '../../../../ts/societyContractConstants';
import { TSalesManagementFormItems } from '../types';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

const Form: React.FC<{
  formItems: TSalesManagementFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(
  ({
    formItems: {
      isEnabledSellByFax,
      isEnabledSellDataS,
      printingCompanyPhotoCorrectionType,
      isEnabledPhotoSelect,
    },
    validator,
  }) => (
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_24">
        <ul className="l-flex">
          <li className="c-dataLabel">
            販売情報
            <span className="l-relative">
              <span className="c-tooltip u-mgl_xs">
                <i className="c-icon_help"></i>
              </span>
              <span className="c-tooltip_body p-groupsSummary_tooltip_help">
                先生撮影団体の場合のみ表示されます
              </span>
            </span>
          </li>
          <li className="c-dataValue">
            <div className="t-bgBox_gray">
              <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>FAX販売</label>{' '}
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="salesManagement.isEnabledSellByFax"
                        choices={isEnabledSellByFax}
                        validator={validator}
                        optionalValidationRule={{ required: true }}
                      />
                    </li>
                  </ul>
                </li>
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>データ小の販売</label>{' '}
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="salesManagement.isEnabledSellDataS"
                        choices={isEnabledSellDataS}
                        validator={validator}
                        optionalValidationRule={{ required: true }}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>写真の自動補正</label>{' '}
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="salesManagement.printingCompanyPhotoCorrectionType"
                        choices={printingCompanyPhotoCorrectionType}
                        validator={validator}
                        optionalValidationRule={{ required: true }}
                      />
                    </li>
                  </ul>
                </li>
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>選定代行サービス</label>{' '}
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="salesManagement.isEnabledPhotoSelect"
                        choices={isEnabledPhotoSelect}
                        validator={validator}
                        optionalValidationRule={{ required: true }}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">備考</li>
                <li className="c-dataValue u-pre_wrap">
                  <TextArea
                    name="salesManagement.memo"
                    validator={validator}
                    rows={5}
                  />
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  )
);

const SaveFormSalesManagement: React.FC<{
  societyContractTypeId: number;
  formItems: TSalesManagementFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(({ societyContractTypeId, ...props }) =>
  isContractTypeTeacher(societyContractTypeId) ? <Form {...props} /> : null
);

export default SaveFormSalesManagement;
