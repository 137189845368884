import React from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { fetchPdf } from '@/ts/fetch';
import { errorToast } from '@/ts/toast';
import '../flyer.scss';

const Download: React.FC<{
  eventno: string;
  eventFlyerName: string | null;
  downloadLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
}> = ({ eventno, eventFlyerName, downloadLinkRef }) => {
  const downloadEventFlyer = async () => {
    if (eventFlyerName) {
      try {
        const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
          `/api/events/${eventno}/flyer/download`
        );
        if (!(pdfResponse instanceof Blob)) {
          if (pdfResponse.validator.hasError) {
            errorToast(
              'チラシのダウンロードに失敗しました。再ダウンロードを試しても失敗する場合は、開発者にご連絡をください'
            );
          }
          return;
        }
        const link = downloadLinkRef.current!;
        const url = URL.createObjectURL(pdfResponse);
        link.href = url;
        link.download = eventFlyerName;
        link.click();
      } catch (e) {
        errorToast('エラーが発生しました');
      }
    }
  };
  return (
    <button
      type="button"
      className={`c-btn_edit c-btn_small u-mgr_s`}
      onClick={downloadEventFlyer}
    >
      <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
      <span>イベントチラシ</span>
    </button>
  );
};

export default Download;
