import { get as getPostalCode } from 'japan-postal-code';
import {
  GroupedSingleSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Help from '../../../shared/Help/App';
import { TFormInputs, TFormItems } from './types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';

type TSaveFormProps = {
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formInputs: TFormInputs;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  formItems: TFormItems;
  cancelLink: string;
};

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    validator,
    formInputs,
    errorMessages,
    onSubmit,
    formItems,
    cancelLink,
  }) => {
    const methods = useForm<TFormInputs>({ defaultValues: formInputs });

    const setAddressByPostcode = () => {
      const postcode = String(methods.getValues('postcode'));
      getPostalCode(postcode, (address) => {
        formItems.prefectures.forEach((area) => {
          area.choices.forEach((prefecture) => {
            if (prefecture.value === address.prefecture) {
              methods.setValue('prefectureId', Number(prefecture.key));
            }
          });
        });
        methods.setValue('city', address.city);
        methods.setValue('address', address.area);
      });
    };

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="PUT"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>法人名</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="name"
                  placeholder="法人名"
                  validator={validator}
                />
              </li>
              <li className="c-dataLabel">
                <label>法人名(かな)</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="namekana"
                  placeholder="法人名(かな)"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>表示名称</label>
                <Help>ユーザー画面での表示名</Help>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="viewname"
                  placeholder="表示名称"
                  validator={validator}
                />
              </li>
              <li className="c-dataLabel">
                <label>法人番号</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="corporateNumber"
                  placeholder="9999999999999"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>法人代表者名</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="representativeName"
                  placeholder="氏名"
                  validator={validator}
                />
              </li>
              <li className="c-dataLabel">
                <label>法人代表者名(かな)</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="representativeNamekana"
                  placeholder="氏名(かな)"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-dataLabel">
                <label>郵便番号</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <div className="l-flex">
                  <div className="c-label_innerHalf__isPostcode">
                    <TextInput
                      name="postcode"
                      placeholder="123-4567"
                      validator={validator}
                    />
                  </div>
                  <div className="c-label_innerHalf__isPostcode">
                    <input
                      className="c-btn_large c-btn_Search"
                      value="検索"
                      type="button"
                      onClick={setAddressByPostcode}
                    />
                  </div>
                </div>
              </li>
              <li className="c-dataLabel">
                <label>都道府県</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <GroupedSingleSelect
                  name="prefectureId"
                  choices={formItems.prefectures}
                  placeholder="都道府県"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>住所(市区町村)</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="city"
                  placeholder="市区町村"
                  validator={validator}
                />
              </li>
              <li className="c-dataLabel">
                <label>住所(番地・建物)</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="address"
                  placeholder="番地・建物"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>法人代表者電話番号</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="telephonenumber"
                  placeholder="03-1234-5678"
                  validator={validator}
                />
              </li>
              <li className="c-dataLabel">
                <label>FAX番号</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="faxnumber"
                  placeholder="03-1234-5678"
                  validator={validator}
                />
              </li>
            </ul>
            <div className="u-align_center u-mgb_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value="登録"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
