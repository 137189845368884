import React, { useState, useEffect } from 'react';
import {
  TextInput,
  TextArea,
  RadioBoxes,
  GroupedSingleSelect,
} from '@/components/shared/Form/Inputs';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { BackButton, TFormInputs, TFormItems } from './types';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { BusinessRegistration } from './BusinessRegistration';
import { Link } from 'react-router-dom';
import { BankAccountForm } from './BankAccountForm';

type TFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  defaultValue: Partial<TFormInputs>;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  backButton: BackButton;
};

const BasicInfoForm: React.FC<{
  validator: TValidatorResponse;
  formItems: TFormItems;
  onCheckClick: (clicked: boolean) => void;
  setConfirmedNumber: React.Dispatch<React.SetStateAction<string>>;
}> = ({ formItems, validator, onCheckClick, setConfirmedNumber }) => {
  const methods = useFormContext<TFormInputs>();
  return (
    <div className="c-frame">
      <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
        <h2>基本情報</h2>
      </div>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>組織名</label>
          <small className="c-required">(必須)</small>
        </li>
        <li className="c-dataValue">
          <TextInput name="name" placeholder="組織名" validator={validator} />
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>組織種別</label>
          <small className="c-required">(必須)</small>
        </li>
        <li className="c-dataValue">
          <RadioBoxes
            name="type"
            choices={formItems.types}
            validator={validator}
          />
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>ステータス</label>
          <small className="c-required">(必須)</small>
        </li>
        <li className="c-dataValue">
          <RadioBoxes
            name="status"
            choices={formItems.statuses}
            validator={validator}
          />
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>法人番号</label>
        </li>
        <li className="c-dataValue">
          <TextInput
            name="corporate_number"
            placeholder="13桁の数字"
            validator={validator}
          />
        </li>
      </ul>
      <BusinessRegistration
        validator={validator}
        register={methods.register}
        setValue={methods.setValue}
        onCheckClick={onCheckClick}
        setConfirmedNumber={setConfirmedNumber}
      />
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>都道府県</label>
          <small className="c-required">(必須)</small>
        </li>
        <li className="c-dataValue">
          <GroupedSingleSelect
            name="prefecture_master_no"
            choices={formItems.prefectures}
            placeholder="プルダウンから検索（テキスト入力もできます）"
            validator={validator}
          />
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>社内メモ</label>
        </li>
        <li className="c-dataValue">
          <TextArea
            name="internal_memo"
            placeholder="カメラマンには表示されません"
            validator={validator}
          />
        </li>
      </ul>
    </div>
  );
};

export const Form: React.FC<TFormProps> = ({
  validator,
  formItems,
  defaultValue,
  onSubmit,
  errorMessages,
  backButton,
}) => {
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const methods = useForm<TFormInputs>({ defaultValues: defaultValue });
  const watchedValue = methods.watch('business_registration_number');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState(watchedValue);
  const [confirmedNumber, setConfirmedNumber] = useState(
    defaultValue.business_registration_number != null
      ? defaultValue.business_registration_number
      : ''
  );

  useEffect(() => {
    setBusinessRegistrationNumber(watchedValue || '');
  }, [watchedValue]);

  useEffect(() => {
    if (confirmedNumber === businessRegistrationNumber) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [businessRegistrationNumber, confirmedNumber]);

  return (
    <FormProvider {...methods}>
      {errorMessages.length > 0 && (
        <div className="c-error_block">
          {errorMessages.map((message, index) => (
            <ul className="c-error_list" key={index}>
              <li className="c-error_listItem">{message}</li>
            </ul>
          ))}
        </div>
      )}
      <form
        method="POST"
        name="storeForm"
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <BasicInfoForm
          validator={validator}
          formItems={formItems}
          onCheckClick={setCanSubmit}
          setConfirmedNumber={setConfirmedNumber}
        />
        <BankAccountForm validator={validator} formItems={formItems} />
        <div className="u-mgb_l" />
        <div className="p-photographerOrganizationForm_link">
          <Link
            className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
            to={backButton.url}
          >
            {backButton.name}
          </Link>
          {canSubmit ? (
            <input
              className="c-btn_large c-btn_primary is-arrow c-input_submit"
              type="submit"
              value="登録"
            />
          ) : (
            <div className="c-btn_large c-btn_cancel u-mgr_m c-input_submit">
              国税庁サイト確認ボタンを押して下さい
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
