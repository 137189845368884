import React from 'react';
import { Link } from 'react-router-dom';
import { TGroups } from './types';
import * as constants from '../constants';
import { kanriUrl } from '../../../../ts/url';
import { dateFormat } from '../../../../ts/formatTools';

const SearchResultGroupsList: React.FC<{ groups: TGroups[] }> = (props) => (
  <table className="c-indexList p-groupSocietiesList">
    <thead>
      <tr>
        <th>パートナー名(表示名)</th>
        <th>団体名</th>
        <th>住所</th>
        <th>電話番号</th>
        <th>FAX番号</th>
        <th>担当営業</th>
        <th>契約情報(プラン)</th>
      </tr>
    </thead>
    <tbody>
      {props.groups.map((group: TGroups) => (
        <tr key={group.id}>
          <td className="c-indexList_column_s p-groupSocietiesList-societyName">
            <div>
              <div className="c-idLink_hasBg p-groupsSocieties_id">
                <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
                {group.partnerId}
              </div>
            </div>
            <Link className="c-textlink" to={`/partners/${group.partnerId}`}>
              {group.partnerName}
            </Link>
          </td>
          <td className="c-indexList_column_s">
            <div>
              <div className="c-idLink_hasBg p-groupsSocieties_id">
                <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                {group.id}
              </div>
            </div>
            <a
              className="c-textlink"
              href={kanriUrl({
                action_owner_GROUPsummary: 'true',
                groupsno: String(group.id),
              })}
            >
              {group.name}
            </a>
          </td>
          <td className="c-indexList_column_s">
            {`〒${group.postcode} ${group.prefectureName}${group.city}${group.address}`}
          </td>
          <td className="c-indexList_column_xs">{group.telephonenumber}</td>
          <td className="c-indexList_column_xs">{group.faxnumber}</td>
          <td className="c-indexList_column_xs">
            {group.salesDepartmentOwnerName}
          </td>
          <td className="c-indexList_column_l">
            <ul className="l-flex p-groupsSocietiesList_contractStatus">
              <li className="p-groupsSocietiesList_contractStatus">
                <ul className="l-flex">
                  <li className="p-groupsLabel_operation">
                    <span
                      className={`c-statusLabel c-statusLabel__${
                        group.contractStatus === constants.STATUS_ACTIVE
                          ? 'positive'
                          : 'negative'
                      } small`}
                    >
                      {group.statusName}
                    </span>
                  </li>
                  <li className="u-mgr_m">{group.planName}</li>
                </ul>
              </li>
              <li>
                <ul className="p-groupsSocietiesList_contractInfo l-flex">
                  <li className="p-groupsSocietiesList_contractInfoCaption">
                    登録日
                  </li>
                  <li className="p-groupsSocietiesList_contractInfoValue has-separate">
                    {/* TODO: 登録日 */}
                    {dateFormat(group.createdday)}
                  </li>
                  <li className="p-groupsSocietiesList_contractInfoCaption">
                    認識タイプ
                  </li>
                  <li className="p-groupsSocietiesList_contractInfoValue has-separate">
                    {group.authenticationTypeName}
                  </li>
                  <li className="p-groupsSocietiesList_contractInfoCaption">
                    事前確認
                  </li>
                  <li className="p-groupsSocietiesList_contractInfoValue">
                    {group.isRequiredPreConfirmationName}
                  </li>
                </ul>
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default SearchResultGroupsList;
