import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { Prompting } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/Prompting';
import { ArrivalAndDeparturePhoto } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/ArrivalAndDeparturePhoto';
import { LunchPhoto } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/LunchPhoto/LunchPhoto';
import { GroupPhoto } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/GroupPhoto';
import { Transportation } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/Transportation';
import { NgPhotographySubject } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/NgPhotographySubject';
import { Rest } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/Rest';
import { Belongings } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/Belongings/Belongings';
import { SocietyRequest } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/SocietyRequest';
import { SpecifiedPosition } from './SpecifiedPosition';
import { SignboardPhoto } from './SignboardPhoto';
import { Tripod } from './Tripod';
import { RentalStepladder } from './RentalStepladder';
import { AlbumOption } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/AlbumOption';
import { Equipment } from '@/components/pages/Events/Show/Photography/PhotographyPhotography/Equipment';
import {
  shouldRenderAlbumOption,
  shouldRenderRentalStepladder,
  shouldRenderRest,
  shouldRenderSignboardPhoto,
  shouldRenderSpecifiedPosition,
  shouldRenderTripod,
} from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';
import { PARENT_LIST_ITEMS } from '../listItem';

type PhotographyPhotographyProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const PhotographyPhotography: React.FC<PhotographyPhotographyProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.photography.id}>
        {PARENT_LIST_ITEMS.photography.name}
      </div>
      <hr className="u-line_plane" />
      <Prompting methods={methods} validator={validator} />
      <ArrivalAndDeparturePhoto methods={methods} validator={validator} />
      <LunchPhoto methods={methods} validator={validator} />
      {shouldRenderSignboardPhoto(photography) && (
        <SignboardPhoto validator={validator} constants={constants} />
      )}
      <GroupPhoto photography={photography} validator={validator} />
      <Transportation methods={methods} validator={validator} />
      {shouldRenderSpecifiedPosition(photography) && (
        <SpecifiedPosition methods={methods} validator={validator} />
      )}
      <NgPhotographySubject
        methods={methods}
        constants={constants}
        validator={validator}
      />
      {shouldRenderRest(photography) && (
        <Rest methods={methods} constants={constants} validator={validator} />
      )}
      {shouldRenderAlbumOption(photography) && (
        <AlbumOption
          photography={photography}
          methods={methods}
          validator={validator}
        />
      )}
      <Equipment
        photography={photography}
        constants={constants}
        methods={methods}
        validator={validator}
      />
      <Belongings constants={constants} validator={validator} />
      {shouldRenderTripod(photography) && <Tripod validator={validator} />}
      {shouldRenderRentalStepladder(photography) && (
        <RentalStepladder validator={validator} />
      )}
      <SocietyRequest validator={validator} />
    </div>
  );
};
