import React from 'react';
import { TPhotographer } from '../types';
import { dateFormatFromISO8601 } from '@/ts/formatTools';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import dayjs from 'dayjs';
import Accordion from '@/components/shared/Accordion/App';

export const DetailProfile: React.FC<{
  photographer: TPhotographer;
}> = ({ photographer }) => (
  <div className="c-frame">
    <div className="u-pdb_s u-mgb_m">
      <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
        <h2 id="p-photographersShow_detail-info">業務情報</h2>
      </div>
      <table className="p-photographersShow_photographerTable">
        <tbody>
          <TableRow>
            <TableCell title={'ID'}>{photographer.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'稼動状況'}>{photographer.statusName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'登録日'}>
              {dateFormatFromISO8601(photographer.createdDay)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'更新日'}>
              {dateFormatFromISO8601(photographer.updatedDay)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'非稼動変更日'}>
              {dateFormatFromISO8601(photographer.deletedDay)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'社内外'}>
              {photographer.isShanai === '1' ? '社内' : '社外'}
            </TableCell>
          </TableRow>
          {photographer.isShanai === '0' ? (
            <>
              <TableRow>
                <TableCell title={'契約タイプ'}>
                  {photographer.agreementTypeName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell title={'経費算出方法'}>
                  {photographer.expenseCalculationTypeName}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <></>
          )}
          <TableRow>
            <TableCell title={'ランク'}>
              {`${photographer.oldRankName} ${
                photographer.rankFeeEffectiveDate
                  ? `(${dayjs(photographer.rankFeeEffectiveDate).format(
                      'YYYY年MM月から適用'
                    )})`
                  : ''
              }`}
              <br />
              <Accordion labelOpen="履歴を開く" labelClose="履歴を閉じる">
                <table className="p-photographersShow_eventTable">
                  <thead>
                    <tr>
                      <th>変更日</th>
                      <th>ランク</th>
                      <th>適用開始月</th>
                    </tr>
                  </thead>
                  <tbody>
                    {photographer.rankHistories.map((history) => (
                      <tr>
                        <td>
                          {dayjs(history.registeredAt).format('YYYY/MM/DD')}
                        </td>
                        <td>{history.name}</td>
                        <td>
                          {dayjs(history.effectiveDate).format('YYYY年MM月')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Accordion>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'ランク報酬額（税込）'}>
              {photographer.rankFee}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              title={'繁忙日特典額（税込）'}
              helpText={'ポイント酬額のこと'}
            >
              {`${photographer.thanksPointFee} ${
                photographer.thanksPointFeeEffectiveDate
                  ? `(${dayjs(photographer.thanksPointFeeEffectiveDate).format(
                      'YYYY年MM月から適用'
                    )})`
                  : ''
              }`}
              <br />
              <Accordion labelOpen="履歴を開く" labelClose="履歴を閉じる">
                <table className="p-photographersShow_eventTable">
                  <thead>
                    <tr>
                      <th>変更日</th>
                      <th>金額</th>
                      <th>適用開始月</th>
                    </tr>
                  </thead>
                  <tbody>
                    {photographer.thanksPointFeeHistories.map((history) => (
                      <tr>
                        <td>
                          {dayjs(history.registeredAt).format('YYYY/MM/DD')}
                        </td>
                        <td>{history.fee}</td>
                        <td>
                          {dayjs(history.effectiveDate).format('YYYY年MM月')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Accordion>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              title={'基本金額（税込）'}
              helpText={'ランク方式ではない場合の報酬額'}
            >
              {`${photographer.rewardPrice} ${
                photographer.rewardPriceEffectiveDate
                  ? `(${dayjs(photographer.rewardPriceEffectiveDate).format(
                      'YYYY年MM月から適用'
                    )})`
                  : ''
              }`}
              <br />
              {photographer.rewardPriceHistories.length > 0 && (
                <Accordion labelOpen="履歴を開く" labelClose="履歴を閉じる">
                  <table className="p-photographersShow_eventTable">
                    <thead>
                      <tr>
                        <th>変更日</th>
                        <th>金額</th>
                        <th>適用開始月</th>
                      </tr>
                    </thead>
                    <tbody>
                      {photographer.rewardPriceHistories.map((history) => (
                        <tr>
                          <td>
                            {dayjs(history.registeredAt).format('YYYY/MM/DD')}
                          </td>
                          <td>{history.rewardPrice}</td>
                          <td>
                            {dayjs(history.effectiveDate).format('YYYY年MM月')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'スナップ自動補正'}>
              {photographer.isNoHoseiName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'リマインダー'}>
              {photographer.isSendReminderName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'自動アサイン'}>
              {photographer.isEnabledAutoArrangementName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'車所持'}>{photographer.hasCarName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'宿泊イベント'}>
              {photographer.isStayNightName}
            </TableCell>
          </TableRow>
          {photographer.isShanai === '0' ? (
            <>
              <TableRow>
                <TableCell title={'撮影可能な日'}>
                  {photographer.workingDateTypeName}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <></>
          )}
          <TableRow>
            <TableCell title={'メモ'}>{photographer.memo}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'面談情報'}>
              {photographer.interviewInfo}
            </TableCell>
          </TableRow>
          <span id="p-photographersShow_equipment">
            <TableRow>
              <TableCell title={'撮影機材'}>{photographer.equipment}</TableCell>
            </TableRow>
          </span>
        </tbody>
      </table>
    </div>
  </div>
);
