import React, { MouseEventHandler, useState } from 'react';
import SubMenu from './SubMenu';
import { dateFormat } from '../../../../ts/formatTools';
import { TCreateUpdateInfo, TOrganization, TSociety } from '../../types';
import TextCopy from '../../../shared/TextCopy/App';
import { Link } from 'react-router-dom';

const SocietyHeader: React.FC<{
  society: TSociety & TCreateUpdateInfo;
  organization: TOrganization;
  showEditButton?: boolean;
  showLinks?: boolean;
}> = React.memo(({ society, organization, showEditButton, showLinks }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const openSubMenu: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsOpenSubMenu(!isOpenSubMenu);
  };

  return (
    <>
      <div className="l-flex_between_center p-groupsSummary_Label">
        <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
          団体ID: {society.id}
        </span>
        <div className="l-flex">
          <div className="u-mgr_s">
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              登録日
            </span>
            <span className="u-fz_xs">{dateFormat(society.createdday)}</span>
          </div>
          <div>
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              更新日
            </span>
            <span className="u-fz_xs">{dateFormat(society.updatedday)}</span>
            <span className="u-fz_xs">
              （{society.updatedUserno}：{society.updatedUserName}）
            </span>
          </div>
        </div>
      </div>
      <div className="l-flex_between_center">
        <div className="p-groupsList_detail_names">
          <div className="l-flex_align_base">
            <h4 className="c-section_title u-fz_l">{organization.name}</h4>
            <TextCopy
              text={`${society.name}\n${window.location.origin}/societies/${society.id}`}
            />
          </div>
          <span className="u-fz_xs">{organization.namekana}</span>
        </div>
        {(showEditButton || showLinks) && (
          <div className="l-flex_end">
            {showEditButton && (
              <Link
                className="c-btn_rectangle c-btn_edit"
                to={`/societies/${society.id}/edit`}
              >
                <i className="c-icon c-icon__xsmall c-icon_edit" />
              </Link>
            )}
            {showLinks && (
              <div className="l-relative u-pcOff">
                <button
                  className="c-submenu_rectangle c-btn_drawer"
                  onClick={openSubMenu}
                >
                  <i className="c-icon__Xsmall c-icon_leader"></i>
                </button>
                <SubMenu
                  isPopup={true}
                  society={society}
                  organization={organization}
                  onClickClose={openSubMenu}
                  isOpen={isOpenSubMenu}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default SocietyHeader;
