import {
  EVENT_TYPE_ID_CHRISTMAS,
  EVENT_TYPE_ID_DAILY_CHILDCARE,
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_FIELD_TRIPS,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  EVENT_TYPE_ID_HALLOWEEN,
  EVENT_TYPE_ID_MOCHI_POUND,
  EVENT_TYPE_ID_OTHER,
  EVENT_TYPE_ID_POOL,
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  EVENT_TYPE_ID_SLEEPOVER,
  EVENT_TYPE_ID_SPORTS_DAY,
  EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
  EVENT_TYPE_ID_SUMMER_FESTIVAL,
  EVENT_TYPE_ID_THROWING_BEANS,
} from '@/components/pages/Events/Show/Photography/const';

export const shouldRenderRecital = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_RECITAL ||
  eventTypeId === EVENT_TYPE_ID_RECITAL_REHEARSAL;
export const shouldRenderGraduationCeremony = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_GRADUATION_CEREMONY;
export const shouldRenderSportsDay = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_SPORTS_DAY ||
  eventTypeId === EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL;
export const shouldRenderEntranceCeremony = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_ENTRANCE_CEREMONY;
export const shouldRenderHalloween = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_HALLOWEEN;
export const shouldRenderChristmas = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_CHRISTMAS;
export const shouldRenderThrowingBeans = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_THROWING_BEANS;
export const shouldRenderMochiPound = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_MOCHI_POUND;
export const shouldRenderSummerFestival = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_SUMMER_FESTIVAL;
export const shouldRenderPool = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_POOL;
export const shouldRenderSleepover = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_SLEEPOVER;
export const shouldRenderDailyChildcare = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_DAILY_CHILDCARE;
export const shouldRenderFieldTrip = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_FIELD_TRIPS;
export const shouldRenderOther = (eventTypeId: number): boolean =>
  eventTypeId === EVENT_TYPE_ID_OTHER;
