import './loading.scss';
import React, { ReactNode } from 'react';
import LoadingImg from './loading.svg';

const App: React.FC<{ loadingText?: ReactNode }> = ({ loadingText }) => {
  return (
    <div className="svg-loading-center">
      <img className="svg-loading-icon" src={LoadingImg} alt="Icon" />
      <div className="loading-text">{loadingText || 'Loading...'}</div>
    </div>
  );
};

export default App;
