import { canApprove, canPay } from '../constants';
import { TTarget } from '../types';

/**
 * 一括承認申請 可不可
 * TODO: queryParams をnumberに変換可能になったらstring[]->number[]に修正
 */
const isEnabledMultipleRequestApprove = (statuses: string[]): boolean =>
  statuses.every((s) => canApprove(Number(s)));
/**
 * 一括振込日登録 可不可
 * TODO: queryParams をnumberに変換可能になったらstring[]->number[]に修正
 */
const isEnabledMultipleSetPaidAt = (
  statuses: string[],
  target: TTarget
): boolean => statuses.every((s) => canPay(Number(s), target));

export { isEnabledMultipleRequestApprove, isEnabledMultipleSetPaidAt };
