import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { removeEmpty } from '@/ts/objectTools';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import QueryString from 'query-string';
import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import { IndexResponse, TSearchFormInputs } from './types';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { IndexPageLayout } from '@/components/pages/PhotographerPayments/IndexPageLayout';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { SearchForm } from '@/components/pages/PhotographerPayments/AdjustmentPayment/IndexPage/SearchForm';
import { TMutate } from '@/ts/useJsonApi';
import { Results } from '@/components/pages/PhotographerPayments/AdjustmentPayment/IndexPage/Results';

const toQueryParam = (queryString: string): Record<string, unknown> => {
  return removeEmpty(convertQueryToFormData(queryString));
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[1-9][0-9]*$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/adjustment?${query}`;
};

const Content: FC<{
  response: IndexResponse;
  onSubmit: (formData: TSearchFormInputs) => void;
  queryParams: Record<string, unknown>;
  mutate: TMutate<IndexResponse>;
}> = ({ response, onSubmit, queryParams, mutate }) => {
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '調整金一覧',
            path: '/photographer_payments/adjustment',
          },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start u-mgb_m">
          <h1 className="l-flex_center c-page_title">調整金一覧</h1>
        </div>
        <SearchForm
          response={response}
          onSubmit={onSubmit}
          queryParams={queryParams}
        />
        <Results
          response={response}
          mutate={mutate}
          queryParams={queryParams}
        />
      </div>
    </IndexPageLayout>
  );
};

export const IndexPage: FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));

  const onSubmit = (formData: TSearchFormInputs): void => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/adjustment',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };

  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as IndexResponse}
          onSubmit={onSubmit}
          queryParams={queryParams}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }

  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Content
      response={response}
      onSubmit={onSubmit}
      queryParams={queryParams}
      mutate={mutate}
    />
  );
};
