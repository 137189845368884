import React from 'react';
import { Link } from 'react-router-dom';
import { Corporation } from './types';

const CorporationList: React.FC<{ data: Corporation[] }> = (props) => (
  <ul>
    {props.data.map((corporation: Corporation) => (
      <li className="c-indexListItem l-clearfix" key={corporation.id}>
        <div className="u-mgr_s l-flex_between_center">
          <div>
            <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
              <span>法人ID：{corporation.id}</span>
            </div>
            <Link to={'/corporations/' + corporation.id}>
              <b>{corporation.name}</b>
            </Link>
          </div>
          <div>
            <ul className="l-flex p-CorporationList_info">
              <li>
                <i className="c-icon_map u-mgr_xs" />
                <span>
                  {corporation.prefectureName} {corporation.city}{' '}
                  {corporation.address}
                </span>
              </li>
              <li>
                <i className="c-icon_tel u-mgr_xs" />
                <span>{corporation.telephone}</span>
              </li>
            </ul>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

export default CorporationList;
