import React from 'react';

import { dateFormat, numberFormat } from '../../../../ts/formatTools';
import GroupHeader from './GroupHeader';
import { TGroupedIncentivePaymentCost, TIncentivePaymentCost } from './types';

const IncentivePaymentCost: React.FC<{
  incentivePaymentCost: TIncentivePaymentCost;
}> = React.memo(
  ({
    incentivePaymentCost: {
      eventId,
      eventName,
      photographingDay,
      commissionRate,
      quantity,
      totalSale,
      totalPayment,
    },
  }) => (
    <tr>
      <td>{eventId}</td>
      <td>{eventName}</td>
      <td className="u-align_center">{dateFormat(photographingDay)}</td>
      <td className="u-align_center">{commissionRate}%</td>
      <td className="u-align_right">{numberFormat(quantity)} 枚</td>
      <td className="u-align_right">{numberFormat(totalSale)} 円</td>
      <td className="u-align_right">{numberFormat(totalPayment)} 円</td>
    </tr>
  )
);

const GroupedIncentivePaymentCost: React.FC<{
  groupedIncentivePaymentCost: TGroupedIncentivePaymentCost;
}> = React.memo(
  ({
    groupedIncentivePaymentCost: {
      isBulkDelivery,
      groupName,
      planName,
      incentivePaymentCosts,
      totalPayment,
      totalSale,
    },
  }) => (
    <>
      <GroupHeader
        isBulkDelivery={isBulkDelivery}
        groupName={groupName}
        planName={planName}
      />
      <div className="l-relative u-mgb_m">
        <table className="c-incentivePayment_table">
          <thead>
            <tr>
              <th className="c-incentivePayment_table_id_cost">
                <div className="c-incentivePayment_tableCaption">
                  イベントID
                </div>
              </th>
              <th className="c-incentivePayment_table_eventName_cost">
                <div className="c-incentivePayment_tableCaption u-align_left">
                  イベント名
                </div>
              </th>
              <th className="c-incentivePayment_table_shootingDay_cost">
                <div className="c-incentivePayment_tableCaption">撮影日</div>
              </th>
              <th className="c-incentivePayment_table_commissionRate_cost">
                <div className="c-incentivePayment_tableCaption">手数料</div>
              </th>
              <th className="c-incentivePayment_table_incentive_cost">
                <div className="c-incentivePayment_tableCaption">販売枚数</div>
              </th>
              <th className="c-incentivePayment_table_sales_cost">
                <div className="c-incentivePayment_tableCaption">売上額</div>
              </th>
              <th className="c-incentivePayment_table_payments_cost">
                <div className="c-incentivePayment_tableCaption">支払額</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {incentivePaymentCosts.map((paymentCost) => (
              <IncentivePaymentCost
                key={paymentCost.eventId}
                incentivePaymentCost={paymentCost}
              />
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="u-align_right">
                <b className="u-align_right">
                  売上額小計 {numberFormat(totalSale)} 円
                </b>
              </td>
              <td className="u-align_right">
                <b className="u-align_right">
                  支払額小計 {numberFormat(totalPayment)} 円
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
);

export default GroupedIncentivePaymentCost;
