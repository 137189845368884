import React from 'react';

import StatusLabel from '../../shared/StatusLabel/App';
import { dateTimeFormat, HYPHEN } from '../../../ts/formatTools';
import { TMergedUseraccount } from './types';
import { LEADER_APPROVAL_STATUS_APPROVED } from '../../shared/Certificationkeys/constants';
import { getUserSummaryUrl } from '../../../ts/legacyUrl';

const Useraccount: React.FC<{
  mergedUseraccount: TMergedUseraccount;
}> = React.memo(({ mergedUseraccount }) => (
  <>
    <td className="c-indexList_column_s">
      <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short u-fz_s">
        <i className="c-icon c-icon__xxsmall c-icon_person u-mgr_xs" />
        <a
          href={getUserSummaryUrl(mergedUseraccount.id)}
          className="c-textlink"
        >
          {mergedUseraccount.id}
        </a>
      </div>
    </td>
    <td className="c-indexList_column_m">{mergedUseraccount.name}</td>
    <td className="c-indexList_column_m">{mergedUseraccount.mailaddress}</td>
    <td className="c-indexList_column_m">
      {mergedUseraccount.telephoneNumber}
    </td>
    <td className="c-indexList_column_s">
      <StatusLabel
        isPositive={!!mergedUseraccount.isActive}
        statusName={mergedUseraccount.isActiveName}
      />
    </td>
    {mergedUseraccount.approvalStatusName !== undefined ? (
      <>
        <td className="c-indexList_column_s">
          <StatusLabel
            isPositive={
              mergedUseraccount.approvalStatus ===
              LEADER_APPROVAL_STATUS_APPROVED
            }
            statusName={mergedUseraccount.approvalStatusName}
          />
        </td>
        <td className="c-indexList_column_s">{mergedUseraccount.userName}</td>
        <td className="c-indexList_column_s">
          {mergedUseraccount.studentName}
        </td>
        <td className="c-indexList_column_xs">
          {mergedUseraccount.relationship}
        </td>
        <td className="c-indexList_column_s">{mergedUseraccount.className}</td>
      </>
    ) : (
      <>
        <td className="c-indexList_column_s">{HYPHEN}</td>
        <td className="c-indexList_column_s">{HYPHEN}</td>
        <td className="c-indexList_column_s">{HYPHEN}</td>
        <td className="c-indexList_column_xs">{HYPHEN}</td>
        <td className="c-indexList_column_s">{HYPHEN}</td>
      </>
    )}
    <td className="c-indexList_column_s">
      {mergedUseraccount.certificationkeyLinkedAt
        ? dateTimeFormat(mergedUseraccount.certificationkeyLinkedAt)
        : HYPHEN}
    </td>
  </>
));

export default Useraccount;
