import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { Checkboxes, TextArea } from '@/components/shared/Form/Inputs';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

export const FieldTrip: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  return (
    <PhotographyAccordion
      title={`${photography.eventTypeName}情報`}
      id={LIST_ITEMS.event.fieldTrip.id}
    >
      <div className="t-textColor_sub">活動方法</div>
      <Checkboxes
        name="fieldTripActivityTypeIds"
        choices={constants.fieldTripActivityTypes}
        validator={validator}
      />
      <div className="t-textColor_sub">詳細</div>
      <TextArea
        name="fieldTripActivityRemark"
        validator={validator}
        placeholder="遠足の活動方法詳細を入力してください"
      />
      <div className="u-fz_s t-textColor_sub">
        ※グループ数など、活動方法の補足情報を記載してください。
      </div>
    </PhotographyAccordion>
  );
};
