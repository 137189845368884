import React from 'react';
import { Link } from 'react-router-dom';
import StatusLabel from '../../../shared/StatusLabel/App';
import { dateFormat, numberFormat } from '../../../../ts/formatTools';
import { TEvent } from './types';
import Photographers from '../Photographers';

const Event: React.FC<{
  event: TEvent;
}> = React.memo(({ event }) => {
  const equipments = [
    !!event.dressCodeName ? (
      <>
        <strong>正装: </strong>
        {event.dressCodeName}
      </>
    ) : undefined,
    !!event.strobeAvailableName ? (
      <>
        <strong>ストロボ: </strong>
        {event.strobeAvailableName}
      </>
    ) : undefined,
    !!event.monoblockStrobeName ? (
      <>
        <strong>モノブロ: </strong>
        {event.monoblockStrobeName}
      </>
    ) : undefined,
  ].filter((item) => item !== undefined);

  return (
    <div className="c-frame">
      <div className="l-flex_between_center u-mgb_xs">
        <div className="l-flex_align_base">
          <span className="c-label c-label__monotone u-fz_xs">
            ID: {event.id}
          </span>
          <h4 className="c-section_title u-fz_l">{event.name}</h4>
          {!!event.isCanceled && (
            <StatusLabel
              isPositive={false}
              statusName="撮影中止"
              additionalClassName="u-fz_xs"
            />
          )}
        </div>
        <div className="l-flex_end">
          {numberFormat(event.expectSales)}円 ({numberFormat(event.grossProfit)}
          円)
        </div>
      </div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <strong>団体: </strong>
          <Link to={`/societies/${event.societyId}`} className="c-textlink">
            {event.societyName}
          </Link>
        </li>
        <li className="l-col_12">
          <strong>
            {dateFormat(event.photographingDay)} {event.photographingTimeFrom}-
            {event.photographingTimeTo}
          </strong>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24">
          <strong>団体住所: </strong>
          {event.societyAddress}
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        {!!event.meetingPlace && (
          <li className="l-col_24">
            <strong>集合場所: </strong>
            {event.meetingPlaceName}
            {!!event.meetingPlaceMemo && ' - ' + event.meetingPlaceMemo}
          </li>
        )}
        <li className="l-col_12">
          <strong>宿泊: </strong>
          {event.isRequiredStayNightName} / <strong>車: </strong>
          {event.isRequiredCarName} / <strong>性別: </strong>
          {event.requestGenderTypeName}
        </li>
        {!!equipments.length && (
          <li className="l-col_12">
            {equipments.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 ? item : <> / {item}</>}
              </React.Fragment>
            ))}
          </li>
        )}
        {!!event.standName && (
          <li className="l-col_12">
            <strong>ひな壇: </strong>
            {event.standName}
            {!!event.standTypeName &&
              `(${event.standTypeName}${
                event.standTypeMemo ? `: ${event.standTypeMemo}` : ''
              })`}
            {!!event.standCountName &&
              `(${event.standCountName}${
                event.standCountMemo ? `: ${event.standCountMemo}` : ''
              })`}
          </li>
        )}
        {!!event.backgroundPaperName && (
          <li className="l-col_12">
            <strong>バック紙: </strong>
            {event.backgroundPaperName}
            {!!event.backgroundPaperWhoseName &&
              `(${event.backgroundPaperWhoseName})`}
            {!!event.backgroundPaperStandName &&
              `(スタンド: ${event.backgroundPaperStandName})`}
          </li>
        )}
        {!!event.additionalEquipmentName && (
          <li className="l-col_12">
            <strong>その他機材: </strong>
            {event.additionalEquipmentName}
            {!!event.additionalEquipmentMemo &&
              `(${event.additionalEquipmentMemo})`}
          </li>
        )}
        {!!event.societyRequestPhotographers.length && (
          <li className="l-col_12">
            <strong>指名: </strong>
            <Photographers photographers={event.societyRequestPhotographers} />
          </li>
        )}
      </ul>
    </div>
  );
});

export default Event;
