import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { TimeSchedule } from '@/components/pages/Events/Show/Photography/PhotographyEvent/TimeSchedule';
import { EmergencyContact } from '@/components/pages/Events/Show/Photography/PhotographyEvent/EmergencyContact';
import { TeacherInChargeOfPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/TeacherInChargeOfPhotography';
import { Christmas } from './Christmas';
import { SenNoteForPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/SenNoteForPhotography';
import { SummerFestival } from './SummerFestival';
import { DailyChildcare } from './DailyChildcare';
import { MochiPound } from './MochiPound';
import { Halloween } from './Halloween';
import { Pool } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Pool';
import { EntranceCeremony } from './EntranceCeremony';
import { ThrowingBeans } from '@/components/pages/Events/Show/Photography/PhotographyEvent/ThrowingBeans';
import { Sleepover } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Sleepover';
import { PhotographerExpense } from '@/components/pages/Events/Show/Photography/PhotographyEvent/PhotographerExpense';
import { EventType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/EventType';
import { GraduationCeremony } from './GraduationCeremony';
import { SportsDay } from './SportsDay';
import { Recital } from './Recital';
import { OtherPhotography } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography';
import { shouldRenderPhotographerExpense } from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';
import { PARENT_LIST_ITEMS } from '../listItem';
import { FieldTrip } from './FieldTrip';
import {
  shouldRenderChristmas,
  shouldRenderDailyChildcare,
  shouldRenderEntranceCeremony,
  shouldRenderFieldTrip,
  shouldRenderGraduationCeremony,
  shouldRenderHalloween,
  shouldRenderMochiPound,
  shouldRenderOther,
  shouldRenderPool,
  shouldRenderRecital,
  shouldRenderSleepover,
  shouldRenderSportsDay,
  shouldRenderSummerFestival,
  shouldRenderThrowingBeans,
} from '@/components/pages/Events/Show/Photography/Shared/shouldRenderEventContent';

type EventProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const PhotographyEvent: React.FC<EventProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.event.id}>
        {PARENT_LIST_ITEMS.event.name}
      </div>
      <hr className="u-line_plane" />
      <EventType photography={photography} />
      {shouldRenderChristmas(photography.eventTypeId) && (
        <Christmas
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {shouldRenderSummerFestival(photography.eventTypeId) && (
        <SummerFestival
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {shouldRenderDailyChildcare(photography.eventTypeId) && (
        <DailyChildcare
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {shouldRenderMochiPound(photography.eventTypeId) && (
        <MochiPound
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      {shouldRenderHalloween(photography.eventTypeId) && (
        <Halloween photography={photography} validator={validator} />
      )}
      {shouldRenderPool(photography.eventTypeId) && (
        <Pool
          photography={photography}
          methods={methods}
          constants={constants}
          validator={validator}
        />
      )}
      {shouldRenderEntranceCeremony(photography.eventTypeId) && (
        <EntranceCeremony
          photography={photography}
          constants={constants}
          validator={validator}
        />
      )}
      {shouldRenderThrowingBeans(photography.eventTypeId) && (
        <ThrowingBeans
          photography={photography}
          methods={methods}
          validator={validator}
        />
      )}
      {shouldRenderSleepover(photography.eventTypeId) && (
        <Sleepover
          photography={photography}
          validator={validator}
          constants={constants}
          methods={methods}
        />
      )}
      {shouldRenderSportsDay(photography.eventTypeId) && (
        <SportsDay
          validator={validator}
          photography={photography}
          constants={constants}
        />
      )}
      {shouldRenderRecital(photography.eventTypeId) && (
        <Recital
          validator={validator}
          constants={constants}
          photography={photography}
        />
      )}
      {shouldRenderGraduationCeremony(photography.eventTypeId) && (
        <GraduationCeremony
          photography={photography}
          constants={constants}
          validator={validator}
        />
      )}
      {shouldRenderFieldTrip(photography.eventTypeId) && (
        <FieldTrip
          photography={photography}
          constants={constants}
          validator={validator}
        />
      )}
      {shouldRenderOther(photography.eventTypeId) && (
        <OtherPhotography
          photography={photography}
          validator={validator}
          constants={constants}
        />
      )}
      <TimeSchedule photography={photography} validator={validator} />
      <EmergencyContact methods={methods} validator={validator} />
      <TeacherInChargeOfPhotography methods={methods} validator={validator} />
      {shouldRenderPhotographerExpense(photography) && (
        <PhotographerExpense validator={validator} />
      )}
      <SenNoteForPhotography validator={validator} />
    </div>
  );
};
