import { fetcher } from '@/ts/fetch';
import { TPhotography, TPhotographyResponse } from '../types';
import { TMutate } from '@/ts/useJsonApi';
import { useRef } from 'react';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError, toMessages } from '@/ts/useApi';
import clsx from 'clsx';
import styles from './style.module.scss';
import { RiExternalLinkFill, RiUpload2Fill } from 'react-icons/ri';
import { numberFormat } from '@/ts/formatTools';
import dayjs from 'dayjs';
import { PhotographyAccordion } from '../Shared/Accordion/App';
import { LIST_ITEMS, PARENT_LIST_ITEMS } from '../listItem';
import { shouldRenderDocumentInformationFromGroup } from '../Shared/shouldRenderPhotographyContent';

const MAX_UPLOAD_SIZE_KB = 7000;
const MAX_INDIVIDUAL_UPLOAD_SIZE_KB = 3000;

export const AttachedDocument: React.FC<{
  eventId: string;
  photography: TPhotography;
  mutate: TMutate<TPhotographyResponse>;
}> = ({ eventId, photography, mutate }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const showFileSelector = () => fileInput.current && fileInput.current.click();
  const sum = photography.documents.reduce((acc, cur) => acc + cur.size, 0);
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!fileInput.current) {
      return;
    }
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];
    const size = file.size;
    e.target.value = '';
    if (size > MAX_INDIVIDUAL_UPLOAD_SIZE_KB * 1000) {
      errorToast(
        `${MAX_INDIVIDUAL_UPLOAD_SIZE_KB}KBを超えるファイルはアップロードできません`
      );
      return;
    }
    if (size + sum > MAX_UPLOAD_SIZE_KB * 1000) {
      errorToast(
        `合計ファイルサイズが${MAX_UPLOAD_SIZE_KB}KBを超えるためアップロードできません`
      );
      return;
    }
    const data = new FormData();
    data.append('file', file);
    try {
      await fetcher(
        '/api/events/' + eventId + '/photographies/attached_files',
        {
          method: 'POST',
          body: data,
        }
      );
    } catch (e) {
      if (isValidationError(e)) {
        errorToast(
          `エラーが発生しました: ${
            (e.jsonMessage.validator.messages?.['file'] as string[])?.[0] || ''
          }`
        );
      } else {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
      return;
    }
    mutate(undefined, { keepCurrentData: true });
    successToast('ファイルを追加しました');
  };
  const handleDelete = async (name: string) => {
    try {
      await fetcher(
        '/api/events/' + eventId + '/photographies/attached_files/' + name,
        {
          method: 'DELETE',
        }
      );
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    mutate(undefined, { keepCurrentData: true });
    successToast('削除しました');
  };
  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.document.id}>
        {PARENT_LIST_ITEMS.document.name}
      </div>
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">
        追加された資料は自動で詳細メールに反映されます。合計{MAX_UPLOAD_SIZE_KB}
        KBまでメールに添付可能です。
      </div>
      {photography.documents.length > 0 && (
        <table className={clsx('u-mgt_s')}>
          <thead>
            <tr>
              <th className={clsx(styles.fileCellHead)}>ファイル名</th>
              <th className={clsx(styles.fileCellHead)}>追加日</th>
              <th className={clsx(styles.fileCellHead)}>サイズ</th>
              <th className={clsx(styles.fileCellHead)}></th>
            </tr>
          </thead>
          <tbody>
            {photography.documents.map((document) => (
              <tr key={document.name}>
                <td className={clsx(styles.fileCell)}>
                  <a href={document.url} target="_href" className="c-textlink">
                    {document.name}
                  </a>
                  <RiExternalLinkFill
                    size="1.6rem"
                    className={clsx('u-mgl_xs', styles.externalLinkIcon)}
                  />
                </td>
                <td className={clsx(styles.fileCell)}>
                  {dayjs(document.lastModified).format('YYYY/MM/DD HH:mm')}
                </td>
                <td className={clsx(styles.fileCell, 'u-align_right')}>
                  {numberFormat(document.size / 1000)}KB
                </td>
                <td className={clsx(styles.fileCell)}>
                  <button
                    className="c-btn_small c-btn_delete"
                    onClick={() => handleDelete(document.name)}
                  >
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="u-mgt_s">
        <table className={clsx('u-mgt_s', 'u-mgb_s')}>
          <thead>
            <tr>
              <td className={clsx(styles.fileCellHead)}>ファイル容量合計</td>
              <td className={clsx(styles.fileCell)}>
                {numberFormat(sum / 1000)}KB /{' '}
                {numberFormat(MAX_UPLOAD_SIZE_KB)}KB
              </td>
            </tr>
          </thead>
        </table>
        <button
          className="c-btn_middle c-btn_secondary u-mgb_s"
          onClick={showFileSelector}
        >
          <RiUpload2Fill />
          ファイル追加
        </button>
        <input
          className="is-hidden"
          type="file"
          ref={fileInput}
          name="file"
          onChange={onFileChange}
          accept=".jpg,.jpeg,.png,.pdf,.zip"
        ></input>
      </div>
      {shouldRenderDocumentInformationFromGroup(photography) && (
        <PhotographyAccordion
          id={LIST_ITEMS.document.documentInformationFromGroup.id}
          title={LIST_ITEMS.document.documentInformationFromGroup.name}
        >
          <div className="t-textColor_sub">未作成の資料有無</div>
          <div>
            {photography.hasUncreatedDocuments === true ? 'あり' : 'なし'}
          </div>
          <div className="t-textColor_sub u-fz_s">
            ※責任者画面の詳細登録で登録された情報を表示しています。
          </div>
        </PhotographyAccordion>
      )}
    </div>
  );
};
