import React, { ReactElement } from 'react';

const BaseModal: React.FC<{
  handleClose: () => void;
  spHeader?: ReactElement;
}> = ({ children, handleClose, spHeader }) => {
  return (
    <div className="c-pcModal c-spModal">
      <div className="c-frame c-frame_modal">
        <div className="u-pcOff c-spModal_head">
          <div className="l-flex_between_center">
            <p className="c-spModal_headName">{spHeader}</p>
            <span className="c-spModal_headBtn">
              <i className="c-icon_close u-fz_s" onClick={handleClose}></i>
            </span>
          </div>
        </div>
        <span className="c-pcModal_headBtn u-spOff">
          <i className="c-icon_close u-fz_s" onClick={handleClose} />
        </span>
        {children}
      </div>
    </div>
  );
};

export const scrollToModalTop = (): void => {
  document.querySelector('.c-frame_modal')!.scrollTo({ top: 0 });
};

export default BaseModal;
