import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { putJson } from '../../../../ts/useApi';
import {
  errorToast,
  successToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TCheck } from '../../../../types';
import { TLinkToCertificationkeyResponse } from './types';

const LinkTo: React.FC<{
  id: number;
  checks: TCheck[];
  refetch: () => void;
}> = React.memo(({ id, checks, refetch }) => {
  const add: MouseEventHandler = useCallback(async () => {
    const checkedIds = checks.filter((c) => c.checked).map((c) => c.id);
    if (
      !window.confirm(
        `${checkedIds.length}名の会員を写真閲覧キーに紐付けます。よろしいですか？`
      )
    ) {
      return;
    }
    try {
      const { data, validator } =
        await putJson<TLinkToCertificationkeyResponse>(
          `/api/certificationkeys/${id}/useraccounts`,
          {
            useraccountIds: checkedIds,
          }
        );
      if (data === 'OK') {
        successToast('紐付けを行いました');
        refetch();
      } else {
        errorToastWithValidatorMessages(
          '紐付けに失敗しました',
          validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [id, checks, refetch]);

  return (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m"
      onClick={add}
    >
      <span>選択した会員を紐付け</span>
    </button>
  );
});

const LinkToCertificationkey: React.FC<{
  id: number;
  checks: TCheck[];
  refetch: () => void;
}> = React.memo(({ ...props }) =>
  props.checks.some((c) => c.checked) ? (
    <LinkTo {...props} />
  ) : (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m is-disabled"
    >
      <span>選択した会員を紐付け</span>
    </button>
  )
);

export default LinkToCertificationkey;
