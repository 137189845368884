import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import React, { FC, useState } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { IndexResponse } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import QueryString from 'query-string';
import { TFormInputs } from '@/components/pages/PhotographerPayments/Transfers/IndexPage/types';
import { SearchForm } from '@/components/pages/PhotographerPayments/Transfers/IndexPage/SearchForm';
import { useForm } from 'react-hook-form';
import { TRANSFER_STATUS_UNCLOSED } from '@/ts/photographerPaymentTransfers/status_constants';
import { removeEmpty } from '@/ts/objectTools';

const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(convertQueryToFormData(queryString));
  if (!queryParam.status) {
    queryParam.status = TRANSFER_STATUS_UNCLOSED;
  }
  if (!queryParam.minPrice) {
    queryParam.minPrice = undefined;
  }
  if (!queryParam.maxPrice) {
    queryParam.maxPrice = undefined;
  }
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/transfers?${query}`;
};

const Errors: FC<{ errors: string[] }> = ({ errors }) => {
  if (errors.length === 0) {
    return <></>;
  }
  return (
    <div className="c-error_block">
      <ul className="c-error_list">
        {errors.map((error, index) => (
          <li className="c-error_listItem" key={index}>
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Content: FC<{
  response: IndexResponse;
  queryParams: Record<string, unknown>;
  onSubmit: (formData: TFormInputs) => void;
  mutate: () => void;
}> = ({ response, queryParams, onSubmit, mutate }) => {
  const methods = useForm<TFormInputs>({ defaultValues: queryParams });
  const statusWatch = Number(methods.watch('status'));
  const [errors, setErrors] = useState<string[]>([]);
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          { id: 2, title: '支払一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_between">
          <h1 className="l-flex_center c-page_title">
            支払一覧
            {statusWatch === TRANSFER_STATUS_UNCLOSED && '（当月）'}
          </h1>
        </div>
        <div className="u-mgt_s" />
        <Errors errors={errors} />
        <SearchForm
          response={response}
          onSubmit={onSubmit}
          methods={methods}
          statusWatch={statusWatch}
        />
        <Results
          response={response}
          mutate={mutate}
          statusWatch={statusWatch}
          methods={methods}
          setErrors={setErrors}
        />
      </div>
    </IndexPageLayout>
  );
};

export const IndexPage: FC = () => {
  const queryString = useLocation().search;
  const history = useHistory();
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));
  const onSubmit = (formData: TFormInputs) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/transfers',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };
  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as IndexResponse}
          onSubmit={onSubmit}
          queryParams={queryParams}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Content
      response={response}
      onSubmit={onSubmit}
      queryParams={queryParams}
      mutate={() => mutate(undefined, { keepCurrentData: true })}
    />
  );
};
