import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BaseModal from '../../BaseModal';
import ErrorMessages from '../../ErrorMessages';
import { RadioBoxes, TextInput } from '../../Form/Inputs';
import {
  TChoice,
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '../../Form/types';
import { TCategoryUpdateRequest } from './types';

export type TCategoryEditModalProps = {
  defaultValues: TCategoryUpdateRequest;
  phototypeName: string;
  handleClose: () => void;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: { viewflag: TChoice[] };
  handleConfirm: (data: TCategoryUpdateRequest) => Promise<void>;
  errorMessages: string[];
};

const CategoryEditModal: React.FC<TCategoryEditModalProps> = ({
  handleClose,
  defaultValues,
  validator,
  formItems,
  handleConfirm,
  errorMessages,
  phototypeName,
}) => {
  const methods = useForm<TCategoryUpdateRequest>({ defaultValues });
  return (
    <BaseModal handleClose={handleClose}>
      <ErrorMessages messages={errorMessages} />
      <FormProvider {...methods}>
        <h4 className="c-section_title">「{defaultValues.name}」を変更する</h4>
        <hr className="u-line_plane" />
        <ul className="l-flex">
          <li className="c-dataLabel">
            <label>カテゴリ名</label>
          </li>
          <li className="c-dataValue">
            <TextInput name="name" validator={validator} />
          </li>
        </ul>
        <ul className="l-flex u-mgt_s">
          <li className="c-dataLabel">
            <label>表示設定</label>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name="viewflag"
              validator={validator}
              choices={formItems.viewflag}
            />
          </li>
        </ul>
        <ul className="l-flex u-mgt_s">
          <li className="c-dataLabel">
            <label>タイプ</label>
          </li>
          <li className="c-dataValue">{phototypeName}</li>
        </ul>
        <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
          <span
            className="c-btn_large c-btn_cancel u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className="c-btn_large c-btn_primary u-mgr_s"
            onClick={methods.handleSubmit(handleConfirm)}
          >
            確定
          </span>
        </div>
      </FormProvider>
    </BaseModal>
  );
};

export default CategoryEditModal;
