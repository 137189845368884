import React from 'react';
import { useFormContext } from 'react-hook-form';

import { numberFormat } from '../../../../ts/formatTools';
import { calcTotalPayment, reCalcSubTotal } from './calc';
import { THtmlFormInputs, TSubTotal } from './types';

const SubTotal: React.FC<{
  subTotal: TSubTotal;
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    subTotal: {
      taxRate,
      totalPaymentSales,
      totalPaymentCosts,
      totalPaymentOthers,
      tax,
      totalPayment,
      totalSalesWithoutTax,
    },
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) => (
    <>
      <li className="c-section_subTitle u-mgb_s u-fz_default">
        <strong>{taxRate ? `税抜（${taxRate}%）` : '税込'}</strong>
      </li>
      {(isShowPaymentCosts || isShowPaymentSales) && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="c-incentivePayment_totalCaption">売上額合計</dt>
            <dd className="c-incentivePayment_totalItem">
              {numberFormat(totalSalesWithoutTax)}
              <small className="u-mgl_xs">円</small>
            </dd>
          </dl>
          <hr className="u-line_plane u-mgt_s u-mgb_s" />
        </li>
      )}
      {isShowPaymentCosts && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="c-incentivePayment_totalCaption">
              支払額合計<small>(原価)</small>
            </dt>
            <dd className="c-incentivePayment_totalItem">
              {numberFormat(totalPaymentCosts)}
              <small className="u-mgl_xs">円</small>
            </dd>
          </dl>
        </li>
      )}
      {isShowPaymentSales && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="c-incentivePayment_totalCaption">
              支払額合計<small>(販売手数料)</small>
            </dt>
            <dd className="c-incentivePayment_totalItem">
              {numberFormat(totalPaymentSales)}
              <small className="u-mgl_xs">円</small>
            </dd>
          </dl>
        </li>
      )}
      {isShowPaymentOthers && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="c-incentivePayment_totalCaption">その他請求小計</dt>
            <dd className="c-incentivePayment_totalItem">
              {numberFormat(totalPaymentOthers)}
              <small className="u-mgl_xs">円</small>
            </dd>
          </dl>
        </li>
      )}
      {!!taxRate && (
        <li>
          <hr className="u-line_plane u-mgt_s u-mgb_s" />
          <dl className="l-flex_between_center">
            <dt className="c-incentivePayment_totalCaption">消費税</dt>
            <dd className="c-incentivePayment_totalItem">
              {numberFormat(tax)}
              <small className="u-mgl_xs">円</small>
            </dd>
          </dl>
          <hr className="u-line_plane u-mgt_s u-mgb_s" />
        </li>
      )}
      <li>
        <dl className="l-flex_between_center">
          <dt className="c-incentivePayment_totalCaption">
            <strong>支払額合計</strong>
          </dt>
          <dd className="c-incentivePayment_totalItem">
            <strong>
              {numberFormat(totalPayment)}
              <small className="u-mgl_xs">円</small>
            </strong>
          </dd>
        </dl>
      </li>
    </>
  )
);

const Contents: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(({ subTotals, ...conditions }) => (
  <div>
    <ul className="t-bgBox_org u-mgb_s">
      {subTotals.map((subTotal) => (
        <SubTotal key={subTotal.taxRate} subTotal={subTotal} {...conditions} />
      ))}
    </ul>
    <ul className="t-bgBox_org u-mgb_s">
      <li>
        <dl className="l-flex_between_center">
          <dt className="c-incentivePayment_totalCaption">
            <strong>総支払額</strong>
          </dt>
          <dd className="c-incentivePayment_totalItem c-incentivePayment_totalItem_box">
            <strong>
              {numberFormat(calcTotalPayment(subTotals))}
              <small className="u-mgl_xs">円</small>
            </strong>
          </dd>
        </dl>
      </li>
    </ul>
  </div>
));

const Show: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo((props) => <Contents {...props} />);

const Edit: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(({ subTotals: orgSubTotals, ...conditions }) => {
  const { watch } = useFormContext<THtmlFormInputs>();
  const inputIncentivePaymentEvents = watch('incentivePaymentEvents');
  const inputIncentivePaymentOthers = watch('incentivePaymentOthers');
  const subTotals = orgSubTotals.map((orgSubTotal) =>
    reCalcSubTotal(
      orgSubTotal,
      inputIncentivePaymentEvents,
      inputIncentivePaymentOthers
    )
  );

  return <Contents subTotals={subTotals} {...conditions} />;
});

const TotalTable: React.FC<{
  canEdit: boolean;
  subTotals: TSubTotal[];
  totalPayment: number;
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(({ canEdit, ...props }) =>
  canEdit ? <Edit {...props} /> : <Show {...props} />
);

export default TotalTable;
