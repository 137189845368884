import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { FC, useState } from 'react';
import QueryString from 'query-string';
import { useJsonApi } from '@/ts/useJsonApi';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { isValidationError, renderError, toMessages } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { kanriUrl } from '@/ts/url';
import {
  NumberInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { postFormData } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { useHistory } from 'react-router-dom';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { FileUpload } from '@/components/shared/Form/FileUpload';
import { FormError } from '@/components/shared/Form/Errors';

type CreateResponse = {
  validator: TValidator;
  data: {
    consumptionTaxPercent: TChoice[];
    types: TChoice[];
    eventId: number;
    eventName: string;
    photographerId: number;
    photographerSei: string;
    photographerMei: string;
    photographerOrganizationName: string;
    photographerOrganizationId: number;
  };
};

type FormValues = {
  price: number;
  externalMemo: string;
  type: number;
  file?: File;
  consumptionTaxPercent: string | number;
};

export const Content: FC<{
  response: CreateResponse;
  eventRequestPhotographerId: number;
}> = ({ response, eventRequestPhotographerId }) => {
  const history = useHistory();
  const methods = useForm<FormValues>({
    defaultValues: {
      consumptionTaxPercent: response.data.consumptionTaxPercent.find(
        (choice) => choice?.isDisabled !== true
      )!.key,
    },
  });
  const { errors } = methods.formState;
  const initialValidator: TValidator = { rules: {} };
  const [validator, setValidator] = useState<TValidator>(initialValidator);
  const onSubmit = usePreventDuplicateCall(async (formValues: FormValues) => {
    try {
      const form = new FormData();
      form.append('price', formValues.price.toString());
      form.append('type', formValues.type.toString());
      form.append('externalMemo', formValues.externalMemo);
      form.append(
        'eventRequestPhotographerId',
        eventRequestPhotographerId.toString()
      );
      const file = formValues.file;
      if (file) {
        form.append('file', file);
      }
      await postFormData('/api/photographer_payments/expenses', form);
    } catch (e) {
      if (isValidationError(e)) {
        setValidator(e.jsonMessage.validator);
        errorToast('入力に誤りがあります');
      } else {
        setValidator(initialValidator);
        errorToast(`編集に失敗しました: ${toMessages(e)}`);
      }
      return;
    }
    successToast('編集に成功しました');
    history.push('/photographer_payments/expenses');
  });
  const handleChangeFile = (file: File) => {
    methods.setValue('file', file);
    return true;
  };
  const handleDeleteFile = () => {
    methods.setValue('file', undefined);
  };
  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '手配リスト',
            path: kanriUrl({
              action_owner_SHINSEItehailist2: 'true',
              photographingday_from: 'today',
            }),
          },
          { id: 3, title: '申請経費代理登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">申請経費代理登録</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <>
            <div className="l-center_wrap">
              <div className="c-frame">
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン</li>
                  <li className="c-dataValue">
                    <div className="l-flex l-col_wrap">
                      <div className="l-col_16">
                        <input
                          className="c-input_plane is-disabled"
                          defaultValue={`${response.data.photographerId} / ${response.data.photographerSei} ${response.data.photographerMei}`}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <div className="l-flex l-col_wrap">
                      <div className="l-col_16">
                        <input
                          className="c-input_plane is-disabled"
                          defaultValue={`${response.data.photographerOrganizationId} / ${response.data.photographerOrganizationName}`}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">イベント</li>
                  <li className="c-dataValue">
                    <div className="l-flex_center l-col_wrap">
                      <div className="l-col_16">
                        <input
                          className="c-input_plane is-disabled"
                          defaultValue={`${response.data.eventId} / ${response.data.eventName}`}
                        />
                      </div>
                      <div className="l-col_8">
                        <div className="l-flex u-mgl_s">
                          <a
                            className="c-textlink"
                            href={kanriUrl({
                              action_owner_SHOUSAIinput: 't',
                              eventno: response.data.eventId.toString(),
                            })}
                            target="_blank"
                            rel="noreferrer"
                          >
                            イベント詳細メールを見る
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="l-flex c-label_line">
                  <div className="c-dataLabel">
                    詳細区分
                    <small className="c-required">(必須)</small>
                  </div>
                  <div className="c-dataValue">
                    <RadioBoxes
                      name={`type`}
                      choices={response.data.types}
                      validator={validator}
                    />
                  </div>
                </div>
                <div className="l-flex c-label_line">
                  <div className="c-dataLabel">
                    金額(税込)
                    <small className="c-required">(必須)</small>
                  </div>
                  <div className="c-dataValue">
                    <NumberInput name={`price`} validator={validator} />
                  </div>
                </div>
                <div className="l-flex c-label_line">
                  <div className="c-dataLabel">税率</div>
                  <div className="c-dataValue">
                    <RadioBoxes
                      name={`consumptionTaxPercent`}
                      choices={response.data.consumptionTaxPercent}
                      validator={validator}
                    />
                  </div>
                </div>
                <div className="l-flex c-label_line">
                  <div className="c-dataLabel">添付ファイル</div>
                  <div className="c-dataValue">
                    <div className="l-flex l-col_wrap">
                      <div className="l-col_8">
                        <FileUpload
                          initialFileName=""
                          onChangeFile={handleChangeFile}
                          onDeleteFile={handleDeleteFile}
                          name="file"
                        />
                        <div className="t-textColor_sub">
                          詳細区分が移動費以外の場合はファイルの登録が必須です
                        </div>
                        <FormError
                          name="file"
                          errors={errors}
                          validator={validator}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-flex c-label_line">
                  <div className="c-dataLabel">
                    備考
                    <small className="c-required">(必須)</small>
                  </div>
                  <div className="c-dataValue">
                    <TextArea name={`externalMemo`} validator={validator} />
                    <div className="t-textColor_sub">※明細に表示されます</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-mgb_l" />
            <ButtonsFooter>
              <a
                className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  photographingday_from: 'today',
                })}
              >
                キャンセル
              </a>
              <button className="c-btn_large c-btn_primary c-input_submit">
                登録
              </button>
            </ButtonsFooter>
          </>
        </FormProvider>
      </form>
    </div>
  );
};

export const Create: FC = () => {
  const { eventRequestPhotographerId } = QueryString.parse(
    window.location.search
  ) as { eventRequestPhotographerId: string };
  const { data: response, error } = useJsonApi<CreateResponse>(
    `/api/photographer_payments/expenses/create?${QueryString.stringify({
      eventRequestPhotographerId,
    })}`
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Content
      response={response}
      eventRequestPhotographerId={parseInt(eventRequestPhotographerId)}
    />
  );
};
