import React, { FocusEventHandler, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makePathForSearchForm } from '@/ts/makePathForSearchForm';
import { TPagination } from './types';

type TPageNumberInputProps = {
  lastPage: number;
  currentPage: number;
  isPageLessThanOne: boolean;
  onBlur: FocusEventHandler<HTMLInputElement>;
};
const PageNumberInput: React.FC<TPageNumberInputProps> = ({
  lastPage,
  currentPage,
  isPageLessThanOne,
  onBlur,
}) => {
  const [page, setPage] = useState(String(currentPage));
  useEffect(() => {
    setPage(String(currentPage));
  }, [currentPage]);

  if (isPageLessThanOne) return <span>1</span>;
  return (
    <input
      type="number"
      min="1"
      max={lastPage}
      value={page}
      onChange={(e) => setPage(e.target.value)}
      onBlur={onBlur}
    />
  );
};

type TProps = {
  pagination: TPagination | null;
  currentPath: string;
  queryString: string;
  onChangePath?: (path: string) => void;
};
const Paginator: React.FC<TProps> = ({
  pagination,
  currentPath,
  queryString,
  onChangePath,
}) => {
  const history = useHistory();

  if (!pagination) {
    return <></>;
  }

  const { currentPage, lastPage } = pagination;

  const isFirst = currentPage === 1;
  const isLast = currentPage === lastPage;

  const firstUrl = isFirst
    ? '/'
    : makePathForSearchForm(currentPath, queryString, undefined, 1);
  const prevUrl = isFirst
    ? '/'
    : makePathForSearchForm(
        currentPath,
        queryString,
        undefined,
        currentPage - 1
      );
  const nextUrl = isLast
    ? '/'
    : makePathForSearchForm(
        currentPath,
        queryString,
        undefined,
        currentPage + 1
      );
  const lastUrl = isLast
    ? '/'
    : makePathForSearchForm(currentPath, queryString, undefined, lastPage);

  const movePage: FocusEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (!e.target.value) {
      return;
    }
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      return;
    }
    const page = Number(e.target.value);
    const path = makePathForSearchForm(
      currentPath,
      queryString,
      undefined,
      page
    );
    if (onChangePath) {
      onChangePath(path);
    } else {
      history.push(path);
    }
  };

  const onClickLink = (e: React.MouseEvent<HTMLElement>, path: string) => {
    if (!onChangePath) {
      return;
    }
    e.preventDefault();
    onChangePath(path);
  };

  return (
    <div className="u-align_center">
      <ul className="c-pagination l-box_center">
        <li className={`c-pagination_allprev${isFirst ? ' is-disabled' : ''}`}>
          <Link
            to={firstUrl}
            className="c-pagination_allprevLink"
            onClick={(e) => onClickLink(e, firstUrl)}
          ></Link>
        </li>
        <li className={`c-pagination_prev${isFirst ? ' is-disabled' : ''}`}>
          <Link
            to={prevUrl}
            className="c-pagination_prevLink"
            onClick={(e) => onClickLink(e, prevUrl)}
          ></Link>
        </li>
        <li className="c-pagination_number">
          <PageNumberInput
            currentPage={currentPage}
            lastPage={lastPage}
            isPageLessThanOne={isFirst && isLast}
            onBlur={movePage}
          />
          <span> / {lastPage}</span>
        </li>
        <li className={`c-pagination_next${isLast ? ' is-disabled' : ''}`}>
          <Link
            to={nextUrl}
            className="c-pagination_nextLink"
            onClick={(e) => onClickLink(e, nextUrl)}
          ></Link>
        </li>
        <li className={`c-pagination_lastprev${isLast ? ' is-disabled' : ''}`}>
          <Link
            to={lastUrl}
            className="c-pagination_lastprevLink"
            onClick={(e) => onClickLink(e, lastUrl)}
          ></Link>
        </li>
      </ul>
    </div>
  );
};

export default Paginator;
