const Description: React.FC = () => (
  <div className="c-frame p-salesIndex_description">
    <h3 className="c-section_subtitle">■よくある質問</h3>
    <h4>金額の定義は何ですか？</h4>
    金額　＝ 商品単価( + 期間外手数料) - 値引き
    <br />
    実質支払い金額 = 金額 + 送料(+代引き手数料) - フォトマネー使用額
    <h4>集計はリアルタイムですか？</h4>
    集計は入金日の翌日早朝に(つまり１日１回だけ)行われます。
    <h4>検索速度が遅いのですが？</h4>
    検索条件を絞って範囲を限定すれば、表示が速くなります。
    <h3 className="c-section_subtitle u-mgt_m">■よくある質問(千向け)</h3>
    <h4>なぜリアル売上とずれるのですか？</h4>
    大きな要因は、「送料」「フォトマネー」の２つです。
    <br />
    <br />
    「リアル売上」は、決済会社からの入金データを指します。
    <br />
    この入金情報はイベント単位で分解されておりません。
    <br />
    <br />
    一方、写真単位の金額を積み上げればイベント単位の「売上的なもの」を知ることができます。
    <br />
    これが「売れ行き集計」と呼ばれるものです。
    <br />
    <br />
    したがって、売れ行き集計に送料は含まれません。
    <br />
    <br />
    フォトマネー「を」買った取引は含まれていません。
    <br />
    フォトマネー「で」払った場合は、現金で払ったものとみなして集計しています。
    <h4>なぜ送料が含まれないのですか？</h4>
    送料は注文単位で発生するものなので、それをイベントごとに分解するのが難しいからです。
    <h4>なぜ、フォトマネーを買った時点で計上されないのでしょうか？</h4>
    買った時点では「どのイベントに計上するべきか」が決まらないからです。
    例えば、お客様が複数団体に所属している場合は、買った時点ではどの団体のためのフォトマネーなのか未定です。
    <h4>値引きとは？</h4>
    キャンペーンに伴う値引き額です。
    <h4>カメラマンの数について</h4>
    イベントに「確定」で手配されたカメラマンの数と、申請時の△と◎の数が使われます。
    <br />
    このため、△と◎の合計がカメラマンの数と一致しない場合があります。
    <h4>指標Xとは？</h4>
    インセンティブ用の集計です。すべてバリュー価格で販売したと仮定して集計し直した金額です。
    <h4>営業担当名検索について</h4>
    団体の営業担当名が空の場合は指定検索ができません。
  </div>
);

export default Description;
