import React from 'react';
import { kanriUrl, ownerUrl } from '@/ts/url';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';
import { isGoodsPlan } from '@/ts/societyContractConstants';
import { useAuthInfo } from '@/components/AuthProvider';
import { TMenuTitlesResponse } from '@/components/pages/Events/Show/types';

const SendPreConfirmationRequestMailLink: React.FC<{
  eventno: number;
  enabled: boolean;
}> = ({ eventno, enabled }) => {
  const handleClick = usePreventDuplicateCall(async () => {
    if (!window.confirm('事前確認の依頼メールを送信しますか？')) {
      return;
    }
    try {
      await postJson(
        `/api/events/${eventno}/send_pre_confirmation_request_mail`
      );
      successToast('事前確認メールを送信しました');
    } catch (e) {
      errorToast(
        <>
          事前確認メールの送信に失敗しました
          <br />
          {toMessages(e)}
        </>
      );
    }
  });
  if (!enabled) {
    return <span className="c-textLink">事前確認メール送信</span>;
  }
  return (
    <>
      <a
        className="c-textLink"
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
        href="/"
      >
        事前確認メール送信
      </a>
    </>
  );
};

export const EventSubMenu: React.FC<{ response: TMenuTitlesResponse }> = ({
  response,
}) => {
  const authInfo = useAuthInfo();
  const ExternalLink: React.FC<{ href: string | undefined }> = ({
    href,
    children,
  }) => (
    <a className="c-textlink" href={href}>
      {children}
    </a>
  );
  return (
    <>
      <ul className="c-submenu_linkList">
        <li className="c-submenu_linkList_item">
          <Link
            className="c-textlink"
            to={`/society_contracts/${response.societyContractId}/plans/${response.planId}/events/create?originalId=${response.eventId}`}
          >
            このイベントを元に新規登録
          </Link>
        </li>
        <li className="c-submenu_linkList_item">
          <Link
            className="c-textlink"
            to={`/society_contracts/${response.societyContractId}/plans/${response.planId}/events/create`}
          >
            イベント新規登録
          </Link>
        </li>
        <li className="c-submenu_linkList_item">
          <Link
            className="c-textlink"
            to={`/events/?societyIds[]=${response.societyId}`}
          >
            イベント一覧
          </Link>
        </li>
        <li className="c-submenu_linkList_item">
          <a
            className="c-textlink"
            href={kanriUrl({
              action_owner_PHOTOGRAPHERTROUBLElist: 'true',
              eventno: response.eventId.toString(),
            })}
          >
            カメラマントラブル一覧
          </a>
        </li>
        {response.hasEventRequest && (
          <>
            {response.doesShowTehaiListLinks && (
              <>
                <li className="c-submenu_linkList_item">
                  <ExternalLink
                    href={kanriUrl({
                      action_owner_SHINSEItehailist2: 'true',
                      'eventnos[]': response.eventId.toString(),
                    })}
                  >
                    {/*TODO:表示条件変更 */}
                    イベントの手配リスト
                  </ExternalLink>
                </li>
                <li className="c-submenu_linkList_item">
                  {response.societyId !== null && (
                    <ExternalLink
                      href={kanriUrl({
                        action_owner_SHINSEItehailist2: 'true',
                        photographingday_from: dayjs(response.photographingDay)
                          .add(-30, 'day')
                          .format('YYYY-MM-DD'),
                        photographingday_to: dayjs(response.photographingDay)
                          .add(30, 'day')
                          .format('YYYY-MM-DD'),
                        society_id: response.societyId.toString(),
                      })}
                    >
                      撮影日前後の手配リスト
                    </ExternalLink>
                  )}
                </li>
              </>
            )}
          </>
        )}
        {!!authInfo?.authOperation && (
          <li className="c-submenu_linkList_item">
            <Link
              className="c-textlink"
              to={`/events/${response.eventId}/eventflyer`}
            >
              PDFチラシ・FAX用紙作成画面
            </Link>
          </li>
        )}
        {response.canSendPreConfirmationRequestMail && (
          <li className="c-submenu_linkList_item">
            <SendPreConfirmationRequestMailLink
              eventno={response.eventId}
              enabled={response.canSendPreConfirmationRequestMail}
            />
          </li>
        )}
        {response.hasConv && (
          <>
            <li className="c-submenu_linkList_item">
              <Link
                className="c-textlink"
                to={`/events/${response.eventId}/hidden_photographs`}
              >
                非表示写真一覧
              </Link>
            </li>
            <li className="c-submenu_linkList_item">
              <Link
                className="c-textlink"
                to={`/events/${response.eventId}/bulk_hidden_photographs`}
              >
                写真一括非表示
              </Link>
            </li>
            <li className="c-submenu_linkList_item">
              <ExternalLink
                href={kanriUrl({
                  action_owner_ORDERlist: 'true',
                  eventno: String(response.eventId),
                  search: 'default',
                })}
              >
                注文一覧
              </ExternalLink>
            </li>
            <li className="c-submenu_linkList_item">
              <ExternalLink
                href={kanriUrl({
                  action_owner_EVENTanalytics: 'true',
                  eventno: String(response.eventId),
                })}
              >
                イベント分析
              </ExternalLink>
            </li>
            {response.planId && isGoodsPlan(response.planId) && (
              <li className="c-submenu_linkList_item">
                <ExternalLink
                  href={kanriUrl({
                    action_owner_EVENTorderlist: 'true',
                    eventno: String(response.eventId),
                  })}
                >
                  物販用注文一覧
                </ExternalLink>
              </li>
            )}
            <li className="c-submenu_linkList_item">
              <ExternalLink
                href={`/sales?${QueryString.stringify({
                  eventId: String(response.eventId),
                  dateFrom: dayjs(response.photographingDay).format(
                    'YYYY-MM-DD'
                  ),
                  salesDepartmentOwnerno: String(response.salesOwnerId),
                })}`}
              >
                売れ行き集計
              </ExternalLink>
            </li>
          </>
        )}
        <li className="c-submenu_linkList_item">
          {/*TODO: 権限分け必要 */}
          <a
            href={kanriUrl({
              action_owner_FOLDERviewer: 'true',
              eventno: String(response.eventId),
            })}
            className="c-textlink"
          >
            フォルダ構成
          </a>
        </li>
        <li className="c-submenu_linkList_item">
          {/*TODO: 権限分け必要 */}
          <a
            href={ownerUrl('events/' + response.eventId + '/upload')}
            className="c-textlink"
          >
            めかぶアップロード
          </a>
        </li>
      </ul>
    </>
  );
};
