import React from 'react';
import styles from './index.module.scss';
import { OrderPhoto, OriginalPhoto } from '../../Show/types';
import { kanriUrl } from '@/ts/url';
import { Link } from 'react-router-dom';
import { numberFormat } from '@/ts/formatTools';

const columns = [
  'No',
  '注文写真ID',
  'サムネイル',
  '団体',
  'イベント',
  'カテゴリ',
  '写真番号[ID]',
  'タイプ',
  'サイズ',
  '枚数',
  '単価',
  '期間外手数料',
];

type Props = {
  photos: OrderPhoto[];
  originalPhotos?: OriginalPhoto[];
};

export const PhotoTable = React.memo<Props>(function OrderTable({
  photos,
  originalPhotos,
}) {
  return (
    <div>
      <div className="l-flex_between_center u-mgb_s">
        <h4 className="c-section_title">写真情報</h4>
        <div>
          以下の写真が対象となります。 ダウンロード販売, 物販,
          一括配送分はまとめ発送に含まれません。
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={`photo-column-${index}`}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {photos.map((photo, index) => (
            <tr key={photo.id}>
              <td align="right">{index + 1}</td>
              <td>{photo.id}</td>
              <td width="100">
                <a href={photo.previewUrl} target="_blank" rel="noreferrer">
                  <img src={photo.photoUrl} alt="orderphoto" />
                </a>
              </td>
              <td>
                {!photo.isPartner ? (
                  <a
                    className="c-textOmit c-textlink"
                    href={kanriUrl({
                      action_owner_GROUPsummary: 'true',
                      groupsno: String(photo.societyId),
                    })}
                  >
                    {photo.societyName}
                  </a>
                ) : (
                  <Link to={'/societies/' + photo.societyId}>
                    {photo.societyName}
                  </Link>
                )}
              </td>
              <td>
                {photo.isPartner ? (
                  <a
                    className="c-textOmit c-textlink"
                    href={kanriUrl({
                      action_owner_EVENTsummary: 'true',
                      eventno: photo.eventId.toString(),
                    })}
                  >
                    {photo.eventName}
                  </a>
                ) : (
                  <Link to={`/events/${photo.eventId}`}>{photo.eventName}</Link>
                )}
              </td>
              <td>
                <Link
                  to={`/events/${photo.eventId}/photographs?categoryno=${photo.categoryNo}`}
                >
                  {photo.parentCategoryName && photo.parentCategoryName}
                  <br />
                  {photo.categoryName}
                </Link>
              </td>
              <td>
                {photo.photoName} [{photo.id}]
              </td>
              <td align="center">{photo.photoType}</td>
              <td align="center">{photo.photoSize}</td>
              <td align="right">{numberFormat(photo.photoSum)}枚</td>
              <td align="right">{numberFormat(photo.photoPrice)}円</td>
              <td align="right">{numberFormat(photo.photoCommission)}円</td>
            </tr>
          ))}

          {originalPhotos?.map((photo, index) => (
            <tr key={photo.id}>
              <td align="right">{photos.length + index + 1}</td>
              <td>{photo.id}</td>
              <td>
                <img src={photo.url} alt="orderphoto" />
              </td>
              <td>お手持ち写真プリント</td>
              <td>お手持ち写真プリント</td>
              <td>お手持ち写真プリント</td>
              <td>{photo.tsuidePhotographId}</td>
              <td>お手持ち写真プリント</td>
              <td align="center">{photo.photoSize}</td>
              <td align="right">{photo.quantity}枚</td>
              <td align="right">{numberFormat(photo.price)}円</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
