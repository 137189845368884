import React, { useRef, useCallback } from 'react';

import { fetcher } from '../../../../ts/useApi';
import {
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TMergedUseraccount } from '../types';
import { TUseraccountsAllResponse } from './types';
import { mergeUseraccounts } from '../mergeUseraccounts';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';

const joinForCsv = (data: (string | number)[][]) =>
  data.map((row) => row.map((item) => `"${item}"`).join(',')).join('\n');

const makeCsvString = (data: TMergedUseraccount[]): string => {
  const header = [
    'ID',
    'ユーザ名',
    'メールアドレス',
    '電話番号',
    '登録状況',
    '責任者承認状況',
    '責任者承認氏名',
    '生徒名',
    '生徒との関係',
    'クラス名',
    'キー登録日時',
  ];
  const body = data.map((u) => [
    u.id,
    u.name,
    u.mailaddress,
    u.telephoneNumber,
    u.isActiveName,
    u.approvalStatusName ?? '',
    u.userName ?? '',
    u.studentName ?? '',
    u.relationship ?? '',
    u.className ?? '',
    u.certificationkeyLinkedAt ?? '',
  ]);
  return joinForCsv([header]) + '\n' + joinForCsv(body);
};

const DownloadCsv: React.FC<{
  id: number;
  keyString: string;
  isRequiredAcceptanceByLeader: boolean;
}> = React.memo(({ id, keyString, isRequiredAcceptanceByLeader }) => {
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const download = useCallback(async () => {
    if (
      !window.confirm('会員一覧をCSVでダウンロードします。よろしいですか？')
    ) {
      return;
    }
    const fileName = `写真閲覧キー会員一覧_${keyString}.csv`;
    const tmpLink = downloadLink.current!;
    try {
      const {
        data: { useraccounts, certificationkeyUseraccounts },
        validator,
      } = await fetcher<TUseraccountsAllResponse>(
        `/api/certificationkeys/${id}/useraccounts_all`
      );
      if (validator?.hasError) {
        errorToastWithValidatorMessages(
          'CSVダウンロードに失敗しました',
          validator.messages
        );
      } else {
        const csvData = mergeUseraccounts(
          useraccounts,
          certificationkeyUseraccounts,
          isRequiredAcceptanceByLeader
        );

        tmpLink.href = makeCsvDownloadUrl(makeCsvString(csvData));
        tmpLink.download = fileName;
        tmpLink.click();
      }
    } catch (error) {
      errorToast('エラーが発生しました');
    }
  }, [id, isRequiredAcceptanceByLeader, keyString]);

  return (
    <>
      <button
        type="button"
        className="c-btn_rectangle c-btn_manage u-mgr_s p-certificationkeysShow_csvDownload_btn"
        onClick={download}
      >
        <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
        <span>CSVダウンロード</span>
      </button>
      <a href="/" download="" className="is-hidden" ref={downloadLink}>
        link
      </a>
    </>
  );
});

const DownloadUseraccountCsv: React.FC<{
  id: number;
  keyString: string;
  canDownload: boolean;
  isRequiredAcceptanceByLeader: boolean;
}> = React.memo(({ canDownload, ...props }) =>
  canDownload ? (
    <DownloadCsv {...props} />
  ) : (
    <button
      type="button"
      className="c-btn_rectangle c-btn_manage u-mgr_s p-certificationkeysShow_csvDownload_btn is-disabled"
    >
      <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
      <span>CSVダウンロード</span>
    </button>
  )
);

export default DownloadUseraccountCsv;
