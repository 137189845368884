import React from 'react';
import { HYPHEN, incentiveRateToString } from '../../../../ts/formatTools';
import Help from '../../../shared/Help/App';
import { PLAN_BOTH } from '../constants';
import { TPartner } from './types';

const IncentiveBothPlan: React.FC<{ partner: TPartner }> = React.memo(
  ({ partner }) => (
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            {partner.incentivePaymentTypeFullOfBothName}率
          </li>
          <li className="c-dataValue">
            <span>
              {incentiveRateToString(partner.displayIncentiveRateFullOfBoth)}%
            </span>
            <small className="t-textColor_sub u-mgl_s">{'(フルプラン)'}</small>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            {partner.incentivePaymentTypeSelfOfBothName}率
          </li>
          <li className="c-dataValue">
            <span>
              {incentiveRateToString(partner.displayIncentiveRateSelfOfBoth)}%
            </span>
            <small className="t-textColor_sub u-mgl_s">
              {'(セルフプラン)'}
            </small>
          </li>
        </ul>
      </li>
    </ul>
  )
);
const IncentiveNotBothPlan: React.FC<{ partner: TPartner }> = React.memo(
  ({ partner }) => (
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_24">
        <ul className="l-flex">
          <li className="c-dataLabel">{partner.incentivePaymentTypeName}率</li>
          <li className="c-dataValue">
            <span>{partner.displayIncentiveRate}%</span>
          </li>
        </ul>
      </li>
    </ul>
  )
);
const UrlLink: React.FC<{ partner: TPartner }> = React.memo(({ partner }) => (
  <a href={partner.homePageUrl} rel="noopener noreferrer" target="_blank">
    {partner.homePageUrl}
  </a>
));

const Incentive: React.FC<{ partner: TPartner }> = (props) =>
  props.partner.plan === PLAN_BOTH ? (
    <IncentiveBothPlan {...props} />
  ) : (
    <IncentiveNotBothPlan {...props} />
  );

const Partner: React.FC<{ partner: TPartner }> = React.memo(({ partner }) => (
  <div className="c-submenu_main">
    <div className="t-bgBox_brown u-pdb_s u-mgb_m">
      <div className="l-flex_center l-flex_center__isSpInput">
        <span className="c-statusLabel p-partnerSummary_label u-mgb_s">
          {partner.planName}
        </span>
        <div className="t-bgBox_white l-flex_auto u-pdt_xs u-pdb_xs u-mgb_s">
          <dl className="l-flex u-fz_s">
            <dt className="u-mgr_s">
              <strong>表示名称</strong>
              <Help>ユーザー画面での表示名</Help>
            </dt>
            <dd>{partner.viewname}</dd>
          </dl>
        </div>
      </div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">住所</li>
            <li className="c-dataValue">
              <div>
                &#12306;
                {`${partner.postcode}　${partner.prefectureName}${partner.city}${partner.address}`}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">電話番号</li>
            <li className="c-dataValue">
              <div>{partner.telephonenumber}</div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">FAX</li>
            <li className="c-dataValue">
              <div className={partner.faxnumber ? '' : 'c-nullMessage_text'}>
                {partner.faxnumber || '未登録'}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">担当営業</li>
            <li className="c-dataValue">
              <div
                className={
                  partner.salesDepartmentOwnerIsDeleted
                    ? 'c-nullMessage_text'
                    : ''
                }
              >
                {partner.salesDepartmentOwnerName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <h3>適格請求書発行事業者情報</h3>
        </li>
        <li className="l-col_12"></li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">登録番号</li>
            <li className="c-dataValue">
              <div
                className={
                  partner.businessRegistrationNumber
                    ? 'u-pre_wrap'
                    : 'u-pre_wrap c-nullMessage_text'
                }
              >
                {partner.businessRegistrationNumber
                  ? partner.businessRegistrationNumber
                  : '未登録'}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12"></li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">事業者名</li>
            <li className="c-dataValue">
              <div
                className={
                  partner.businessRegistrationTradeName
                    ? 'u-pre_wrap'
                    : 'u-pre_wrap c-nullMessage_text'
                }
              >
                {partner.businessRegistrationTradeName
                  ? partner.businessRegistrationTradeName
                  : '未登録'}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">事業者URL</li>
            <li className="c-dataValue">
              <div className={partner.homePageUrl ? '' : 'c-nullMessage_text'}>
                {partner.homePageUrl ? <UrlLink partner={partner} /> : '未登録'}
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            期間外販売
            <Help>団体追加時の期間外販売の設定</Help>
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledOutOfPeriodSalesName}</div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">データ小販売</li>
          <li className="c-dataValue">
            <div>{partner.isEnabledSellDataSName}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            スマート
            <br />
            アルバム販売
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledAlbumSalesName}</div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">FAX注文</li>
          <li className="c-dataValue">
            <div>{partner.isEnabledSellByFaxName}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            プリント時
            <br />
            写真補正
          </li>
          <li className="c-dataValue">
            <div>{partner.printingCompanyPhotoCorrectionTypeName}</div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            コンバート時
            <br />
            写真補正
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledCorrectionWhenConvertedName}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            写真の編集
            <br />
            代行
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledPhotoEditName}</div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            ログインなし
            <br />
            写真閲覧
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledCreateKeyUsableWithoutLoginName}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_24">
        <ul className="l-flex">
          <li className="c-dataLabel">
            オリジナル
            <br />
            ログイン
            <br />
            ページ作成
          </li>
          <li className="c-dataValue">
            <div>{partner.isEnabledOriginalLoginPagesName}</div>
          </li>
        </ul>
      </li>
      {/* MEMO: 運用上使っていないので一旦表示なしにしています
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">お手持ち<br/>プリント</li>
          <li className="c-dataValue">
            <div>{partner.isSalableTsuideName}</div>
          </li>
        </ul>
      </li> */}
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            SPOF写真
            <br />
            ファイル名
          </li>
          <li className="c-dataValue">
            <div>
              {partner.conditions.isTargetPlanSpof
                ? partner.spofFilenameFormat
                : HYPHEN}
            </div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">通貨</li>
          <li className="c-dataValue">
            <div>{partner.moneyTypeName}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">物販</li>
          <li className="c-dataValue">
            <div>
              {partner.conditions.isTargetPlanToSellGoods
                ? partner.isEnabledSellGoodsName
                : HYPHEN}
            </div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">物販手数料率</li>
          <li className="c-dataValue">
            <div>
              {partner.conditions.isTargetPlanToSellGoods &&
              partner.isEnabledSellGoods
                ? `${incentiveRateToString(partner.goodsIncentiveRate)}%`
                : HYPHEN}
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            セルフ
            <br />
            個配利用
          </li>
          <li className="c-dataValue">
            <div>
              {partner.isSelfDeliveryAvailableKobetsu ? '可能' : '不可'}
            </div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            セルフ個配
            <br />
            デフォルト
            <br />
            配送業者
          </li>
          <li className="c-dataValue">
            <div>{partner.selfDeliveryVendorName ?? '未選択'}</div>
          </li>
        </ul>
      </li>
    </ul>
    <Incentive partner={partner} />
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            カスタマー
            <br />
            問合わせ先
          </li>
          <li className="c-dataValue">
            <div>{partner.customerCenterMailaddress}</div>
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            カスタマー
            <br />
            センター
            <Help>主にチラシに印字されます</Help>
          </li>
          <li className="c-dataValue">
            <div className={partner.customerCenter ? '' : 'c-nullMessage_text'}>
              {partner.customerCenter ? partner.customerCenter : '未登録'}
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_24">
        <ul className="l-flex">
          <li className="c-dataLabel">メモ</li>
          <li className="c-dataValue">
            <div className="u-pre_wrap">{partner.memo}</div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
));

export default Partner;
