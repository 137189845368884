import React from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

type Props = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  marginTop?: undefined | 'small' | 'medium' | 'large';
};
export const ContentPanel = React.memo<Props>(function ContentPanel({
  title,
  children,
  marginTop,
}) {
  return (
    <div
      className={clsx(
        styles.container,
        marginTop
          ? styles[
              `margin${marginTop.charAt(0).toUpperCase() + marginTop.slice(1)}`
            ]
          : undefined
      )}
    >
      {title && typeof title === 'string' ? (
        <h4 className={styles.title}>{title}</h4>
      ) : (
        title
      )}
      {children}
    </div>
  );
});
