import React, { FC, useState } from 'react';
import {
  IndexResponse,
  TransferPayment,
} from '@/components/pages/PhotographerPayments/Transfers/IndexPage/types';
import Paginator from '@/components/shared/Paginator/App';
import { Link, useLocation } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import clsx from 'clsx';

export const UnClosedResults: FC<{
  response: IndexResponse;
  isAllChecked: boolean;
  changeAllCheckBox: (allChecked: boolean) => void;
  isCheckedRow: (payment: TransferPayment) => boolean;
  changeCheckBoxRow: (payment: TransferPayment) => (checked: boolean) => void;
  checkedIds: number[];
  setCheckedIds: (ids: number[]) => void;
  mutate: () => void;
  setErrors: (errors: string[]) => void;
}> = ({
  response,
  isAllChecked,
  changeAllCheckBox,
  isCheckedRow,
  changeCheckBoxRow,
  checkedIds,
  setCheckedIds,
  mutate,
  setErrors,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const handleClickBulkClose = async () => {
    try {
      if (!window.confirm('締め処理を行なっても良いでしょうか？')) {
        return;
      }
      setIsLoading(true);
      const idToOrganizationMap: { [id: number]: number } = {};
      response.data.payments.forEach((payment) => {
        idToOrganizationMap[payment.id] = payment.organizationId;
      });
      const organizationIds = checkedIds.map((id) => idToOrganizationMap[id]);
      await postJson(
        `/api/photographer_payments/transfers/bulk_close_monthly_books`,
        {
          photographerOrganizationIds: organizationIds,
        }
      );
      setErrors([]);
    } catch (e) {
      const error = toMessages(e);
      errorToast(`エラーが発生しました: ${error}`);
      setErrors(error.flatMap((error) => error.split('\n')));
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('締めが正常に行われました。');
    mutate();
  };
  if (isLoading) {
    return <SvgLoading />;
  }
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={location.search}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <SmallCheckBox
                checked={isAllChecked}
                setChecked={changeAllCheckBox}
              />
            </TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>適格事業者番号</TableCell>
            <TableCell>法人番号</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.payments.map((payment) => (
            <ResultRow
              key={payment.id}
              payment={payment}
              checked={isCheckedRow(payment)}
              setChecked={changeCheckBoxRow(payment)}
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={location.search}
      />
      <ButtonsFooter>
        <button
          disabled={checkedIds.length === 0}
          className={clsx(
            'c-btn_large c-btn_primary u-pdt_s u-pdb_s c-input_submit',
            {
              'is-disabled': checkedIds.length === 0,
            }
          )}
          onClick={handleClickBulkClose}
        >
          締め処理
        </button>
      </ButtonsFooter>
    </>
  );
};

const ResultRow: FC<{
  payment: TransferPayment;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ payment, checked, setChecked }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <SmallCheckBox checked={checked} setChecked={setChecked} />
        </TableCell>
        <TableCell>
          <div className="l-flex_between u-pdr_xs u-pdl_xs">
            <Link
              to={'/photographer_organizations/' + payment.organizationId}
              target="_blank"
            >{`${payment.organizationId} / ${payment.organizationName}`}</Link>
            <Link
              to={`/photographer_payments?photographerOrganizationIds[]=${payment.organizationId}`}
              className="c-textlink"
              target="_blank"
            >
              報酬管理
            </Link>
          </div>
        </TableCell>
        <TableCell>{payment.businessRegistrationNumber}</TableCell>
        <TableCell>{payment.corporateNumber}</TableCell>
      </TableRow>
    </>
  );
};
