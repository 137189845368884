import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { numberFormat } from '../../../../ts/formatTools';
import { TSalesRow } from './types';

const PHOTO_TYPE_GROUP = 2;
const MAX_DISPLAY_ROWS_THRESHOLD = 2000;

const Results: React.FC<{ rows: TSalesRow[] }> = ({ rows }) => {
  if (rows.length === 0) {
    return (
      <div className="c-error_block">
        <span className="c-error_text">結果が0件です</span>
      </div>
    );
  }
  if (rows.length > MAX_DISPLAY_ROWS_THRESHOLD) {
    return (
      <div className="c-error_block">
        <span className="c-error_text">
          結果が{MAX_DISPLAY_ROWS_THRESHOLD}
          行以上のため表示できません。csv出力をご利用ください
        </span>
      </div>
    );
  }
  const r = (
    name: string | React.ReactElement,
    value: (r: TSalesRow) => number | string | React.ReactElement | undefined,
    showSummation = true,
    displayValue?: (r: TSalesRow) => React.ReactElement
  ) => ({
    name,
    value,
    showSummation,
    displayValue,
  });
  const columns = [
    r('団体ID', (r) => r.societyId, false),
    r(
      '団体名',
      (r) => <Link to={`/societies/${r.societyId}`}>{r.societyName}</Link>,
      false
    ),
    r('契約ID', (r) => r.societyContractId, false),
    r('契約種別', (r) => r.societyContractTypeName, false),
    r('プラン', (r) => r.planName, false),
    r('イベント数', (r) => r.eventCount),
    r('イベントID', (r) => r.eventId, false),
    r(
      'イベント名',
      (r) =>
        r.eventId ? (
          <Link to={`/events/${r.eventId}`}>{r.eventName}</Link>
        ) : undefined,
      false
    ),
    r('解像度', (r) => r.resolution, false),
    r(
      '撮影日',
      (r) =>
        r.photographingday && dayjs(r.photographingday).format('YYYY/MM/DD'),
      false
    ),
    r(
      'バリュー開始日',
      (r) => r.valuestartday && dayjs(r.valuestartday).format('YYYY/MM/DD'),
      false
    ),
    r(
      'バリュー終了日',
      (r) => r.valueendday && dayjs(r.valueendday).format('YYYY/MM/DD'),
      false
    ),
    r('金額(税込)', (r) => r.salesWithTax),
    r('金額（税抜）', (r) => r.salesWithoutTax || ''),
    r('うち値引き', (r) => r.discount),
    r('指標X(税込)', (r) => r.salesForIncentiveWithTax || ''),
    r('指標X(税抜)', (r) => r.salesForIncentiveWithoutTax || ''),
    r('税率', (r) => r.taxRate, false),
    r('カメラマン', (r) => r.photographersCount),
    r('△', (r) => r.cameramanInside),
    r('◎', (r) => r.cameramanOutside),
    ...rows[0].prices.map((price, idx) =>
      r(
        <React.Fragment key={price.key + '___' + price.photoType}>
          {price.displayName
            .replace('ー', '｜')
            .split('')
            .map((s, idx) => (
              <React.Fragment key={idx}>
                {s}
                <br />
              </React.Fragment>
            ))}
          {price.photoType === PHOTO_TYPE_GROUP && <>(集)</>}
        </React.Fragment>,
        (r) => r.prices[idx].soldAmount,
        true,
        (r) =>
          r.prices[idx].price !== undefined ? (
            <>
              {numberFormat(r.prices[idx].soldAmount)}
              <br />
              {'('}
              {numberFormat(r.prices[idx].price!)}
              {')'}
            </>
          ) : (
            <>{numberFormat(r.prices[idx].soldAmount)}</>
          )
      )
    ),
    r('合計枚数', (r) => r.soldPhotoAmount),
  ].filter((column) => column.value(rows[0]) !== undefined);
  const displayValue = (column: ReturnType<typeof r>, row: TSalesRow) => {
    if (column.displayValue) {
      return column.displayValue(row);
    }
    const value = column.value(row);
    if (column.showSummation && typeof value === 'number') {
      return numberFormat(value);
    }
    return value;
  };
  return (
    <div className="c-frame c-index">
      <div className="p-salesIndex_tableWrapper">
        <table className="c-indexList p-salesIndex_table">
          <thead>
            <tr>
              {columns.map((column, idx) => (
                <th key={idx}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  {index === 0
                    ? '合計'
                    : column.showSummation
                    ? numberFormat(
                        rows
                          .map((row) => column.value(row))
                          .reduce<number>(
                            (acc, n) => (typeof n === 'number' ? acc + n : acc),
                            0
                          )
                      )
                    : undefined}
                </th>
              ))}
            </tr>
            {rows.map((row, index) => (
              <tr key={index}>
                {columns.map((column, index) => (
                  <th key={index}>{displayValue(column, row)}</th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Results;
