import BaseModal from '@/components/shared/BaseModal';
import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { getCsv } from '@/ts/fetch';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';
import { getApiPath } from '../getApiPath';
import {
  errorToastWithValidatorMessages,
  errorToast,
} from '../../../../ts/toast';
import { TCsvNgResponse } from './types';
import { TTarget } from '../types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';

type TCsvFormat = 'accounting' | 'payments';

const DownloadCsvModal: React.FC<{ closeModal: () => void; target: TTarget }> =
  React.memo(({ closeModal, target }) => {
    const [csvFormat, setCsvFormat] = useState<TCsvFormat>('accounting');
    const [targetMonth, setTargetMonth] = useState<string>(
      dayjs(new Date()).format('YYYY-MM')
    );
    const downloadLink = useRef<HTMLAnchorElement>(null);
    const Header = <>CSVエクスポート</>;
    const onChangeTargetMonth: ChangeEventHandler<HTMLInputElement> = (e) =>
      setTargetMonth(e.target.value);
    const [isDisabled, setIsDisabled] = useState(false);

    const download = usePreventDuplicateCall(async () => {
      if (!targetMonth) {
        // TODO: UI良くする
        window.alert('年月を指定してください。');
        return;
      }
      const monthKey =
        csvFormat === 'accounting' ? 'issuedOn' : 'claimedMonthTo';
      const ym = dayjs(`${targetMonth}-01`).format('YYMM');
      const fileName =
        csvFormat === 'accounting'
          ? `${ym}月計上分まとめ.csv`
          : `${ym}月末振込リスト.csv`;
      const tmpLink = downloadLink.current!;

      setIsDisabled(true);
      try {
        const res = await getCsv<TCsvNgResponse>(
          getApiPath(target, `for_${csvFormat}.csv?${monthKey}=${targetMonth}`)
        );
        if ((res as TCsvNgResponse).data === 'NG') {
          errorToastWithValidatorMessages(
            'CSVがDLできませんでした。',
            (res as TCsvNgResponse).validator.messages
          );
        } else {
          tmpLink.href = makeCsvDownloadUrl(res as string);
          tmpLink.download = fileName;
          tmpLink.click();
        }
      } catch (e) {
        errorToast('エラーが発生しました');
      } finally {
        setIsDisabled(false);
      }
    });

    return (
      <BaseModal handleClose={closeModal} spHeader={Header}>
        <div className="c-modal_textBox">
          <h4 className="c-section_title sp_off">CSVエクスポート</h4>
          <span>エクスポートデータを選択してください。</span>
          <hr className="u-line_plane" />
          <div className="u-mgb_m">
            <label className="c-radioLabel">
              <input
                type="radio"
                name="type"
                value="accounting"
                className="c-radioBtn"
                onChange={() => setCsvFormat('accounting')}
                checked={csvFormat === 'accounting'}
              />
              <span className="c-label_radioBtn">会計用まとめCSV</span>
            </label>
            <label className="c-radioLabel">
              <input
                type="radio"
                name="type"
                value="payments"
                className="c-radioBtn"
                onChange={() => setCsvFormat('payments')}
                checked={csvFormat === 'payments'}
              />
              <span className="c-label_radioBtn">振込用CSV</span>
            </label>
          </div>
          {csvFormat === 'accounting' ? (
            <div className="c-label_innerHalf">
              <div className="c-value_read u-mgb_m">
                <label className="u-mgr_s">
                  <b>対象</b>
                </label>
                <span>対象の発行月分</span>
              </div>
              <ul className="l-flex is-sp_input u-mgb_m u-pdl_s u-pdr_s">
                <li className="c-dataLabel">
                  <label>発行月</label>
                </li>
                <li className="c-dataValue">
                  <div>
                    <input
                      type="month"
                      name="targetMonth"
                      className="c-input_plane c-input_date"
                      value={targetMonth}
                      onChange={onChangeTargetMonth}
                      placeholder="発行月"
                    />
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            <div className="c-label_innerHalf">
              <div className="c-value_read u-mgb_m">
                <label className="u-mgr_s">
                  <b>対象</b>
                </label>
                <span>〜振込依頼月の20日｜振込依頼有｜振込日未入力</span>
              </div>
              <ul className="l-flex is-sp_input u-mgb_m u-pdl_s u-pdr_s">
                <li className="c-dataLabel">
                  <label>振込依頼月</label>
                </li>
                <li className="c-dataValue">
                  <div>
                    <input
                      type="month"
                      name="targetMonth"
                      className="c-input_plane c-input_date"
                      value={targetMonth}
                      onChange={onChangeTargetMonth}
                      placeholder="振込依頼月"
                    />
                  </div>
                </li>
              </ul>
            </div>
          )}
          <a href="/" download="" className="is-hidden" ref={downloadLink}>
            link
          </a>
        </div>
        <hr className="u-line_plane" />
        <p className="u-align_center">
          {isDisabled ? (
            <button
              type="button"
              className="c-btn_large c-btn_primary c-input_submit c-btn_primary__isArrow is-disabled"
            >
              エクスポート中
            </button>
          ) : (
            <button
              type="button"
              className="c-btn_large c-btn_primary c-input_submit c-btn_primary__isArrow"
              onClick={download}
            >
              エクスポートする
            </button>
          )}
        </p>
      </BaseModal>
    );
  });

const Enabled: React.FC<{ target: TTarget }> = React.memo(({ target }) => {
  const [showModal, setShowModal] = useState(false);
  const downloadCsv: MouseEventHandler = async () => {
    setShowModal(true);
  };
  const closeModal = () => setShowModal(false);

  return (
    <>
      <button
        className="c-btn_large c-btn_primary u-fz_m"
        type="button"
        onClick={downloadCsv}
      >
        <i className="c-icon_export u-mgr_xs"></i>CSVエクスポート
      </button>
      {showModal && (
        <DownloadCsvModal closeModal={closeModal} target={target} />
      )}
    </>
  );
});

const Disabled: React.FC = React.memo(() => (
  <button
    className="c-btn_large c-btn_primary u-fz_m is-disabled"
    type="button"
  >
    <i className="c-icon_export u-mgr_xs"></i>CSVエクスポート
  </button>
));

const DownloadCsv: React.FC<{ enable: boolean; target: TTarget }> = React.memo(
  ({ enable, target }) => (enable ? <Enabled target={target} /> : <Disabled />)
);

export default DownloadCsv;
