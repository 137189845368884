import React, { useState } from 'react';
import { renderError, useJsonApi } from '@/ts/useApi';
import { TPhotographer, TShowResponse } from './types';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { useParams } from 'react-router-dom';

import BasicProfile from './ProfileTables/BasicProfile';
import { DetailProfile } from './ProfileTables/DetailProfile';
import TextCopy from '../../../shared/TextCopy/App';
import './style.scss';

import { BreadCrumb } from '../../../shared/BreadCrumb';
import { EventTable } from './EventTables/EventTable';
import { MenuLink } from './MenuLink';
import SvgLoading from '../../../shared/Loading/SvgLoading';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<TShowResponse>(
    '/api/photographers/' + id
  );

  const [errorMessages] = useState<string[]>([]);

  const emptyValidator = {
    messages: {},
    hasError: false,
    rules: {},
  };

  const [validator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);

  if (error) {
    return renderError(error);
  }

  if (!response) {
    return <SvgLoading />;
  }

  const { photographer, eventsWithinOneWeek, delayedEvents, notDelayedEvents } =
    response.data;
  return (
    <div className="p-photographersShow_profileInfo l-flex l-col_wrap">
      <div className="l-col_20 p-photographersShow_full_with_isSpMode">
        <BreadCrumb
          pagesInfo={[
            { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
            { id: 2, title: 'カメラマン一覧に戻る', path: '/photographers' },
            { id: 3, title: 'カメラマンサマリ', path: undefined },
          ]}
        />
        <Title photographer={photographer} />
        <div className="l-center_wrap">
          <ErrorMessages messages={errorMessages} />
          <ApiErrors {...validator} />
          <div className="l-flex nowrap">
            <div className="c-submenu_main c-summary l-col_wrap">
              <div className="l-flex">
                <div className="l-col_12 p-photographersShow_full_with_isSpMode">
                  <BasicProfile photographer={photographer} />
                  <DetailProfile photographer={photographer} />
                </div>
                <div className="l-col_12 p-photographersShow_full_with_isSpMode">
                  <EventTable
                    title={'直近一週間の撮影予定イベント'}
                    eventType={'eventsWithinOneWeek'}
                    eventList={eventsWithinOneWeek}
                  />
                  <EventTable
                    title={'納品が遅延しているイベント'}
                    eventType={'delayedEvents'}
                    eventList={delayedEvents}
                  />
                  <EventTable
                    title={'納品待ちイベント（遅延していないもの）'}
                    eventType={'notDelayedEvents'}
                    eventList={notDelayedEvents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="l-col_4 p-photographersShow_full_with_isSpMode">
        <MenuLink cameramanId={photographer.id} photographer={photographer} />
      </div>
    </div>
  );
};

export const Title: React.FC<{
  photographer: TPhotographer;
}> = ({ photographer }) => {
  return (
    <div className="nowrap u-mgb_m">
      <div className="l-flex_start p-photographersShow_pageTitle">
        <h1 className="l-flex_center c-page_title">カメラマンサマリ</h1>
        <span className="c-statusLabel u-fz_xs">
          カメラマンID: {photographer.id}
        </span>
        <span className="c-statusLabel u-fz_xs">
          カメラマン組織ID: {photographer.photographerOrganizationId}
        </span>
        <span>
          <TextCopy
            text={`${photographer.id} ${photographer.shortName} \n ${window.location.href}`}
          />
        </span>
      </div>
    </div>
  );
};

export default App;
