import { TableRow } from '@/components/shared/Table';
import { eventLabel, MailLog } from '../../types';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

type Props = {
  logs?: MailLog[];
};

type UseMailLogReturn = {
  rows?: TableRow[];
};

export const useResultPanel = ({ logs }: Props): UseMailLogReturn => {
  const [rows, setRows] = useState<TableRow[]>();

  useEffect(() => {
    if (!logs) {
      return;
    }

    const newRows = logs.map((log) => {
      return [
        format(new Date(log.createdAt), 'yyyy/MM/dd HH:mm:ss'),
        log.email,
        eventLabel[log.event],
        log.response || '',
        log.reason || '',
        log.status || '',
        log.ip || '',
        log.sgEventId,
      ];
    });

    setRows(newRows);
  }, [logs]);

  return { rows };
};
