import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { isKeyTypeGeneral } from '../../../shared/Certificationkeys/constants';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { TShowResponse } from './types';
import Certificationkey from './Certificationkey';
import Useraccounts from './Useraccounts';
import KeyBundles from './KeyBundles';
import MailForm from './MailForm';
import './style.scss';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TShowResponse>(
    '/api/certificationkeys/' + id
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const {
    data: { certificationkey, keyBundles, useraccounts, sendMailInformation },
    sendMailValidationRules,
    useraccountsPagination,
    conditions,
  } = data;

  const isKeyGeneral = isKeyTypeGeneral(certificationkey.type);

  return (
    <div className="c-summary p-certificationkeysShow">
      <Link className="c-page_back" to="/certificationkeys">
        一覧に戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">写真閲覧キーサマリ</h1>
      <Certificationkey
        certificationkey={certificationkey}
        conditions={conditions}
      />

      <Useraccounts
        id={certificationkey.id}
        keyString={certificationkey.key}
        numberOfUsers={certificationkey.numberOfUsers}
        certificationkeyUseraccounts={certificationkey.useraccounts}
        useraccounts={useraccounts}
        pagination={useraccountsPagination}
        canShow={conditions.canShowUseraccounts}
        isRequiredAcceptanceByLeader={conditions.isRequiredAcceptanceByLeader}
      />

      <div className="l-flex_between l-flex_between__isSpInput">
        {isKeyGeneral && (
          <div className="p-certificationkeysShow_keyBundles">
            <KeyBundles
              id={certificationkey.id}
              keyBundles={keyBundles}
              canAddToKeyBundles={conditions.canAddToKeyBundles}
            />
          </div>
        )}
        <div
          className={`p-certificationkeysShow_mailForm${
            isKeyGeneral ? '' : ' p-certificationkeysShow_mailForm__fullWidth'
          }`}
        >
          <MailForm
            id={certificationkey.id}
            validationRules={sendMailValidationRules}
            {...sendMailInformation}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
