import React from 'react';

const GroupHeader: React.FC<{
  isBulkDelivery: number;
  planName: string;
  groupName: string;
}> = React.memo(({ isBulkDelivery, planName, groupName }) => (
  <div className="l-flex_center u-mgb_s">
    <strong className="u-mgr_s">{planName}プラン</strong>
    <strong className="u-mgr_s">{groupName}</strong>
    {!!isBulkDelivery && (
      <span className="c-label c-incentivePayment_label__bulkDelivery">
        一括配送対象
      </span>
    )}
  </div>
));

export default GroupHeader;
