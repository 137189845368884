import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  TValidatorResponse,
  TOnlyValidationRuleResponse,
} from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';
import Prices from './Prices';
import { findPriceIndex } from './SaveForm';

const PriceInputTable: React.FC<{
  taxRate: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
  isAutoCalcAssociated: boolean;
}> = React.memo(
  ({
    taxRate,
    validator,
    formItems: { userTypes, photoTypes, photoSizes },
    isAutoCalcAssociated,
  }) => {
    const { control, watch } = useFormContext<TFormInputs>();
    const { fields: priceFields } = useFieldArray({
      name: 'prices',
      control,
    });
    const showTaxIncludedPrice = watch('isCalculatedByTaxIncludedPrice') === 0;
    return (
      <table className="c-priceTable">
        <thead>
          <tr>
            <th colSpan={2}>区分</th>
            {photoSizes.map((photoSize) => (
              <th key={photoSize.photosizemasterno}>{photoSize.nameJp}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userTypes.map((userType) =>
            photoTypes.map((photoType, index) => (
              <tr key={photoType.phototypemasterno}>
                {index === 0 && <td rowSpan={2}>{userType.name}</td>}
                <td>{photoType.phototypename}</td>
                {photoSizes.map((photoSize) => {
                  const priceIndex = findPriceIndex(
                    priceFields,
                    userType.usertypemasterno,
                    photoType.phototypemasterno,
                    photoSize.photosizemasterno
                  );
                  const price = watch(`prices.${priceIndex}.price`);
                  const isEnabled = watch(`prices.${priceIndex}.isEnabled`);
                  return (
                    <td
                      className="p-priceIndex_inputmode u-align_right"
                      key={photoSize.photosizemasterno}
                    >
                      <Prices
                        name={`prices.${priceIndex}.price`}
                        isEnabled={isEnabled}
                        price={price}
                        validator={validator}
                        isAutoCalcAssociated={isAutoCalcAssociated}
                        userTypeId={userType.usertypemasterno}
                        taxRate={taxRate}
                        showTaxIncludedPrice={showTaxIncludedPrice}
                      />
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  }
);

export default PriceInputTable;
