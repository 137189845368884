import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import React, { FC } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { IndexResponse, TFormInputs } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import dayjs from 'dayjs';
import QueryString from 'query-string';
import { SearchForm } from './SearchForm';
import { TMutate } from '@/ts/useJsonApi';

const Content: FC<{
  response: IndexResponse;
  queryParams: Record<string, unknown>;
  onSubmit: (formData: TFormInputs) => void;
  mutate: TMutate<IndexResponse>;
}> = ({ response, queryParams, onSubmit, mutate }) => {
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          { id: 3, title: 'イベント外経費一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_between">
          <h1 className="l-flex_center c-page_title">イベント外経費一覧</h1>
          <Link
            className="c-btn_rectangle c-btn_primary u-mgb_s"
            to="/photographer_payments/non_event_related_expenses/create"
          >
            新規登録
          </Link>
        </div>
        <SearchForm
          response={response}
          onSubmit={onSubmit}
          queryParams={queryParams}
        />
        {!response.validator?.hasError && (
          <Results
            response={response}
            editValidator={response.editValidator}
            mutate={mutate}
            queryParams={queryParams}
          />
        )}
      </div>
    </IndexPageLayout>
  );
};
const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(convertQueryToFormData(queryString));
  if (Object.keys(queryParam).filter((name) => name !== 'page').length === 0) {
    return {
      tradingDateStartAt: dayjs().format('YYYY-MM-01'),
    };
  }
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/non_event_related_expenses?${query}`;
};

export const IndexPage: FC = () => {
  const queryString = useLocation().search;
  const history = useHistory();
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));
  const onSubmit = (formData: TFormInputs) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/non_event_related_expenses',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };
  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as IndexResponse}
          onSubmit={onSubmit}
          queryParams={queryParams}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Content
      response={response}
      onSubmit={onSubmit}
      queryParams={queryParams}
      mutate={mutate}
    />
  );
};
