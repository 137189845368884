import React from 'react';
import {
  NumberInput,
  Checkboxes,
  TextInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormItems } from './types';

type TTabCommonProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  showBundles?: boolean;
};

const TabCommon: React.FC<TTabCommonProps> = React.memo(
  ({ validator, formItems, showBundles }) => {
    const { keytypes } = formItems;
    const bundles = [
      { key: 'all', value: 'すべて' },
      { key: 'bundled', value: '済' },
      { key: 'notBundled', value: '未実施' },
    ];

    return (
      <ul className="t-bgBox_gray u-mgb_m l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
        <li className="l-col_6">
          <ul className="l-flex">
            <li className="c-dataLabel c-label_line">
              <label>写真閲覧キー</label>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="certificationkey"
                placeholder="8-8888-8888"
                validator={validator}
              />
            </li>
          </ul>
        </li>
        {!showBundles && (
          <li className="l-col_6">
            <ul className="l-flex">
              <li className="c-dataLabel c-label_line">
                <label>キー束番号</label>
              </li>
              <li className="c-dataValue">
                <NumberInput
                  name="bundleId"
                  placeholder="999999"
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        )}
        <li className={showBundles ? 'l-col_6' : 'l-col_12'}>
          <ul className="l-flex">
            <li className="c-dataLabel c-label_line">
              <label>キー種類</label>
            </li>
            <li className="c-dataValue">
              <Checkboxes
                name="keytypes"
                choices={keytypes}
                validator={validator}
              />
            </li>
          </ul>
        </li>
        {showBundles && (
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel c-label_line">
                <label>紐付け</label>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name="bundles"
                  validator={validator}
                  choices={bundles}
                />
              </li>
            </ul>
          </li>
        )}
      </ul>
    );
  }
);

export default TabCommon;
