import {
  TConstants,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { Alert } from '@/components/shared/Alert';
import { TextInput, TimeInput } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import styles from './index.module.scss';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { ownerUrl } from '@/ts/url';
import { LIST_ITEMS, PARENT_LIST_ITEMS } from '../listItem';
import { shouldRenderPhotographingDayCount } from '../Shared/shouldRenderPhotographyContent';
import { REQUEST_OPTION_ID_TIME_UNDEFINED } from '../const';
import { WarningIcon } from '../Shared/WarningIcon';

type BasicInformationProps = {
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const BasicInformation: React.FC<BasicInformationProps> = ({
  photography,
  constants,
  validator,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.basic.id}>
        {PARENT_LIST_ITEMS.basic.name}
      </div>
      <hr className="u-line_plane" />
      <PhotographyAccordion
        title={LIST_ITEMS.basic.eventName.name}
        id={LIST_ITEMS.basic.eventName.id}
      >
        {photography.eventName}
        <div className="t-textColor_sub">
          ※申請サマリの登録内容を表示しています。
        </div>
      </PhotographyAccordion>
      <PhotographyAccordion
        title={LIST_ITEMS.basic.requestOptions.name}
        id={LIST_ITEMS.basic.requestOptions.id}
      >
        <div className="l-flex_center">
          {constants.requestOptionTypes.map((option) => (
            <span
              className={clsx(
                'c-statusLabel',
                photography.requestOptions?.includes(Number(option.key))
                  ? 'c-statusLabel__positive'
                  : 'c-statusLabel__negative'
              )}
              key={option.key}
            >
              <div className="l-flex_center">
                {option.key === REQUEST_OPTION_ID_TIME_UNDEFINED &&
                  photography.requestOptions?.includes(Number(option.key)) && (
                    <WarningIcon />
                  )}
                {option.value}
              </div>
            </span>
          ))}
        </div>
        {photography.requestOptions?.includes(
          REQUEST_OPTION_ID_TIME_UNDEFINED
        ) && (
          <div className="t-textColor_danger u-mgt_s">
            ※撮影時間の確認を行い、申請サマリから情報を登録してください。
          </div>
        )}

        <div className="t-textColor_sub u-mgt_s">
          ※申請サマリの登録内容を表示しています。
        </div>
        <Alert type="error" title="申請オプションについて">
          <p>
            申請オプションの変更は申請サマリ編集により対応してください。
            <br />
            「アルバム用撮影」を選択している場合は登録可能な項目が変わりますので、変更する際はご留意ください。
          </p>
          <a
            className="c-btn c-btn_middle_wide c-btn_black"
            href={ownerUrl(`events/requests/${eventId}`)}
            target="_blank"
            rel="noreferrer"
          >
            申請サマリ編集
          </a>
        </Alert>
      </PhotographyAccordion>
      {shouldRenderPhotographingDayCount(photography) && (
        <PhotographyAccordion
          title={LIST_ITEMS.basic.photographingDayCount.name}
          id={LIST_ITEMS.basic.photographingDayCount.id}
        >
          {photography.photographingDayCount ? (
            <>{photography.photographingDayCount}日間</>
          ) : (
            <>未確認</>
          )}
          <div className="t-textColor_sub">
            ※申請サマリの登録内容を表示しています。
          </div>
        </PhotographyAccordion>
      )}
      <PhotographyAccordion
        title={LIST_ITEMS.basic.photographingDay.name}
        id={LIST_ITEMS.basic.photographingDay.id}
      >
        {photography.photographingDay ? (
          <>{dayjs(photography.photographingDay).format('YYYY/MM/DD（ddd）')}</>
        ) : (
          <>未定</>
        )}
        <div className="t-textColor_sub">
          ※申請サマリの登録内容を表示しています。
        </div>
      </PhotographyAccordion>
      <PhotographyAccordion
        title={LIST_ITEMS.basic.photographingTime.name}
        id={LIST_ITEMS.basic.photographingTime.id}
      >
        {photography.photographingStartTime &&
        photography.photographingEndTime ? (
          <>
            {photography.photographingStartTime}〜
            {photography.photographingEndTime}
          </>
        ) : (
          <>未定</>
        )}
        <div className="t-textColor_sub">
          ※申請サマリの登録内容を表示しています。
        </div>
      </PhotographyAccordion>
      <PhotographyAccordion
        title={LIST_ITEMS.basic.meetingTime.name}
        id={LIST_ITEMS.basic.meetingTime.id}
      >
        {photography.automaticallyCalculatedMeetingTime ? (
          <>{photography.automaticallyCalculatedMeetingTime}</>
        ) : (
          <>未定</>
        )}
        <div className="t-textColor_sub">
          ※団体側では撮影開始時間から自動計算した値を表示しています。
        </div>
        <Alert type="error" title="集合時間の変更について">
          <p>
            自動計算値より時間の変更依頼があった場合は「変更時間」と「変更理由」を記入してください。
            <br />
            <span className={styles.meetingTimeNotice}>
              ※アルバム撮影＋機材（モノブロ、バック紙、スタンド）の場合は必要に応じて30分〜1時間前に変更してください。
            </span>
          </p>
        </Alert>
        <div>
          <ul className={styles.changeTimeWrap}>
            <li>
              <div className="t-textColor_sub">変更時間</div>
              <TimeInput name="manualMeetingTime" validator={validator} />
            </li>
            <li>
              <div className="t-textColor_sub">変更理由</div>
              <TextInput name="meetingTimeChangeReason" validator={validator} />
            </li>
          </ul>
        </div>
      </PhotographyAccordion>
    </div>
  );
};
