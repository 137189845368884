import dayjs from 'dayjs';

export const isWeekend = (date: dayjs.Dayjs): boolean => {
  return date.day() === 0 || date.day() === 6;
};

export const isHoliday = (
  date: dayjs.Dayjs,
  holidayList: string[]
): boolean => {
  const dateString = date.format('YYYY-MM-DD');
  return holidayList.includes(dateString);
};

export const afterBusinessDay = (
  startDate: string,
  holidayData: Record<string, string>,
  afterDays = 0
): string => {
  const initialDate = dayjs(startDate);
  let currentDate = initialDate;
  let countDays = 0;

  while (countDays < afterDays) {
    currentDate = currentDate.add(1, 'day');
    if (
      !isWeekend(currentDate) &&
      !isHoliday(currentDate, Object.keys(holidayData))
    ) {
      countDays++;
    }
  }

  return currentDate.format('YYYY-MM-DD');
};

export const beforeBusinessDay = (
  startDate: string,
  holidayData: Record<string, string>,
  beforeDays = 0
): string => {
  const initialDate = dayjs(startDate);
  let currentDate = initialDate;
  let countDays = 0;

  while (countDays < beforeDays) {
    currentDate = currentDate.subtract(1, 'day');

    if (
      !isWeekend(currentDate) &&
      !isHoliday(currentDate, Object.keys(holidayData))
    ) {
      countDays++;
    }
  }

  return currentDate.format('YYYY-MM-DD');
};
