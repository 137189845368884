import { UseFormReturn } from 'react-hook-form';
import {
  EventRequestPhotographer,
  TEditFormInputs,
} from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';
import { useEffect } from 'react';

export const useInputPriceFromTransportationDistance = (
  methods: UseFormReturn<TEditFormInputs>,
  eventRequestPhotographer: EventRequestPhotographer,
  perKmGasolinePrice: number
): void => {
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (
        name === undefined ||
        type !== 'change' ||
        !name.includes('paymentTransportationDistance')
      ) {
        return;
      }
      const index = Number(name.split('.')[1]);
      const paymentTransportationDistance = methods.getValues(
        `photographerPayments.${index}.paymentTransportationDistance`
      );
      if (paymentTransportationDistance === null) {
        return;
      }
      methods.setValue(
        `photographerPayments.${index}.paymentPrice`,
        Math.round(paymentTransportationDistance * perKmGasolinePrice)
      );
    });
    return () => subscription.unsubscribe();
  }, [methods, eventRequestPhotographer, perKmGasolinePrice]);
};
