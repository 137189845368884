import React from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';

import GeneralPdfDetail from '../../../shared/IncentivePayments/Show/PdfDetails';
import { TShowPdfData, TSubmit } from './types';
import Header from './Header';

const PdfDetails = React.memo<{
  validator: TValidatorResponse;
  data: TShowPdfData;
  submit: TSubmit;
}>(({ data, validator, submit }) => {
  const {
    partner,
    incentivePayment,
    conditions: { canEdit },
    tradingRange,
  } = data;
  const PartnerHeader = (
    <Header
      type="PDF"
      canEdit={canEdit}
      incentivePaymentId={incentivePayment.id}
      partnerName={partner.name}
      status={incentivePayment.status}
      statusName={incentivePayment.statusName}
      issuedOn={incentivePayment.issuedOn}
      isShowCsvDownloadLink={false}
      businessRegistrationNumber={partner.businessRegistrationNumber}
      businessRegistrationTradeName={partner.businessRegistrationTradeName}
      tradingStartDay={tradingRange.startDay}
      tradingEndDay={tradingRange.endDay}
    />
  );

  return (
    <GeneralPdfDetail
      target="PARTNER"
      Header={PartnerHeader}
      validator={validator}
      data={data}
      submit={submit}
    />
  );
});

export default PdfDetails;
