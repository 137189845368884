import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import Paginator from '@/components/shared/Paginator/App';

import { TFormInputs, TGroup, TIndexResponse } from './types';
import SearchForm from './SearchForm';
import SearchResultItem from './SearchResultItem';
import './style.scss';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const SearchResultList: React.FC<{ groups: TGroup[] }> = (props) => (
  <table className="c-indexList">
    <thead>
      <tr>
        <th>パートナー</th>
        <th>団体</th>
        <th>プラン</th>
        <th>住所</th>
        <th>担当営業</th>
        <th>詳細対応者</th>
        <th>稼働状況</th>
        <th>認証</th>
        <th>事前確認</th>
        <th>掲載日固定</th>
        <th>期間外販売</th>
        <th>配送</th>
        <th>パートナー名表示</th>
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      {props.groups.map((g) => (
        <SearchResultItem key={g.id} group={g} />
      ))}
    </tbody>
  </table>
);

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;

  const { data, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/groups/',
    queryString
  );
  const queryParams = convertQueryToFormData(queryString);

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      delete formData.globalSearch;
      const newPath = makePathForSearchForm('/groups/', queryString, formData);

      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const { data: groups, validator, formItems, pagination } = data;

  return (
    <div className="c-index p-groupIndex">
      <h1 className="l-flex_center u-mgb_m c-page_title">パートナー団体一覧</h1>
      <div className="l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath="/groups"
                  queryString={queryString}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !groups.length ? (
            <HasNoResult />
          ) : (
            <SearchResultList groups={groups} />
          )}
          <Paginator
            pagination={pagination}
            currentPath="/groups"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
