export function stripNewLineForBlankTemplate(
  strings: TemplateStringsArray,
  ...exps: unknown[]
): string {
  let result = strings[0];
  exps.forEach((exp, i) => {
    const stringForExp = `${exp}`;
    if (stringForExp === '' && result.endsWith('\n')) {
      result = result.replace(/[\n]+$/, '');
    }
    result += stringForExp;
    result += strings[i + 1];
  });
  return result;
}
