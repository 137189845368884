import React, { ReactEventHandler, useCallback, useMemo } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  DateInput,
  GroupedMultiSelect,
  RadioBoxes,
  MultiSelect,
  Checkboxes,
  NumbersInput,
} from '@/components/shared/Form/Inputs';
import {
  TValidatorResponse,
  TGroupedChoice,
} from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';

const RANK_NONE = 0;
const RANK_TRAINEE = 1;

// TODO: component.8122.jpで共通化
const AreaSelect: React.FC<{ prefectures: TGroupedChoice[] }> = React.memo(
  ({ prefectures }) => {
    const { setValue, getValues } = useFormContext<TFormInputs>();

    const areaPhotographerIds = prefectures.map((g) => ({
      areaName: g.groupName,
      prefectureIds: g.choices.map((c) => c.key),
    }));
    const otherAreas = areaPhotographerIds.filter((a) => a.areaName !== '関東');
    const areas: { areaName: string; prefectureIds: (string | number)[] }[] =
      useMemo(
        () => [
          areaPhotographerIds.find((a) => a.areaName === '関東') ?? {
            areaName: '関東',
            prefectureIds: [],
          },
          {
            areaName: '関東以外',
            prefectureIds: otherAreas.flatMap((a) => a.prefectureIds),
          },
          ...otherAreas,
        ],
        [areaPhotographerIds, otherAreas]
      );

    const onChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
      (event) => {
        const selectedPhotographerIds = areas.find(
          (a) => a.areaName === event.target.value
        )!.prefectureIds;
        const prefectureIds = [
          ...(getValues('prefectures') ?? []),
          ...selectedPhotographerIds,
        ] as unknown as number[];
        setValue('prefectures', prefectureIds);
      },
      [areas, setValue, getValues]
    );

    return (
      <select className="c-input_select__xxsmall u-mgt_xs" onChange={onChange}>
        <option>- エリア -</option>
        {areas.map(({ areaName }) => (
          <option key={areaName} value={areaName}>
            {areaName}
          </option>
        ))}
      </select>
    );
  }
);

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit, setValue, getValues } = methods;

    const clearDates = useCallback(
      (
        from: 'fromDate' | 'requestMailSentDateFrom',
        to: 'toDate' | 'requestMailSentDateTo'
      ) => {
        setValue(from, '');
        setValue(to, '');
      },
      [setValue]
    );
    const clearDateRange: ReactEventHandler = useCallback(
      (e) => {
        e.preventDefault();
        clearDates('fromDate', 'toDate');
      },
      [clearDates]
    );
    const clearRequestDateRange: ReactEventHandler = useCallback(
      (e) => {
        e.preventDefault();
        clearDates('requestMailSentDateFrom', 'requestMailSentDateTo');
      },
      [clearDates]
    );
    const onClickSelectAllButTwo: ReactEventHandler = useCallback(
      (e) => {
        e.preventDefault();
        setValue('ranks', [
          ...(getValues('ranks') ?? []),
          ...(formItems.ranks
            .filter((r) => r.key !== RANK_TRAINEE && r.key !== RANK_NONE)
            .map((r) => r.key) as number[]),
        ]);
      },
      [setValue, getValues, formItems.ranks]
    );
    const {
      prefectures,
      ranks,
      gender,
      hascar,
      staynightflag,
      workingdatetypes,
      agreementtypes,
      hideStatusReserve,
      hidePhotographerOnHoliday,
      requestStatuses,
      photographingStatuses,
    } = formItems;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_16">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>期間</label>
                      <small className="c-required">(必須)</small>
                      <p>
                        <a
                          className="c-textlink_clear"
                          onClick={clearDateRange}
                          href="/"
                        >
                          期間をクリア
                        </a>
                      </p>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="fromDate"
                            placeholder="期間開始日"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="toDate"
                            placeholder="期間終了日"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>性別</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="gender"
                        choices={gender}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                      <AreaSelect prefectures={prefectures} />
                    </li>
                    <li className="c-dataValue">
                      <GroupedMultiSelect
                        name="prefectures"
                        choices={prefectures}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>カメラマンランク</label>
                      <p>
                        <a
                          className="c-textlink_clear"
                          onClick={onClickSelectAllButTwo}
                          href="/"
                        >
                          なし・研修以外
                        </a>
                      </p>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="ranks"
                        choices={ranks}
                        placeholder="クリックで候補表示（複数選択可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>宿泊可否</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="staynightflag"
                        choices={staynightflag}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>撮影可能日</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="workingdatetypes"
                        choices={workingdatetypes}
                        placeholder="クリックで候補表示（複数選択可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>契約タイプ</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="agreementtypes"
                        choices={agreementtypes}
                        placeholder="クリックで候補表示（複数選択可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>車の有無</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="hascar"
                        choices={hascar}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_16">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>依頼送信日</label>
                      <p>
                        <a
                          className="c-textlink_clear"
                          onClick={clearRequestDateRange}
                          href="/"
                        >
                          期間をクリア
                        </a>
                      </p>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="requestMailSentDateFrom"
                            placeholder="依頼送信日開始日"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="requestMailSentDateTo"
                            placeholder="依頼送信日終了日"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>非表示にする</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="hideStatusReserve"
                        choices={hideStatusReserve}
                        validator={validator}
                      />
                      <Checkboxes
                        name="hidePhotographerOnHoliday"
                        choices={hidePhotographerOnHoliday}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>イベントID</label>
                    </li>
                    <li className="c-dataValue">
                      <NumbersInput
                        name="eventIds"
                        placeholder="半角スペース区切りで複数入力可"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>アサイン状態</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="requestStatuses"
                        choices={requestStatuses}
                        placeholder="クリックで候補表示（複数選択可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_8">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>状態</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="photographingStatuses"
                        choices={photographingStatuses}
                        placeholder="クリックで候補表示（複数選択可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
