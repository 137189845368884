import { useCallback, useEffect, useRef, useState } from 'react';
import { fetcher } from './fetch';

export type TMutate<DataType> = (
  f?: ((d: DataType | undefined) => DataType | undefined) | undefined,
  option?: { keepCurrentData?: boolean }
) => void;

export type TUseJsonApiResponse<DataType, Error> = {
  data: DataType | undefined;
  error: Error | undefined;
  mutate: TMutate<DataType>;
};

export function useJsonApi<DataType>(
  path: string
): TUseJsonApiResponse<DataType, Error> {
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const cleanup = useRef<() => void | undefined>();
  const callFetcher = useCallback(async (path: string) => {
    let cancelled = false;
    if (cleanup.current) {
      cleanup.current();
    }
    cleanup.current = () => {
      cancelled = true;
    };
    const response = (await fetcher(path).catch((e) => {
      if (!cancelled) {
        setError(e);
      }
    })) as DataType;
    if (!cancelled) {
      setData(response);
    }
  }, []);
  useEffect(() => {
    setData(undefined);
    setError(undefined);
    callFetcher(path);
  }, [path, callFetcher]);
  const mutate = useCallback(
    (f, option) => {
      if (f) {
        setData((d) => f(d));
      } else {
        if (!option?.keepCurrentData) {
          setData(undefined);
          setError(undefined);
        }
        callFetcher(path);
      }
    },
    [path, callFetcher]
  );
  return { data, error, mutate };
}
