export function initAuth(): void {
  const params = new URLSearchParams({
    // eslint-disable-next-line no-restricted-globals
    redirect_url: location.pathname + location.search,
  });
  // eslint-disable-next-line no-restricted-globals
  location.href = '/auth/?' + params.toString();
}

interface LaravelJsonError {
  message?: string;
}
export class Not200Error<ErrorType = LaravelJsonError> extends Error {
  status: number;
  jsonMessage: ErrorType;
  constructor(status: number, jsonMessage: ErrorType) {
    super();
    this.status = status;
    this.jsonMessage = jsonMessage;
  }
}

const getCsrfToken = () =>
  document.cookie
    .split(';')
    .find((s) => s.trim().startsWith('XSRF-TOKEN'))
    ?.trim()
    .split('=')[1];

const fetchWrap = (
  key: string,
  options?: RequestInit | undefined
): Promise<Response> => {
  const csrfToken = getCsrfToken();
  options = options || {};
  options.headers = Object.assign(
    {},
    options?.headers || {},
    {
      Accept: 'application/json',
    },
    csrfToken ? { 'X-CSRF-TOKEN': csrfToken } : {}
  );
  options.credentials = options.credentials || 'same-origin';
  return fetch(key, options).then((response) => {
    if (response.status === 401) {
      initAuth();
      return new Promise(() => undefined);
    } else if (response.status !== 200) {
      return (async () => {
        let jsonMessage = undefined;
        try {
          jsonMessage = await response.json();
        } catch {}
        return Promise.reject(
          new Not200Error(response.status, jsonMessage as LaravelJsonError)
        );
      })();
    }
    return response;
  });
};

export const fetcher = <DataType>(
  key: string,
  options?: RequestInit | undefined
): Promise<DataType> =>
  fetchWrap(key, options).then((response: Response) => response.json());

export const getCsv = <NgDataType>(url: string): Promise<NgDataType | string> =>
  fetchWrap(url, {
    method: 'GET',
  }).then((response) =>
    response.headers.get('Content-Type')?.startsWith('text/csv')
      ? response.text()
      : response.json()
  );

export const fetchZip = <NgDataType>(
  key: string,
  options?: RequestInit | undefined
): Promise<NgDataType | Blob> =>
  fetchWrap(key, options).then((response) =>
    response.headers.get('Content-Type')?.startsWith('application/zip')
      ? response.blob()
      : response.json()
  );

export const fetchPdf = <NgDataType>(
  key: string,
  options?: RequestInit | undefined
): Promise<NgDataType | Blob> =>
  fetchWrap(key, options).then((response) =>
    response.headers.get('Content-Type')?.startsWith('application/pdf')
      ? response.blob()
      : response.json()
  );

const fetchJson = <DataType>(
  method: string,
  url: string,
  data: unknown = {}
): Promise<DataType> =>
  fetcher<DataType>(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

export const postJson = <DataType>(
  url: string,
  data: unknown = {}
): Promise<DataType> => fetchJson('POST', url, data);

export const postFormData = <DataType>(
  url: string,
  data: FormData
): Promise<DataType> =>
  fetcher<DataType>(url, {
    method: 'POST',
    body: data,
  });

export const putJson = <DataType>(
  url: string,
  data: unknown = {}
): Promise<DataType> => fetchJson('PUT', url, data);

export const patchJson = <DataType>(
  url: string,
  data: unknown = {}
): Promise<DataType> => fetchJson('PATCH', url, data);

export const deleteJson = <DataType>(
  url: string,
  data: unknown = {}
): Promise<DataType> => fetchJson('DELETE', url, data);
