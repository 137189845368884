import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import {
  BON_DANCE_STYLE_TYPE_OTHER,
  CANCELLABLE_DAY_TYPE_ID_THAT_DAY,
  DIPLOMA_AWARD_TYPE_ID_OTHER,
  ID_UNCONFIRMED,
  OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY,
  OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE,
  OTHER_EVENT_TYPE_ID_PLAYING_SHOP,
} from '@/components/pages/Events/Show/Photography/const';
import { shouldRenderTieredPlatform } from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';

export const isVideographerConfirmed = (inputValues: TFormInputs): boolean =>
  inputValues.hasVideo === 0 ||
  (inputValues.hasVideo === 1 &&
    inputValues.videographerTypeId !== ID_UNCONFIRMED);

export const isIdUnconfirmed = (value: number | null): boolean =>
  value === ID_UNCONFIRMED;

export const isFalse = (value: number | null): boolean => value === 0;

export const isBlank = (value: string): boolean => value === '';

export const isConfirmedWithPremise = (
  premise: number,
  target: number | null
): boolean =>
  !isIdUnconfirmed(premise) && (isFalse(premise) || !isIdUnconfirmed(target));

export const isRecitalChecked = (
  inputValues: TFormInputs,
  photography: TPhotography
): boolean =>
  !isIdUnconfirmed(inputValues.hasVenueEnvironment) &&
  isConfirmedWithPremise(
    inputValues.hasVenueEnvironment,
    inputValues.hasParentSeats
  ) &&
  isConfirmedWithPremise(
    inputValues.hasVenueEnvironment,
    inputValues.lightingTypeId
  ) &&
  (photography.hasVideo !== true ||
    (photography.hasVideo &&
      inputValues.videographerTypeId !== ID_UNCONFIRMED));

export const isGraduationCeremonyChecked = (
  inputValues: TFormInputs,
  photography: TPhotography
): boolean =>
  isConfirmedWithPremise(
    inputValues.hasDiplomaAward,
    inputValues.specifiedDiplomaAwardPhotographyPositionTypeId
  ) &&
  isConfirmedWithPremise(
    inputValues.hasDiplomaAward,
    inputValues.diplomaAwardTypeId
  ) &&
  (isFalse(inputValues.hasDiplomaAward) ||
    inputValues.diplomaAwardTypeId === DIPLOMA_AWARD_TYPE_ID_OTHER ||
    inputValues.diplomaAwardCount !== null) &&
  isVideographerConfirmed(inputValues) &&
  photography.hasThankYouParty !== null &&
  photography.hasDressCode !== null;

export const isSportsDayChecked = (inputValues: TFormInputs): boolean =>
  !isIdUnconfirmed(inputValues.isPhotographyInsideTrackPermitted) &&
  !isIdUnconfirmed(inputValues.hasSkippingPhotography) &&
  !isBlank(inputValues.openAt) &&
  !isBlank(inputValues.openingCeremonyStartAt) &&
  isVideographerConfirmed(inputValues);

export function isMochiPoundChecked(inputValues: TFormInputs): boolean {
  return (
    !isIdUnconfirmed(inputValues.photographyEnvironmentTypeId) &&
    inputValues.riceMortarCount !== null &&
    inputValues.poundStyle !== ''
  );
}

export const isSummerFestivalChecked = (inputValues: TFormInputs): boolean =>
  !isIdUnconfirmed(inputValues.hasStalls) &&
  (isFalse(inputValues.hasStalls) ||
    (inputValues.stallsCount !== null && inputValues.stallsCount > 0)) &&
  !isIdUnconfirmed(inputValues.hasBonDance) &&
  (isFalse(inputValues.hasBonDance) ||
    inputValues.bonDanceStyleTypeId === BON_DANCE_STYLE_TYPE_OTHER ||
    (inputValues.bonDanceStyleTypeId !== ID_UNCONFIRMED &&
      inputValues.bonDanceSongCount !== null &&
      !isIdUnconfirmed(inputValues.bonDanceCircleStyleTypeId) &&
      !isIdUnconfirmed(inputValues.isBonDancePhotographyPermittedInCircle))) &&
  isConfirmedWithPremise(
    inputValues.hasPortableShrine,
    inputValues.portableShrineRouteTypeId
  );

export const isSleepoverChecked = (inputValues: TFormInputs): boolean =>
  !isIdUnconfirmed(inputValues.hasSleepingFacePhoto) &&
  (isFalse(inputValues.canSleepover) ||
    inputValues.sleepoverMeals.every(
      (meal) =>
        meal.hasPreparingMeal === false ||
        !isIdUnconfirmed(meal.preparingMealTypeId ?? null)
    ));

export const isOtherChecked = (inputValues: TFormInputs): boolean =>
  !isIdUnconfirmed(inputValues.hasNapPhoto) &&
  (inputValues.otherPhotographyTypeId !==
    OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE ||
    !isIdUnconfirmed(inputValues.diggingMethodTypeId)) &&
  (inputValues.otherPhotographyTypeId !== OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY ||
    (!isIdUnconfirmed(inputValues.hasBirthdayChildrenPhoto) &&
      inputValues.birthdayChildrenCount !== null)) &&
  (inputValues.otherPhotographyTypeId !== OTHER_EVENT_TYPE_ID_PLAYING_SHOP ||
    (!isBlank(inputValues.buyersSellersRemark) &&
      inputValues.shopCount !== null));

export const isTeachersChargeOfPhotographyChecked = (
  inputValues: TFormInputs
): boolean =>
  inputValues.teachersInChargeOfPhotography.length > 0 &&
  inputValues.teachersInChargeOfPhotography.some(
    (teacher) => !isBlank(teacher)
  );

export const isChristmasChecked = (inputValues: TFormInputs): boolean =>
  isConfirmedWithPremise(inputValues.hasGift, inputValues.giftStyleTypeId) &&
  !isIdUnconfirmed(inputValues.hasSantaArrival);

export const isPhotographyChecked = (inputValues: TFormInputs): boolean =>
  isFalse(inputValues.hasRest) ||
  (inputValues.hasRest === 1 &&
    (inputValues.isRestTimeToBeDetermined ||
      !isBlank(inputValues.restTimeStartAt) ||
      !isBlank(inputValues.restTimeEndAt)) &&
    !isIdUnconfirmed(inputValues.restPlaceTypeId));

export const isEquipmentChecked = (
  photography: TPhotography,
  inputValues: TFormInputs
): boolean =>
  !shouldRenderTieredPlatform(photography) ||
  inputValues.tieredPlatformSenNote !== '';

export const isTripodChecked = (inputValues: TFormInputs): boolean =>
  !isIdUnconfirmed(inputValues.isTripodPermitted) &&
  isConfirmedWithPremise(
    inputValues.isTripodPermitted,
    inputValues.requireOwnTripod
  );

export const isPhotographySubjectsChecked = (
  inputValues: TFormInputs
): boolean =>
  inputValues.photographySubjectTypeIds.length > 0 ||
  inputValues.newChildrenPhotographySubjects.some(
    ({ count }) => count && count > 0
  );

export const isCancelDecisionChecked = (inputValues: TFormInputs): boolean =>
  inputValues.cancellableDayTypeId !== ID_UNCONFIRMED &&
  (inputValues.cancellableDayTypeId !== CANCELLABLE_DAY_TYPE_ID_THAT_DAY ||
    !!inputValues.cancelDecisionTime) &&
  inputValues.cancelNotificationTypeId !== ID_UNCONFIRMED;
