import React from 'react';
import dayjs from 'dayjs';
import { TProps } from './types';
import PhotoType from './PhotoType';
// TODO: layout 列の交互色、ボーダー？

const Table: React.FC<TProps> = ({
  pricePattern,
  userTypes,
  photoSizes: orgPhotoSizes,
  photoTypes,
  sizeNames,
}) => {
  // NOTE: 必要なサイズのみ表示
  const photoSizes = orgPhotoSizes.filter((photoSize) =>
    sizeNames.includes(photoSize.sizename)
  );
  return (
    <table className="c-priceTable">
      <thead>
        <tr>
          <th colSpan={2}>区分</th>
          {photoSizes.map((size) => (
            <th key={size.photosizemasterno}>{size.nameJp}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {userTypes.map((userType) =>
          photoTypes.map((photoType, index) => (
            <tr
              key={`${userType.usertypemasterno}_${photoType.phototypemasterno}`}
            >
              {index === 0 && <th rowSpan={2}>{userType.name}</th>}
              <PhotoType
                key={photoType.phototypemasterno}
                pricePatternCategories={pricePattern.pricepatterncategories}
                userType={userType}
                photoType={photoType}
                photoSizes={photoSizes}
              />
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

const App: React.FC<TProps & { showId?: boolean }> = ({
  showId,
  pricePattern,
  showEditMemoButton = false,
  onEditMemo = () => undefined,
  showIsCalculatedByTaxIncludedPrice = false,
  ...data
}) => {
  return (
    <>
      {showId && (
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
          <li className="l-col_24">
            <ul className="l-flex">
              <li className="c-dataLabel">価格No.</li>
              <li className="c-dataValue">{pricePattern.pricepatternno}</li>
            </ul>
          </li>
        </ul>
      )}
      <ul
        className={`l-col_wrap l-flex l-col_wrap__isSpMode ${
          showId ? '' : 'l-col_wrap__noBorder'
        }`}
      >
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">メモ</li>
            <li className="c-dataValue u-pre_wrap">
              <div className="l-flex_align_start nowrap">
                <div className="u-pre_wrap">{pricePattern.memo}</div>
                {showEditMemoButton && (
                  <button
                    type="button"
                    className="c-btn_rectangle c-btn_edit u-mgl_m"
                    onClick={onEditMemo}
                  >
                    <i className="c-icon__small c-icon_edit" />
                  </button>
                )}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">登録日</li>
            <li className="c-dataValue">
              {dayjs(pricePattern.createdday).format('YYYY/MM/DD')}
            </li>
          </ul>
        </li>
      </ul>
      {showIsCalculatedByTaxIncludedPrice && (
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_24">
            <ul className="l-flex">
              <li className="c-dataLabel">入力方式</li>
              <li className="c-dataValue">
                {pricePattern.isCalculatedByTaxIncludedPrice === 1
                  ? '税込'
                  : '税抜'}
              </li>
            </ul>
          </li>
        </ul>
      )}
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">基本サイズ</li>
            <li className="c-dataValue">
              <ul className="l-flex">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">スナップ写真:</li>
                    <li className="c-dataValue">
                      <span>
                        {
                          pricePattern.pricepatterncategories[0]?.photoSize
                            ?.nameJp
                        }
                      </span>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">集合写真:</li>
                    <li className="c-dataValue">
                      <span>
                        {
                          pricePattern.pricepatterncategories[1]?.photoSize
                            ?.nameJp
                        }
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">価格</li>
          </ul>
        </li>
      </ul>
      <Table pricePattern={pricePattern} {...data} />
    </>
  );
};

export default App;
