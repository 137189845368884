import QueryString from 'query-string';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { removeEmpty } from './objectTools';

const convertQueryToFormData = (
  query: string,
  validator?: TValidatorResponse
): Record<string, unknown> => {
  const r = QueryString.parse(query, { arrayFormat: 'bracket' }) as Record<
    string,
    unknown
  >;
  validator &&
    Object.keys(r).forEach((key) => {
      if (validator.rules[key]?.type === 'integer') {
        r[key] = Number(r[key]);
      }
    });
  return r;
};

/**
 * search formで使うpath文字列を生成
 *
 * MEMO: page以外のpaginatorのparameterが必要になったら改修してください
 */
const makePathForSearchForm = (
  path: string,
  queryString: string,
  formData?: Record<string, unknown>,
  page?: number
): string => {
  const queryParams = convertQueryToFormData(queryString);
  const formParams = formData ? removeEmpty(formData) : {};

  let params: Record<string, unknown | unknown[]> = {};
  if (formData !== undefined) {
    // form dataがある場合、pageは1にリセット
    if (formParams.page) {
      delete formParams.page;
    }
    // また、queryParamを無視
    params = {
      ...formParams,
    };
  } else {
    // TODO: page以外が必要なときは足してください
    const pagerParams: Record<string, string> = {};
    if (page) {
      pagerParams.page = String(page);
    } else if (queryParams.page) {
      pagerParams.page = queryParams.page as string;
    }
    params = {
      ...queryParams,
      ...pagerParams,
    };
  }

  const query = QueryString.stringify(params, { arrayFormat: 'bracket' });

  return path + (query ? `?${query}` : '');
};

export { makePathForSearchForm, convertQueryToFormData };
