import React, { useCallback, useRef } from 'react';

import { getCsv } from '@/ts/fetch';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';

import { errorToast } from '../../../../ts/toast';

const DownloadCsv: React.FC<{
  incentivePaymentId: number;
}> = React.memo(({ incentivePaymentId }) => {
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const download = useCallback(async () => {
    const fileName = '請求明細の内訳.csv';
    const tmpLink = downloadLink.current!;

    try {
      const res = await getCsv(
        `/api/partner_incentive_payments/${incentivePaymentId}/costs.csv`
      );
      // NOTE: jsonで戻るケースはないはずなので実装してないです
      tmpLink.href = makeCsvDownloadUrl(res as string);
      tmpLink.download = fileName;
      tmpLink.click();
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [incentivePaymentId]);

  return (
    <>
      <button
        type="button"
        className="c-btn_large c-btn_manage"
        onClick={download}
      >
        <i className="c-icon_download t-textColor_white u-mgr_xs" />
        <span>請求明細をダウンロード</span>
      </button>
      <a href="/" download="" className="is-hidden" ref={downloadLink}>
        link
      </a>
    </>
  );
});

export default DownloadCsv;
