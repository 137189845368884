import { Resolver, UseFormReturn, useForm } from 'react-hook-form';
import { Props } from '.';
import { PhotoNameSearchFormParams } from '../../hooks';
import { createPhotoNameSearchValidationSchema } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';

type UsePhotoNameSearchReturn = {
  formMethods: UseFormReturn<PhotoNameSearchFormParams>;
};

export const usePhotoNameSearch = ({
  names,
  societyId,
  groupId,
  eventId,
}: Props): UsePhotoNameSearchReturn => {
  const formMethods = useForm<PhotoNameSearchFormParams>({
    resolver: zodResolver(
      createPhotoNameSearchValidationSchema
    ) as Resolver<PhotoNameSearchFormParams>,
    defaultValues: {
      names: names
        ? [
            { value: names[0] ? String(names[0]) : '' },
            { value: names[1] ? String(names[1]) : '' },
          ]
        : [{ value: '' }, { value: '' }],
      societyId,
      groupId,
      eventId,
    },
  });

  return {
    formMethods,
  };
};
