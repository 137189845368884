import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import {
  RadioBoxes,
  TextInput,
  TimeInput,
} from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '../../const';
import { useFormContext } from 'react-hook-form';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { useEffect } from 'react';
import styles from './style.module.scss';
import clsx from 'clsx';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';
import { Video } from '../Video';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type RouteParams = {
  eventId: string;
};

export const SportsDay: React.FC<{
  validator: TValidator;
  photography: TPhotography;
  constants: TConstants;
}> = ({ validator, photography, constants }) => {
  const { eventId } = useParams<RouteParams>();
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasSkippingPhotography' && type === 'change') {
        if (value.hasSkippingPhotography !== 1) {
          setValue('skippingPhotographyRemark', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <PhotographyAccordion
      title={`${photography.eventTypeName}情報`}
      id={LIST_ITEMS.event.sportsDay.id}
    >
      <div className="t-textColor_sub">撮影環境</div>
      <div>{photography.photographingAmbientName ?? '未確認'}</div>
      <Alert type="error" title="室内または屋外の変更について">
        <p className="u-mgb_s">
          申請サマリの項目のため申請サマリ編集から対応をお願いします。
        </p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">トラック内撮影</div>
      <RadioBoxes
        name="isPhotographyInsideTrackPermitted"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: '可能' },
          { key: 0, value: '不可' },
        ]}
      />
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">撮影不要の演目</div>
      <RadioBoxes
        name="hasSkippingPhotography"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasSkippingPhotography') === 1 && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextInput name="skippingPhotographyRemark" validator={validator} />
        </>
      )}
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">保護者の開場時間</div>
      <div className={clsx(styles.timeInputWrapper)}>
        <TimeInput name="openAt" validator={validator} />
      </div>
      <div className="t-textColor_sub u-mgt_s">開会式の開始時間</div>
      <div className={clsx(styles.timeInputWrapper)}>
        <TimeInput name="openingCeremonyStartAt" validator={validator} />
      </div>
      <hr className="u-line_plane" />
      <Video validator={validator} constants={constants} />
    </PhotographyAccordion>
  );
};
