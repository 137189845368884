import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { renderError, useJsonApi } from '../../../../ts/useApi';
import { dateFormatHyphen } from '../../../../ts/formatTools';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TEditResponse } from '../Edit/types';
import { TFormInputs, TFormItems, TSaveFormProps } from './types';
import SaveForm from './SaveForm';
import './app.scss';

const getFormDefaultValuesBase = (
  societyContractId: string,
  planId: string,
  formItems: TFormItems
): Partial<TFormInputs> => ({
  societyContractId: Number(societyContractId),
  planId: Number(planId),
  cancelflag: 0,
  openflag: 0,
  certificationkeyno: '0',
  pricepatternno: getDefaultPricePattern(formItems),
});

const getDefaultPricePattern = (formItems: TFormItems) =>
  String(
    formItems.pricepattern.pricePatterns.find(
      (pricePattern) => pricePattern.defaultpriceflag === '1'
    )?.pricepatternno ?? 0
  );

type TFormVariationsProps = Omit<TSaveFormProps, 'defaultValues'> & {
  societyContractId: string;
  planId: string;
};
type TFormProps = Omit<
  TFormVariationsProps,
  'eventno' | 'submitButton' | 'cancelLink'
>;

const FormWithDefaultValues: React.FC<TFormVariationsProps> = React.memo(
  ({ societyContractId, planId, ...props }) => {
    const { formItems, societyInfo } = props;

    const defaultValues: Partial<TFormInputs> = {
      ...getFormDefaultValuesBase(societyContractId, planId, formItems),
      faceRecommendFlag: formItems.societyContracts.isEnabledFaceRecommend,
      alignment: formItems.societyContracts.photoAlignment,
      autoeventtermsettingflag:
        societyInfo.society.isEnabledAutoEventPeriodSetting,
    };

    return <SaveForm defaultValues={defaultValues} {...props} />;
  }
);

const FormWithOriginal: React.FC<
  TFormVariationsProps & { originalID: string }
> = React.memo(({ originalID, societyContractId, planId, ...props }) => {
  const { data, error } = useJsonApi<TEditResponse>(
    `/api/society_contracts/${societyContractId}/plans/${planId}/events/${originalID}/edit`
  );
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }
  const { event: originalEvent, eventRequestOptions } = data.data;

  const { formItems } = props;

  // FIXME: 閲覧：編集で型定義がメチャメチャである。要修正
  const defaultValues: Partial<TFormInputs> = {
    ...getFormDefaultValuesBase(societyContractId, planId, formItems),
    eventname: originalEvent.eventname,
    eventtype: originalEvent.eventtype,
    photographingday: dateFormatHyphen(originalEvent.photographingday)!,
    eventrequestoptions: eventRequestOptions,
    mojiireday: originalEvent.mojiireday ?? undefined,
    participants: originalEvent.participants,
    alignment: Number(originalEvent.alignment),
    faceRecommendFlag: Number(originalEvent.face_recommend_flag),
    autoeventtermsettingflag: Number(originalEvent.autoeventtermsettingflag),
    opMemo: `【コピー元】ID: ${originalID} (https://sen.8122.jp/events/${originalID})`,
    originalEventId: parseInt(originalID),
  };

  return (
    <>
      <div className="c-frame">
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel e-events-dataLabel">
            <label>コピー元イベント</label>
          </li>
          <li className="c-dataValue">
            <Link to={`/events/${originalID}`} className="c-textlink">
              <span className="c-label c-label__monotone u-fz_xs">
                ID：{originalID}
              </span>
              {originalEvent.eventname}
            </Link>
          </li>
        </ul>
      </div>
      <SaveForm defaultValues={defaultValues} {...props} />
    </>
  );
});

const FormVariations: React.FC<TFormProps> = React.memo((props) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const originalID = searchParams.get('originalId');

  const formProps = {
    ...props,
    eventno: 0,
    submitButton: '登録する',
    cancelLink: '/events',
  };

  return originalID ? (
    <FormWithOriginal originalID={originalID} {...formProps} />
  ) : (
    <FormWithDefaultValues {...formProps} />
  );
});

export default FormVariations;
