import React from 'react';
import styles from './index.module.scss';

type Props = {
  title: string | React.ReactNode;
  children?: React.ReactNode;
};
export const PageContainer = React.memo<Props>(function PageContainer({
  title,
  children,
}) {
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.container}>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </>
  );
});
