import React, { FC, useState } from 'react';
import {
  IndexResponse,
  TFormInputs,
  TransferPayment,
} from '@/components/pages/PhotographerPayments/Transfers/IndexPage/types';
import Paginator from '@/components/shared/Paginator/App';
import { Link, useLocation } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError, toMessages } from '@/ts/useApi';
import {
  DateInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { alertApiError } from '@/ts/formValidation';
import BaseModal from '@/components/shared/BaseModal';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import clsx from 'clsx';
import styles from './ClosedResults.module.scss';
import { numberFormat } from '@/ts/formatTools';

const REASON_TYPE_OTHER = 4;
export const ClosedResults: FC<{
  response: IndexResponse;
  isAllChecked: boolean;
  changeAllCheckBox: (allChecked: boolean) => void;
  isCheckedRow: (payment: TransferPayment) => boolean;
  changeCheckBoxRow: (payment: TransferPayment) => (checked: boolean) => void;
  checkedIds: number[];
  setCheckedIds: (ids: number[]) => void;
  methods: UseFormReturn<TFormInputs>;
  mutate: () => void;
}> = ({
  response,
  isAllChecked,
  changeAllCheckBox,
  isCheckedRow,
  changeCheckBoxRow,
  checkedIds,
  setCheckedIds,
  methods,
  mutate,
}) => {
  const [validator, setValidator] = useState<TValidator>(response.validator);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carryoverRegisterModalOpened, setCarryoverRegisterModalOpened] =
    useState(false);
  const [
    intransferrableRegisterModalOpened,
    setIntransferrableRegisterModalOpened,
  ] = useState(false);
  const location = useLocation();
  const handleClickBulkCarryover = async () => {
    try {
      if (
        !window.confirm(
          '繰越を行います。\n翌月の支払に含まれる形となりますが、よろしいでしょうか？'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(`/api/photographer_payments/transfers/bulk_carryover`, {
        ids: checkedIds,
        reason:
          methods.getValues('reasonType') === REASON_TYPE_OTHER
            ? methods.getValues('reason')
            : '',
        reasonType: methods.getValues('reasonType'),
      });
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('支払の繰越が正常に行われました。');
    setValidator(response.validator);
    setCarryoverRegisterModalOpened(false);
    mutate();
  };

  function handleError(e: Error) {
    if (isValidationError(e)) {
      errorToast(`入力に誤りがあります`);
      setValidator(e.jsonMessage.validator);
    } else {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
    }
  }

  const handleClickBulkTransfer = async () => {
    try {
      if (
        !window.confirm(
          '選択している組織の支払日を登録します。よろしいでしょうか？'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(`/api/photographer_payments/transfers/bulk_transfer`, {
        ids: checkedIds,
        transferredAt: methods.getValues('transferredAt'),
      });
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('支払日の登録が正常に行われました。');
    setValidator(response.validator);
    mutate();
  };

  const handleClickBulkAllTransfer = async () => {
    try {
      if (
        !window.confirm(
          '表示されている組織全ての支払日を登録します。\nよろしいでしょうか？'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(`/api/photographer_payments/transfers/bulk_transfer`, {
        ids: response.data.payments.map((payment) => payment.id),
        transferredAt: methods.getValues('transferredAt'),
      });
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('支払日の登録が正常に行われました。。');
    setValidator(response.validator);
    mutate();
  };

  const handleClickBulkIntransferrable = async () => {
    try {
      if (
        !window.confirm(
          '選択した組織の支払を支払処理不可とします。\n処理を行うと支払が行えませんが、よろしいでしょうか？'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(
        `/api/photographer_payments/transfers/bulk_intransferrable`,
        { ids: checkedIds, reason: methods.getValues('intransferrableReason') }
      );
    } catch (e) {
      if (isValidationError(e)) {
        if (e.jsonMessage.validator.messages?.reason) {
          e.jsonMessage.validator.messages.intransferrableReason =
            e.jsonMessage.validator.messages.reason;
          delete e.jsonMessage.validator.messages.reason;
        }
        setValidator(e.jsonMessage.validator);
        alertApiError();
      } else {
        setValidator(response.validator);
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('支払不可処理が正常に行われました。');
    setValidator(response.validator);
    mutate();
  };

  const handleClickBulkUnclose = async () => {
    try {
      if (
        !window.confirm(
          '選択した組織の締めを解除します。よろしいでしょうか？\n解除後は支払一覧（締め解除中）で締め処理を行うことで支払へ進むことができます。'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(
        `/api/photographer_payments/transfers/bulk_unclose_monthly_books`,
        { ids: checkedIds }
      );
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('締め解除処理が完了しました。');
    setValidator(response.validator);
    mutate();
  };

  if (isLoading) {
    return <SvgLoading />;
  }

  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={location.search}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <SmallCheckBox
                checked={isAllChecked}
                setChecked={changeAllCheckBox}
              />
            </TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>支払明細</TableCell>
            <TableCell>適格事業者番号</TableCell>
            <TableCell>法人番号</TableCell>
            <TableCell>支払予定月</TableCell>
            <TableCell>支払金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.payments.map((payment) => (
            <ResultRow
              key={payment.id}
              payment={payment}
              checked={isCheckedRow(payment)}
              setChecked={changeCheckBoxRow(payment)}
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={location.search}
      />
      <div className={clsx(styles.bottomSpace)} />
      <FormProvider {...methods}>
        <div className={clsx(styles.paymentDayForm, 'c-frame')}>
          <ul className="l-flex l-col_12">
            <li className="c-dataLabel l-flex">
              <label>支払日</label>
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <ul>
                <li>
                  <DateInput name="transferredAt" validator={validator} />
                </li>
              </ul>
            </li>
          </ul>
          <p className="u-mgt_s">
            &lowast;請求書発行での支払いの場合は、カメラマンから入金いただいた日を登録してください。
          </p>
        </div>
      </FormProvider>
      <ButtonsFooter>
        <>
          <button
            disabled={checkedIds.length === 0}
            className={clsx('c-btn_large c-btn_danger u-pdt_s u-pdb_s', {
              'is-disabled': checkedIds.length === 0,
            })}
            onClick={() => setIntransferrableRegisterModalOpened(true)}
          >
            振込処理不可
          </button>
          {intransferrableRegisterModalOpened && (
            <IntransferrableRegisterModal
              closeModal={() => setIntransferrableRegisterModalOpened(false)}
              methods={methods}
              handleClickBulkIntransferrable={handleClickBulkIntransferrable}
              validator={validator}
            ></IntransferrableRegisterModal>
          )}
          <button
            onClick={handleClickBulkAllTransfer}
            disabled={checkedIds.length !== 0}
            className={clsx('c-btn_large c-btn_primary u-pdt_s u-pdb_s', {
              'is-disabled': checkedIds.length !== 0,
            })}
          >
            一括支払完了
          </button>
          <button
            disabled={checkedIds.length === 0}
            className={clsx(
              'c-btn_large c-btn_primary u-pdt_s u-pdb_s c-input_submit',
              {
                'is-disabled': checkedIds.length === 0,
              }
            )}
            onClick={handleClickBulkTransfer}
          >
            個別に支払完了
          </button>
          <button
            disabled={checkedIds.length === 0}
            className={clsx(
              'c-btn_large c-btn_edit u-pdt_s u-pdb_s c-input_submit',
              {
                'is-disabled': checkedIds.length === 0,
              }
            )}
            onClick={() => setCarryoverRegisterModalOpened(true)}
          >
            繰越し
          </button>
          {carryoverRegisterModalOpened && (
            <CarryoverRegisterModal
              closeModal={() => setCarryoverRegisterModalOpened(false)}
              methods={methods}
              response={response}
              handleClickBulkCarryover={handleClickBulkCarryover}
              validator={validator}
            ></CarryoverRegisterModal>
          )}
          <button
            onClick={handleClickBulkUnclose}
            disabled={checkedIds.length === 0}
            className={clsx(
              'c-btn_large c-btn_danger u-pdt_s u-pdb_s c-input_submit',
              {
                'is-disabled': checkedIds.length === 0,
              }
            )}
          >
            締め解除
          </button>
        </>
      </ButtonsFooter>
    </>
  );
};

const IntransferrableRegisterModal: React.FC<{
  closeModal: () => void;
  methods: UseFormReturn<TFormInputs>;
  handleClickBulkIntransferrable: () => void;
  validator: TValidator;
}> = ({ closeModal, methods, handleClickBulkIntransferrable, validator }) => {
  return (
    <BaseModal handleClose={closeModal}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">支払処理不可</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m"></div>
      </div>
      <div className="t-textColor_sub u-mgb_s">
        支払処理不可となった理由やカメラマンに確認取れた日などを記入してください。
        <br />
        千管理画面の報酬管理画面上で記録されます。
      </div>
      <FormProvider {...methods}>
        <div className="l-flex l-col_12">
          <div className="c-dataLabel">
            補足事項
            <small className="c-required">(必須)</small>
          </div>
          <div className="c-dataValue">
            <TextArea name="intransferrableReason" validator={validator} />
            <p>カメラマンには通知されません。</p>
            <p>最大文字数500文字</p>
          </div>
        </div>
      </FormProvider>
      <hr className="u-line_plane" />
      <p className="u-align_center">
        <button
          type="button"
          className="c-btn_large c-btn_primary c-input_submit"
          onClick={handleClickBulkIntransferrable}
        >
          登録
        </button>
        <button
          type="button"
          className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
          onClick={closeModal}
        >
          キャンセル
        </button>
      </p>
    </BaseModal>
  );
};

const CarryoverRegisterModal: React.FC<{
  closeModal: () => void;
  methods: UseFormReturn<TFormInputs>;
  response: IndexResponse;
  handleClickBulkCarryover: () => void;
  validator: TValidator;
}> = ({
  closeModal,
  methods,
  response,
  handleClickBulkCarryover,
  validator,
}) => {
  return (
    <BaseModal handleClose={closeModal}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">繰越</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m"></div>
      </div>
      <div className="t-textColor_sub u-mgb_s">
        繰越しとなった理由を選択または記入してください。
      </div>
      <FormProvider {...methods}>
        <div className="l-flex l-col_12">
          <div className="c-dataLabel">
            理由
            <small className="c-required">(必須)</small>
          </div>
          <div className="c-dataValue">
            <RadioBoxes
              name="reasonType"
              choices={response.formItems.carryoverChoices}
              validator={validator}
            />
            {methods.watch('reasonType') === REASON_TYPE_OTHER && (
              <>
                <TextArea
                  name="reason"
                  validator={validator}
                  placeholder="フリー入力を選択時は必ず入力してください"
                />
                <p>カメラマン明細に表示されます</p>
                <p>最大文字数500文字</p>
              </>
            )}
          </div>
        </div>
      </FormProvider>
      <hr className="u-line_plane" />
      <p className="u-align_center">
        <button
          type="button"
          className="c-btn_large c-btn_primary c-input_submit"
          onClick={handleClickBulkCarryover}
        >
          登録
        </button>
        <button
          type="button"
          className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
          onClick={closeModal}
        >
          キャンセル
        </button>
      </p>
    </BaseModal>
  );
};

const ResultRow: FC<{
  payment: TransferPayment;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ payment, checked, setChecked }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <SmallCheckBox checked={checked} setChecked={setChecked} />
        </TableCell>
        <TableCell>
          <Link
            to={'/photographer_organizations/' + payment.organizationId}
            target="_blank"
          >{`${payment.organizationId} / ${payment.organizationName}`}</Link>
        </TableCell>
        <TableCell>
          {payment.payment > 0 && (
            <Link
              to={`/photographer_payments/transfers/${payment.id}`}
              className="c-textlink"
              target="_blank"
            >
              明細
            </Link>
          )}
        </TableCell>
        <TableCell>{payment.businessRegistrationNumber}</TableCell>
        <TableCell>{payment.corporateNumber}</TableCell>
        <TableCell>{payment.scheduledTransferMonth}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {numberFormat(payment.payment)}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
