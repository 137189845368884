import React, { useCallback, useState } from 'react';
import { postJson } from '../../../ts/useApi';
import { successToast, errorToast } from '../../../ts/toast';

const Create: React.FC<{
  id: number;
  currentFiscalYear: number;
  target: 'groups' | 'societies';
  canCreateSocietyContractIds?: number[];
}> = React.memo(
  ({ id, currentFiscalYear, target, canCreateSocietyContractIds }) => {
    const [showAlert, setShowAlert] = useState(true);

    const create = useCallback(
      async (societyContractId?: number) => {
        if (
          !window.confirm(
            `${currentFiscalYear}年度の年度キーの発行を行います。よろしいですか？`
          )
        ) {
          return;
        }
        try {
          const param = societyContractId
            ? `?societyContractId=${societyContractId}`
            : '';
          await postJson(
            `/api/${target}/${id}/certificationkeys/create_year/${currentFiscalYear}${param}`
          );
          successToast('年度キーを発行しました');
          setShowAlert(false);
        } catch (error) {
          errorToast('エラーが発生しました');
        }
      },
      [id, currentFiscalYear, target]
    );

    return showAlert ? (
      <div className="c-error_block l-flex_between_center">
        <span className="c-alert_text">
          {currentFiscalYear}年度の年度キーが発行されていません
        </span>
        {canCreateSocietyContractIds === undefined ? (
          <button
            type="button"
            className="c-btn_rectangle c-btn_create"
            onClick={() => create()}
          >
            発行する
          </button>
        ) : (
          canCreateSocietyContractIds.map((societyContractId) => (
            <button
              key={societyContractId}
              type="button"
              className="c-btn_rectangle c-btn_create"
              onClick={() => create(societyContractId)}
            >
              契約（ID: {societyContractId}）のキーを発行する
            </button>
          ))
        )}
      </div>
    ) : null;
  }
);

const App: React.FC<{
  canCreate: boolean;
  id: number;
  currentFiscalYear: number;
  target: 'groups' | 'societies';
  canCreateSocietyContractIds?: number[];
}> = React.memo(({ canCreate, ...props }) =>
  canCreate ? <Create {...props} /> : null
);

export default App;
