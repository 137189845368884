import React from 'react';
import { Link } from 'react-router-dom';
import { TEvent, TCategory, TConvertinfo } from './types';

const BreadNavi: React.FC<{
  event: TEvent | null;
  convertinfo: TConvertinfo | null;
  category: TCategory | null;
  photographCount: number | undefined;
  isHideParentCategory: boolean;
  showCategoryLink?: boolean;
  showSocietyName: boolean;
}> = ({
  event,
  convertinfo,
  category,
  photographCount,
  isHideParentCategory,
  showCategoryLink,
  showSocietyName,
}) => {
  const categoryNameToShow = category
    ? isHideParentCategory
      ? category.categoryname
      : `${category.parentcategoryName} - ${category.categoryname}`
    : '';
  return (
    <>
      {showSocietyName && (
        <>
          {event && event.societyId ? (
            <>
              <Link to={`/societies/${event.societyId}`}>
                {event.societyName}
              </Link>
              <span className="p-photographs_breadnav_separator">&raquo;</span>
            </>
          ) : event ? (
            <>
              <Link to={`/partners/${event.partnerId}`}>
                {event.partnerName}
              </Link>
              <span className="p-photographs_breadnav_separator">&raquo;</span>
              <a href={event.groupSummaryUrl}>{event.groupName}</a>
              <span className="p-photographs_breadnav_separator">&raquo;</span>
            </>
          ) : undefined}
        </>
      )}
      {event ? (
        event.link ? (
          <a href={event.link}>{event.eventName}</a>
        ) : (
          <Link to={`/events/${event.id}`}>{event.eventName}</Link>
        )
      ) : convertinfo ? (
        <a href={convertinfo.link}>{convertinfo.directoryname}</a>
      ) : (
        <></>
      )}
      {category && (
        <>
          <span className="p-photographs_breadnav_separator">&raquo;</span>
          {showCategoryLink ? (
            <Link
              to={
                event
                  ? `/events/${event.id}/photographs?categoryno=${category.id}`
                  : `/photographs?categoryno=${category.id}`
              }
            >
              {categoryNameToShow}
            </Link>
          ) : (
            <>{categoryNameToShow}</>
          )}
        </>
      )}
      {photographCount !== undefined && <>[全 {photographCount}枚]</>}
    </>
  );
};

export default BreadNavi;
