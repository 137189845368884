import { useJsonApi } from '@/ts/useJsonApi';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { kanriUrl } from '../../../../ts/url';
import { renderError, toMessages } from '../../../../ts/useApi';
import { fetcher, postJson } from '@/ts/fetch';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { errorToast, successToast } from '../../../../ts/toast';
import BaseModal from '@/components/shared/BaseModal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NumberInput, RadioBoxes } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { TConvertinfo, TFormInputs, TResponse } from './types';
import { alertApiError } from '../../../../ts/formValidation';
import Paginator from '@/components/shared/Paginator/App';
import SearchForm from './SearchForm';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';

const OWNER_TYPE_SEN = 1;
const OWNER_TYPE_TEACHER = 2;
const OWNER_TYPE_PARTNER = 3;

const ChangeModal: React.FC<{
  handleClose: () => void;
  handleChanged: () => void;
  convertinfo: TConvertinfo;
}> = ({ handleClose, convertinfo, handleChanged }) => {
  const defaultOwnerType = convertinfo.societyContractId
    ? OWNER_TYPE_TEACHER
    : convertinfo.isSen
    ? OWNER_TYPE_SEN
    : OWNER_TYPE_PARTNER;
  const methods = useForm({
    defaultValues: {
      partnerId:
        defaultOwnerType === OWNER_TYPE_PARTNER
          ? convertinfo.partnerId
          : undefined,
      societyContractId: convertinfo.societyContractId,
      ownerType: defaultOwnerType,
    },
  });
  const { watch } = methods;
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const handleConfirm = async (data: unknown) => {
    try {
      const response = await postJson<{ validator: TValidatorResponse }>(
        `/api/convertinfos/${convertinfo.id}/change_owner`,
        data
      );
      setErrorMessages([]);
      if (response.validator.hasError) {
        setValidator(response.validator);
        alertApiError();
        return;
      }
      setValidator({ rules: {} });
      successToast('所属先を変更しました');
      handleChanged();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('所属先の変更に失敗しました');
    }
  };
  return (
    <BaseModal handleClose={handleClose}>
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <h4 className="c-section_title">{convertinfo.name}の所属先変更</h4>
      <FormProvider {...methods}>
        <div className="c-searchForm">
          <ul className="l-flex">
            <li className="c-dataLabel">所属先種別</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                <RadioBoxes
                  name="ownerType"
                  validator={validator}
                  choices={[
                    { key: OWNER_TYPE_SEN, value: '直販' },
                    { key: OWNER_TYPE_TEACHER, value: '団体契約' },
                    { key: OWNER_TYPE_PARTNER, value: 'パートナー' },
                  ]}
                  isInline={true}
                ></RadioBoxes>
              </div>
            </li>
          </ul>
          {watch('ownerType') === OWNER_TYPE_TEACHER && (
            <ul className="l-flex">
              <li className="c-dataLabel">団体契約ID</li>
              <li className="c-dataValue">
                <NumberInput name="societyContractId" validator={validator} />
              </li>
            </ul>
          )}
          {watch('ownerType') === OWNER_TYPE_PARTNER && (
            <ul className="l-flex">
              <li className="c-dataLabel">パートナーID</li>
              <li className="c-dataValue">
                <NumberInput name="partnerId" validator={validator} />
              </li>
            </ul>
          )}
        </div>
      </FormProvider>
      <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
        <span
          className="c-btn_large c-btn_cancel u-mgr_s"
          onClick={handleClose}
        >
          キャンセル
        </span>
        <span
          className="c-btn_large c-btn_primary u-mgr_s"
          onClick={methods.handleSubmit(handleConfirm)}
        >
          所属先を変更
        </span>
      </div>
    </BaseModal>
  );
};

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<TResponse>(`/api/convertinfos/unbundled${queryString}`);
  const [modalOpened, setModalOpened] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [currentConvertinfo, setCurrentConvertinfo] = useState<
    TConvertinfo | undefined
  >(undefined);
  const queryParams = convertQueryToFormData(queryString);
  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(
        '/convertinfos/unbundled/',
        queryString,
        formData
      );
      history.push(newPath);
    },
    [history, queryString]
  );
  const refreshToAll = () => {
    if (queryString === '') {
      mutate();
    } else {
      history.push('/convertinfos/unbundled');
    }
  };
  const destroy = async (convertinfo: TConvertinfo) => {
    if (!window.confirm(`${convertinfo.name}を削除してよろしいですか？`)) {
      return;
    }
    try {
      await fetcher(`/api/convertinfos/${convertinfo.id}`, {
        method: 'DELETE',
      });
      successToast('削除しました');
      mutate();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const openModal = (convertinfo: TConvertinfo) => {
    setModalOpened(true);
    setCurrentConvertinfo(convertinfo);
  };
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <>
      <h1 className="c-page_title u-mgb_m">アップ済みフォルダ</h1>
      <ErrorMessages messages={errorMessages} />
      <SearchForm
        validator={response.validator}
        queryParams={queryParams}
        onSubmit={onSubmit}
      />
      <div className="c-frame c-index">
        <div className="l-flex_between_center u-mgb_s">
          <p className="c-text_resultNumber">
            <span>{response.pagination?.total}</span>
            <small>件の結果</small>
          </p>
          <div className="l-flex_end">
            <div className="c-pagination_upper u-mgr_s">
              <Paginator
                pagination={response.pagination}
                currentPath="/convertinfos/unbundled"
                queryString={queryString}
              />
            </div>
          </div>
        </div>
        <table className="c-indexList p-convertinfoIndex_list u-fz_s">
          <thead>
            <tr>
              <th>所属先</th>
              <th>パートナー</th>
              <th>団体</th>
              <th>契約種別</th>
              <th>フォルダ名</th>
              <th>取り込み日時</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {response.data.map((convertinfo) => (
              <tr key={convertinfo.id}>
                <td>
                  {convertinfo.societyContractId
                    ? '団体契約'
                    : convertinfo.isSen
                    ? '直販'
                    : 'パートナー'}
                </td>
                <td>
                  <span className="c-idLink_hasBg">
                    <span className=" u-mgb_xs l-flex_center">
                      <i className="c-icon_partner" />
                      <span className="c-textOmit">
                        {convertinfo.isSen || convertinfo.societyContractId
                          ? ''
                          : convertinfo.partnerId}
                      </span>
                    </span>
                  </span>
                  <div className="c-textOmit">
                    {convertinfo.societyId || convertinfo.isSen ? (
                      <>千株式会社</>
                    ) : (
                      <Link to={'/partners/' + convertinfo.partnerId}>
                        {convertinfo.partnerName}
                      </Link>
                    )}
                  </div>
                </td>
                <td className="c-indexList_column_xl">
                  {convertinfo.societyId && (
                    <>
                      <span className="c-idLink_hasBg">
                        <span className="u-mgb_xs l-flex_center">
                          <i className="c-icon_groups" />
                          <span className="c-textOmit">
                            {convertinfo.societyId}
                          </span>
                        </span>
                      </span>
                      <div>
                        <Link to={'/societies/' + convertinfo.societyId}>
                          {convertinfo.societyName}
                        </Link>
                      </div>
                    </>
                  )}
                </td>
                <td>{convertinfo.contractTypeName}</td>
                <td className="c-indexList_column_xl">
                  <a href={kanriUrl({ co: convertinfo.id.toString() })}>
                    {convertinfo.name}
                  </a>
                </td>
                <td className="c-indexList_column__nowrap">
                  {dayjs(convertinfo.createdday).format('YYYY-MM-DD HH:MM:ss')}
                </td>
                <td className="c-indexList_column__nowrap">
                  <button
                    className="c-btn_large c-btn_edit u-mgr_s"
                    onClick={() => openModal(convertinfo)}
                  >
                    所属先を変更
                  </button>
                  <button
                    className="c-btn_rectangle c-btn_delete"
                    onClick={() => destroy(convertinfo)}
                  >
                    <i className="c-icno c-icon__xsmall c-icon_trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalOpened && currentConvertinfo && (
        <ChangeModal
          handleClose={() => setModalOpened(false)}
          handleChanged={() => {
            setModalOpened(false);
            refreshToAll();
          }}
          convertinfo={currentConvertinfo}
        ></ChangeModal>
      )}
    </>
  );
};

export default App;
