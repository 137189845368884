import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { TextArea } from '@/components/shared/Form/Inputs';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type SenNoteForPhotographyProps = {
  validator: TValidator;
};

export const SenNoteForPhotography: React.FC<SenNoteForPhotographyProps> = ({
  validator,
}) => {
  return (
    <PhotographyAccordion
      title={LIST_ITEMS.event.senNote.name}
      id={LIST_ITEMS.event.senNote.id}
    >
      <TextArea
        name="senNote"
        validator={validator}
        rows={5}
        placeholder="その他情報を入力してください"
      />
      <div className="t-textColor_sub">
        ※昨年の日報やカメラマンの撮影枚数など記載する欄がない場合に使用してください。
        <br />
        ※旅行会社やバス会社などの情報がある場合は記載してください。（例：■旅行会社：○○ツーリスト　■バス会社：○○交通）
      </div>
    </PhotographyAccordion>
  );
};
