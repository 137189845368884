import React from 'react';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';

export const EnvironmentType: React.FC<{
  validator: TValidator;
  photographyEnvironmentTypes: TChoice[];
}> = ({ validator, photographyEnvironmentTypes }) => {
  return (
    <>
      <div className="t-textColor_sub">実施場所(撮影環境)</div>
      <RadioBoxes
        name="photographyEnvironmentTypeId"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          ...photographyEnvironmentTypes,
        ]}
      />
    </>
  );
};
