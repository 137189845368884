import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import { TFormInputs, TSaveResponse } from '../Create/types';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import SaveForm from '../Create/SaveForm';
import { SubmitHandler } from 'react-hook-form';
import { TEditResponse } from './types';
import { useHistory } from 'react-router-dom';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TEditResponse>(
    `/api/societies/${id}/edit`
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const { validator, data } = (await fetcher(`/api/societies/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })) as TSaveResponse;
      setErrorMessages([]);
      if (validator.hasError) {
        setValidator(validator);
        alertApiError();
        return;
      }
      successToast('更新しました');
      history.push(`/societies/${data.id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const {
    organization,
    society,
    societyDirect,
    requestPhotographers,
    societyStudents,
  } = data.data;

  const defaultValues = {
    name: organization.name,
    namekana: organization.namekana,
    fundingTypeId: society.fundingTypeId,
    groupTypeId: society.groupTypeId,
    mailaddress: organization.mailaddress,
    telephonenumber: organization.telephonenumber,
    faxnumber: organization.faxnumber,
    postcode: organization.postcode,
    prefectureId: organization.prefectureId,
    city: organization.city,
    address: organization.address,
    lat: organization.lat,
    lng: organization.lng,
    nearestStation: society.nearestStation,
    nearestBusStop: society.nearestBusStop,
    howToGo: society.howToGo,
    isLegalEntity: society.isLegalEntity,
    isSalableTsuide: society.isSalableTsuide,
    numberOfBelonging: society.numberOfBelonging,
    numberOfFamily: society.numberOfFamily,
    numberOfChildrenCapacity: society.numberOfChildrenCapacity,
    startMonthOfFiscalYear: society.startMonthOfFiscalYear,
    classnames: society.classnames,
    isRequiredAcceptanceByLeader: society.isRequiredAcceptanceByLeader,
    question1: society.question1,
    question2: society.question2,
    question3: society.question3,
    societyContractIdForAcceptance: society.societyContractIdForAcceptance,
    autoNoticeCertificationkeyType: society.autoNoticeCertificationkeyType,
    viewname: organization.viewname,
    url: organization.url,
    requestedPhotographerMemo: societyDirect.requestedPhotographerMemo,
    assigningInternalPhotographerTraineeType:
      societyDirect.assigningInternalPhotographerTraineeType,
    assigningExternalPhotographerTraineeType:
      societyDirect.assigningExternalPhotographerTraineeType,
    isEnabledCreateKeyUsableWithoutLogin:
      society.isEnabledCreateKeyUsableWithoutLogin,
    isEnabledAutoEventPeriodSetting:
      societyDirect.isEnabledAutoEventPeriodSetting,
    photographerId: societyDirect.photographerId,
    photographerChangeHistory: societyDirect.photographerChangeHistory,
    desiredPhotographerIds: requestPhotographers.map(
      (requestPhotographer) => `${requestPhotographer.photographerId}`
    ),
    studentInfos: societyStudents,
    vacationPrecaution: society.vacationPrecaution,
    isEnabledCertificationKeyShareable:
      society.isEnabledCertificationKeyShareable,
  };

  const contracts = society.societyContractsIdAndType.map((contract) => {
    return {
      key: contract.id,
      value: `(ID:${contract.id} ${contract.typeName}) ${contract.representativeName}`,
    };
  });
  const formItems = { ...data.formItems, contracts };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">団体 - 編集</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          societyId={society.id}
          validator={validator}
          formItems={formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="更新する"
          cancelLink={`/societies/${id}`}
        />
      </div>
    </div>
  );
};

export default App;
