import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { renderError } from '../../../../ts/useApi';
import { transformBoolToNumber } from '../../../../ts/transformBoolToNumber';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { SearchForm } from './SearchForm';
import { SearchResult } from './SearchResult';
import SvgLoading from '../../../shared/Loading/SvgLoading';
import { TIndexResponse, TFormInputs } from './types';
import './style.scss';
import { removeEmpty } from '@/ts/objectTools';

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;

  const { data: response, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/photographer_organizations/',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      delete formData.globalSearch;
      const newPath = makePathForSearchForm(
        '/photographer_organizations/',
        queryString,
        removeEmpty(transformBoolToNumber(formData))
      );
      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }

  const { photographerOrganizations, pagination, formItems, validator } =
    response;

  const queryParams: TFormInputs = convertQueryToFormData(
    queryString,
    validator
  );

  return (
    <div>
      <h1 className="l-flex_center u-mgb_m">
        <span className="c-page_title">カメラマン組織一覧</span>
      </h1>
      <SearchForm
        formItems={formItems}
        queryParams={queryParams}
        onSubmit={onSubmit}
        validator={validator}
      />
      <SearchResult
        photographerOrganizations={photographerOrganizations}
        pagination={pagination}
        queryString={queryString}
      />
    </div>
  );
};

export default App;
