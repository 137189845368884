import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  Checkboxes,
  DateInput,
  GroupedMultiSelect,
  MultiSelect,
  NumbersInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({
    validator,
    queryParams,
    onSubmit,
    formItems: {
      prefectures,
      photographingTimes,
      arrangeStatuses,
      agreementTypes,
      photographerRanks,
    },
  }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>イベントID</label>
                    </li>
                    <li className="c-dataValue">
                      <NumbersInput
                        name="eventIds"
                        placeholder="スペース区切りで入力してください"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>依頼状態</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        isDisplayUnselected
                        name="arrangeStatus"
                        choices={arrangeStatuses}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>撮影日</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="photographingDayFrom"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="photographingDayTo"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>撮影中止</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        isDisplayUnselected
                        name="notCanceled"
                        choices={[{ key: 1, value: '撮影中止を除く' }]}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                    </li>
                    <li className="c-dataValue">
                      <GroupedMultiSelect
                        name="prefectureIds"
                        choices={prefectures}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>撮影時間</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="photographingTimes"
                        choices={photographingTimes}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>カメラマンID</label>
                    </li>
                    <li className="c-dataValue">
                      <NumbersInput
                        name="photographerIds"
                        placeholder="スペース区切りで入力してください"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>カメラマン契約種類</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="agreementTypes"
                        choices={agreementTypes}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>カメラマンランク</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="photographerRankIds"
                        choices={photographerRanks}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12" />
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
