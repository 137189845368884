import React, { useState } from 'react';
import { MouseEventHandler } from 'react-select';

import { postJson } from '../../../../ts/useApi';
import {
  errorToast,
  errorToastWithValidatorMessages,
  successToast,
} from '../../../../ts/toast';
import { TPostResponse, TBankAccount } from './types';

const HasMistake: React.FC<{
  hasMistake: number;
  hasMistakeName: string;
}> = React.memo(({ hasMistake, hasMistakeName }) => (
  <div>
    <span
      className={`c-statusLabel c-incentivePayment_bankAccount_hasMistakeToggle${
        hasMistake ? ' is-wrong' : ''
      }`}
    >
      {hasMistakeName}
    </span>
  </div>
));

const HasMistakeToggle: React.FC<{
  id: number;
  hasMistake: number;
}> = React.memo(({ id, hasMistake: baseHasMistake }) => {
  const [hasMistake, setHasMistake] = useState(!!baseHasMistake);

  const toggleHasMistake: MouseEventHandler = async () => {
    const updatedHasMistake = !hasMistake;
    try {
      const res = await postJson<TPostResponse>(
        `/api/bank_accounts/${id}/update_has_mistake`,
        {
          hasMistake: Number(updatedHasMistake),
        }
      );
      if (res.data === 'OK') {
        successToast(
          `不備${updatedHasMistake ? 'あり' : 'なし'}に更新しました`
        );
        setHasMistake(updatedHasMistake);
      } else {
        errorToastWithValidatorMessages(
          '更新に失敗しました',
          res.validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  };

  return (
    <div>
      <span
        className={`c-statusLabel c-incentivePayment_bankAccount_hasMistakeToggle is-switch${
          hasMistake ? ' is-wrong' : ''
        }`}
        onClick={toggleHasMistake}
      >
        {hasMistake ? '不備あり' : '不備なし'}
      </span>
    </div>
  );
});

const HasBankAccount: React.FC<{
  canEditHasMistake: boolean;
  bankAccount: TBankAccount;
}> = React.memo(
  ({
    canEditHasMistake,
    bankAccount: {
      id,
      hasMistakeName,
      hasMistake,
      bankName,
      branchName,
      typeName,
      number,
      holder,
    },
  }) => (
    <>
      <div className="l-flex_start">
        <h5 className="c-section_title u-mgr_s">口座情報</h5>
        {canEditHasMistake ? (
          <HasMistakeToggle id={id} hasMistake={hasMistake} />
        ) : (
          <HasMistake hasMistake={hasMistake} hasMistakeName={hasMistakeName} />
        )}
      </div>
      <hr className="u-line_plane" />
      <ul className="l-flex_start">
        <li className="u-mgr_m">{bankName}</li>
        <li className="u-mgr_m">{branchName}</li>
        <li className="u-mgr_m">
          <strong className="u-mgr_xs">口座番号</strong>
          <span className="u-mgr_xs">{typeName}</span>
          <span>{number}</span>
        </li>
        <li className="u-mgr_m">
          <strong className="u-mgr_xs">口座名義</strong>
          <span>{holder}</span>
        </li>
      </ul>
    </>
  )
);

const HasNoBankAccount: React.FC = React.memo(() => (
  <>
    <div className="l-flex_start">
      <h5 className="c-section_title u-mgr_s">口座情報</h5>
      <HasMistake hasMistake={1} hasMistakeName="未登録" />
    </div>
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">
      <div className="u-align_left">登録がありません</div>
    </div>
  </>
));

const BankAccount: React.FC<{
  canEditHasMistake: boolean;
  bankAccount: TBankAccount | null;
}> = React.memo(({ canEditHasMistake, bankAccount }) =>
  bankAccount ? (
    <HasBankAccount
      canEditHasMistake={canEditHasMistake}
      bankAccount={bankAccount}
    />
  ) : (
    <HasNoBankAccount />
  )
);

export default BankAccount;
