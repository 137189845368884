import { FC, useState } from 'react';
import { IndexResponse, TPayment } from '../types';
import { TMutate } from '@/ts/useJsonApi';
import Paginator from '@/components/shared/Paginator/App';
import QueryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
} from '@/ts/photographerPayments/constants';
import styles from './Results.module.scss';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import { numberFormat } from '@/ts/formatTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';

export const Results: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
  queryParams: Record<string, unknown>;
}> = ({ response, queryParams, mutate }) => {
  const location = useLocation();
  const payments: TPayment[] = response.data.payments;

  const handleUndoCancel = usePreventDuplicateCall(
    async (paymentId: number) => {
      if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
        return;
      }
      try {
        await postJson('/api/photographer_payments/bulk_undo_cancel', {
          ids: [paymentId],
        });
      } catch (e) {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
        return;
      }
      successToast('取り消し解除しました');
      mutate(undefined, { keepCurrentData: true });
    }
  );

  const handleBulkCancel = usePreventDuplicateCall(
    async (paymentId: number) => {
      if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
        return;
      }
      try {
        await postJson('/api/photographer_payments/bulk_cancel', {
          ids: [paymentId],
        });
      } catch (e) {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
        return;
      }
      successToast('取り消ししました');
      mutate(undefined, { keepCurrentData: true });
    }
  );

  if (!response.data.payments?.length) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }

  const paginatorQueryString = QueryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={paginatorQueryString}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>調整金種別</TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>撮影日</TableCell>
            <TableCell>団体</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>合計金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <ResultRow
              payment={payment}
              handleUndoCancel={handleUndoCancel}
              handleBulkCancel={handleBulkCancel}
              key={payment.id}
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={paginatorQueryString}
      />
    </>
  );
};

const ResultRow: FC<{
  payment: TPayment;
  handleUndoCancel: (paymentId: number) => Promise<void>;
  handleBulkCancel: (paymentId: number) => Promise<void>;
  key: number;
}> = ({ payment, handleUndoCancel, handleBulkCancel }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <TableRow
        highlighted={false}
        disabled={payment.status === STATUS_CANCELED}
      >
        <TableCell>
          <div>
            <span onClick={() => setOpened(!opened)} className={styles.arrow}>
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell>{`${payment.typeName}`}</TableCell>
        <TableCell>{`${payment.photographerName}`}</TableCell>
        <TableCell>{payment.organizationName}</TableCell>
        <TableCell>
          {payment.eventId && (
            <Link to={`/events/${payment.eventId}`} className="c-textlink">
              {`${payment.eventId} / ${payment.eventName}`}
            </Link>
          )}
        </TableCell>
        <TableCell>{payment.tradingDateAt}</TableCell>
        <TableCell>{payment.societyName}</TableCell>
        <TableCell>{payment.statusName}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {payment.price !== null ? numberFormat(payment.price) : ''}
          </div>
        </TableCell>
      </TableRow>
      {opened && (
        <OpenedDetail>
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow>
                <InnerTableCell>税率</InnerTableCell>
                <InnerTableCell>源泉徴収</InnerTableCell>
                <InnerTableCell>金額</InnerTableCell>
                <InnerTableCell>備考(明細に記載されます)</InnerTableCell>
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              <InnerTableRow disabled={payment.status === STATUS_CANCELED}>
                <InnerTableCell>
                  {payment.consumptionTaxPercent === '0'
                    ? '非課税'
                    : payment.consumptionTaxPercent
                    ? `${payment.consumptionTaxPercent}%`
                    : ''}
                </InnerTableCell>
                <InnerTableCell>
                  {payment.hasWithholdingTax === null
                    ? ''
                    : payment.hasWithholdingTax
                    ? '必要'
                    : '不要'}
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_right">
                    {payment.price !== null ? numberFormat(payment.price) : ''}
                  </div>
                </InnerTableCell>
                <InnerTableCell>
                  <span className="u-pre_wrap">{payment.externalMemo}</span>
                </InnerTableCell>
              </InnerTableRow>
            </InnerTableBody>
          </InnerTable>
          <div className={styles.closeLinkContainer}>
            {payment.status === STATUS_CANCELED && (
              <button
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleUndoCancel(payment.id)}
              >
                取り消し解除
              </button>
            )}
            {payment.status === STATUS_APPROVED && (
              <button
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleBulkCancel(payment.id)}
              >
                取り消し
              </button>
            )}
            <span className={styles.closeLinkWrapper}>
              <span
                className={styles.closeLink}
                onClick={() => setOpened(false)}
              >
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                閉じる
              </span>
            </span>
          </div>
        </OpenedDetail>
      )}
    </>
  );
};
