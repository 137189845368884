import React from 'react';

import { isContractTypeTeacher } from '../../../../ts/societyContractConstants';
import { isIncentivePaymentTypeSales } from '../../../../ts/salesManagementConstants';
import { HYPHEN, incentiveRateToString } from '../../../../ts/formatTools';
import { TSalesManagementPlan } from '../../types';

const IncentivePaymentTypeSales: React.FC<{
  salesManagementPlan: TSalesManagementPlan;
}> = React.memo(({ salesManagementPlan }) => (
  <>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">インセンティブ種類</li>
          <li className="c-dataValue">
            {salesManagementPlan.incentivePaymentTypeName}
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">手数料率</li>
          <li className="c-dataValue">
            {incentiveRateToString(salesManagementPlan.incentiveRate)} %
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">請求明細書 特殊集計</li>
          <li className="c-dataValue">
            {salesManagementPlan.specialIncentiveRateTypeName}
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">特殊集計しきい値</li>
          <li className="c-dataValue">
            {salesManagementPlan.incentiveRateThresholdPrice ?? HYPHEN} 円
          </li>
        </ul>
      </li>
    </ul>
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">
            特殊集計しきい値未満のときのインセンティブ率
          </li>
          <li className="c-dataValue">
            {incentiveRateToString(
              salesManagementPlan.incentiveRateWhenLessThanThresholdPrice
            )}{' '}
            %
          </li>
        </ul>
      </li>
      <li className="l-col_12" />
    </ul>
  </>
));

const IncentivePaymentTypeCost: React.FC<{
  salesManagementPlan: TSalesManagementPlan;
}> = React.memo(({ salesManagementPlan }) => (
  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
    <li className="l-col_12">
      <ul className="l-flex">
        <li className="c-dataLabel">インセンティブ種類</li>
        <li className="c-dataValue">
          {salesManagementPlan.incentivePaymentTypeName}
        </li>
      </ul>
    </li>
    <li className="l-col_12">
      <ul className="l-flex">
        <li className="c-dataLabel">手数料率</li>
        <li className="c-dataValue">
          {incentiveRateToString(salesManagementPlan.wholesaleCommissionRate)} %
        </li>
      </ul>
    </li>
  </ul>
));

const Show: React.FC<{
  salesManagementPlan: TSalesManagementPlan;
}> = React.memo(({ salesManagementPlan }) => (
  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
    <li className="l-col_24">
      <ul className="l-flex">
        <li className="c-dataLabel">
          販売情報
          <span className="l-relative">
            <span className="c-tooltip u-mgl_xs">
              <i className="c-icon_help"></i>
            </span>
            <span className="c-tooltip_body p-groupsSummary_tooltip_help">
              先生撮影団体の場合のみ表示されます
            </span>
          </span>
        </li>
        <li className="c-dataValue">
          <div className="t-bgBox_gray">
            {isIncentivePaymentTypeSales(
              salesManagementPlan.incentivePaymentType
            ) ? (
              <IncentivePaymentTypeSales
                salesManagementPlan={salesManagementPlan}
              />
            ) : (
              <IncentivePaymentTypeCost
                salesManagementPlan={salesManagementPlan}
              />
            )}
          </div>
        </li>
      </ul>
    </li>
  </ul>
));

const SalesManagementPlan: React.FC<{
  societyContractTypeId: number;
  salesManagementPlan: TSalesManagementPlan | undefined;
}> = React.memo(({ societyContractTypeId, salesManagementPlan }) =>
  isContractTypeTeacher(societyContractTypeId) &&
  salesManagementPlan !== undefined ? (
    <Show salesManagementPlan={salesManagementPlan as TSalesManagementPlan} />
  ) : null
);

export default SalesManagementPlan;
