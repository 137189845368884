import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormItems, TFormInputs } from './types';
import {
  GroupedMultiSelect,
  MultiSelect,
  NumberInput,
  TextInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { ApiErrors } from '@/components/shared/Form/Errors';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_m u-mgt_xs">
            <h4>団体新規紐付け</h4>
            {/* TODO: 開いたり閉じたり */}
            {/* <div className="c-btn_toggleDetails">
              <span className="sp_off">閉じる</span>
            </div> */}
          </div>
          <hr className="u-line_plane" />
          <form
            method="GET"
            onSubmit={methods.handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div className="t-bgBox_gray">
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">
                  <label>団体</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-col_wrap has-gutter l-flex is-sp_mode">
                    <li className="l-col_8">
                      <NumberInput
                        name="societyId"
                        placeholder="団体ID"
                        validator={validator}
                      />
                    </li>
                    <li className="l-col_8">
                      <TextInput
                        name="societyName"
                        placeholder="団体名"
                        validator={validator}
                      />
                    </li>
                    <li className="l-col_8">
                      <TextInput
                        name="societyNameKana"
                        placeholder="団体名(かな)"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">
                  <label>担当営業</label>
                </li>
                <li className="c-dataValue u-pdr_xs u-pdl_xs">
                  <MultiSelect
                    name="primarySales[]"
                    choices={formItems.saleses}
                    placeholder="クリックで候補表示（複数可）"
                    validator={validator}
                  />
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>電話番号</label>
                    </li>
                    <li className="c-dataValue l-col_wrap has-gutter is-sp_mode">
                      <div className="l-col_auto">
                        <TextInput
                          name="telephone"
                          placeholder="03-1234-5678"
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <GroupedMultiSelect
                        name="prefecture"
                        choices={formItems.prefectures}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        住所(市区町村
                        <br />
                        以下)
                      </label>
                    </li>
                    <li className="c-dataValue l-col_wrap has-gutter is-sp_mode">
                      <div className="l-col_auto">
                        <TextInput
                          name="address"
                          placeholder="住所(市区町村以下)"
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        紐付け済み
                        <br />
                        団体
                      </label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <RadioBoxes
                        name="excludeLinkedToCorporation"
                        choices={formItems.excludeLinkedToCorporation}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    );
  }
);

export default SearchForm;
