import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { deleteJson } from '../../../../ts/useApi';
import {
  successToast,
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TRemoveResponse } from './types';

const Remove: React.FC<{
  isLeaderKey: boolean;
  id: number;
  useraccountId: number;
  refetch: () => void;
}> = React.memo(({ isLeaderKey, id, useraccountId, refetch }) => {
  const onClick: MouseEventHandler = useCallback(async () => {
    const msg = isLeaderKey
      ? '会員の紐付けを解除し、責任者から一般ユーザーに格下げします。よろしいですか？'
      : '会員の紐付けを解除します。よろしいですか？';
    if (!window.confirm(msg)) {
      return;
    }
    try {
      const { data, validator } = await deleteJson<TRemoveResponse>(
        `/api/certificationkeys/${id}/useraccounts/${useraccountId}`
      );
      if (data === 'OK') {
        successToast('会員の紐付けを解除しました');
        refetch();
      } else {
        errorToastWithValidatorMessages(
          '会員の紐付け解除に失敗しました',
          validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [id, useraccountId, refetch, isLeaderKey]);

  return (
    <button
      className="c-btn_small c-btn_rectangle c-btn_delete"
      onClick={onClick}
    >
      <span className="u-fz_s">紐付け解除</span>
    </button>
  );
});

const RemoveButton: React.FC<{
  canRemove: boolean;
  isLeaderKey: boolean;
  id: number;
  useraccountId: number;
  refetch: () => void;
}> = React.memo(({ canRemove, ...props }) =>
  canRemove ? (
    <Remove {...props} />
  ) : (
    <button className="c-btn_small c-btn_rectangle c-btn_delete is-disabled">
      <span className="u-fz_s">紐付け解除</span>
    </button>
  )
);

export default RemoveButton;
