import { TValidatorResponse } from '@/components/shared/Form/types';
import { fetcher } from '@/ts/fetch';
import { makePathForSearchForm } from '@/ts/makePathForSearchForm';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { TFormInputs } from '../ArrangePhotographer/Index/types';
import { isValidationError, toMessages } from '@/ts/useApi';
import { flattenValidatorMessages } from '@/components/shared/Form/Errors';

export type PhotoIdSearchFormParams = {
  ids: {
    value: string;
  }[];
};

export type PhotoNameSearchFormParams = {
  names: {
    value: string;
  }[];
  societyId?: number;
  groupId?: number;
  eventId?: number;
};

export enum PhotoTypeMasterNo {
  SNAP = 1,
  GROUP = 2,
}

export type Photograph = {
  id: number;
  name: string;
  saveDir: string;
  cameramanId: number;
  categoryNo: number;
  categoryName: string;
  viewNo: number;
  photoTypeMasterNo: PhotoTypeMasterNo;
  canView: boolean;
  photoTypeName: string;
  parentCategoryNo: number;
  parentCategoryName: string;
  convertInfoNo: number;
  directoryName: string;
  location: number;
  eventId: number;
  eventName: string;
  groupId?: number;
  groupName?: string;
  societyId?: number;
  societyName?: string;
  thumbnailUrl?: string;
  createdAt: string;
  updatedAt: string;
};

type UsePhotoSearchReturn = {
  loading: boolean;
  searchParam?: SearchParam;
  photographs?: Photograph[];
  errorMessages?: string[];
  handleIdSearchSubmit: SubmitHandler<PhotoIdSearchFormParams>;
  handleNameSearchSubmit: SubmitHandler<PhotoNameSearchFormParams>;
};

type SearchParam = {
  type: 'id' | 'name';
  path: string;
  idSearchFormParams?: {
    ids?: number[];
  };
  nameSearchFormParams?: {
    names?: string[];
    societyId?: number;
    groupId?: number;
    eventId?: number;
  };
};

export type ApiErrorResponse = {
  data: string;
  validator: TValidatorResponse;
  inputs: TFormInputs;
};

export const usePhotoSearch = (): UsePhotoSearchReturn => {
  const history = useHistory();
  const { search: queryString } = useLocation();
  const [photographs, setPhotographs] = useState<Photograph[]>();
  const [searchParam, setSearchParam] = useState<SearchParam>();
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();

  const handleIdSearchSubmit = useCallback<
    SubmitHandler<PhotoIdSearchFormParams>
  >(
    (values: PhotoIdSearchFormParams) => {
      setPhotographs(undefined);
      const queryString = values.ids
        .map((id) => {
          return id.value ? `ids[]=${id.value}` : null;
        })
        .join('&');

      history.push(`/photographs/search?type=id&${queryString}`);
    },
    [history]
  );

  const handleNameSearchSubmit = useCallback<
    SubmitHandler<PhotoNameSearchFormParams>
  >(
    (values: PhotoNameSearchFormParams) => {
      setPhotographs(undefined);
      let queryString = values.names
        .map((name) => {
          return name.value ? `names[]=${name.value}` : null;
        })
        .join('&');
      if (values.societyId) {
        queryString = queryString.concat(`&societyId=${values.societyId}`);
      }
      if (values.groupId) {
        queryString = queryString.concat(`&groupId=${values.groupId}`);
      }
      if (values.eventId) {
        queryString = queryString.concat(`&eventId=${values.eventId}`);
      }

      history.push(`/photographs/search?type=name&${queryString}`);
    },
    [history]
  );

  const [{ loading: idSearchLoading }, handleIdSearch] =
    useAsyncFn(async () => {
      if (!searchParam || searchParam.type !== 'id') return;

      try {
        const response = await fetcher<Photograph[]>(searchParam.path);
        setPhotographs(response);
        setErrorMessages(undefined);
      } catch (e: unknown) {
        if (isValidationError(e) && e.jsonMessage.validator.messages) {
          setErrorMessages(
            flattenValidatorMessages(e.jsonMessage.validator.messages).map(
              (message) => message
            )
          );
        } else {
          setErrorMessages(toMessages(e));
        }
      }
    }, [searchParam]);

  const [{ loading: nameSearchLoading }, handleNameSearch] =
    useAsyncFn(async () => {
      if (!searchParam || searchParam.type !== 'name') return;

      try {
        const response = await fetcher<Photograph[]>(searchParam.path);
        setPhotographs(response);
        setErrorMessages(undefined);
      } catch (e: unknown) {
        if (isValidationError(e) && e.jsonMessage.validator.messages) {
          setErrorMessages(
            flattenValidatorMessages(e.jsonMessage.validator.messages).map(
              (message) => message
            )
          );
        } else {
          setErrorMessages(toMessages(e));
        }
      }
    }, [searchParam]);

  useAsync(async () => {
    if (!searchParam) return;

    if (searchParam.type === 'id') {
      await handleIdSearch();
    }
    if (searchParam.type === 'name') {
      await handleNameSearch();
    }
  }, [handleIdSearch, handleNameSearch]);

  const getArrayFromQueryString = (
    queryString: string,
    key: string
  ): string[] => {
    const params = new URLSearchParams(queryString);

    return params.getAll(key);
  };
  const getValueFromQueryString = (
    queryString: string,
    key: string
  ): string | undefined => {
    const params = new URLSearchParams(queryString);
    const value = params.get(key);

    return value ? value : undefined;
  };
  useEffect(() => {
    const params = new URLSearchParams(queryString);
    const type = params.get('type') as 'id' | 'name';

    setSearchParam({
      type,
      path: makePathForSearchForm(
        `/api/photographs/search/${type}`,
        queryString
      ),
      idSearchFormParams:
        type === 'id'
          ? {
              ids: getArrayFromQueryString(queryString, 'ids[]').map((id) =>
                parseInt(id)
              ),
            }
          : undefined,
      nameSearchFormParams:
        type === 'name'
          ? {
              names: getArrayFromQueryString(queryString, 'names[]').map(
                (name) => name
              ),
              societyId: getValueFromQueryString(queryString, 'societyId')
                ? Number(getValueFromQueryString(queryString, 'societyId'))
                : undefined,
              groupId: getValueFromQueryString(queryString, 'groupId')
                ? Number(getValueFromQueryString(queryString, 'groupId'))
                : undefined,
              eventId: getValueFromQueryString(queryString, 'eventId')
                ? Number(getValueFromQueryString(queryString, 'eventId'))
                : undefined,
            }
          : undefined,
    });
  }, [queryString]);

  return {
    loading: idSearchLoading || nameSearchLoading,
    searchParam,
    photographs,
    errorMessages,
    handleIdSearchSubmit,
    handleNameSearchSubmit,
  };
};
