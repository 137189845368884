import SparkMD5 from 'spark-md5';

export const getMd5FromFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      const spark = new SparkMD5.ArrayBuffer();
      try {
        spark.append(reader.result as ArrayBuffer);
      } catch (err) {
        reject(err);
      }
      resolve(spark.end());
    });
    reader.readAsArrayBuffer(file);
  });
