import { FC } from 'react';
import { kanriUrl } from '@/ts/url';
import { DataResponse } from '../../type';
import styles from '../Form.module.scss';

export const Info: FC<{
  eventRequestPhotographer: DataResponse;
}> = ({ eventRequestPhotographer }) => {
  return (
    <>
      <div className="l-flex c-label_line">
        <div className={styles.requestFeeEditLabel}>カメラマン</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.photographerId} / ${eventRequestPhotographer.photographerSei} ${eventRequestPhotographer.photographerMei}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className={styles.requestFeeEditLabel}>カメラマン組織</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.photographerOrganizationId} / ${eventRequestPhotographer.photographerOrganizationName}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className={styles.requestFeeEditLabel}>イベント</div>
        <div className="c-dataValue">
          <div className="l-flex_center l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.eventId} / ${eventRequestPhotographer.eventName}`}
              />
            </div>
            <div className="l-col_8">
              <div className="l-flex u-mgl_s">
                <a
                  className="c-textlink"
                  href={kanriUrl({
                    action_owner_SHOUSAIinput: 't',
                    eventno: eventRequestPhotographer.eventId.toString(),
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  イベント詳細メールを見る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
