import React, { FC, useState } from 'react';
import styles from './Results.module.scss';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import { NumberInput, TextArea } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
  STATUS_MANUALLY_HANDLED,
  STATUS_WAITING_CONFIRMATION,
} from '@/ts/photographerPayments/constants';
import { IndexResponse, Payment } from '../types';
import { postJson, putJson } from '@/ts/fetch';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError, toMessages } from '@/ts/useApi';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import Paginator from '@/components/shared/Paginator/App';
import { Link, useLocation } from 'react-router-dom';
import { TMutate } from '@/ts/useJsonApi';
import { dateFormat, numberFormat } from '@/ts/formatTools';
import dayjs from 'dayjs';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';

type RowStatus = 'closed' | 'opened' | 'editing';

const ItemDetail: FC<{
  isEditing: boolean;
  payment: Payment;
  editValidator: TValidator;
  handleClose: () => void;
  handleEndEdit: () => void;
}> = ({ payment, isEditing, handleClose, handleEndEdit, editValidator }) => {
  const [validator, setValidator] = useState<TValidator>(editValidator);
  const methods = useForm({
    defaultValues: {
      status: payment.status,
      price: payment.price,
      externalMemo: payment.externalMemo,
    },
  });
  const handleClickUndoCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
      return;
    }
    try {
      await postJson(`/api/photographer_payments/bulk_undo_cancel`, {
        ids: [payment.id],
      });
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消しを解除しました。');
    handleEndEdit();
  });
  const handleClickUnsetManuallyHandled = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(
        '手動対応済みを解除します。\nこの違約金は以前のステータスに戻り、カメラマンが承諾済みの場合、締め処理の対象になります。\nよろしいですか？'
      )
    ) {
      return;
    }
    try {
      await postJson(
        `/api/photographer_payments/penalties/bulk_unset_manually_handled`,
        {
          ids: [payment.id],
        }
      );
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    successToast('支払い対象に戻しました');
    handleEndEdit();
  });
  const handleSubmit = usePreventDuplicateCall(async (data: unknown) => {
    try {
      await putJson(`/api/photographer_payments/penalties/${payment.id}`, data);
      successToast('違約金が更新されました。');
      handleEndEdit();
    } catch (e) {
      if (isValidationError(e)) {
        errorToast(`入力に誤りがあります`);
        setValidator(e.jsonMessage.validator);
      } else {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
    }
  });
  return (
    <FormProvider {...methods}>
      <OpenedDetail>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow>
                <InnerTableCell>ステータス</InnerTableCell>
                <InnerTableCell>カメラマン</InnerTableCell>
                <InnerTableCell>税率</InnerTableCell>
                <InnerTableCell>源泉徴収</InnerTableCell>
                <InnerTableCell>金額</InnerTableCell>
                <InnerTableCell>違約金発生理由</InnerTableCell>
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              <InnerTableRow disabled={payment.status === STATUS_CANCELED}>
                <InnerTableCell>{payment.statusName}</InnerTableCell>
                <InnerTableCell>{`${payment.photographerSei} ${payment.photographerMei}`}</InnerTableCell>
                <InnerTableCell>
                  {`${payment.consumptionTaxPercent}%`}
                </InnerTableCell>
                <InnerTableCell>
                  {payment.hasWithholdingTax ? '必要' : '不要'}
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_right">
                    {isEditing ? (
                      <NumberInput name="price" validator={validator} />
                    ) : (
                      numberFormat(payment.price)
                    )}
                  </div>
                </InnerTableCell>
                <InnerTableCell>
                  {isEditing ? (
                    <TextArea name="externalMemo" validator={validator} />
                  ) : (
                    payment.externalMemo
                  )}
                </InnerTableCell>
              </InnerTableRow>
            </InnerTableBody>
          </InnerTable>
          <div className={styles.innerTableButtons}>
            {(payment.status === STATUS_APPROVED ||
              payment.status === STATUS_WAITING_CONFIRMATION) &&
            isEditing ? (
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="更新"
              />
            ) : payment.status === STATUS_CANCELED ? (
              <button
                className="c-btn_large c-btn_delete"
                type="button"
                onClick={handleClickUndoCancel}
              >
                取り消し解除
              </button>
            ) : payment.status === STATUS_MANUALLY_HANDLED ? (
              <button
                className="c-btn_large c-btn_delete"
                type="button"
                onClick={handleClickUnsetManuallyHandled}
              >
                支払い対象に戻す
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.innerTableButtons}>
            <span className={styles.closeDetailLinkWrapper}>
              <span className={styles.closeDetailLink} onClick={handleClose}>
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                {isEditing ? 'キャンセル' : '閉じる'}
              </span>
            </span>
          </div>
        </form>
      </OpenedDetail>
    </FormProvider>
  );
};

const isDisplayCheckBox = (payment: Payment) =>
  payment.status === STATUS_APPROVED ||
  payment.status === STATUS_WAITING_CONFIRMATION;

const Item: FC<{
  payment: Payment;
  mutate: () => void;
  editValidator: TValidator;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ payment, mutate, editValidator, checked, setChecked }) => {
  const [status, setStatus] = useState<RowStatus>('closed');
  const opened = status !== 'closed';
  const handleClickEdit = () => {
    setStatus('editing');
  };
  return (
    <>
      <TableRow
        highlighted={checked}
        disabled={payment.status === STATUS_CANCELED}
      >
        <TableCell>
          {isDisplayCheckBox(payment) && (
            <SmallCheckBox checked={checked} setChecked={setChecked} />
          )}
        </TableCell>
        <TableCell>
          <div className={styles.resultTableButtonsCell}>
            <span
              className={styles.arrow}
              onClick={() => setStatus(opened ? 'closed' : 'opened')}
            >
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
            {payment.status === STATUS_WAITING_CONFIRMATION && (
              <button
                className={clsx('c-btn_rectangle', 'c-btn_edit', {
                  'is-disabled': status === 'editing',
                })}
                onClick={handleClickEdit}
              >
                <i className="c-icon c-icon__xsmall c-icon_edit"></i>
              </button>
            )}
          </div>
        </TableCell>
        <TableCell>{`${payment.photographerSei} ${payment.photographerMei}`}</TableCell>
        <TableCell>{payment.organizationName}</TableCell>
        <TableCell>
          <Link className="c-textlink" to={`/events/${payment.eventId}`}>
            {`${payment.eventId} / ${payment.eventName}`}
          </Link>
        </TableCell>
        <TableCell>{dateFormat(dayjs(payment.tradingDate))}</TableCell>
        <TableCell>{payment.statusName}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {numberFormat(payment.price)}
          </div>
        </TableCell>
      </TableRow>
      {opened && (
        <ItemDetail
          payment={payment}
          isEditing={status === 'editing'}
          editValidator={editValidator}
          handleClose={() => setStatus('closed')}
          handleEndEdit={() => {
            setStatus('opened');
            mutate();
          }}
        />
      )}
    </>
  );
};

export const Results: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
  editValidator: TValidator;
}> = ({ response, mutate, editValidator }) => {
  const location = useLocation();
  const payments = response.data.payments || [];
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const checkableIds = payments
    .filter((payment) => isDisplayCheckBox(payment))
    .map((payment) => payment.id);
  const changeStatus =
    (
      checkedIds: number[],
      status: number,
      statusName: string
    ): Parameters<TMutate<IndexResponse>>[0] =>
    (response) =>
      response && {
        ...response,
        data: {
          ...response.data,
          payments: response.data.payments?.map((payment) => ({
            ...payment,
            ...(checkedIds.includes(payment.id) ? { status, statusName } : {}),
          })),
        },
      };

  const handleBulkCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    try {
      await postJson('/api/photographer_payments/bulk_cancel', {
        ids: checkedIds,
      });
    } catch (e) {
      errorToast('取り消しに失敗しました');
      return;
    }
    setCheckedIds([]);
    successToast('取り消しに成功しました');
    mutate(changeStatus(checkedIds, STATUS_CANCELED, '取り消し'));
  });
  const handleBulkSetManuallyHandled = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(
        '選択した違約金を手動対応済みに変更します。\n手動対応済みに変更すると、支払い金額から差し引くことはできなくなりますがよろしいでしょうか？'
      )
    ) {
      return;
    }
    try {
      await postJson(
        '/api/photographer_payments/penalties/bulk_set_manually_handled',
        {
          ids: checkedIds,
        }
      );
    } catch (e) {
      errorToast('手動対応済みへの変更に失敗しました');
      return;
    }
    setCheckedIds([]);
    successToast('手動対応済みへの変更に成功しました');
    mutate(changeStatus(checkedIds, STATUS_MANUALLY_HANDLED, '手動対応済み'));
  });
  if (!response.data.payments || response.data.payments.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={location.search}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {checkableIds.length > 0 && (
                <SmallCheckBox
                  checked={checkedIds.length === checkableIds.length}
                  setChecked={(checked) =>
                    checked ? setCheckedIds(checkableIds) : setCheckedIds([])
                  }
                />
              )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>撮影日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <Item
              key={payment.id}
              payment={payment}
              mutate={() => mutate(undefined, { keepCurrentData: true })}
              editValidator={editValidator}
              checked={checkedIds.includes(payment.id)}
              setChecked={(checked) =>
                checked
                  ? setCheckedIds([...checkedIds, payment.id])
                  : setCheckedIds(checkedIds.filter((id) => id !== payment.id))
              }
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={location.search}
      />
      <ButtonsFooter>
        <button
          type="button"
          className={clsx(
            'c-btn_large',
            'c-btn_cancel',
            'u-pdt_s',
            'u-pdb_s',
            checkedIds.length === 0 ? 'is-disabled' : undefined
          )}
          onClick={handleBulkCancel}
        >
          取り消し
        </button>
        <button
          type="button"
          className={clsx(
            'c-btn_large',
            'c-btn_primary',
            checkedIds.length === 0 ? 'is-disabled' : undefined
          )}
          onClick={handleBulkSetManuallyHandled}
        >
          手動対応済み
        </button>
      </ButtonsFooter>
    </>
  );
};
