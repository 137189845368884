import { UseFormReturn, useForm } from 'react-hook-form';
import { Props } from '.';
import { PhotoIdSearchFormParams } from '../../hooks';

type UsePhotoIdSearchReturn = {
  formMethods: UseFormReturn<PhotoIdSearchFormParams>;
};

export const usePhotoIdSearch = ({ ids }: Props): UsePhotoIdSearchReturn => {
  const formMethods = useForm<PhotoIdSearchFormParams>({
    defaultValues: {
      ids: ids
        ? [
            { value: ids[0] ? String(ids[0]) : '' },
            { value: ids[1] ? String(ids[1]) : '' },
          ]
        : [{ value: '' }, { value: '' }],
    },
  });

  return {
    formMethods,
  };
};
