import { isIncentivePaymentTypeSales } from '../../../../ts/salesManagementConstants';

const NOT_HALF_ASSOCIATED_PHOTO_SIZE_NAMES = [
  'Data',
  'Data_S',
  'L+Data',
  'A4',
  '8PW',
  '6PW',
  'DVD',
  'BD',
  'Goods',
];

export const calcTaxInPrice = (
  price: number | undefined,
  taxRate: number
): number | undefined => {
  if (price === undefined || isNaN(price)) {
    return undefined;
  }
  return Math.round(price * taxRate);
};

export const calcAssociatedPrice = (
  price: number | undefined,
  incentiveRateType: number | null,
  photoSizeName: string,
  teacherFlag: boolean
): number | undefined => {
  if (price === undefined || isNaN(price)) {
    return undefined;
  }
  if (!isAssociatedPriceHalf(price, incentiveRateType, photoSizeName)) {
    return price;
  }
  if (teacherFlag) {
    return price;
  }

  return price ? Math.floor(price / 2) : price;
};
const isAssociatedPriceHalf = (
  price: number,
  incentiveRateType: number | null,
  photoSizeName: string
): boolean => {
  // インセンティブ種類＝2:卸値 は半額にしない
  if (
    incentiveRateType !== null &&
    !isIncentivePaymentTypeSales(incentiveRateType)
  ) {
    return false;
  }
  // データ系・グッズ系・A4・6PW・8PWは半額にしない
  if (NOT_HALF_ASSOCIATED_PHOTO_SIZE_NAMES.includes(photoSizeName)) {
    return false;
  }

  return true;
};
