import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumberInput,
  Checkboxes,
  MultiSelect,
  GroupedMultiSelect,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    // MEMO: validation error があるとformがsubmitされない
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    const {
      plans,
      prefectures,
      salesDepartmentOwners,
      photographers,
      statuses,
    } = formItems;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="c-certificationkeys_searchForm_modelInfo">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>パートナー</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-flex">
                        <li className="c-certificationkeys_searchForm_modelInfo_id">
                          <NumberInput
                            name="partnerId"
                            placeholder="パートナーID"
                            validator={validator}
                          />
                        </li>
                        <li className="c-certificationkeys_searchForm_modelInfo_name">
                          <TextInput
                            name="partnerName"
                            placeholder="パートナー名／表示名"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="c-searchForm_tel">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>電話番号</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="telephonenumber"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="c-certificationkeys_searchForm_modelInfo">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>団体</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-flex">
                        <li className="c-certificationkeys_searchForm_modelInfo_id">
                          <NumberInput
                            name="id"
                            placeholder="団体ID"
                            validator={validator}
                          />
                        </li>
                        <li className="c-certificationkeys_searchForm_modelInfo_name">
                          <TextInput
                            name="name"
                            placeholder="団体名"
                            validator={validator}
                          />
                        </li>
                        <li className="c-certificationkeys_searchForm_modelInfo_kana">
                          <TextInput
                            name="kana"
                            placeholder="団体名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="c-searchForm_fax">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>FAX番号</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="faxnumber"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="c-certificationkeys_searchForm_modelInfo">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>プラン</label>
                    </li>
                    <li className="c-dataValue">
                      <div className="t-bgBox_gray">
                        <Checkboxes
                          name="plans"
                          choices={plans}
                          isInline={true}
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="l-col_7">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>住所</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="address"
                        placeholder="市区町村以下"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                    </li>
                    <li className="c-dataValue">
                      <GroupedMultiSelect
                        name="prefectureIds"
                        choices={prefectures}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>稼動状況</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="statuses"
                        choices={statuses}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>担当営業</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="salesDepartmentOwnernos"
                        choices={salesDepartmentOwners}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>詳細対応者</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="photographerIds"
                        choices={photographers}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
