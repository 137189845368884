import React from 'react';
import { dateFormat } from '../../../../ts/formatTools';
import { kanriUrl } from '../../../../ts/url';
import { TEvent } from './types';

const LatestEvents: React.FC<{ events: TEvent[] }> = React.memo(
  ({ events }) => {
    const hasNoEventsElement = (
      <div className="c-emptyState_box">登録されたイベントがありません。</div>
    );

    const eventElements = (
      <ul className="c-indexList" v-if="latestRegisterEvents.length">
        {events.map((event) => (
          <li className="c-indexListItem l-flex_between u-w_100" key={event.id}>
            <dl className="p-partnersSummary_namesItem l-flex p-partnersSummary_listItem_latestItemname">
              <dt className="u-mgr_s">
                <span className="c-idLink_hasBg p-partners_idLink u-fz_xs">
                  <i className="c-icon_event u-mgr_xs"></i>
                  <span>{event.id}</span>
                </span>
              </dt>
              <dd className="p-partnersSummary_listItem_detail l-flex">
                <a
                  className="c-textOmit c-textlink"
                  href={kanriUrl({
                    action_owner_EVENTsummary: 'true',
                    eventno: event.id.toString(),
                  })}
                >
                  {event.name}
                </a>
                {!!event.isCancelled && (
                  <span className="c-statusLabel p-partnersSummary_label u-mgl_xs">
                    撮影中止
                  </span>
                )}
              </dd>
            </dl>
            <div className="l-flex_end l-flex_end__isSpInput p-partnersSummary_listItem_latestItemInfo">
              <dl className="p-partnersSummary_namesItem l-flex u-mgr_s p-partnersSummary_listItem_latestItemInfoDetail">
                <dt className="u-mgr_s">
                  <span className="c-idLink_hasBg p-partners_idLink u-fz_xs">
                    <i className="c-icon_groups u-mgr_xs"></i>
                    <span>{event.groupID}</span>
                  </span>
                </dt>
                <dd className="p-partnersSummary_listItem_detail">
                  <a
                    className="c-textOmit c-textlink"
                    href={kanriUrl({
                      action_owner_GROUPsummary: 'true',
                      groupsno: String(event.groupID),
                    })}
                  >
                    {event.groupName}
                  </a>
                </dd>
              </dl>
              <dl className="l-flex">
                <dt className="c-label c-label__monotone u-mgr_s u-fz_xs p-partnersSummary_registrationDate">
                  登録日
                </dt>
                <dd>{dateFormat(event.createdday)}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    );

    return (
      <div className="c-frame">
        <div className="u-mgb_s">
          <h4 className="c-section_title">最新の登録イベント</h4>
        </div>
        {events.length ? eventElements : hasNoEventsElement}
      </div>
    );
  }
);

export default LatestEvents;
