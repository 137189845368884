import React from 'react';
import { Link } from 'react-router-dom';
import Paginator from '@/components/shared/Paginator/App';
import { Items } from './Items';
import { TPhotographerOrganization } from '../types';
import { TPagination } from '@/components/shared/Paginator/types';
import '../style.scss';

export const SearchResult: React.FC<{
  photographerOrganizations: TPhotographerOrganization[];
  pagination: TPagination;
  queryString: string;
}> = ({ photographerOrganizations, pagination, queryString }) => (
  <div className="c-frame">
    <div className="l-flex_between_center u-mgb_s">
      <p className="c-text_resultNumber">
        <span>{pagination?.total}</span>
        <small>件の結果</small>
      </p>
      <div className="l-flex">
        <div className="c-pagination_upper u-mgr_s">
          <Paginator
            pagination={pagination}
            currentPath="/photographer_organizations"
            queryString={queryString}
          />
        </div>
        <div>
          <Link
            className="c-btn_rectangle c-btn_create"
            to="/photographer_organizations/create"
          >
            <i className="c-icon c-icon__small c-icon_create"></i>
          </Link>
        </div>
      </div>
    </div>
    <div className="p-photographerOrganizationIndex_scrollbar">
      <div className="grid_header p-photographerOrganizationIndex_grid_other_department_container_wrap">
        <div className="p-photographerOrganizationIndex_grid_other_department_container">
          <div className="p-photographerOrganizationIndex_grid_row">
            <div
              className="
              p-photographerOrganizationIndex_grid_caption
              p-photographerOrganizationIndex_grid_caption_1st
            "
            >
              カメラマン組織
            </div>
            <div
              className="
              p-photographerOrganizationIndex_grid_caption
              p-photographerOrganizationIndex_grid_caption_2nd
            "
            >
              組織種別
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              適格請求書発行事業者登録番号
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              適格請求書発行事業者登録屋号
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              法人番号
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              所在地
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              所属人数
            </div>
            <div className="p-photographerOrganizationIndex_grid_caption">
              メモ
            </div>
          </div>
        </div>
      </div>
      {photographerOrganizations.map((photographerOrganization) => (
        <div
          className="p-photographerOrganizationIndex_grid_other_department_container"
          key={photographerOrganization.id}
        >
          <Items photographerOrganization={photographerOrganization} />
        </div>
      ))}
    </div>
    <div className="l-flex_between_center u-mgb_s">
      <p className="c-text_resultNumber">
        <span>{pagination?.total}</span>
        <small>件の結果</small>
      </p>
      <div className="l-flex">
        <div className="c-pagination_upper u-mgr_s">
          <Paginator
            pagination={pagination}
            currentPath="/photographer_organizations"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  </div>
);
