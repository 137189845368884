import React, { useCallback, useEffect, useState } from 'react';
import { postJson } from '@/ts/fetch';
import BreadNavi from '../BreadNavi';
import {
  TPrintImageInfo,
  TSaveResponse,
  TShowResponse,
  TTrimmingRequest,
} from './types';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { HideManyModal } from '../modals';
import ErrorMessages from '../../ErrorMessages';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import Cropper from 'react-easy-crop';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberInput, TextInput } from '../../Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '../../Form/types';

const PhotographImage: React.FC<{
  data: TShowResponse['data'];
  selectedPrintImageInfo: TPrintImageInfo | undefined;
  widthMargin: number | undefined;
  heightMargin: number | undefined;
}> = ({ data, selectedPrintImageInfo, widthMargin, heightMargin }) => {
  return (
    <div className="l-flex l-flex_align_start">
      <div className="p-photographsShow_previewBox">
        <img
          className="p-photographsShow_preview"
          src={data.photograph.urlMNoLogo}
          alt=""
        />
        {selectedPrintImageInfo !== undefined && (
          <>
            <div
              className="p-photographsShow_previewMask"
              style={{
                height: '100%',
                width: `${widthMargin}%`,
                top: 0,
                left: 0,
              }}
            />
            <div
              className="p-photographsShow_previewMask"
              style={{
                height: '100%',
                width: `${widthMargin}%`,
                top: 0,
                left: `${100 - widthMargin!}%`,
              }}
            />
            <div
              className="p-photographsShow_previewMask"
              style={{
                width: `${100 - widthMargin! * 2}%`,
                height: `${heightMargin!}%`,
                top: 0,
                left: `${widthMargin}%`,
              }}
            />
            <div
              className="p-photographsShow_previewMask"
              style={{
                width: `${100 - widthMargin! * 2}%`,
                height: `${heightMargin!}%`,
                top: `${100 - heightMargin!}%`,
                left: `${widthMargin}%`,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ShowTrimming: React.FC<{
  trimInfo: NonNullable<TShowResponse['data']['trimInfo']>;
  photograph: TShowResponse['data']['photograph'];
  frameInfo: NonNullable<TShowResponse['data']['typesetterFrame']>;
}> = ({ trimInfo, photograph, frameInfo }) => {
  // トリミング用
  const maxWidth = 600;
  // オリジナル * scale = トリミング+写植に合わせた元画像のサイズ
  const originalWidth = trimInfo.originalWidth * Number(trimInfo.scale);
  const originalHeight = trimInfo.originalHeight * Number(trimInfo.scale);

  let previewScale = 0;
  let previewWidth = 0;
  let previewHeight = 0;
  let styleTrimming: React.CSSProperties = {};
  let styleOriginal: React.CSSProperties = {};
  let trimTop = 0;
  let leftMargin = 0;
  let topMargin = 0;
  const border = 3;

  if (trimInfo.originalWidth > maxWidth) {
    previewScale =
      maxWidth / (originalWidth + frameInfo.leftMargin + frameInfo.rightMargin);
    previewWidth = originalWidth * previewScale;
    previewHeight = originalHeight * previewScale;
    leftMargin = frameInfo.leftMargin * previewScale;
    topMargin = frameInfo.topMargin * previewScale;

    styleOriginal = {
      width: previewWidth + 'px',
    };
    const trimLeft =
      Number(trimInfo.trimLeft) *
      trimInfo.originalWidth *
      Number(trimInfo.scale);
    trimTop =
      Number(trimInfo.trimTop) *
      trimInfo.originalHeight *
      Number(trimInfo.scale);
    styleTrimming = {
      width: Number(trimInfo.trimWidth) * previewScale + border * 2 + 'px',
      transform:
        `translate(` +
        (trimLeft * previewScale - border - leftMargin) +
        `px,` +
        (trimTop * previewScale - border - topMargin) +
        `px)`,
      border: border + `px solid rgba(0,0,255,0.5)`,
      opacity: 0.6,
    };
  } else {
    previewWidth = Number(trimInfo.originalWidth);
    previewHeight = trimInfo.originalHeight;
    previewScale =
      (Number(trimInfo.originalWidth) +
        frameInfo.leftMargin +
        frameInfo.rightMargin) /
      originalWidth;

    styleOriginal = {
      width: trimInfo.originalWidth + 'px',
    };
    const trimLeft = Number(trimInfo.trimLeft) * trimInfo.originalWidth;
    trimTop = Number(trimInfo.trimTop) * trimInfo.originalHeight;
    leftMargin = frameInfo.leftMargin * previewScale;
    topMargin = frameInfo.topMargin * previewScale;
    styleTrimming = {
      width: previewScale * Number(trimInfo.trimWidth) + 'px',
      transform:
        `translate(` +
        (trimLeft - border - leftMargin) +
        `px,` +
        (trimTop - border - topMargin) +
        `px)`,
      outline: `3px solid blue`,
      opacity: 0.6,
    };
  }

  return (
    <div className="">
      <div
        className="p-photographsShow_trimmingSpace"
        style={{
          width: previewWidth + 30 + 'px',
          height: previewHeight + 30 + 'px',
        }}
      >
        {Number(trimInfo.rotateDegree) !== 0 ? (
          '回転している場合は重ねて表示できません'
        ) : (
          <div className="l-flex l-flex_between_center">
            <img
              className="p-photographsShow_OriginalPreview"
              src={photograph.preconvertPath}
              alt=""
              style={styleOriginal}
            />
            <img
              className="p-photographsShow_trimmingPreview"
              src={photograph.urlMNoLogo}
              alt=""
              style={styleTrimming}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const EditTrimming: React.FC<{
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  photograph: TShowResponse['data']['photograph'];
  typesetterFrame: NonNullable<TShowResponse['data']['typesetterFrame']>;
  trimmingComplete: (trims: TTrimmingRequest) => Promise<void>;
}> = ({ validator, photograph, typesetterFrame, trimmingComplete }) => {
  const defaultValues = {
    trimmingX: 0,
    trimmingY: 0,
    trimmingWidth: 0,
    trimmingHeight: 0,
    rotate: 0,
    photographno: photograph.id,
  };
  const methods = useForm({ defaultValues });
  const maxSize = 600;
  const maxHeight = 600 * (photograph.height / photograph.width) + 30;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const zoomRate = 0.01;

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      methods.setValue('trimmingX', croppedAreaPixels.x);
      methods.setValue('trimmingY', croppedAreaPixels.y);
      methods.setValue('trimmingWidth', croppedAreaPixels.width);
      methods.setValue('trimmingHeight', croppedAreaPixels.height);
      methods.setValue('rotate', rotate);
      methods.setValue('photographno', photograph.id);
    },
    [methods, photograph.id, rotate]
  );

  return (
    <div className="">
      <FormProvider {...methods}>
        <div className="l-flex l-flex_align_start">
          <div
            className="p-photographsShow_trimmingSpace"
            style={{
              width: maxSize + 'px',
              height: maxHeight + 'px',
            }}
          >
            <div className="l-flex l-flex_between_center">
              <Cropper
                image={photograph.preconvertPath}
                crop={crop}
                zoom={zoom}
                aspect={
                  (typesetterFrame.outerWidth -
                    typesetterFrame.leftMargin -
                    typesetterFrame.rightMargin) /
                  (typesetterFrame.outerHeight -
                    typesetterFrame.bottomMargin -
                    typesetterFrame.topMargin)
                }
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                rotation={rotate}
              />
            </div>
          </div>
        </div>
        <div className="l-col_wrap">
          <button
            className="c-btn_large c-btn_primary u-mgr_s"
            onClick={methods.handleSubmit(trimmingComplete)}
          >
            完了
          </button>
          <button
            className="c-btn_large c-btn_edit u-mgr_s"
            onClick={() => setRotate(rotate - 0.1)}
          >
            左回転
          </button>
          <button
            className="c-btn_large c-btn_edit u-mgr_s"
            onClick={() => setRotate(rotate + 0.1)}
          >
            右回転
          </button>
          <button
            className="c-btn_large c-btn_edit u-mgr_s"
            onClick={() => setZoom(zoom + zoomRate)}
          >
            拡大
          </button>
          <button
            className="c-btn_large c-btn_edit u-mgr_s"
            onClick={() => (zoom > 1 ? setZoom(zoom - zoomRate) : setZoom(1))}
          >
            縮小
          </button>
          <NumberInput
            name="trimmingX"
            validator={validator}
            additionalClassName="is-hidden"
          ></NumberInput>
          <NumberInput
            name="trimmingY"
            validator={validator}
            additionalClassName="is-hidden"
          ></NumberInput>
          <NumberInput
            name="trimmingWidth"
            validator={validator}
            additionalClassName="is-hidden"
          ></NumberInput>
          <NumberInput
            name="trimmingHeight"
            validator={validator}
            additionalClassName="is-hidden"
          ></NumberInput>
          <NumberInput
            name="rotate"
            validator={validator}
            additionalClassName="is-hidden"
          ></NumberInput>
          <TextInput
            name="photographno"
            validator={validator}
            additionalClassName="is-hidden"
          ></TextInput>
        </div>
      </FormProvider>
    </div>
  );
};

const App: React.FC<{
  response: TShowResponse;
  mutate: (
    f: (d: TShowResponse | undefined) => TShowResponse | undefined
  ) => void;
  handleApiError: (e: unknown, s: string) => string[];
  showSocietyName?: boolean;
  requireHideReason?: boolean;
  isTrimEditable: boolean;
}> = ({
  response,
  mutate,
  handleApiError,
  showSocietyName = true,
  requireHideReason = true,
  isTrimEditable,
}) => {
  const emptyValidator = {
    hasError: false,
    messages: {},
    rules: {},
  };
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [showHideModal, setShowHideModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [modalErrorMessages, setModalErrorMessages] = useState<string[]>([]);
  const [trimmingEdit, setTrimmingEdit] = useState(false);
  const history = useHistory();
  const showPhotograph = usePreventDuplicateCall(async () => {
    try {
      await postJson(`/api/photographs/${response?.data.photograph.id}/show`);
      mutate((response) =>
        response
          ? {
              ...response,
              data: {
                ...data,
                photograph: { ...data.photograph, isHide: false },
              },
            }
          : undefined
      );
      setErrorMessages([]);
      // TODO: validatorの確認
    } catch (e) {
      setErrorMessages(handleApiError(e, '写真を表示にするのに失敗しました'));
    }
  });

  const hidePhotograph = usePreventDuplicateCall(async () => {
    if (requireHideReason) {
      setShowHideModal(true);
      return;
    }
    try {
      await postJson(
        `/api/photographs/${response?.data.photograph.id}/hide`,
        {}
      );
      mutate((response) =>
        response
          ? {
              ...response,
              data: {
                ...data,
                photograph: { ...data.photograph, isHide: true },
              },
            }
          : undefined
      );
      setErrorMessages([]);
    } catch (e) {
      setErrorMessages(handleApiError(e, '写真を非表示にするのに失敗しました'));
    }
  });
  const handleHideConfirmed = usePreventDuplicateCall(
    async (reasons: number[]) => {
      try {
        await postJson(`/api/photographs/hide_many`, {
          photographIds: [response?.data.photograph.id],
          reasons,
        });
        setModalErrorMessages([]);
        mutate((response) =>
          response
            ? {
                ...response,
                data: {
                  ...data,
                  photograph: { ...data.photograph, isHide: true },
                },
              }
            : undefined
        );
        // TODO: validatorの確認
        setShowHideModal(false);
      } catch (e) {
        setModalErrorMessages(
          handleApiError(e, '写真を非表示にするのに失敗しました')
        );
      }
    }
  );
  const trimmingComplete = usePreventDuplicateCall(async (trims) => {
    try {
      const saveResponse = (await postJson(
        `/api/photographs/${response.data.photograph.id}/manual_trimming`,
        {
          ...trims,
        }
      )) as TSaveResponse;
      setModalErrorMessages([]);
      setErrorMessages([]);

      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        return;
      }
      setShowHideModal(false);
      window.location.reload();
    } catch (e) {
      setModalErrorMessages(
        handleApiError(e, '写真のトリミングに失敗しました')
      );
    }
  });

  const data = response.data;
  useEffect(() => {
    const listner = (e: KeyboardEvent) => {
      if (e.code === 'ArrowLeft' && data.prevPhotographId) {
        e.preventDefault();
        history.push(`/photographs/${data.prevPhotographId}`);
      } else if (e.code === 'ArrowRight' && data.nextPhotographId) {
        e.preventDefault();
        history.push(`/photographs/${data.nextPhotographId}`);
      }
    };
    window.document.addEventListener('keydown', listner);
    return () => window.document.removeEventListener('keydown', listner);
  }, [data, history]);
  const selectedPrintImageInfo = data.printImageInfo?.find(
    (info) => info.name === selectedSize
  );
  const printSizeChoices = [
    { value: null, label: '---表示しない---' },
    ...(data.printImageInfo?.map((size) => ({
      value: size.name,
      label: `${size.name} : ${size.printLength.longSide}x${size.printLength.shortSide}`,
    })) || []),
  ];
  const widthMargin =
    data.photograph.width >= data.photograph.height
      ? selectedPrintImageInfo?.considerCutWidth.longSide.percent
      : selectedPrintImageInfo?.considerCutWidth.shortSide.percent;
  const heightMargin =
    data.photograph.width < data.photograph.height
      ? selectedPrintImageInfo?.considerCutWidth.longSide.percent
      : selectedPrintImageInfo?.considerCutWidth.shortSide.percent;

  return (
    <>
      <ErrorMessages messages={errorMessages} />
      <h3 className="c-page_title u-mgb_s">
        {data.photograph.photoname} [{data.photograph.id}]
      </h3>
      <div className="p-photographs_breadnav">
        <BreadNavi
          event={data.event}
          convertinfo={data.convertinfo}
          category={data.category}
          photographCount={data.photographCount}
          isHideParentCategory={data.isHideParentCategory}
          showCategoryLink={true}
          showSocietyName={showSocietyName}
        />
      </div>
      <div>
        {data.prevPhotographId ? (
          <Link to={`/photographs/${data.prevPhotographId}`}>＜ 前の写真</Link>
        ) : (
          <>＜ 前の写真</>
        )}

        <span className="u-mgl_s u-mgr_s">{'|'}</span>
        {data.nextPhotographId ? (
          <Link to={`/photographs/${data.nextPhotographId}`}>次の写真 ＞</Link>
        ) : (
          <>次の写真 ＞</>
        )}
      </div>
      <div className="l-flex">
        {trimmingEdit && data.trimInfo && data.typesetterFrame ? (
          <div className="l-flex_between">
            <ShowTrimming
              trimInfo={data.trimInfo}
              photograph={data.photograph}
              frameInfo={data.typesetterFrame}
            ></ShowTrimming>
            <EditTrimming
              photograph={data.photograph}
              typesetterFrame={data.typesetterFrame}
              trimmingComplete={trimmingComplete}
              validator={validator}
            ></EditTrimming>
          </div>
        ) : (
          <PhotographImage
            data={data}
            selectedPrintImageInfo={selectedPrintImageInfo}
            widthMargin={widthMargin}
            heightMargin={heightMargin}
          ></PhotographImage>
        )}
        <div className="u-mgl_m">
          {data.trimInfo && isTrimEditable ? (
            <div>
              <button
                className="c-btn_large c-btn_edit u-mgb_s"
                onClick={() => setTrimmingEdit(!trimmingEdit)}
              >
                {trimmingEdit ? 'プレビューに戻る' : 'トリミング'}
              </button>
            </div>
          ) : (
            ''
          )}
          {data.printImageInfo && !trimmingEdit && (
            <>
              印刷イメージ
              <br />
              <p className="u-fz_xs">
                ※このイメージは、印刷によって最大これだけ切れる可能性があるというのを可視化したものです。
              </p>
              <Select
                defaultValue={printSizeChoices[0]}
                options={printSizeChoices}
                classnamePrefix="c-input_multiSelect"
                onChange={(value) => setSelectedSize(value?.value || null)}
              />
              {selectedPrintImageInfo && (
                <p className="u-mgt_s">
                  用紙サイズに合わせた上で印刷時に2%拡大すると、
                  <br />
                  長辺が最小で
                  {selectedPrintImageInfo.printMilli.considerLongSideMin.toFixed(
                    2
                  )}
                  mm、 最大で
                  {selectedPrintImageInfo.printMilli.considerLongSideMax.toFixed(
                    2
                  )}
                  mm
                  <br />
                  短辺が最小で
                  {selectedPrintImageInfo.printMilli.considerShortSideMin.toFixed(
                    2
                  )}
                  mm、 最大で
                  {selectedPrintImageInfo.printMilli.considerShortSideMax.toFixed(
                    2
                  )}
                  mm 用紙から切れる。
                </p>
              )}
            </>
          )}
          <div className="u-mgt_m">
            <input
              type="button"
              value="写真を表示にする"
              className={`c-btn_large c-btn_edit u-mgr_s ${
                !data.photograph.isHide ? 'is-disabled' : ''
              }`}
              onClick={showPhotograph}
            />
            <input
              type="button"
              value="写真を非表示にする"
              className={`c-btn_large c-btn_edit u-mgr_s ${
                data.photograph.isHide ? 'is-disabled' : ''
              }`}
              onClick={hidePhotograph}
            />
          </div>
          <table className="u-mgt_m p-photographsShow_table">
            <caption className="p-photographsShow_tableCaption">
              基本情報
            </caption>
            <tbody>
              <tr>
                <td className="p-photographsShow_tableCell">ディレクトリ</td>
                <td className="p-photographsShow_tableCell">
                  {data.photograph.savedir}
                </td>
              </tr>
              <tr>
                <td className="p-photographsShow_tableCell">元ファイルパス</td>
                <td className="p-photographsShow_tableCell">
                  {data.photograph.clientpath}
                </td>
              </tr>
              <tr>
                <td className="p-photographsShow_tableCell">
                  サイズ（幅x高さ）
                </td>
                <td className="p-photographsShow_tableCell">
                  {data.photograph.width}x{data.photograph.height}
                </td>
              </tr>
              <tr>
                <td className="p-photographsShow_tableCell">表示状態</td>
                <td className="p-photographsShow_tableCell">
                  {data.photograph.isHide
                    ? '非表示'
                    : data.photograph.isCategoryHide
                    ? 'カテゴリー非表示'
                    : '表示'}
                </td>
              </tr>
            </tbody>
          </table>
          {data.exif ? (
            <table className="u-mgt_m">
              <caption className="p-photographsShow_tableCaption">EXIF</caption>
              <tbody>
                {data.exif.map((exifRow) => (
                  <tr key={exifRow.key}>
                    <td className="p-photographsShow_tableCell">
                      {exifRow.key}
                    </td>
                    <td className="p-photographsShow_tableCell">
                      {exifRow.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>{data.exifErrorMessage}</>
          )}
        </div>
      </div>
      <div className="l-flex u-mgt_m">
        <div>
          <img
            alt="販売画面一覧表示用"
            src={data.photograph.urlS}
            className="p-photographsShow_thumbnail"
          />
          <p>販売画面一覧表示用</p>
        </div>
        <div className="u-mgl_s">
          <img alt="管理画面サムネ" src={data.photograph.urlSS} />
          <p>管理画面サムネ</p>
        </div>
      </div>
      <div className="u-mgt_m">
        <img alt="販売画面拡大表示用" src={data.photograph.urlM} />
        <p>販売画面拡大表示用</p>
      </div>
      <div className="u-mgt_m">
        <img alt="元画像" src={data.photograph.urlL} />
        <p>元画像</p>
      </div>
      {showHideModal && (
        <HideManyModal
          handleClose={() => setShowHideModal(false)}
          selectedPhotographs={[data.photograph]}
          handleConfirm={handleHideConfirmed}
          hiddenReasons={response.formItems.photograph.hiddenReason.filter(
            (reason) => reason.registerable
          )}
          errorMessages={modalErrorMessages}
        />
      )}
    </>
  );
};

export const useApiUrl = (): string => {
  const { id } = useParams<{ id: string }>();
  return `/api/photographs/${id}`;
};

export default App;
