import { TableRow } from '@/components/shared/Table';
import { useEffect, useState } from 'react';
import { Photograph } from '../../hooks';
import { Link } from 'react-router-dom';
import { kanriUrl } from '@/ts/url';
import { format } from 'date-fns';
import Stack from '@/components/shared/Stack';
import styles from './index.module.scss';

type Props = {
  photographs?: Photograph[];
};

type UseResultPanelReturn = {
  rows?: TableRow[];
};

export const useResultPanel = ({
  photographs,
}: Props): UseResultPanelReturn => {
  const [rows, setRows] = useState<TableRow[]>();

  useEffect(() => {
    if (!photographs) {
      return;
    }

    const newRows = photographs.map((p) => {
      const groupName = p.societyName
        ? p.societyName
        : p.groupName
        ? p.groupName
        : '';

      return [
        <Stack justifyContent="center">
          <Link
            className="c-textlink"
            to={`/photographs/${p.id}`}
            target="_blank"
          >
            {p.thumbnailUrl && (
              <img src={p.thumbnailUrl} alt="" className={styles.image} />
            )}
          </Link>
        </Stack>,
        groupName && (
          <a
            className="c-textlink"
            href={kanriUrl({
              action_owner_GROUPsummary: 'true',
              groupsno: String(p.groupId),
            })}
          >
            {groupName}
          </a>
        ),
        p.eventId > 0 ? (
          <a
            className="c-textlink"
            href={kanriUrl({
              action_owner_EVENTsummary: 'true',
              eventno: String(p.eventId),
            })}
          >
            {p.eventName}
          </a>
        ) : (
          ''
        ),
        <Link
          className="c-textlink"
          to={`/events/${p.eventId}/photographs?categoryno=${p.categoryNo}`}
        >
          {p.categoryName}
        </Link>,
        String(p.id),
        p.name,
        String(p.cameramanId),
        p.photoTypeName,
        format(new Date(p.updatedAt), 'yyyy-MM-dd'),
        <a
          className="c-textlink"
          href={kanriUrl({
            action_Owner_ORDERlist: 'true',
            photographno: String(p.id),
            search: 'true',
          })}
        >
          注文履歴
        </a>,
      ];
    });

    setRows(newRows);
  }, [photographs]);

  return { rows };
};
