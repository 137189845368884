import React, { FC, useState } from 'react';
import {
  IndexResponse,
  TransferPayment,
} from '@/components/pages/PhotographerPayments/Transfers/IndexPage/types';
import Paginator from '@/components/shared/Paginator/App';
import { Link, useLocation } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { dateFormatFromISO8601, numberFormat } from '@/ts/formatTools';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import clsx from 'clsx';

export const TransferredResults: FC<{
  response: IndexResponse;
  isAllChecked: boolean;
  changeAllCheckBox: (allChecked: boolean) => void;
  isCheckedRow: (payment: TransferPayment) => boolean;
  changeCheckBoxRow: (payment: TransferPayment) => (checked: boolean) => void;
  checkedIds: number[];
  setCheckedIds: (ids: number[]) => void;
  mutate: () => void;
}> = ({
  response,
  isAllChecked,
  changeAllCheckBox,
  isCheckedRow,
  changeCheckBoxRow,
  checkedIds,
  setCheckedIds,
  mutate,
}) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClickBulkCancelTransfer = async () => {
    try {
      if (
        !window.confirm(
          '選択した組織の支払を支払前に戻します。\nカメラマンサイト上でも支払完了から支払前に変更されますが、よろしいでしょうか？'
        )
      ) {
        return;
      }
      setIsLoading(true);
      await postJson(`/api/photographer_payments/transfers/bulk_cancel`, {
        ids: checkedIds,
      });
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    } finally {
      setIsLoading(false);
    }
    setCheckedIds([]);
    successToast('支払前に戻す処理が正常に行われました。');
    mutate();
  };

  if (isLoading) {
    return <SvgLoading />;
  }
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={location.search}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <SmallCheckBox
                checked={isAllChecked}
                setChecked={changeAllCheckBox}
              />
            </TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>支払明細</TableCell>
            <TableCell>適格事業者番号</TableCell>
            <TableCell>法人番号</TableCell>
            <TableCell>支払日</TableCell>
            <TableCell>支払金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.payments.map((payment) => (
            <ResultRow
              key={payment.id}
              payment={payment}
              checked={isCheckedRow(payment)}
              setChecked={changeCheckBoxRow(payment)}
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={location.search}
      />
      <ButtonsFooter>
        <button
          disabled={checkedIds.length === 0}
          className={clsx(
            'c-btn_large c-btn_danger u-pdt_s u-pdb_s c-input_submit',
            {
              'is-disabled': checkedIds.length === 0,
            }
          )}
          onClick={handleClickBulkCancelTransfer}
        >
          支払前に戻す
        </button>
      </ButtonsFooter>
    </>
  );
};

const ResultRow: FC<{
  payment: TransferPayment;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ payment, checked, setChecked }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <SmallCheckBox checked={checked} setChecked={setChecked} />
        </TableCell>
        <TableCell>
          <Link
            to={'/photographer_organizations/' + payment.organizationId}
            target="_blank"
          >{`${payment.organizationId} / ${payment.organizationName}`}</Link>
        </TableCell>
        <TableCell>
          {payment.payment > 0 && (
            <Link
              to={`/photographer_payments/transfers/${payment.id}`}
              className="c-textlink"
              target="_blank"
            >
              明細
            </Link>
          )}
        </TableCell>
        <TableCell>{payment.businessRegistrationNumber}</TableCell>
        <TableCell>{payment.corporateNumber}</TableCell>
        <TableCell>{dateFormatFromISO8601(payment.transferredAt)}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {numberFormat(payment.payment)}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
