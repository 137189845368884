import React from 'react';
import { TFormInputs } from '../types';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import './app.scss';

const InputPasswd: React.FC<{
  name: string;
}> = ({ name }) => {
  const { register } = useFormContext();
  return (
    <input type="password" className="c-input_plane" {...register(name)} />
  );
};

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSaveFormProps = {
  ownerno?: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  defaultValues: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    ownerno,
    validator,
    defaultValues,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const methods = useForm<Required<TFormInputs>>({ defaultValues });

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
              管理者ID {ownerno}
            </span>
            <hr className="u-line_plane" />

            {/* パスワード */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel o-owneraccounts-dataLabel">
                    <label>新しいパスワード</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <InputPasswd name="passwd" />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf"></li>
            </ul>

            <section className="o-owneraccounts-sec">
              <h3>パスワードに必要な条件は以下となります。</h3>
              <ul>
                <li>８文字以上３２文字以下で指定してください</li>
                <li>半角の「英文字」「数字」「記号」を使用できます</li>
              </ul>
            </section>

            <div className="u-align_center u-mgb_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
