import React from 'react';
import { Link } from 'react-router-dom';
import { TSocietyInfo } from './types';

const Header: React.FC<TSocietyInfo> = React.memo(
  ({ society, societyContract }) => (
    <div className="c-frame l-flex_center">
      <div className="u-mgr_m">
        <span className="c-label c-label__monotone u-fz_xs">
          団体ID：{society.id}
        </span>
        <span className="u-fz_l">
          <Link to={`/societies/${society.id}`} className="c-textlink">
            {society.name}
          </Link>
        </span>
      </div>
      <div>
        <span className="c-label c-label__monotone u-fz_xs">
          契約ID：{societyContract.id}
        </span>
        <span className="u-fz_l">
          {societyContract.societyContractTypeName}
        </span>
      </div>
    </div>
  )
);

export default Header;
