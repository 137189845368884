import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import QueryString from 'query-string';
import SearchInput from './SearchInput';
import SearchButtons from './SearchButtons';

const SearchBox: React.FC = () => {
  const [isSearchButtonVisible, setIsSearchButtonVisible] = useState(false);
  const location = useLocation();
  const handleFocusStatusChange = (b: boolean): void => {
    setIsSearchButtonVisible(b);
  };
  const [searchWord, setSearchWord] = useState('');
  useEffect(() => {
    const currentSearch = QueryString.parse(location.search)['globalSearch'];
    setSearchWord(typeof currentSearch === 'string' ? currentSearch : '');
  }, [location]);
  const searchWordChanged = useCallback((s: string) => setSearchWord(s), []);
  const nodeRefSearch = React.useRef(null);
  return (
    <div
      className={
        'p-global_searchBox ' +
        (isSearchButtonVisible ? ' p-global_searchBox__isActive' : '')
      }
    >
      <form name="globalSearch" method="GET">
        <div className="l-relative">
          <SearchInput
            searchWord={searchWord}
            onSearchWordChange={searchWordChanged}
            handleFocusStatusChange={handleFocusStatusChange}
          />
          <span className="p-global_search_icon t-textColor_sub">
            <i className="c-icon_search"></i>
          </span>
        </div>
        <CSSTransition
          nodeRef={nodeRefSearch}
          in={isSearchButtonVisible}
          timeout={300}
          mountOnEnter
          unmountOnExit
          classNames="p-global_fadeIn"
        >
          <ul
            ref={nodeRefSearch}
            className="p-global_search_submitList c-clearfix"
          >
            <SearchButtons searchWord={searchWord} type="global" />
          </ul>
        </CSSTransition>
      </form>
    </div>
  );
};

export default SearchBox;
