import { TFormInputs, TPhotography } from '../types';
import { ID_UNCONFIRMED, REQUEST_OPTION_ID_TIME_UNDEFINED } from '../const';
import { LIST_ITEMS, PARENT_LIST_ITEMS } from '../listItem';
import {
  shouldRenderAlbumOption,
  shouldRenderDocumentInformationFromGroup,
  shouldRenderMeetingPlaceBicycleParkingLot,
  shouldRenderMeetingPlaceCarParkingLot,
  shouldRenderPhotographerExpense,
  shouldRenderPhotographingDayCount,
  shouldRenderRentalStepladder,
  shouldRenderRest,
  shouldRenderSignboardPhoto,
  shouldRenderSpecifiedPosition,
  shouldRenderTripod,
} from '../Shared/shouldRenderPhotographyContent';
import {
  shouldRenderChristmas,
  shouldRenderDailyChildcare,
  shouldRenderEntranceCeremony,
  shouldRenderFieldTrip,
  shouldRenderGraduationCeremony,
  shouldRenderHalloween,
  shouldRenderMochiPound,
  shouldRenderOther,
  shouldRenderPool,
  shouldRenderRecital,
  shouldRenderSleepover,
  shouldRenderSportsDay,
  shouldRenderSummerFestival,
  shouldRenderThrowingBeans,
} from '@/components/pages/Events/Show/Photography/Shared/shouldRenderEventContent';
import {
  isCancelDecisionChecked,
  isChristmasChecked,
  isConfirmedWithPremise,
  isEquipmentChecked,
  isGraduationCeremonyChecked,
  isIdUnconfirmed,
  isMochiPoundChecked,
  isOtherChecked,
  isPhotographyChecked,
  isPhotographySubjectsChecked,
  isRecitalChecked,
  isSleepoverChecked,
  isSportsDayChecked,
  isSummerFestivalChecked,
  isTeachersChargeOfPhotographyChecked,
  isTripodChecked,
  isVideographerConfirmed,
} from '@/components/pages/Events/Show/Photography/CheckList/isListItemChecked';

export type TCheckListChild = {
  name: string | ((photography: TPhotography) => string);
  scrollTarget: string;
  visible: (photography: TPhotography, inputValues: TFormInputs) => boolean;
} & (
  | {
      checked: (inputValues: TFormInputs, photography: TPhotography) => boolean;
    }
  | { star: true }
);

export type TCheckListParent = {
  name: string;
  scrollTarget: string;
  children: TCheckListChild[];
};

const documentListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.document.name,
  scrollTarget: PARENT_LIST_ITEMS.document.id,
  children: [
    {
      name: '一覧／追加',
      scrollTarget: PARENT_LIST_ITEMS.document.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.document.documentInformationFromGroup.name,
      scrollTarget: LIST_ITEMS.document.documentInformationFromGroup.id,
      visible: (photography) =>
        shouldRenderDocumentInformationFromGroup(photography),
      checked: () => true,
    },
  ],
};

const basicListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.basic.name,
  scrollTarget: PARENT_LIST_ITEMS.basic.id,
  children: [
    {
      name: LIST_ITEMS.basic.eventName.name,
      scrollTarget: LIST_ITEMS.basic.eventName.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.basic.requestOptions.name,
      scrollTarget: LIST_ITEMS.basic.requestOptions.id,
      visible: () => true,
      checked: (inputValues, photography) =>
        !photography.requestOptions.includes(REQUEST_OPTION_ID_TIME_UNDEFINED),
    },
    {
      name: LIST_ITEMS.basic.photographingDayCount.name,
      scrollTarget: LIST_ITEMS.basic.photographingDayCount.id,
      visible: (photography) => shouldRenderPhotographingDayCount(photography),
      checked: () => true,
    },
    {
      name: LIST_ITEMS.basic.photographingDay.name,
      scrollTarget: LIST_ITEMS.basic.photographingDay.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.basic.photographingTime.name,
      scrollTarget: LIST_ITEMS.basic.photographingTime.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.basic.meetingTime.name,
      scrollTarget: LIST_ITEMS.basic.meetingTime.id,
      visible: () => true,
      checked: () => true,
    },
  ],
};

const meetingPlaceItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.meetingPlace.name,
  scrollTarget: PARENT_LIST_ITEMS.meetingPlace.id,
  children: [
    {
      name: LIST_ITEMS.meetingPlace.meetingPlaceDetail.name,
      scrollTarget: LIST_ITEMS.meetingPlace.meetingPlaceDetail.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.meetingPlace.meetingPlaceAccess.name,
      scrollTarget: LIST_ITEMS.meetingPlace.meetingPlaceAccess.id,
      visible: () => true,
      checked: (inputValues) => inputValues.meetingPlaceAccess !== '',
    },
    {
      name: LIST_ITEMS.meetingPlace.meetingPlaceTransportationType.name,
      scrollTarget: LIST_ITEMS.meetingPlace.meetingPlaceTransportationType.id,
      visible: () => true,
      checked: (inputValues) =>
        inputValues.meetingPlaceTransportationTypeIds.length > 0,
    },
    {
      name: LIST_ITEMS.meetingPlace.meetingPlaceCarParkingLot.name,
      scrollTarget: LIST_ITEMS.meetingPlace.meetingPlaceCarParkingLot.id,
      visible: (photography, inputValues) =>
        shouldRenderMeetingPlaceCarParkingLot(inputValues),
      checked: (inputValues) =>
        inputValues.hasCarParkingLotAtMeetingPlace !== ID_UNCONFIRMED,
    },
    {
      name: LIST_ITEMS.meetingPlace.meetingPlaceBicycleParkingLot.name,
      scrollTarget: LIST_ITEMS.meetingPlace.meetingPlaceBicycleParkingLot.id,
      visible: (photography, inputValues) =>
        shouldRenderMeetingPlaceBicycleParkingLot(inputValues),
      checked: (inputValues) =>
        inputValues.hasBicycleParkingLotAtMeetingPlace !== ID_UNCONFIRMED,
    },
  ],
};

const photographingPlaceItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.photographingPlace.name,
  scrollTarget: PARENT_LIST_ITEMS.photographingPlace.id,
  children: [
    {
      name: LIST_ITEMS.photographingPlace.photographingPlaceDetail.name,
      scrollTarget: LIST_ITEMS.photographingPlace.photographingPlaceDetail.id,
      visible: () => true,
      checked: () => true,
    },
  ],
};

const photographySubjectsItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.photographySubjects.name,
  scrollTarget: PARENT_LIST_ITEMS.photographySubjects.id,
  children: [
    {
      name: LIST_ITEMS.photographySubjects.photographySubjects.name,
      scrollTarget: LIST_ITEMS.photographySubjects.photographySubjects.id,
      visible: () => true,
      checked: (inputValues) => isPhotographySubjectsChecked(inputValues),
    },
    {
      name: LIST_ITEMS.photographySubjects.participantTypes.name,
      scrollTarget: LIST_ITEMS.photographySubjects.participantTypes.id,
      visible: () => true,
      checked: (inputValues) => inputValues.participantTypeIds.length > 0,
    },
    {
      name: LIST_ITEMS.photographySubjects.participantCount.name,
      scrollTarget: LIST_ITEMS.photographySubjects.participantCount.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.photographySubjects.subjectMark.name,
      scrollTarget: LIST_ITEMS.photographySubjects.subjectMark.id,
      visible: () => true,
      checked: () => true,
    },
  ],
};

const addEventTypeSuffix = (eventTypeName: string) => `${eventTypeName}情報`;

const eventListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.event.name,
  scrollTarget: PARENT_LIST_ITEMS.event.id,
  children: [
    {
      name: LIST_ITEMS.event.eventType.name,
      scrollTarget: LIST_ITEMS.event.eventType.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.recital.id,
      visible: (photography) => shouldRenderRecital(photography.eventTypeId),
      checked: (inputValues, photography) =>
        isRecitalChecked(inputValues, photography),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.graduationCeremony.id,
      visible: (photography) =>
        shouldRenderGraduationCeremony(photography.eventTypeId),
      checked: (inputValues, photography) =>
        isGraduationCeremonyChecked(inputValues, photography),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.sportsDay.id,
      visible: (photography) => shouldRenderSportsDay(photography.eventTypeId),
      checked: (inputValues) => isSportsDayChecked(inputValues),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.entranceCeremony.id,
      visible: (photography) =>
        shouldRenderEntranceCeremony(photography.eventTypeId),
      checked: (inputValues, photography) =>
        isConfirmedWithPremise(
          inputValues.hasMedalAward,
          inputValues.medalAwardStyleTypeId
        ) &&
        isVideographerConfirmed(inputValues) &&
        photography.hasDressCode !== null,
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.halloween.id,
      visible: (photography) => shouldRenderHalloween(photography.eventTypeId),
      checked: (inputValues) => !isIdUnconfirmed(inputValues.hasFashionShow),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.christmas.id,
      visible: (photography) => shouldRenderChristmas(photography.eventTypeId),
      checked: (inputValues) => isChristmasChecked(inputValues),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.throwingBeans.id,
      visible: (photography) =>
        shouldRenderThrowingBeans(photography.eventTypeId),
      checked: (inputValues) =>
        isConfirmedWithPremise(
          inputValues.hasOgreArrival,
          inputValues.isCryFacePermittedForOgreArrival
        ),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.mochiPound.id,
      visible: (photography) => shouldRenderMochiPound(photography.eventTypeId),
      checked: (inputValues) => isMochiPoundChecked(inputValues),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.summerFestival.id,
      visible: (photography) =>
        shouldRenderSummerFestival(photography.eventTypeId),
      checked: (inputValues) => isSummerFestivalChecked(inputValues),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.pool.id,
      visible: (photography) => shouldRenderPool(photography.eventTypeId),
      checked: (inputValues) => !isIdUnconfirmed(inputValues.poolClothesTypeId),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.sleepover.id,
      visible: (photography) => shouldRenderSleepover(photography.eventTypeId),
      checked: (inputValues) => isSleepoverChecked(inputValues),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.dailyChildcare.id,
      visible: (photography) =>
        shouldRenderDailyChildcare(photography.eventTypeId),
      checked: (inputValues) => !isIdUnconfirmed(inputValues.hasNapPhoto),
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.fieldTrip.id,
      visible: (photography) => shouldRenderFieldTrip(photography.eventTypeId),
      checked: (inputValues) => inputValues.fieldTripActivityTypeIds.length > 0,
    },
    {
      name: (photography) => addEventTypeSuffix(photography.eventTypeName),
      scrollTarget: LIST_ITEMS.event.other.id,
      visible: (photography) => shouldRenderOther(photography.eventTypeId),
      checked: (inputValues) => isOtherChecked(inputValues),
    },
    {
      name: LIST_ITEMS.event.timeSchedule.name,
      scrollTarget: LIST_ITEMS.event.timeSchedule.id,
      visible: () => true,
      star: true,
    },
    {
      name: LIST_ITEMS.event.emergencyContacts.name,
      scrollTarget: LIST_ITEMS.event.emergencyContacts.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.event.teachersInChargeOfPhotography.name,
      scrollTarget: LIST_ITEMS.event.teachersInChargeOfPhotography.id,
      visible: () => true,
      checked: (inputValues) =>
        isTeachersChargeOfPhotographyChecked(inputValues),
    },
    {
      name: LIST_ITEMS.event.photographerExpense.name,
      scrollTarget: LIST_ITEMS.event.photographerExpense.id,
      visible: (photography) => shouldRenderPhotographerExpense(photography),
      checked: (inputValues) =>
        !isIdUnconfirmed(inputValues.hasPhotographerExpense),
    },
    {
      name: LIST_ITEMS.event.senNote.name,
      scrollTarget: LIST_ITEMS.event.senNote.id,
      visible: () => true,
      checked: () => true,
    },
  ],
};

const photographyListItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.photography.name,
  scrollTarget: PARENT_LIST_ITEMS.photography.id,
  children: [
    {
      name: LIST_ITEMS.photography.prompting.name,
      scrollTarget: LIST_ITEMS.photography.prompting.id,
      visible: () => true,
      checked: () => true,
    },
    {
      name: LIST_ITEMS.photography.arrivalAndDeparturePhoto.name,
      scrollTarget: LIST_ITEMS.photography.arrivalAndDeparturePhoto.id,
      visible: (photography) => true,
      checked: (inputValues) =>
        !isIdUnconfirmed(inputValues.hasArrivalAndDeparturePhoto),
    },
    {
      name: LIST_ITEMS.photography.lunchPhoto.name,
      scrollTarget: LIST_ITEMS.photography.lunchPhoto.id,
      visible: (photography) => true,
      checked: (inputValues) => !isIdUnconfirmed(inputValues.hasLunchPhoto),
    },
    {
      name: LIST_ITEMS.photography.signboardPhoto.name,
      scrollTarget: LIST_ITEMS.photography.signboardPhoto.id,
      visible: (photography) => shouldRenderSignboardPhoto(photography),
      checked: (inputValues) => !isIdUnconfirmed(inputValues.hasSignboardPhoto),
    },
    {
      name: LIST_ITEMS.photography.groupPhoto.name,
      scrollTarget: LIST_ITEMS.photography.groupPhoto.id,
      visible: (photography) => true,
      checked: (inputValues, photography) => photography.hasGroupPhoto !== null,
    },
    {
      name: LIST_ITEMS.photography.transportation.name,
      scrollTarget: LIST_ITEMS.photography.transportation.id,
      visible: (photography) => true,
      checked: (inputValues) =>
        isConfirmedWithPremise(
          inputValues.hasTransportation,
          inputValues.isPhotographerTransportationOnboardPermitted
        ),
    },
    {
      name: LIST_ITEMS.photography.specifiedPosition.name,
      scrollTarget: LIST_ITEMS.photography.specifiedPosition.id,
      visible: (photography) => shouldRenderSpecifiedPosition(photography),
      checked: () => true,
    },
    {
      name: LIST_ITEMS.photography.ngPhotographySubject.name,
      scrollTarget: LIST_ITEMS.photography.ngPhotographySubject.id,
      visible: (photography) => true,
      checked: (inputValues) => true,
    },
    {
      name: LIST_ITEMS.photography.rest.name,
      scrollTarget: LIST_ITEMS.photography.rest.id,
      visible: (photography) => shouldRenderRest(photography),
      checked: (inputValues) => isPhotographyChecked(inputValues),
    },
    {
      name: LIST_ITEMS.photography.albumOption.name,
      scrollTarget: LIST_ITEMS.photography.albumOption.id,
      visible: (photography) => shouldRenderAlbumOption(photography),
      checked: (inputValues) => true,
    },
    {
      name: LIST_ITEMS.photography.equipments.name,
      scrollTarget: LIST_ITEMS.photography.equipments.id,
      visible: (photography) => true,
      checked: (inputValues, photography) =>
        isEquipmentChecked(photography, inputValues),
    },
    {
      name: LIST_ITEMS.photography.belongings.name,
      scrollTarget: LIST_ITEMS.photography.belongings.id,
      visible: (photography) => true,
      checked: (inputValues) => true,
    },
    {
      name: LIST_ITEMS.photography.tripod.name,
      scrollTarget: LIST_ITEMS.photography.tripod.id,
      visible: (photography) => shouldRenderTripod(photography),
      checked: (inputValues) => isTripodChecked(inputValues),
    },
    {
      name: LIST_ITEMS.photography.rentalStepladder.name,
      scrollTarget: LIST_ITEMS.photography.rentalStepladder.id,
      visible: (photography) => shouldRenderRentalStepladder(photography),
      checked: (inputValues) =>
        !isIdUnconfirmed(inputValues.isRentalStepladderAvailable),
    },
    {
      name: LIST_ITEMS.photography.societyRequest.name,
      scrollTarget: LIST_ITEMS.photography.societyRequest.id,
      visible: (photography) => true,
      checked: (inputValues) => true,
    },
  ],
};

const dealingWithUnexpectedSituationsItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.dealingWithUnexpectedSituations.name,
  scrollTarget: PARENT_LIST_ITEMS.dealingWithUnexpectedSituations.id,
  children: [
    {
      name: LIST_ITEMS.dealingWithUnexpectedSituations.rainyDayAction.name,
      scrollTarget:
        LIST_ITEMS.dealingWithUnexpectedSituations.rainyDayAction.id,
      visible: () => true,
      star: true,
    },
    {
      name: LIST_ITEMS.dealingWithUnexpectedSituations.cancelDecision.name,
      scrollTarget:
        LIST_ITEMS.dealingWithUnexpectedSituations.cancelDecision.id,
      visible: () => true,
      checked: (inputValues) => isCancelDecisionChecked(inputValues),
    },
  ],
};

const photographyCautionItem: TCheckListParent = {
  name: PARENT_LIST_ITEMS.photographyCaution.name,
  scrollTarget: PARENT_LIST_ITEMS.photographyCaution.id,
  children: [],
};

export const checkListItems: TCheckListParent[] = [
  documentListItem,
  basicListItem,
  meetingPlaceItem,
  photographingPlaceItem,
  photographySubjectsItem,
  eventListItem,
  photographyListItem,
  dealingWithUnexpectedSituationsItem,
  photographyCautionItem,
];
