import React, { useState, useEffect } from 'react';
import {
  TCategory,
  TCategoryInfos,
  TIndexData,
  TParentCategory,
  TCategoryUpdate,
  TCategoryChangeViewFlagToParent,
} from './types';
import NoImg from '../img/noimage.jpg';
import { kanriUrl } from '@/ts/url';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TChoice,
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import BaseModal from '@/components/shared/BaseModal';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  RadioBoxes,
  SingleSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { postJson, putJson } from '@/ts/fetch';
import { toMessages } from '@/ts/useApi';
import { Link } from 'react-router-dom';
import { alertApiError } from '@/ts/formValidation';
import { errorToast, successToast } from '@/ts/toast';

const PHOTO_TYPE_SNAP = 1;
const ALIGNMENT_DATETIME_ORIGINAL = 1;

const CategoryAddModal: React.FC<{
  handleClose: () => void;
  viewnoChoices: TChoice[];
  phototypemasternoChoices: TChoice[];
  showTypeSelect: boolean;
  handleConfirm: (data: unknown) => Promise<void>;
  isAddingParentCategory: boolean;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  errorMessages: string[];
}> = ({
  handleClose,
  viewnoChoices,
  phototypemasternoChoices,
  showTypeSelect,
  handleConfirm,
  isAddingParentCategory,
  validator,
  errorMessages,
}) => {
  const methods = useForm({
    defaultValues: {
      phototypemasterno: PHOTO_TYPE_SNAP,
    },
  });
  handleConfirm = usePreventDuplicateCall(handleConfirm);
  return (
    <BaseModal handleClose={handleClose}>
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <FormProvider {...methods}>
        <h4 className="c-section_title">
          {isAddingParentCategory ? '親カテゴリ追加' : 'カテゴリ追加'}
        </h4>
        <hr className="u-line_plane" />
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            {isAddingParentCategory ? '親カテゴリ名' : 'カテゴリ名'}
          </li>
          <li className="c-dataValue">
            <TextInput name="name" validator={validator} />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">追加箇所</li>
          <li className="c-dataValue">
            <SingleSelect
              name="viewno"
              validator={validator}
              choices={viewnoChoices}
              menuPortalTarget={document.body}
            />
          </li>
        </ul>
        {showTypeSelect && (
          <ul className="l-flex_between c-label_line">
            <li className="c-dataLabel">タイプ</li>
            <li className="c-dataValue">
              <RadioBoxes
                name="phototypemasterno"
                validator={validator}
                choices={phototypemasternoChoices}
              />
            </li>
          </ul>
        )}
        <p className="u-align_center l-flex_center_line">
          <span
            className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className={`c-btn_large c-btn_primary is-arrow c-input_submit`}
            onClick={methods.handleSubmit(handleConfirm)}
          >
            追加
          </span>
        </p>
      </FormProvider>
    </BaseModal>
  );
};

const CategoryUpdateModal: React.FC<{
  handleClose: () => void;
  categoryName: string;
  viewflagChoices: TChoice[];
  defaultViewflag: number;
  defaultPhototype: number;
  phototypemasternoChoices: TChoice[];
  handleConfirm: (data: unknown) => Promise<void>;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  errorMessages: string[];
}> = ({
  handleClose,
  categoryName,
  viewflagChoices,
  defaultViewflag,
  phototypemasternoChoices,
  defaultPhototype,
  handleConfirm,
  validator,
  errorMessages,
}) => {
  const methods = useForm({
    defaultValues: {
      name: categoryName,
      viewflag: defaultViewflag,
      phototypemasterno: defaultPhototype,
    },
  });
  handleConfirm = usePreventDuplicateCall(handleConfirm);
  return (
    <BaseModal handleClose={handleClose}>
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <FormProvider {...methods}>
        <h4 className="c-section_title">カテゴリ変更</h4>
        <hr className="u-line_plane" />
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">カテゴリ名</li>
          <li className="c-dataValue">
            <TextInput name="name" validator={validator} />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">表示設定</li>
          <li className="c-dataValue">
            <RadioBoxes
              name="viewflag"
              validator={validator}
              choices={viewflagChoices}
            />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">タイプ</li>
          <li className="c-dataValue">
            <RadioBoxes
              name="phototypemasterno"
              validator={validator}
              choices={phototypemasternoChoices}
            />
          </li>
        </ul>
        <p className="u-align_center l-flex_center_line">
          <span
            className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className={`c-btn_large c-btn_primary is-arrow c-input_submit`}
            onClick={methods.handleSubmit(handleConfirm)}
          >
            変更
          </span>
        </p>
      </FormProvider>
    </BaseModal>
  );
};

const ParentCategoryUpdateModal: React.FC<{
  handleClose: () => void;
  parentCategoryName: string;
  viewflagChoices: TChoice[];
  defaultViewflag: number;
  handleConfirm: (data: unknown) => Promise<void>;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  errorMessages: string[];
}> = ({
  handleClose,
  parentCategoryName,
  viewflagChoices,
  defaultViewflag,
  handleConfirm,
  validator,
  errorMessages,
}) => {
  const methods = useForm({
    defaultValues: {
      name: parentCategoryName,
      viewflag: defaultViewflag,
    },
  });
  handleConfirm = usePreventDuplicateCall(handleConfirm);
  return (
    <BaseModal handleClose={handleClose}>
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <FormProvider {...methods}>
        <h4 className="c-section_title">親カテゴリ変更</h4>
        <hr className="u-line_plane" />
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">親カテゴリ名</li>
          <li className="c-dataValue">
            <TextInput name="name" validator={validator} />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">表示設定</li>
          <li className="c-dataValue">
            <RadioBoxes
              name="viewflag"
              validator={validator}
              choices={viewflagChoices}
            />
          </li>
        </ul>
        <p className="u-align_center l-flex_center_line">
          <span
            className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className={`c-btn_large c-btn_primary is-arrow c-input_submit`}
            onClick={methods.handleSubmit(handleConfirm)}
          >
            変更
          </span>
        </p>
      </FormProvider>
    </BaseModal>
  );
};

const useCategoryAddModalParams = (
  data: TCategoryInfos,
  convertInfoNo: number,
  validators: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  },
  formItems: {
    category: {
      phototypemasterno: TChoice[];
    };
  },
  categoriesUpdated: () => void
): {
  showModal: boolean;
  handleAddCategory: (parentCategory: TParentCategory) => void;
  handleAddTopLevelCategory: () => void;
  props: Parameters<typeof CategoryAddModal>[0];
} => {
  const [showModal, setShowModal] = useState(false);
  const [viewnoChoices, setViewnoChoices] = useState<TChoice[]>([]);
  const [isAddingParentCategory, setIsAddingParentCategory] = useState(false);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showTypeSelect, setShowTypeSelect] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState<
    (data: unknown) => Promise<void>
  >(() => Promise.resolve());
  const handleAddCategory = (parentCategory: TParentCategory) => {
    setViewnoChoices([
      { key: 1, value: '一番上' },
      ...parentCategory.categories.map(({ viewno, categoryname }) => ({
        key: viewno + 1,
        value: `「${categoryname}」の下`,
      })),
    ]);
    setIsAddingParentCategory(false);
    setValidator(validators.categorySave);
    setShowModal(true);
    setShowTypeSelect(true);
    setErrorMessages([]);
    setHandleConfirm(() => async (data: unknown) => {
      try {
        const response = await postJson<{ validator: TValidatorResponse }>(
          `/api/parent_categories/${parentCategory.parentcategoryno}/categories`,
          data
        );
        if (response.validator.hasError) {
          setValidator(response.validator);
          alertApiError('更新に失敗しました');
          return;
        }
        setShowModal(false);
        categoriesUpdated();
      } catch (e) {
        setErrorMessages(toMessages(e));
      }
    });
  };
  const handleAddTopLevelCategory = () => {
    const parentCategories = data.parentCategories;
    if (!parentCategories) {
      return;
    }
    if (!data.isVisibleParentCategory) {
      handleAddCategory(parentCategories[0]);
      return;
    }
    setViewnoChoices([
      { key: 1, value: '一番上' },
      ...parentCategories.map(({ viewno, parentcategoryname }) => ({
        key: viewno + 1,
        value: `「${parentcategoryname}」の下`,
      })),
    ]);
    setIsAddingParentCategory(true);
    setValidator(validators.parentCategorySave);
    setShowModal(true);
    setShowTypeSelect(false);
    setErrorMessages([]);
    setHandleConfirm(() => async (data: unknown) => {
      try {
        const saveResponse = await postJson<{ validator: TValidatorResponse }>(
          `/api/convertinfos/${convertInfoNo}/parent_categories`,
          data
        );
        if (saveResponse.validator.hasError) {
          setValidator(saveResponse.validator);
          alertApiError();
          return;
        }
        setShowModal(false);
        categoriesUpdated();
      } catch (e) {
        setErrorMessages(toMessages(e));
      }
    });
  };
  return {
    showModal: showModal,
    handleAddCategory,
    handleAddTopLevelCategory,
    props: {
      handleClose: () => {
        setShowModal(false);
      },
      viewnoChoices,
      phototypemasternoChoices: formItems.category.phototypemasterno,
      showTypeSelect,
      handleConfirm,
      isAddingParentCategory,
      validator,
      errorMessages,
    },
  };
};

const useCategoryUpdateModalParams = (
  validators: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  },
  formItems: {
    category: {
      phototypemasterno: TChoice[];
    };
  },
  categoriesUpdated: () => void
): {
  showModal: boolean;
  handleUpdateCategory: (category: TCategory) => void;
  props: Parameters<typeof CategoryUpdateModal>[0];
} => {
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [viewflagChoices, setViewflagChoices] = useState<TChoice[]>([]);
  const [defaultViewflag, setDefaultViewflag] = useState(0);
  const [defaultPhototype, setDefaultPhototype] = useState(1);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [handleConfirm, setHandleConfirm] = useState<
    (data: unknown) => Promise<void>
  >(() => Promise.resolve());
  const handleUpdateCategory = (category: TCategory) => {
    setValidator(validators.categorySave);
    setShowModal(true);
    setCategoryName(category.categoryname);
    setViewflagChoices([
      { key: 0, value: '表示' },
      { key: 1, value: '非表示' },
    ]);
    setDefaultViewflag(category.viewflag ? 1 : 0);
    setDefaultPhototype(Number(category.phototype));
    setErrorMessages([]);
    setHandleConfirm(() => async (data: unknown) => {
      try {
        const response = await putJson<{ validator: TValidatorResponse }>(
          `/api/categories/${category.categoryno}`,
          data
        );
        if (response.validator.hasError) {
          setValidator(response.validator);
          alertApiError('更新に失敗しました');
          return;
        }
        setShowModal(false);
        categoriesUpdated();
      } catch (e) {
        setErrorMessages(toMessages(e));
      }
    });
  };
  return {
    showModal: showModal,
    handleUpdateCategory,
    props: {
      handleClose: () => {
        setShowModal(false);
      },
      categoryName,
      viewflagChoices,
      defaultViewflag,
      defaultPhototype,
      phototypemasternoChoices: formItems.category.phototypemasterno,
      handleConfirm,
      validator,
      errorMessages,
    },
  };
};

const useParentCategoryUpdateModalParams = (
  validators: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  },
  categoriesUpdated: () => void
): {
  showModal: boolean;
  handleUpdateParentCategory: (parentCategory: TParentCategory) => void;
  props: Parameters<typeof ParentCategoryUpdateModal>[0];
} => {
  const [showModal, setShowModal] = useState(false);
  const [parentCategoryName, setParentCategoryName] = useState('');
  const [viewflagChoices, setViewflagChoices] = useState<TChoice[]>([]);
  const [defaultViewflag, setDefaultViewflag] = useState(0);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [handleConfirm, setHandleConfirm] = useState<
    (data: unknown) => Promise<void>
  >(() => Promise.resolve());
  const handleUpdateParentCategory = (parentCategory: TParentCategory) => {
    setValidator(validators.categorySave);
    setShowModal(true);
    setParentCategoryName(parentCategory.parentcategoryname);
    setViewflagChoices([
      { key: 0, value: '表示' },
      { key: 1, value: '非表示' },
    ]);
    setDefaultViewflag(parentCategory.viewflag ? 1 : 0);
    setErrorMessages([]);
    setHandleConfirm(() => async (data: unknown) => {
      try {
        const response = await putJson<{ validator: TValidatorResponse }>(
          `/api/parent_categories/${parentCategory.parentcategoryno}`,
          data
        );
        if (response.validator.hasError) {
          setValidator(response.validator);
          alertApiError('更新に失敗しました');
          return;
        }
        setShowModal(false);
        categoriesUpdated();
      } catch (e) {
        setErrorMessages(toMessages(e));
      }
    });
  };
  return {
    showModal: showModal,
    handleUpdateParentCategory,
    props: {
      handleClose: () => {
        setShowModal(false);
      },
      parentCategoryName,
      viewflagChoices,
      defaultViewflag,
      handleConfirm,
      validator,
      errorMessages,
    },
  };
};

const useCategoryChildrenUpdateParams = (
  validators: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  },
  formItems: {
    category: {
      phototypemasterno: TChoice[];
    };
  },
  categoriesUpdated: () => void
): {
  viewflagChoices: TChoice[];
  phototypemasternoChoices: TChoice[];
  showForm: boolean;
  handleUpdateCategory: (category: TCategoryInfos) => void;
  handleConfirm: (data: unknown) => Promise<void>;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  errorMessages: string[];
} => {
  const [showForm, setShowForm] = useState(false);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [handleConfirm, setHandleConfirm] = useState<
    (data: unknown) => Promise<void>
  >(() => Promise.resolve());
  const handleUpdateCategory = (category: TCategoryInfos) => {
    setValidator(validators.categorySave);
    setShowForm(!showForm);
    setErrorMessages([]);
    setHandleConfirm(() => async (data: unknown) => {
      try {
        successToast('更新中');
        const response = await putJson<{ validator: TValidatorResponse }>(
          `/api/all_categories_update`,
          data
        );
        if (response.validator.hasError) {
          setValidator(response.validator);
          errorToast('更新に失敗しました');
          alertApiError('更新に失敗しました');
          return;
        }
        categoriesUpdated();
      } catch (e) {
        toMessages(e).forEach((err) => {
          errorToast(err);
        });
        setErrorMessages(toMessages(e));
      }
    });
  };
  return {
    viewflagChoices: [
      { key: 1, value: '非表示' },
      { key: 0, value: '表示' },
    ],
    phototypemasternoChoices: formItems.category.phototypemasterno,
    showForm,
    handleUpdateCategory,
    handleConfirm,
    validator,
    errorMessages,
  };
};

const ParentCategoryRow: React.FC<{
  parentCategory: TParentCategory;
  hasPhotoType: boolean;
  handleAddCategory: (parentCategory: TParentCategory) => void;
  validator: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  };
  handleUpdate: () => void;
}> = ({
  parentCategory,
  hasPhotoType,
  handleAddCategory,
  validator,
  handleUpdate,
}) => {
  const {
    showModal: showCategoryUpdateModal,
    handleUpdateParentCategory,
    props: parentCategoryUpdateModalProps,
  } = useParentCategoryUpdateModalParams(validator, handleUpdate);
  const viewno = ('0' + parentCategory.viewno).slice(-2);
  const isHidden = parentCategory.viewflag;
  return (
    <>
      <tr
        className={
          isHidden
            ? 'p-eventShow_categoryList_parentHiddenRow'
            : 'p-eventShow_categoryList_parentRow'
        }
      >
        <th></th>
        <th colSpan={2}>{`${viewno} ${parentCategory.parentcategoryname}`}</th>
        <th>
          <span>{isHidden ? '非表示' : '表示'}</span>
        </th>
        {hasPhotoType ? <th></th> : ''}
        <th>
          <button
            type="button"
            className="c-btn c-btn_primary c-btn_small u-mgr_s"
            onClick={() => handleAddCategory(parentCategory)}
          >
            <i className="c-icon c-icon_create" />
          </button>
          <button
            type="button"
            className="c-btn c-btn_small c-btn_edit"
            onClick={() => handleUpdateParentCategory(parentCategory)}
          >
            <i className="c-icon c-icon_edit" />
          </button>
        </th>
      </tr>
      {showCategoryUpdateModal && (
        <ParentCategoryUpdateModal
          {...parentCategoryUpdateModalProps}
        ></ParentCategoryUpdateModal>
      )}
    </>
  );
};

const CategoryRow: React.FC<{
  category: TCategory;
  hasPhotoType: boolean;
  eventId: number;
  isFirstCategory: boolean;
  isLastCategory: boolean;
  parentIsHidden: boolean;
  alignment: number;
  validator: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  };
  formItems: {
    category: {
      phototypemasterno: TChoice[];
    };
  };
  handleUpdate: () => void;
}> = ({
  category,
  hasPhotoType,
  eventId,
  isFirstCategory,
  isLastCategory,
  parentIsHidden,
  alignment,
  validator,
  formItems,
  handleUpdate,
}) => {
  const {
    showModal: showCategoryUpdateModal,
    handleUpdateCategory,
    props: categoryUpdateModalProps,
  } = useCategoryUpdateModalParams(validator, formItems, handleUpdate);
  const viewno = ('0' + category.viewno).slice(-2);
  const isHidden = category.viewflag;
  const categoryInvisible = category.categoryInvisible;
  const changeViewNoUpLink = kanriUrl({
    action_owner_CATEGORYchangeviewno: String(true),
    categoryno: String(category.categoryno),
    convertinfono: String(category.convertinfono),
    eventno: String(category.eventno),
    mypage: 'EVENTsummary',
    mode: 'moveUp',
  });
  const changeViewNoDownLink = kanriUrl({
    action_owner_CATEGORYchangeviewno: String(true),
    categoryno: String(category.categoryno),
    convertinfono: String(category.convertinfono),
    eventno: String(category.eventno),
    mypage: 'EVENTsummary',
    mode: 'moveDown',
  });
  const alignmentString =
    alignment === ALIGNMENT_DATETIME_ORIGINAL
      ? 'datetimeoriginal'
      : 'photographno';
  return (
    <tr
      className={
        isHidden || parentIsHidden || categoryInvisible
          ? 'p-eventShow_categoryList_hiddenRow'
          : ''
      }
    >
      <th>
        {!isFirstCategory && (
          <a href={changeViewNoUpLink} className="u-mgr_s">
            ▲
          </a>
        )}
        {!isLastCategory && <a href={changeViewNoDownLink}>▼</a>}
      </th>
      <th>
        <Link
          className="c-textlink"
          to={`/events/${eventId}/photographs?categoryno=${category.categoryno}&sort=${alignmentString}`}
        >{`${viewno} ${category.categoryname}`}</Link>
      </th>
      <th className="p-eventShow_categoriesThumbnail_img">
        <img
          className="p-eventShow_categoriesThumbnail"
          src={category.imageUrl || NoImg}
          alt="サムネイル"
        />
      </th>
      <th>
        <span>
          {isHidden
            ? '非表示'
            : parentIsHidden || categoryInvisible
            ? ''
            : '表示'}
        </span>
      </th>
      {hasPhotoType && <th>{category.phototypeName}</th>}
      <th>
        <button
          type="button"
          className="c-btn c-btn_small c-btn_edit"
          onClick={() => handleUpdateCategory(category)}
        >
          <i className="c-icon c-icon_edit" />
        </button>
      </th>
      {showCategoryUpdateModal && (
        <CategoryUpdateModal
          {...categoryUpdateModalProps}
        ></CategoryUpdateModal>
      )}
    </tr>
  );
};

const ParentCategoryRowForm: React.FC<{
  parentCategory: TParentCategory;
  hasPhotoType: boolean;
  handleAddCategory: (parentCategory: TParentCategory) => void;
  validator: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  };
  viewflagChoices: TChoice[];
  handleUpdate: () => void;
}> = ({
  parentCategory,
  hasPhotoType,
  handleAddCategory,
  validator,
  viewflagChoices,
  handleUpdate,
}) => {
  const viewno = ('0' + parentCategory.viewno).slice(-2);
  const isHidden = parentCategory.viewflag;
  return (
    <>
      <tr
        className={
          isHidden
            ? 'p-eventShow_categoryList_parentHiddenRow'
            : 'p-eventShow_categoryList_parentRow'
        }
      >
        <th></th>
        <th colSpan={2}>
          <TextInput
            name={`parentCategory.${parentCategory.parentcategoryno}.name`}
            validator={validator.parentCategorySave}
          />
          <br />
          変更前：{`${viewno} ${parentCategory.parentcategoryname}`}
        </th>
        <th>
          <RadioBoxes
            name={`parentCategory.${parentCategory.parentcategoryno}.viewflag`}
            validator={validator.parentCategorySave}
            choices={viewflagChoices}
          />
          <br />
          変更前：<span>{isHidden ? '非表示' : '表示'}</span>
        </th>
        {hasPhotoType ? <th></th> : ''}
        <th></th>
      </tr>
    </>
  );
};
const CategoryRowForm: React.FC<{
  category: TCategory;
  hasPhotoType: boolean;
  eventId: number;
  parentIsHidden: boolean;
  alignment: number;
  validator: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  };
  viewflagChoices: TChoice[];
  phototypemasternoChoices: TChoice[];
}> = ({
  category,
  hasPhotoType,
  eventId,
  parentIsHidden,
  alignment,
  validator,
  viewflagChoices,
  phototypemasternoChoices,
}) => {
  const viewno = ('0' + category.viewno).slice(-2);
  const isHidden = category.viewflag;
  const categoryInvisible = category.categoryInvisible;
  const alignmentString =
    alignment === ALIGNMENT_DATETIME_ORIGINAL
      ? 'datetimeoriginal'
      : 'photographno';

  return (
    <tr
      className={
        isHidden || parentIsHidden || categoryInvisible
          ? 'p-eventShow_categoryList_hiddenRow'
          : ''
      }
    >
      <th></th>
      <th>
        <TextInput
          name={`category.${category.categoryno}.name`}
          validator={validator.categorySave}
        />
        <br />
        変更前：
        <Link
          className="c-textlink"
          to={`/events/${eventId}/photographs?categoryno=${category.categoryno}&sort=${alignmentString}`}
        >{`${viewno} ${category.categoryname}`}</Link>
      </th>
      <th className="p-eventShow_categoriesThumbnail_img">
        <img
          className="p-eventShow_categoriesThumbnail"
          src={category.imageUrl || NoImg}
          alt="サムネイル"
        />
      </th>
      <th>
        <span>
          <RadioBoxes
            name={`category.${category.categoryno}.viewflag`}
            validator={validator.categorySave}
            choices={viewflagChoices}
          />

          <br />
          {isHidden
            ? '変更前：非表示'
            : parentIsHidden || categoryInvisible
            ? ''
            : '変更前：表示'}
        </span>
      </th>
      {hasPhotoType && (
        <th>
          <RadioBoxes
            name={`category.${category.categoryno}.phototypemasterno`}
            validator={validator.categorySave}
            choices={phototypemasternoChoices}
          />
          <br />
          変更前：
          {category.phototypeName}
        </th>
      )}
      <th></th>
    </tr>
  );
};

const CategoryList: React.FC<{
  data: TIndexData;
  validators: {
    categorySave: TOnlyValidationRuleResponse;
    parentCategorySave: TOnlyValidationRuleResponse;
  };
  formItems: {
    category: {
      phototypemasterno: TChoice[];
    };
  };
  handleUpdate: () => void;
}> = ({ data, validators, formItems, handleUpdate }) => {
  const {
    showModal: showCategoryAddModal,
    handleAddCategory,
    handleAddTopLevelCategory,
    props: categoryAddModalProps,
  } = useCategoryAddModalParams(
    data.categoryInfos,
    data.main.convertInfo.convertInfoNo,
    validators,
    formItems,
    handleUpdate
  );

  const {
    viewflagChoices,
    phototypemasternoChoices,
    showForm,
    handleUpdateCategory,
    handleConfirm,
    validator,
    errorMessages,
  } = useCategoryChildrenUpdateParams(validators, formItems, handleUpdate);
  const preventDuplicateCallHandle = usePreventDuplicateCall(handleConfirm);

  const categoryDefaultValues: TCategoryUpdate = {
    parentCategory: {},
    category: {},
  };
  const categoryChangeViewFlagToParent: TCategoryChangeViewFlagToParent = {};
  data.categoryInfos.parentCategories?.forEach((parentCategory) => {
    categoryDefaultValues['parentCategory'][parentCategory.parentcategoryno] = {
      name: parentCategory.parentcategoryname,
      viewflag: parentCategory.viewflag ? 1 : 0,
    };
    parentCategory.categories.forEach((category) => {
      categoryChangeViewFlagToParent[String(category.categoryno)] = {
        parentCategoryNo: String(parentCategory.parentcategoryno),
      };
      categoryDefaultValues['category'][category.categoryno] = {
        name: category.categoryname,
        viewflag: category.viewflag ? 1 : 0,
        phototypemasterno: Number(category.phototype),
      };
    });
  });
  const methods = useForm({
    defaultValues: categoryDefaultValues,
  });
  useEffect(() => {
    // 大カテゴリが表示の場合のみ処理
    if (!data.categoryInfos.isVisibleParentCategory) {
      return;
    }
    const subscription = methods.watch((value, { name, type }) => {
      if (!name || !value || !value.category) {
        return;
      }
      const [categoryType, categoryNo]: Array<string> = name.split('.');
      if (
        categoryType !== 'category' ||
        value.category[categoryNo]?.viewflag !== 0
      ) {
        return;
      }
      methods.setValue(
        `parentCategory.${categoryChangeViewFlagToParent[categoryNo]['parentCategoryNo']}.viewflag`,
        0
      );
    });

    return () => subscription.unsubscribe();
  });

  const categoryViewChecked = (flag: number) => {
    // 大カテゴリが表示の場合のみ大カテゴリを一括チェック対象にする
    if (data.categoryInfos.isVisibleParentCategory) {
      const parentCategoryValues = methods.getValues('parentCategory');
      Object.keys(parentCategoryValues).forEach((key) => {
        parentCategoryValues[key].viewflag = flag;
      });
      methods.setValue('parentCategory', parentCategoryValues);
    }

    const categoryValues = methods.getValues('category');
    Object.keys(categoryValues).forEach((key) => {
      categoryValues[key].viewflag = flag;
    });
    methods.setValue('category', categoryValues);
  };

  const childrenPhototypeChecked = (flag: number) => {
    const getvalues = methods.getValues('category');
    Object.keys(getvalues).forEach((key) => {
      getvalues[key].phototypemasterno = flag;
    });
    methods.setValue('category', getvalues);
  };

  const hasPhotoType = data.categoryInfos.hasPhotoType;
  return (
    <>
      <table className="p-eventShow_categoryList">
        <thead>
          <tr>
            <th></th>
            <th>カテゴリ</th>
            <th>代表写真</th>
            <th>非表示フラグ</th>
            {hasPhotoType && <th>タイプ</th>}
            <th>
              <div className="l-flex nowrap">
                <button
                  type="button"
                  className="c-btn c-btn_primary c-btn_small"
                  onClick={handleAddTopLevelCategory}
                >
                  <i className="c-icon c-icon_create" />
                </button>
                <button
                  type="button"
                  className="c-btn c-btn_small u-mgl_s c-btn_edit"
                  onClick={() => handleUpdateCategory(data.categoryInfos)}
                >
                  <i className="c-icon c-icon_edit" />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {showForm ? (
            <FormProvider {...methods}>
              <tr>
                <th colSpan={3}>
                  <ErrorMessages messages={errorMessages} />
                  <ApiErrors {...validator} />
                </th>
                <th>
                  <span>
                    <label className="c-radioLabel">
                      <input
                        className="c-radioBtn"
                        type="radio"
                        name="view"
                        onClick={() => categoryViewChecked(1)}
                      />
                      <span className="c-label_radioBtn">全て非表示</span>
                    </label>
                    <label className="c-radioLabel">
                      <input
                        className="c-radioBtn"
                        type="radio"
                        name="view"
                        onClick={() => categoryViewChecked(0)}
                      />
                      <span className="c-label_radioBtn">全て表示</span>
                    </label>
                  </span>
                </th>
                <th>
                  <span>
                    <label className="c-radioLabel">
                      <input
                        className="c-radioBtn"
                        type="radio"
                        name="phototype"
                        onClick={() => childrenPhototypeChecked(1)}
                      />
                      <span className="c-label_radioBtn">全てスナップ</span>
                    </label>
                    <label className="c-radioLabel">
                      <input
                        className="c-radioBtn"
                        type="radio"
                        name="phototype"
                        onClick={() => childrenPhototypeChecked(2)}
                      />
                      <span className="c-label_radioBtn">全て集合</span>
                    </label>
                  </span>
                </th>
                <th></th>
              </tr>
              {data.categoryInfos.parentCategories?.map((parentCategory) => (
                <React.Fragment key={parentCategory.parentcategoryno}>
                  {data.categoryInfos.isVisibleParentCategory && (
                    <ParentCategoryRowForm
                      parentCategory={parentCategory}
                      hasPhotoType={hasPhotoType}
                      handleAddCategory={handleAddCategory}
                      key={parentCategory.parentcategoryno}
                      validator={validators}
                      viewflagChoices={viewflagChoices}
                      handleUpdate={handleUpdate}
                    />
                  )}

                  {parentCategory.categories.map((category, idx) => (
                    <CategoryRowForm
                      category={category}
                      hasPhotoType={hasPhotoType}
                      key={category.categoryno}
                      eventId={data.main.eventNo}
                      parentIsHidden={parentCategory.viewflag}
                      alignment={data.main.alignment}
                      validator={validators}
                      viewflagChoices={viewflagChoices}
                      phototypemasternoChoices={phototypemasternoChoices}
                    />
                  ))}
                </React.Fragment>
              ))}
              <tr>
                <th colSpan={6}>
                  <p className="u-align_center l-flex_center_line">
                    <span
                      className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
                      onClick={() => handleUpdateCategory(data.categoryInfos)}
                    >
                      キャンセル
                    </span>
                    <span
                      className={`c-btn_large c-btn_primary is-arrow c-input_submit`}
                      onClick={methods.handleSubmit(preventDuplicateCallHandle)}
                    >
                      変更
                    </span>
                  </p>
                </th>
              </tr>
            </FormProvider>
          ) : (
            <>
              {data.categoryInfos.parentCategories?.map((parentCategory) => (
                <React.Fragment key={parentCategory.parentcategoryno}>
                  {data.categoryInfos.isVisibleParentCategory && (
                    <ParentCategoryRow
                      parentCategory={parentCategory}
                      hasPhotoType={hasPhotoType}
                      handleAddCategory={handleAddCategory}
                      key={parentCategory.parentcategoryno}
                      validator={validators}
                      handleUpdate={handleUpdate}
                    />
                  )}

                  {parentCategory.categories.map((category, idx) => (
                    <CategoryRow
                      category={category}
                      hasPhotoType={hasPhotoType}
                      key={category.categoryno}
                      eventId={data.main.eventNo}
                      isFirstCategory={idx === 0}
                      isLastCategory={
                        idx === parentCategory.categories.length - 1
                      }
                      parentIsHidden={parentCategory.viewflag}
                      alignment={data.main.alignment}
                      validator={validators}
                      formItems={formItems}
                      handleUpdate={handleUpdate}
                    />
                  ))}
                </React.Fragment>
              ))}
            </>
          )}
        </tbody>
      </table>

      {showCategoryAddModal && (
        <CategoryAddModal {...categoryAddModalProps}></CategoryAddModal>
      )}
    </>
  );
};

export default CategoryList;
