import React from 'react';
import './style.scss';
export const ProfileHeader: React.FC<{ pageTitle: string }> = ({
  pageTitle,
}) => {
  return (
    <>
      <MenuLink />
      <div className="u-mgb_m">
        <h1 className="l-flex_center">{pageTitle}</h1>
      </div>
    </>
  );
};
const MenuLink: React.FC = () => {
  return (
    <div className="p-photographersForm_pageLinkArea l-flex">
      <a href={'#p-photographers_basic-info'}>
        <div className={'l-flex_start'}>
          <div className={'page_link_icon'}>&#8810;</div>
          <div>基本情報</div>
        </div>
      </a>
      <a href={'#p-photographers_detail-info'}>
        <div className={'l-flex_start'}>
          <div className={'page_link_icon'}>&#8810;</div>
          <div>業務情報</div>
        </div>
      </a>
      <a href={'#p-photographers_equipment'}>
        <div className={'l-flex_start'}>
          <div className={'page_link_icon'}>&#8810;</div>
          <div>機材情報</div>
        </div>
      </a>
    </div>
  );
};
