import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '../../const';
import { Alert } from '@/components/shared/Alert';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

export const RentalStepladder: React.FC<{ validator: TValidator }> = ({
  validator,
}) => {
  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.rentalStepladder.name}
      id={LIST_ITEMS.photography.rentalStepladder.id}
    >
      <RadioBoxes
        name="isRentalStepladderAvailable"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: '可能' },
          { key: 0, value: '不可' },
        ]}
      />
      <Alert type="warning" title="脚立・踏み台について">
        ※脚立／踏み台はパイプ椅子を除きます。
        <br />
        ※原則、カメラマンの脚立持参はお断りさせていただいております。
        <br />
        ※貸出不可の場合、撮影位置によって観覧者の後頭部が写り込み可能性がございます。
      </Alert>
    </PhotographyAccordion>
  );
};
