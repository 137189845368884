import {
  CheckBox,
  RadioBoxes,
  SingleSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TFormItems } from '../types';
import { TChoice, TValidatorResponse } from '@/components/shared/Form/types';
import zenginCode from 'zengin-code';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import './style.scss';
import { TFormInputs } from '../types';

const calcBankChoices = (): TChoice[] =>
  Object.entries(zenginCode).map(([key, bank]) => ({
    key,
    value: bank.name,
  }));

const calcBranchChoices = (bankId: string): TChoice[] =>
  zenginCode[bankId] !== undefined
    ? Object.entries(zenginCode[bankId].branches).map(([key, branch]) => ({
        key,
        value: branch.name,
      }))
    : [];

export const BankAccountForm: React.FC<{
  validator: TValidatorResponse;
  formItems: TFormItems;
}> = ({ formItems, validator }) => {
  const methods = useFormContext<TFormInputs>();
  const bankChoices = useMemo(calcBankChoices, []);
  const autocompleteBankInfo = !methods.watch('bank_id_manual_input');
  const bankId = methods.watch('bank_id');
  const branchChoices = useMemo(
    () => (bankId ? calcBranchChoices(bankId) : [{ key: '', value: '' }]),
    [bankId]
  );
  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'bank_id_manual_input' && !value.bank_id_manual_input) {
        // 自動入力に戻す時は色々面倒なので、全部消す
        methods.setValue('bank_id', null);
        methods.setValue('bank_name', null);
        methods.setValue('branch_id', null);
        methods.setValue('branch_name', null);
      }
      if (name === 'bank_id' && !value.bank_id_manual_input && value.bank_id) {
        methods.setValue('bank_name', zenginCode[value.bank_id].name);
        methods.setValue('branch_id', null);
        methods.setValue('branch_name', null);
      }
      if (
        value.bank_id &&
        name === 'branch_id' &&
        value.branch_id &&
        !value.bank_id_manual_input
      ) {
        methods.setValue(
          'branch_name',
          zenginCode[value.bank_id].branches[value.branch_id].name
        );
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  });
  return (
    <div className="c-frame">
      <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
        <h2>口座情報</h2>
      </div>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>銀行名</label>
        </li>
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              {autocompleteBankInfo ? (
                <SingleSelect
                  name="bank_id"
                  choices={bankChoices}
                  validator={validator}
                />
              ) : (
                <TextInput name="bank_name" validator={validator} />
              )}
            </li>
            <li className="c-label_innerHalf">
              <CheckBox
                name="bank_id_manual_input"
                validator={validator}
                label="銀行名と支店名を手動入力する"
              />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>銀行番号</label>
        </li>
        <li className="c-dataValue p-photographerOrganizationBankForm_formNumber">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              {autocompleteBankInfo ? (
                methods.watch('bank_id')
              ) : (
                <TextInput name="bank_id" validator={validator} />
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>支店名</label>
        </li>
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              {autocompleteBankInfo ? (
                <SingleSelect
                  name="branch_id"
                  choices={branchChoices}
                  validator={validator}
                />
              ) : (
                <TextInput name="branch_name" validator={validator} />
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>支店番号</label>
        </li>
        <li className="c-dataValue  p-photographerOrganizationBankForm_formNumber">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              {autocompleteBankInfo ? (
                methods.watch('branch_id')
              ) : (
                <TextInput name="branch_id" validator={validator} />
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>口座種別</label>
        </li>
        <li className="c-dataValue">
          <RadioBoxes
            name="account_type"
            choices={formItems.accountTypes}
            validator={validator}
          />
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>口座番号</label>
        </li>
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              <TextInput
                name="account_number"
                placeholder=""
                validator={validator}
              />

              <span className="t-textColor_sub">
                ※数字７桁以下で入力してください。７桁未満の場合は、先頭に「０」で補完します。
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>口座名義</label>
        </li>
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              <TextInput
                name="account_holder"
                placeholder=""
                validator={validator}
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
