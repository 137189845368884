import { FC, useState } from 'react';
import styles from '../Results.module.scss';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import {} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { TEventRequestPhotographer } from '../../types';
import clsx from 'clsx';
import {
  TableCell,
  TableRow,
  OpenedDetail,
} from '@/components/shared/ResultTable';
import { RiCloseLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Payments } from './Payments';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { REQUEST_FEE_TYPE } from '../../../constants';
import { STATUS_APPROVED } from '@/ts/photographerPayments/constants';
import { numberFormat } from '@/ts/formatTools';
import { EVENT_ASSIGN_STATUS } from '../../../constants';

const PhotographerPaymentsStatusLabel: FC<{
  isEventCanceled: boolean;
  eventAssignStatus: number;
  isRequestMailSent: boolean;
}> = ({ isEventCanceled, eventAssignStatus, isRequestMailSent }) => {
  let className = '';
  let statusName = '';
  if (isEventCanceled) {
    statusName = '中止';
    className = styles.canceled;
  } else if (eventAssignStatus === EVENT_ASSIGN_STATUS.UNDECIDED) {
    if (isRequestMailSent) {
      statusName = '返答待ち';
    } else {
      statusName = '未';
    }
    className = styles.temporary;
  } else if (eventAssignStatus === EVENT_ASSIGN_STATUS.TEMPORARY) {
    if (isRequestMailSent) {
      statusName = '返答待ち';
    } else {
      statusName = '仮';
    }
    className = styles.temporary;
  } else if (eventAssignStatus === EVENT_ASSIGN_STATUS.DECLINED) {
    statusName = '辞退';
    className = styles.declined;
  } else {
    statusName = '確定';
    className = styles.decided;
  }
  return (
    <span className={clsx(styles.paymentsStatusLabel, className)}>
      {statusName}
    </span>
  );
};

export const Item: FC<{
  eventRequestPhotographer: TEventRequestPhotographer;
  validator: TValidator;
  checked: boolean;
  isDisplayCheckBox: (
    eventRequestPhotographer: TEventRequestPhotographer
  ) => boolean;
  setChecked: (checked: boolean) => void;
}> = ({ eventRequestPhotographer, checked, setChecked, isDisplayCheckBox }) => {
  const [opened, setOpened] = useState(false);
  const isGreyOut =
    Number(eventRequestPhotographer.eventAssignStatus) ===
      EVENT_ASSIGN_STATUS.DECLINED ||
    eventRequestPhotographer.isEventCanceled === true;
  return (
    <>
      <TableRow disabled={isGreyOut}>
        <TableCell>
          {isDisplayCheckBox(eventRequestPhotographer) && (
            <SmallCheckBox checked={checked} setChecked={setChecked} />
          )}
        </TableCell>
        <TableCell>
          <div>
            <span onClick={() => setOpened(!opened)} className={styles.arrow}>
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell>
          {eventRequestPhotographer.isEventCanceled}
          <PhotographerPaymentsStatusLabel
            isEventCanceled={eventRequestPhotographer.isEventCanceled}
            eventAssignStatus={Number(
              eventRequestPhotographer.eventAssignStatus
            )}
            isRequestMailSent={eventRequestPhotographer.isRequestMailSent}
          />
          <Link
            to={`/events/${eventRequestPhotographer.eventId}`}
            className="c-textlink"
          >
            {eventRequestPhotographer.eventId} /{' '}
            {eventRequestPhotographer.eventName}
          </Link>
        </TableCell>
        <TableCell>
          {`${eventRequestPhotographer.photographerSei}${eventRequestPhotographer.photographerMei}`}
        </TableCell>
        <TableCell>{`${eventRequestPhotographer.societyName}`}</TableCell>
        <TableCell>
          {`${eventRequestPhotographer.photographerOrganizationName}`}
        </TableCell>
        <TableCell>{eventRequestPhotographer.workingTime}</TableCell>
        <TableCell>
          {dayjs(eventRequestPhotographer.photographingDay).format(
            'YYYY/MM/DD'
          )}
        </TableCell>
        <TableCell>{eventRequestPhotographer.paymentStatusName}</TableCell>
        <TableCell>{numberFormat(eventRequestPhotographer.price)}</TableCell>
      </TableRow>
      {opened && (
        <OpenedDetail>
          <Payments
            payments={eventRequestPhotographer.payments}
            isGreyOut={isGreyOut}
          />
          {eventRequestPhotographer.paymentStatus === STATUS_APPROVED &&
            eventRequestPhotographer.isEventCanceled === false &&
            eventRequestPhotographer.eventAssignStatus !==
              EVENT_ASSIGN_STATUS.DECLINED && (
              <div className={styles.innerTableButtons}>
                {eventRequestPhotographer.isSpecialFee ? (
                  <Link
                    to={`/photographer_payments/request_fees/bulk_edit?requestFeeType=${REQUEST_FEE_TYPE.NORMAL_FEE}&eventRequestPhotographerIds[]=${eventRequestPhotographer.id}`}
                    className="c-btn_large c-btn_Search c-input_submit"
                  >
                    通常報酬に変更
                  </Link>
                ) : (
                  <Link
                    to={`/photographer_payments/request_fees/bulk_edit?requestFeeType=${REQUEST_FEE_TYPE.SPECIAL_FEE}&eventRequestPhotographerIds[]=${eventRequestPhotographer.id}`}
                    className="c-btn_large c-btn_Search c-input_submit"
                  >
                    特別報酬に変更
                  </Link>
                )}
              </div>
            )}
          <span className={styles.closeLinkWrapper}>
            <span className={styles.closeLink} onClick={() => setOpened(false)}>
              <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
              <span>閉じる</span>
            </span>
          </span>
        </OpenedDetail>
      )}
    </>
  );
};
