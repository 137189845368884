import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import { FC } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { TIndexResponse, TFormInputs } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import { SearchForm } from './SearchForm';
import QueryString from 'query-string';
import { removeEmpty } from '@/ts/objectTools';
import { transformBoolToNumber } from '@/ts/transformBoolToNumber';
import { TMutate } from '@/ts/useJsonApi';

const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(convertQueryToFormData(queryString));
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[0-9]+$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/request_fees?${query}`;
};

const Content: FC<{
  response: TIndexResponse;
  queryParams: Record<string, unknown>;
  onSearchSubmit: (formData: TFormInputs) => void;
  onEditSubmit: (ids: number[]) => void;
  mutate: TMutate<TIndexResponse>;
}> = ({ response, queryParams, onSearchSubmit, onEditSubmit, mutate }) => {
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          { id: 2, title: '報酬管理', path: '/photographer_payments' },
          { id: 3, title: '注文金一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start u-mgb_m">
          <h1 className="l-flex_center c-page_title">注文金一覧</h1>
        </div>
        <SearchForm
          response={response}
          onSubmit={onSearchSubmit}
          queryParams={queryParams}
        />
        <Results
          response={response}
          onEditSubmit={onEditSubmit}
          mutate={mutate}
        />
      </div>
    </IndexPageLayout>
  );
};

export const IndexPage: FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<TIndexResponse>(toApiPath(queryParams));

  const onSearchSubmit = (formData: TFormInputs) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/request_fees',
      queryString,
      removeEmpty(transformBoolToNumber(formData)) as Record<string, unknown>
    );
    history.push(newPath);
  };

  const onEditSubmit = (ids: number[]) => {
    const queryString = ids
      .map((id) => `eventRequestPhotographerIds[]=${id}`)
      .join('&');
    history.push({
      pathname: '/photographer_payments/request_fees/bulk_edit',
      search: queryString,
    });
  };

  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as TIndexResponse}
          onSearchSubmit={onSearchSubmit}
          onEditSubmit={onEditSubmit}
          queryParams={queryParams}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }

  return (
    <Content
      response={response}
      onSearchSubmit={onSearchSubmit}
      onEditSubmit={onEditSubmit}
      queryParams={queryParams}
      mutate={mutate}
    />
  );
};
