import React, { MouseEventHandler } from 'react';

import { postJson } from '../../../../ts/useApi';
import {
  successToast,
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TPostResponse } from './types';
import { TTarget } from '../Index/types';
import { getApiPath } from '../getApiPath';
import Help from '../../Help/App';

const ChangeType: React.FC<{
  target: TTarget;
  incentivePaymentId: number;
  typeToChange: 'HTML' | 'PDF';
}> = React.memo(({ target, incentivePaymentId, typeToChange }) => {
  const detailType = typeToChange === 'HTML' ? 'Web請求明細' : 'PDF';
  const changeType: MouseEventHandler = async () => {
    // TODO: UIをよくする
    if (
      !window.confirm(`明細の種類を${detailType}に変更します。よろしいですか？`)
    ) {
      return;
    }
    try {
      const res = await postJson<TPostResponse>(
        getApiPath(target, `${incentivePaymentId}/change_type`),
        { type: typeToChange }
      );
      if (res.data === 'OK') {
        successToast(`${detailType}に変更しました`);
        window.location.reload();
      } else {
        errorToastWithValidatorMessages(
          '変更に失敗しました',
          res.validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  };
  return (
    <>
      <button
        className="c-btn_large c-btn_manage"
        type="button"
        onClick={changeType}
      >
        <span>
          {typeToChange === 'HTML' ? (
            <>
              <i className="c-icon_arrowLeft t-textColor_white u-mgr_s" />
              Web請求明細に戻す
            </>
          ) : (
            <>
              <i className="c-icon_arrowRight t-textColor_white u-mgr_s" />
              PDFに変更する
            </>
          )}
        </span>
      </button>
      <Help>
        {typeToChange === 'HTML'
          ? 'Web上で編集可能な請求明細に戻します。'
          : 'PDFの請求明細をwebにアップロードする方法です。'}
      </Help>
    </>
  );
});
export default ChangeType;
