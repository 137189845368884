import { useState } from 'react';
import { TPhotography } from '../types';
import styles from './style.module.scss';
import clsx from 'clsx';
import { TFormInputs } from '../types';
import { useFormContext } from 'react-hook-form';
import {
  checkListItems,
  TCheckListChild,
  TCheckListParent,
} from './checkListItem';
import {
  RiCheckboxCircleLine,
  RiCloseFill,
  RiErrorWarningFill,
  RiStarFill,
} from 'react-icons/ri';

const CheckListChild: React.FC<{
  item: TCheckListChild;
  photography: TPhotography;
}> = ({ item, photography }) => {
  const methods = useFormContext<TFormInputs>();
  const handleClick = () => {
    const element = document.getElementById(item.scrollTarget);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getItemName = (item: TCheckListChild, photography: TPhotography) => {
    return typeof item.name === 'function' ? item.name(photography) : item.name;
  };
  if (!item.visible(photography, methods.watch())) {
    return null;
  }
  if ('checked' in item && !item.checked(methods.watch(), photography)) {
    return (
      <div className={styles.uncheckedChildItem} onClick={handleClick}>
        <RiErrorWarningFill className={clsx(styles.childItemIcon)} />
        {getItemName(item, photography)}
      </div>
    );
  }
  if ('star' in item) {
    return (
      <div className={styles.starChildItem} onClick={handleClick}>
        <RiStarFill className={clsx(styles.childItemIcon)} />
        {getItemName(item, photography)}
      </div>
    );
  }
  return (
    <div className={styles.checkedChildItem} onClick={handleClick}>
      <RiCheckboxCircleLine className={clsx(styles.childItemIcon)} />
      {getItemName(item, photography)}
    </div>
  );
};
const CheckListParent: React.FC<{
  item: TCheckListParent;
  photography: TPhotography;
}> = ({ item, photography }) => {
  const inputValues = useFormContext<TFormInputs>().watch();
  const allChecked = item.children.every(
    (child) =>
      !child.visible(photography, inputValues) ||
      !('checked' in child) ||
      child.checked(inputValues, photography)
  );
  return (
    <>
      <div
        className={clsx(
          allChecked ? styles.checkedParentItem : styles.uncheckedParentItem
        )}
        onClick={() => {
          const element = document.getElementById(item.scrollTarget);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      >
        {item.name}
      </div>
      {item.children.map((child) => (
        <CheckListChild
          item={child}
          photography={photography}
          key={child.scrollTarget}
        />
      ))}
    </>
  );
};

const CheckListItems: React.FC<{
  photography: TPhotography;
  handleClose: () => void;
}> = ({ photography, handleClose }) => {
  return (
    <div className={clsx(styles.checkListOverlay)}>
      <div className={styles.checkListHead} onClick={handleClose}>
        <span>チェックリスト</span>
        <RiCloseFill />
      </div>
      <div className={styles.checkListContainer}>
        <div className="u-mgt_s"></div>
        {checkListItems.map((item) => (
          <CheckListParent
            item={item}
            photography={photography}
            key={item.scrollTarget}
          />
        ))}
        <button
          type="button"
          onClick={() => handleClose()}
          className={styles.closeButton}
        >
          <RiCloseFill className={styles.closeButtonIcon} />
          閉じる
        </button>
      </div>
    </div>
  );
};

export const CheckList: React.FC<{ photography: TPhotography }> = ({
  photography,
}) => {
  const [displayed, setDisplayed] = useState(false);
  return (
    <>
      {displayed && (
        <CheckListItems
          photography={photography}
          handleClose={() => setDisplayed(false)}
        />
      )}
      <button
        type="button"
        className={styles.closedCheckBoxButton}
        onClick={() => setDisplayed(!displayed)}
      >
        チェックリストを確認する
      </button>
    </>
  );
};
