import React from 'react';
import './societyHeader.scss';
import dayjs from 'dayjs';

const AcademicYearSelect: React.FC<{
  selectedFiscalYear: string;
  setSelectedFiscalYear: React.Dispatch<React.SetStateAction<string>>;
}> = React.memo(({ selectedFiscalYear, setSelectedFiscalYear }) => {
  const now = dayjs();
  const fiscalYear = now.subtract(3, 'month').year();

  const handleChange = (currentSelectedFiscalYear: string) => {
    setSelectedFiscalYear(currentSelectedFiscalYear);
  };

  return (
    <div>
      <select
        className="p-totalForecastsArea_price"
        value={selectedFiscalYear}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option value={fiscalYear}>{fiscalYear}</option>
        <option value={fiscalYear + 1}>{fiscalYear + 1}</option>
      </select>
      年度
    </div>
  );
});

const SocietyHeader: React.FC<{
  societyName: string;
  societyId: number;
  selectedFiscalYear: string;
  setSelectedFiscalYear: React.Dispatch<React.SetStateAction<string>>;
}> = (props) => (
  <div className="p-salesForecastsIndex-headBlock p-salesForecastsIndex-bg-gray u-mgb_m">
    <div className="p-salesForecastsIndex-headBlock_idArea u-mgr_s">
      <i className="c-icon c-icon__small c-icon_groups u-mgr_xs"></i>
      {props.societyId}
    </div>
    <a
      className="c-textlink p-salesForecastsIndex-headBlock_name u-mgr_m"
      href={`/societies/${props.societyId}`}
    >
      <h4 className="c-textOmit">{props.societyName}</h4>
    </a>
    <div className="p-salesForecastsIndex-headBlock_year">
      <AcademicYearSelect
        selectedFiscalYear={props.selectedFiscalYear}
        setSelectedFiscalYear={props.setSelectedFiscalYear}
      />
    </div>
  </div>
);

export default SocietyHeader;
