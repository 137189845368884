import { canRequestApprove, canSetPaidAt } from '../constants';
import { TCheck, TIncentivePayment, TTarget } from './types';

const initialize = (
  incentivePayments: TIncentivePayment[],
  enabledMultipleRequestApprove: boolean,
  enabledMultipleSetPaidAt: boolean,
  target: TTarget
): TCheck[] =>
  incentivePayments.map((payment) => {
    let disabled = true;
    if (enabledMultipleRequestApprove) {
      disabled = !canRequestApprove(payment.status, payment.isPdfSaved);
    } else if (enabledMultipleSetPaidAt) {
      disabled = !canSetPaidAt(
        payment.status,
        payment.bankAccountHasMistake,
        target
      );
    }
    return {
      id: payment.id,
      checked: false,
      disabled,
    };
  });

export { toggleCheckAll, toggleCheck } from '../../../../ts/toggleCheckboxes';
export { initialize };
