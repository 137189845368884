import React, { useCallback } from 'react';
import { renderError, toMessages, useJsonApi } from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import './photographinsert.scss';
import { errorToast } from '../../../../ts/toast';
import { TResponse } from '@/components/shared/Photographs/Insert/types';

import BaseApp, { useApiUrl } from '@/components/shared/Photographs/Insert/App';

const App: React.FC = () => {
  const url = useApiUrl();
  const { data: response, error } = useJsonApi<TResponse>(url);
  const handleApiError = useCallback((e: unknown, s: string) => {
    errorToast(s);
    return toMessages(e);
  }, []);
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return <BaseApp response={response} handleApiError={handleApiError} />;
};
export default App;
