import React, { MouseEventHandler, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { convertValidatorForArrayForm } from '@/components/shared/Form/validation';
import { TextInput, NumberInput } from '@/components/shared/Form/Inputs';
import { HYPHEN, numberFormat } from '../../../../ts/formatTools';
import { calcTotalPaymentOthers } from './calc';
import {
  TGroupedIncentivePaymentOther,
  THtmlFormInputs,
  TIncentivePaymentOther,
  TInputIncentivePaymentOther,
} from './types';

const TableHeader: React.FC = React.memo(() => (
  <>
    <th className="c-incentivePayment_tableOther_detail">
      <div className="c-incentivePayment_tableCaption u-align_left">内訳</div>
    </th>
    <th className="c-incentivePayment_tableOther_unitPrice">
      <div className="c-incentivePayment_tableCaption">単価</div>
    </th>
    <th className="c-incentivePayment_tableOther_set">
      <div className="c-incentivePayment_tableCaption">数量</div>
    </th>
    <th className="c-incentivePayment_tableOther_payment">
      <div className="c-incentivePayment_tableCaption">金額</div>
    </th>
  </>
));
const TableTotalPaymentHeader: React.FC = React.memo(() => (
  <>
    <td></td>
    <td></td>
    <td className="c-incentivePayment_unitTotalBox u-align_right">
      <div className="c-incentivePayment_unitTotal">
        <b>小計</b>
      </div>
    </td>
  </>
));

const IncentivePaymentOtherEdit: React.FC<{
  index: number;
  validator: TValidatorResponse;
  incentivePaymentOther: TInputIncentivePaymentOther;
  onClickRemove: MouseEventHandler;
}> = React.memo(
  ({
    index,
    validator: orgValidator,
    incentivePaymentOther: { unitPrice, quantity },
    onClickRemove,
  }) => {
    const validator = convertValidatorForArrayForm(
      'incentivePaymentOthers',
      index,
      orgValidator
    );

    return (
      <tr>
        <td>
          <TextInput
            name={`incentivePaymentOthers.${index}.name`}
            validator={validator}
            placeholder="入場料、駐車料など"
          />
        </td>
        <td>
          <NumberInput
            name={`incentivePaymentOthers.${index}.unitPrice`}
            additionalClassName="u-align_right"
            validator={validator}
            unit="円"
          />
        </td>
        <td>
          <NumberInput
            name={`incentivePaymentOthers.${index}.quantity`}
            additionalClassName="u-align_right"
            validator={validator}
          />
        </td>
        <td className="u-align_right">
          <strong>
            {unitPrice && quantity
              ? numberFormat(unitPrice * quantity)
              : HYPHEN}{' '}
            円
          </strong>
        </td>
        <td>
          <NumberInput
            name={`incentivePaymentOthers.${index}.id`}
            additionalClassName="is-hidden"
            validator={validator}
          />
          <NumberInput
            name={`incentivePaymentOthers.${index}.taxRate`}
            additionalClassName="is-hidden"
            validator={validator}
          />
          <button
            type="button"
            className="c-btn_rectangle c-btn_delete_s"
            onClick={onClickRemove}
          >
            <i className="c-icon_trash"></i>
          </button>
        </td>
      </tr>
    );
  }
);

const IncentivePaymentOthersEdit: React.FC<{
  validator: TValidatorResponse;
  taxRate: number | null;
}> = React.memo(({ validator, taxRate }) => {
  const { control, watch } = useFormContext<THtmlFormInputs>();
  const { fields, append, remove } = useFieldArray({
    name: 'incentivePaymentOthers',
    keyName: 'identity',
    control,
  });

  const watchFieldArray = watch('incentivePaymentOthers');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
      // NOTE: taxRate=nullが0になるので強制的にnumber | null に固定する
      taxRate: watchFieldArray[index].taxRate || null,
    };
  });

  // sum totalPayment
  const totalPayment = calcTotalPaymentOthers(taxRate, watchFieldArray);

  // actions
  const appendRow: MouseEventHandler = useCallback(() => {
    const newOthers: TInputIncentivePaymentOther = { taxRate };
    append(newOthers);
  }, [append, taxRate]);

  return (
    <>
      <table className="c-incentivePayment_tableOther is-edit">
        <thead>
          <tr>
            <TableHeader />
            <th className="c-incentivePayment_tableOther_del" />
          </tr>
        </thead>
        <tbody>
          {/* NOTE: 配列には全incentivePaymentOtherを含むので、必要なもの以外弾く */}
          {controlledFields.map((paymentOther, index) =>
            paymentOther.taxRate === taxRate ? (
              <IncentivePaymentOtherEdit
                key={index}
                index={index}
                validator={validator}
                incentivePaymentOther={paymentOther}
                onClickRemove={() => remove(index)}
              />
            ) : null
          )}
          <tr>
            <TableTotalPaymentHeader />
            <td className="c-incentivePayment_unitTotalBox u-align_right">
              <div className="c-incentivePayment_unitTotal">
                <b>{numberFormat(totalPayment)} 円</b>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
      <div className="c-incentivePayment_addDetailButton">
        <button
          type="button"
          className="c-btn_large c-btn_primary"
          onClick={appendRow}
        >
          + 内訳を追加
        </button>
      </div>
    </>
  );
});

const IncentivePaymentOthersShow: React.FC<{
  incentivePaymentOthers: TIncentivePaymentOther[];
  totalPayment: number;
}> = React.memo(({ incentivePaymentOthers, totalPayment }) => (
  <table className="c-incentivePayment_tableOther">
    <thead>
      <tr>
        <TableHeader />
      </tr>
    </thead>
    <tbody>
      {incentivePaymentOthers.map(
        ({ id, name, unitPrice, quantity, price }) => (
          <tr key={id}>
            <td>{name}</td>
            <td className="u-align_right">{numberFormat(unitPrice)} 円</td>
            <td className="u-align_right">{numberFormat(quantity)}</td>
            <td className="u-align_right">
              <strong>{numberFormat(price)} 円</strong>
            </td>
          </tr>
        )
      )}
      <tr>
        <TableTotalPaymentHeader />
        <td className="c-incentivePayment_unitTotalBox u-align_right">
          <div className="c-incentivePayment_unitTotal">
            <strong>{numberFormat(totalPayment)} 円</strong>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
));

const GroupedIncentivePaymentOther: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  groupedIncentivePaymentOther: TGroupedIncentivePaymentOther;
}> = React.memo(
  ({
    canEdit,
    validator,
    groupedIncentivePaymentOther: {
      incentivePaymentOthers,
      taxRate,
      totalPayment,
    },
  }) => {
    return (
      <div>
        <h5 className="c-section_subTitle u-fz_default">
          その他請求 -{' '}
          <strong>{taxRate ? `税抜（${taxRate}%）` : '税込'}</strong>
        </h5>
        <div className="l-relative u-mgb_l">
          {canEdit ? (
            <IncentivePaymentOthersEdit
              validator={validator}
              taxRate={taxRate}
            />
          ) : (
            <IncentivePaymentOthersShow
              incentivePaymentOthers={incentivePaymentOthers}
              totalPayment={totalPayment}
            />
          )}
        </div>
      </div>
    );
  }
);

export default GroupedIncentivePaymentOther;
