import { TailSpin } from 'react-loader-spinner';
import './overlaySpinner.scss';
const App: React.FC = () => (
  <>
    <div className="p-overlaySpinner">
      <div className="p-overlaySpinner_spinnerBox">
        <TailSpin height={200} width={200} color="#ff8e42" />
      </div>
    </div>
  </>
);

export default App;
