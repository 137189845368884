import { TextArea } from '@/components/shared/Form/Inputs';
import React from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';

const Memos: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  isHtml: boolean;
  privateMemo: string;
  publicMemo: string;
}> = React.memo(({ canEdit, validator, isHtml, privateMemo, publicMemo }) => (
  <>
    <div className={isHtml ? 'u-mgb_m' : 'c-label_innerHalf'}>
      <label>
        <strong className="u-mgr_s">備考</strong>
        <span className="t-textColor_sub u-fz_xs">
          備考はパートナーも閲覧可能です
        </span>
      </label>
      <div className="u-pre_wrap">
        {canEdit ? (
          <TextArea
            name="publicMemo"
            placeholder="備考"
            validator={validator}
          />
        ) : (
          publicMemo || '（登録されていません）'
        )}
      </div>
    </div>
    <div className={isHtml ? 'u-mgb_m' : 'c-label_innerHalf'}>
      <label>
        <strong className="u-mgr_s">メモ</strong>
        <span className="t-textColor_sub u-fz_xs">
          メモは社内ユーザーのみ閲覧可能です
        </span>
      </label>
      <div className="u-pre_wrap">
        {canEdit ? (
          <TextArea
            name="privateMemo"
            placeholder="メモ"
            validator={validator}
          />
        ) : (
          privateMemo || '（登録されていません）'
        )}
      </div>
    </div>
    {isHtml && (
      <>
        <div className="u-mgb_s">
          【請求明細の注意事項】
          <ul className="p-partnerIncentivePaymentsShow-memo-ui">
            <li>
              販売手数料について
              <ul>
                <li>直近の基本申込書に基づき集計した金額を言います。</li>
              </ul>
            </li>
            <li>
              支払額について
              <ul>
                <li>
                  支払額（Ａ）・・・集計対象月に発生した売上より、直近の基本申込書に記載の販売手数料％と印刷原価を差し引いた金額
                </li>
                <li>
                  支払額（Ｂ）・・・集計対象月に発生した売上より、直近の基本申込書に記載の販売手数料％を差し引いた金額
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="u-mgb_s">
          <ul className="p-partnerIncentivePaymentsShow-memo-ui">
            <li>
              支払額小計と支払額合計の金額差異について
              <ul>
                <li>
                  団体別・イベント別の支払額小計（1）と、本明細の支払額合計（2）は、手数料を計算する際に発生する小数点の取り扱いが異なるため、一致しない場合がございます。予めご了承ください。
                </li>
                <li>
                  各集計用データは「請求明細をダウンロード」からご確認ください。
                </li>
                <li>
                  1.団体・イベント別集計（本明細の団体別・イベント別の支払額小計はこちらの計算結果を反映）
                </li>
                <li>
                  2.価格・サイズ別集計（本明細の支払額合計はこちらの計算結果を反映）
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    )}
  </>
));

export default Memos;
