import React from 'react';
import {
  TPricePatternCategory,
  TPhotoSize,
  TUserType,
  TPhotoType,
} from './types';
import Price from './Price';

const PhotoType: React.FC<{
  pricePatternCategories: TPricePatternCategory[];
  userType: TUserType;
  photoType: TPhotoType;
  photoSizes: TPhotoSize[];
}> = React.memo(
  ({ pricePatternCategories, userType, photoType, photoSizes }) => {
    const pricePatternCategory = pricePatternCategories.find(
      (category) =>
        category.usertypemasterno === userType.usertypemasterno &&
        category.phototypemasterno === photoType.phototypemasterno
    );
    return (
      <>
        <th>{photoType.phototypename}</th>
        {photoSizes.map((photoSize) => (
          <td key={photoSize.sizename} className="u-align_right">
            <Price
              pricePatternCategory={pricePatternCategory}
              photoSize={photoSize}
            />
          </td>
        ))}
      </>
    );
  }
);

export default PhotoType;
