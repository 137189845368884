import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { postJson } from '../../../../ts/useApi';
import { asyncProcessStartToast } from '../../../../ts/toast';

const Create: React.FC = React.memo(() => {
  const create: MouseEventHandler = useCallback((e) => {
    e.preventDefault();

    const currentYear = new Date().getFullYear();
    // TODO: UI実装
    if (
      !window.confirm(
        `${currentYear}年度用の年度キーを未発行の団体に一括発行します。よろしいですか？`
      )
    ) {
      return;
    }
    postJson(`/api/certificationkeys/create_year/${currentYear}`);
    asyncProcessStartToast('年度キー一括発行');
  }, []);

  return (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m p-certificationkeys_btn_rectangle"
      onClick={create}
    >
      <span>年度キー一括発行</span>
    </button>
  );
});

const CreateYearCertificationkeys: React.FC<{
  canCreate: boolean;
}> = React.memo(({ canCreate }) =>
  canCreate ? (
    <Create />
  ) : (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m  p-certificationkeys_btn_rectangle is-disabled"
    >
      <span>年度キー一括発行</span>
    </button>
  )
);

export default CreateYearCertificationkeys;
