import { PHOTO_TYPE_SNAP } from '../../../shared/IncentivePayments/constants';

const DEFAULT_PRICE_FLAG_DEFAULT = '1';
const USER_TYPE_GENERAL = 1;
export const USER_TYPE_ASSOCIATED = 2;

export const PHOTO_SIZE_ID_L = 1;
export const PHOTO_SIZE_ID_2L = 11;
export const PHOTO_SIZE_ID_DVD = 13;
export const PHOTO_SIZE_ID_2LW = 2;

const PRICE_NOT_FOR_SALE = -1;

export const isDefaultPrice = (defaultPriceFlag: string): boolean =>
  defaultPriceFlag === DEFAULT_PRICE_FLAG_DEFAULT;

export const isUserTypeGeneral = (userTypeId: number): boolean =>
  userTypeId === USER_TYPE_GENERAL;

export const isPhotoTypeSnap = (photoTypeId: number): boolean =>
  photoTypeId === PHOTO_TYPE_SNAP;

export const isPhotoSize2LW = (photoSizeId: number): boolean =>
  photoSizeId === PHOTO_SIZE_ID_2LW;

export const isPriceNotForSale = (price: number): boolean =>
  price === PRICE_NOT_FOR_SALE;
