import React, { useCallback } from 'react';
import { renderError, toMessages, useJsonApi } from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TShowResponse } from '@/components/shared/Photographs/Show/types';
import './photographsShow.scss';
import BaseApp, { useApiUrl } from '@/components/shared/Photographs/Show/App';
import { errorToast } from '../../../../ts/toast';
import './photographsShow.scss';
import { kanriUrl } from '../../../../ts/url';
import { useAuthInfo } from '../../../AuthProvider';

const App: React.FC = () => {
  const url = useApiUrl();
  const { data: response, error, mutate } = useJsonApi<TShowResponse>(url);
  const authInfo = useAuthInfo();
  const handleApiError = useCallback((e: unknown, s: string) => {
    errorToast(s);
    return toMessages(e);
  }, []);
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  if (response.data.convertinfo) {
    response.data.convertinfo.link = kanriUrl({
      co: response.data.convertinfo.id.toString(),
    });
  }
  if (response.data.event) {
    response.data.event.link = kanriUrl({
      e: response.data.event.id.toString(),
    });
  }
  return (
    <BaseApp
      response={response}
      mutate={mutate}
      handleApiError={handleApiError}
      isTrimEditable={authInfo.authOperation}
    ></BaseApp>
  );
};

export default App;
