import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SearchSociety, Society } from './types';

const SearchSocieties: React.FC<{
  societies: SearchSociety[];
  corporationSocieties: Society[];
  isChecked: (societyId: number) => void;
  onSubmit: SubmitHandler<Record<string, unknown>>;
  onChange: (position: number, id: number) => void;
}> = (props) => (
  <ul className="c-indexList p-groupsList">
    {props.societies.map((society: SearchSociety, index) => (
      <li className="c-indexListItem l-clearfix" key={society.societyId}>
        <div className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <div className="l-col_2">
            <label
              className={`c-checkbox_listbox${
                society.corporationId != null ? ' is-disabled' : ''
              }`}
            >
              <input
                type="checkbox"
                name={'societyId'}
                value={society.societyId}
                className={`c-checkbox_list`}
                checked={props.isChecked(society.societyId)!}
                onChange={() => props.onChange(index, society.societyId)}
              />
              <span
                className={`c-label_checkbox_list${
                  society.corporationId != null ? '' : ' t-checkbox_col_white'
                }`}
              ></span>
            </label>
          </div>
          <div className="l-col_auto l-flex_between_center">
            <div>
              <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                <i className="c-icon_groups u-mgr_xs"></i>
                <span>{society.societyId}</span>
              </div>
              <Link
                className="c-textlink"
                to={`/societies/${society.societyId}`}
              >
                <h4 className="c-textOmit">{society.societyName}</h4>
              </Link>
            </div>
            <div>
              <ul className="l-flex p-CorporationList_info">
                <li>
                  <i className="c-icon_map u-mgr_xs" />
                  <span>
                    {society.prefectureName} {society.city} {society.address}
                  </span>
                </li>
                <li>
                  <i className="c-icon_tel u-mgr_xs" />
                  <span>{society.telephone}</span>
                </li>
                <li>
                  <i className="c-icon_person u-mgr_xs" />
                  <span>{society.salesOwnerName}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

export default SearchSocieties;
