import React from 'react';
import { dateFormat, HYPHEN } from '../../../../ts/formatTools';
import { TPhotographerSchedule } from './types';
import { Link } from 'react-router-dom';
import { getPhotographerSummaryUrl } from '../../../../ts/legacyUrl';
import Accordion from '../../../shared/Accordion/App';
import dayjs from 'dayjs';

const STATUS_TEMPORARY = 2;
const STATUS_DECLINED = 9;
const STATUS_WORKING = 2;

const PHOTOGRAPHING_STATUS_ASSIGNED = 1;
const PHOTOGRAPHING_STATUS_OFFER_SENT = 2;
const PHOTOGRAPHING_STATUS_ANSWERED = 3;
const PHOTOGRAPHING_STATUS_ARRIVED = 4;
const PHOTOGRAPHING_STATUS_REPORTED = 5;
const PHOTOGRAPHING_STATUS_DELIVERY_COMPLETED = 6;

type TPhotographingInfo = Partial<{
  sent: string;
  answered: string;
  arrived: string;
  reported: string;
  deliveryCompleted: string;
}>;
const PHOTOGRAPHING_INFO_LABELS = {
  sent: '送信',
  answered: '回答',
  arrived: '現着',
  reported: '日報',
  deliveryCompleted: '納品',
};
type TProps = { photographer: TPhotographerSchedule };

const formatDateTime = (date?: dayjs.ConfigType) =>
  date ? dayjs(date).format('YYYY/MM/DD HH:mm') : HYPHEN;

const ListHeader: React.FC<{
  displayAssignDetail: boolean;
  displayPhotographerDetail: boolean;
}> = React.memo(({ displayAssignDetail, displayPhotographerDetail }) => (
  <tr>
    <th>
      稼働
      <br />
      状態
    </th>
    <th>日付</th>
    <th>カメラマン</th>
    <th>
      アサイン
      <br />
      状態
    </th>
    {displayAssignDetail && (
      <>
        <th>イベント</th>
        <th>状態</th>
      </>
    )}
    <th>
      撮影状態更新日時
      <br />
      依頼詳細
    </th>
    <th>
      ランク
      <br />
      契約
    </th>
    <th>住所</th>
    {displayPhotographerDetail && (
      <>
        <th>撮影可能日</th>
        <th>
          カメラマン
          <br />
          詳細
        </th>
        <th>撮影情報</th>
        <th>登録日</th>
        <th>メモ</th>
      </>
    )}
  </tr>
));

const RequestInfo: React.FC<{
  photographer: TPhotographerSchedule;
  isDisplayDetail: boolean;
}> = React.memo(({ photographer, isDisplayDetail }) => {
  if (photographer.shinseiPhotographerStatus === null) {
    return (
      <>
        {isDisplayDetail && (
          <>
            <td className="c-indexList_column_l">{HYPHEN}</td>
            <td className="c-indexList_column_m c-indexList_column__nowrap">
              {HYPHEN}
            </td>
          </>
        )}
        <td className="c-indexList_column_xl u-pre_wrap">{HYPHEN}</td>
      </>
    );
  }

  const photographingInfo: TPhotographingInfo = {};
  if (photographer.photographingStatus) {
    if (photographer.photographingStatus >= PHOTOGRAPHING_STATUS_OFFER_SENT) {
      photographingInfo['sent'] = formatDateTime(
        photographer.requestMailSentTime
      );
    }
    if (photographer.photographingStatus >= PHOTOGRAPHING_STATUS_ANSWERED) {
      photographingInfo['answered'] = formatDateTime(
        photographer.requestRespondTime
      );
    }
    if (photographer.photographingStatus >= PHOTOGRAPHING_STATUS_ARRIVED) {
      photographingInfo['arrived'] = formatDateTime(photographer.arrivedTime);
    }
    if (photographer.photographingStatus >= PHOTOGRAPHING_STATUS_REPORTED) {
      photographingInfo['reported'] = formatDateTime(
        photographer.reportSentTime
      );
    }
    if (
      photographer.photographingStatus >=
      PHOTOGRAPHING_STATUS_DELIVERY_COMPLETED
    ) {
      photographingInfo['deliveryCompleted'] = formatDateTime(
        photographer.deliveryCompletedTime
      );
    }
  }

  return (
    <>
      {isDisplayDetail && (
        <>
          <td className="c-indexList_column_l">
            <>
              <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
                {photographer.eventId}
              </div>
              <br />
              <Link
                className="c-textlink"
                to={`/events/${photographer.eventId}`}
              >
                {photographer.eventName}
                {!!photographer.isCanceledEvent && '【撮影中止】'}
              </Link>
            </>
          </td>
          <td className="c-indexList_column_m c-indexList_column__nowrap">
            <div
              className={`c-statusLabel c-statusLabel__${
                photographer.photographingStatus ===
                PHOTOGRAPHING_STATUS_ASSIGNED
                  ? 'negative'
                  : 'positive'
              }`}
            >
              {photographer.photographingStatusName}
            </div>
          </td>
        </>
      )}
      <td className="c-indexList_column_xl u-pre_wrap">
        {photographer.photographingStatus === PHOTOGRAPHING_STATUS_OFFER_SENT
          ? photographingInfo.sent
          : photographer.photographingStatus === PHOTOGRAPHING_STATUS_ANSWERED
          ? photographingInfo.answered
          : photographer.photographingStatus === PHOTOGRAPHING_STATUS_ARRIVED
          ? photographingInfo.arrived
          : photographer.photographingStatus === PHOTOGRAPHING_STATUS_REPORTED
          ? photographingInfo.reported
          : photographer.photographingStatus ===
            PHOTOGRAPHING_STATUS_DELIVERY_COMPLETED
          ? photographingInfo.deliveryCompleted
          : ''}
        {photographer.photographingStatus &&
          photographer.photographingStatus >=
            PHOTOGRAPHING_STATUS_OFFER_SENT && (
            <div className="u-mgt_m u-pdt_s u-fz_s">
              <Accordion labelOpen="依頼詳細" labelClose="依頼詳細">
                {Object.entries(photographingInfo)
                  .filter(([_, value]) => value)
                  .map(
                    ([key, value]) =>
                      `${
                        (PHOTOGRAPHING_INFO_LABELS as Record<string, string>)[
                          key
                        ]
                      }: ${value}`
                  )
                  .join('\n')}
                <br />
                {!!photographer.requestMailTypeName && (
                  <>
                    <strong>メール種類:</strong>
                    <br />
                    {photographer.requestMailTypeName}
                    <br />
                  </>
                )}
                {!!photographer.requestMailAdditionalContent && (
                  <>
                    <strong>追加本文:</strong>
                    <br />
                    {photographer.requestMailAdditionalContent}
                    <br />
                  </>
                )}
                {!!photographer.availableTimeText && (
                  <>
                    <strong>辞退詳細:</strong>
                    <br />
                    {photographer.availableTimeText}
                    <br />
                  </>
                )}
                {!!photographingInfo.sent && (
                  <a
                    href={`https://mail.google.com/mail/u/0/#search/${photographer.eventId}+${photographer.sei}`}
                    className="c-textlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    メーラーで確認 <i className="c-icon_blank" />
                  </a>
                )}
              </Accordion>
            </div>
          )}
      </td>
    </>
  );
});

const PhotographerInfo: React.FC<TProps> = React.memo(({ photographer }) => {
  return (
    <>
      <td className="c-indexList_column_ml">
        {photographer.workingdatetypeName}
      </td>
      <td className="c-indexList_column_l">
        <strong>性別:</strong> {photographer.genderName}
        <br />
        <strong>車:</strong> {photographer.hascarName} / <strong>宿泊:</strong>{' '}
        {photographer.staynightflagName}
      </td>
      <td className="c-indexList_column_l">
        <strong>最終:</strong> {dateFormat(photographer.latestPhotographingDay)}
        <br />
        <strong>回数:</strong>{' '}
        {photographer.numberOfInPeriodPhotographingCount !== null &&
          `${photographer.numberOfInPeriodPhotographingCount ?? 0}/期間, `}
        {photographer.numberOfLastYearPhotographingCount ?? 0}/年
      </td>
      <td className="c-indexList_column_s">
        {dateFormat(photographer.createdday)}
      </td>
      <td className="c-indexList_column_xxl u-pre_wrap u-fz_s">
        {!!photographer.memo && (
          <>
            <div>
              <strong>メモ</strong>
            </div>
            <Accordion labelOpen="表示" labelClose="非表示">
              {photographer.memo}
            </Accordion>
          </>
        )}
      </td>
    </>
  );
});

const SearchResultItem: React.FC<{
  photographer: TPhotographerSchedule;
  displayAssignDetail: boolean;
  displayPhotographerDetail: boolean;
}> = React.memo(
  ({ photographer, displayAssignDetail, displayPhotographerDetail }) => {
    return (
      <tr>
        <td className="c-indexList_column_s c-indexList_column__nowrap">
          <div
            className={`${
              photographer.status === STATUS_WORKING
                ? 'c-statusLabel c-statusLabel__positive'
                : 'c-statusLabel c-statusLabel__negative'
            }`}
          >
            {photographer.statusName}
          </div>
        </td>
        <td className="c-indexList_column_s">
          {dateFormat(photographer.date)}
        </td>
        <td className="c-indexList_column_l">
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            {photographer.photographerId}
          </div>
          <br />
          <a
            className="c-textlink"
            href={getPhotographerSummaryUrl(photographer.photographerId)}
          >
            {`${photographer.sei} ${photographer.mei}`}({photographer.shortname}
            )
          </a>
        </td>
        <td className="c-indexList_column_s c-indexList_column__nowrap">
          <div
            className={`c-statusLabel c-statusLabel__wide ${
              photographer.shinseiPhotographerStatus === STATUS_DECLINED
                ? 'c-statusLabel__negative'
                : photographer.shinseiPhotographerStatus === STATUS_TEMPORARY
                ? 'c-statusLabel__undecided'
                : 'c-statusLabel__monotoneLine'
            }`}
          >
            {photographer.shinseiPhotographerStatusName ?? 'なし'}
          </div>
        </td>
        <RequestInfo
          photographer={photographer}
          isDisplayDetail={displayAssignDetail}
        />
        <td className="c-indexList_column_l">
          {photographer.rankName}
          <br />
          <small>({photographer.agreementtypeName})</small>
        </td>
        <td className="c-indexList_column_xl">
          {photographer.prefectureName} {photographer.city}
          <br />
          (最寄駅: {photographer.station})
        </td>
        {displayPhotographerDetail && (
          <PhotographerInfo photographer={photographer} />
        )}
      </tr>
    );
  }
);

export default SearchResultItem;
export { ListHeader };
