import React from 'react';
import { Link } from 'react-router-dom';
import { TSociety, TContract } from './types';
import { dateFormat } from '../../../../ts/formatTools';

// 契約状態：契約中
const STATUS_UNDER_CONTRACT = 2;

const SearchResultSocietiesList: React.FC<{ societies: TSociety[] }> = (
  props
) => (
  <table className="c-indexList p-groupSocietiesList">
    <thead>
      <tr>
        <th>団体名</th>
        <th>住所</th>
        <th>電話番号</th>
        <th>FAX番号</th>
        <th>担当営業</th>
        <th>契約情報(契約種類/プラン)</th>
      </tr>
    </thead>
    <tbody>
      {props.societies.map((society: TSociety) => (
        <tr key={society.societyId}>
          <td className="c-indexList_column_s p-groupSocietiesList-societyName">
            <div>
              <div className="c-idLink_hasBg p-groupsSocieties_id">
                <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                {society.societyId}
              </div>
            </div>
            <Link className="c-textlink" to={`/societies/${society.societyId}`}>
              {society.societyName}
            </Link>
          </td>
          <td className="c-indexList_column_s">
            {society.prefectureName}
            {society.city}
            {society.address}
          </td>
          <td className="c-indexList_column_xs">{society.telephone}</td>
          <td className="c-indexList_column_xs">{society.fax}</td>
          <td className="c-indexList_column_xs">{society.salesOwnerName}</td>
          <td className="c-indexList_column_l">
            {society.contracts.map((contract: TContract, index: number) => (
              <React.Fragment key={index}>
                <ul className="l-flex p-groupsSocietiesList_contractStatus">
                  <li className="p-groupsSocietiesList_contractStatus_type">
                    <ul className="l-flex">
                      <li className="u-fz_s c-textOmit t-textColor_sub">
                        NO.{index + 1}
                      </li>
                      <li className="p-groupsLabel_operation">
                        <span
                          className={`c-statusLabel c-statusLabel__${
                            contract.contractStatus === STATUS_UNDER_CONTRACT
                              ? 'positive'
                              : 'negative'
                          } small`}
                        >
                          {contract.contractStatusName}
                        </span>
                      </li>
                      <li className="u-mgr_m">
                        {contract.contractTypeName}/{contract.planName}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="p-groupsSocietiesList_contractInfo l-flex">
                      <li className="p-groupsSocietiesList_contractInfoCaption">
                        契約締結日
                      </li>
                      <li className="p-groupsSocietiesList_contractInfoValue has-separate">
                        {dateFormat(contract.concludeDate)}
                      </li>
                      <li className="p-groupsSocietiesList_contractInfoCaption">
                        認識タイプ
                      </li>
                      <li className="p-groupsSocietiesList_contractInfoValue has-separate">
                        {contract.authTypeName}
                      </li>
                      <li className="p-groupsSocietiesList_contractInfoCaption">
                        事前確認
                      </li>
                      <li className="p-groupsSocietiesList_contractInfoValue">
                        {contract.confirmationCheckName}
                      </li>
                    </ul>
                  </li>
                </ul>
              </React.Fragment>
            ))}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default SearchResultSocietiesList;
