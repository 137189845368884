import React from 'react';
import { dateFormat } from '../../../../ts/formatTools';
import { kanriUrl } from '../../../../ts/url';
import { TGroup } from './types';

const LatestGroups: React.FC<{ groups: TGroup[] }> = React.memo(
  ({ groups }) => {
    const hasNoGroupsElement = (
      <div className="c-emptyState_box">登録された団体がありません。</div>
    );

    const groupElements = (
      <ul className="c-indexList">
        {groups.map((group) => (
          <li className="c-indexListItem l-flex_between u-w_100" key={group.id}>
            <dl className="p-partnersSummary_namesItem l-flex p-partnersSummary_listItem_latestItemname">
              <dt className="u-mgr_s">
                <span className="c-idLink_hasBg p-partners_idLink u-fz_xs">
                  <i className="c-icon_groups u-mgr_xs"></i>
                  <span>{group.id}</span>
                </span>
              </dt>
              <dd className="p-partnersSummary_listItem_detail">
                <a
                  className="c-textOmit c-textlink"
                  href={kanriUrl({
                    action_owner_GROUPsummary: 'true',
                    groupsno: String(group.id),
                  })}
                >
                  {group.name}
                </a>
              </dd>
            </dl>
            <dl className="l-flex">
              <dt className="c-label c-label__monotone u-mgr_s u-fz_xs p-partnersSummary_registrationDate">
                登録日
              </dt>
              <dd>{dateFormat(group.createdday)}</dd>
            </dl>
          </li>
        ))}
      </ul>
    );

    return (
      <div className="c-frame">
        <div className="u-mgb_s">
          <h4 className="c-section_title">最新の登録団体</h4>
        </div>
        {groups.length ? groupElements : hasNoGroupsElement}
      </div>
    );
  }
);

export default LatestGroups;
