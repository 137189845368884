import { UseFormReturn } from 'react-hook-form';
import {
  EventRequestPhotographer,
  TEditFormInputs,
} from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';
import { ROUND_TRIP } from '@/ts/photographerPayments/payment_transportation_ways';
import { useEffect } from 'react';

export const useInputPriceFromTransportationWay = (
  methods: UseFormReturn<TEditFormInputs>,
  eventRequestPhotographer: EventRequestPhotographer
): void => {
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (
        name === undefined ||
        type !== 'change' ||
        !name.includes('paymentTransportationWay')
      ) {
        return;
      }
      const index = Number(name.split('.')[1]);
      calculatePriceByTripType(index);
    });

    const calculatePriceByTripType = (index: number): void => {
      const paymentTransportationWay = methods.getValues(
        `photographerPayments.${index}.paymentTransportationWay`
      );
      const photographerPaymentsPrice = methods.getValues(
        `photographerPayments.${index}.paymentPrice`
      );
      if (
        paymentTransportationWay === null ||
        photographerPaymentsPrice === null
      ) {
        return;
      }
      let multiplier = 0.5;
      if (paymentTransportationWay === ROUND_TRIP) {
        multiplier = 2;
      }
      methods.setValue(
        `photographerPayments.${index}.paymentPrice`,
        Math.round(photographerPaymentsPrice * multiplier)
      );
    };

    return () => subscription.unsubscribe();
  }, [methods, eventRequestPhotographer]);
};
