import { errorToast } from './toast';

const alertApiError = (message = '入力内容に不備があります'): void => {
  errorToast(message);
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export { alertApiError };
