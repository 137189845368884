import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { putJson } from '../../../../ts/useApi';
import {
  errorToast,
  successToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TCheck } from '../../../../types';
import { TAddCertificationkeysResponse } from './types';

const Add: React.FC<{
  bundleId: number;
  checks: TCheck[];
  refetch: () => void;
}> = React.memo(({ bundleId, checks, refetch }) => {
  const add: MouseEventHandler = useCallback(async () => {
    const checkedItems = checks.filter((c) => c.checked);
    const checkedIds = checkedItems.map((c) => c.id);
    if (
      !window.confirm(
        `${checkedIds.length}件の写真閲覧キーをキー束に紐付けます。よろしいですか？`
      )
    ) {
      return;
    }
    try {
      const { data, validator } = await putJson<TAddCertificationkeysResponse>(
        `/api/key_bundles/${bundleId}/certificationkeys`,
        {
          certificationkeyIds: checkedIds,
        }
      );
      if (data === 'OK') {
        successToast('紐付けを行いました');
        refetch();
      } else {
        errorToastWithValidatorMessages(
          '紐付けに失敗しました',
          validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [bundleId, checks, refetch]);

  return (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m"
      onClick={add}
    >
      <span>選択した写真閲覧キーを紐付け</span>
    </button>
  );
});

const AddCertificationkeys: React.FC<{
  bundleId: number;
  checks: TCheck[];
  refetch: () => void;
}> = React.memo(({ ...props }) =>
  props.checks.some((c) => c.checked) ? (
    <Add {...props} />
  ) : (
    <button
      type="button"
      className="c-btn_rectangle c-btn_create u-mgr_m is-disabled"
    >
      <span>選択した写真閲覧キーを紐付け</span>
    </button>
  )
);

export default AddCertificationkeys;
