import React from 'react';
import { Link } from 'react-router-dom';
import { TPhotographer } from '../../types';

type TProps = {
  photographer: TPhotographer;
};

export const Items: React.FC<TProps> = ({ photographer }) => {
  return (
    <>
      <div className="p-photographerIndex_grid_row">
        <div className="p-photographerIndex_grid_value p-photographerIndex_grid_value_1st">
          {photographer.status === 0 ? (
            <span className="p-photographerIndex_status not_working">
              {photographer.statusName}
            </span>
          ) : photographer.status === 1 ? (
            <span className="p-photographerIndex_status reserve">
              {photographer.statusName}
            </span>
          ) : (
            <span className="p-photographerIndex_status working">
              {photographer.statusName}
            </span>
          )}
          <Link to={'/photographers/' + photographer.id}>
            {photographer.id + ' / ' + photographer.sei + photographer.mei}
          </Link>
        </div>
        <div className="p-photographerIndex_grid_value p-photographerIndex_grid_value_2nd">
          {photographer.shortName}
        </div>
        <div className="p-photographerIndex_grid_value p-photographerIndex_grid_value_3rd">
          <div>
            {photographer.photographerOrganizationId ? (
              <Link
                to={
                  '/photographer_organizations/' +
                  photographer.photographerOrganizationId
                }
              >
                {photographer.photographerOrganizationId +
                  ' / ' +
                  photographer.photographerOrganizationName}
              </Link>
            ) : null}
          </div>
        </div>
        <div className="p-photographerIndex_grid_value p-photographerIndex_grid_value_4rd">
          <div>
            <div className="c-textOmit">{photographer.authorityName}</div>
          </div>
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.businessRegistrationNumber}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.businessRegistrationTradeName}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.prefecture}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.city}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.address}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.mailAddresses}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.telephone}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.genderName}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.hasCar ? 'あり' : 'なし'}
        </div>
        <div className="p-photographerIndex_grid_value">
          {photographer.canStay ? '可能' : '不可'}
        </div>
      </div>
    </>
  );
};
