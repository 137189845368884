import React, { useState, useEffect, useCallback } from 'react';
import Globalmenu from '../Globalmenu/App';
import './header.scss';
import logoImg from '../../images/logo.png';
import { useAuthInfo } from '../AuthProvider';
import { Link } from 'react-router-dom';

function useIsPCView() {
  const isPC = () => window.innerWidth > 767;
  const [isPCView, setIsPCView] = useState(isPC());
  useEffect(() => {
    const listner = () => setIsPCView(isPC());
    window.addEventListener('resize', listner);
    return () => {
      window.removeEventListener('resize', listner);
    };
  }, []);
  return isPCView;
}

const App: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const authInfo = useAuthInfo();
  const toggleMenu = useCallback(() => setOpened(!opened), [opened]);
  const isPCView = useIsPCView();
  return (
    <header className="l-header">
      <div className="p-header_inner">
        <div className="p-header_logoWrap">
          <h1 className="p-header_logo">
            <Link to="/">
              <img
                className="p-header_logoImg"
                src={logoImg}
                alt="はいチーズ！"
              />
            </Link>
          </h1>
        </div>
        {isPCView && <Globalmenu />}
        <div className="p-header_infoBox l-flex_end l-flex_center l-relative">
          <div className="p-header_button_box l-flex_end l-flex_center l-relative">
            <div className="p-header_button" onClick={toggleMenu}>
              <span className="p-header_text">{authInfo.name} さん</span>
            </div>
            {opened && (
              <div className="c-popup_details c-popup_submenuWrap">
                <ul className="c-popup_submenuList">
                  <li className="c-popup_submenuList_item">
                    <a className="c-textlink u-pdr_m" href="/administrator">
                      権限を確認する
                    </a>
                  </li>
                  <li className="c-popup_submenuList_item c-popup_submenuList_item__logout">
                    <a className="c-textlink" href="/auth/logout">
                      ログアウト
                    </a>
                  </li>
                </ul>
                <p
                  className="c-popup_closeBtn t-textColor_sub u-fz_xs"
                  onClick={toggleMenu}
                >
                  <i className="c-icon_close u-mgr_xs"></i>
                  <span>閉じる</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
export default App;
