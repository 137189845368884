import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  CheckBox,
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { fetchPdf } from '@/ts/fetch';
import { useJsonApi } from '@/ts/useJsonApi';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { errorToast } from '../../../../../ts/toast';
import { renderError, toMessages } from '../../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TForm, TResponse, TSelectableEvent } from './types';

import './partner_flyer.scss';
import { convertValidatorForArrayForm } from '@/components/shared/Form/validation';
import { kanriUrl } from '../../../../../ts/url';
import { alertApiError } from '../../../../../ts/formValidation';
import OverlaySpinner from '../../../../shared/OverlaySpinner/App';
import Help from '../../../../shared/Help/App';
import {
  dateFormatFromISO8601,
  dateFormatWithDayOfWeekFromISO8601,
} from '../../../../../ts/formatTools';

const SelectableEventsForm: React.FC<{
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  selectableEvents: TSelectableEvent[];
}> = ({ validator, selectableEvents }) => {
  const prefix = 'events';
  return (
    <>
      {selectableEvents.map((event, index) => (
        <div className="l-flex nowrap u-mgb_xs" key={index}>
          <CheckBox
            name={`${prefix}.${index}.checked`}
            validator={convertValidatorForArrayForm(prefix, 0, validator)}
            label=""
          />
          <div className="p-flyer_textInputWrapper">
            <TextInput
              name={`${prefix}.${index}.name`}
              validator={convertValidatorForArrayForm(prefix, 0, validator)}
            />
          </div>
        </div>
      ))}
    </>
  );
};

const Form: React.FC<{ response: TResponse }> = ({ response }) => {
  const selectableEvents: TSelectableEvent[] = [];
  const [isDownloading, setIsDownloading] = useState(false);
  response.data.events.forEach((event) => {
    const eventValue = {
      id: event.id,
      checked: true,
      name: event.name,
    };
    selectableEvents.push(eventValue);
  });

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const methods = useForm<TForm>({
    defaultValues: {
      showQr: 1,
      showCertificationkey: 1,
      title: response.data.title,
      events: selectableEvents,
      freeText: response.data.defaultFreeText,
      productDeliveryTime: 30,
      partnerName: response.data.partnerName,
      id: response.data.id,
      fileName: response.data.fileName,
      certificationkey: response.data.certificationkey,
      showPartnerNameFlag: 1,
      deliveryTypeName: response.data.deliveryTypeName,
    },
  });

  const [partnerShowFlag, setPartnerShowFlag] = useState(true);
  useEffect(() => {
    if (!partnerShowFlag) {
      methods.setValue('showPartnerNameFlag', 0);
    } else {
      methods.setValue('showPartnerNameFlag', 1);
    }
  }, [partnerShowFlag, methods]);

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(response.validator);
  useEffect(() => {
    if (response) {
      setValidator(response.validator);
    }
  }, [response]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const handleSubmit = async (data: TForm) => {
    const uniqEventNames = Array.from(
      new Set(
        data.events.filter((event) => event.checked).map((event) => event.name)
      )
    );
    if (
      uniqEventNames.length !==
      data.events.filter((event) => event.checked).length
    ) {
      alert(
        '選択したイベントに同名のイベントが含まれています。問題ないことを確認して下さい。'
      );
    }
    try {
      setIsDownloading(true);
      const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
        '/api/events/flyer/event_partner_flyer',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
      if (!(pdfResponse instanceof Blob)) {
        if (pdfResponse.validator.hasError) {
          setValidator(pdfResponse.validator);
          alertApiError('チラシのダウンロードに失敗しました');
        }
        return;
      }
      setErrorMessages([]);
      setValidator(response.validator);
      const link = downloadLinkRef.current!;
      link.href = URL.createObjectURL(pdfResponse);

      const selectedIds = data.events
        .filter((event) => event.checked)
        .map((event) => event.id)
        .join('+');

      link.download = 'Chirashi-event-' + selectedIds + '.pdf';
      link.click();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleMoveToFax = (data: TForm) => {
    const checkedIds = data.events
      .filter((event) => event.checked)
      .map((event) => event.id);
    window.location.href = kanriUrl({
      action_owner_EVENTchirashiold: 'true',
      eventno1: checkedIds[0]?.toString(),
      eventno2: checkedIds[1]?.toString() || '',
      eventno3: checkedIds[2]?.toString() || '',
    });
  };

  const formatValueStartDay = (valueStartDay: string | null): string => {
    return '開始日 : ' + dateFormatWithDayOfWeekFromISO8601(valueStartDay);
  };

  const formatValueEndDay = (valueEndDay: string | null): string => {
    return (
      'バリュー終了日 : ' + dateFormatWithDayOfWeekFromISO8601(valueEndDay)
    );
  };

  const formatPublishEndDay = (publishEndDay: string | null): string => {
    return '掲載終了日 : ' + dateFormatWithDayOfWeekFromISO8601(publishEndDay);
  };

  return (
    <FormProvider {...methods}>
      {isDownloading && <OverlaySpinner />}
      <form className="flyer_form">
        <ErrorMessages messages={errorMessages} />
        <ApiErrors {...validator} />

        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>団体名</label>
          </li>
          <li className="c-dataValue">
            <TextInput
              name="title"
              placeholder="団体名"
              validator={validator}
              additionalClassName="p-flyer_titleInput"
            />
          </li>
        </ul>

        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>期間</label>
          </li>
          <li className="c-dataValue">
            <p className="period_text">
              {formatValueStartDay(response.data.valueStartDay)}
            </p>
            {response.data.valueEndDay !== response.data.publishEndDay ? (
              <p className="period_text">
                {formatValueEndDay(response.data.valueEndDay)}
              </p>
            ) : (
              ''
            )}
            <p className="period_text">
              {formatPublishEndDay(response.data.publishEndDay)}
            </p>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>写真閲覧キー</label>
          </li>
          <li className="c-dataValue">{response.data.certificationkey}</li>
        </ul>

        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>写真閲覧キーの有無</label>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name="showCertificationkey"
              validator={validator}
              choices={[
                { key: 0, value: 'なし' },
                { key: 1, value: 'あり' },
              ]}
            />
            （写真閲覧キーなしの場合、二次元コードは表示されません）
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>イベント</label>
          </li>
          <li className="c-dataValue">
            <p>最大３つまで選択できます。</p>
            <SelectableEventsForm
              validator={validator}
              selectableEvents={selectableEvents}
            />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            <label>二次元コードの有無</label>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name="showQr"
              validator={validator}
              choices={[
                { key: 0, value: 'なし' },
                { key: 1, value: 'あり' },
              ]}
            />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">その他の注意点</li>
          <li className="c-dataValue u-pre_wrap">
            {response.data.etcAttentionPoint}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">
            チラシ・SB等配送の注意点
          </li>
          <li className="c-dataValue u-pre_wrap">
            {response.data.deliveryAttentionPoint}
          </li>
        </ul>
        {response.data.isBulkToIndividualDelivery ? (
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel dataLabel-large">一括配送終了日</li>
            <li className="c-dataValue u-pre_wrap">
              {dateFormatFromISO8601(response.data.bulkDeliveryCloseDay)}
            </li>
          </ul>
        ) : (
          ''
        )}
        {response.data.hasPayment ? (
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel dataLabel-large">支払期限</li>
            <li className="c-dataValue u-pre_wrap">
              {response.data.paymentLimitDay ? (
                <>{dateFormatFromISO8601(response.data.paymentLimitDay)}</>
              ) : (
                '「入金締日」が入力されていないため、「支払期限」がチラシに反映されません。'
              )}
            </li>
          </ul>
        ) : (
          ''
        )}
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel dataLabel-large">お知らせ</li>
          <li className="c-dataValue">
            <p>450文字・10行まで入力可能。</p>
            <TextArea
              name="freeText"
              validator={validator}
              rows={10}
              cols={validator.rules?.freeText?.maxCols}
            />
          </li>
        </ul>
        {response.data.hasCustomerCenter ? (
          <>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel dataLabel-large">撮影事業者名</li>
              <li className="c-dataValue">
                <TextInput
                  name="partnerName"
                  validator={validator}
                  placeholder="パートナー名"
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel dataLabel-large">
                カスタマーセンター
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="customerCenter"
                  validator={validator}
                  placeholder="カスタマーセンター"
                />
              </li>
            </ul>
          </>
        ) : (
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel dataLabel-large">撮影事業者名</li>
            <li className="c-dataValue">
              <label className="c-checkboxLabel">
                <input
                  type="checkbox"
                  name="showPartnerNameFlag"
                  className="c-checkbox"
                  checked={partnerShowFlag}
                  onChange={() => setPartnerShowFlag(!partnerShowFlag)}
                />
                <span className="c-label_checkbox">撮影事業者名を表示する</span>
              </label>
              {partnerShowFlag ? (
                <TextInput
                  name="partnerName"
                  validator={validator}
                  placeholder="パートナー名"
                />
              ) : (
                ''
              )}
            </li>
          </ul>
        )}
        {response.data.hasProductDeliveryTime ? (
          <ul className="l-flex c-label_line">
            <li className="c-dataLabel dataLabel-large">
              <label>
                商品の
                <br />
                配送時期
              </label>
              <Help>
                ※フルプラン一括配送は支払期限から10日前後で指定の場所へお届けいたします。
                お届け日から団体へ納品可能な日数を加味してご入力ください。
                また、セルフプリントプラン・物販で販売するイベントは、支払期限から団体へ納品可能な日数を加味してご入力ください。
              </Help>
            </li>
            <li className="l-flex">
              <TextInput
                name="productDeliveryTime"
                validator={validator}
                additionalClassName="c-label_innerHalf"
              />
            </li>
            <li>
              <p className="delivery_period_text">日を目途にお届け</p>
            </li>
          </ul>
        ) : (
          ''
        )}

        <p className="u-align_center l-flex_center_line">
          <button
            className="c-btn_large c-btn_edit is-arrow c-input_submit u-mgr_s"
            type="button"
            onClick={() => window.location.reload()}
          >
            やり直す
          </button>

          <span
            className="c-btn_large c-btn_primary is-arrow c-input_submit u-mgr_s"
            onClick={methods.handleSubmit(handleSubmit)}
          >
            チラシをダウンロード
          </span>
          <span
            className="c-btn_large c-btn_primary is-arrow c-input_submit"
            onClick={methods.handleSubmit(handleMoveToFax)}
          >
            FAX用紙作成画面へ
          </span>
        </p>
        <a href="/" className="is-hidden" ref={downloadLinkRef}>
          dummy
        </a>
      </form>
    </FormProvider>
  );
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<TResponse>(
    `/api/events/flyer/event_list_partner_flyer?eventId=${id}`
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }

  return (
    <>
      <a
        className="c-page_back"
        href={kanriUrl({ action_owner_EVENTsummary: 't', eventno: id })}
      >
        イベントサマリに戻る
      </a>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">PDFチラシ作成画面</span>
      </h3>
      <div className="c-frame">
        <Form response={response} />
      </div>
    </>
  );
};

export default App;
