import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import { fetcher, useJsonApi, renderError, toMessages } from '@/ts/useApi';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { DateInput, RadioBoxes } from '@/components/shared/Form/Inputs';
import { TFormSampleBook, TIndexData } from './types';
import { TChoice, TValidatorResponse } from '@/components/shared/Form/types';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { successToast, errorToast } from '@/ts/toast';
import { alertApiError } from '@/ts/formValidation';
import { useAuthInfo } from '@/components/AuthProvider';

type TCreateRequest = {
  photographingday: string;
  covertype: number;
  hasqrcode: number;
  colrow: string;
  haswhitepage: number;
};

const STATUS_NOT_EXISTS = 0;
const STATUS_CREATING = 1;
const STATUS_CREATED = 2;
const DEFAULT_CONVERTINFO_ID = 0;

type TShowResponse = {
  data: {
    status:
      | typeof STATUS_NOT_EXISTS
      | typeof STATUS_CREATING
      | typeof STATUS_CREATED;
    url?: string;
    progressMessage?: string;
    lastModified?: string;
  };
  formItems: {
    covertype: TChoice[];
    hasqrcode: TChoice[];
    colrow: TChoice[];
    haswhitepage: TChoice[];
  };
};

export type TStoreResponse = {
  validator: TValidatorResponse;
};

const emptyValidator: TValidatorResponse = {
  messages: {},
  hasError: false,
  rules: {},
};

// サンプルブック (サンプル)
export const SampleBook: React.FC<{ data: TIndexData }> = ({ data }) => {
  const authInfo = useAuthInfo();
  const info = data.main.sampleBook;
  // DOM生成前に、SBのステータスを確認し、表示を出し分ける
  const { eventId } = useParams<{ eventId: string }>();
  const {
    data: sbData,
    error,
    mutate,
  } = useJsonApi<TShowResponse>('/api/events/' + eventId + '/samplebook');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);

  // SB作成処理アクション
  const createSB = usePreventDuplicateCall(async (request: TCreateRequest) => {
    try {
      const URL = '/api/events/' + eventId + '/samplebook';
      const response = await fetcher<TStoreResponse>(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      if (!response.validator.hasError) {
        mutate((response) =>
          response
            ? {
                ...response,
                data: { ...response.data, status: 1 },
              }
            : undefined
        );
        successToast('サンプルブックの作成を受け付けました');
      } else {
        alertApiError('サンプルブックの作成に失敗しました');
      }
      setValidator(response.validator);
      setErrorMessages([]);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
      mutate();
    }
  });

  // SB削除処理アクション
  const deleteSB = usePreventDuplicateCall(async () => {
    try {
      await fetcher('/api/events/' + eventId + '/samplebook', {
        method: 'DELETE',
      });
      successToast('サンプルブックを削除しました');
      mutate((response) =>
        response
          ? {
              ...response,
              data: { ...response.data, status: STATUS_NOT_EXISTS },
            }
          : undefined
      );
      setErrorMessages([]);
    } catch (e) {
      setErrorMessages(toMessages(e));
      mutate();
    }
  });

  // requiredのみなので最初は空のvalidatorにする
  useEffect(() => {
    if (sbData?.data.status === STATUS_CREATING) {
      const intervalId = setInterval(() => {
        mutate(undefined, { keepCurrentData: true });
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [mutate, sbData?.data.status]);
  if (error) {
    return renderError(error);
  }
  if (!sbData) {
    return <></>;
  }
  if (!info.requireSb && !info.requireSbJizen) {
    return <div>この団体はサンプルブックはありません。</div>;
  }
  if (info.convertInfoNo === DEFAULT_CONVERTINFO_ID) {
    return (
      <div>
        写真がないため、サンプルブックは作成できません。
        {!!info.sampleBookAttentionPoint && (
          <>
            <br />
            [注意]
            <br />
            <div className="u-pre_wrap">{info.sampleBookAttentionPoint}</div>
          </>
        )}
      </div>
    );
  }
  return (
    <div>
      {!!info.sampleBookAttentionPoint && (
        <>
          [注意]
          <br />
          <div className="u-pre_wrap">{info.sampleBookAttentionPoint}</div>
        </>
      )}
      <div className="t-bgBox_gray">
        <ErrorMessages messages={errorMessages} />
        {sbData.data.status === STATUS_NOT_EXISTS ? (
          <>
            <SBCreateForm
              sbData={sbData.formItems}
              validator={validator}
              photographingday={info.photographingDay}
              handleCreate={createSB}
            />
          </>
        ) : sbData.data.status === STATUS_CREATING ? (
          <SBCreating sbData={sbData} handleCancel={deleteSB} />
        ) : sbData.data.status === STATUS_CREATED ? (
          <SBDownloadForm
            sbData={sbData}
            handleCancel={deleteSB}
            canOperation={authInfo.authOperation}
            dateSentMail={info.dateSentMail}
          />
        ) : undefined}
      </div>
      <br />
      <p>
        <span className="p-eventShow_dataTitle">
          サンプルブック確認コマンド：
        </span>
        aws s3 ls s3://samplebook/qa/8122.jp/999999/99999999/
        {/* TODO: ちゃんとしたコマンド */}
        <span className="p-eventShow_dataTitle u-mgl_m">期間外販売：</span>
        {info.valueEndDay === info.publishEndDay ? 'なし' : 'あり'}
      </p>
    </div>
  );
};

/**
 *
 * @returns サンプルブック作成済パターン (ダウンロードリンク表示)
 */
const SBDownloadForm: React.FC<{
  sbData: TShowResponse;
  handleCancel: () => void;
  canOperation: boolean;
  dateSentMail: string | null;
}> = ({ sbData, handleCancel, canOperation, dateSentMail }) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <>
      <p className="c-section_title">ダウンロードの準備が整いました</p>
      作成日時：{dayjs(sbData.data.lastModified).format('YYYY-MM-DD HH:mm:ss')}
      <br />
      <p className="u-fz_xs c-alert_text">
        非表示、回転、差替え作業をした場合は、削除して作成しなおしてください。
      </p>
      <div>
        <a
          href={sbData.data.url}
          download
          className="c-btn c-btn_create c-btn_small"
        >
          ダウンロードする
        </a>
        <button
          type="button"
          className="c-btn c-btn_delete c-btn_small u-mgl_l"
          onClick={handleCancel}
        >
          削除
        </button>
        {canOperation && (
          <Link
            to={`/events/${eventId}/samplebook/mail/create`}
            className="c-btn c-btn_edit c-btn_small u-mgl_l"
          >
            メール作成
          </Link>
        )}
        {dateSentMail && (
          <span className="u-fz_xs u-mgl_s">
            送信日時：
            {dayjs(dateSentMail).format('YYYY-MM-DD HH:mm')}
          </span>
        )}
      </div>
    </>
  );
};

// 作成中のローディング処理
const useLoadingDots = () => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setCounter((cnt) => (cnt + 1) % 3),
      1000
    );
    return () => {
      clearInterval(intervalId);
    };
  });
  return Array.from({ length: counter + 1 }, () => '・').join('');
};

/**
 * サンプルブック作成中表示
 * @returns
 */
const SBCreating: React.FC<{
  sbData: TShowResponse;
  handleCancel: () => void;
}> = ({ sbData, handleCancel }) => {
  const loadingDots = useLoadingDots();
  return (
    <>
      <p className="c-section_title">サンプルブックを作成中です</p>
      {sbData.data.progressMessage
        ? sbData.data.progressMessage + 'を処理中' + loadingDots
        : '処理を準備中' + loadingDots}
      <p>
        <button
          type="button"
          className="c-btn c-btn_small c-btn_edit"
          onClick={handleCancel}
        >
          キャンセル
        </button>
      </p>
    </>
  );
};

/**
 * サンプルブック作成用Form
 * @param props
 * @returns
 */
const SBCreateForm: React.FC<{
  sbData: TFormSampleBook;
  validator: TValidatorResponse;
  photographingday: string;
  handleCreate: (request: TCreateRequest) => void;
}> = ({ sbData, validator, photographingday, handleCreate }) => {
  const methods = useForm<TCreateRequest>({
    defaultValues: {
      photographingday: dayjs(photographingday).format('YYYY-MM-DD'),
      covertype: 1,
      hasqrcode: 1,
      colrow: '4x5',
      haswhitepage: 1,
    },
  });
  const { handleSubmit } = methods;

  return (
    <div>
      <FormProvider {...methods}>
        <div className="l-flex">
          <div className="c-dataLabel">撮影日</div>
          <div className="c-dataValue">
            <DateInput name="photographingday" validator={validator} />
          </div>
        </div>
        <div className="l-flex l-col_wrap">
          <div className="l-col_12">
            <div className="c-dataLabel">QRコード</div>
            <div className="c-dataValue">
              <RadioBoxes
                name="hasqrcode"
                choices={sbData.hasqrcode}
                validator={validator}
                isInline={true}
              />
            </div>
          </div>
          <div className="l-col_12">
            <div className="c-dataLabel">表紙タイプ</div>
            <div className="c-dataValue">
              <RadioBoxes
                name="covertype"
                choices={sbData.covertype}
                validator={validator}
                isInline={true}
              />
            </div>
          </div>
        </div>
        <div className="l-flex l-col_wrap">
          <div className="l-col_12">
            <div className="c-dataLabel">写真の並べ方</div>
            <div className="c-dataValue">
              <RadioBoxes
                name="colrow"
                choices={sbData.colrow}
                validator={validator}
                isInline={true}
              />
            </div>
          </div>
          <div className="l-col_12">
            <div className="c-dataLabel">白紙の挿入</div>
            <div className="c-dataValue">
              <RadioBoxes
                name="haswhitepage"
                choices={sbData.haswhitepage}
                validator={validator}
                isInline={true}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="c-btn c-btn_create c-btn_small"
          onClick={handleSubmit(handleCreate)}
        >
          PDF作成
        </button>
      </FormProvider>
    </div>
  );
};

export default SampleBook;
