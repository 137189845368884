import React, { ChangeEvent } from 'react';

const SearchInput: React.FC<{
  searchWord: string;
  onSearchWordChange: (w: string) => void;
  handleFocusStatusChange: (b: boolean) => void;
}> = ({ searchWord, onSearchWordChange, handleFocusStatusChange }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    onSearchWordChange(e.target.value);
  return (
    <input
      type="text"
      className="p-global_search_input"
      name="input"
      value={searchWord}
      onChange={onChange}
      onFocus={() => handleFocusStatusChange(true)}
      onBlur={() => handleFocusStatusChange(false)}
    />
  );
};

export default SearchInput;
