import React from 'react';
import { TSalesForecasts } from './types';
import { formatMoney } from './App';
import { dateFormatWithDayOfWeekFromISO8601 } from '../../../../ts/formatTools';
import {
  getEventRequestsCreateBySocietyContractPlanUrl,
  getEventRequestsCreateBySocietyContractPlanPreviousYearEventNoUrl,
} from '../../../../ts/legacyUrl';

const formatPhotographingTime = (
  startHour: string | null,
  startMinute: string | null,
  finishHour: string | null,
  finishMinute: string | null
): string => {
  if (
    startHour === null ||
    startMinute === null ||
    finishHour === null ||
    finishMinute === null
  ) {
    return '';
  }
  return (
    startHour + ':' + startMinute + ' ~ ' + finishHour + ':' + finishMinute
  );
};

const formatPercent = (num: number | null): string => {
  return num === null ? '' : Math.floor(num * 100) + '%';
};

const formatCostRate = (num: number | null): string => {
  return num === null ? '' : num + '%';
};

const formatParticipants = (num: number | null): string => {
  return num === null ? '' : num + '人';
};

const previousEventCancelClass = (salesForecast: TSalesForecasts): string => {
  if (salesForecast.previousYearEventNo === null) {
    return '';
  }
  return salesForecast.previousYearIsCanceled
    ? 'p-salesForecastsIndex-bg-darkgray-overlay '
    : '';
};

const SalesForecastTable: React.FC<{
  salesForecasts: TSalesForecasts[];
  planId: number;
  societyContractId: number;
}> = React.memo(({ salesForecasts, societyContractId, planId }) => (
  <div>
    <div className="u-mgt_xs p-salesForecastsTable_wrap">
      <table className="p-salesForecastsTable">
        <colgroup className="p-salesForecastsIndex-w-110" />
        <colgroup className="p-salesForecastsIndex-bg-green p-salesForecastsIndex-w-110" />
        <colgroup className="p-salesForecastsIndex-w-200" />
        <colgroup className="p-salesForecastsIndex-bg-green p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-w-100" />
        <colgroup className="p-salesForecastsIndex-bg-yellow p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-bg-yellow p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-bg-yellow p-salesForecastsIndex-w-80" />
        <colgroup />
        <colgroup className="p-salesForecastsIndex-w-80 p-salesForecastsIndex-bg-lightgray" />
        <colgroup className="p-salesForecastsIndex-w-80" />
        <colgroup className="p-salesForecastsIndex-w-200 p-salesForecastsIndex-bg-lightgray" />
        <colgroup className="p-salesForecastsIndex-w-180" />
        <colgroup className="p-salesForecastsIndex-bg-lightgray" />
        <colgroup />
        <thead>
          <tr className="u-borderTop_none">
            <td
              colSpan={5}
              className="p-salesForecastsTable_title u-radiusLt_base p-salesForecastsIndex-bg-green p-salesForecastsTable_fixed1"
            >
              今年度イベント申請状況
              <span className="p-salesForecastsIndex-event-request-icon">
                <a
                  className="c-btn_rectangle c-btn_create"
                  href={getEventRequestsCreateBySocietyContractPlanUrl(
                    societyContractId,
                    planId
                  )}
                >
                  新規イベント申請
                </a>
              </span>
            </td>
            <td
              colSpan={5}
              className="p-salesForecastsTable_title p-salesForecastsIndex-bg-yellow"
            >
              今年度売上予測
            </td>
            <td
              colSpan={7}
              className="p-salesForecastsTable_title u-radiusRt_base p-salesForecastsIndex-bg-lightgray"
            >
              昨年度イベント実績
            </td>
          </tr>
          <tr>
            <th rowSpan={2} className="p-salesForecastsTable_fixed1">
              申請年月日
            </th>
            <th rowSpan={2} className="p-salesForecastsTable_fixed2">
              撮影年月日
            </th>
            <th rowSpan={2} className="p-salesForecastsTable_fixed3">
              イベント名
            </th>
            <th rowSpan={2} className="p-salesForecastsTable_fixed4">
              申請状況
            </th>
            <th rowSpan={2} className="p-salesForecastsTable_fixed5">
              イベント種別
            </th>
            <th colSpan={2}>手入力</th>
            <th colSpan={2} className="p-salesForecastsIndex-bg-white">
              自動
            </th>
            <th rowSpan={2}>
              予定
              <br />
              参加人数
            </th>
            <th rowSpan={2}></th>
            <th rowSpan={2}>実施状況</th>
            <th rowSpan={2}>撮影年月日</th>
            <th rowSpan={2}>イベント名</th>
            <th rowSpan={2}>売上実績</th>
            <th rowSpan={2}>参加人数</th>
            <th rowSpan={2}>
              写真閲覧キー
              <br />
              登録率
            </th>
          </tr>
          <tr>
            <th>予想売上</th>
            <th>予想原価率</th>
            <th>予想売上</th>
            <th>予想原価率</th>
          </tr>
        </thead>
        <tbody>
          {salesForecasts.map((salesForecast: TSalesForecasts, index) => (
            <tr key={index}>
              <td className="p-salesForecastsTable_fixed1">
                {salesForecast.currentYearEventNo
                  ? dateFormatWithDayOfWeekFromISO8601(
                      salesForecast.currentYearCreatedDay
                    )
                  : '未申請'}
              </td>
              <td className="p-salesForecastsTable_fixed2">
                {salesForecast.currentYearEventNo ? (
                  <div>
                    {dateFormatWithDayOfWeekFromISO8601(
                      salesForecast.currentYearPhotographingDay
                    )}
                    <br />
                    {formatPhotographingTime(
                      salesForecast.currentYearPhotographingTimeStartHour,
                      salesForecast.currentYearPhotographingTimeStartMinute,
                      salesForecast.currentYearPhotographingTimeFinishHour,
                      salesForecast.currentYearPhotographingTimeFinishMinute
                    )}
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td className="p-salesForecastsTable_fixed3">
                {salesForecast.currentYearEventNo ? (
                  <div>
                    <div className="u-mgb_xs">
                      <span className="c-idLink_hasBg c-idLink_hasBg__flex">
                        <i className="c-icon_event"></i>
                        <span className="c-textOmit">
                          {salesForecast.currentYearEventNo}
                        </span>
                      </span>
                    </div>
                    <div className="l-flex_between u-w_100 p-salesForecastsIndex-event-name-link">
                      <p className="u-mgr_s u-w_85">
                        <a href={`/events/${salesForecast.currentYearEventNo}`}>
                          {salesForecast.currentYearEventName}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td className="u-align_center p-salesForecastsTable_fixed4">
                {salesForecast.currentYearEventNo ? (
                  <p
                    className={
                      'c-statusLabel u-mgr_0 c-statusLabel__salesForecast-small c-statusLabel__' +
                      (salesForecast.currentYearIsTransferred
                        ? 'positive'
                        : 'yellow')
                    }
                  >
                    {salesForecast.currentYearEventRequestingStatusName}
                  </p>
                ) : (
                  ''
                )}
              </td>
              <td className="p-salesForecastsTable_fixed5">
                {salesForecast.currentYearEventNo
                  ? salesForecast.currentYearEventTypeName
                  : ''}
              </td>
              <td className="u-align_right">
                {salesForecast.currentYearEventNo
                  ? formatMoney(
                      salesForecast.currentYearManuallyEnteredSalesForecast
                    )
                  : ''}
              </td>
              <td>
                {salesForecast.currentYearEventNo
                  ? formatCostRate(
                      salesForecast.currentYearCostRateForecastWithManuallyEnteredSalesForecast
                    )
                  : ''}
              </td>
              <td className="u-align_right">
                {salesForecast.currentYearEventNo
                  ? formatMoney(
                      salesForecast.currentYearAutomaticallyCalculatedSalesForecast
                    )
                  : ''}
              </td>
              <td>
                {salesForecast.currentYearEventNo
                  ? formatCostRate(
                      salesForecast.currentYearCostRateForecastWithAutomaticallyCalculatedSalesForecast
                    )
                  : ''}
              </td>
              <td>
                {salesForecast.currentYearEventNo
                  ? formatParticipants(salesForecast.currentYearParticipants)
                  : ''}
              </td>
              <td>
                {salesForecast.previousYearEventNo && (
                  <a
                    className="c-btn_rectangle c-btn_create"
                    href={getEventRequestsCreateBySocietyContractPlanPreviousYearEventNoUrl(
                      societyContractId,
                      planId,
                      salesForecast.previousYearEventNo
                    )}
                  >
                    今年度
                    <br />
                    分申請
                  </a>
                )}
              </td>
              <td
                className={
                  'u-align_center ' + previousEventCancelClass(salesForecast)
                }
              >
                {salesForecast.previousYearEventNo ? (
                  <p
                    className={
                      'c-statusLabel u-mgr_0 c-statusLabel__salesForecast-small c-statusLabel__' +
                      (salesForecast.previousYearIsCanceled
                        ? 'yellow'
                        : 'negative')
                    }
                  >
                    {salesForecast.previousYearIsCanceled === null
                      ? '未実施'
                      : salesForecast.previousYearCancelingStatusName}
                  </p>
                ) : (
                  '実績無し'
                )}
              </td>
              <td className={previousEventCancelClass(salesForecast)}>
                {salesForecast.previousYearEventNo ? (
                  <div>
                    {dateFormatWithDayOfWeekFromISO8601(
                      salesForecast.previousYearPhotographingDay
                    )}
                    <br />
                    {formatPhotographingTime(
                      salesForecast.previousYearPhotographingTimeStartHour,
                      salesForecast.previousYearPhotographingTimeStartMinute,
                      salesForecast.previousYearPhotographingTimeFinishHour,
                      salesForecast.previousYearPhotographingTimeFinishMinute
                    )}
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td className={previousEventCancelClass(salesForecast)}>
                {salesForecast.previousYearEventNo ? (
                  <div>
                    <div className="u-mgb_xs">
                      <span className="c-idLink_hasBg p-salesForecastsIndex-bg-darkgray c-idLink_hasBg__flex">
                        <i className="c-icon_event"></i>
                        <span className="c-textOmit">
                          {salesForecast.previousYearEventNo}
                        </span>
                      </span>
                    </div>
                    <div className="l-flex_between u-w_100">
                      <p className="u-mgr_s u-w_85">
                        <a
                          href={`/events/${salesForecast.previousYearEventNo}`}
                        >
                          {salesForecast.previousYearEventName}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td
                className={
                  'u-align_right ' + previousEventCancelClass(salesForecast)
                }
              >
                {salesForecast.previousYearEventNo
                  ? formatMoney(salesForecast.previousYearSales)
                  : ''}
              </td>
              <td className={previousEventCancelClass(salesForecast)}>
                {salesForecast.previousYearEventNo
                  ? formatParticipants(salesForecast.previousYearParticipants)
                  : ''}
              </td>
              <td className={previousEventCancelClass(salesForecast)}>
                {salesForecast.previousYearEventNo
                  ? formatPercent(salesForecast.previousYearKeyRegistrationRate)
                  : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
));

export default SalesForecastTable;
