import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { postJson, putJson } from '@/ts/fetch';
import {
  TCategoryUpdateRequest,
  TIndexResponse,
  TIndexResponseData,
  TPhotograph,
} from './types';
import Photograph from './Photograph';
import BreadNavi from '../BreadNavi';
import dayjs from 'dayjs';
import { Filter, CategoryMenu, SortMenu } from './links';
import QueryString from 'query-string';
import {
  ChangeCategoryModal,
  HideManyModal,
  ShowManyModal,
  THideManyModalProps,
  TChangeCategoryModalProps,
  TShowManyModalProps,
  TReverseViewFlagModalProps,
  ReverseViewFlagModal,
} from '../modals';
import CategoryEditModal from './CategoryEditModal';
import PhotographInfoModal, {
  TPhotographInfoModalProps,
} from './PhotographInfoModal';
import { scrollToModalTop } from '../../BaseModal';
import { context, usePhotographIndexState } from './context';
import { TCategoryEditModalProps } from './CategoryEditModal';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '../../Form/types';
import {
  QualityAlertSummaryModal,
  TQualityAlertSummaryModalProps,
} from '@/components/shared/Photographs/Index/QualityAlertSummaryModal';

type TMenusProps = {
  showMoveMenu: boolean;
  allChecked: boolean;
  handleAllCheckedChange: (b: boolean) => void;
  handleChangeCategoryMany: () => void;
  handleShowMany: () => void;
  handleHideMany: () => void;
  handleReverseViewflag: () => void;
  handleShowPhotographInfo: () => void;
  handleShowQualityAlertSummary: () => void;
};

const Menus: React.FC<TMenusProps> = ({
  showMoveMenu,
  allChecked,
  handleAllCheckedChange,
  handleChangeCategoryMany,
  handleHideMany,
  handleShowMany,
  handleReverseViewflag,
  handleShowPhotographInfo,
}) => {
  return (
    <>
      <label className="c-checkboxLabel u-align_center">
        <input
          className="c-checkbox"
          type="checkbox"
          checked={allChecked}
          onChange={(e) => handleAllCheckedChange(e.target.checked)}
        />
        <span className="c-label_checkbox small">すべてチェック</span>
      </label>
      <br />
      <div className="u-mgt_s u-mgb_s">
        <span className="u-mgr_s">チェックしたものを</span>
        {showMoveMenu && (
          <button
            className="c-btn_large c-btn_edit u-mgr_s"
            onClick={handleChangeCategoryMany}
          >
            まとめて移動
          </button>
        )}
        <button
          className="c-btn_large c-btn_edit u-mgr_s"
          onClick={handleHideMany}
        >
          まとめて非表示
        </button>
        <button
          className="c-btn_large c-btn_edit u-mgr_s"
          onClick={handleShowMany}
        >
          まとめて表示
        </button>
        <button
          className="c-btn_large c-btn_edit u-mgr_s"
          onClick={handleReverseViewflag}
        >
          まとめて反転
        </button>
        {/*TODO:千管理画面のみ*/}
        <button
          className="c-btn_large c-btn_edit u-mgr_s"
          onClick={handleShowPhotographInfo}
        >
          情報を確認する
        </button>
      </div>
    </>
  );
};

const useHideManyModalProps = (
  data: TIndexResponseData | undefined,
  formItems: TIndexResponse['formItems'] | undefined,
  handleDataChanged: (
    mutate: (data: TIndexResponseData) => TIndexResponseData
  ) => void
): {
  showModal: boolean;
  handleOpen: (checkedIds: number[]) => void;
  props: THideManyModalProps;
} => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotographIds, setSelectedPhotographIds] = useState<number[]>(
    []
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { handleApiSuccess, handleApiError } = usePhotographIndexState();
  const handleOpen = (checkedIds: number[]) => {
    const targetPhotographIds = checkedIds.filter(
      (id) =>
        data?.photographs.find((photograph) => photograph.id === id)?.isHide ===
        false
    );
    if (targetPhotographIds.length === 0) {
      window.alert('表示の写真を一枚以上選択してください');
      return;
    }
    const showPhotographsCount = data?.photographs.filter(
      (photograph) => !photograph.isHide
    ).length;
    if (targetPhotographIds.length === showPhotographsCount) {
      window.alert(
        'すべての写真を非表示には出来ません。カテゴリそのものを非表示にしてください。'
      );
      return;
    }
    setSelectedPhotographIds(targetPhotographIds);
    setShowModal(true);
  };

  const handleConfirm = async (reasons: number[]) => {
    try {
      await postJson('/api/photographs/hide_many', {
        photographIds: selectedPhotographIds,
        reasons,
      });
      handleDataChanged((data) => ({
        ...data,
        photographs: data.photographs.map((photograph) => ({
          ...photograph,
          isHide: selectedPhotographIds.includes(photograph.id)
            ? true
            : photograph.isHide,
        })),
      }));
      setShowModal(false);
      setErrorMessages([]);
      handleApiSuccess(
        `${selectedPhotographIds.length}枚の写真を非表示にしました`
      );
    } catch (e) {
      scrollToModalTop();
      setErrorMessages(handleApiError(e));
    }
  };
  return {
    showModal,
    handleOpen,
    props: {
      handleClose: () => {
        setShowModal(false);
      },
      selectedPhotographs:
        data?.photographs.filter((photograph) =>
          selectedPhotographIds.includes(photograph.id)
        ) || [],
      handleConfirm,
      hiddenReasons:
        formItems?.photograph.hiddenReason.filter(
          (reason) => reason.registerable
        ) || [],
      errorMessages,
    },
  };
};

const useShowManyModalProps = (
  data: TIndexResponseData | undefined,
  handleDataChanged: (
    mutate: (data: TIndexResponseData) => TIndexResponseData
  ) => void
): {
  showModal: boolean;
  handleOpen: (checkedIds: number[]) => void;
  props: TShowManyModalProps;
} => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotographIds, setSelectedPhotographIds] = useState<number[]>(
    []
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { handleApiSuccess, handleApiError } = usePhotographIndexState();
  const handleOpen = (checkedIds: number[]) => {
    const targetPhotographIds = checkedIds.filter(
      (id) =>
        data?.photographs.find((photograph) => photograph.id === id)?.isHide ===
        true
    );
    if (targetPhotographIds.length === 0) {
      window.alert('非表示の写真を一枚以上選択してください');
      return;
    }
    setSelectedPhotographIds(targetPhotographIds);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    try {
      await postJson('/api/photographs/show_many', {
        photographIds: selectedPhotographIds,
      });
      handleDataChanged((data) => ({
        ...data,
        photographs: data.photographs.map((photograph) => ({
          ...photograph,
          isHide: selectedPhotographIds.includes(photograph.id)
            ? false
            : photograph.isHide,
        })),
      }));
      setShowModal(false);
      setErrorMessages([]);
      handleApiSuccess(
        `${selectedPhotographIds.length}枚の写真を表示にしました`
      );
    } catch (e) {
      scrollToModalTop();
      setErrorMessages(handleApiError(e));
    }
  };
  return {
    showModal,
    handleOpen,
    props: {
      handleClose: () => {
        setShowModal(false);
      },
      selectedPhotographs:
        data?.photographs.filter((photograph) =>
          selectedPhotographIds.includes(photograph.id)
        ) || [],
      handleConfirm,
      errorMessages,
    },
  };
};

const useChangeCategoryModalProps = (
  data: TIndexResponseData | undefined,
  handleDataChanged: (
    mutate: (data: TIndexResponseData) => TIndexResponseData
  ) => void
): {
  showModal: boolean;
  handleOpen: (checkedIds: number[]) => void;
  props: TChangeCategoryModalProps | undefined;
} => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotographIds, setSelectedPhotographIds] = useState<number[]>(
    []
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { handleApiSuccess, handleApiError } = usePhotographIndexState();
  const handleOpen = (checkedIds: number[]) => {
    if (checkedIds.length === 0) {
      window.alert('写真を一枚以上選択してください');
      return;
    }
    setSelectedPhotographIds(checkedIds);
    setShowModal(true);
  };

  const handleConfirm = async (categoryId: number) => {
    try {
      await postJson('/api/photographs/change_category_many', {
        photographIds: selectedPhotographIds,
        categoryId,
      });
      handleDataChanged((data) => ({
        ...data,
        photographs: data.photographs.filter(
          (photograph) => !selectedPhotographIds.includes(photograph.id)
        ),
      }));
      setShowModal(false);
      setErrorMessages([]);
      handleApiSuccess(
        `${selectedPhotographIds.length}枚の写真をカテゴリ移動しました`
      );
    } catch (e) {
      scrollToModalTop();
      setErrorMessages(handleApiError(e));
    }
  };
  return {
    showModal: !!data?.category && showModal,
    handleOpen,
    props:
      data && data.category
        ? {
            handleClose: () => {
              setShowModal(false);
            },
            selectedPhotographs:
              data?.photographs.filter((photograph) =>
                selectedPhotographIds.includes(photograph.id)
              ) || [],
            handleConfirm,
            currentCategory: data.category,
            parentCategories: data.parentCategories,
            errorMessages,
          }
        : undefined,
  };
};

const usePhotographInfoModalProps = (
  data: TIndexResponseData | undefined
): {
  showModal: boolean;
  handleOpen: (checkedIds: number[]) => void;
  props: TPhotographInfoModalProps;
} => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPhotographIds, setSelectedPhotographIds] = useState<number[]>(
    []
  );
  const handleOpen = (checkedIds: number[]) => {
    if (checkedIds.length === 0) {
      window.alert('写真を一枚以上選択してください');
      return;
    }
    setSelectedPhotographIds(checkedIds);
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  return {
    showModal,
    handleOpen,
    props: {
      handleClose,
      selectedPhotographs:
        data?.photographs.filter((photograph) =>
          selectedPhotographIds.includes(photograph.id)
        ) || [],
    },
  };
};

const useCategoryEditModalProps = (
  response: TIndexResponse | undefined,
  handleDataChanged: (
    mutate: (data: TIndexResponseData) => TIndexResponseData
  ) => void
): {
  showModal: boolean;
  handleOpen: () => void;
  props: TCategoryEditModalProps | undefined;
} => {
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >({ rules: {} });
  const { handleApiSuccess, handleApiError } = usePhotographIndexState();
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  useEffect(() => {
    if (response && response.validators && response.validators.categoryUpdate) {
      setValidator(response?.validators.categoryUpdate);
    }
  }, [response]);
  const handleConfirm = async (formData: TCategoryUpdateRequest) => {
    try {
      const updateResponse = (await putJson(
        `/api/categories/${response?.data.category?.id}`,
        formData
      )) as { validator: TValidatorResponse };
      handleDataChanged((data) => ({
        ...data,
        category: data.category
          ? {
              ...data.category,
              categoryname: formData.name,
              viewflag: formData.viewflag,
            }
          : null,
      }));
      setValidator(updateResponse.validator);
      setErrorMessages([]);
      if (updateResponse.validator.hasError) {
        handleApiError(null, 'カテゴリの情報変更に失敗しました');
        return;
      }
      setShowModal(false);
      handleApiSuccess('カテゴリの情報を変更しました');
    } catch (e) {
      console.log(e);
      scrollToModalTop();
      setErrorMessages(handleApiError(e));
    }
  };
  return {
    showModal,
    handleOpen,
    props:
      response && response.data.category
        ? {
            handleClose,
            defaultValues: {
              name: response.data.category.categoryname,
              viewflag: response.data.category.viewflag,
            },
            validator: validator,
            formItems: response.formItems.category,
            handleConfirm,
            errorMessages,
            phototypeName: response.data.category.phototypemastername,
          }
        : undefined,
  };
};

const useReverseViewflagModalProps = (
  data: TIndexResponseData | undefined,
  handleDataChanged: (
    mutate: (data: TIndexResponseData) => TIndexResponseData
  ) => void
): {
  reverseModal: boolean;
  handleOpen: (checkedIds: number[]) => void;
  props: TReverseViewFlagModalProps;
} => {
  const [reverseModal, setViewFlagReverseModal] = useState(false);
  const [selectedPhotographIds, setSelectedPhotographIds] = useState<number[]>(
    []
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { handleApiSuccess, handleApiError } = usePhotographIndexState();
  const handleOpen = (checkedIds: number[]) => {
    const targetPhotographIds = checkedIds.filter((id) =>
      data?.photographs.find((photograph) => photograph.id === id)
    );
    if (targetPhotographIds.length === 0) {
      window.alert('非表示の写真を一枚以上選択してください');
      return;
    }
    setSelectedPhotographIds(targetPhotographIds);
    setViewFlagReverseModal(true);
  };

  const handleConfirm = async () => {
    try {
      await postJson('/api/photographs/reverse_viewflag', {
        photographIds: selectedPhotographIds,
      });
      handleDataChanged((data) => ({
        ...data,
        photographs: data.photographs.map((photograph) => ({
          ...photograph,
          isHide: selectedPhotographIds.includes(photograph.id)
            ? !photograph.isHide
            : photograph.isHide,
        })),
      }));
      setViewFlagReverseModal(false);
      setErrorMessages([]);
      handleApiSuccess(`${selectedPhotographIds.length}枚の写真を反転しました`);
    } catch (e) {
      scrollToModalTop();
      setErrorMessages(handleApiError(e));
    }
  };
  return {
    reverseModal,
    handleOpen,
    props: {
      handleClose: () => {
        setViewFlagReverseModal(false);
      },
      selectedPhotographs:
        data?.photographs.filter((photograph) =>
          selectedPhotographIds.includes(photograph.id)
        ) || [],
      handleConfirm,
      errorMessages,
    },
  };
};

const useQualityAlertSummaryModalProps = (
  data: TIndexResponseData | undefined
): {
  showModal: boolean;
  handleOpen: () => void;
  props: TQualityAlertSummaryModalProps;
} => {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return {
    showModal,
    handleOpen,
    props: {
      handleClose,
      photographs: data?.photographs ?? [],
      latestVersion: data?.photographQualityAlertVersion ?? '',
    },
  };
};

const Container: React.FC<{
  response: TIndexResponse;
  mutate: (
    f: (d: TIndexResponse | undefined) => TIndexResponse | undefined
  ) => void;
  showSocietyName: boolean;
  showEditButton: boolean;
}> = ({ response, mutate, showSocietyName, showEditButton }) => {
  const location = useLocation();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const handleDataChanged = (
    mutateFunc: (data: TIndexResponseData) => TIndexResponseData
  ) =>
    mutate((response) =>
      response ? { ...response, data: mutateFunc(response.data) } : undefined
    );
  const {
    showModal: showShowManyModal,
    handleOpen: handleShowMany,
    props: showManyModalProps,
  } = useShowManyModalProps(response?.data, handleDataChanged);
  const {
    showModal: showHideManyModal,
    handleOpen: handleHideMany,
    props: hideManyModalProps,
  } = useHideManyModalProps(
    response?.data,
    response?.formItems,
    handleDataChanged
  );
  const {
    showModal: showChangeCategoryModal,
    handleOpen: handleChangeCategory,
    props: changeCategoryModalProps,
  } = useChangeCategoryModalProps(response?.data, handleDataChanged);
  const {
    showModal: showPhotographInfoModal,
    handleOpen: handleShowPhotographInfo,
    props: photographInfoModalProps,
  } = usePhotographInfoModalProps(response?.data);
  const {
    showModal: showCategoryEditModal,
    handleOpen: handleShowCategoryEditModal,
    props: categoryEditModalProps,
  } = useCategoryEditModalProps(response, handleDataChanged);
  const { showMultipleUpdate } = usePhotographIndexState();
  const {
    reverseModal: reverseViewFlagModal,
    handleOpen: handleReverseViewflag,
    props: reverseViewFlagModalProps,
  } = useReverseViewflagModalProps(response?.data, handleDataChanged);
  const {
    showModal: showQualityAlertSummaryModal,
    handleOpen: handleShowQualityAlertSummary,
    props: qualityAlertSummaryModalProps,
  } = useQualityAlertSummaryModalProps(response?.data);

  const { data } = response;
  const query = QueryString.parse(location.search);
  const showMoveMenu = !!data.category;
  const { event } = data;
  const postPeriod = event
    ? !event.valueStartDay && !event.publishEndDay
      ? '(未定)'
      : `${dayjs(event.valueStartDay).format('YYYY-MM-DD')} 〜 ${dayjs(
          event.publishEndDay
        ).format('YYYY-MM-DD')}`
    : undefined;
  const showBottomMenu = data.photographs.length > 10;
  const handlePhotographChange = (id: number) => (photograph: TPhotograph) => {
    handleDataChanged((data) => ({
      ...data,
      photographs: data.photographs.map((currentPhotograph) =>
        currentPhotograph.id === photograph.id ? photograph : currentPhotograph
      ),
    }));
  };
  const handleEventThumbnailChanged = (id: number) => () => {
    const url = response.data.photographs.find(
      (photograph) => photograph.id === id
    )?.url;
    handleDataChanged((data) => ({
      ...data,
      eventThumbnailUrl: url ?? data.eventThumbnailUrl,
      photographs: data.photographs.map((photograph) => ({
        ...photograph,
        isMainEventThumbnail: photograph.id === id,
      })),
    }));
  };
  const handleChecked = (id: number) => (b: boolean) => {
    if (b) {
      setCheckedIds([...checkedIds, id]);
    } else {
      setCheckedIds(checkedIds.filter((currentId) => currentId !== id));
    }
  };
  const allChecked = data.photographs.every((photograph) =>
    checkedIds.includes(photograph.id)
  );
  const handleAllCheckedChange = (b: boolean) => {
    if (b) {
      setCheckedIds(data.photographs.map((photograph) => photograph.id));
    } else {
      setCheckedIds([]);
    }
  };
  const filteredCheckedIds = checkedIds.filter(
    (id) =>
      data.photographs.find((photograph) => photograph.id === id) !== undefined
  );
  const menusProps: TMenusProps = {
    showMoveMenu,
    allChecked,
    handleAllCheckedChange,
    handleChangeCategoryMany: () => handleChangeCategory(filteredCheckedIds),
    handleShowMany: () => handleShowMany(filteredCheckedIds),
    handleHideMany: () => handleHideMany(filteredCheckedIds),
    handleReverseViewflag: () => handleReverseViewflag(filteredCheckedIds),
    handleShowPhotographInfo: () =>
      handleShowPhotographInfo(filteredCheckedIds),
    handleShowQualityAlertSummary,
  };
  return (
    <div>
      <div className="p-photographs_breadnav">
        <div className="l-flex_between">
          <div className="p-photographs_breadnavWrapper">
            <BreadNavi
              event={event}
              convertinfo={data.convertinfo}
              category={data.category}
              isHideParentCategory={data.isHideParentCategory}
              photographCount={
                query['filter'] !== 'sold' ? data.photographs.length : undefined
              }
              showSocietyName={showSocietyName}
            />
          </div>
          {showEditButton && (
            <button
              className={`c-btn_large c-btn_edit u-mgr_s p-photographs_editButton ${
                !!response.data.category ? '' : 'is-disabled'
              }`}
              onClick={() => handleShowCategoryEditModal()}
            >
              変更する
              <i className="c-icon c-icon_small c-icon_edit u-pdl_s" />
            </button>
          )}
          {/*
          TODO:
          ライトプランのパートナーでログインしている場合は写真追加不可
          */}
          {data.category && (
            <a
              className="c-btn_large c-btn_create"
              target="_blank"
              rel="noopener noreferrer"
              href={`/categories/${data.category.id}/photographs/insert`}
            >
              写真を追加する
              <i className="c-icon c-icon__small c-icon_create u-pdl_xs" />
            </a>
          )}
        </div>
        {!!event && (
          <div className="p-photographs_salesInfo">
            {data.category ? 'カテゴリ内の' : 'イベント全体の'}売れた写真：
            <span className="p-photographs_salesInfoNumber u-mgr_s u-mgl_s">
              {data.salesInformation.count}枚
              {data.salesInformation.amount !== null &&
                `${data.salesInformation.amount}円`}
            </span>
          </div>
        )}
      </div>
      <div className="l-flex l-flex_between nowrap">
        <div className="p-photographs_linkContainer">
          {event && (
            <div className="l-flex l-flex_between nowrap">
              <div>
                撮影日：
                {dayjs(event.photographingDay).format('YYYY-MM-DD')}
                <span className="u-mgr_s u-mgl_s">|</span>
                掲載期間：{postPeriod}
              </div>
              {event.isDirectPhotoPlan && (
                <div className="u-align_right">
                  <button
                    className="c-btn_small c-btn_primary u-mgr_s"
                    onClick={handleShowQualityAlertSummary}
                  >
                    写真品質判定サマリを確認する
                  </button>
                </div>
              )}
            </div>
          )}
          <CategoryMenu
            parentCategories={data.parentCategories}
            isShowParentCat={!data.isHideParentCategory}
            hasAllSoldLink={!!event}
          />
        </div>
        <div className="p-photographs_eventThumbnailContainer">
          <img
            src={data.eventThumbnailUrl}
            alt=""
            className="p-photographs_photo"
          />
          イベントの代表
        </div>
      </div>
      <div className="l-flex_between u-mgt_s">
        <Filter showSoldFilter={!!event} />
        <SortMenu currentSort={data.currentSort} />
      </div>
      {showMultipleUpdate && <Menus {...menusProps} />}
      {data.photographs.length > 0 ? (
        <div className="l-flex">
          {data.photographs.map((photograph) => (
            <Photograph
              key={photograph.id}
              photograph={photograph}
              handlePhotographChange={handlePhotographChange(photograph.id)}
              canChangeCategory={!!data.category}
              handleEventThumbnailChanged={handleEventThumbnailChanged(
                photograph.id
              )}
              handleChecked={handleChecked(photograph.id)}
              checked={checkedIds.includes(photograph.id)}
              handleCategoryChange={() => handleChangeCategory([photograph.id])}
              isDirectPhotoPlan={data.event?.isDirectPhotoPlan ?? false}
            />
          ))}
        </div>
      ) : (
        <div>
          {query['filter'] === 'sold' ? (
            <>売れた写真はありません</>
          ) : query['filter'] === 'hidden' ? (
            <>非表示写真はありません</>
          ) : (
            <>写真はありません。</>
          )}
        </div>
      )}
      {showBottomMenu && showMultipleUpdate && <Menus {...menusProps} />}
      {showShowManyModal && <ShowManyModal {...showManyModalProps} />}
      {showHideManyModal && <HideManyModal {...hideManyModalProps} />}
      {showChangeCategoryModal && changeCategoryModalProps && (
        <ChangeCategoryModal {...changeCategoryModalProps} />
      )}
      {showPhotographInfoModal && (
        <PhotographInfoModal {...photographInfoModalProps} />
      )}
      {showCategoryEditModal && categoryEditModalProps && (
        <CategoryEditModal {...categoryEditModalProps} />
      )}
      {showBottomMenu && showMultipleUpdate && (
        <CategoryMenu
          parentCategories={data.parentCategories}
          isShowParentCat={!data.isHideParentCategory}
          hasAllSoldLink={!!event}
        />
      )}
      {reverseViewFlagModal && reverseViewFlagModalProps && (
        <ReverseViewFlagModal {...reverseViewFlagModalProps} />
      )}
      {showQualityAlertSummaryModal && (
        <QualityAlertSummaryModal {...qualityAlertSummaryModalProps} />
      )}
    </div>
  );
};

const App: React.FC<{
  response: TIndexResponse;
  mutate: (
    f: (d: TIndexResponse | undefined) => TIndexResponse | undefined
  ) => void;
  handleApiSuccess: (s: string) => void;
  handleApiError: (e: unknown, s?: string) => string[];
  showSocietyName?: boolean;
  showMultipleUpdate?: boolean;
  showValidationError?: boolean;
  showEditButton?: boolean;
  showIndividualHide?: boolean;
}> = ({
  handleApiSuccess,
  handleApiError,
  showSocietyName = true,
  showMultipleUpdate = true,
  showValidationError = true,
  showEditButton = false,
  showIndividualHide = true,
  ...props
}) => {
  const state = useMemo(
    () => ({
      handleApiSuccess,
      handleApiError,
      showMultipleUpdate,
      showValidationError,
      showIndividualHide,
    }),
    [
      handleApiSuccess,
      handleApiError,
      showMultipleUpdate,
      showValidationError,
      showIndividualHide,
    ]
  );
  return (
    <context.Provider value={state}>
      <Container
        showSocietyName={showSocietyName}
        showEditButton={showEditButton}
        {...props}
      />
    </context.Provider>
  );
};

export const useApiUrl = (): string => {
  const { eventId } = useParams<{ eventId?: string }>();
  const location = useLocation();
  return eventId
    ? `/api/events/${eventId}/photographs` + location.search
    : `/api/photographs` + location.search;
};
export default App;
