import React from 'react';
import styles from './FormItem.module.scss';
import clsx from 'clsx';

type Props = {
  title: string | React.ReactNode;
  wideTitle?: boolean;
  children?: React.ReactNode;
};
export const FormItem = React.memo<Props>(function FormItem({
  title,
  wideTitle,
  children,
}) {
  return (
    <div className={styles.container}>
      {title && typeof title === 'string' ? (
        <div
          className={
            wideTitle ? clsx(styles.title, styles.wideTitle) : styles.title
          }
        >
          {title}
        </div>
      ) : (
        title
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
});
