import '../../Show/corporateInfo.scss';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import Paginator from '@/components/shared/Paginator/App';
import SearchForm from './SearchForm';
import SearchSocieties from './SearchSocieties';
import { ApiResult, CorporationSociety, Society, TFormInputs } from './types';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { useJsonApiForSearchForm } from '../../../../../ts/useJsonApiForSearchForm';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { errorToast, successToast } from '../../../../../ts/toast';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const queryString = useLocation().search;
  const {
    data: result,
    error: searchError,
    mutate: mutateSociety,
  } = useJsonApiForSearchForm<ApiResult>('/api/societies', queryString);

  const emptyValidator = {
    messages: {},
    hasError: false,
    rules: {},
  };
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [postCorporationSocietyValidator, setPostCorporationSocietyValidator] =
    useState<TValidatorResponse | TOnlyValidationRuleResponse>(emptyValidator);

  const {
    data: corporationSociety,
    error: societyError,
    mutate: mutateCorporationSociety,
  } = useJsonApi<CorporationSociety>('/api/corporations/' + id + '/societies');

  const queryParams = convertQueryToFormData(queryString, result?.validator);
  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    (formData) => {
      const newPath = makePathForSearchForm(
        '/corporations/' + id + '/societies/edit/',
        queryString,
        formData
      );

      history.push(newPath);
      setPostCorporationSocietyValidator({
        messages: {},
        hasError: false,
        rules: {},
      });
      mutateCorporationSociety();
    },
    [id, history, queryString, mutateCorporationSociety]
  );

  type CheckSociety = {
    id: number;
    checked: boolean;
  };

  const methods = useForm();
  const [societyIds, setSocietyIds] = useState<CheckSociety[]>([]);

  useEffect(() => {
    if (result) {
      setSocietyIds(
        result.data.map((data, index) => ({
          id: data.societyId,
          checked: false,
        }))
      );
    }
  }, [result, corporationSociety]);

  const location = useLocation();
  useEffect(() => {
    setPostCorporationSocietyValidator({
      messages: {},
      hasError: false,
      rules: {},
    });
  }, [location]);

  // チェックボックス押したとき
  // societyIdsのおされたとこtrueにする
  const onChangeCheckBox = (position: number, id: number) => {
    const updateCheckedState = societyIds.map((item, index) =>
      item.id === id
        ? { id: item.id, checked: !item.checked }
        : { id: item.id, checked: item.checked }
    );

    setSocietyIds(updateCheckedState);
  };

  const onChangeAllCheck = (b: boolean) => {
    if (b) {
      const setSocieties = result!.data.map((society) =>
        society.corporationId != null
          ? {
              id: society.societyId,
              checked: false,
            }
          : {
              id: society.societyId,
              checked: true,
            }
      );
      setSocietyIds(setSocieties);
    } else {
      setSocietyIds(
        result!.data.map((data, index) => ({
          id: data.societyId,
          checked: false,
        }))
      );
    }
  };

  // societyIdsからsocietyId探す
  // あったらcheckedを返す
  const isChecked = (societyId: number) => {
    return societyIds.find((item) => item.id === societyId)?.checked || false;
  };

  // submitボタン押したとき
  const onSubmitLink: SubmitHandler<Record<string, unknown>> = async () => {
    try {
      const checkedSocietyIds = societyIds
        .filter((item) => item.checked === true)
        .map((item) => item.id);
      const postCorporationSociety: ApiResult = await fetcher(
        '/api/corporations/' + id + '/societies',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ societyIds: checkedSocietyIds }),
        }
      );
      if (postCorporationSociety.validator.hasError) {
        setPostCorporationSocietyValidator(postCorporationSociety.validator);
        errorToast('エラーが発生しました');
        mutateCorporationSociety();
        mutateSociety();
        return;
      }
      setPostCorporationSocietyValidator(emptyValidator);
      setErrorMessages([]);
      successToast('紐付けを行いました');
      mutateCorporationSociety();
      mutateSociety();
    } catch (error) {
      errorToast('エラーが発生しました');
      setErrorMessages(toMessages(error));
    }
  };

  const onSubmitDelete = async (societyId: number) => {
    if (window.confirm('法人への紐付けを解除します。よろしいですか？')) {
      try {
        await fetcher('/api/corporations/' + id + '/societies/' + societyId, {
          method: 'DELETE',
        });
        setErrorMessages([]);
        successToast('紐付けを解除しました');
        mutateCorporationSociety();
        mutateSociety();
      } catch (error) {
        errorToast('エラーが発生しました');
        setErrorMessages(toMessages(error));
      }
    }
  };

  if (searchError) {
    return renderError(searchError);
  }
  if (societyError) {
    return renderError(societyError);
  }
  if (!result) {
    return <SvgLoading />;
  }
  if (!corporationSociety) {
    return <SvgLoading />;
  }

  return (
    <div className="App">
      <div className="c-page_back">
        <Link to={'/corporations/' + id}>法人サマリへ戻る</Link>
      </div>
      <h1>法人 - 紐付け編集</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <div className="l-flex p-groupsSummary_Label">
              <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                法人情報ID: {corporationSociety?.corporation.id}
              </span>
              <h4 className="c-section_title u-fz_l">
                {corporationSociety?.corporation.name}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="c-frame">
        <SearchForm
          validator={result.validator}
          formItems={result.formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <form onSubmit={methods.handleSubmit(onSubmitLink)}>
          <Paginator
            pagination={result.pagination}
            currentPath={`/corporations/${id}/societies/edit/`}
            queryString={queryString}
          />
          <label className="c-checkboxLabel u-align_center">
            <input
              className="c-checkbox"
              type="checkbox"
              onChange={(e) => onChangeAllCheck(e.target.checked)}
            />
            <span className="c-label_checkbox small">すべてチェック</span>
          </label>
          <SaveApiError messages={errorMessages} />
          <ApiErrors {...postCorporationSocietyValidator} />
          <div className="p-eventsIndex_scrollbar">
            <SearchSocieties
              societies={result.data}
              corporationSocieties={corporationSociety.societies}
              isChecked={isChecked}
              onSubmit={onSubmitLink}
              onChange={onChangeCheckBox}
            />
          </div>
          <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
            <input
              type="submit"
              value="選択した団体を紐付け"
              className="c-btn_large c-btn_primary c-input_submit"
            ></input>
          </div>
        </form>
      </div>
      <div className="c-frame">
        <div className="c-grid_wrap u-mgt_s">
          <div className="l-flex_between_center u-mgb_s">
            <h4 className="c-section_title u-fz_l">紐付け済み団体一覧</h4>
          </div>
          <ul className="c-indexList p-groupsList">
            {corporationSociety?.societies.map((society: Society) => (
              <li
                className="c-indexListItem l-clearfix"
                key={society.society.id}
              >
                <div className="u-mgr_s l-flex_between_center">
                  <div>
                    <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                      <i className="c-icon_groups u-mgr_xs"></i>
                      <span>{society.society.id}</span>
                    </div>
                    <Link
                      className="c-textlink"
                      to={`/societies/${society.society.id}`}
                    >
                      <h4 className="c-textOmit">{society.society.name}</h4>
                    </Link>
                  </div>
                  <div>
                    <ul className="l-flex p-CorporationList_info">
                      <li>
                        <i className="c-icon_map u-mgr_xs" />
                        <span>
                          {society.organization.prefectureName}{' '}
                          {society.organization.city}{' '}
                          {society.organization.address}
                        </span>
                      </li>
                      <li>
                        <i className="c-icon_tel u-mgr_xs" />
                        <span>{society.organization.telephonenumber}</span>
                      </li>
                      <li>
                        <i className="c-icon_person u-mgr_xs" />
                        <span>{society.society.salesOwnerName}</span>
                      </li>
                      <li>
                        <button
                          className="c-btn_rectangle c-btn_delete u-mgr_s"
                          onClick={() => onSubmitDelete(society.society.id)}
                        >
                          <i className="c-icon_Xsmall c-icon_trash" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default App;
