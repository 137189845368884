import React from 'react';
import { dateFormatFromISO8601 } from '../../../../../ts/formatTools';
import {
  TDelayedEvents,
  TEventsWithinOneWeek,
  TNotDelayedEvents,
} from '../types';
import { Link } from 'react-router-dom';

type EventType = 'eventsWithinOneWeek' | 'delayedEvents' | 'notDelayedEvents';
export const EventTable: React.FC<{
  eventList: (TEventsWithinOneWeek | TNotDelayedEvents | TDelayedEvents)[];
  title: string;
  eventType: EventType;
}> = ({ eventList, title, eventType }) => {
  return (
    <div className="c-frame">
      <div className="u-pdb_s u-mgb_m">
        <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
          <h2>{title}</h2>
        </div>
        <table className="p-photographersShow_eventTable">
          <thead>
            <tr>
              <th>カメラマン</th>
              <th>撮影日</th>
              {eventType === 'delayedEvents' ||
              eventType === 'notDelayedEvents' ? (
                <th>納品日</th>
              ) : (
                <></>
              )}
              <th>団体ID</th>
              <th>イベントID</th>
              <th>掲載開始日</th>
              <th>チラシ</th>
              <th>SB</th>
              <th>DVD</th>
            </tr>
          </thead>
          <tbody>
            {eventList.map((event, idx) => (
              <tr key={idx}>
                <td>
                  <Link to={`/photographers/${event.photographerId}`}>
                    {event.photographerId}/{event.photographerName}
                  </Link>
                </td>
                <td>{dateFormatFromISO8601(event.photographingDay)}</td>
                {['delayedEvents', 'notDelayedEvents'].includes(eventType) &&
                'deliveryLimitDay' in event ? (
                  <td>{dateFormatFromISO8601(event.deliveryLimitDay)}</td>
                ) : (
                  <></>
                )}
                <td>
                  <a href={event.societyLink}>{event.societyId}</a>
                </td>
                <td>
                  <a href={event.eventLink}>{event.eventNo}</a>
                </td>
                <td>{dateFormatFromISO8601(event.valueStartDay, '未定')}</td>
                <td>
                  {event.isRequiredFlyer ? (
                    <>
                      {dateFormatFromISO8601(event.flyerSendDay, '')}
                      {event.isFlyerSend ? '済み' : 'まだ'}
                    </>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {event.isRequiredSampleBook ? (
                    <>
                      {dateFormatFromISO8601(event.sbSendDay, '')}
                      {event.isSbSend ? '済み' : 'まだ'}
                    </>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {event.isRequiredDvd ? (
                    <>
                      {dateFormatFromISO8601(event.dvdSendDay, '')}
                      {event.isDvdSend ? '済み' : 'まだ'}
                    </>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
