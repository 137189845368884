import { FC } from 'react';
import styles from './ButtonsFooter.module.scss';

export const ButtonsFooter: FC = ({ children }) => {
  return (
    <>
      <div className={styles.padding}></div>
      <div className={styles.main}>{children}</div>
    </>
  );
};
