import { useRef, useCallback } from 'react';

// 処理中に複数回呼び出した場合二回目以降は中の関数を呼び出さないような関数を返します
export function usePreventDuplicateCall<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => Promise<void>
>(f: T): T {
  const processing = useRef<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<T>(
    (async (...args: unknown[]) => {
      if (processing.current) {
        return;
      }
      processing.current = true;
      try {
        return await f(...args);
      } finally {
        processing.current = false;
      }
    }) as T,
    [f]
  );
}
