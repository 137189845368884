import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TextInput, DateInput } from '@/components/shared/Form/Inputs';
import {
  TValidatorResponse,
  TOnlyValidationRuleResponse,
} from '@/components/shared/Form/types';
import { alertApiError } from '@/ts/formValidation';
import { fetchPdf } from '@/ts/fetch';
import { errorToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';

import { TCreateResponse, TForm } from '../types';

const ApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const SendForm: React.FC<TCreateResponse> = React.memo(({ eventno, data }) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const fileName = data.fileName;
  const defaultValues = {
    toName: data.toName,
    createDate: dayjs(data.createDate).format('YYYY-MM-DD'),
  };
  const methods = useForm<TForm>({ defaultValues });
  const onSubmit = async (formData: TForm) => {
    try {
      const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
        `/api/events/${eventno}/flyer/cover_letter/download`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      if (!(pdfResponse instanceof Blob)) {
        if (pdfResponse.validator.hasError) {
          setValidator(pdfResponse.validator);
          alertApiError();
        }
        return;
      }
      setErrorMessages([]);
      const link = downloadLinkRef.current!;
      const url = URL.createObjectURL(pdfResponse);
      link.href = url;
      link.download = fileName;
      link.click();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  return (
    <FormProvider {...methods}>
      <ApiError messages={errorMessages} />
      <ApiValidationErrors {...validator} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>団体名</label>
          </li>
          <li className="c-dataValue">{data.societyName}</li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>団体ID</label>
          </li>
          <li className="c-dataValue">
            <Link to={`/societies/${data.societyId}`} target="_blank">
              {data.societyId}
            </Link>
            <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>配送先住所指定</label>
          </li>
          {data.isRequiredDeliveryAddress ? (
            <li className="c-dataValue">あり</li>
          ) : (
            <li className="c-dataValue">なし</li>
          )}
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>宛先</label>
          </li>
          <li className="c-dataValue">
            <TextInput name="toName" placeholder="宛先" validator={validator} />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>作成日付</label>
          </li>
          <li className="c-dataValue">
            <DateInput name="createDate" validator={validator} />
          </li>
        </ul>
        <p className="u-align_center l-flex_center_line">
          <input
            className="c-btn_large c-btn_edit is-arrow c-input_submit"
            type="submit"
            value={'作成ダウンロード'}
          />
        </p>
        <a href="/" className="is-hidden" ref={downloadLinkRef}>
          dummy
        </a>
      </form>
    </FormProvider>
  );
});

export default SendForm;
