export type Order = {
  data: {
    orderInfo: OrderInfo;
    summary: PhotoSummary;
    orderDeliveryInfo: OrderDeliveryInfo;
    printLog: PrintLog;
    photos: OrderPhoto[];
    originalPhotos: OriginalPhoto[];
  };
};

export type PrintLog = {
  jvis?: JvisPrintLog;
  hokuryo?: HokuryoPrintLog;
  machineTech?: MachineTechPrintLog;
};

export type OrderInfo = {
  id: number;
  userAccountNo: number;
  firstName: string | null;
  secondName: string | null;
  createdAt: string;
  paymentDate: string;
  paymentLimitDate: string;
  paymentType: number;
  paymentTypeMasterNo: number;
  paymentStatusMasterNo: number;
  paymentStatus: number;
  photoSum: number;
  commission: number;
  usePoint: number;
  totalPayment: number;
  conveniencePaymentNum1: string;
  conveniencePaymentNum2: string;
  conveniencePaymentNum3: string;
  conveniencePaymentNumTyped1: string | null;
  conveniencePaymentNumTyped2: string | null;
  conveniencePaymentNumTyped3: string | null;
  conveniencePaymentNumTyped4: string | null;
  reductionPoint: number;
  pressingMailFlag: number;
  telephone: string;
  mobilePcFlag: number;
  collectOnDeliveryFlag: number;
  carriage: number;
  collectCarriage: number;
  discount: number;
  downloadLimitDate: string;
  printCompanyFlag: string;
  bundleId: number | null;
  taxAmount: number;
  taxRate: number;
  paymentTransactionId: string | null;
  couponDiscount: number;
  isForbiddenExtendPaymentLimit: boolean;

  total: number;
  paymentTypeName: string;
  paymentStatusName: string;
};

export type PhotoSummary = {
  count: number;
  photoAmount: number;
  commission: number;
  countBySize: Record<string, number>;
  countBySizeOriginal: Record<string, number>;
  totalAmount: number;
};

export type OrderPhoto = {
  id: number;
  societyId: number;
  societyName: string;
  eventId: number;
  eventName: string;
  cameramanId: number;
  cameramanName: string;
  categoryNo: number;
  categoryName: string;
  orderPhotoNo: number;
  photoType: string;
  albumSalesFlag: boolean;
  deleteMailFlag: boolean;
  discount: number;
  hasOverlayText: boolean;
  overlayText?: string;
  isEdit: boolean;
  photoUrl: string;
  photoCommission: number;
  photoName: string;
  photoPrice: number;
  photoSize: string;
  photoSum: number;
  previewUrl: string;
  parentCategoryNo?: number;
  parentCategoryName?: string;
  isPartner: boolean;
};

export type OriginalPhoto = {
  id: number;
  tsuidePhotographId: number;
  photoSize: string;
  price: number;
  quantity: number;
  url: string;
};

export type OrderDeliveryInfo = {
  id: number;
  groupName?: string;
  firstName: string;
  secondName: string;
  postalCode: string;
  prefecture: string;
  prefectureName: string;
  city: string;
  address: string;
  telephone: string;
  className?: string;
  nendo?: string; // 年度?
  printCompanyFlag: string;
  printCompanyName: string;
  carriage: number;
  collectCarriage?: number;
  bundleId?: number;
};

export type JvisBulkDelivery = {
  id: number;
  uploadAt?: string;
  deliveredAt?: string;
  invoiceNo?: number;
  createdAt: string;
  closedAt?: string;
};

export type JvisPrintLog = {
  id: number;
  orderType: number;
  deliveredAt?: string;
  invoiceNo: number;
  createdAt: string;
  mailSentFlag: number;
  bulkDelivery?: JvisBulkDelivery;
};

export type OrderType = 1 | 2 | 3;
export const OrderTypeLabel: { [key in OrderType]: string } = {
  1: 'web',
  2: 'tax',
  3: 'addsen',
};

export type HokuryoPrintLog = {
  id: number;
  orderType: OrderType;
  printedAt?: string;
  invoicedAt?: string;
  invoiceNo?: number;
  error?: string;
  createdAt: string;
  mailSentFlag: number;
};

export type MachineTechPrintLog = {
  id: number;
  orderType: OrderType;
  fileName: string;
  printedAt?: string;
  invoicedAt?: string;
  invoiceNo?: number;
  error?: string;
  createdAt: string;
  mailSentFlag: number;
};
