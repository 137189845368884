import React from 'react';
import Help from '../../../../shared/Help/App';
import './style.scss';
export const FormRow: React.FC<
  React.PropsWithChildren<{
    title: string;
    helpText?: string;
    isHidden?: boolean;
    isRequired?: boolean;
  }>
> = ({ children, title, helpText, isHidden = false, isRequired = false }) => {
  if (isHidden) {
    return null;
  }
  return (
    <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <td className="l-col_24">
        <ul className="l-flex">
          <li className="c-dataLabel l-flex">
            <span className="u-mgr_m">{title}</span>
            {isRequired ? <small className="c-required">(必須)</small> : <></>}
            {helpText ? <Help>{helpText}</Help> : <></>}
          </li>
          <li className="c-dataValue u-pre_wrap">{children}</li>
        </ul>
      </td>
    </tr>
  );
};
