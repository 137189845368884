const INCENTIVE_PAYMENT_TYPE_SALES = 1;
const SPECIAL_INCENTIVE_RATE_TYPE_NONE = 0;
const SPECIAL_INCENTIVE_RATE_TYPE_L = 1;

const isIncentivePaymentTypeSales = (incentivePaymentType: number): boolean =>
  incentivePaymentType === INCENTIVE_PAYMENT_TYPE_SALES;
const isSpecialIncentiveRateTypeL = (
  specialIncentiveRateType: number | undefined
): boolean => specialIncentiveRateType === SPECIAL_INCENTIVE_RATE_TYPE_L;

export {
  INCENTIVE_PAYMENT_TYPE_SALES,
  SPECIAL_INCENTIVE_RATE_TYPE_NONE,
  isIncentivePaymentTypeSales,
  isSpecialIncentiveRateTypeL,
};
