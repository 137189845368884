import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { isValidationError, renderError, toMessages } from '@/ts/useApi';
import { useJsonApi } from '@/ts/useJsonApi';
import { FC, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { kanriUrl } from '@/ts/url';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import {
  NumberInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { postFormData } from '@/ts/fetch';
import { FileUpload } from '@/components/shared/Form/FileUpload';
import {
  ALLOWED_FILE_NAME_REGEXP,
  FILE_NAME_NOT_ALLOWED_MESSAGE,
} from '../validate';
import { FormError } from '@/components/shared/Form/Errors';

type Payment = {
  id: number;
  photographerId: number;
  photographerSei: string;
  photographerMei: string;
  photographerOrganizationId: string;
  photographerOrganizationName: string;
  eventName: string;
  eventId: number;
  type: number;
  price: number;
  fileName: string | null;
  externalMemo: string;
};

type EditResponse = {
  data: {
    payments: Payment[];
    types: TChoice[];
    consumptionTaxPercent: TChoice[];
  };
  validator: TValidator;
};

type FormValues = {
  updates: {
    price: number;
    type: number;
    externalMemo: string;
    file?: File;
    deleteCurrentFile?: boolean;
  }[];
};

const Form: FC<{
  index: number;
  validator: TValidator;
  payment: Payment;
  typeChoices: TChoice[];
  consumptionTaxPercentChoices: TChoice[];
}> = ({
  index,
  validator,
  payment,
  typeChoices,
  consumptionTaxPercentChoices,
}) => {
  const methods = useFormContext();
  const { errors } = methods.formState;
  const handleFileChange = (file: File) => {
    if (!ALLOWED_FILE_NAME_REGEXP.test(file.name)) {
      errorToast(FILE_NAME_NOT_ALLOWED_MESSAGE);
      return false;
    }
    methods.setValue(`updates.${index}.file`, file);
    return true;
  };
  const handleDeleteFile = () => {
    methods.setValue(`updates.${index}.deleteCurrentFile`, true);
  };
  return (
    <div className="c-frame">
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">カメラマン</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${payment.photographerId} / ${payment.photographerSei} ${payment.photographerMei}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">カメラマン組織</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${payment.photographerOrganizationId} / ${payment.photographerOrganizationName}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">イベント</div>
        <div className="c-dataValue">
          <div className="l-flex_center l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${payment.eventId} / ${payment.eventName}`}
              />
            </div>
            <div className="l-col_8">
              <div className="l-flex u-mgl_s">
                <a
                  className="c-textlink"
                  href={kanriUrl({
                    action_owner_SHOUSAIinput: 't',
                    eventno: payment.eventId.toString(),
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  イベント詳細メールを見る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">
          詳細区分
          <small className="c-required">(必須)</small>
        </div>
        <div className="c-dataValue">
          <RadioBoxes
            name={`updates.${index}.type`}
            choices={typeChoices}
            validator={validator}
          />
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">
          金額(税込)
          <small className="c-required">(必須)</small>
        </div>
        <div className="c-dataValue">
          <NumberInput name={`updates.${index}.price`} validator={validator} />
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">税率</div>
        <div className="c-dataValue">
          <RadioBoxes
            name={`updates.${index}.consumptionTaxPercent`}
            choices={consumptionTaxPercentChoices}
            validator={validator}
          />
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">添付ファイル</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_8">
              <FileUpload
                initialFileName={payment.fileName || ''}
                onChangeFile={handleFileChange}
                onDeleteFile={handleDeleteFile}
                name={`updates.${index}.file`}
              />
              <div className="t-textColor_sub">
                詳細区分が移動費以外の場合はファイルの登録が必須です
              </div>
              <FormError
                name={`updates.${index}.file`}
                errors={errors}
                validator={validator}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">
          備考
          <small className="c-required">(必須)</small>
        </div>
        <div className="c-dataValue">
          <TextArea
            name={`updates.${index}.externalMemo`}
            validator={validator}
          />
          <div className="t-textColor_sub">※明細に表示されます</div>
        </div>
      </div>
    </div>
  );
};

const Content: FC<{ response: EditResponse }> = ({ response }) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      updates: response.data.payments.map((payment) => ({
        type: payment.type,
        price: payment.price,
        externalMemo: payment.externalMemo,
        consumptionTaxPercent: response.data.consumptionTaxPercent.find(
          (choice) => choice.isDisabled !== true
        )!.key,
      })),
    },
  });
  const initialValidator: TValidator = response.validator;
  const [validator, setValidator] = useState<TValidator>(initialValidator);
  const history = useHistory();
  const handleSubmit = usePreventDuplicateCall(
    async (formValues: FormValues) => {
      try {
        const form = new FormData();
        for (const index in formValues.updates) {
          form.append(
            `updates[${index}][price]`,
            formValues.updates[parseInt(index)].price.toString()
          );
          form.append(
            `updates[${index}][type]`,
            formValues.updates[parseInt(index)].type.toString()
          );
          form.append(
            `updates[${index}][externalMemo]`,
            formValues.updates[parseInt(index)].externalMemo
          );
          form.append(
            `updates[${index}][id]`,
            response.data.payments[parseInt(index)].id.toString()
          );
          form.append(
            `updates[${index}][deleteCurrentFile]`,
            (
              formValues.updates[parseInt(index)].deleteCurrentFile || false
            ).toString()
          );
          const file = formValues.updates[parseInt(index)].file;
          if (file) {
            form.append(`updates[${index}][file]`, file);
          }
        }
        await postFormData(
          '/api/photographer_payments/expenses/bulk_update',
          form
        );
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          errorToast('入力に誤りがあります');
        } else {
          setValidator(initialValidator);
          errorToast(`編集に失敗しました: ${toMessages(e)}`);
        }
        return;
      }
      successToast('編集に成功しました');
      history.push('/photographer_payments/expenses');
    }
  );
  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          {
            id: 3,
            title: '申請経費一覧',
            path: '/photographer_payments/expenses',
          },
          { id: 4, title: '申請経費代理編集', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">申請経費代理編集</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <FormProvider {...methods}>
          {response.data.payments.map((payment, index) => (
            <Form
              index={index}
              payment={payment}
              validator={validator}
              typeChoices={response.data.types}
              consumptionTaxPercentChoices={response.data.consumptionTaxPercent}
              key={index}
            />
          ))}
          <ButtonsFooter>
            <Link
              className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
              to="/photographer_payments/expenses"
            >
              キャンセル
            </Link>
            <button className="c-btn_large c-btn_primary c-input_submit">
              登録
            </button>
          </ButtonsFooter>
        </FormProvider>
      </form>
    </div>
  );
};

export const Edit: FC = () => {
  const location = useLocation();
  const { data: response, error } = useJsonApi<EditResponse>(
    `/api/photographer_payments/expenses/edit${location.search}`
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return <Content response={response} />;
};
