import React from 'react';

import { isContractTypeTeacher } from '../../../../ts/societyContractConstants';
import {
  isIncentivePaymentTypeSales,
  isSpecialIncentiveRateTypeL,
} from '../../../../ts/salesManagementConstants';
import { HYPHEN } from '../../../../ts/formatTools';
import { TSalesManagementPlanFormItems } from '../types';
import {
  TValidatorResponse,
  TOnlyValidationRuleResponse,
} from '@/components/shared/Form/types';
import {
  NumberInput,
  NumberLooseInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { useFormContext } from 'react-hook-form';
import { TFormInputs } from './types';

const IncentivePaymentTypeSales: React.FC<{
  index: number;
  specialIncentiveRateType: number | undefined;
  formItems: TSalesManagementPlanFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(
  ({
    index,
    specialIncentiveRateType,
    formItems: {
      incentivePaymentTypeForInput,
      specialIncentiveRateType: specialIncentiveRateTypeItems,
    },
    validator,
  }) => (
    <>
      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
        <li className="c-label_innerHalf">
          <ul className="l-flex">
            <li className="c-dataLabel">
              <label>インセンティブ種類</label>{' '}
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <RadioBoxes
                name={`salesManagementPlans.${index}.incentivePaymentType`}
                choices={incentivePaymentTypeForInput}
                validator={validator}
                optionalValidationRule={{ required: true }}
              />
            </li>
          </ul>
        </li>
        <li className="c-label_innerHalf">
          <ul className="l-flex">
            <li className="c-dataLabel">
              <label>手数料率</label>{' '}
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <NumberLooseInput
                name={`salesManagementPlans.${index}.incentiveRate`}
                additionalClassName="u-align_right"
                validator={validator}
                step={0.01}
                unit="%"
                optionalValidationRule={{ required: true }}
              />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
        <li className="c-label_innerHalf">
          <ul className="l-flex">
            <li className="c-dataLabel">
              <label>請求明細書 特殊集計</label>{' '}
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <RadioBoxes
                name={`salesManagementPlans.${index}.specialIncentiveRateType`}
                choices={specialIncentiveRateTypeItems}
                validator={validator}
                // TODO: ↓radioがvalidationに対応していないため
                // optionalValidationRule={{ required: true }}
              />
            </li>
          </ul>
        </li>
        <li className="c-label_innerHalf" />
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-label_innerHalf">
          <ul className="l-flex">
            <li className="c-dataLabel">
              <label>特殊集計しきい値</label>
              {isSpecialIncentiveRateTypeL(specialIncentiveRateType) && (
                <small className="c-required">(必須)</small>
              )}
            </li>
            <li className="c-dataValue">
              {isSpecialIncentiveRateTypeL(specialIncentiveRateType) ? (
                <NumberInput
                  name={`salesManagementPlans.${index}.incentiveRateThresholdPrice`}
                  additionalClassName="u-align_right"
                  validator={validator}
                  unit="円"
                  optionalValidationRule={{ required: true }}
                />
              ) : (
                HYPHEN
              )}
            </li>
          </ul>
        </li>
        <li className="c-label_innerHalf">
          <ul className="l-flex">
            <li className="c-dataLabel">
              特殊集計しきい値未満のときのインセンティブ率
              {isSpecialIncentiveRateTypeL(specialIncentiveRateType!) && (
                <small className="c-required">(必須)</small>
              )}
            </li>
            <li className="c-dataValue">
              {isSpecialIncentiveRateTypeL(specialIncentiveRateType!) ? (
                <NumberLooseInput
                  name={`salesManagementPlans.${index}.incentiveRateWhenLessThanThresholdPrice`}
                  additionalClassName="u-align_right"
                  validator={validator}
                  step={0.01}
                  unit="%"
                  optionalValidationRule={{ required: true }}
                />
              ) : (
                HYPHEN
              )}
            </li>
          </ul>
        </li>
      </ul>
    </>
  )
);

const IncentivePaymentTypeCost: React.FC<{
  index: number;
  formItems: TSalesManagementPlanFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(
  ({ index, formItems: { incentivePaymentTypeForInput }, validator }) => (
    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
      <li className="c-label_innerHalf">
        <ul className="l-flex">
          <li className="c-dataLabel">
            <label>インセンティブ種類</label>{' '}
            <small className="c-required">(必須)</small>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name={`salesManagementPlans.${index}.incentivePaymentType`}
              choices={incentivePaymentTypeForInput}
              validator={validator}
              optionalValidationRule={{ required: true }}
            />
          </li>
        </ul>
      </li>
      <li className="c-label_innerHalf">
        <ul className="l-flex">
          <li className="c-dataLabel">
            <label>手数料率</label> <small className="c-required">(必須)</small>
          </li>
          <li className="c-dataValue">
            <NumberLooseInput
              name={`salesManagementPlans.${index}.wholesaleCommissionRate`}
              additionalClassName="u-align_right"
              validator={validator}
              step={0.01}
              unit="%"
              optionalValidationRule={{ required: true }}
            />
          </li>
        </ul>
      </li>
    </ul>
  )
);

const Form: React.FC<{
  index: number;
  formItems: TSalesManagementPlanFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(({ index, formItems, validator }) => {
  const { watch } = useFormContext<TFormInputs>();
  const incentivePaymentType = watch(
    `salesManagementPlans.${index}.incentivePaymentType`
  );
  const specialIncentiveRateType = watch(
    `salesManagementPlans.${index}.specialIncentiveRateType`
  );

  return (
    <ul className="l-flex_between c-label_line is-sp_input">
      <li className="c-dataLabel">
        販売情報
        <span className="l-relative">
          <span className="c-tooltip u-mgl_xs">
            <i className="c-icon_help"></i>
          </span>
          <span className="c-tooltip_body p-groupsSummary_tooltip_help">
            先生撮影団体の場合のみ表示されます
          </span>
        </span>
      </li>
      <li className="c-dataValue">
        <div className="t-bgBox_gray">
          {isIncentivePaymentTypeSales(incentivePaymentType) ? (
            <IncentivePaymentTypeSales
              index={index}
              specialIncentiveRateType={specialIncentiveRateType}
              formItems={formItems}
              validator={validator}
            />
          ) : (
            <IncentivePaymentTypeCost
              index={index}
              formItems={formItems}
              validator={validator}
            />
          )}
        </div>
      </li>
    </ul>
  );
});

const SaveFormSalesManagementPlan: React.FC<{
  societyContractTypeId: number;
  index: number;
  formItems: TSalesManagementPlanFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
}> = React.memo(({ societyContractTypeId, ...props }) =>
  isContractTypeTeacher(societyContractTypeId) ? <Form {...props} /> : null
);

export default SaveFormSalesManagementPlan;
