import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import {
  Checkboxes,
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  ID_UNCONFIRMED,
  LIGHTING_TYPE_ID_OTHER,
  PARENT_SEAT_TYPE_ID_OTHER,
} from '../../const';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type RouteParams = {
  eventId: string;
};

export const Recital: React.FC<{
  validator: TValidator;
  constants: TConstants;
  photography: TPhotography;
}> = ({ validator, constants, photography }) => {
  const { eventId } = useParams<RouteParams>();
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasVenueEnvironment' && type === 'change') {
        if (value.hasVenueEnvironment !== 1) {
          setValue('hasParentSeats', ID_UNCONFIRMED);
          setValue('parentSeatTypeIds', []);
          setValue('parentSeatOtherDescription', '');
          setValue('lightingTypeId', ID_UNCONFIRMED);
          setValue('lightingOtherDescription', '');
          setValue('venueSize', '');
        }
      }
      if (name === 'hasParentSeats' && type === 'change') {
        if (value.hasParentSeats !== 1) {
          setValue('parentSeatTypeIds', []);
          setValue('parentSeatOtherDescription', '');
        }
      }
      if (name === 'parentSeatTypeIds' && type === 'change') {
        if (!value.parentSeatTypeIds?.includes(PARENT_SEAT_TYPE_ID_OTHER)) {
          setValue('parentSeatOtherDescription', '');
        }
      }
      if (name === 'lightingTypeId' && type === 'change') {
        if (value.lightingTypeId !== LIGHTING_TYPE_ID_OTHER) {
          setValue('lightingOtherDescription', '');
        }
      }
      if (name === 'videographerTypeId' && type === 'change') {
        if (value.videographerTypeId === ID_UNCONFIRMED) {
          setValue('videoRemark', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <PhotographyAccordion
      title={`${photography.eventTypeName}情報`}
      id={LIST_ITEMS.event.recital.id}
    >
      <div className="t-textColor_sub">会場詳細</div>
      <RadioBoxes
        name="hasVenueEnvironment"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasVenueEnvironment') === 1 && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">保護者客席</div>
          <RadioBoxes
            name="hasParentSeats"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              { key: 1, value: 'あり' },
              { key: 0, value: 'なし' },
            ]}
          />
          {watch('hasParentSeats') === 1 && (
            <>
              <div className="t-textColor_sub">客席詳細</div>
              <Checkboxes
                name="parentSeatTypeIds"
                validator={validator}
                choices={[...constants.parentSeatTypes]}
              />
              {watch('parentSeatTypeIds')?.includes(
                PARENT_SEAT_TYPE_ID_OTHER
              ) && (
                <>
                  <div className="t-textColor_sub">その他詳細</div>
                  <TextInput
                    name="parentSeatOtherDescription"
                    validator={validator}
                  />
                </>
              )}
            </>
          )}

          <hr className="u-line_plane" />
          <div className="t-textColor_sub">上映中の照明</div>
          <RadioBoxes
            name="lightingTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              ...constants.lightingTypes,
            ]}
          />
          {watch('lightingTypeId') === LIGHTING_TYPE_ID_OTHER && (
            <>
              <div className="t-textColor_sub">その他詳細</div>
              <TextInput
                name="lightingOtherDescription"
                validator={validator}
              />
            </>
          )}
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">広さ詳細</div>
          <TextArea
            name="venueSize"
            validator={validator}
            placeholder="会場の広さ詳細を入力してください"
          />
        </>
      )}

      <hr className="u-line_plane" />
      <div className="t-textColor_sub">動画撮影</div>
      <div>
        {photography.hasVideo === true
          ? 'あり'
          : photography.hasVideo === false
          ? 'なし'
          : '未確認'}
      </div>
      <div className="t-textColor_sub u-fz_s">
        ※申請サマリの登録情報を表示しています。
      </div>
      <Alert type="error" title="発表会の動画撮影について">
        <p>申請サマリの項目のため申請サマリ編集から対応をお願いいたします</p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      {photography.hasVideo === true && (
        <>
          <div className="t-textColor_sub">撮影者</div>
          <RadioBoxes
            name="videographerTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              ...constants.videographerTypes,
            ]}
          />
          {watch('videographerTypeId') !== ID_UNCONFIRMED && (
            <>
              <div className="t-textColor_sub">詳細</div>
              <TextArea
                name="videoRemark"
                validator={validator}
                placeholder="動画撮影の詳細を入力してください"
              />
            </>
          )}
        </>
      )}
    </PhotographyAccordion>
  );
};
