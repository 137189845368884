// プラン：両方
const PLAN_BOTH = 'SP';
// 稼働：稼働
const STATUS_ACTIVE = 0;
// 写真補正：補正なし
const PRINTING_COMPANY_PHOTO_CORRECTION_TYPE_DISABLED = 1;
// 写真補正：カメラマン依存
const PRINTING_COMPANY_PHOTO_CORRECTION_TYPE_CAMERAMAN = 2;
// FAX注文：不可
const IS_ENABLED_SELL_BY_FAX_DISABLED = 0;

export {
  PLAN_BOTH,
  STATUS_ACTIVE,
  PRINTING_COMPANY_PHOTO_CORRECTION_TYPE_DISABLED,
  PRINTING_COMPANY_PHOTO_CORRECTION_TYPE_CAMERAMAN,
  IS_ENABLED_SELL_BY_FAX_DISABLED,
};
