import React from 'react';
import { Link } from 'react-router-dom';
import { Owneraccount } from './types';
import dayjs from 'dayjs';

const AUTH_SALES_AVAILABLE = '1';
const AUTH_ADMIN_AVAILABLE = '1';
const AUTH_TEHAI_AVAILABLE = '1';
const AUTH_REPORTALL_AVAILABLE = '1';
const AUTH_USERPOLICY_AVAILABLE = '1';
const AUTH_PARTNERSUPPORT_AVAILABLE = '1';
const AUTH_OPERATION_AVAILABLE = '1';
const AUTH_CORPORATEPLANNING_AVAILABLE = '1';
const EIGYOFLAG_ON = '1';
const AREATYPE_JAPAN = '1';
const AUTH_PHOTOGRAPHER_PAYMENTS_CLOSE = true;

const AdministratorList: React.FC<{ owneraccounts: Owneraccount[] }> = (
  props
) => {
  return (
    <ul className="c-indexList p-groupsList">
      {props.owneraccounts.map((owneraccount: Owneraccount) => (
        <li className="c-indexListItem" key={owneraccount.ownerno}>
          <div className="l-flex is-sp_input">
            <div className="u-mgr_s">
              <ul className="a-administrator-labelList">
                <li>
                  <div className="c-idLink_hasBg p-groups_id a-administrator-icon">
                    <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                    管理者ID
                  </div>
                  {owneraccount.ownerno}
                </li>
                <li>
                  <div className="c-idLink_hasBg p-groups_id a-administrator-icon">
                    <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                    登録日
                  </div>
                  {dayjs(owneraccount.createdday).format('YYYY/MM/DD')}
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_sales === AUTH_SALES_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    売上
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_admin === AUTH_ADMIN_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    管理
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_tehai === AUTH_TEHAI_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    手配
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_reportall === AUTH_REPORTALL_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    レポート
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_userprivacy ===
                      AUTH_USERPOLICY_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    個人情報
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_partnersupport ===
                      AUTH_PARTNERSUPPORT_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    サポート
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_operation === AUTH_OPERATION_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    業務
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_corporateplanning ===
                      AUTH_CORPORATEPLANNING_AVAILABLE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    経企
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.eigyoflag === EIGYOFLAG_ON
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    営業
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.auth_photographer_payments_close ===
                      AUTH_PHOTOGRAPHER_PAYMENTS_CLOSE
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    支払締め
                  </span>
                </li>
                <li>
                  <span
                    className={`c-statusLabel c-statusLabel__${
                      owneraccount.areatype === AREATYPE_JAPAN
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    日本
                  </span>
                </li>
              </ul>
            </div>
            <br />
            <div className="p-groupsList_detailBox">
              <div className="l-flex_between p-groupsList_upper">
                <div className="p-groupsList_names">
                  <ul className="a-administrator-infoList">
                    <li>
                      <h4 className="c-textOmit">{owneraccount.ownername}</h4>
                    </li>
                    <li>
                      <i className="c-icon_tel u-mgr_xs"></i>
                      {owneraccount.telephone === null ? (
                        <span className="c-nullMessage_text">未登録</span>
                      ) : (
                        <span>{owneraccount.telephone}</span>
                      )}
                    </li>
                    <li>
                      <i className="c-icon_mail u-mgr_xs"></i>
                      {owneraccount.mailaddress === null ? (
                        <span className="c-nullMessage_text">未登録</span>
                      ) : (
                        <span>{owneraccount.mailaddress}</span>
                      )}
                    </li>
                  </ul>
                </div>
                <Link
                  className={`c-btn_rectangle c-btn_edit ${
                    owneraccount.canEdit ? '' : 'is-disabled'
                  }`}
                  to={'/administrator/' + owneraccount.ownerno + '/edit'}
                >
                  <i className="c-icon c-icon__xsmall c-icon_edit" />
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
  // );
};

export default AdministratorList;
