import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { Data, TDeleteResponse } from './types';
import { CorporationSociety, Society } from '../Societies/Edit/types';
import './corporateInfo.scss';
import { dateFormat } from '../../../../ts/formatTools';
import ErrorMessages from '../../../shared/ErrorMessages/App';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: corpData, error: corpError } = useJsonApi<Data>(
    '/api/corporations/' + id
  );

  const { data: corporationSociety, error: societyError } =
    useJsonApi<CorporationSociety>('/api/corporations/' + id + '/societies');

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  const emptyValidator = {
    messages: {},
    hasError: false,
    rules: {},
  };
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);

  const onSubmitDelete = async (corporationId: number) => {
    if (window.confirm('法人を削除します。よろしいですか？')) {
      try {
        const response = (await fetcher('/api/corporations/' + corporationId, {
          method: 'DELETE',
        })) as TDeleteResponse;
        if (response.validator.hasError) {
          setValidator(response.validator);
          alertApiError();
          return;
        }
        setErrorMessages([]);
        successToast('削除しました');
        history.push(`/corporations`);
      } catch (error) {
        errorToast('エラーが発生しました');
        setErrorMessages(toMessages(error));
      }
    }
  };

  if (corpError) {
    return renderError(corpError);
  }
  if (societyError) {
    return renderError(societyError);
  }
  if (!corpData) {
    return <SvgLoading />;
  }
  if (!corporationSociety) {
    return <SvgLoading />;
  }
  const corporation = corpData.data.corporation;
  const organization = corpData.data.organization;
  return (
    <div className="App">
      <div className="c-page_back">
        <Link to="/corporations">法人一覧に戻る</Link>
      </div>
      <h1>法人サマリ</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <ErrorMessages messages={errorMessages} />
            <ApiErrors {...validator} />
            <div className="l-flex_between_center p-groupsSummary_Label">
              <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                法人情報ID: {corporation.id}
              </span>
              <div className="l-flex">
                <div className="u-mgr_s">
                  <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                    登録日
                  </span>
                  <span className="u-fz_xs">
                    {dateFormat(corporation.createdday)}
                  </span>
                </div>
                <div>
                  <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                    更新日
                  </span>
                  <span className="u-fz_xs">
                    {dateFormat(corporation.updatedday)}
                  </span>
                  <span className="u-fz_xs">
                    （{corporation.updatedUserno}：{corporation.updatedUserName}
                    ）
                  </span>
                </div>
              </div>
            </div>
            <hr className="u-line_plane" />
            <div className="l-flex_between_center">
              <div>
                <div className="l-flex_align_base">
                  <h4 className="c-section_title u-fz_l">{corporation.name}</h4>
                </div>
                <span className="u-fz_xs">{corporation.namekana}</span>
              </div>
              <div className="l-flex_end">
                <button
                  className="c-btn_rectangle c-btn_delete u-mgr_s"
                  onClick={() => onSubmitDelete(corporation.id)}
                >
                  <i className="c-icon_Xsmall c-icon_trash" />
                </button>
                <Link
                  className="c-btn_rectangle c-btn_edit u-mgr_s"
                  to={`/corporations/edit/${id}`}
                >
                  <i className="c-icon_Xsmall c-icon_edit" />
                </Link>
              </div>
            </div>
            <div className="u-mgt_s u-mgb_m">
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">法人番号</li>
                    <li className="c-dataValue">
                      {corporation.corporateNumber}
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">代表者名</li>
                    <li className="c-dataValue">
                      {corporation.representativeName} (
                      {corporation.representativeNamekana})
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">住所</li>
                    <li className="c-dataValue">
                      <div className="u-pre_wrap">
                        〒{organization.postcode} {organization.prefectureName}
                        {organization.city}
                        {organization.address}
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      法人代表者
                      <br />
                      電話番号
                    </li>
                    <li className="c-dataValue">
                      <span>{organization.telephonenumber}</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">FAX</li>
                    <li className="c-dataValue">
                      <span>{organization.faxnumber}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="c-frame">
        <div className="c-grid_wrap u-mgt_s">
          <div className="l-flex_between_center u-mgb_s">
            <h4 className="c-section_title u-fz_l">紐付け済み団体一覧</h4>
            <div className="l-flex_end">
              <Link
                className="c-btn_rectangle c-btn_edit"
                to={`/corporations/${id}/societies/edit`}
              >
                <i className="c-icon_Xsmall c-icon_edit" />
              </Link>
            </div>
          </div>
        </div>
        <ul className="c-indexList p-groupsList">
          {corporationSociety?.societies.map((society: Society) => (
            <li className="c-indexListItem l-clearfix" key={society.society.id}>
              <div className="u-mgr_s l-flex_between_center">
                <div>
                  <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                    <i className="c-icon_groups u-mgr_xs"></i>
                    <span>{society.society.id}</span>
                  </div>
                  <Link
                    className="c-textlink"
                    to={`/societies/${society.society.id}`}
                  >
                    <h4 className="c-textOmit">{society.society.name}</h4>
                  </Link>
                </div>
                <div>
                  <ul className="l-flex p-CorporationList_info">
                    <li>
                      <i className="c-icon_map u-mgr_xs" />
                      <span>
                        {society.organization.prefectureName}{' '}
                        {society.organization.city}{' '}
                        {society.organization.address}
                      </span>
                    </li>
                    <li>
                      <i className="c-icon_tel u-mgr_xs" />
                      <span>{society.organization.telephonenumber}</span>
                    </li>
                    <li>
                      <i className="c-icon_person u-mgr_xs" />
                      <span>{society.society.salesOwnerName}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default App;
