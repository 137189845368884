import React from 'react';
import { isContractTypeTeacher } from '../../../../ts/societyContractConstants';

import { TSalesManagement } from '../../types';

const Show: React.FC<{
  salesManagement: TSalesManagement;
}> = React.memo(({ salesManagement }) => (
  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
    <li className="l-col_24">
      <ul className="l-flex">
        <li className="c-dataLabel">
          販売情報
          <span className="l-relative">
            <span className="c-tooltip u-mgl_xs">
              <i className="c-icon_help"></i>
            </span>
            <span className="c-tooltip_body p-groupsSummary_tooltip_help">
              先生撮影団体の場合のみ表示されます
            </span>
          </span>
        </li>
        <li className="c-dataValue">
          <div className="t-bgBox_gray">
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">FAX販売</li>
                  <li className="c-dataValue">
                    {salesManagement.isEnabledSellByFaxName}
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">データ小の販売</li>
                  <li className="c-dataValue">
                    {salesManagement.isEnabledSellDataSName}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">写真の自動補正</li>
                  <li className="c-dataValue">
                    {salesManagement.printingCompanyPhotoCorrectionTypeName}
                  </li>
                </ul>
              </li>
              {/* NOTE: アルバム購入は固定値なので画面から除外 */}
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">選定代行サービス</li>
                  <li className="c-dataValue">
                    {salesManagement.isEnabledPhotoSelectName}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_24">
                <ul className="l-flex">
                  <li className="c-dataLabel">備考</li>
                  <li className="c-dataValue u-pre_wrap">
                    {salesManagement.memo}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
  </ul>
));

const SalesManagement: React.FC<{
  societyContractTypeId: number;
  salesManagement: TSalesManagement | null;
}> = React.memo(({ societyContractTypeId, salesManagement }) =>
  isContractTypeTeacher(societyContractTypeId) ? (
    <Show salesManagement={salesManagement as TSalesManagement} />
  ) : null
);

export default SalesManagement;
