import React, { ChangeEventHandler, useCallback, useState } from 'react';

import BaseModal from '@/components/shared/BaseModal';
import { patchJson } from '../../../../ts/useApi';
import { TUpdateResponse } from './types';
import {
  successToast,
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';

const UpdateModal: React.FC<{
  id: number;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}> = React.memo(({ id, name: orgName, setName, closeModal }) => {
  const [inputName, setInputName] = useState(orgName);
  const header = <>キー名の編集</>;
  const onChangeName: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInputName(e.target.value);

  const update = useCallback(async () => {
    try {
      const { data, validator } = await patchJson<TUpdateResponse>(
        `/api/certificationkeys/${id}`,
        {
          name: inputName,
        }
      );
      if (data === 'NG') {
        errorToastWithValidatorMessages(
          'キー名の更新に失敗しました',
          validator.messages
        );
      } else {
        successToast('キー名を更新しました');
        setName(inputName);
        closeModal();
      }
    } catch (error) {
      errorToast('エラーが発生しました');
    }
  }, [id, inputName, setName, closeModal]);

  return (
    <BaseModal handleClose={closeModal} spHeader={header}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">{header}</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m">
          <input
            type="text"
            name="name"
            value={inputName}
            placeholder="キー名"
            className="c-input_plane"
            maxLength={50}
            onChange={onChangeName}
          />
        </div>
      </div>
      <hr className="u-line_plane" />
      <p className="u-align_center">
        <button
          type="button"
          className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
          onClick={closeModal}
        >
          キャンセル
        </button>
        <button
          type="button"
          className="c-btn_large c-btn_primary c-input_submit"
          onClick={update}
        >
          設定する
        </button>
      </p>
    </BaseModal>
  );
});

const Update: React.FC<{
  id: number;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}> = React.memo((props) => {
  const [showModal, setShowModal] = useState(false);
  const onClick = useCallback(() => setShowModal(true), [setShowModal]);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <button
        className="c-btn_small c-btn_rectangle c-btn_edit"
        onClick={onClick}
      >
        <i className="c-icon c-icon__xxsmall c-icon_edit" />
      </button>
      {showModal && <UpdateModal {...props} closeModal={closeModal} />}
    </>
  );
});

const Name: React.FC<{
  id: number;
  name: string;
  canEdit: boolean;
}> = React.memo(({ canEdit, id, name: orgName }) => {
  const [name, setName] = useState<string>(orgName);

  return (
    <>
      <span className="u-mgr_xs">{name}</span>
      {canEdit ? <Update id={id} name={name} setName={setName} /> : null}
    </>
  );
});

export default Name;
