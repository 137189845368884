import React, {
  useState,
  useRef,
  ChangeEventHandler,
  MouseEventHandler,
} from 'react';
import {
  successToast,
  errorToastWithValidatorMessages,
  errorToast,
} from '../../../../ts/toast';
import { fetcher } from '../../../../ts/useApi';
import { getApiPath } from '../getApiPath';
import { TTarget } from '../types';

import { TPostResponse } from './types';

const PdfFileUpload: React.FC<{
  target: TTarget;
  incentivePaymentId: number;
  isPdfSaved: number;
  pdfFilename: string;
  pdfUrl: string;
}> = React.memo(
  ({ target, incentivePaymentId, isPdfSaved, pdfFilename, pdfUrl }) => {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isShowFileInput, setIsShowFileInput] = useState(!isPdfSaved);
    const fileInput = useRef<HTMLInputElement>(null);

    const showFileInput = () => setIsShowFileInput(true);
    const showFileSelector = () =>
      fileInput.current && fileInput.current.click();
    const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!e.target.files || !e.target.files[0]) {
        setSelectedFile(undefined);
      }
      setSelectedFile(e.target.files![0]);
    };
    const uploadFile: MouseEventHandler = async () => {
      if (!selectedFile) {
        // TODO: UI
        window.alert('アップロードするPDFファイルを選択してください');
        return;
      }
      // TODO: UI
      if (
        !window.confirm('PDFファイルをアップロードします。よろしいですか？')
      ) {
        return;
      }
      try {
        const data = new FormData();
        data.append('pdfFile', selectedFile);
        const res = await fetcher<TPostResponse>(
          getApiPath(target, `${incentivePaymentId}/upload_pdf`),
          {
            method: 'POST',
            body: data,
          }
        );
        if (res.data === 'OK') {
          successToast('PDFファイルをアップロードしました');
          setIsShowFileInput(false);
        } else {
          errorToastWithValidatorMessages(
            'アップロードに失敗しました',
            res.validator.messages
          );
        }
      } catch (e) {
        errorToast('エラーが発生しました');
      }
    };

    return (
      <>
        {isShowFileInput ? (
          <>
            <div className="l-flex_center">
              <div className="c-fileUpload c-incentivePayment_fileUpload">
                {selectedFile
                  ? selectedFile.name
                  : 'アップロードするPDFファイルを指定'}
              </div>
              <div className="c-btn_inputFile">
                <button
                  type="button"
                  className="c-btn_middle c-btn_Search"
                  onClick={showFileSelector}
                >
                  選択
                </button>
                <input
                  className="is-hidden"
                  type="file"
                  ref={fileInput}
                  name="pdf"
                  accept=".pdf,application/pdf"
                  min="1"
                  max="10000"
                  onChange={onFileChange}
                />
              </div>
            </div>
            <div>
              <button
                className={`c-btn_large c-btn_primary btn_primary__isArrow${
                  selectedFile ? '' : ' is-disabled'
                }`}
                type="button"
                onClick={uploadFile}
              >
                PDFをアップロード
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="l-flex_center">
              <i className="c-icon_blank u-mgr_xs"></i>
              <a
                className="c-textlink"
                href={pdfUrl}
                target="_blank"
                rel="noreferrer"
              >
                {pdfFilename}
              </a>
            </div>
            <div className="u-fz_s">
              <button
                className="c-btn_large c-btn_Search"
                onClick={showFileInput}
              >
                PDFをアップロードし直す
              </button>
            </div>
          </>
        )}
      </>
    );
  }
);

export default PdfFileUpload;
