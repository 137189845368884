import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type SpecifiedPositionProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const SpecifiedPosition: React.FC<SpecifiedPositionProps> = ({
  methods,
  validator,
}) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasSpecifiedPosition' && type === 'change') {
        if (value.hasSpecifiedPosition !== 1) {
          setValue('specifiedPositionSenNote', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.specifiedPosition.name}
      id={LIST_ITEMS.photography.specifiedPosition.id}
    >
      <RadioBoxes
        name="hasSpecifiedPosition"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasSpecifiedPosition') === 1 && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="specifiedPositionSenNote"
            validator={validator}
            placeholder="撮影立ち位置指定の詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};
