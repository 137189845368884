export function parallelExec<T>(
  funcs: (() => Promise<T>)[],
  workerNum: number
): Promise<T[]> {
  let currentIndex = 0;
  const results: (T | undefined)[] = [...Array(funcs.length)].fill(undefined);
  const workers = [...Array(workerNum)].map(async () => {
    while (true) {
      if (currentIndex >= funcs.length) {
        return;
      }
      const index = currentIndex;
      currentIndex += 1;
      const func = funcs[index];
      results[index] = await func();
    }
  });
  return Promise.all(workers).then(() => results as T[]);
}
