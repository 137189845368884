import React, { MouseEventHandler, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  renderError,
  toMessages,
  useJsonApi,
  deleteJson,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import Header from '../Header';
import { TDeleteResponse } from './types';
import { TShowResponse } from '../types';
import './bankAccountShow.scss';

const DeleteApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

const App: React.FC = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data, error } = useJsonApi<TShowResponse>(
    '/api/society_contracts/' + contractId + '/bank_accounts'
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const {
    data: { bankAccount, society, societyContract },
  } = data;

  const openDeleteModal: MouseEventHandler = async (e) => {
    e.preventDefault();
    const ok = window.confirm('口座情報を削除してよろしいですか？');
    if (ok) {
      try {
        const deleteResponse = await deleteJson<TDeleteResponse>(
          `/api/bank_accounts/${bankAccount.id}`
        );
        if (!deleteResponse.data) {
          setErrorMessages(toMessages(new Error('削除に失敗しました')));
          return;
        }
        setErrorMessages([]);
        history.push(`/societies/${society.id}`);
      } catch (e) {
        setErrorMessages(toMessages(e));
      }
    }
  };
  return (
    <div className="c-summary">
      <DeleteApiError messages={errorMessages} />
      <Link className="c-page_back" to={'/societies/' + society.id}>
        団体サマリへ戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">口座情報</h1>
      <Header society={society} societyContract={societyContract} />
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <ul className="l-col_wrap l-flex l-col_wrap__noBorder l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">金融機関名</li>
                  <li className="c-dataValue">
                    <div>{bankAccount.bankName}</div>
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">支店名</li>
                  <li className="c-dataValue l-flex_between">
                    <div>{bankAccount.branchName}</div>
                    <Link
                      className="c-btn_rectangle c-btn_edit l-flex_end"
                      to={`/society_contracts/${societyContract.id}/bank_accounts/edit`}
                    >
                      <i className="c-icon c-icon__xsmall c-icon_edit"></i>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">口座種類</li>
                  <li className="c-dataValue">
                    <div>{bankAccount.typeName}</div>
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">口座番号</li>
                  <li className="c-dataValue">
                    <div>{bankAccount.number}</div>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">口座名義</li>
                  <li className="c-dataValue">
                    <div>{bankAccount.holder}</div>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="u-align_center">
              <Link
                className="c-btn_large c-btn_delete u-mgr_m c-input_submit"
                to="/"
                onClick={openDeleteModal}
              >
                削除する
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
