import React, { useContext } from 'react';
import { useJsonApi, renderError } from '../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';

export type AuthInfo = {
  id: number;
  name: string;
  mailaddress: string;
  photographerId: number | null;
  authSales: boolean;
  authOperation: boolean;
  authPhotographerPaymentsClose: boolean;
  authTehai: boolean;
  authAdmin: boolean;
  // 権限系が追加される予定
};

type AuthStatus =
  | {
      authed: false;
    }
  | ({
      authed: true;
    } & AuthInfo);

const AuthContext = React.createContext<AuthInfo | undefined>(undefined);

function initAuth(): void {
  const params = new URLSearchParams({
    // eslint-disable-next-line no-restricted-globals
    redirect_url: location.pathname + location.search,
  });
  // eslint-disable-next-line no-restricted-globals
  location.href = '/auth/?' + params.toString();
}

const AuthProvider: React.FC = (props) => {
  const { data: authStatus, error } = useJsonApi<AuthStatus>('/auth/status');
  if (error) {
    return renderError(error);
  }
  if (!authStatus) {
    return <SvgLoading />;
  }
  if (!authStatus.authed) {
    initAuth();
    return <SvgLoading />;
  }
  return (
    <AuthContext.Provider value={authStatus as AuthInfo}>
      {props.children}
    </AuthContext.Provider>
  );
};

function useAuthInfo(): AuthInfo {
  return useContext(AuthContext)!;
}

export { AuthProvider, useAuthInfo, initAuth };
