import React, { ChangeEventHandler, useMemo, useRef } from 'react';
import {
  CheckBox,
  DateInput,
  RadioBoxes,
  SingleSelect,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TFormInputs, TFormItems } from '../Create/types';
import { TChoice, TValidatorResponse } from '@/components/shared/Form/types';
import { FormRow } from './FormRow';
import { Link } from 'react-router-dom';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { get as getPostalCode } from 'japan-postal-code';

type TFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  defaultValues: Record<string, unknown> | undefined;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  setProfileImage: React.Dispatch<React.SetStateAction<string | null>>;
  submitButton: string;
  cancelLink: string;
};

const IS_PARENT_AUTHORITY = '1';

export const Form: React.FC<TFormProps> = ({
  validator,
  formItems,
  defaultValues,
  onSubmit,
  errorMessages,
  setProfileImage,
  submitButton,
  cancelLink,
}) => {
  const methods = useForm<Required<TFormInputs>>({ defaultValues });
  const fileInput = useRef<HTMLInputElement>(null);
  const showFileSelector = () => fileInput.current && fileInput.current.click();
  const [fileName, setFileName] = React.useState('');
  const [showAuthority, setShowAuthority] = React.useState(true);
  const [isDeleteFaceImage, setIsDeleteFaceImage] = React.useState(false);
  const [fileErrorMessage, setFileErrorMessage] = React.useState('');
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      setProfileImage(null);
      return;
    }
    // dataUrlではなくase64でAPIに送るためファイル形式はここでチェク
    if (file.type !== 'image/jpeg') {
      setFileErrorMessage('JPEG形式以外のファイルは選択できません。');
      return;
    }
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB >= 5) {
      setFileErrorMessage(
        'ファイルサイズが5MBを超えているため選択できません。'
      );
      return;
    }

    setFileName(file.name);
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(',')[1];
      if (!base64) return;
      setProfileImage(base64);
      setIsDeleteFaceImage(false);
      setFileErrorMessage('');
    };
    reader.readAsDataURL(file);
  };

  const deletePhoto = () => {
    setProfileImage(null);
    setIsDeleteFaceImage(true);
    setFileName('');
  };

  const isShanai = methods.watch('isShanai') === '1';

  const emailInputColumns = Array.from({ length: 4 }, () => '');

  const isChild = methods.watch('authority') === '2';

  const isSameNameChecked = methods.watch('familyRegisterNameCopy');
  const seiWatch = methods.watch('sei');
  const meiWatch = methods.watch('mei');
  const computedFullName = seiWatch + ' ' + meiWatch;
  React.useEffect(() => {
    if (isSameNameChecked) {
      methods.setValue('familyRegisterName', computedFullName);
    }
  }, [methods, isSameNameChecked, computedFullName]);

  const watchPhotographerOrganizationId = methods.watch(
    'photographerOrganizationId'
  );
  const registeredOrganizationId = defaultValues?.photographerOrganizationId;
  React.useEffect(() => {
    const isParentCameramanAlreadyRegistered =
      formItems.organizationIdsWithParentPhotographer.includes(
        Number(watchPhotographerOrganizationId)
      );
    const selectedDifferentOrganization =
      registeredOrganizationId !== watchPhotographerOrganizationId;
    const notParent = defaultValues?.authority !== IS_PARENT_AUTHORITY;
    if (
      isParentCameramanAlreadyRegistered &&
      (selectedDifferentOrganization || notParent)
    ) {
      methods.setValue('authority', '2');
      setShowAuthority(false);
      return;
    }
    setShowAuthority(true);
  }, [
    methods,
    watchPhotographerOrganizationId,
    formItems.organizationIdsWithParentPhotographer,
    defaultValues?.authority,
    registeredOrganizationId,
  ]);

  const submitBefore = (data: TFormInputs) => {
    data.isDeleteImage = isDeleteFaceImage;
    onSubmit(data);
  };

  const setAddressByPostcode = () => {
    const postcode = String(methods.getValues('postCode'));
    getPostalCode(postcode, (address) => {
      formItems.prefectures.forEach((prefecture) => {
        if (prefecture.value === address.prefecture) {
          methods.setValue('prefecturesMasterNo', Number(prefecture.key));
        }
      });
      methods.setValue('city', address.city);
      methods.setValue('address', address.area);
    });
  };

  const agreementTypeChoices = useMemo<TChoice[]>(
    () => [
      { key: '0', value: '' },
      ...formItems.agreementDocuments.map((value) => ({
        key: value.agreementType,
        value: value.fileName,
      })),
    ],
    [formItems.agreementDocuments]
  );

  return (
    <FormProvider {...methods}>
      <SaveApiError messages={errorMessages} />
      <ApiValidationErrors {...validator} />
      <form
        method="POST"
        onSubmit={methods.handleSubmit(submitBefore)}
        autoComplete="off"
      >
        <div className="c-frame">
          <div className="u-pdb_s u-mgb_m">
            <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
              <h2 id="p-photographers_basic-info">基本情報</h2>
            </div>
            <table className="p-photographers_photographerTable">
              <tbody>
                <FormRow title="カメラマン組織" isRequired={true}>
                  <SingleSelect
                    name="photographerOrganizationId"
                    validator={validator}
                    choices={formItems.organizations}
                    placeholder={'カメラマン組織を選択してください'}
                  />
                  <p>
                    カメラマン組織(法人・屋号・代表者)の登録がまだの場合
                    <Link
                      to={'/photographer_organizations/create'}
                      className="c-textlink"
                    >
                      こちら
                    </Link>
                    から登録を先に行なってください。
                  </p>
                </FormRow>
                <FormRow title="権限" isRequired={true}>
                  {showAuthority ? (
                    <RadioBoxes
                      name="authority"
                      choices={formItems.authorities}
                      validator={validator}
                    />
                  ) : (
                    <p>子カメラマン</p>
                  )}
                  <p>
                    一つのカメラマン組織に対して、親カメラマンは1アカウントのみです。
                  </p>
                </FormRow>
                <FormRow title="氏名" isRequired={true}>
                  <div className={'l-flex'}>
                    <div className="l-col_6 u-mgr_s">
                      <TextInput name={'sei'} validator={validator} />
                    </div>
                    <div className="l-col_6">
                      <TextInput name={'mei'} validator={validator} />
                    </div>
                  </div>
                </FormRow>
                <FormRow title="フリガナ" isRequired={true}>
                  <div className={'l-flex'}>
                    <div className="l-col_6 u-mgr_s">
                      <TextInput name={'seiKana'} validator={validator} />
                    </div>
                    <div className="l-col_6">
                      <TextInput name={'meiKana'} validator={validator} />
                    </div>
                  </div>
                </FormRow>
                <FormRow
                  title="短縮名"
                  helpText="手配表で使用"
                  isRequired={true}
                >
                  <TextInput name={'shortName'} validator={validator} />
                </FormRow>
                <FormRow title={'戸籍名'} isHidden={isChild}>
                  <CheckBox
                    name={'familyRegisterNameCopy'}
                    validator={validator}
                    label={'氏名と同一'}
                  />
                  {isSameNameChecked ? (
                    <></>
                  ) : (
                    <TextInput
                      name={'familyRegisterName'}
                      validator={validator}
                    />
                  )}
                </FormRow>
                <FormRow
                  title={'顔写真'}
                  helpText="アップロードできるファイルは image/jpeg 形式、5120 KB まで"
                >
                  <div className="l-flex_center p-photographers_file">
                    <div className="c-btn_inputFile">
                      <div
                        className={'p-photographers_fileName'}
                        onClick={showFileSelector}
                      >
                        <p>ファイル選択</p>
                      </div>
                      <input
                        type={'file'}
                        name={'profileImage'}
                        ref={fileInput}
                        onChange={handleChange}
                      />
                      <p>{fileName}</p>
                    </div>
                    {defaultValues?.photographerImageUrl &&
                    !isDeleteFaceImage ? (
                      <img
                        src={String(defaultValues?.photographerImageUrl)}
                        alt=""
                        className="u-mgr_s"
                      />
                    ) : (
                      <div className="p-photographersShow_facePhoto p-photographersShow_facePhotoNothing u-mgl_s">
                        写真がありません
                      </div>
                    )}
                  </div>
                  <button
                    onClick={deletePhoto}
                    className="c-btn_small c-btn_rectangle c-btn_delete u-mgt_s"
                    type="button"
                  >
                    画像を削除
                  </button>
                  {fileErrorMessage}
                </FormRow>
                <FormRow title="性別" isRequired={true}>
                  <RadioBoxes
                    name="gender"
                    choices={formItems.genders}
                    validator={validator}
                  />
                </FormRow>
                <FormRow title="生年月日" isRequired={true}>
                  <div className="l-col_6">
                    <DateInput
                      name="birthdate"
                      placeholder="生年月日"
                      validator={validator}
                    />
                  </div>
                </FormRow>
                <FormRow title={'郵便番号'}>
                  <div className="l-flex">
                    <div className="l-col_6">
                      <TextInput name={'postCode'} validator={validator} />
                    </div>
                    <div className="u-mgl_s c-label_innerHalf__isPostcode">
                      <input
                        className="c-btn_large c-btn_Search"
                        value="検索"
                        type="button"
                        onClick={setAddressByPostcode}
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow title={'都道府県'} isRequired={!isChild}>
                  <SingleSelect
                    name="prefecturesMasterNo"
                    validator={validator}
                    choices={formItems.prefectures}
                    placeholder={'都道府県'}
                  />
                </FormRow>
                <FormRow title={'市区町村'}>
                  <TextInput name={'city'} validator={validator} />
                </FormRow>
                <FormRow title={'それ以下住所'}>
                  <TextInput name={'address'} validator={validator} />
                </FormRow>

                <FormRow title={'緯度/経度'} helpText="自動アサイン処理で使用">
                  <div className={'l-flex'}>
                    <div className={'l-col_6 u-mgr_s'}>
                      <TextInput name={'lat'} validator={validator} />
                    </div>
                    <div className={'l-col_6'}>
                      <TextInput name={'lng'} validator={validator} />
                    </div>
                  </div>
                </FormRow>
                <FormRow
                  title={'最寄駅'}
                  helpText="入力しておくと、ジョルダン検索にリンクしたい"
                >
                  <TextInput name={'station'} validator={validator} />
                </FormRow>
                <FormRow title="メールアドレス">
                  {emailInputColumns.map((emailInput, idx) => (
                    <div className="u-mgb_s" key={idx}>
                      <TextInput
                        name={`mailAddresses[${idx}]`}
                        validator={validator}
                        placeholder={'メールアドレス'}
                      />
                    </div>
                  ))}
                  {validator.messages.mailAddresses ? (
                    Object.values(validator.messages.mailAddresses).map(
                      (message, idx) => (
                        <p key={idx} className="c-inputError_item">
                          {message}
                        </p>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </FormRow>
                <FormRow
                  title="電話番号"
                  isRequired={true}
                  helpText="例: 03-1234-1234"
                >
                  <TextInput
                    name={'telephone'}
                    validator={validator}
                    placeholder="03-1234-1234"
                  />
                </FormRow>
                <FormRow
                  title="緊急連絡先"
                  isRequired={true}
                  isHidden={isChild}
                >
                  <ul className="l-flex l-col_12">
                    <li className="c-dataLabel">姓</li>
                    <li className="c-dataValue">
                      <TextInput
                        name={'emergencyFirstName'}
                        validator={validator}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex l-col_12">
                    <li className="c-dataLabel">名</li>
                    <li className="c-dataValue">
                      <TextInput
                        name={'emergencyLastName'}
                        validator={validator}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex l-col_12">
                    <li className="c-dataLabel">電話番号</li>
                    <li className="c-dataValue">
                      <TextInput
                        name={'emergencyTelephone'}
                        validator={validator}
                        placeholder="03-1234-1234"
                      />
                    </li>
                  </ul>
                  <ul className="l-flex l-col_12">
                    <li className="c-dataLabel">続柄</li>
                    <li className="c-dataValue">
                      <TextInput
                        name={'emergencyRelationship'}
                        validator={validator}
                      />
                      <p>家族（3親等以内）または職場</p>
                    </li>
                  </ul>
                </FormRow>
              </tbody>
            </table>
          </div>
        </div>

        <div className="c-frame">
          <div className="u-pdb_s u-mgb_m">
            <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
              <h2 id="p-photographers_detail-info">業務情報</h2>
            </div>
            <table className="p-photographers_photographerTable">
              <tbody>
                <FormRow title="稼動状況" isRequired={true}>
                  <RadioBoxes
                    name="status"
                    choices={formItems.statuses}
                    validator={validator}
                  />
                </FormRow>
                <FormRow title={'社内外'} isRequired={true} isHidden={isChild}>
                  <RadioBoxes
                    name="isShanai"
                    choices={formItems.employeeType}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="契約タイプ"
                  isRequired={true}
                  isHidden={isChild || isShanai}
                >
                  <SingleSelect
                    name="agreementType"
                    validator={validator}
                    choices={agreementTypeChoices}
                    placeholder={'契約タイプ'}
                  />
                  <p>
                    約款の確認をお願いします。
                    {formItems.agreementDocuments.map(
                      (agreementDocument, idx) => (
                        <a
                          key={idx}
                          href={agreementDocument.url}
                          className="u-mgr_s"
                        >
                          {agreementDocument.fileName}
                        </a>
                      )
                    )}
                  </p>
                </FormRow>
                <FormRow title={'経費算出方法'} isHidden={isChild || isShanai}>
                  <SingleSelect
                    name="expenseCalculationType"
                    validator={validator}
                    choices={formItems.expenseCalculations}
                    placeholder={'経費算出方法'}
                  />
                </FormRow>
                <FormRow
                  title={'ランク'}
                  isRequired={true}
                  isHidden={isChild || isShanai}
                >
                  <SingleSelect
                    name="oldRankId"
                    validator={validator}
                    choices={formItems.photographerRanks}
                    placeholder={'ランク'}
                  />
                </FormRow>
                <FormRow
                  title={'繁忙日特典額（税込）'}
                  isHidden={isChild || isShanai}
                >
                  <TextInput name={'thanksPointFee'} validator={validator} />
                </FormRow>
                <FormRow title={'基本金額（税込）'} isHidden={isChild}>
                  <TextInput name={'rewardPrice'} validator={validator} />
                </FormRow>
                <FormRow
                  title="スナップ自動補正"
                  isRequired={true}
                  helpText="印刷会社がスナップ写真をプリントするときに自動補正をするかどうか"
                >
                  <RadioBoxes
                    name="isNoHosei"
                    choices={[
                      { key: '0', value: 'あり' },
                      { key: '1', value: 'なし' },
                    ]}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="リマインダー"
                  isRequired={true}
                  helpText="各種リマインドメールを送信するか"
                >
                  <RadioBoxes
                    name="isSendReminder"
                    choices={[
                      { key: '1', value: '送る' },
                      { key: '0', value: '不要' },
                    ]}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="自動アサイン"
                  isRequired={true}
                  helpText="自動アサイン処理で使用"
                  isHidden={isChild}
                >
                  <RadioBoxes
                    name="isEnabledAutoArrangement"
                    choices={[
                      { key: '1', value: '可能' },
                      { key: '0', value: '不可' },
                    ]}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="車所持"
                  isRequired={true}
                  helpText="車を持っているか"
                >
                  <RadioBoxes
                    name="hasCar"
                    choices={[
                      { key: '1', value: 'あり' },
                      { key: '0', value: 'なし' },
                    ]}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="宿泊イベント"
                  isRequired={true}
                  helpText="宿泊イベントの撮影が可能か"
                  isHidden={isChild}
                >
                  <RadioBoxes
                    name="isStayNight"
                    choices={[
                      { key: '1', value: '可' },
                      { key: '0', value: '不可' },
                    ]}
                    validator={validator}
                  />
                </FormRow>
                <FormRow
                  title="撮影可能日"
                  isRequired={true}
                  isHidden={isChild || isShanai}
                >
                  <RadioBoxes
                    name="canWorkingDateType"
                    choices={formItems.workingDateTypes}
                    validator={validator}
                  />
                </FormRow>
                <FormRow title="面談情報" isHidden={isChild}>
                  <TextArea name={'interviewInfo'} validator={validator} />
                </FormRow>
                <FormRow title="メモ">
                  <TextArea name={'memo'} validator={validator} />
                </FormRow>
                <FormRow title="撮影機材">
                  <span id="p-photographers_equipment">
                    <TextArea name={'equipment'} validator={validator} />
                  </span>
                </FormRow>
              </tbody>
            </table>
          </div>
          <div className="u-align_center u-mgb_m">
            <a
              className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
              href={cancelLink}
            >
              キャンセル
            </a>
            <input
              className="c-btn_large c-btn_primary is-arrow c-input_submit"
              type="submit"
              value={submitButton}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);
