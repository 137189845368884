import React, { useState } from 'react';
import { toMessages, fetcher } from '../../../../ts/useApi';
import { TInvitationMailForm, TSaveResponse } from '../types';
import SaveForm from '../InvitationMailInput/SaveForm';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const location =
    useLocation<{ subject: string; body: string; society_id: string }>();
  const { ownerno } = useParams<{ ownerno: string }>();

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  if (!location.state) {
    return <SvgLoading />;
  }

  const onSubmit: SubmitHandler<TInvitationMailForm> = async (formData) => {
    if (!window.confirm('招待メールを送信します。\nよろしいですか？')) {
      return;
    }
    try {
      const sendResponse = (await fetcher(
        '/api/owneraccounts/' + ownerno + '/invitation_mail/send',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (sendResponse.validator.hasError) {
        setValidator(sendResponse.validator);
        alertApiError();
        return;
      }
      successToast('送信しました');
      history.push(`/societies/${location.state.society_id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const defaultValues = {
    subject: location.state.subject,
    body: location.state.body,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">管理者 - 招待メール確認</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          ownerno={ownerno}
          confirmflag={true}
          validator={validator}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="送信する"
          cancelLink={`/societies/${ownerno}`}
        />
      </div>
    </div>
  );
};

export default App;
