import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { GIFT_STYLE_TYPE_OTHER, ID_UNCONFIRMED } from '../../const';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

export const Christmas: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasGift' && type === 'change') {
        if (value.hasGift !== 1) {
          setValue('giftStyleTypeId', ID_UNCONFIRMED);
          setValue('giftStyleOtherDescription', '');
        }
      }
      if (name === 'giftStyleTypeId' && type === 'change') {
        if (value.giftStyleTypeId !== GIFT_STYLE_TYPE_OTHER) {
          setValue('giftStyleOtherDescription', '');
        }
      }
      if (name === 'hasSantaArrival' && type === 'change') {
        if (value.hasSantaArrival !== 1) {
          setValue('isCryFacePermittedForSantaArrival', ID_UNCONFIRMED);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <PhotographyAccordion
        title={`${photography.eventTypeName}情報`}
        id={LIST_ITEMS.event.christmas.id}
      >
        <div className="t-textColor_sub">プレゼント受け渡し</div>
        <RadioBoxes
          name="hasGift"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />

        {watch('hasGift') === 1 && (
          <>
            <hr className="u-line_plane" />

            <div className="t-textColor_sub">受け取り方法</div>
            <RadioBoxes
              name="giftStyleTypeId"
              validator={validator}
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                ...constants.christmasGiftStyleTypes,
              ]}
            />
            {watch('giftStyleTypeId') === GIFT_STYLE_TYPE_OTHER && (
              <>
                <div className="t-textColor_sub">その他詳細</div>
                <TextInput
                  name="giftStyleOtherDescription"
                  validator={validator}
                />
              </>
            )}
          </>
        )}
        <hr className="u-line_plane" />

        <div className="t-textColor_sub">サンタさんの登場</div>
        <RadioBoxes
          name="hasSantaArrival"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />
        {watch('hasSantaArrival') === 1 && (
          <>
            <div className="t-textColor_sub">泣き顔撮影</div>
            <RadioBoxes
              name="isCryFacePermittedForSantaArrival"
              validator={validator}
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                { key: 1, value: '可能' },
                { key: 0, value: '不可' },
              ]}
            />
          </>
        )}
      </PhotographyAccordion>
    </>
  );
};
