import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED, MEDAL_AWARD_STYLE_TYPE_OTHER } from '../../const';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { Video } from '../Video';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router-dom';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';
import { WarningIcon } from '../../Shared/WarningIcon';

export const EntranceCeremony: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasMedalAward' && type === 'change') {
        if (value.hasMedalAward !== 1) {
          setValue('medalAwardStyleTypeId', ID_UNCONFIRMED);
          setValue('medalAwardOtherDescription', '');
        }
      }
      if (name === 'medalAwardStyleTypeId' && type === 'change') {
        if (value.medalAwardStyleTypeId !== MEDAL_AWARD_STYLE_TYPE_OTHER) {
          setValue('medalAwardOtherDescription', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  });
  return (
    <PhotographyAccordion
      title={`${photography.eventTypeName}情報`}
      id={LIST_ITEMS.event.entranceCeremony.id}
    >
      <div className="t-textColor_sub">正装</div>
      <div className="l-flex_center">
        {photography.hasDressCode === null && <WarningIcon />}
        {photography.hasDressCode === true
          ? '必要'
          : photography.hasDressCode === false
          ? '不要'
          : '未定'}
      </div>
      {photography.hasDressCode === null && (
        <div className="u-fz_s t-textColor_danger">
          ※有無の確認を行い、申請サマリから情報を登録してください。
        </div>
      )}
      <div className="u-fz_s t-textColor_sub">
        ※申請サマリの登録情報を表示しています
      </div>
      <Alert type="error" title="正装について">
        <p>
          申請サマリの項目のため、申請サマリ編集から対応をお願いいたします。撮影情報をコピーする際は「服装指定」として下記に置換されます。
          <br />
          ・正装「必要」→スーツ（ネクタイ着用）
          <br />
          ・正装「不要」→ジャケット着用（オフィスカジュアル）
        </p>
        <a
          className="c-btn c-btn_middle_wide c-btn_black u-mgt_s"
          href={ownerUrl(`events/requests/${eventId}`)}
          target="_blank"
          rel="noreferrer"
        >
          申請サマリ編集
        </a>
      </Alert>
      <div className="t-textColor_sub">メダル授与</div>
      <RadioBoxes
        name="hasMedalAward"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasMedalAward') === 1 && (
        <>
          <hr className="u-line_plane" />
          <div className="t-textColor_sub">授与方法</div>
          <RadioBoxes
            name="medalAwardStyleTypeId"
            validator={validator}
            choices={[
              { key: ID_UNCONFIRMED, value: '未確認' },
              ...constants.medalAwardStyleTypes,
            ]}
          />
          {watch('medalAwardStyleTypeId') === MEDAL_AWARD_STYLE_TYPE_OTHER && (
            <>
              <div className="t-textColor_sub">その他詳細</div>
              <TextInput
                name="medalAwardOtherDescription"
                validator={validator}
              />
            </>
          )}
        </>
      )}
      <hr className="u-line_plane" />
      <Video validator={validator} constants={constants} />
    </PhotographyAccordion>
  );
};
