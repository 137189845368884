import React, { useState, useRef } from 'react';
import { TextInput } from '@/components/shared/Form/Inputs';
import '../style.scss';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { errorToast, successToast } from '../../../../../ts/toast';
import { TFormInputs } from '../types';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

type TFormProps = {
  validator: TValidatorResponse;
  register: UseFormRegister<TFormInputs>;
  setValue: UseFormSetValue<TFormInputs>;
  onCheckClick: (clicked: boolean) => void;
  setConfirmedNumber: React.Dispatch<React.SetStateAction<string>>;
};

export const BusinessRegistration: React.FC<TFormProps> = ({
  validator,
  register,
  setValue,
  onCheckClick,
  setConfirmedNumber,
}) => {
  const number = useRef<HTMLInputElement>(null);
  const [businessRegistrationValidator, setBusinessRegistrationValidator] =
    useState<TValidatorResponse>(validator);
  const [checkDate, setCheckDate] = useState<string>();
  const [tradeName, setTradeName] = useState<string>();
  const [registrationDate, setRegistRationDate] = useState<string>();
  const [updateDate, setUpdateDate] = useState<string>();
  const [disposalDate, setDisposalDate] = useState<string>();
  const [expireDate, setExpireDate] = useState<string>();

  const handleClick = async () => {
    try {
      if (!number.current?.value) {
        setCheckDate(undefined);
        setTradeName(undefined);
        setRegistRationDate(undefined);
        setUpdateDate(undefined);
        setDisposalDate(undefined);
        setExpireDate(undefined);
        setValue('business_registration_check_date', undefined);
        setValue('business_registration_trade_name', undefined);
        setValue('business_registration_create_date', undefined);
        setValue('business_registration_update_date', undefined);
        setValue('business_registration_disposal_date', undefined);
        setValue('business_registration_expire_date', undefined);
        setConfirmedNumber('');
        onCheckClick(true);
        return;
      }
      const response = await fetch(
        `/api/photographer_organizations/business_registration_info?business_registration_number=${number.current.value}`,
        { method: 'GET' }
      );

      const responseJson = await response.json();
      const data = responseJson.data;
      setBusinessRegistrationValidator({
        ...responseJson.validator,
        rules: validator.rules,
      });

      setCheckDate(data.checkDate);
      setTradeName(data.tradeName);
      setRegistRationDate(data.registrationDate);
      setUpdateDate(data.updateDate);
      setDisposalDate(data.disposalDate);
      setExpireDate(data.expireDate);
      setValue('business_registration_check_date', data.checkDate);
      setValue('business_registration_trade_name', data.tradeName);
      setValue('business_registration_create_date', data.registrationDate);
      setValue('business_registration_update_date', data.updateDate);
      setValue('business_registration_disposal_date', data.disposalDate);
      setValue('business_registration_expire_date', data.expireDate);
      if (data.tradeName) {
        successToast('データを取得しました');
        setConfirmedNumber(number.current.value);
        onCheckClick(true);
      } else {
        errorToast('この番号は登録されていません');
      }
    } catch (e) {
      errorToast('国税庁APIにてエラーが発生しました');
    }
  };

  return (
    <div className="p-photographerOrganizationCreate_businessResistation_Area">
      <ul className="l-flex_between c-label_line is-sp_input">
        <div className="p-photographerOrganizationCreate_businessResistation_title">
          適格請求書発行事業者登録情報
        </div>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataLabel">
          <label>番号</label>
        </li>
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-label_innerHalf">
              <TextInput
                name="business_registration_number"
                placeholder="T + 13桁の数字"
                validator={businessRegistrationValidator}
                ref={number}
              />
            </li>
            <li className="c-label_innerHalf">
              <button
                className="p-photographerOrganizationCreate_button api"
                onClick={handleClick}
                type="button"
              >
                国税庁サイト確認
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>確認日</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_check_date')}
                    value={checkDate}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>屋号</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_trade_name')}
                    value={tradeName}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>登録日</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_create_date')}
                    value={registrationDate}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>更新日</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_update_date')}
                    value={updateDate}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>取消日</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_disposal_date')}
                    value={disposalDate}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-flex_between c-label_line is-sp_input">
        <li className="c-dataValue">
          <ul className="l-flex_between">
            <li className="c-dataLabel">
              <label>廃止日</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex_between">
                <li className="c-label_innerHalf">
                  <input
                    {...register('business_registration_expire_date')}
                    defaultValue={expireDate}
                    value={expireDate}
                    placeholder="自動で値が入ります"
                    className="p-photographerOrganizationCreate_box auto"
                    readOnly
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
