import { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export const LinkText: FC<{ to: string }> = ({ to, children }) => {
  const location = useLocation();
  const isCurrentPage = to === location.pathname;
  return (
    <div>
      {isCurrentPage ? (
        <span className="t-textColor_sub">{children}</span>
      ) : (
        <RouterLink className="c-textlink" to={to}>
          {children}
        </RouterLink>
      )}
    </div>
  );
};
