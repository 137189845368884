import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { renderError } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { errorToast, successToast } from '@/ts/toast';
import { afterBusinessDay, beforeBusinessDay } from '@/ts/businessDay';
import { useJsonApiForSearchForm } from '@/ts/useJsonApiForSearchForm';
import { putJson } from '@/ts/fetch';
import { toMessages } from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';
import { TPeriodResponse, TPeriodFormDatas, TPeriodFormInputs } from './types';
import styles from './style.module.scss';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import PeriodResultItem from './PeriodResultItem';
import { getFiscalYear } from '@/ts/fiscalYear';
import { PUBLISHPERIODYEAR } from '../constants';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const CERTIFICATION_NOT_CHANGE = 9;

const makeEnabledPublishingPeriods = (
  photographingday: string,
  publishingStartDay: number,
  publishingEndDay: number,
  holidays: Record<string, string>,
  enabledPublishingPeriodsBusinessDaysLater: number
) => {
  const currentDate = dayjs(photographingday);
  const currentMonth = Number(currentDate.format('M'));
  const currentYear = Number(currentDate.format('YYYY'));
  let proposedStartDate = dayjs()
    .date(publishingStartDay)
    .month(currentMonth - 1)
    .year(currentYear);

  while (
    proposedStartDate.isBefore(
      dayjs(
        afterBusinessDay(
          photographingday,
          holidays,
          enabledPublishingPeriodsBusinessDaysLater
        )
      )
    )
  ) {
    proposedStartDate = proposedStartDate.add(1, 'month');
  }

  const startDate = proposedStartDate.format('YYYY-MM-DD');
  const endDate = dayjs(startDate)
    .set('date', Math.min(dayjs(startDate).daysInMonth(), publishingEndDay))
    .add(publishingEndDay <= publishingStartDay ? 1 : 0, 'month')
    .format('YYYY-MM-DD');

  return { valuestartday: startDate, valueendday: endDate };
};

const makeValueEndDay = (day: dayjs.Dayjs, termValue: string) => {
  const dayMatch = termValue.match(/(\d+)d/);
  if (dayMatch) {
    return day.add(Number(dayMatch[1]) - 1, 'day').format('YYYY-MM-DD');
  }
  const monthMatch = termValue.match(/(\d+)m/);
  if (monthMatch) {
    return day.add(Number(monthMatch[1]), 'month').format('YYYY-MM-DD');
  }
  return day.format('YYYY-MM-DD');
};

const makePublishEndDay = (
  photographingday: string,
  societyStartMonthOfFiscalYear: number
) => {
  const fiscalYear = getFiscalYear(
    dayjs(photographingday),
    societyStartMonthOfFiscalYear
  );
  if (societyStartMonthOfFiscalYear === 4) {
    return dayjs('' + (fiscalYear + 1) + '-05-31').format('YYYY-MM-DD');
  } else {
    return dayjs(
      '' + (fiscalYear + 1) + '-' + societyStartMonthOfFiscalYear + '-01'
    )
      .endOf('month')
      .format('YYYY-MM-DD');
  }
};

const rewritePeriodToSimultaneousRelease = (
  sorted: TPeriodFormDatas,
  simultaneousReleaseStartDate: dayjs.Dayjs,
  simultaneousReleaseLastId: string
): TPeriodFormDatas => {
  Object.entries(sorted).forEach(([sortEventId, sortEvent]) => {
    if (
      dayjs(sortEvent.simultaneousStart).isSame(
        simultaneousReleaseStartDate,
        'day'
      )
    ) {
      sorted[sortEventId].simultaneousPhotographingday =
        sorted[simultaneousReleaseLastId].photographingday;
      sorted[sortEventId].valuestartday =
        sorted[simultaneousReleaseLastId].valuestartday;
      sorted[sortEventId].valueendday =
        sorted[simultaneousReleaseLastId].valueendday;
      sorted[sortEventId].publishendday =
        sorted[simultaneousReleaseLastId].publishendday;
      sorted[sortEventId].chirashisenddate =
        sorted[simultaneousReleaseLastId].chirashisenddate;
      sorted[sortEventId].dvdsenddate =
        sorted[simultaneousReleaseLastId].dvdsenddate;
      sorted[sortEventId].sbsenddate =
        sorted[simultaneousReleaseLastId].sbsenddate;
      sorted[sortEventId].faxsenddate =
        sorted[simultaneousReleaseLastId].faxsenddate;
    }
  });

  return sorted;
};

const PeriodResult: React.FC<{
  response: TPeriodResponse;
  handleUpdate: (
    f?: (d: TPeriodResponse | undefined) => TPeriodResponse | undefined
  ) => void;
}> = ({ response, handleUpdate }) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { data: events, validator, conditions, formItems } = response;

  const {
    fromPeriodPhotographingDay,
    toPeriodPhotographingDay,
    valuestartdayBusinessDaysLaterDefault,
    valuestartdayBusinessDaysLaterSpecialEvent,
    valuestartdayBusinessDaysLaterSports,
    simultaneousReleasePeriod,
    enabledPublishingPeriodsBusinessDaysLater,
    limitedEventTypeIds,
  } = formItems.periodSettings;
  let simultaneousReleaseFlag = false;
  let simultaneousReleaseStartDate = dayjs();
  let simultaneousReleaseEndDate = dayjs();
  let simultaneousReleaseLastId = '';
  let enabledPublishingPeriodsFlag = false;

  //const methods = useForm();
  const periodDefaultValues: TPeriodFormInputs = {
    event: {},
  };

  events.forEach((event) => {
    let valuestartday = '';
    let valueendday = '';
    let publishendday = '';
    let valuestartBussinessDays = valuestartdayBusinessDaysLaterDefault;
    let chirashisenddate = '';
    const eventMonth = dayjs(event.photographingday).month() + 1;

    // 特殊：スポーツ系団体の場合
    if (event.societyGroupTypeId === 10) {
      valuestartBussinessDays = valuestartdayBusinessDaysLaterSports;
    }
    // 特殊：10月撮影イベント「運動会」「運動会予行」「ハロウィン」限定
    if (eventMonth === 10 && limitedEventTypeIds.includes(event.eventTypeId)) {
      valuestartBussinessDays = valuestartdayBusinessDaysLaterSpecialEvent;
    }

    // 固定掲載
    if (
      event.societyContract.isEnabledPublishingPeriods === 1 &&
      event.societyContract.publishingStartDay &&
      event.societyContract.publishingEndDay
    ) {
      enabledPublishingPeriodsFlag = true;
      const enabledPublishingPeriods = makeEnabledPublishingPeriods(
        event.photographingday,
        event.societyContract.publishingStartDay,
        event.societyContract.publishingEndDay,
        formItems.holidays,
        enabledPublishingPeriodsBusinessDaysLater
      );
      valuestartday = enabledPublishingPeriods.valuestartday;
      valueendday = enabledPublishingPeriods.valueendday;
      publishendday = event.societyContractPlan.isEnabledOutOfPeriodSales
        ? makePublishEndDay(
            event.photographingday,
            event.societyStartMonthOfFiscalYear
          )
        : enabledPublishingPeriods.valueendday;
      // 通常掲載
    } else {
      valuestartday = afterBusinessDay(
        event.photographingday,
        formItems.holidays,
        valuestartBussinessDays
      );
      valueendday = makeValueEndDay(
        dayjs(valuestartday),
        event.publishterm.publishtermvalue
      );
      publishendday = event.societyContractPlan.isEnabledOutOfPeriodSales
        ? makePublishEndDay(
            event.photographingday,
            event.societyStartMonthOfFiscalYear
          )
        : valueendday;
    }
    // 責任者終了日を設定する
    const fiscalYear = getFiscalYear(dayjs(valuestartday), 4);
    const leaderendday = fiscalYear + PUBLISHPERIODYEAR + '-05-31';

    // チラシ有
    if (
      event.societyContract.isRequiredFlyer &&
      event.societyContract.flyerPreDeliveryType
    ) {
      // 撮影前に送付する日付の計算
      if (event.societyContract.flyerPreDeliveryType === 1) {
        chirashisenddate = beforeBusinessDay(
          event.photographingday,
          formItems.holidays,
          Number(event.societyContract.numberOfBusinessDaysToPreship)
        );
        // 公開前に送付する日付の計算
      } else {
        chirashisenddate = beforeBusinessDay(
          valuestartday,
          formItems.holidays,
          Number(event.societyContract.numberOfBusinessDaysToPreship)
        );
      }
    }

    periodDefaultValues['event'][event.eventId] = {
      check:
        dayjs(event.photographingday).isBetween(
          fromPeriodPhotographingDay,
          toPeriodPhotographingDay
        ) &&
        !event.requestOptions.hasOwnProperty('1') &&
        event.openStatusId !== 3 &&
        event.valueStartDay === null,
      eventname: event.eventName,
      photographingday: event.photographingday,
      valuestartday: valuestartday,
      valueendday: valueendday,
      publishendday: publishendday,
      leaderendday: leaderendday,
      chirashisenddate: chirashisenddate,
      dvdsenddate: event.dvd.required ? valuestartday : '',
      sbsenddate: event.sb.required ? valuestartday : '',
      sbjizensenddate: event.confirmSb.required ? valuestartday : '',
      faxsenddate: event.fax.required ? valuestartday : '',
      simultaneousStart: '',
      simultaneousEnd: '',
      simultaneousPhotographingday: '',
      certificationType:
        event.certificationKey.length > 1
          ? CERTIFICATION_NOT_CHANGE
          : event.authenticationType,
      societyContractId: event.societyContract.societyContractId,
      isRequiredPreConfirmation:
        event.societyContract.isRequiredPreConfirmation,
    };
  });

  // 固定掲載でない場合、同時掲載の計算を行う
  if (!enabledPublishingPeriodsFlag) {
    periodDefaultValues.event = Object.entries(periodDefaultValues.event)
      .sort(([eventId1, event1], [eventId2, event2]) => {
        const date1 = new Date(event1.photographingday);
        const date2 = new Date(event2.photographingday);
        return date1.getTime() - date2.getTime();
      })
      .reduce((sorted: TPeriodFormDatas, [eventId, event], index, array) => {
        // 設定日以前のイベントは同時掲載計算しない
        if (
          dayjs(event.photographingday).isBefore(dayjs(), 'day') &&
          !simultaneousReleaseFlag
        ) {
          sorted[eventId] = event;
          return sorted;
        }
        if (!simultaneousReleaseFlag) {
          simultaneousReleaseFlag = true;
          simultaneousReleaseStartDate = dayjs(event.photographingday);
          simultaneousReleaseEndDate = simultaneousReleaseStartDate.add(
            simultaneousReleasePeriod,
            'day'
          );
        }

        // 次の同時公開
        if (
          !dayjs(event.photographingday).isBetween(
            simultaneousReleaseStartDate,
            simultaneousReleaseEndDate,
            null,
            '[]'
          )
        ) {
          // データ更新
          sorted = rewritePeriodToSimultaneousRelease(
            sorted,
            simultaneousReleaseStartDate,
            simultaneousReleaseLastId
          );

          simultaneousReleaseStartDate = dayjs(event.photographingday);
          simultaneousReleaseEndDate = simultaneousReleaseStartDate.add(
            simultaneousReleasePeriod,
            'day'
          );
        }

        simultaneousReleaseLastId = eventId;
        sorted[eventId] = event;
        sorted[eventId].simultaneousStart =
          simultaneousReleaseStartDate.format('YYYY-MM-DD');
        sorted[eventId].simultaneousEnd =
          simultaneousReleaseEndDate.format('YYYY-MM-DD');

        if (index === array.length - 1) {
          // データ更新
          sorted = rewritePeriodToSimultaneousRelease(
            sorted,
            simultaneousReleaseStartDate,
            simultaneousReleaseLastId
          );
        }

        return sorted;
      }, {});
  }

  events.forEach((event) => {
    periodDefaultValues.event[event.eventId].valuestartday = event.valueStartDay
      ? dayjs(event.valueStartDay).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].valuestartday;
    periodDefaultValues.event[event.eventId].valueendday = event.valueEndDay
      ? dayjs(event.valueEndDay).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].valueendday;
    periodDefaultValues.event[event.eventId].publishendday = event.publishEndDay
      ? dayjs(event.publishEndDay).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].publishendday;
    periodDefaultValues.event[event.eventId].leaderendday = event.leaderEndDay
      ? dayjs(event.leaderEndDay).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].leaderendday;
    periodDefaultValues.event[event.eventId].chirashisenddate = event.flyer
      .sendDate
      ? dayjs(event.flyer.sendDate).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].chirashisenddate;
    periodDefaultValues.event[event.eventId].sbsenddate = event.sb.sendDate
      ? dayjs(event.sb.sendDate).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].sbsenddate;
    periodDefaultValues.event[event.eventId].faxsenddate = event.fax.sendDate
      ? dayjs(event.fax.sendDate).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].faxsenddate;
    periodDefaultValues.event[event.eventId].dvdsenddate = event.dvd.sendDate
      ? dayjs(event.dvd.sendDate).format('YYYY-MM-DD')
      : periodDefaultValues.event[event.eventId].dvdsenddate;
  });

  const methods = useForm({
    defaultValues: periodDefaultValues,
  });
  const handleConfirm = async (data: unknown) => {
    try {
      successToast('更新中');
      const checkData = { ...(data as TPeriodFormInputs) };
      const updateData: TPeriodFormInputs = {
        event: {},
      };
      Object.keys(checkData.event).forEach((key) => {
        if (
          !Array.isArray(checkData.event[key].check) &&
          checkData.event[key].check
        ) {
          updateData.event[key] = checkData.event[key];
        }
      });
      const response = await putJson<{ validator: TValidatorResponse }>(
        `/api/events/period/period_bulk_update`,
        updateData
      );

      if (response.validator.hasError) {
        errorToast('更新に失敗しました');
        alertApiError('更新に失敗しました');
        return;
      }
      handleUpdate();
    } catch (e) {
      errorToast('更新に失敗しました');
      setErrorMessages(toMessages(e));
    }
  };
  const preventDuplicateCallHandle = usePreventDuplicateCall(handleConfirm);

  return (
    <>
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <FormProvider {...methods}>
        <div className={styles.scrollbar}>
          {/* グリッドヘッダ */}
          <div
            className={`${styles.grid_header} ${styles.grid_other_department_container_wrap}`}
          >
            <div className={styles.grid_other_department_container}>
              <div className={styles.grid_row}>
                <div className={styles.grid_caption}>チェックボックス</div>
                <div className={styles.grid_caption}>団体</div>
                <div className={styles.grid_caption}>
                  事前
                  <br />
                  確認
                </div>
                <div className={styles.grid_caption}>
                  その他の
                  <br />
                  注意点
                </div>
                <div className={styles.grid_caption}>プラン</div>
                <div className={styles.grid_caption}>撮影日</div>
                <div className={styles.grid_caption}>イベント</div>
                <div className={styles.grid_caption}>OP用メモ</div>
                <div className={styles.grid_caption}>種類</div>
                <div className={styles.grid_caption}>
                  申請
                  <br />
                  オプション
                </div>
                <div className={styles.grid_caption}>公開設定</div>
                <div className={styles.grid_caption}>認証タイプ</div>
                <div className={styles.grid_caption}>写真閲覧キー</div>
                <div className={styles.grid_caption}>
                  固定
                  <br />
                  掲載
                </div>
                <div className={styles.grid_caption}>
                  掲載
                  <br />
                  開始日
                </div>
                <div className={styles.grid_caption}>
                  バリュー
                  <br />
                  終了日
                </div>
                <div className={styles.grid_caption}>
                  掲載
                  <br />
                  終了日
                </div>
                <div className={styles.grid_caption}>
                  責任者
                  <br />
                  終了日
                </div>
                <div className={styles.grid_caption}>
                  チラシ
                  <br />
                  の有無
                </div>
                <div className={styles.grid_caption}>
                  チラシ発送指定日の有無
                </div>
                <div className={styles.grid_caption}>チラシ</div>
                <div className={styles.grid_caption}>SB</div>
                <div className={styles.grid_caption}>
                  FAX
                  <br />
                  用紙
                </div>
                <div className={styles.grid_caption}>DVD</div>
              </div>
            </div>
          </div>
          {/* 詳細情報 */}
          {conditions.showDateEditForm &&
            events.map((e, key) => (
              <div className={styles.grid_other_department_container} key={key}>
                <PeriodResultItem
                  events={e}
                  formItems={formItems}
                  validator={validator}
                  methods={methods}
                />
              </div>
            ))}
          <div>
            <p className="u-align_center l-flex_center_line">
              <span
                className={`c-btn_large c-btn_primary is-arrow c-input_submit`}
                onClick={methods.handleSubmit(preventDuplicateCallHandle)}
              >
                変更
              </span>
            </p>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

const App: React.FC = () => {
  const queryString = useLocation().search;
  const {
    data: response,
    error,
    mutate,
  } = useJsonApiForSearchForm<TPeriodResponse>(
    '/api/events/period/period_list',
    queryString
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <div className="l-content_wrap">
      {/* ページタイトル */}
      <h1 className="l-flex_center u-mgb_m">
        <span className="c-page_title">期間設定</span>
      </h1>
      <div className="App">
        <div className="l-content">
          <div className="l-center_wrap">
            {/* 検索結果表示 */}
            <div className="c-frame">
              <PeriodResult response={response} handleUpdate={() => mutate()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
