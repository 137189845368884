import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import { MonthInput } from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { getCsv } from '@/ts/fetch';
import { removeEmpty } from '@/ts/objectTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { ApiErrors } from '@/components/shared/Form/Errors';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';
import { errorToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';
import { TWithholdingInputs } from './types';
import { alertApiError } from '@/ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};
const WithholdingForm: React.FC<{
  defaultValues: TWithholdingInputs;
}> = ({ defaultValues }) => {
  const downloadLink = useRef<HTMLAnchorElement | null>(null);
  const methods = useForm({ defaultValues });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);

  const downloadCsv = usePreventDuplicateCall(
    async (data: Record<string, unknown>) => {
      const path = `/api/photographer_payments/zengin/withholding_csv?${QueryString.stringify(
        removeEmpty(data)
      )}`;
      try {
        const res = await getCsv<{ validator: TValidatorResponse }>(path);
        setErrorMessages([]);
        if (typeof res === 'object' && res.validator.hasError) {
          setValidator(res.validator);
          alertApiError();
          return;
        } else {
          setValidator(emptyValidator);
        }

        const tmpLink = downloadLink.current!;
        const url = makeCsvDownloadUrl(res as string);
        tmpLink.href = url;
        tmpLink.download = `photographer_withholding_${dayjs().format(
          'YYYYMMDD_HHmmss'
        )}.csv`;
        tmpLink.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        errorToast('CSVの生成に失敗しました');
        setErrorMessages(toMessages(e));
      }
    }
  );

  return (
    <FormProvider {...methods}>
      <div className="c-frame c-searchForm">
        <ApiErrors {...validator} />
        <ErrorMessages messages={errorMessages} />
        <div className="c-section_title">源泉徴収CSVダウンロード</div>
        <ul className="l-flex l-col_wrap">
          <li className="l-col_12">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">締め月</li>
              <li className="c-dataValue">
                <MonthInput
                  name="occurrenceMonth"
                  placeholder="締め月"
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
        <div className="u-align_center">
          <button
            className="c-btn_large c-btn_Search c-input_submit u-mgr_m"
            type="button"
            onClick={methods.handleSubmit(downloadCsv)}
          >
            CSVダウンロード
          </button>
        </div>
        <a href="/" download="" className="is-hidden" ref={downloadLink}>
          dummy link
        </a>
      </div>
    </FormProvider>
  );
};

export default WithholdingForm;
