import { useState } from 'react';
import { fetcher } from '@/ts/useApi';
import { TLeaderMailaddressConfirmResponse, TSocieties } from './types';
import { errorToast, successToast } from '@/ts/toast';
import { Link } from 'react-router-dom';
import './leader_mailaddress_comfirm.scss';

const App: React.FC = () => {
  const [mailaddress, setMailAddress] = useState<string>('');
  const [returnMailaddress, setReturnMailaddress] = useState<string>('');
  const [result, setResult] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [societies, setSocieties] = useState<TSocieties[]>([]);

  const validateMailaddress = async () => {
    if (mailaddress && mailaddress.includes('@')) {
      successToast('確認中です');
      try {
        const apiResponse = (await fetcher(
          `/api/invitation_mail/validate_leader_mailaddress?mailaddress=${encodeURIComponent(
            mailaddress.trim()
          )}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )) as TLeaderMailaddressConfirmResponse;
        setReturnMailaddress(apiResponse.data.mailaddress);
        setResult(apiResponse.data.result);
        setReason(apiResponse.data.reason);
        setSocieties(apiResponse.data.societies);
      } catch (e) {
        errorToast('判定に失敗しました');
      }
    } else {
      setReturnMailaddress('');
      setResult('');
      setReason('');
      setSocieties([]);
      errorToast('メールアドレスを入力してください');
    }
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">責任者メールアドレスチェック</span>
      </h3>
      <div className="l-center_wrap">
        {/* メールアドレス入力フォーム */}
        <div className="c-frame">
          <div className="t-bgBox_gray">
            <div className="c-dataLabel l-col_wrap has-gutter u-pdr_xs u-pdl_xs">
              <div className="c-label_line">
                <label>メールアドレスを入力してください</label>
                <small className="c-required">(必須)</small>
              </div>
            </div>
            <div className="c-dataValue">
              <div className="l-col_auto">
                <div className="l-relative">
                  <div className="t-bgBox_white">
                    <input
                      className="c-input_plane"
                      type="email"
                      onChange={(e) => {
                        setMailAddress(e.target.value);
                      }}
                      placeholder="xxxx@example.com"
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                className="c-btn_large c-btn_Search c-input_submit c-label_line"
                onClick={validateMailaddress}
              >
                確認する
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="l-center_wrap">
        {/* 結果出力 */}
        <div className="c-frame">
          <div className="t-bgBox_gray">
            <div className="c-dataLabel l-col_wrap has-gutter u-pdr_xs u-pdl_xs">
              <div className="c-label_line">
                <label>判定結果</label>
              </div>
            </div>
            <div className="t-bgBox_white">
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel dataLabel-large">
                      <label>メールアドレス</label>
                    </li>
                    <li className="c-dataValue">{returnMailaddress}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel dataLabel-large">
                      <label>判定結果</label>
                    </li>
                    <li className="c-dataValue">{result}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel dataLabel-large">
                      <label>理由</label>
                    </li>
                    <li className="c-dataValue">{reason}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel dataLabel-large">
                      <label>対象団体</label>
                    </li>
                    <li className="c-dataValue">
                      {societies
                        ? societies.map((society, index) => (
                            <Link
                              key={index}
                              to={`societies/${society.societyId}`}
                            >
                              {society.societyName}(団体ID：{society.societyId}){' '}
                              <br />
                            </Link>
                          ))
                        : null}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
