import React, { ChangeEventHandler } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { dateFormat, numberFormat } from '../../../../ts/formatTools';
import GeneralStatusLabel from '../../../shared/StatusLabel/App';
import StatusLabel from '../../../shared/IncentivePayments/StatusLabel';
import SearchResultItemPaidAt from '../../../shared/IncentivePayments/Index/SearchResultItemPaidAt';
import { TCheck, TIncentivePayment } from './types';
import Help from '../../../shared/Help/App';

type TProps = {
  canEdit: boolean;
  incentivePayment: TIncentivePayment;
  check?: TCheck;
  toggleCheck: ChangeEventHandler;
};
const SearchResultItem: React.FC<TProps> = React.memo(
  ({ canEdit, incentivePayment, check, toggleCheck }) => {
    return (
      <tr>
        <td className="c-indexList_column_checkbox u-align_center">
          {check &&
            (check.disabled ? (
              '×'
            ) : (
              <label className="c-checkbox_listbox">
                <input
                  type="checkbox"
                  className="c-checkbox_list"
                  name="checks[]"
                  value={check.id}
                  checked={check.checked}
                  onChange={toggleCheck}
                />
                {/* NOTE: レイアウトの都合で必要な空のelement */}
                <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
              </label>
            ))}
        </td>
        <td className="c-indexList_column_s">
          <StatusLabel
            status={incentivePayment.status}
            statusName={incentivePayment.statusName}
          />
          <div className="u-fz_xs t-textColor_sub u-pdt_xs">
            {dayjs(incentivePayment.issuedOn).format('YYYY/MM')}
          </div>
        </td>
        <td className="c-indexList_column_l">
          <div>
            <Link
              className="c-textlink"
              to={`/partners/${incentivePayment.partnerId}`}
            >
              <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
                <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
                {incentivePayment.partnerId}
              </div>
            </Link>
          </div>
          <Link
            className="c-textlink"
            to={`/partner_incentive_payments/${incentivePayment.id}`}
          >
            {incentivePayment.partnerName}
          </Link>
        </td>
        <td className="c-indexList_column_s">{incentivePayment.planName}</td>
        <td className="c-indexList_column_s u-align_right">
          {numberFormat(incentivePayment.totalPayment, 2)}円
        </td>
        <td className="c-indexList_column_s u-align_right">
          {numberFormat(incentivePayment.totalSale)}円
        </td>
        <td className="c-indexList_column_s">
          {dateFormat(incentivePayment.acceptedAt)}
        </td>
        <td className="c-indexList_column_s">
          {dateFormat(incentivePayment.claimedAt)}
        </td>
        <td className="c-indexList_column_s c-indexList_column__nowrap">
          {incentivePayment.bankAccountHasMistakeName === null ? (
            <GeneralStatusLabel statusName="未登録" isPositive />
          ) : (
            <GeneralStatusLabel
              statusName={incentivePayment.bankAccountHasMistakeName}
              isPositive={!!incentivePayment.bankAccountHasMistake}
            />
          )}
        </td>
        <td className="c-indexList_column_s">
          <GeneralStatusLabel
            statusName={incentivePayment.isPdfSaved === null ? 'Web' : 'PDF'}
            isPositive={incentivePayment.isPdfSaved === 0}
          />
          {incentivePayment.isPdfSaved === 0 && <Help>PDFが未登録です</Help>}
        </td>
        <td className="c-indexList_column_l c-indexList_column__nowrap">
          <SearchResultItemPaidAt
            target="PARTNER"
            canEdit={canEdit}
            id={incentivePayment.id}
            paidAt={incentivePayment.paidAt}
            status={incentivePayment.status}
            bankAccountHasMistake={incentivePayment.bankAccountHasMistake}
          />
        </td>
      </tr>
    );
  }
);

export default SearchResultItem;
