import { UseFormReturn } from 'react-hook-form';
import { TFormItems, TFormInputs } from './types';
import dayjs from 'dayjs';
import { getFiscalYear } from '@/ts/fiscalYear';
import { TSocietyInfoByContract } from '../../../types';
import { isContractTypeDirect } from '../../../../ts/societyContractConstants';

import {
  OPENFLAG_OPEN,
  OPENFLAG_JIZENCOMP,
  OPENFLAG_LEADEROPEN,
  EVENTOPTION_TEACHERTAKE,
  EVENTOPTION_LEADEROPEN,
  OPENFLAG_CLOSE,
  OPENFLAG_JIZENSTART,
} from '../constants';

const EVENTTYPE_STAYING_EDU = 5;

const addPublishTermValue = (day: dayjs.Dayjs, termValue: string) => {
  const dayMatch = termValue.match(/(\d+)d/);
  if (dayMatch) {
    return day.add(Number(dayMatch[1]) - 1, 'day');
  }
  const monthMatch = termValue.match(/(\d+)m/);
  if (monthMatch) {
    return day.add(Number(monthMatch[1]), 'month');
  }
  return day;
};

const setPublishEndDayFromPhotographingDay = (
  societyInfo: TSocietyInfoByContract,
  methods: UseFormReturn<TFormInputs>
) => {
  const photographingday = methods.getValues('photographingday');
  if (!photographingday) {
    window.alert(
      '撮影日が入力されていないため、掲載終了日は自動で入力されません\n自動で掲載終了日を入力する場合は、撮影日を入力してください'
    );
    return;
  }
  const fiscalYear = getFiscalYear(
    dayjs(photographingday),
    societyInfo.society.startMonthOfFiscalYear
  );
  if (societyInfo.society.startMonthOfFiscalYear === 4) {
    methods.setValue(
      'publishendday',
      dayjs('' + (fiscalYear + 1) + '-05-31').format('YYYY-MM-DD')
    );
  } else {
    methods.setValue(
      'publishendday',
      dayjs(
        '' +
          (fiscalYear + 1) +
          '-' +
          societyInfo.society.startMonthOfFiscalYear +
          '-01'
      )
        .endOf('month')
        .format('YYYY-MM-DD')
    );
  }
};

export const handleValueStartDayChanged = (
  valuestartday: string,
  societyInfo: TSocietyInfoByContract,
  formItems: TFormItems,
  methods: UseFormReturn<TFormInputs>
): void => {
  // 妥当な日付になっていない場合、処理を抜ける
  if (!valuestartday.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    methods.setValue('valuestartday', null);
    return;
  }
  if (formItems.societyContracts.isEnabledPublishingPeriods) {
    const isPublishingStartDayMatch =
      formItems.societyContracts.publishingStartDay ===
      dayjs(valuestartday).date();
    if (!isPublishingStartDayMatch) {
      methods.setValue('publishendday', null);
      methods.setValue('valueendday', null);
      alert(
        '通常設定している公開開始日と異なるため、バリュー終了日は自動で入力されません。\nバリュー終了日を手動で入力してください。'
      );
      setPublishEndDayFromPhotographingDay(societyInfo, methods);
      return;
    }
    const endDay = dayjs(valuestartday)
      .set(
        'date',
        Math.min(
          dayjs(valuestartday).daysInMonth(),
          formItems.societyContracts.publishingEndDay
        )
      )
      .add(
        formItems.societyContracts.publishingEndDay <=
          formItems.societyContracts.publishingStartDay
          ? 1
          : 0,
        'month'
      )
      .format('YYYY-MM-DD');
    if (formItems.societyContractPlans.isEnabledOutOfPeriodSales) {
      methods.setValue('valueendday', endDay);
      setPublishEndDayFromPhotographingDay(societyInfo, methods);
    } else {
      methods.setValue('publishendday', endDay);
    }
  } else {
    if (formItems.societyContractPlans.isEnabledOutOfPeriodSales) {
      methods.setValue(
        'valueendday',
        addPublishTermValue(
          dayjs(valuestartday),
          formItems.societyContractPlans.publishtermvalue
        ).format('YYYY-MM-DD')
      );
      setPublishEndDayFromPhotographingDay(societyInfo, methods);
    } else {
      methods.setValue(
        'publishendday',
        addPublishTermValue(
          dayjs(valuestartday),
          formItems.societyContractPlans.publishtermvalue
        ).format('YYYY-MM-DD')
      );
    }
  }
};

export const handleOnInputLeaderEndDayChanged = (
  value: boolean,
  societyInfo: TSocietyInfoByContract,
  formItems: TFormItems,
  methods: UseFormReturn<TFormInputs>
): void => {
  if (value) {
    if (
      !isContractTypeDirect(formItems.societyContracts.societyContractTypeId)
    ) {
      return;
    }
    const valueStartDay = dayjs(methods.getValues('valuestartday'));
    if (!valueStartDay.isValid()) {
      return;
    }
    const fiscalYear = getFiscalYear(valueStartDay, 4);
    methods.setValue(
      'leaderendday',
      fiscalYear + societyInfo.society.publishPeriodYear + '-05-31'
    );
  } else {
    methods.setValue('leaderendday', null);
  }
};

export const handleEventTypeChanged = (
  value: number | undefined,
  methods: UseFormReturn<TFormInputs>
): void => {
  if (value !== EVENTTYPE_STAYING_EDU) {
    return;
  }

  methods.setValue('mojiireday', 'なし');
};

export const handleEventRequestOptionsChanged = (
  value: (string | undefined)[] | undefined,
  beforeValue: (string | undefined)[] | undefined,
  methods: UseFormReturn<TFormInputs>
): void => {
  if (!value) {
    return;
  }
  const openflag = methods.getValues('openflag');
  if (
    value.includes(EVENTOPTION_LEADEROPEN) &&
    !beforeValue?.includes(EVENTOPTION_LEADEROPEN)
  ) {
    // 公開設定が「1:公開」「4:事前確認完了」を選択している
    if (openflag === OPENFLAG_OPEN || openflag === OPENFLAG_JIZENCOMP) {
      methods.setValue('openflag', '');
    }
  } else if (
    !value.includes(EVENTOPTION_LEADEROPEN) &&
    beforeValue?.includes(EVENTOPTION_LEADEROPEN)
  ) {
    // 11:責任者公開がチェックされていない
    if (openflag === OPENFLAG_LEADEROPEN) {
      methods.setValue('openflag', '');
    }
  }
  // 12:先生撮影がチェックされている
  if (
    value.includes(EVENTOPTION_TEACHERTAKE) &&
    !beforeValue?.includes(EVENTOPTION_TEACHERTAKE)
  ) {
    methods.setValue('mojiireday', 'なし');
  }
};

export const handleOpenSettingChanged = (
  value: number | '' | undefined,
  beforeValue: number | '' | undefined,
  methods: UseFormReturn<TFormInputs>
): void => {
  const eventOptions = methods.getValues('eventrequestoptions');
  if (
    value === OPENFLAG_LEADEROPEN &&
    !eventOptions?.includes(EVENTOPTION_LEADEROPEN)
  ) {
    alert(
      '申請オプション「責任者公開」が設定されていません\n公開設定「責任者専用」を選択する前に「責任者公開」を設定してください'
    );
    methods.setValue('openflag', beforeValue || '');
    return;
  } else if (
    (value === OPENFLAG_OPEN ||
      value === OPENFLAG_JIZENCOMP ||
      value === OPENFLAG_JIZENSTART) &&
    eventOptions?.includes(EVENTOPTION_LEADEROPEN)
  ) {
    alert(
      '申請オプション「責任者公開」が設定されている時は、\n公開設定「非掲載」「非公開」「責任者専用」以外を選択できません'
    );
    methods.setValue('openflag', beforeValue || '');
    return;
  }
};

export const handleCancelFlagChanged = (
  value: number | undefined,
  methods: UseFormReturn<TFormInputs>
): void => {
  if (value === 0) {
    return;
  }
  if (methods.getValues('openflag') !== OPENFLAG_CLOSE) {
    // 公開設定を非公開にする
    methods.setValue('openflag', OPENFLAG_CLOSE);
  }
};
