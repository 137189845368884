import { TValidator } from '@/components/shared/Form/types';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Constants, DataResponse } from '../../type';
import { Rank } from './Rank';
import { Base } from './Base';
import { Special } from './Special';
import { BusyDay } from './BusyDay';
import { OverTime } from './OverTime';
import { Additionals } from './Additionals';
import { Equipment } from './Equipment';
import { REQUEST_FEE_TYPE, REQUEST_FEE_FORM_TYPE } from '../../../constants';

function typeJudge(
  requestFeeType: number | null,
  eventRequestPhotographer: DataResponse
) {
  if (requestFeeType) {
    if (requestFeeType === REQUEST_FEE_TYPE.SPECIAL_FEE) {
      return REQUEST_FEE_FORM_TYPE.SPECIAL_FEE;
    } else if (requestFeeType === REQUEST_FEE_TYPE.NORMAL_FEE) {
      if (eventRequestPhotographer.rankFee) {
        return REQUEST_FEE_FORM_TYPE.RANK_FEE;
      }
      return REQUEST_FEE_FORM_TYPE.BASE_FEE;
    }
  }
  if (eventRequestPhotographer.specialFee) {
    return REQUEST_FEE_FORM_TYPE.SPECIAL_FEE;
  } else if (eventRequestPhotographer.rankFee) {
    return REQUEST_FEE_FORM_TYPE.RANK_FEE;
  }
  return REQUEST_FEE_FORM_TYPE.BASE_FEE;
}

export const Payments: FC<{
  index: number;
  validator: TValidator;
  eventRequestPhotographer: DataResponse;
  constants: Constants;
  requestFeeType: number;
}> = ({
  index,
  validator,
  eventRequestPhotographer,
  constants,
  requestFeeType,
}) => {
  const { register } = useFormContext();
  const type: number = typeJudge(requestFeeType, eventRequestPhotographer);

  return (
    <div>
      <input
        type="hidden"
        {...register(`eventRequestPhotographers.${index}.requestFeeType`)}
        value={type}
      />
      {type === REQUEST_FEE_FORM_TYPE.RANK_FEE && (
        <>
          <Rank index={index} constants={constants} validator={validator} />
          <BusyDay index={index} constants={constants} validator={validator} />
          <OverTime index={index} constants={constants} validator={validator} />
        </>
      )}
      {type === REQUEST_FEE_FORM_TYPE.BASE_FEE && (
        <>
          <Base index={index} constants={constants} validator={validator} />
          <BusyDay index={index} constants={constants} validator={validator} />
          <OverTime index={index} constants={constants} validator={validator} />
        </>
      )}
      {type === REQUEST_FEE_FORM_TYPE.SPECIAL_FEE && (
        <Special index={index} constants={constants} validator={validator} />
      )}
      <Equipment index={index} constants={constants} validator={validator} />
      <Additionals
        key={index}
        index={index}
        eventRequestPhotographer={eventRequestPhotographer}
        constants={constants}
        validator={validator}
      />
    </div>
  );
};
