import React, { MouseEvent } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumberInput,
  DateInput,
  Checkboxes,
  RadioBoxes,
  MonthInput,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit, setValue } = methods;

    const { planIds, statuses, bankAccountHasMistakes } = formItems;
    const clearDateRange = (
      e: MouseEvent,
      dateFrom: 'issuedOnFrom' | 'claimedAtFrom' | 'paidAtFrom',
      dateTo: 'issuedOnTo' | 'claimedAtTo' | 'paidAtTo'
    ) => {
      e.preventDefault();
      setValue(dateFrom, '');
      setValue(dateTo, '');
    };

    return (
      <div className="c-searchForm c-incentivePayments_searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>団体</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-flex">
                        <li className="c-incentivePayments_searchForm_targetInfo_id">
                          <NumberInput
                            name="societyId"
                            placeholder="団体ID"
                            validator={validator}
                          />
                        </li>
                        <li className="c-incentivePayments_searchForm_targetInfo_name">
                          <TextInput
                            name="societyName"
                            placeholder="団体名／表示名"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>口座不備</label>
                    </li>
                    <li className="c-dataValue">
                      <div className="t-bgBox_gray">
                        <RadioBoxes
                          name="bankAccountHasMistake"
                          choices={bankAccountHasMistakes}
                          isInline={true}
                          validator={validator}
                          isDisplayUnselected={true}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>発行月</label>
                      <br />
                      <a
                        href="/"
                        className="c-textlink u-fz_s"
                        onClick={(e) =>
                          clearDateRange(e, 'issuedOnFrom', 'issuedOnTo')
                        }
                      >
                        クリア
                      </a>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <MonthInput
                            name="issuedOnFrom"
                            placeholder="発行月 from"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <MonthInput
                            name="issuedOnTo"
                            placeholder="発行月 to"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>振込依頼日</label>
                      <br />
                      <a
                        href="/"
                        className="c-textlink u-fz_s"
                        onClick={(e) =>
                          clearDateRange(e, 'claimedAtFrom', 'claimedAtTo')
                        }
                      >
                        クリア
                      </a>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="claimedAtFrom"
                            placeholder="振込依頼日 from"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="claimedAtTo"
                            placeholder="振込依頼日 to"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>振込日</label>
                      <br />
                      <a
                        href="/"
                        className="c-textlink u-fz_s"
                        onClick={(e) =>
                          clearDateRange(e, 'paidAtFrom', 'paidAtTo')
                        }
                      >
                        クリア
                      </a>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="paidAtFrom"
                            placeholder="振込日 from"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="paidAtTo"
                            placeholder="振込日 to"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>プラン</label>
                    </li>
                    <li className="c-dataValue">
                      <div className="t-bgBox_gray">
                        <Checkboxes
                          name="planIds"
                          choices={planIds}
                          isInline={true}
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>管理部ステータス</label>
                    </li>
                    <li className="c-dataValue">
                      <div className="t-bgBox_gray">
                        <Checkboxes
                          name="statuses"
                          choices={statuses}
                          isInline={true}
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
