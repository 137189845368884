import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BreadCrumb.module.scss';

type BreadCrumbPages = {
  id: number;
  title: string;
  path: string | undefined;
};
export const BreadCrumb: React.FC<{ pagesInfo: BreadCrumbPages[] }> = ({
  pagesInfo,
}) => {
  return (
    <div className={styles.breadcrumb}>
      {pagesInfo.map((pageInfo, index) => (
        <div key={pageInfo.id}>
          {index !== 0 ? (
            <span className={styles.breadcrumbArrow}>&gt;</span>
          ) : (
            <></>
          )}
          {pageInfo.path ? (
            pageInfo.path.startsWith('https') ? (
              <a className="c-textlink" href={pageInfo.path}>
                {pageInfo.title}
              </a>
            ) : (
              <Link
                to={pageInfo.path}
                key={index}
                className={styles.breadcrumbLink}
              >
                {pageInfo.title}
              </Link>
            )
          ) : (
            <>{pageInfo.title}</>
          )}
        </div>
      ))}
    </div>
  );
};
