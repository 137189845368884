export const ID_UNCONFIRMED = -1 as const;
export const ID_NONE = -2 as const;

export const EVENT_TYPE_ID_MOCHI_POUND = 11;
export const EVENT_TYPE_ID_CHRISTMAS = 12;
export const EVENT_TYPE_ID_SUMMER_FESTIVAL = 17;
export const EVENT_TYPE_ID_HALLOWEEN = 7;
export const EVENT_TYPE_ID_OTHER = 0;
export const EVENT_TYPE_ID_POOL = 18;
export const EVENT_TYPE_ID_DAILY_CHILDCARE = 10;
export const EVENT_TYPE_ID_SLEEPOVER = 5;
export const EVENT_TYPE_ID_FIELD_TRIPS = 13;
export const EVENT_TYPE_ID_ENTRANCE_CEREMONY = 4;
export const EVENT_TYPE_ID_GRADUATION_CEREMONY = 3;
export const EVENT_TYPE_ID_SPORTS_DAY = 1;
export const EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL = 15;
export const EVENT_TYPE_ID_RECITAL = 2;
export const EVENT_TYPE_ID_RECITAL_REHEARSAL = 16;
export const EVENT_TYPE_ID_THROWING_BEANS = 6;

export const OTHER_EVENT_TYPE_ID_DEFAULT = 1;
export const OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE = 2;
export const OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY = 4;
export const OTHER_EVENT_TYPE_ID_PLAYING_SHOP = 5;

export const REQUEST_OPTION_ID_TIME_UNDEFINED = 2;
export const REQUEST_OPTION_ID_ALBUM = 14;

export const MEDAL_AWARD_STYLE_TYPE_PER_PERSON = 1;
export const MEDAL_AWARD_STYLE_TYPE_OTHER = 2;

export const SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_NO_POSITION =
  -2;

export const SPECIFIED_DIPLOMA_AWARD_PHOTOGRAPHY_POSITION_TYPE_ID_OTHER = 3;
export const DIPLOMA_AWARD_TYPE_ID_PER_PERSON = 1;
export const DIPLOMA_AWARD_TYPE_ID_REPRESENTATIVE = 2;
export const DIPLOMA_AWARD_TYPE_ID_OTHER = 3;

export const BREAK_FIRST_MEAL_TYPE_ID = 1;
export const LUNCH_MEAL_TYPE_ID = 2;
export const DINNER_MEAL_TYPE_ID = 3;

export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM = 1;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_MONOBLOCK = 2;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER = 3;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACKGROUND_PAPER_STAND = 4;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT = 5;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE = 6;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_WIDE_ANGLE_LENS = 7;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TELEPHOTO_LENS = 8;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BATTERY = 9;
export const PHOTOGRAPHY_EQUIPMENT_TYPE_ID_EXTRA_MEMORY_CARD = 10;

export const FARMING_EXPERIENCE_DIGGING_METHOD_OTHER_TYPE = 3;

export const CANCELLABLE_DAY_TYPE_ID_THAT_DAY = 2;

export const TRANSPORTATION_TYPE_CAR = '2';
export const TRANSPORTATION_TYPE_MOTORCYCLE = '3';
export const TRANSPORTATION_TYPE_BICYCLE = '4';

export const RAINY_DAY_ACTION_ID_CANCEL = 9;
export const RAINY_DAY_ACTION_ID_POSTPONE = 2;
export const RAINY_DAY_ACTION_ID_NO_CANCEL = 1;

export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE = 1;
export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_NO_CHANGE =
  '雨天の場合も内容の変更はありません';
export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE = 2;
export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_HAS_CHANGE =
  '雨天の場合も撮影内容を変更して撮影実施いたします';
export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL = 3;
export const RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_CANCEL =
  '雨天の場合は撮影中止になります';
export const HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_TRUE =
  '天候などで集合場所が変更になる場合は連絡が入りますので、指示に従ってください';
export const HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_FALSE =
  '集合場所の変更は発生しません';

export const BON_DANCE_STYLE_TYPE_IN_CIRCLE = 1;
export const BON_DANCE_STYLE_TYPE_OTHER = 2;
export const BON_DANCE_CIRCLE_STYLE_TYPE_SINGLE = 1;
export const PORTABLE_SHRINE_ROUTE_TYPE_INSIDE = 1;

export const GIFT_STYLE_TYPE_OTHER = 3;
export const REST_PLACE_TYPE_ID_OTHER = 3;

export const PARENT_SEAT_TYPE_ID_OTHER = '4';
export const LIGHTING_TYPE_ID_OTHER = 6;
export const POOL_CLOTHES_TYPE_ID_OTHER = 3;

export const PARTICIPANT_TYPE_ID_STAFF = '1';
export const PARTICIPANT_TYPE_ID_CURRENT_PRESCHOOLERS = '2';
export const PARTICIPANT_TYPE_ID_GRADUATION_PRESCHOOLERS = '3';
export const PARTICIPANT_TYPE_ID_PARENT = '4';
export const PARTICIPANT_TYPE_ID_EXTERNAL_INSTRUCTOR = '5';
export const PARTICIPANT_TYPE_ID_EXTERNAL = '6';
export const PARTICIPANT_TYPE_ID_OTHER = '7';
export const CANCEL_NOTIFICATION_TYPE_ID_OTHER = 3;
export const VIDEOGRAPHER_TYPE_PROFESSIONAL = 1;

export const REST_PLACE_TYPE_OTHER = 3;
