import React from 'react';
import {
  TextInput,
  NumberInput,
  Checkboxes,
} from '@/components/shared/Form/Inputs';
import { TSearchFormTabProps } from './types';

const TabOld: React.FC<TSearchFormTabProps> = React.memo(
  ({ validator, formItems }) => {
    const { oldPartnerPlans } = formItems;
    return (
      <>
        <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel c-label_line">
                <label>旧パートナー</label>
              </li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="c-certificationkeys_searchForm_modelInfo_id">
                    <NumberInput
                      name="oldPartnerId"
                      placeholder="旧パートナーID"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_name">
                    <TextInput
                      name="oldPartnerName"
                      placeholder="旧パートナー名/表示名"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_kana">
                    <TextInput
                      name="oldPartnerNamekana"
                      placeholder="旧パートナー名(かな)"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel c-label_line">
                <label>旧団体</label>
              </li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="c-certificationkeys_searchForm_modelInfo_id">
                    <NumberInput
                      name="oldGroupId"
                      placeholder="旧団体ID"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_name">
                    <TextInput
                      name="oldGroupName"
                      placeholder="旧団体名/表示名"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_kana">
                    <TextInput
                      name="oldGroupNamekana"
                      placeholder="旧団体名(かな)"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
          <li className="l-col_24">
            <ul className="l-content">
              <li className="c-dataLabel c-label_line">
                <label>プラン</label>
              </li>
              <li className="c-dataValue">
                <Checkboxes
                  name="oldPartnerPlans"
                  choices={oldPartnerPlans}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
);

export default TabOld;
