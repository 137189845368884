import { fetcher, TUseJsonApiResponse, useJsonApi } from './useApi';
import { makePathForSearchForm } from '@/ts/makePathForSearchForm';

/**
 * 検索form用
 * form処理＆ページング処理
 */
const useJsonApiForSearchForm = <DataType>(
  path: string,
  queryString: string
): TUseJsonApiResponse<DataType, Error> => {
  const newPath = makePathForSearchForm(path, queryString);
  return useJsonApi<DataType>(newPath);
};

/**
 * 検索form用 mutate用
 */
const fetchJsonApiForSearchForm = <DataType>(
  path: string,
  queryString: string,
  formData?: Record<string, unknown>
): Promise<DataType> => {
  const newPath = makePathForSearchForm(path, queryString, formData);
  return fetcher<DataType>(newPath);
};

export { useJsonApiForSearchForm, fetchJsonApiForSearchForm };
