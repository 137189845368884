import React from 'react';

const AlertMessages: React.FC<{ messageWhenUneditable: string }> = React.memo(
  ({ messageWhenUneditable }) => {
    if (!messageWhenUneditable) {
      return null;
    }

    return (
      <div className="c-frame">
        <div className="u-align_center">
          <div className="c-alert_text">
            {messageWhenUneditable}
            <br />
            編集が必要な場合は、もの部に連絡してください。
          </div>
        </div>
      </div>
    );
  }
);

export default AlertMessages;
