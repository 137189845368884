import { TConstants } from '@/components/pages/Events/Show/Photography/types';

export const getParticipantPhotographyPermittedLabel = (
  constants: TConstants,
  id: string
): string =>
  `${
    constants.photographyParticipantType.find((type) => type.key === Number(id))
      ?.value
  }写り込み`;
