import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TFormInputs, TFormItems, TSaveFormProps } from './types';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  TextInput,
  SingleSelect,
  NumberInput,
  TextArea,
  Checkboxes,
  RadioBoxes,
  CheckBox,
  DatePicker,
} from '@/components/shared/Form/Inputs';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import PriceTable from '../../../shared/PriceTable/App';
import { TPricePattern } from '../../../shared/PriceTable/types';
import {
  isContractTypeDirect,
  isContractTypeTeacher,
} from '../../../../ts/societyContractConstants';
import {
  handleCancelFlagChanged,
  handleEventRequestOptionsChanged,
  handleEventTypeChanged,
  handleOnInputLeaderEndDayChanged,
  handleOpenSettingChanged,
  handleValueStartDayChanged,
} from './handlers';
import { kanriUrl } from '../../../../ts/url';
import { Link } from 'react-router-dom';
import { TSocietyInfoByContract } from '../../../types';
import { ENABLED_PUBLISHING_PERIOD } from '../constants';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);
// 撮影中止フラグ
const CANCELFLAG_FALSE = 0;
const CANCELFLAG_TRUE = 1;

// チラシ発送のタイミング
const FLYER_PRE_DELIVERY_TYPE_BEFORE_SHOOTING = 1;
const FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY = 2;

const DISPLAY_EVENT_KEY_MAX = 3;
const HIDDEN_EVENT_KEYS_DISPLAY_MAX = 100;

// 年度キー初期表示数
const DISPLAY_NENDO_KEY_MAX = 3;

// 認証タイプ
const AUTH_TYPE_EVENT = 1;

// 認証キーコンポーネント
const CertificationKey: React.FC<{
  formItems: TFormItems;
  name: string;
  societyInfo: TSocietyInfoByContract;
  editable: boolean;
  editableMsg: string;
}> = ({ formItems, name, societyInfo, editable, editableMsg }) => {
  const { register } = useFormContext();
  const [open, setOpen] = useState(false);
  const [isNendoKeyFormOpen, setIsNendoKeyFormOpen] = useState(false);

  // NOTE:契約の認証タイプがイベントのとき、年度キーを全て隠す
  // NOTE:契約の認証タイプが年度のときイベントキーが4つより大きい場合は隠す
  const isHiddenYearKeys =
    societyInfo.societyContract.authenticationType === AUTH_TYPE_EVENT;
  const numberOfEventKeysToHidden =
    Object.keys(formItems.certificationKeyEvents).length > DISPLAY_EVENT_KEY_MAX
      ? Object.keys(formItems.certificationKeyEvents).length -
        DISPLAY_EVENT_KEY_MAX
      : 0;
  const numberOfYearKeysToHidden = isHiddenYearKeys
    ? Object.keys(formItems.certificationKeyYears).length
    : 0;
  const numberOfKeysToHidden =
    numberOfEventKeysToHidden + numberOfYearKeysToHidden;

  if (!editable) {
    return (
      <section key="certificationkeylist">
        写真閲覧キーを変更できません。{editableMsg}
      </section>
    );
  }
  return (
    <section key="certificationkeylist">
      <section>
        <label className="c-radioLabel">
          <input
            type="radio"
            value="0"
            className="c-radioBtn"
            {...register(name)}
          />
          <span className="c-label_radioBtn">未定</span>
        </label>
      </section>

      <section className="p-event_createForm_discrete_create_key">
        <span>8-XXXX-XXXX このイベント用に個別発行</span>
        <br />
        {formItems.yearList.map((nendo) => (
          <label className="c-radioLabel" key={nendo.key}>
            <input
              type="radio"
              value={nendo.key}
              className="c-radioBtn"
              {...register(name)}
            />
            <span className="c-label_radioBtn">{nendo.value}</span>
          </label>
        ))}
        {editableMsg}
      </section>

      {Object.keys(formItems.certificationKeyEvents).length > 0 &&
        formItems.certificationKeyEvents.map((certificationkeys, index) => (
          <React.Fragment key={certificationkeys.certificationkey}>
            {/*
              全て描画すると、認証キーが非常に多い時に描画が非常に遅くなるので、アコーディオンが閉じているときは一部だけ表示する。
              ある程度表示しないと、アコーディオンのアニメーションが動作しないので、全て非表示にはしない
            */}
            {(index < HIDDEN_EVENT_KEYS_DISPLAY_MAX || open) && (
              <div
                className={`c-accordion_content ${
                  index < DISPLAY_EVENT_KEY_MAX || open ? 'is-open' : ''
                }`}
              >
                <label>
                  <span>
                    <a
                      href={`/certificationkeys/${certificationkeys.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {certificationkeys.certificationkey}
                    </a>{' '}
                    {certificationkeys.keyname}
                  </span>
                  <br />
                  {certificationkeys.yearsList.map((nendoInfo) => (
                    <label className="c-radioLabel" key={nendoInfo.key}>
                      <input
                        type="radio"
                        value={nendoInfo.key}
                        className="c-radioBtn"
                        {...register(name)}
                      />
                      <span className="c-label_radioBtn">
                        {nendoInfo.value}
                      </span>
                    </label>
                  ))}
                </label>
                <br />
              </div>
            )}
          </React.Fragment>
        ))}
      {(Object.keys(formItems.certificationKeyEvents).length -
        DISPLAY_EVENT_KEY_MAX >
        0 ||
        societyInfo.societyContract.authenticationType === AUTH_TYPE_EVENT) && (
        <button
          type="button"
          className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-button e-events-buttonLabel"
          onClick={() => {
            setOpen((b) => !b);
          }}
        >
          {`${
            open
              ? '写真閲覧キーを閉じる'
              : `残り${numberOfKeysToHidden}個の写真閲覧キーを表示`
          }`}
        </button>
      )}
      <br />
      {/* 最新３件以前の年度キーを閉じる */}
      {Object.keys(formItems.certificationKeyYears).length > 0 &&
        formItems.certificationKeyYears.map((certificationkeys, index) => (
          <section
            key={certificationkeys.key}
            className={`c-accordion_content ${
              isHiddenYearKeys && !open
                ? ''
                : certificationkeys.currentFiscalYear &&
                  !!certificationkeys.keyName.match(/^[0-9]{2}.*/g)
                ? `is-open p-event_createForm_defaultSelect`
                : isNendoKeyFormOpen || index <= DISPLAY_NENDO_KEY_MAX - 1
                ? `is-open`
                : ''
            }`}
          >
            <label className="c-radioLabel">
              <input
                type="radio"
                value={certificationkeys.key}
                className="c-radioBtn"
                {...register(name)}
              />
              <span className="c-label_radioBtn">
                <Link to={`/certificationkeys/${certificationkeys.id}`}>
                  {certificationkeys.certificationKey}
                </Link>{' '}
                {certificationkeys.value} {certificationkeys.keyName}
              </span>
            </label>
            <br />
          </section>
        ))}

      {Object.keys(formItems.certificationKeyYears).length >
        DISPLAY_NENDO_KEY_MAX &&
        ((isHiddenYearKeys && open) || !isHiddenYearKeys) && (
          <button
            type="button"
            className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-button e-events-buttonLabel"
            onClick={() => {
              setIsNendoKeyFormOpen((b) => !b);
            }}
          >
            {`${
              isNendoKeyFormOpen
                ? '年度キーを閉じる'
                : `残り${
                    Object.keys(formItems.certificationKeyYears).length -
                    DISPLAY_NENDO_KEY_MAX
                  }個の年度キーを表示`
            }`}
          </button>
        )}
    </section>
  );
};

// 価格コンポーネント
const PricePattern: React.FC<{
  formItems: TFormItems;
  data: TPricePattern[];
  name: string;
}> = ({ formItems, data, name }) => {
  const { register } = useFormContext();
  const [isPriceFormOpen, setIsPriceFormOpen] = useState(false);
  const enableDefaultPrice = data.some(
    (pricePattern: TPricePattern) => pricePattern.defaultpriceflag === '1'
  );

  return (
    <>
      <li className="c-dataLabel e-events-dataLabel">
        <label className="c-radioLabel">
          <input
            type="radio"
            value="0"
            className="c-radioBtn"
            {...register(name)}
          />
          <span className="c-label_radioBtn">未定</span>
        </label>
      </li>

      <div data-testid="forPriceTest">
        {data.map((pricePattern) => (
          <li
            className={`c-accordion_content ${
              pricePattern.defaultpriceflag === '1'
                ? 'is-open p-eventSummary_price p-eventSummary_price__default'
                : isPriceFormOpen || (!enableDefaultPrice && data.length <= 1)
                ? 'is-open p-eventSummary_price'
                : ''
            }`}
            key={pricePattern.pricepatternno.toString()}
          >
            <div className="l-flex_center p-eventSummary_priceBox">
              <label className="c-radioLabel u-mgl_xs">
                <input
                  type="radio"
                  value={pricePattern.pricepatternno}
                  className="c-radioBtn"
                  {...register(name)}
                />
                <span className="c-label_radioBtn" />
              </label>
              <div className="p-eventSummary_price_tableContainer">
                <PriceTable
                  showId={true}
                  pricePattern={pricePattern}
                  photoTypes={formItems.pricepattern.photoTypes}
                  photoSizes={formItems.pricepattern.photoSizes}
                  userTypes={formItems.pricepattern.userTypes}
                  sizeNames={formItems.pricepattern.sizeNames}
                />
              </div>
            </div>
          </li>
        ))}
      </div>

      {data.length > 1 && (
        <button
          type="button"
          className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-button e-events-buttonLabel"
          onClick={() => {
            setIsPriceFormOpen((b) => !b);
          }}
        >
          {`${
            isPriceFormOpen
              ? '価格を閉じる'
              : enableDefaultPrice
              ? `残り${data.length - 1}個の価格を表示`
              : `残り${data.length}個の価格を表示`
          }`}
        </button>
      )}
    </>
  );
};

const DatePickerWithOnBlurCheck: React.FC<
  { check: (v: unknown) => boolean } & Parameters<typeof DatePicker>[0]
> = ({ check, ...props }) => {
  const methods = useFormContext();
  const previousValue = useRef<unknown>(methods.getValues(props.name));
  const handleChange = (currentValue: string | null | undefined) => {
    if (!check(currentValue)) {
      methods.setValue(props.name, previousValue.current);
      return;
    }
    previousValue.current = currentValue;
  };
  return (
    <DatePicker
      {...props}
      onBlur={handleChange}
      onClear={() => handleChange('')}
    />
  );
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    eventno,
    validator,
    formItems,
    defaultValues,
    editable,
    societyInfo,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const isEdit = eventno !== 0;
    const showDefaultLeaderEndDay = !!(
      isContractTypeDirect(formItems.societyContracts.societyContractTypeId) &&
      defaultValues.leaderendday &&
      (defaultValues.leaderendday !== defaultValues.publishendday ||
        !formItems.societyContractPlans.isEnabledOutOfPeriodSales)
    );
    const methods = useForm<TFormInputs>({
      defaultValues: {
        ...defaultValues,
        oninputleaderendday: showDefaultLeaderEndDay,
      },
    });

    // 責任者向け終了日設定チェックボックス
    const watchOnInputLeaderEndDay = methods.watch('oninputleaderendday');
    // 撮影日
    const watchPhotographingDay = methods.watch('photographingday', '');
    // 認証キー
    const watchCertificationkeyno = methods.watch('certificationkeyno');
    const watchPreviousYearEventno = methods.watch('previousyeareventno');

    const resetPublishDay = useCallback(() => {
      methods.setValue('valuestartday', null);
      methods.setValue('valueendday', null);
      methods.setValue('publishendday', null);
    }, [methods]);

    // photographingdayの内容が変わったら各値をリセットする
    useEffect(() => {
      if (methods.formState.dirtyFields.photographingday) {
        resetPublishDay();
        methods.setValue('chirashisenddate', null);
        methods.setValue('sbsenddate', null);
        methods.setValue('faxsenddate', null);
        methods.setValue('dvdsenddate', null);
        methods.setValue('sbjizensenddate', null);
      }
    }, [watchPhotographingDay, methods, resetPublishDay]);

    useEffect(() => {
      if (!editable.editableCertificationkeyno.editable) {
        return;
      }
      if (
        watchPhotographingDay &&
        dayjs(watchPhotographingDay).isValid() &&
        watchCertificationkeyno !== undefined &&
        watchCertificationkeyno !== '0' &&
        watchCertificationkeyno !== null
      ) {
        // 撮影年度の取得
        const startMonthOfFiscalYear =
          societyInfo.society.startMonthOfFiscalYear;
        const photographingMonth = Number(
          dayjs(watchPhotographingDay).format('M')
        );
        let photographingNendo = Number(
          dayjs(watchPhotographingDay).format('YYYY')
        );
        if (photographingMonth < startMonthOfFiscalYear) {
          photographingNendo--;
        }

        // 登録中の認証キー
        const currentCertificationKey = defaultValues.certificationkeyno
          ? defaultValues.certificationkeyno.split('_')
          : '0';

        // 選択中の認証キー
        const selectedKey = String(
          methods.getValues('certificationkeyno')
        ).split('_');

        if (
          currentCertificationKey[0] !== '0' &&
          currentCertificationKey[0] !== selectedKey[0]
        ) {
          if (
            window.confirm(
              '写真閲覧キー登録済みのイベントです。\n写真閲覧キーを変更する場合は「OK」を、戻す場合は「キャンセル」を選択してください'
            )
          ) {
            // 「OK」を押した場合は何もしない
          } else {
            methods.setValue(
              'certificationkeyno',
              defaultValues.certificationkeyno
                ? defaultValues.certificationkeyno
                : '0'
            );
          }
        }

        if (selectedKey[0] === '0') {
          return;
        }

        // 日付のフォーマットチェックは不要？
        if (Number(selectedKey[1]) !== photographingNendo) {
          if (
            window.confirm(
              '選択しようとした写真閲覧キーは撮影日と年度が異なります。\n撮影年度: ' +
                photographingNendo +
                '年度 (撮影日: ' +
                watchPhotographingDay +
                ')\n年度キー: ' +
                selectedKey[1] +
                '年度\n撮影年度に合わせて' +
                photographingNendo +
                '年度の年度キーを選択するよう変更しますか？' +
                '\n（そのまま' +
                selectedKey[1] +
                '年度のキーを設定する場合、キャンセルを押してください。）'
            )
          ) {
            // 撮影年度に合わせて認証キーの年度を変更する
            let keyChanged = formItems.certificationKeyEvents.some(
              (eventKeys) => {
                return eventKeys.yearsList.some((yearinfo) => {
                  if (selectedKey[0] !== String(yearinfo.key).split('_')[0]) {
                    // 選択中のイベントキーでなければfalse
                    return false;
                  }
                  if (
                    String(yearinfo.key).split('_')[1] ===
                    String(photographingNendo)
                  ) {
                    methods.setValue(
                      'certificationkeyno',
                      String(yearinfo.key).split('_')[0] +
                        '_' +
                        photographingNendo
                    );
                    return true;
                  }
                  return false;
                });
              }
            );
            keyChanged =
              keyChanged ||
              formItems.certificationKeyYears.some((yearsKey) => {
                if (
                  String(yearsKey.key).split('_')[1] ===
                  String(photographingNendo)
                ) {
                  methods.setValue(
                    'certificationkeyno',
                    String(yearsKey.key).split('_')[0] +
                      '_' +
                      photographingNendo
                  );
                  return true;
                }
                return false;
              });
            if (!keyChanged) {
              alert(
                '撮影年度の年度キーが存在しません。撮影日を確認して下さい。'
              );
            }
          } else {
            alert(
              '撮影日と写真閲覧キーの年度が異なった状態です。\n撮影日と写真閲覧キーの組み合わせが正しいか確認して下さい。'
            );
          }
        }
      }
    }, [
      editable,
      societyInfo,
      watchPhotographingDay,
      watchCertificationkeyno,
      formItems.certificationKeyEvents,
      formItems.certificationKeyYears,
      methods,
      defaultValues,
    ]);

    // 撮影　中止フラグ
    const watchCancelFlag = methods.watch('cancelflag');

    // 公開設定
    const watchCancelReason = methods.watch('cancelShootingReason');
    const beforeOpenflag = useRef(methods.getValues('openflag'));
    const beforeEventRequestOptions = useRef(
      methods.getValues('eventrequestoptions')
    );
    useEffect(() => {
      const subscription = methods.watch((value, { name, type }) => {
        if (name === 'oninputleaderendday' && type === 'change') {
          handleOnInputLeaderEndDayChanged(
            value['oninputleaderendday'] || false,
            societyInfo,
            formItems,
            methods
          );
        } else if (name === 'eventtype' && type === 'change') {
          handleEventTypeChanged(value['eventtype'], methods);
        } else if (name === 'eventrequestoptions' && type === 'change') {
          handleEventRequestOptionsChanged(
            value['eventrequestoptions'],
            beforeEventRequestOptions.current,
            methods
          );
        } else if (name === 'openflag' && type === 'change') {
          handleOpenSettingChanged(
            value['openflag'],
            beforeOpenflag.current,
            methods
          );
        } else if (name === 'cancelflag' && type === 'change') {
          handleCancelFlagChanged(value['cancelflag'], methods);
        }
        beforeOpenflag.current = methods.getValues('openflag');
        const eventRequestOptions = methods.getValues('eventrequestoptions');
        beforeEventRequestOptions.current = eventRequestOptions
          ? [...eventRequestOptions]
          : eventRequestOptions;
      });
      return () => subscription.unsubscribe();
    }, [methods, societyInfo, formItems]);

    const clickResetButton = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      resetPublishDay();
    };
    const holidayCheck = (date: unknown) => {
      const day = dayjs(date as string);
      if (
        day.day() === 0 ||
        day.day() === 6 ||
        formItems.holidays[date as string]
      ) {
        window.alert('祝日または土日の発送日設定はできません');
        return false;
      }
      return true;
    };
    const chirashiSendDateCheck = (date: unknown) => {
      if (!holidayCheck(date)) {
        return false;
      }
      if (
        formItems.societyContracts.flyerPreDeliveryType ===
        FLYER_PRE_DELIVERY_TYPE_BEFORE_SHOOTING
      ) {
        const photograpingDay = dayjs(methods.getValues('photographingday'));
        const chirashiSendDate = dayjs(date as string);
        if (
          photograpingDay.isValid() &&
          chirashiSendDate.isValid() &&
          chirashiSendDate.isAfter(photograpingDay)
        ) {
          window.alert('チラシ発送日が撮影日よりも後になっています');
        }
      }
      if (
        formItems.societyContracts.flyerPreDeliveryType ===
        FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY
      ) {
        const valueStartDay = dayjs(methods.getValues('valuestartday'));
        const chirashiSendDate = dayjs(date as string);
        if (
          valueStartDay.isValid() &&
          chirashiSendDate.isValid() &&
          chirashiSendDate.isSameOrAfter(valueStartDay)
        )
          window.alert(
            'チラシ発送日が掲載開始日と同日か掲載開始日より後になっています'
          );
      }
      return true;
    };

    const nowYear = dayjs(new Date()).year();

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <div className="t-bgBox_gray u-pdb_s u-mgb_m">ユーザー向け情報</div>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel e-events-dataLabel">
                <label>前年の同イベントのID</label>
                <br />
                {!!watchPreviousYearEventno && (
                  <>
                    <i className="c-icon c-icon__xxsmall c-icon_blank u-mgr_xs" />
                    <a
                      target="_blank"
                      href={`/events/${watchPreviousYearEventno}`}
                      rel="noreferrer"
                      className="c-textlink"
                    >
                      前年同イベント
                    </a>
                  </>
                )}
              </li>
              <li className="c-dataValue">
                <ul className="l-flex l-col_wrap">
                  <li className="l-col_12">
                    <NumberInput
                      name="previousyeareventno"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            {/* イベント名 */}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel e-events-dataLabel">
                <label>イベント名</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="eventname"
                  placeholder="イベント名"
                  validator={validator}
                />
              </li>
            </ul>

            {/* イベントの種類 */}
            {isContractTypeDirect(
              formItems.societyContracts.societyContractTypeId
            ) && (
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel e-events-dataLabel">
                  <label>イベント種類</label>
                  <small className="c-required">(必須)</small>
                </li>
                <li className="c-dataValue u-pdr_xs u-pdl_xs">
                  <SingleSelect
                    name="eventtype"
                    choices={formItems.event.eventTypeIds}
                    validator={validator}
                  />
                </li>
              </ul>
            )}

            {/* 撮影日・申請オプション */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel e-events-dataLabel">
                    <label>撮影日</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <DatePicker
                      holidays={formItems.holidays}
                      name="photographingday"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            {/* 撮影中止フラグ・撮影中止種別・撮影中止理由詳細 */}
            {isContractTypeDirect(
              formItems.societyContracts.societyContractTypeId
            ) && (
              <>
                <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                  <li className="c-label_innerHalf">
                    <ul className="l-flex">
                      <li className="c-dataLabel e-events-dataLabel">
                        <label>撮影中止フラグ</label>
                        {isContractTypeDirect(
                          formItems.societyContracts.societyContractTypeId
                        ) && <small className="c-required">(必須)</small>}
                      </li>
                      <li className="c-dataValue">
                        <RadioBoxes
                          name="cancelflag"
                          choices={formItems.event.cancelflag}
                          validator={validator}
                        />
                        <div>
                          <span className="p-events-cautionText">
                            ※「中止」の場合は自動的に「非公開」になります
                          </span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="c-label_innerHalf"></li>
                </ul>

                {watchCancelFlag === CANCELFLAG_TRUE && (
                  <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel e-events-dataLabel">
                          <label>撮影中止種別</label>
                          <small className="c-required">(必須)</small>
                        </li>
                        <li className="c-dataValue">
                          <SingleSelect
                            name="cancelShootingReason"
                            choices={formItems.event.cancel_shooting_reason}
                            placeholder="撮影中止種別"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      {watchCancelReason !== undefined &&
                      watchCancelReason > 1 ? (
                        <ul className="l-flex">
                          <li className="c-dataLabel e-events-dataLabel">
                            <label>撮影中止理由詳細</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            {watchCancelReason === 2 ||
                            watchCancelReason === 3 ? (
                              <SingleSelect
                                name="cancelShootingReasonDetail"
                                choices={
                                  formItems.event.cancel_shooting_reason_detail
                                }
                                placeholder="撮影中止種別"
                                validator={validator}
                              />
                            ) : (
                              <TextInput
                                name="cancelShootingReasonDetailOther"
                                placeholder="理由の詳細"
                                validator={validator}
                              />
                            )}
                          </li>
                        </ul>
                      ) : (
                        <div></div>
                      )}
                    </li>
                  </ul>
                )}
              </>
            )}

            {!!formItems.societyContractPlans.isEnabledOverlayText && (
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>スナップ写真の文字入れ用日付</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="mojiireday"
                        placeholder="スナップ写真の文字入れ用日付"
                        validator={validator}
                      />
                      <span>
                        例1：{nowYear}.07.20・21
                        <br />
                        例2：{nowYear}.07.20-22
                        <br />
                        「なし」と入力すると、カート画面で日付入れボタンが出なくなります。
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            )}

            {/* 公開設定・スナップ写真の文字入れ用日付 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              {watchCancelFlag === CANCELFLAG_FALSE && (
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>公開設定</label>
                    </li>
                    <li className="c-dataValue">
                      <SingleSelect
                        name="openflag"
                        choices={formItems.event.openflag}
                        placeholder="公開設定"
                        validator={validator}
                      />
                      <div>
                        <a
                          className="p-events-aboutVisibleEvent"
                          href={kanriUrl({
                            action_owner_manual_EventVisibility: 't',
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ※イベント見れる・見れないはこちらから
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel e-events-dataLabel">
                <label>申請オプション</label>
              </li>
              <li className="c-dataValue">
                <Checkboxes
                  name="eventrequestoptions"
                  choices={formItems.event.eventOptions}
                  validator={validator}
                />
              </li>
            </ul>

            {/* 掲載開始日・掲載終了日 */}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel e-events-dataLabel">
                <label>期間設定</label>
              </li>
              <li className="c-dataValue">
                <ul className="l-flex l-col_wrap">
                  <li className="p-eventSummary_datePicker">
                    <label>掲載開始日</label>
                    <DatePicker
                      holidays={formItems.holidays}
                      name="valuestartday"
                      onClear={() => {
                        handleValueStartDayChanged(
                          '',
                          societyInfo,
                          formItems,
                          methods
                        );
                      }}
                      onBlur={(date) => {
                        handleValueStartDayChanged(
                          date || '',
                          societyInfo,
                          formItems,
                          methods
                        );
                      }}
                      validator={validator}
                    />
                  </li>
                  {formItems.societyContractPlans.isEnabledOutOfPeriodSales !==
                    0 &&
                    isContractTypeDirect(
                      formItems.societyContracts.societyContractTypeId
                    ) && (
                      <>
                        <li className="p-eventSummary_datePicker">
                          <label>バリュー終了日</label>
                          <DatePicker
                            holidays={formItems.holidays}
                            name="valueendday"
                            validator={validator}
                          />
                        </li>
                      </>
                    )}
                  <li className="p-eventSummary_datePicker">
                    <label>掲載終了日</label>
                    <DatePicker
                      holidays={formItems.holidays}
                      name="publishendday"
                      validator={validator}
                    />
                  </li>
                </ul>
                {formItems.societyContracts.isEnabledPublishingPeriods ===
                  ENABLED_PUBLISHING_PERIOD && (
                  <div>
                    <span className="p-events-cautionText">
                      掲載日固定の団体です(
                      {formItems.societyContracts.publishingStartDay}日-
                      {formItems.societyContracts.publishingEndDay}日)
                    </span>
                  </div>
                )}
                <div>
                  <span className="p-events-annotation">
                    ※未入力の場合は未設定となります
                  </span>
                </div>
                <ul>
                  <li className="c-dataValue">
                    <button
                      type="button"
                      className="c-btn_large c-btn_cancel u-mgb_s c-input_submit e-events-buttonLabel"
                      onClick={clickResetButton}
                    >
                      入力をクリア
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            {/* バリュー終了日・責任者終了日 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              {isContractTypeDirect(
                formItems.societyContracts.societyContractTypeId
              ) && (
                <>
                  <li className="c-dataLabel e-events-dataLabel">
                    <label></label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex l-col_wrap">
                      {/* 責任者終了日チェックボックス */}
                      <li>
                        <CheckBox
                          name="oninputleaderendday"
                          validator={validator}
                          label="責任者向け終了日を別途定義する"
                        />
                      </li>
                      <li>
                        {watchOnInputLeaderEndDay && (
                          <DatePicker
                            holidays={formItems.holidays}
                            name="leaderendday"
                            validator={validator}
                          />
                        )}
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>

            <div className="t-bgBox_gray u-pdb_s u-mgb_m">イベント情報</div>
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel e-events-dataLabel">
                    <label>顔検索機能使用可否</label>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="faceRecommendFlag"
                      choices={formItems.event.faceRecommendFlag}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel e-events-dataLabel">
                    <label>イベント期間自動設定の可否</label>
                  </li>
                  <li className="c-dataValue">
                    {/* TODO: 団体設定によって隠す */}
                    <RadioBoxes
                      name="autoeventtermsettingflag"
                      choices={formItems.event.autoeventtermsettingflag}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            {/* 参加人数 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel e-events-dataLabel">
                    <label>参加人数</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="participants"
                      placeholder="参加人数"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            {/* 認証キー */}
            <ul className="l-flex u-mgb_s">
              <li className="c-dataLabel e-events-dataLabel">
                <label>写真閲覧キー</label>
                <div>
                  認証タイプ：
                  <br />
                  {societyInfo.societyContract.authenticationTypeName}
                </div>
              </li>
              <li className="c-dataValue" data-testid="forCertificationKeyTest">
                <CertificationKey
                  formItems={formItems}
                  name="certificationkeyno"
                  societyInfo={societyInfo}
                  editable={editable.editableCertificationkeyno.editable}
                  editableMsg={editable.editableCertificationkeyno.editableMsg}
                />
              </li>
            </ul>

            <div className="t-bgBox_gray u-pdb_s u-mgb_m">運用上の情報</div>

            {/* チラシ部数・チラシ送信日 */}
            {formItems.societyContracts.isRequiredFlyer === 1 &&
              !isContractTypeTeacher(
                formItems.societyContracts.societyContractTypeId
              ) && (
                <>
                  <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>チラシ部数</label>
                    </li>
                    <li className="c-dataValue">
                      <NumberInput
                        name="chirashicount"
                        placeholder="チラシ部数"
                        validator={validator}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>チラシ送信日</label>
                    </li>
                    <li className="c-dataValue">
                      <DatePickerWithOnBlurCheck
                        holidays={formItems.holidays}
                        name="chirashisenddate"
                        validator={validator}
                        check={chirashiSendDateCheck}
                      />
                    </li>
                  </ul>
                </>
              )}

            {/* SB発送日・FAX発送日 */}
            {formItems.societyContracts.isRequiredSamplebook === 1 && (
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>SB発送日</label>
                    </li>
                    <li className="c-dataValue">
                      <DatePickerWithOnBlurCheck
                        holidays={formItems.holidays}
                        name="sbsenddate"
                        validator={validator}
                        check={holidayCheck}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            )}

            {formItems.societyContracts.isRequiredFax === 1 && (
              <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel e-events-dataLabel">
                      <label>FAX発送日</label>
                    </li>
                    <li className="c-dataValue">
                      <DatePickerWithOnBlurCheck
                        holidays={formItems.holidays}
                        name="faxsenddate"
                        validator={validator}
                        check={holidayCheck}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            )}

            {/* DVD発送日・SB（事前確認用）発送日 */}
            {(formItems.societyContracts.isRequiredDvd === 1 ||
              formItems.societyContracts
                .isRequiredSamplebookForPreConfirmation === 1) && (
              <>
                {formItems.societyContracts.isRequiredDvd === 1 && (
                  <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel e-events-dataLabel">
                          <label>DVD発送日</label>
                        </li>
                        <li className="c-dataValue">
                          <DatePickerWithOnBlurCheck
                            holidays={formItems.holidays}
                            name="dvdsenddate"
                            validator={validator}
                            check={holidayCheck}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}
                {formItems.societyContracts
                  .isRequiredSamplebookForPreConfirmation === 1 && (
                  <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel e-events-dataLabel">
                          <label>SB（事前確認用）発送日</label>
                        </li>
                        <li className="c-dataValue">
                          <DatePickerWithOnBlurCheck
                            holidays={formItems.holidays}
                            name="sbjizensenddate"
                            validator={validator}
                            check={holidayCheck}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}
              </>
            )}

            {/* 写真の並び順 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel e-events-dataLabel">
                    <label>写真の並び順</label>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="alignment"
                      choices={formItems.event.alignment}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            {/* 写真フォルダ */}
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel e-events-dataLabel">
                <label>写真フォルダ</label>
              </li>
              <li className="c-dataValue">
                {editable.editableConvertinfo.editable ? (
                  <SingleSelect
                    name="convertinfono"
                    choices={formItems.convertInfos}
                    placeholder="写真フォルダ"
                    validator={validator}
                    windowed={true}
                  />
                ) : (
                  <>
                    写真フォルダを変更できません。
                    {editable.editableConvertinfo.editableMsg}
                  </>
                )}
              </li>
            </ul>

            {!isEdit && (
              <ul className="c-label_line is-sp_input l-flex">
                <li className="c-dataLabel e-events-dataLabel">
                  <label>
                    メモ
                    <br />
                    (OPへの依頼は全てバックログでお願いいたします)
                  </label>
                </li>
                <li className="c-dataValue">
                  <TextArea name="memo" validator={validator} rows={10} />
                </li>
              </ul>
            )}
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel e-events-dataLabel">
                <label>OP用メモ</label>
              </li>
              <li className="c-dataValue">
                <TextArea name="opMemo" validator={validator} rows={10} />
              </li>
            </ul>

            <div className="t-bgBox_gray u-pdb_s u-mgb_m">価格情報</div>

            {/* 価格情報リストパターン */}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel e-events-dataLabel">
                <label>価格情報</label>
              </li>
              <li className="c-dataValue">
                <ul>
                  {editable.editablePrice.editable ? (
                    <PricePattern
                      formItems={formItems}
                      data={formItems.pricepattern.pricePatterns}
                      name="pricepatternno"
                    />
                  ) : (
                    <>
                      価格を変更できません。
                      {editable.editableConvertinfo.editableMsg}
                    </>
                  )}
                </ul>
              </li>
            </ul>
            {/* 編集中に他の人が編集していたときの制御に利用 */}
            <TextInput
              name="openEditPageTime"
              validator={validator}
              additionalClassName="is-hidden"
            />

            <div className="u-align_center u-mgb_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-linkLabel"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit e-events-linkLabel"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
