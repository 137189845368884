import clsx from 'clsx';
import { TOverviewResponse } from '../types';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { BiLinkExternal } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';

const RecentEventRow: React.FC<{
  currentEventId: string;
  event: TOverviewResponse['recentEvents'][0];
}> = ({ currentEventId, event }) => {
  const isCurrentEvent = currentEventId === event.id.toString();
  const cellClass = clsx(
    styles.tableCell,
    isCurrentEvent ? styles.currentEvent : ''
  );
  return (
    <tr>
      <td className={clsx(cellClass, 'u-align_center')}>
        <span className={clsx(!event.isDetailMailSent && styles.unsent)}>
          {event.isDetailMailSent ? '送付済' : '未送信'}
        </span>
      </td>
      <td className={cellClass}>
        {`${dayjs(event.photographingDay).format('YYYY/MM/DD (ddd)')} ${
          event.photographingStartTime &&
          event.photographingEndTime &&
          `${dayjs(event.photographingStartTime).format('HH:mm')}〜${dayjs(
            event.photographingEndTime
          ).format('HH:mm')}`
        }`}
      </td>
      <td className={clsx(cellClass, styles.tableEventCell)}>
        <div className={clsx(styles.eventLinkBox)}>
          {event.isCancelled && (
            <div className={clsx(styles.cancelledLabel)}>撮影中止</div>
          )}
          <Link
            className={clsx('c-textlink', styles.eventLink)}
            target="_blank"
            to={`/events/${event.id}`}
          >
            {event.id} / {event.name}
          </Link>
          <BiLinkExternal className={clsx(styles.eventLinkIcon)} />
        </div>
      </td>
    </tr>
  );
};

export const RecentEvents: React.FC<{ response: TOverviewResponse }> = ({
  response,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  return (
    <>
      <div className="c-section_title">直近イベント</div>
      <div className="t-textColor_sub u-mgt_s">
        詳細メール送付状況やカメラマンアサイン状況に関係なく、当団体での直近イベントが5件表示されます。
      </div>
      <table className={clsx('u-mgt_s', styles.table)}>
        <thead>
          <tr>
            <th className={clsx(styles.tableHead, styles.tableDetailMailHead)}>
              詳細メール
            </th>
            <th
              className={clsx(
                styles.tableHead,
                styles.tablePhotographingDayHead
              )}
            >
              撮影日時
            </th>
            <th className={clsx(styles.tableHead, styles.tableEventHead)}>
              イベント名
            </th>
          </tr>
        </thead>
        <tbody>
          {response.recentEvents.map((event) => (
            <RecentEventRow event={event} currentEventId={eventId} />
          ))}
        </tbody>
      </table>
    </>
  );
};
