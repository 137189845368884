import React, { Ref, useState } from 'react';
import clsx from 'clsx';

/**
 * 開閉を自力で制御したい場合はこちらを使う
 */
export const ManualAccordion: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  labelClose?: string;
  labelOpen?: string;
  isDisableScroll?: boolean;
  toggleButtonRef?: Ref<HTMLButtonElement>;
}> = React.memo(
  ({
    children,
    isOpen,
    setIsOpen,
    labelClose: defaultLabelClose,
    labelOpen: defaultLabelOpen,
    isDisableScroll,
    toggleButtonRef,
  }) => {
    const labelClose = defaultLabelClose ?? '閉じる';
    const labelOpen = defaultLabelOpen ?? '詳細';
    return (
      <div className="c-accordion">
        <button
          type="button"
          className={`c-accordion_button c-btn_toggleDetails u-fz_xs${
            isOpen ? ' is-open' : ''
          }`}
          onClick={() => setIsOpen(!isOpen)}
          ref={toggleButtonRef}
        >
          {isOpen ? labelClose : labelOpen}
        </button>
        <div
          className={clsx('c-accordion_content', {
            'is-open': isOpen,
            isDisableScroll: isDisableScroll,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);

const Accordion: React.FC<{
  isOpen?: boolean;
  labelClose?: string;
  labelOpen?: string;
  isDisableScroll?: boolean;
}> = React.memo(({ isOpen: defaultIsOpen, ...props }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false);
  return <ManualAccordion isOpen={isOpen} setIsOpen={setIsOpen} {...props} />;
});

export default Accordion;
