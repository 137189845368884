import React from 'react';

type TStatusLabelProps = {
  isPositive: boolean;
  statusName: string;
  additionalClassName?: string;
};
const StatusLabel: React.FC<TStatusLabelProps> = ({
  isPositive,
  statusName,
  additionalClassName,
}) => {
  const className =
    'c-statusLabel' +
    ` c-statusLabel__${isPositive ? 'positive' : 'negative'}` +
    (additionalClassName ? ` ${additionalClassName}` : '');
  return <span className={className}>{statusName}</span>;
};

export default StatusLabel;
