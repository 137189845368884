import React from 'react';
import {
  TextInput,
  NumberInput,
  Checkboxes,
} from '@/components/shared/Form/Inputs';
import { TSearchFormTabProps } from './types';

const TabPartner: React.FC<TSearchFormTabProps> = React.memo(
  ({ validator, formItems }) => {
    const { partnerPlans } = formItems;
    return (
      <>
        <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel c-label_line">
                <label>パートナー</label>
              </li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="c-certificationkeys_searchForm_modelInfo_id">
                    <NumberInput
                      name="partnerId"
                      placeholder="パートナーID"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_name">
                    <TextInput
                      name="partnerName"
                      placeholder="パートナー名/表示名"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_kana">
                    <TextInput
                      name="partnerNamekana"
                      placeholder="パートナー名(かな)"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>パートナー団体</label>
              </li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="c-certificationkeys_searchForm_modelInfo_id">
                    <NumberInput
                      name="groupId"
                      placeholder="団体ID"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_name">
                    <TextInput
                      name="groupName"
                      placeholder="団体名/表示名"
                      validator={validator}
                    />
                  </li>
                  <li className="c-certificationkeys_searchForm_modelInfo_kana">
                    <TextInput
                      name="groupNamekana"
                      placeholder="団体名(かな)"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
          <li className="l-col_24">
            <ul className="l-content">
              <li className="c-dataLabel c-label_line">
                <label>プラン</label>
              </li>
              <li className="c-dataValue">
                <Checkboxes
                  name="partnerPlans"
                  choices={partnerPlans}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
);

export default TabPartner;
