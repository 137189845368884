import React, { FC } from 'react';
import styles from './IndexPageLayout.module.scss';
import { RiLink } from 'react-icons/ri';
import { kanriUrl, ownerUrl } from '@/ts/url';
import { TMenuTitlesResponse } from '@/components/pages/Events/Show/types';
import {
  dateFormatHyphen,
  dateTimeFormatWithoutSeconds,
} from '@/ts/formatTools';
import { LinkText } from '@/components/shared/LinkText';
import { EventSubMenu } from '@/components/pages/Events/Show/ShowPageLayout/EventSubMenu';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const SideMenu: FC<{
  response: TMenuTitlesResponse;
}> = ({ response }) => {
  const isEventSummaryTab = useLocation().pathname.endsWith(
    `/events/${response.eventId}`
  );
  return (
    <div className={styles.sideMenu}>
      <div className={styles.sideMenuEvent}>
        <dl>
          <dt>撮影日時</dt>
          <dd>
            {dayjs(response.photographingDay).format('YYYY/MM/DD（ddd）')}
            {response.photographingStartTime !== null &&
            response.photographingEndTime !== null
              ? `${dayjs(response.photographingStartTime).format(
                  'HH:mm'
                )}〜${dayjs(response.photographingEndTime).format('HH:mm')}`
              : ''}
          </dd>
        </dl>
        <dl>
          <dt>団体</dt>
          <dd>{`${response.societyId} / ${response.societyName}${
            !isEventSummaryTab && response.societyTelephoneNumber
              ? `（TEL：${response.societyTelephoneNumber}）`
              : ''
          }`}</dd>
        </dl>
        <dl>
          <dt>イベント</dt>
          <dd>{`${response.eventId} / ${response.eventName}`}</dd>
        </dl>
        <dl>
          <dt>イベント種類</dt>
          <dd>{response.eventTypeName}</dd>
        </dl>
        {!isEventSummaryTab && (
          <>
            <dl>
              <dt>撮影カメラマン</dt>
              <dd>
                {response.photographers.map((photographer, index) => (
                  <div key={index}>
                    ・{photographer.name}
                    {photographer.telephoneNumber
                      ? `（TEL：${photographer.telephoneNumber}）`
                      : ''}
                  </div>
                ))}
              </dd>
            </dl>
            <dl>
              <dt>担当営業</dt>
              <dd>{response.salesOwnerName}</dd>
            </dl>
            <dl>
              <dt>団体詳細入力者</dt>
              <dd>
                {response.requesterName && response.requestedAt
                  ? `${response.requesterName}(${dateTimeFormatWithoutSeconds(
                      response.requestedAt
                    )})`
                  : response.firstRegisteredFromSenAt &&
                    `登録不可(${dateTimeFormatWithoutSeconds(
                      response.firstRegisteredFromSenAt
                    )})`}
              </dd>
            </dl>
          </>
        )}
        {isEventSummaryTab && (
          <dl>
            <dt>申請オプション</dt>
            <dd className={styles.requestOptionContainer}>
              {response.requestOptionNames.map((option, index) => (
                <div key={index} className={styles.requestOption}>
                  {option}
                </div>
              ))}
            </dd>
          </dl>
        )}
      </div>
      {!isEventSummaryTab && (
        <div className={styles.sideMenuLinkGroup}>
          <p className={styles.sideMenuTitle}>
            <RiLink />
            その他関連
          </p>
          <div>
            {response.societyId !== null && (
              <a
                className="c-textlink"
                href={kanriUrl({
                  action_owner_SHINSEItehailist2: 'true',
                  society_id: response.societyId.toString(),
                  photographingday_from:
                    dateFormatHyphen(response.photographingDay) ?? '',
                })}
              >
                手配リスト
              </a>
            )}
            <br />
            {response.hasEventRequest && (
              <>
                {response.societyId !== null && (
                  <a
                    className="c-textlink"
                    href={ownerUrl('events/requests', {
                      society_id: response.societyId.toString(),
                      photographing_day_from: '',
                    })}
                  >
                    申請リスト
                  </a>
                )}
                <br />
                {response.societyId !== null && (
                  <a
                    className="c-textlink"
                    href={ownerUrl('events/change_requests', {
                      photographing_day_from: '',
                      society_id: response.societyId.toString(),
                      relation: 'changes',
                    })}
                  >
                    イベント変更申請リスト
                  </a>
                )}
                <br />
              </>
            )}
            <LinkText to={`/events?societyIds[]=${response.societyId}`}>
              イベント一覧
            </LinkText>
          </div>
        </div>
      )}
      {isEventSummaryTab && <EventSubMenu response={response} />}
    </div>
  );
};

export const ShowPageLayout: FC<{ response: TMenuTitlesResponse }> = ({
  children,
  response,
}) => {
  return (
    <div className="l-flex l-col_wrap">
      <div className="l-col_18">{children}</div>
      <div className="l-col_6">
        <SideMenu response={response} />
      </div>
    </div>
  );
};
