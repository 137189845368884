import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react-select';

import * as constants from '../constants';
import StatusLabel from '../../../shared/StatusLabel/App';
import { TGroup } from './types';
import {
  getEventCreateUrl,
  getNewsUrl,
  getOrdersUrl,
  getPricesUrl,
  getUsersUrl,
} from '../../../../ts/legacyUrl';
import { kanriUrl } from '../../../../ts/url';

type TPopUpMenuProps = {
  groupID: number;
  isOpen: boolean;
  onClickClose: MouseEventHandler;
};
const PopUpMenu: React.FC<TPopUpMenuProps> = ({
  groupID,
  isOpen,
  onClickClose,
}) => {
  if (!isOpen) {
    return null;
  }
  // 3ヶ月前の日
  const date3MonthAgo = new Date();
  date3MonthAgo.setMonth(date3MonthAgo.getMonth() - 3);

  return (
    <div className="c-popup_details c-popup_submenuWrap">
      <ul className="c-popup_submenuList">
        <li className="c-popup_submenuList_item">
          <a className="c-textlink" href={getEventCreateUrl(groupID)}>
            イベント登録
          </a>
        </li>
        <li className="c-popup_submenuList_item">
          <Link className="c-textlink" to={`/events?societyIds[]=${groupID}`}>
            イベント一覧
          </Link>
        </li>
        <li className="c-popup_submenuList_item">
          <a className="c-textlink" href={getPricesUrl(groupID)}>
            価格
          </a>
        </li>
        <li className="c-popup_submenuList_item">
          <a className="c-textlink" href={getNewsUrl(groupID)}>
            お知らせ
          </a>
        </li>
        <li className="c-popup_submenuList_item">
          <a className="c-textlink" href={getUsersUrl(groupID)}>
            会員
          </a>
        </li>
        <li className="c-popup_submenuList_item">
          <a className="c-textlink" href={getOrdersUrl(groupID, date3MonthAgo)}>
            注文(直近3ヶ月)
          </a>
        </li>
      </ul>
      <p className="c-popup_closeBtn" onClick={onClickClose}>
        <i className="c-icon_small c-icon_close"></i>閉じる
      </p>
    </div>
  );
};

type TProps = { group: TGroup };
const SearchResultItem: React.FC<TProps> = ({ group }) => {
  const [isOpenPopUpMenu, setIsOpenPopUpMenu] = useState(false);
  const togglePopUpMenu: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsOpenPopUpMenu(!isOpenPopUpMenu);
  };

  return (
    <tr>
      <td className="c-indexList_column_m">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
            {group.partnerId}
          </div>
        </div>
        <Link className="c-textlink" to={`/partners/${group.partnerId}`}>
          {group.partnerName}
        </Link>
      </td>
      <td className="c-indexList_column_l">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
            {group.id}
          </div>
        </div>
        <a
          className="c-textlink"
          href={kanriUrl({
            action_owner_GROUPsummary: 'true',
            groupsno: String(group.id),
          })}
        >
          {group.name}
        </a>
      </td>
      <td className="c-indexList_column_s">{group.planName}</td>
      <td className="c-indexList_column_l">
        {'〒'}
        {group.postcode}
        <br />
        {`${group.prefectureName}${group.city}${group.address}`}
      </td>
      <td className="c-indexList_column_m">{group.salesDepartmentOwnerName}</td>
      <td className="c-indexList_column_m">{group.photographerName}</td>
      <td className="c-indexList_column__nowrap">
        <StatusLabel
          isPositive={group.status === constants.STATUS_ACTIVE}
          statusName={group.statusName}
        />
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.authenticationTypeName}
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.isRequiredPreConfirmationName}
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.isEnabledPublishingPeriodsName}
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.isEnabledOutOfPeriodSalesName}
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.deliveryTypeName}
      </td>
      <td className="c-indexList_column_xs c-indexList_column__nowrap">
        {group.isEnabledDisplayPartnerName}
      </td>
      <td className="c-indexList_column_xs">
        <a
          className="c-submenu_rectangle c-btn_drawer"
          onClick={togglePopUpMenu}
          href="/"
        >
          <i className="c-icon c-icon__xsmall c-icon_leader"></i>
        </a>
        <PopUpMenu
          groupID={group.id}
          isOpen={isOpenPopUpMenu}
          onClickClose={togglePopUpMenu}
        />
      </td>
    </tr>
  );
};

export default SearchResultItem;
