import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TIndexResponse, TEventRequestPhotographer } from '../types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { useLocation } from 'react-router-dom';
import Paginator from '@/components/shared/Paginator/App';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import { Item } from './Item';
import { STATUS_APPROVED } from '@/ts/photographerPayments/constants';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { TFormInputs } from '../types';
import { EVENT_ASSIGN_STATUS } from '../../constants';
import { TMutate } from '@/ts/useJsonApi';
import { RegisterSpecialFeesModal } from './RegisterSpecialFeesModal';
import { errorToast } from '@/ts/toast';

export const Results: FC<{
  response: TIndexResponse;
  onEditSubmit: (ids: number[]) => void;
  mutate: TMutate<TIndexResponse>;
}> = ({ response, onEditSubmit, mutate }) => {
  const location = useLocation();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const methods = useForm<TFormInputs>({});
  const [bulkRegisterModalOpened, setBulkRegisterModalOpened] = useState(false);

  const isDisplayCheckBox = (
    eventRequestPhotographer: TEventRequestPhotographer
  ) =>
    eventRequestPhotographer.paymentStatus === STATUS_APPROVED &&
    eventRequestPhotographer.isEventCanceled === false &&
    eventRequestPhotographer.eventAssignStatus !== EVENT_ASSIGN_STATUS.DECLINED;

  const checkableIds = response.eventRequestPhotographers
    .filter((eventRequestPhotographer) =>
      isDisplayCheckBox(eventRequestPhotographer)
    )
    .map((eventRequestPhotographer) => eventRequestPhotographer.id);

  if (response.eventRequestPhotographers.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }

  const handleBulkRegisterModalOpen = () => {
    return () => {
      const isRegisteredPayments = response.eventRequestPhotographers.filter(
        (eventRequestPhotographer) =>
          eventRequestPhotographer.isRegisteredPayments &&
          checkedIds.includes(eventRequestPhotographer.id)
      );

      if (isRegisteredPayments.length > 0) {
        const errorMessages = isRegisteredPayments.map((registered) => (
          <p>{`${registered.eventName} ${registered.eventId} / ${registered.photographerSei} ${registered.photographerMei} / ${registered.societyName}`}</p>
        ));
        errorToast(
          <>
            <p>
              手動登録済みの注文金が含まれているため登録できません。
              <br />
            </p>
            {errorMessages}
          </>
        );
        return;
      }

      setBulkRegisterModalOpened(true);
    };
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="c-pagination_upper u-mgb_s">
            <Paginator
              pagination={response.pagination}
              currentPath={location.pathname}
              queryString={location.search}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {checkableIds.length > 0 && (
                    <SmallCheckBox
                      checked={checkedIds.length === checkableIds.length}
                      setChecked={(checked) =>
                        checked
                          ? setCheckedIds(checkableIds)
                          : setCheckedIds([])
                      }
                    />
                  )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell>イベント</TableCell>
                <TableCell>カメラマン</TableCell>
                <TableCell>団体</TableCell>
                <TableCell>カメラマン組織</TableCell>
                <TableCell>拘束時間</TableCell>
                <TableCell>撮影日</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>
                  合計金額
                  <br />
                  (税込)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.eventRequestPhotographers.map(
                (eventRequestPhotographer) => (
                  <Item
                    key={eventRequestPhotographer.id}
                    eventRequestPhotographer={eventRequestPhotographer}
                    validator={response.validator}
                    checked={checkedIds.includes(eventRequestPhotographer.id)}
                    setChecked={(checked) =>
                      checked
                        ? setCheckedIds([
                            ...checkedIds,
                            eventRequestPhotographer.id,
                          ])
                        : setCheckedIds(
                            checkedIds.filter(
                              (id) => id !== eventRequestPhotographer.id
                            )
                          )
                    }
                    isDisplayCheckBox={isDisplayCheckBox}
                  />
                )
              )}
            </TableBody>
          </Table>
          <Paginator
            pagination={response.pagination}
            currentPath={location.pathname}
            queryString={location.search}
          />
          <ButtonsFooter>
            <button
              className={`c-btn_large c-btn_primary u-pdt_s u-pdb_s ${
                checkedIds.length > 0 ? '' : 'is-disabled'
              }`}
              onClick={() => onEditSubmit(checkedIds)}
              disabled={checkedIds.length === 0}
            >
              個々に登録
            </button>
            <button
              className={`c-btn_large c-btn_edit u-pdt_s u-pdb_s ${
                checkedIds.length > 0 ? '' : 'is-disabled'
              }`}
              type="button"
              onClick={handleBulkRegisterModalOpen()}
              disabled={checkedIds.length === 0}
            >
              特別報酬一括登録
            </button>
          </ButtonsFooter>
        </form>
      </FormProvider>
      {bulkRegisterModalOpened && (
        <RegisterSpecialFeesModal
          closeModal={() => setBulkRegisterModalOpened(false)}
          checkedIds={checkedIds}
          bulkSaveValidator={response.validator}
          constants={response.constants}
          setCheckedIds={setCheckedIds}
          mutate={mutate}
        />
      )}
    </>
  );
};
