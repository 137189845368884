import { FC } from 'react';
import {} from '@/components/shared/Form/Inputs';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import { TPhotographerPayment } from '../../../types';
import { numberFormat } from '@/ts/formatTools';

export const Payments: FC<{
  payments: TPhotographerPayment[];
  isGreyOut: boolean;
}> = ({ payments, isGreyOut }) => {
  return (
    <InnerTable>
      <InnerTableHead>
        <InnerTableRow>
          <InnerTableCell>詳細区分</InnerTableCell>
          <InnerTableCell>税率</InnerTableCell>
          <InnerTableCell>源泉徴収</InnerTableCell>
          <InnerTableCell>金額(税込)</InnerTableCell>
          <InnerTableCell>ステータス</InnerTableCell>
          <InnerTableCell>補足(明細には表示されません)</InnerTableCell>
        </InnerTableRow>
      </InnerTableHead>
      <InnerTableBody>
        {payments.map((payment, index) => (
          <InnerTableRow key={index} disabled={isGreyOut}>
            <InnerTableCell>{payment.requestFeeName}</InnerTableCell>
            <InnerTableCell>
              {payment.consumptionTaxPercent > 0
                ? `課税${payment.consumptionTaxPercent}%`
                : '非課税'}
            </InnerTableCell>
            <InnerTableCell>
              {payment.hasWithholdingTax ? '必要' : '不要'}
            </InnerTableCell>
            <InnerTableCell>{numberFormat(payment.price)}</InnerTableCell>
            <InnerTableCell>
              {payment.isAutoCalculated ? '自動算出' : '手動算出'}
            </InnerTableCell>
            <InnerTableCell>{payment.externalMemo}</InnerTableCell>
          </InnerTableRow>
        ))}
      </InnerTableBody>
    </InnerTable>
  );
};
