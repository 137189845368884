import React from 'react';
import { Link } from 'react-router-dom';
import { THiddenPhotograph } from './types';

const Img: React.FC<{ src: string; alt: string }> = React.memo(
  ({ src, alt }) => <img src={src} alt={alt} />
);

const HiddenPhotograph: React.FC<THiddenPhotograph & { eventId: number }> =
  React.memo(({ eventId, ...photo }) => (
    <tr>
      <td className="c-indexList_column_m">
        <Link
          className="c-textlink"
          to={`/events/${eventId}/photographs?categoryno=${photo.categoryId}`}
        >
          {photo.parentCategoryName ? `${photo.parentCategoryName} - ` : ''}
          {photo.categoryName}
        </Link>
      </td>
      <td className="c-indexList_column_s">
        <Link
          className="c-textlink"
          to={`/photographs/${photo.id}`}
          target="_blank"
        >
          <Img src={photo.thumbnailUrl} alt={photo.name} />
        </Link>
      </td>
      <td className="c-indexList_column_s u-align_right">{photo.id}</td>
      <td className="c-indexList_column_m u-align_right">{photo.name}</td>
      <td className="c-indexList_column_s u-align_right">
        {photo.photographerId}
      </td>
      <td className="c-indexList_column_xs u-align_center">
        {photo.isDeletedByLeader ? 'D' : ''}
      </td>
      <td className="c-indexList_column_xs u-align_center">
        {photo.isFailedToConvert ? 'E' : ''}
      </td>
      <td className="c-indexList_column_l">
        {photo.hiddenReasons.map((reason) => (
          <p key={reason}>{reason}</p>
        ))}
      </td>
    </tr>
  ));

export default HiddenPhotograph;
