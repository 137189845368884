import { FC, useState } from 'react';
import styles from './Results.module.scss';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiExternalLinkFill,
} from 'react-icons/ri';
import {
  NumberInput,
  SingleSelect,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import clsx from 'clsx';
import {
  EventRequestPhotographer,
  IndexResponse,
  TEditFormInputs,
} from '../types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError, postJson, putJson, toMessages } from '@/ts/useApi';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import Paginator from '@/components/shared/Paginator/App';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { Link, useLocation } from 'react-router-dom';
import {
  dateFormatFromISO8601,
  dateFormatHyphenFromISO8601,
  numberFormat,
} from '@/ts/formatTools';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
} from '@/ts/photographerPayments/constants';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { kanriUrl } from '@/ts/url';
import dayjs from 'dayjs';
import {
  CANCELED as TRANSPORTATION_CANCELED,
  CLOSED as TRANSPORTATION_CLOSED,
} from '@/ts/photographerPayments/transportation_expenses_constants';
import { ONEWAY } from '@/ts/photographerPayments/payment_transportation_ways';
import {
  CAR,
  TRAIN,
} from '@/ts/photographerPayments/payment_transportation_types';
import { TMutate } from '@/ts/useJsonApi';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';
import { useInputPlaceFromTransportationType } from './useInputPlaceFromTransportationType';
import { useInputPriceFromTransportationDistance } from './useInputPriceFromTransportationDistance';
import { useChangeTransportationLinkFromPlace } from './useChangeTransportationLinkFromPlace';
import { useInputPriceFromTransportationWay } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/Results/useInputPriceFromTransportationWay';
import { onBlurChangePriceFromPlace } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/Results/onBlurChangePriceFromPlace';
import { getTransportationPaymentPrice } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/Results/onBlurChangePriceFromPlace/onBlurChangePriceFromPlace';
import { useInputPriceFromTransportationType } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/Results/useInputPriceFromTransportationType';

type RowStatus = 'closed' | 'opened' | 'editing';

const ResultDetail: FC<{
  isEditing: boolean;
  eventRequestPhotographer: EventRequestPhotographer;
  formItems: IndexResponse['formItems'];
  editValidator: TValidator;
  handleClose: () => void;
  handleEndEdit: () => void;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  eventRequestPhotographer,
  formItems,
  isEditing,
  handleClose,
  handleEndEdit,
  editValidator,
  setIsRegistered,
}) => {
  const [validator, setValidator] = useState<TValidator>(editValidator);

  const defaultValues = eventRequestPhotographer.photographerPayments.map(
    (payment) => {
      return {
        paymentId: payment.paymentId,
        paymentStatus: payment.paymentStatus,
        paymentTransportationType: payment.paymentTransportationType,
        paymentTransportationTypeName: payment.paymentTransportationTypeName,
        paymentTransportationWay: payment.paymentTransportationWay,
        paymentTransportationDistance: payment.paymentTransportationDistance,
        paymentTransportationPhotographerPlace:
          payment.paymentTransportationPhotographerPlace,
        paymentTransportationEventPlace:
          payment.paymentTransportationEventPlace,
        consumptionTaxPercent: payment.consumptionTaxPercent,
        hasWithholdingTax: payment.hasWithholdingTax,
        paymentPrice: payment.paymentPrice,
        externalMemo: payment.externalMemo,
      };
    }
  );

  const methods = useForm<TEditFormInputs>({
    defaultValues: {
      photographerPayments: defaultValues,
    },
  });

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'photographerPayments',
  });

  const addExpensesForm = async () => {
    const index = fields.length;
    append({
      paymentId: 0,
      paymentStatus: STATUS_APPROVED,
      paymentTransportationType: TRAIN,
      paymentTransportationTypeName: '',
      paymentTransportationWay: ONEWAY,
      paymentTransportationDistance: null,
      paymentTransportationPhotographerPlace:
        eventRequestPhotographer.photographerStation,
      paymentTransportationEventPlace: eventRequestPhotographer.eventStation,
      consumptionTaxPercent: 10,
      hasWithholdingTax: 1,
      paymentPrice: undefined,
      externalMemo: '',
    });
    // paymentTransportationTypeは固定のため二行目以降も毎回同じ金額を補完すると消すのが面倒なため1行目のみ補完する
    if (index !== 0) {
      return;
    }
    const price = await getTransportationPaymentPrice(
      TRAIN,
      eventRequestPhotographer.photographerStation,
      eventRequestPhotographer.eventStation
    );
    if (price !== 0) {
      methods.setValue(
        `photographerPayments.${index}.paymentPrice`,
        Number(price)
      );
    }
  };

  const removeExpensesForm = (index: number) => {
    if (fields[index].paymentId !== 0) {
      errorToast(
        '登録済みの交通費明細は行削除できません。取り消しボタンを押してください。'
      );
      return;
    }
    if (window.confirm('追加した行を削除しますか？')) {
      remove(index);
    }
  };

  // 取り消し解除（eventrequestPhotographer単位）
  const handleClickBulkUndoCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
      return;
    }
    try {
      await putJson(
        `/api/photographer_payments/transportation_expenses/event_request_photographers/${eventRequestPhotographer.eventRequestPhotographerId}`
      );
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }

    successToast('取り消し解除しました。');
    setIsRegistered(true);
    handleEndEdit();
  });

  // 取り消し解除（payment単位）
  const handleUndoCancel = async (paymentId: number) => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
      return;
    }
    const undoCancelPaymentData =
      eventRequestPhotographer.photographerPayments.filter(function (payment) {
        return payment.paymentId === paymentId;
      });
    undoCancelPaymentData[0].paymentStatus = STATUS_APPROVED;
    try {
      await postJson(
        `/api/photographer_payments/transportation_expenses/event_request_photographers/${eventRequestPhotographer.eventRequestPhotographerId}`,
        { photographerPayments: undoCancelPaymentData }
      );
    } catch (e) {
      errorToast('取り消し解除に失敗しました');
      return;
    }
    successToast('取り消し解除しました');
    setIsRegistered(true);
    handleEndEdit();
  };

  // 取り消し（payment単位）
  const handleCancel = async (paymentId: number) => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    const cancelPaymentData =
      eventRequestPhotographer.photographerPayments.filter(function (payment) {
        return payment.paymentId === paymentId;
      });
    cancelPaymentData[0].paymentStatus = STATUS_CANCELED;
    try {
      await postJson(
        `/api/photographer_payments/transportation_expenses/event_request_photographers/${eventRequestPhotographer.eventRequestPhotographerId}`,
        { photographerPayments: cancelPaymentData }
      );
    } catch (e) {
      errorToast('取り消しに失敗しました');
      return;
    }
    successToast('取り消しました');
    setIsRegistered(true);
    handleEndEdit();
  };

  const handleSubmit = usePreventDuplicateCall(async (data) => {
    try {
      await postJson(
        `/api/photographer_payments/transportation_expenses/event_request_photographers/${eventRequestPhotographer.eventRequestPhotographerId}`,
        data
      );
    } catch (e) {
      if (isValidationError(e)) {
        errorToast(`入力に誤りがあります`);
        setValidator(e.jsonMessage.validator);
      } else {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
      return;
    }
    successToast('登録しました');
    setIsRegistered(true);
    handleEndEdit();
  });

  useInputPlaceFromTransportationType(methods, eventRequestPhotographer);
  useInputPriceFromTransportationDistance(
    methods,
    eventRequestPhotographer,
    formItems.perKmGasolinePrice
  );
  useInputPriceFromTransportationWay(methods, eventRequestPhotographer);
  useInputPriceFromTransportationType(methods, eventRequestPhotographer);
  const { jorudanLinkFrom, jorudanLinkTo, navitimeLinkFrom, navitimeLinkTo } =
    useChangeTransportationLinkFromPlace(methods, eventRequestPhotographer);

  return (
    <FormProvider {...methods}>
      <OpenedDetail>
        <form
          className={styles.detailForm}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow
                disabled={
                  eventRequestPhotographer.paymentTransportationStatus ===
                  TRANSPORTATION_CANCELED
                }
              >
                <InnerTableCell></InnerTableCell>
                <InnerTableCell>詳細区分</InnerTableCell>
                <InnerTableCell>往復片道</InnerTableCell>
                <InnerTableCell>距離 (km)</InnerTableCell>
                <InnerTableCell>カメラマン最寄り</InnerTableCell>
                <InnerTableCell>団体最寄り</InnerTableCell>
                <InnerTableCell>税率</InnerTableCell>
                <InnerTableCell>源泉徴収</InnerTableCell>
                <InnerTableCell>金額</InnerTableCell>
                <InnerTableCell>備考</InnerTableCell>
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              {fields.map((field, index) => (
                <InnerTableRow
                  key={index}
                  disabled={
                    field.paymentStatus === STATUS_CANCELED && !isEditing
                  }
                >
                  {isEditing ? (
                    <InnerTableCell>
                      <div className="u-align_right">
                        {field.paymentStatus === STATUS_CANCELED ? (
                          <button
                            type="button"
                            className="c-btn_large c-btn_delete u-mgb_s"
                            onClick={() => handleUndoCancel(field.paymentId)}
                          >
                            取り消し解除
                          </button>
                        ) : field.paymentStatus === STATUS_APPROVED &&
                          field.paymentId > 0 ? (
                          <button
                            type="button"
                            className="c-btn_large c-btn_cancel u-mgb_s"
                            onClick={() => handleCancel(field.paymentId)}
                          >
                            取り消し
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </InnerTableCell>
                  ) : (
                    <InnerTableCell></InnerTableCell>
                  )}
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <SingleSelect
                          name={`photographerPayments.${index}.paymentTransportationType`}
                          choices={formItems.transportationExpenseTypes}
                          validator={validator}
                        />
                      ) : field.paymentTransportationTypeName ? (
                        <>{field.paymentTransportationTypeName}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <SingleSelect
                          name={`photographerPayments.${index}.paymentTransportationWay`}
                          choices={formItems.paymentTransportationWays}
                          validator={validator}
                        />
                      ) : field.paymentTransportationWay ? (
                        <>{`${
                          formItems.paymentTransportationWays.find(
                            (way) => way.key === field.paymentTransportationWay
                          )?.value
                        }`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div className="u-align_right">
                      {methods.watch(
                        `photographerPayments.${index}.paymentTransportationType`
                      ) !== CAR ? (
                        ''
                      ) : isEditing &&
                        field.paymentStatus !== STATUS_CANCELED ? (
                        <TextInput
                          name={`photographerPayments.${index}.paymentTransportationDistance`}
                          validator={validator}
                        />
                      ) : field.paymentTransportationDistance ? (
                        <>
                          {`${numberFormat(
                            field.paymentTransportationDistance,
                            3
                          )}`}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <TextArea
                          name={`photographerPayments.${index}.paymentTransportationPhotographerPlace`}
                          validator={validator}
                          rows={5}
                          onBlur={async () =>
                            onBlurChangePriceFromPlace(methods, index)
                          }
                        />
                      ) : field.paymentTransportationPhotographerPlace ? (
                        <>{`${field.paymentTransportationPhotographerPlace}`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <TextArea
                          name={`photographerPayments.${index}.paymentTransportationEventPlace`}
                          validator={validator}
                          rows={5}
                          onBlur={async () =>
                            onBlurChangePriceFromPlace(methods, index)
                          }
                        />
                      ) : field.paymentTransportationEventPlace ? (
                        <>{`${field.paymentTransportationEventPlace}`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <SingleSelect
                          name={`photographerPayments.${index}.consumptionTaxPercent`}
                          choices={formItems.consumptionTaxPercent}
                          validator={validator}
                        />
                      ) : field.consumptionTaxPercent ? (
                        <>{`課税${field.consumptionTaxPercent}%`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <SingleSelect
                          name={`photographerPayments.${index}.hasWithholdingTax`}
                          choices={[
                            {
                              key: 1,
                              value: '必要',
                            },
                          ]}
                          validator={validator}
                        />
                      ) : field.hasWithholdingTax === 1 ? (
                        <>{'必要'}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div className="u-align_right">
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <NumberInput
                          name={`photographerPayments.${index}.paymentPrice`}
                          validator={validator}
                        />
                      ) : field.paymentPrice ? (
                        <>{`${numberFormat(field.paymentPrice)}`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                  <InnerTableCell>
                    <div>
                      {isEditing && field.paymentStatus !== STATUS_CANCELED ? (
                        <TextArea
                          name={`photographerPayments.${index}.externalMemo`}
                          validator={validator}
                        />
                      ) : field.externalMemo ? (
                        <>{`${field.externalMemo}`}</>
                      ) : (
                        ''
                      )}
                    </div>
                  </InnerTableCell>
                </InnerTableRow>
              ))}
            </InnerTableBody>
          </InnerTable>
          <div className={'u-mgt_s l-flex_between'}>
            <div>
              {isEditing && (
                <span
                  className="c-btn_small c-btn_primary u-mgl_s"
                  onClick={addExpensesForm}
                >
                  ＋追加
                </span>
              )}
              <span className="c-textlink u-mgl_m">
                <a
                  href={kanriUrl({
                    action_owner_SHOUSAIinput: 't',
                    eventno: eventRequestPhotographer.eventId.toString(),
                  })}
                  className="c-textlink"
                  rel="noreferrer"
                  target="_blank"
                >
                  詳細メール
                </a>
              </span>
              <RiExternalLinkFill></RiExternalLinkFill>
              <span className="c-textlink u-mgl_m">
                <a
                  href={`https://www.jorudan.co.jp/norikae/cgi/nori.cgi?eki1=${jorudanLinkFrom}&eki2=${jorudanLinkTo}&Dyyyymm=${dateFormatFromISO8601(
                    eventRequestPhotographer.photographingDay,
                    '-',
                    'YYYYMM'
                  )}&Ddd=${dateFormatFromISO8601(
                    eventRequestPhotographer.photographingDay,
                    '-',
                    'DD'
                  )}&Dhh=9&Dmn1=0&Dmn2=0`}
                  className="c-textlink"
                  rel="noreferrer"
                  target="_blank"
                >
                  ジョルダン
                </a>
              </span>
              <RiExternalLinkFill></RiExternalLinkFill>
              <span className="c-textlink u-mgl_m">
                <a
                  href={`https://www.navitime.co.jp/maps/routeResult?start={"name":"${navitimeLinkFrom}"}&goal={"name":"${navitimeLinkTo}"}&start-time=${dateFormatHyphenFromISO8601(
                    eventRequestPhotographer.photographingDay
                  )} 9:00&car=only.multi.turn&type=car&car-route=smic`}
                  className="c-textlink"
                  rel="noreferrer"
                  target="_blank"
                >
                  NAVITIME
                </a>
              </span>
              <RiExternalLinkFill></RiExternalLinkFill>
            </div>
            {isEditing && fields.length >= 1 && (
              <span
                className="c-btn_small c-btn_danger u-mgl_s"
                onClick={() => removeExpensesForm(fields.length - 1)}
              >
                ー削除
              </span>
            )}
          </div>
          <div className={styles.innerTableButtons}>
            {eventRequestPhotographer.paymentTransportationStatus ===
              TRANSPORTATION_CANCELED && (
              <button
                type="button"
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleClickBulkUndoCancel()}
              >
                取り消し解除
              </button>
            )}
            {isEditing && (
              <button
                type="submit"
                className="c-btn_large c-btn_Search u-mgb_s"
              >
                登録
              </button>
            )}
            <span className={styles.closeDetailLinkWrapper}>
              <span className={styles.closeDetailLink} onClick={handleClose}>
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                {isEditing ? 'キャンセル' : '閉じる'}
              </span>
            </span>
          </div>
        </form>
      </OpenedDetail>
    </FormProvider>
  );
};

const ResultRow: FC<{
  eventRequestPhotographer: EventRequestPhotographer;
  formItems: IndexResponse['formItems'];
  mutate: () => void;
  editValidator: TValidator;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({
  eventRequestPhotographer,
  formItems,
  mutate,
  editValidator,
  checked,
  setChecked,
}) => {
  const [status, setStatus] = useState<RowStatus>('closed');
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const opened = status !== 'closed';
  const handleClickEdit = () => {
    setStatus('editing');
  };

  return (
    <>
      <TableRow
        highlighted={checked}
        disabled={
          eventRequestPhotographer.paymentTransportationStatus ===
          TRANSPORTATION_CANCELED
        }
      >
        <TableCell>
          {!(
            eventRequestPhotographer.paymentTransportationStatus ===
              TRANSPORTATION_CANCELED ||
            eventRequestPhotographer.paymentTransportationStatus ===
              TRANSPORTATION_CLOSED
          ) && <SmallCheckBox checked={checked} setChecked={setChecked} />}
        </TableCell>
        <TableCell>
          <div className={styles.resultTableButtonsCell}>
            <span
              className={styles.arrow}
              onClick={() => setStatus(opened ? 'closed' : 'opened')}
            >
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
            {!(
              eventRequestPhotographer.paymentTransportationStatus ===
                TRANSPORTATION_CLOSED ||
              eventRequestPhotographer.paymentTransportationStatus ===
                TRANSPORTATION_CANCELED
            ) && (
              <button
                type="button"
                className={clsx('c-btn_rectangle', 'c-btn_edit', {
                  'is-disabled': status === 'editing' || isRegistered,
                })}
                onClick={handleClickEdit}
              >
                <i className="c-icon c-icon__xsmall c-icon_edit"></i>
              </button>
            )}
          </div>
        </TableCell>
        <TableCell>
          <Link
            to={`/photographers/${eventRequestPhotographer.photographerId}`}
            className="c-textlink"
            target="_blank"
          >
            {`${eventRequestPhotographer.photographerSei} ${eventRequestPhotographer.photographerMei}`}
          </Link>
        </TableCell>
        <TableCell>
          {eventRequestPhotographer.photographerOrganizationName}
        </TableCell>
        <TableCell>
          <Link
            className="c-textlink"
            to={`/events/${eventRequestPhotographer.eventId}`}
            target="_blank"
          >
            {`${eventRequestPhotographer.eventId} / ${eventRequestPhotographer.eventName}`}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/societies/${eventRequestPhotographer.societyId}`}
            className="c-textlink"
            target="_blank"
          >
            {eventRequestPhotographer.societyName}
          </Link>
        </TableCell>
        <TableCell>
          {dayjs(eventRequestPhotographer.photographingDay).format(
            'YYYY/MM/DD'
          )}
        </TableCell>
        <TableCell>
          {eventRequestPhotographer.paymentTransportationStatusName}
        </TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {numberFormat(eventRequestPhotographer.totalPaymentPrice)}
          </div>
        </TableCell>
      </TableRow>
      {opened && (
        <ResultDetail
          eventRequestPhotographer={eventRequestPhotographer}
          formItems={formItems}
          isEditing={status === 'editing'}
          editValidator={editValidator}
          handleClose={() => setStatus('closed')}
          handleEndEdit={() => {
            setStatus('closed');
            mutate();
          }}
          setIsRegistered={setIsRegistered}
        />
      )}
    </>
  );
};

export const Results: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
  editValidator: TValidator;
  queryParams: Record<string, unknown>;
}> = ({ response, mutate, editValidator, queryParams }) => {
  const location = useLocation();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  if (
    !response.data.eventRequestPhotographers ||
    response.data.eventRequestPhotographers.length === 0
  ) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }

  const checkableIds = response.data.eventRequestPhotographers
    .filter(
      (eventRequestPhotographer) =>
        !(
          eventRequestPhotographer.paymentTransportationStatus ===
            TRANSPORTATION_CANCELED ||
          eventRequestPhotographer.paymentTransportationStatus ===
            TRANSPORTATION_CLOSED
        )
    )
    .map(
      (eventRequestPhotographer) =>
        eventRequestPhotographer.eventRequestPhotographerId
    );

  const handleClickBulkCancel = async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    try {
      await putJson(
        `/api/photographer_payments/transportation_expenses/bulk_cancel`,
        {
          eventRequestPhotographerIds: checkedIds,
        }
      );
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消しました。');
    setCheckedIds([]);
    mutate(
      (response) =>
        response && {
          ...response,
          data: {
            ...response.data,
            eventRequestPhotographers:
              response.data.eventRequestPhotographers.map(
                (eventRequestPhotographer) => ({
                  ...eventRequestPhotographer,
                  ...(checkedIds.includes(
                    eventRequestPhotographer.eventRequestPhotographerId
                  )
                    ? {
                        paymentTransportationStatus: TRANSPORTATION_CANCELED,
                        paymentTransportationStatusName: '取り消し',
                        photographerPayments:
                          eventRequestPhotographer.photographerPayments.map(
                            (payment) => ({
                              ...payment,
                              paymentStatus: STATUS_CANCELED,
                            })
                          ),
                      }
                    : {}),
                })
              ),
          },
        }
    );
  };
  const paginatorQueryString =
    location.search +
    (location.search
      ? ''
      : `&photographingDayStartAt=${queryParams.photographingDayStartAt}&photographingDayEndAt=${queryParams.photographingDayEndAt}`);
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={paginatorQueryString}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {checkableIds.length > 0 ? (
                <SmallCheckBox
                  checked={checkedIds.length === checkableIds.length}
                  setChecked={(checked) =>
                    checked ? setCheckedIds(checkableIds) : setCheckedIds([])
                  }
                />
              ) : (
                <>　</>
              )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>団体</TableCell>
            <TableCell>撮影日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>合計金額（税込）</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.eventRequestPhotographers.map(
            (eventRequestPhotographer) => (
              <ResultRow
                key={eventRequestPhotographer.eventRequestPhotographerId}
                eventRequestPhotographer={eventRequestPhotographer}
                formItems={response.formItems}
                mutate={() => mutate(undefined, { keepCurrentData: true })}
                editValidator={editValidator}
                checked={checkedIds.includes(
                  eventRequestPhotographer.eventRequestPhotographerId
                )}
                setChecked={(checked) =>
                  checked
                    ? setCheckedIds([
                        ...checkedIds,
                        eventRequestPhotographer.eventRequestPhotographerId,
                      ])
                    : setCheckedIds(
                        checkedIds.filter(
                          (id) =>
                            id !==
                            eventRequestPhotographer.eventRequestPhotographerId
                        )
                      )
                }
              />
            )
          )}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={paginatorQueryString}
      />
      <ButtonsFooter>
        <button
          type="button"
          onClick={handleClickBulkCancel}
          className={clsx(
            'c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit',
            {
              'is-disabled': checkedIds.length === 0,
            }
          )}
          disabled={checkedIds.length === 0}
        >
          取り消し
        </button>
      </ButtonsFooter>
    </>
  );
};
