import React, { useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormItems, TFormInputs } from './types';
import {
  Checkboxes,
  GroupedMultiSelect,
  MultiSelect,
  NumberInput,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { ApiErrors } from '@/components/shared/Form/Errors';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    useEffect(() => {
      if (Object.keys(queryParams).length) {
        setIsSearchOpen(true);
      }
    }, [queryParams]);

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form
          method="GET"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <div className="t-bgBox_gray">
              <div className="t-bgBox_gray u-pdt_s u-pdb_s u-mgb_s">
                <div className="l-flex_between_center">
                  <b>検索条件</b>
                  <div
                    className="c-btn_toggleDetails"
                    onClick={() => {
                      setIsSearchOpen((b) => !b);
                    }}
                  >
                    <span className="u-spOff">
                      {isSearchOpen ? '開く' : '閉じる'}
                    </span>
                  </div>
                </div>
                <div
                  className={`c-accordion_content ${
                    isSearchOpen ? '' : ' is-open'
                  }`}
                >
                  <ul className="l-flex_between c-label_line is-sp_input u-mgt_m">
                    <li className="c-dataLabel">
                      <label>団体</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap has-gutter l-flex is-sp_mode u-pdr_xs u-pdl_xs">
                        <li className="l-col_8">
                          <NumberInput
                            name="societyId"
                            placeholder="団体ID"
                            validator={validator}
                          />
                        </li>
                        <li className="l-col_8">
                          <TextInput
                            name="societyName"
                            placeholder="団体名"
                            validator={validator}
                          />
                        </li>
                        <li className="l-col_8">
                          <TextInput
                            name="societyNameKana"
                            placeholder="団体名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel">
                      <label>担当営業</label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <MultiSelect
                        name="primarySales[]"
                        choices={formItems.saleses}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-dataLabel">
                      <label>詳細対応者</label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <MultiSelect
                        name="primaryPhotographer[]"
                        choices={formItems.photographers}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>電話番号</label>
                        </li>
                        <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-pdr_xs u-pdl_xs">
                          <div className="l-col_auto">
                            <TextInput
                              name="telephone"
                              placeholder="03-1234-5678"
                              validator={validator}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>FAX番号</label>
                        </li>
                        <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-pdr_xs u-pdl_xs">
                          <div className="l-col_auto">
                            <TextInput
                              name="fax"
                              placeholder="03-1234-5678"
                              validator={validator}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>メールアドレス</label>
                        </li>
                        <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-pdr_xs u-pdl_xs">
                          <div className="l-col_auto">
                            <TextInput
                              name="mailaddress"
                              placeholder="sample@sample.com"
                              validator={validator}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>都道府県</label>
                        </li>
                        <li className="c-dataValue u-pdr_xs u-pdl_xs">
                          <GroupedMultiSelect
                            name="prefecture"
                            choices={formItems.prefectures}
                            placeholder="クリックで候補表示（複数可）"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>
                            住所(市区町村
                            <br />
                            以下)
                          </label>
                        </li>
                        <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-pdr_xs u-pdl_xs">
                          <div className="l-col_auto">
                            <TextInput
                              name="address"
                              placeholder="住所(市区町村以下)"
                              validator={validator}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex_between c-label_line is-sp_input">
                        <li className="c-dataLabel">
                          <label>印刷会社</label>
                        </li>
                        <li className="c-dataValue u-pdr_xs u-pdl_xs">
                          <Checkboxes
                            name="printCompany"
                            choices={formItems.printCompany}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex_between c-label_line is-sp_input">
                        <li className="c-dataLabel">
                          <label>配送情報</label>
                        </li>
                        <li className="c-dataValue u-pdr_xs u-pdl_xs">
                          <Checkboxes
                            name="deliveryInfo"
                            choices={formItems.deliveryInfo}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex_between c-label_line is-sp_input">
                        <li className="c-dataLabel">
                          <label>契約状況</label>
                        </li>
                        <li className="c-dataValue u-pdr_xs u-pdl_xs">
                          <Checkboxes
                            name="contractStatus"
                            choices={formItems.contractStatus}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex_between c-label_line is-sp_input">
                        <li className="c-dataLabel">
                          <label>プラン</label>
                        </li>
                        <li className="c-dataValue u-pdr_xs u-pdl_xs">
                          <div className="t-bgBox_white">
                            <Checkboxes
                              name="plan"
                              choices={formItems.plans}
                              isInline={true}
                              validator={validator}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SearchForm;
