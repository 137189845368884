import dayjs from 'dayjs';
import { kanriUrl, ownerUrl, partnerUrl } from './url';

// for partner summary --

/**
 * 顧客
 */
const getCustomersUrl = (): string => {
  return ownerUrl('customers');
};

/**
 * 発票CSV（for 台湾）
 */
const getTaiwanInvoiceCsvUrl = (partnerID: number): string => {
  return kanriUrl({
    action_owner_PARTNERtwinvoice: 'true',
    agencyaccountno: partnerID.toString(),
  });
};

/**
 * 代理ログイン
 */
const getProxyLoginUrl = (userMailAddress: string, ownerID: number): string => {
  return partnerUrl({
    action_owner_Login: 'true',
    mailaddress: userMailAddress,
    partnerownerno: ownerID.toString(),
  });
};

/**
 * 管理者招待メール
 */
const getSendingInvitationMailUrl = (ownerID: number): string => {
  return kanriUrl({
    action_owner_OWNERACCOUNTInvitationMailinput: 'true',
    ownerno: ownerID.toString(),
  });
};

// for group list --

/**
 * イベント新規
 */
const getEventCreateUrl = (groupID: number): string => {
  return kanriUrl({
    action_Owner_EVENTnew: 'true',
    groupsno: groupID.toString(),
  });
};

/**
 * 価格
 */
const getPricesUrl = (groupID: number): string => {
  return kanriUrl({
    action_Owner_PRICE: 'true',
    groupsno: groupID.toString(),
  });
};

/**
 * お知らせ
 */
const getNewsUrl = (groupID: number): string => {
  return kanriUrl({
    action_Owner_NEWSlist: 'true',
    groupsno: groupID.toString(),
  });
};

/**
 * 会員
 */
const getUsersUrl = (groupID: number): string => {
  return ownerUrl('users', {
    groupsno: groupID.toString(),
  });
};

/**
 * 注文
 */
const getOrdersUrl = (groupID: number, createdDateFrom: Date): string => {
  const date = dayjs(createdDateFrom);
  return kanriUrl({
    action_Owner_ORDERlist: 'true',
    groupsno: groupID.toString(),
    createdyear1: date.format('YYYY'),
    createdmonthday1: date.format('MMDD'),
    search: 'default',
  });
};

// for society summary --

const getEventRequestsCreateBySocietyContractPlanUrl = (
  societyContractId: number,
  planId: number
): string =>
  ownerUrl('events/requests/create', {
    society_contract_id: societyContractId.toString(),
    plan_id: planId.toString(),
  });
const getEventRequestsCreateBySocietyContractPlanPreviousYearEventNoUrl = (
  societyContractId: number,
  planId: number,
  previousYearEventNo: number
): string =>
  ownerUrl('events/requests/create', {
    society_contract_id: societyContractId.toString(),
    plan_id: planId.toString(),
    'previousyeareventno[]': previousYearEventNo.toString(),
  });
const getEventRequestsCreateManyBySocietyContractPlanUrl = (
  societyContractId: number,
  planId: number
): string =>
  ownerUrl('events/requests/create_many', {
    society_contract_id: societyContractId.toString(),
    plan_id: planId.toString(),
  });

// -- for certificationkeys

/**
 * 会員
 */
const getUserSummaryUrl = (userID: number): string =>
  ownerUrl(`users/${userID}`);

/**
 * カメラマン
 */
const getPhotographerSummaryUrl = (photographerID: number): string => {
  return kanriUrl({
    cam: photographerID.toString(),
  });
};

export {
  getProxyLoginUrl,
  getSendingInvitationMailUrl,
  getCustomersUrl,
  getTaiwanInvoiceCsvUrl,
  getPricesUrl,
  getNewsUrl,
  getUsersUrl,
  getOrdersUrl,
  getEventCreateUrl,
  getEventRequestsCreateBySocietyContractPlanUrl,
  getEventRequestsCreateBySocietyContractPlanPreviousYearEventNoUrl,
  getEventRequestsCreateManyBySocietyContractPlanUrl,
  getUserSummaryUrl,
  getPhotographerSummaryUrl,
};
