import { FC, useMemo } from 'react';
import { IndexResponse, TFormInputs } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Checkboxes,
  DateInput,
  MultiSelect,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import Accordion from '@/components/shared/Accordion/App';

export const SearchForm: FC<{
  response: IndexResponse;
  onSubmit: (form: TFormInputs) => void;
  queryParams: Record<string, unknown>;
}> = ({ response, onSubmit, queryParams }) => {
  const methods = useForm<TFormInputs>({ defaultValues: queryParams });
  const validator: TValidator = response.validator;
  const photographerChoices = useMemo(
    () =>
      response.formItems.photographers.map((photographer) => ({
        key: photographer.id,
        value: `${photographer.id} / ${photographer.sei} ${photographer.mei}`,
      })),
    [response]
  );
  const photographerOrganizationChoices = useMemo(
    () =>
      response.formItems.photographerOrganizations.map(
        (photographerOrganization) => ({
          key: photographerOrganization.id,
          value: `${photographerOrganization.id} / ${photographerOrganization.name}`,
        })
      ),
    [response]
  );
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="c-searchForm">
          <div className="c-frame">
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerIds"
                      validator={validator}
                      choices={photographerChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerOrganizationIds"
                      validator={validator}
                      choices={photographerOrganizationChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">取引日</li>
                  <li className="c-dataValue">
                    <ul className="c-input_dateBlock">
                      <li>
                        <DateInput
                          name="tradingDateStartAt"
                          validator={validator}
                        />
                      </li>
                      <li>
                        <DateInput
                          name="tradingDateEndAt"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">ステータス</li>
                  <li className="c-dataValue">
                    <Checkboxes
                      name="statuses"
                      validator={validator}
                      choices={response.formItems.statuses}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <Accordion
              labelClose="詳細検索"
              labelOpen="詳細検索"
              isDisableScroll={true}
            >
              <ul className="l-flex_start l-col_wrap">
                <li className="l-col_24">
                  <ul className="l-flex">
                    <li className="c-dataLabel">詳細区分</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="types"
                        validator={validator}
                        choices={response.formItems.types}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </Accordion>
            <div className="l-flex_center_line">
              <button className="c-btn_large c-btn_Search c-input_submit">
                検索
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
