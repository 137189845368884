import { UseFormReturn } from 'react-hook-form';
import {
  EventRequestPhotographer,
  TEditFormInputs,
} from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';
import { useEffect, useState } from 'react';
import {
  BUS,
  CAR,
  HIGHWAY,
  TRAIN,
} from '@/ts/photographerPayments/payment_transportation_types';

type TTransportationLink = {
  jorudanLinkFrom: string;
  jorudanLinkTo: string;
  navitimeLinkFrom: string;
  navitimeLinkTo: string;
};

export const useChangeTransportationLinkFromPlace = (
  methods: UseFormReturn<TEditFormInputs>,
  eventRequestPhotographer: EventRequestPhotographer
): TTransportationLink => {
  const [jorudanLinkFrom, setJorudanLinkFrom] = useState<string>(
    eventRequestPhotographer.photographerStation ?? ''
  );
  const [jorudanLinkTo, setJorudanLinkTo] = useState<string>(
    eventRequestPhotographer.eventStation ?? ''
  );
  const [navitimeLinkFrom, setNavitimeLinkFrom] = useState<string>(
    eventRequestPhotographer.photographerAddress ?? ''
  );
  const [navitimeLinkTo, setNavitimeLinkTo] = useState<string>(
    eventRequestPhotographer.eventAddress ?? ''
  );
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (
        name === undefined ||
        type !== 'change' ||
        (!name.includes('paymentTransportationPhotographerPlace') &&
          !name.includes('paymentTransportationEventPlace') &&
          !name.includes('paymentTransportationType'))
      ) {
        return;
      }
      const index = Number(name.split('.')[1]);
      const paymentTransportationPhotographerPlace = methods.getValues(
        `photographerPayments.${index}.paymentTransportationPhotographerPlace`
      );
      const paymentTransportationType = methods.getValues(
        `photographerPayments.${index}.paymentTransportationType`
      );
      const paymentTransportationEventPlace = methods.getValues(
        `photographerPayments.${index}.paymentTransportationEventPlace`
      );
      if (
        paymentTransportationPhotographerPlace === null ||
        paymentTransportationEventPlace === null ||
        paymentTransportationType === null
      ) {
        return;
      }
      if (
        paymentTransportationType === CAR ||
        paymentTransportationType === HIGHWAY
      ) {
        setNavitimeLinkFrom(paymentTransportationPhotographerPlace);
        setNavitimeLinkTo(paymentTransportationEventPlace);
      }

      if (
        paymentTransportationType === TRAIN ||
        paymentTransportationType === BUS
      ) {
        setJorudanLinkFrom(paymentTransportationPhotographerPlace);
        setJorudanLinkTo(paymentTransportationEventPlace);
      }
    });

    return () => subscription.unsubscribe();
  }, [methods]);
  return {
    jorudanLinkFrom: jorudanLinkFrom,
    jorudanLinkTo: jorudanLinkTo,
    navitimeLinkFrom: navitimeLinkFrom,
    navitimeLinkTo: navitimeLinkTo,
  };
};
