import React from 'react';
import { OrderInfo, PhotoSummary as Summary } from '../../types';
import { numberFormat } from '@/ts/formatTools';

type Props = {
  orderInfo: OrderInfo;
  summary: Summary;
};
export const PhotoSummary = React.memo<Props>(({ orderInfo, summary }) => {
  return (
    <>
      <div className="l-flex_between_center u-mgb_s">
        <h4 className="c-section_title">写真枚数</h4>
      </div>
      <div className="u-mgt_s u-mgb_m">
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">合計</li>
              <li className="c-dataValue">{numberFormat(summary.count)}枚</li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">内訳</li>
              <li className="c-dataValue">
                {Object.keys(summary.countBySize).map((photoSum) => (
                  <div key={photoSum}>
                    {photoSum.toUpperCase()} {summary.countBySize[photoSum]}枚
                  </div>
                ))}
                <br />
                {Object.keys(summary.countBySizeOriginal).map((photoSum) => (
                  <span key={photoSum}>
                    {photoSum.toUpperCase()}{' '}
                    {summary.countBySizeOriginal[photoSum]}
                  </span>
                ))}
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">
                写真小計
                <br />
                (内手数料)
              </li>
              <li className="c-dataValue">
                {numberFormat(summary.totalAmount)}円
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">送料</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.carriage)}円
                {orderInfo.collectCarriage
                  ? '代引き手数料' + numberFormat(orderInfo.collectCarriage)
                  : ''}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
});
