import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import Paginator from '@/components/shared/Paginator/App';
import { makePathForSearchForm } from '@/ts/makePathForSearchForm';
import { toggleCheck, toggleCheckAll } from '../../../../ts/toggleCheckboxes';
import { TAB_DOUBLE } from '../../../shared/Certificationkeys/constants';
import {
  TCertificationkey,
  TFormInputs,
  TIndexResponse,
} from '../../../shared/Certificationkeys/types';
import { TYPES_GENERAL } from '../../../shared/Certificationkeys/constants';
import SearchForm from '../../../shared/Certificationkeys/SearchForm';
import SearchResultItem from '../../../shared/Certificationkeys/SearchResultItem';
import { TCheck } from '../../../../types';
import AddCertificationkeys from './AddCertificationkeys';
import RemoveCertificationkeyButton, {
  canRemoveCertificationkey,
} from './RemoveCertificationkeyButton';
import { useHistory } from 'react-router-dom';
import QueryString from 'query-string';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const initializeChecks = (
  bundledCertificationkeyIds: number[],
  certificationkeys: TCertificationkey[]
): TCheck[] =>
  certificationkeys.map((c) => {
    const disabled =
      !c.conditions.canAddToKeyBundles ||
      bundledCertificationkeyIds.includes(c.id);
    return {
      id: c.id,
      checked: false,
      disabled,
    };
  });

const CertificationkeyList: React.FC<{
  bundleId: number;
  certificationkeys: TCertificationkey[];
  checkAll: boolean;
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>;
  checks: TCheck[];
  setChecks: React.Dispatch<React.SetStateAction<TCheck[]>>;
  canRemoveCertificationkeys: boolean;
  refetch: () => void;
}> = ({
  bundleId,
  certificationkeys,
  checkAll,
  setCheckAll,
  checks,
  setChecks,
  canRemoveCertificationkeys,
  refetch,
}) => {
  const onChangeCheckAll: ChangeEventHandler = useCallback(() => {
    toggleCheckAll(checkAll, checks, setCheckAll, setChecks);
  }, [checkAll, checks, setCheckAll, setChecks]);
  const onChangeCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      toggleCheck(e.target, checks, setCheckAll, setChecks);
    },
    [checks, setCheckAll, setChecks]
  );

  return (
    <table className="c-indexList">
      <thead>
        <tr>
          <th className="u-align_center">
            {checks.some((c) => !c.disabled) ? (
              <label className="c-checkbox_listbox">
                <input
                  type="checkbox"
                  className="c-checkbox_list"
                  name="check-all"
                  value="all"
                  checked={checkAll}
                  onChange={onChangeCheckAll}
                />
                {/* NOTE: レイアウトの都合で必要な空のelement */}
                <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
              </label>
            ) : (
              '×'
            )}
          </th>
          <th>キー種類</th>
          <th>写真閲覧キー</th>
          <th>パートナー名</th>
          <th>団体名</th>
          <th>契約</th>
          <th>キー束</th>
          <th className="u-align_center">操作</th>
        </tr>
      </thead>
      <tbody>
        {certificationkeys.map((c) => {
          const check = checks.find((ch) => ch.id === c.id);
          return (
            <tr
              key={c.id}
              className={c.isActive ? '' : 'c-certificationkey__notActive'}
            >
              <td className="c-indexList_column_checkbox u-align_center">
                {check &&
                  (check.disabled ? (
                    '×'
                  ) : (
                    <label className="c-checkbox_listbox">
                      <input
                        type="checkbox"
                        className="c-checkbox_list"
                        name="checks[]"
                        value={check.id}
                        checked={check.checked}
                        onChange={onChangeCheck}
                      />
                      {/* NOTE: レイアウトの都合で必要な空のelement */}
                      <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
                    </label>
                  ))}
              </td>
              <SearchResultItem key={c.id} certificationkey={c} />
              <td className="c-indexList_column_m u-align_center">
                <RemoveCertificationkeyButton
                  canRemove={canRemoveCertificationkey(
                    canRemoveCertificationkeys,
                    bundleId,
                    c.bundleIds
                  )}
                  bundleId={bundleId}
                  certificationkeyId={c.id}
                  refetch={refetch}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Certificationkeys: React.FC<{
  canRemoveCertificationkeys: boolean;
  bundleId: number;
  bundledCertificationkeyIds: number[];
  queryParams: Record<string, unknown>;
  data: TIndexResponse;
  refetch: () => void;
}> = React.memo(
  ({
    canRemoveCertificationkeys,
    bundleId,
    bundledCertificationkeyIds,
    queryParams,
    data: { data: certificationkeys, pagination, formItems, validator },
    refetch,
  }) => {
    const history = useHistory();
    const [selectedType, setSelectedType] = useState<number>(
      queryParams.type ? Number(queryParams.type) : TAB_DOUBLE
    );

    const [checks, setChecks] = useState<TCheck[]>(
      initializeChecks(bundledCertificationkeyIds, certificationkeys)
    );
    const [checkAll, setCheckAll] = useState(false);

    const path = `/key_bundles/${bundleId}`;
    const queryString =
      '?' + QueryString.stringify(queryParams, { arrayFormat: 'bracket' });

    // NOTE: キー種類は年度・イベントのみ表示
    formItems.keytypes = formItems.keytypes.filter((c) =>
      TYPES_GENERAL.includes(c.key as number)
    );
    const onSubmit: SubmitHandler<TFormInputs> = useCallback(
      async (formData) => {
        const newPath = makePathForSearchForm(path, '', {
          ...formData,
          type: selectedType,
        });

        history.push(newPath);
      },
      [selectedType, path, history]
    );

    return (
      <div className="c-index l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          showBundles
        />

        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <AddCertificationkeys
                bundleId={bundleId}
                checks={checks}
                refetch={refetch}
              />
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath={path}
                  queryString={queryString}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !certificationkeys.length ? (
            <HasNoResult />
          ) : (
            <CertificationkeyList
              bundleId={bundleId}
              certificationkeys={certificationkeys}
              checks={checks}
              setChecks={setChecks}
              checkAll={checkAll}
              setCheckAll={setCheckAll}
              canRemoveCertificationkeys={canRemoveCertificationkeys}
              refetch={refetch}
            />
          )}
          <Paginator
            pagination={pagination}
            currentPath={path}
            queryString={queryString}
          />
        </div>
      </div>
    );
  }
);

export default Certificationkeys;
