import { renderError } from '@/ts/useApi';
import { useJsonApi } from '@/ts/useJsonApi';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { EditResponse } from './type';
import { Form } from './Form';
import queryString from 'query-string';

export const BulkEdit: FC = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  });

  const eventRequestPhotographerIds = queryParams.eventRequestPhotographerIds;
  const requestFeeType = queryParams.requestFeeType;

  const eventRequestPhotographerIdsQuery = Array.isArray(
    eventRequestPhotographerIds
  )
    ? eventRequestPhotographerIds
        .map((id) => `eventRequestPhotographerIds[]=${id}`)
        .join('&')
    : eventRequestPhotographerIds
    ? `eventRequestPhotographerIds[]=${eventRequestPhotographerIds}`
    : '';
  const requestFeeTypeQuery = requestFeeType
    ? `requestFeeType=${requestFeeType}`
    : '';

  const query = [eventRequestPhotographerIdsQuery, requestFeeTypeQuery]
    .filter(Boolean)
    .join('&');

  const { data, error } = useJsonApi<EditResponse>(
    '/api/photographer_payments/request_fees/bulk_edit?' + query
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  return (
    <Form
      eventRequestPhotographers={data.eventRequestPhotographers}
      constants={data.constants}
      initialValidator={data.validator}
      requestFeeType={Number(data.requestFeeType)}
    />
  );
};
