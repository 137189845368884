import React from 'react';
import { TableHeader } from './components/TableHeader';
import { TableBody } from './components/TableBody';

export type TableColumn = string | React.ReactElement;
export type TableRow = TableColumn[];

type Props = {
  columns: TableColumn[];
  rows: TableRow[];
  gridTemplateColumns?: string[];
  columnStyle?: React.CSSProperties;
};

export const Table = React.memo<Props>(function Table({
  columns,
  rows,
  gridTemplateColumns,
  columnStyle,
}) {
  const style = gridTemplateColumns
    ? gridTemplateColumns.join(' ')
    : columns.map((_) => 'minmax(80px, 1fr)').join(' ');

  return (
    <div>
      <TableHeader columns={columns} gridTemplateColumns={style} />
      <TableBody
        rows={rows}
        gridTemplateColumns={style}
        columnStyle={columnStyle}
      />
    </div>
  );
});
