import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import Paginator from '@/components/shared/Paginator/App';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';

import { TCheck } from '../../../../types';
import { toggleCheck, toggleCheckAll } from '../../../../ts/toggleCheckboxes';
import { TMergedUseraccount, TUseraccountsResponse } from '../types';
import { mergeUseraccounts } from '../mergeUseraccounts';
import Useraccount from '../Useraccount';
import SearchForm from './SearchForm';
import { TFormInputs } from './types';
import LinkToCertificationkey from './LinkToCertificationkey';
import RemoveButton from './RemoveButton';
import { isKeyTypeLeader } from '../../../shared/Certificationkeys/constants';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const List: React.FC<{
  id: number;
  type: number;
  mergedUseraccounts: TMergedUseraccount[];
  checkAll: boolean;
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>;
  checks: TCheck[];
  setChecks: React.Dispatch<React.SetStateAction<TCheck[]>>;
  refetch: () => void;
}> = React.memo(
  ({
    id,
    type,
    mergedUseraccounts,
    checkAll,
    setCheckAll,
    checks,
    setChecks,
    refetch,
  }) => {
    const onChangeCheckAll: ChangeEventHandler = useCallback(() => {
      toggleCheckAll(checkAll, checks, setCheckAll, setChecks);
    }, [checkAll, checks, setCheckAll, setChecks]);
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        toggleCheck(e.target, checks, setCheckAll, setChecks);
      },
      [checks, setCheckAll, setChecks]
    );
    return (
      <table className="c-indexList">
        <thead>
          <tr>
            <th className="u-align_center">
              {checks.some((c) => !c.disabled) ? (
                <label className="c-checkbox_listbox">
                  <input
                    type="checkbox"
                    className="c-checkbox_list"
                    name="check-all"
                    value="all"
                    checked={checkAll}
                    onChange={onChangeCheckAll}
                  />
                  {/* NOTE: レイアウトの都合で必要な空のelement */}
                  <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
                </label>
              ) : (
                '×'
              )}
            </th>
            <th>ID</th>
            <th>ユーザ名</th>
            <th>メールアドレス</th>
            <th>電話番号</th>
            <th>登録状況</th>
            <th>責任者承認状況</th>
            <th>責任者承認氏名</th>
            <th>生徒名</th>
            <th>生徒との関係</th>
            <th>クラス名</th>
            <th>キー登録日時</th>
            <th className="u-align_center">操作</th>
          </tr>
        </thead>
        <tbody>
          {mergedUseraccounts.map((u) => {
            const check = checks.find((ch) => ch.id === u.id);
            return (
              <tr key={u.id}>
                <td className="c-indexList_column_checkbox u-align_center">
                  {check &&
                    (check.disabled ? (
                      '×'
                    ) : (
                      <label className="c-checkbox_listbox">
                        <input
                          type="checkbox"
                          className="c-checkbox_list"
                          name="checks[]"
                          value={check.id}
                          checked={check.checked}
                          onChange={onChangeCheck}
                        />
                        {/* NOTE: レイアウトの都合で必要な空のelement */}
                        <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
                      </label>
                    ))}
                </td>
                <Useraccount mergedUseraccount={u} />
                <td className="c-indexList_column_m u-align_center">
                  <RemoveButton
                    isLeaderKey={isKeyTypeLeader(type)}
                    canRemove={u.conditions.canRemoveFromCertificationkey}
                    id={id}
                    useraccountId={u.id}
                    refetch={refetch}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);

const initializeChecks = (
  mergedUseraccounts: TMergedUseraccount[],
  certificationkeyCanLinkToUseraccounts: boolean
): TCheck[] =>
  mergedUseraccounts.map((u) => {
    const disabled =
      !certificationkeyCanLinkToUseraccounts ||
      !u.conditions.canLinkToCertificationkeys ||
      !!u.certificationkeyLinkedAt;
    return {
      id: u.id,
      checked: false,
      disabled,
    };
  });

const Useraccounts: React.FC<{
  id: number;
  type: number;
  isRequiredAcceptanceByLeader: boolean;
  canLinkToUsers: boolean;
  queryString: string;
  useraccountsData: TUseraccountsResponse;
  refetch: () => void;
}> = React.memo(
  ({
    id,
    type,
    isRequiredAcceptanceByLeader,
    canLinkToUsers,
    queryString,
    useraccountsData,
    refetch,
  }) => {
    const history = useHistory();

    const {
      data: { useraccounts, certificationkeyUseraccounts },
      pagination,
      validator,
      formItems,
    } = useraccountsData;
    const mergedUseraccounts = mergeUseraccounts(
      useraccounts,
      certificationkeyUseraccounts,
      isRequiredAcceptanceByLeader
    );
    const queryParams = convertQueryToFormData(queryString, validator);

    const [checks, setChecks] = useState<TCheck[]>(
      initializeChecks(mergedUseraccounts, canLinkToUsers)
    );
    const [checkAll, setCheckAll] = useState(false);

    const path = `/certificationkeys/${id}/useraccounts`;
    const onSubmit: SubmitHandler<TFormInputs> = useCallback(
      async (formData) => {
        const newPath = makePathForSearchForm(path, queryString, formData);

        history.push(newPath);
      },
      [path, history, queryString]
    );

    return (
      <div className="c-index l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <LinkToCertificationkey
                id={id}
                checks={checks}
                refetch={refetch}
              />
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath={path}
                  queryString={queryString}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !useraccounts.length ? (
            <HasNoResult />
          ) : (
            <List
              id={id}
              type={type}
              mergedUseraccounts={mergedUseraccounts}
              checks={checks}
              setChecks={setChecks}
              checkAll={checkAll}
              setCheckAll={setCheckAll}
              refetch={refetch}
            />
          )}
          <Paginator
            pagination={pagination}
            currentPath={path}
            queryString={queryString}
          />
        </div>
      </div>
    );
  }
);

export default Useraccounts;
