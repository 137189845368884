import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ApiErrors } from '@/components/shared/Form/Errors';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';
import Common from './TabCommon';
import Direct from './TabDirect';
import Partner from './TabPartner';
import Old from './TabOld';
import { TABS, TAB_DIRECT, TAB_PARTNER, TAB_OLD } from './constants';
import Help from '../Help/App';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
  setSelectedType: React.Dispatch<React.SetStateAction<number>>;
  selectedType: number;
  showBundles?: boolean;
};

const TabItem: React.FC<{
  selected: boolean;
  handleSelect: () => void;
}> = ({ children, handleSelect, selected }) => {
  return (
    <li
      className={`c-tabListItem${selected ? ' is-active' : ''}`}
      onClick={handleSelect}
    >
      {children}
    </li>
  );
};

const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({
    validator,
    formItems,
    queryParams,
    onSubmit,
    setSelectedType,
    selectedType,
    showBundles,
  }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    return (
      <div className="c-certificationkeys_searchForm c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="c-tabList">
                {TABS.map(({ type, title, description }) => (
                  <TabItem
                    selected={selectedType === type}
                    handleSelect={() => setSelectedType(type)}
                    key={type}
                  >
                    {title}
                    <Help>{description}</Help>
                  </TabItem>
                ))}
              </ul>
              <div className="u-mgt_s">
                <Common
                  validator={validator}
                  formItems={formItems}
                  showBundles={showBundles}
                />
                {selectedType === TAB_DIRECT ? (
                  <Direct validator={validator} formItems={formItems} />
                ) : selectedType === TAB_PARTNER ? (
                  <Partner validator={validator} formItems={formItems} />
                ) : selectedType === TAB_OLD ? (
                  <Old validator={validator} formItems={formItems} />
                ) : null}
              </div>

              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
