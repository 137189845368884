import React, { useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumbersInput,
  GroupedMultiSelect,
  Checkboxes,
} from '@/components/shared/Form/Inputs';
import { TFormInputs, TFormItems, TFormName } from '../types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: TFormName;
  onSubmit: SubmitHandler<TFormInputs>;
};

export const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const { prefectures } = formItems;

    useEffect(() => {
      Object.values(queryParams).forEach((value) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            setIsSearchOpen(true);
          } else if (!Array.isArray(value)) {
            setIsSearchOpen(true);
          }
        }
      });
    }, [queryParams]);

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form method="GET" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="t-bgBox_gray p-photographersIndex_searchForm">
            <div className="l-flex_between_center">
              <b>検索条件</b>
              <div
                className="c-btn_toggleDetails"
                onClick={() => {
                  setIsSearchOpen((b) => !b);
                }}
              >
                <span className="u-spOff">
                  {isSearchOpen ? '開く' : '閉じる'}
                </span>
              </div>
            </div>
            <div
              className={`p-photographerIndex_accordion_content ${
                isSearchOpen ? '' : 'is-open'
              }`}
            >
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>ID</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <NumbersInput
                        name="ids"
                        placeholder="(複数の場合、スペース区切りで入力)"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>氏名</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <TextInput
                        name="sei"
                        placeholder="姓（入力例：山田）"
                        validator={validator}
                      />
                    </li>
                    <li className="c-label_innerHalf">
                      <TextInput
                        name="mei"
                        placeholder="名（入力例：太郎）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>住所</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-dataValue">
                      <GroupedMultiSelect
                        name="prefectures"
                        choices={prefectures}
                        placeholder="都道府県  （複数選択可、クリックにて動作します）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label></label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <TextInput
                        name="city"
                        placeholder="市区町村（入力例：〇〇市××町）"
                        validator={validator}
                      />
                    </li>
                    <li className="c-label_innerHalf">
                      <TextInput
                        name="address"
                        placeholder="番地・建物（入力例：〇−〇 △△ビル△階）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>電話番号</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <TextInput
                        name="telephone"
                        placeholder="（入力例：080-1234-5678）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>稼動状況</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <Checkboxes
                        name="statuses"
                        choices={formItems.statuses}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>
                    カメラマン
                    <br />
                    組織ID
                  </label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <NumbersInput
                        name="photographerOrganizationIds"
                        placeholder="(複数の場合、スペース区切りで入力)"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                <li className="c-dataLabel">
                  <label>権限</label>
                </li>
                <li className="c-dataValue">
                  <ul className="l-flex_between">
                    <li className="c-label_innerHalf">
                      <Checkboxes
                        name="authorities"
                        choices={formItems.authorities}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
            <input
              className="c-btn_large c-btn_Search c-input_submit"
              type="submit"
              value="この内容で検索"
            />
          </div>
        </form>
      </FormProvider>
    );
  }
);
