import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import {
  ID_UNCONFIRMED,
  OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY,
  OTHER_EVENT_TYPE_ID_DEFAULT,
  OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE,
  OTHER_EVENT_TYPE_ID_PLAYING_SHOP,
} from '../../const';
import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Alert } from '@/components/shared/Alert';
import { ownerUrl } from '@/ts/url';
import { useParams } from 'react-router';
import { Default } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography/Default';
import { PlayingShop } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography/PlayingShop';
import { BirthdayParty } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography/BirthdayParty';
import { FarmingExperience } from '@/components/pages/Events/Show/Photography/PhotographyEvent/OtherPhotography/FarmingExperience';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

export const OtherPhotography: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, validator, constants }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'otherPhotographyTypeId' && type === 'change') {
        setValue('diggingMethodTypeId', ID_UNCONFIRMED);
        setValue('diggingMethodOtherDescription', '');
        setValue('hasBirthdayChildrenPhoto', ID_UNCONFIRMED);
        setValue('birthdayChildrenCount', null);
        setValue('buyersSellersRemark', '');
        setValue('shopCount', null);
        setValue('shopPhotographyVenue', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <>
      <PhotographyAccordion
        title={`${photography.eventTypeName}情報`}
        id={LIST_ITEMS.event.other.id}
      >
        <div className="t-textColor_sub">種類</div>
        <RadioBoxes
          name="otherPhotographyTypeId"
          validator={validator}
          choices={constants.otherPhotographyTypes}
        />
        <Alert type="error" title="その他の種類について">
          <p>
            デフォルトを選択すると、イベント種類「日常保育」と同等の情報入力が可能です。
            <br />
            芋掘りなどの畑での収穫活動は、遠足の場合も「畑体験」を選択してください。
            <br />
            始業式、終業式は「デフォルト」を選択してください。
            <br />
            卒業式予行はその他ではなく、イベント種類「卒業式」にイベント申請で変更して登録をしてください。
          </p>
          <a
            className="c-btn c-btn_middle_wide c-btn_black"
            href={ownerUrl(`events/requests/${eventId}`)}
            target="_blank"
            rel="noreferrer"
          >
            申請サマリ編集
          </a>
        </Alert>

        {watch('otherPhotographyTypeId') === OTHER_EVENT_TYPE_ID_DEFAULT && (
          <Default validator={validator} constants={constants} />
        )}

        {watch('otherPhotographyTypeId') ===
          OTHER_EVENT_TYPE_ID_FARMING_EXPERIENCE && (
          <FarmingExperience validator={validator} constants={constants} />
        )}

        {watch('otherPhotographyTypeId') ===
          OTHER_EVENT_TYPE_ID_BIRTHDAY_PARTY && (
          <BirthdayParty validator={validator} constants={constants} />
        )}

        {watch('otherPhotographyTypeId') ===
          OTHER_EVENT_TYPE_ID_PLAYING_SHOP && (
          <PlayingShop validator={validator} constants={constants} />
        )}
      </PhotographyAccordion>
    </>
  );
};
