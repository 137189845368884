import styles from './style.module.scss';
import clsx from 'clsx';

export const PhotographyAccordion: React.FC<{
  title: string;
  children: React.ReactNode;
  id?: string;
}> = ({ title, children, id }) => {
  return (
    <>
      <div className={clsx('c-section_subTitle_gry', styles.parent)} id={id}>
        <span className={styles.text}>{title}</span>
      </div>
      <div className={styles.body}>{children}</div>
    </>
  );
};
