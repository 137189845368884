import React from 'react';
import Help from '../../../../shared/Help/App';

export const TableCell: React.FC<
  React.PropsWithChildren<{
    title: string;
    helpText?: string;
  }>
> = ({ children, title, helpText }) => {
  return (
    <td className="l-col_24">
      <ul className="l-flex">
        <li className="c-dataLabel">
          {title}
          {helpText ? <Help>{helpText}</Help> : <></>}
        </li>
        <li className="c-dataValue u-pre_wrap">
          {children ? (
            children
          ) : (
            <p className={'c-no_data_text'}>設定されていません。</p>
          )}
        </li>
      </ul>
    </td>
  );
};
