import React, { useRef, useState } from 'react';
import { FieldErrors, useFormContext, Controller } from 'react-hook-form';
import Select, { MenuListComponentProps } from 'react-select';
import { FixedSizeList } from 'react-window';
import dayjs from 'dayjs';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';

import { TChoice, TGroupedChoice, TValidationRule, TValidator } from './types';
import { getRules } from './validation';
import { FormError, getApiErrorMessages, getError } from './Errors';
import clsx from 'clsx';

type TInputProps = {
  name: string;
  validator: TValidator;
  placeholder?: string;
  additionalClassName?: string;
  optionalValidationRule?: TValidationRule;
  onBlur?: (e: unknown) => void;
  isDisabled?: boolean;
};

const MemoedTextInput: React.FC<
  TInputProps & {
    forwardedRef: React.MutableRefObject<HTMLInputElement | null>;
  }
> = React.memo(
  ({
    validator,
    additionalClassName,
    optionalValidationRule,
    forwardedRef,
    ...props
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = getRules(props.name, validator, optionalValidationRule);
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = hasError
      ? 'c-input_plane is-error'
      : 'c-input_plane';
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );
    const { ref: reactHookRef, ...restRegisterParam } = register(
      props.name,
      options
    );

    return (
      <>
        <input
          type="text"
          className={classNameString}
          ref={(e) => {
            reactHookRef(e);
            if (forwardedRef) {
              forwardedRef.current = e;
            }
          }}
          {...props}
          {...restRegisterParam}
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

const TextInput: React.FC<TInputProps & React.RefAttributes<HTMLInputElement>> =
  React.forwardRef<HTMLInputElement, TInputProps>((props, ref) => (
    <MemoedTextInput
      forwardedRef={ref as React.MutableRefObject<HTMLInputElement | null>}
      {...props}
    />
  ));

type TTextArea = {
  name: string;
  validator: TValidator;
  additionalClassName?: string;
  optionalValidationRule?: TValidationRule;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea: React.FC<TTextArea> = React.memo(
  ({
    validator,
    additionalClassName,
    onBlur,
    optionalValidationRule,
    ...props
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = getRules(props.name, validator, optionalValidationRule);
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = hasError
      ? 'c-input_txtarea is-error'
      : 'c-input_txtarea';
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );

    return (
      <>
        <textarea
          className={classNameString}
          {...props}
          {...register(props.name, options)}
          onBlur={onBlur}
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

// FIXME: validation適用していない
type TNumbersProps = {
  name: string;
  validator: TValidator;
  placeholder?: string;
};
const NumbersInput: React.FC<TNumbersProps> = React.memo(
  ({ name, placeholder, validator }) => {
    const {
      control,
      getValues,
      formState: { errors },
    } = useFormContext();
    const rules = {
      validate: (a: unknown) => {
        return a === 'VALIDATION_FAILED'
          ? 'スペース区切りの数字で入力してください'
          : true;
      },
    };

    const className = getHasError(name, validator, errors)
      ? 'c-input_plane is-error'
      : 'c-input_plane';
    // 配列でデフォルト値を受け取る
    const formInputValue = getValues()[name];

    // UIでの表示は 「11 222」の形式、かつ、formでの値は[11,222]のような形にしたい
    // 初期値として設定する値はテキストに変換して渡す (配列のまま渡すと「,」区切りで連結した文字列に自動変換される)
    const defaultVal = Array.isArray(formInputValue)
      ? formInputValue.join(' ')
      : formInputValue;
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, ref } }) => (
            <input
              placeholder={placeholder}
              className={className}
              type="text"
              name={name}
              defaultValue={defaultVal}
              onChange={(e) =>
                onChange(
                  e.target.value
                    ? e.target.value.match(/^\d((\d|\s)*)+$/)
                      ? e.target.value.split(/\s+/)
                      : 'VALIDATION_FAILED'
                    : []
                )
              }
              ref={ref}
            />
          )}
        />
        <FormError name={name} errors={errors} validator={validator} />
      </>
    );
  }
);

type TNumberProps = TInputProps & {
  step?: number;
  unit?: string;
  allowNull?: boolean;
};
const NumberInput: React.FC<TNumberProps> = React.memo(
  ({
    validator,
    additionalClassName,
    optionalValidationRule,
    unit,
    isDisabled,
    allowNull,
    ...props
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = {
      ...getRules(props.name, validator, optionalValidationRule),
      // 数値型にしたい
      // cf: https://zenn.dev/yodaka/articles/e490a79bccd5e2
      ...(allowNull === true
        ? {
            setValueAs: (value: unknown) =>
              value == null || value === '' ? null : Number(value),
          }
        : { valueAsNumber: true }),
    };
    const hasError = getHasError(props.name, validator, errors);
    let baseClassNameString = clsx(
      'c-input_plane',
      hasError ? 'is-error' : undefined,
      isDisabled ? 'is-disabled' : undefined
    );
    baseClassNameString = unit
      ? `${baseClassNameString} c-input_withUnit`
      : baseClassNameString;
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );

    return (
      <>
        <input
          type="text"
          className={classNameString}
          {...props}
          {...register(props.name, options)}
          pattern="^-?[0-9]+$"
        />
        {unit !== undefined && <span>{unit}</span>}
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

const NumberLooseInput: React.FC<TNumberProps> = React.memo(
  ({
    validator,
    additionalClassName,
    optionalValidationRule,
    unit,
    ...props
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = {
      ...getRules(props.name, validator, optionalValidationRule),
      // 数値型にしたい
      valueAsNumber: true,
    };
    const hasError = getHasError(props.name, validator, errors);
    let baseClassNameString = hasError
      ? 'c-input_plane is-error'
      : 'c-input_plane';
    baseClassNameString = unit
      ? `${baseClassNameString} c-input_withUnit`
      : baseClassNameString;
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );

    return (
      <>
        <input
          type="text"
          className={classNameString}
          {...props}
          {...register(props.name, options)}
        />
        {unit !== undefined && <span>{unit}</span>}
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

const NullableNumberInput: React.FC<TNumberProps> = ({ ...props }) => (
  <NumberInput allowNull={true} {...props} />
);

interface TimeInputElement extends HTMLInputElement {
  showPicker: () => void;
}

const TimeInput: React.FC<TInputProps> = React.memo(
  ({ validator, additionalClassName, optionalValidationRule, ...props }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = {
      ...getRules(props.name, validator, optionalValidationRule),
    };
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = hasError
      ? 'c-input_plane c-input_time is-error'
      : 'c-input_plane c-input_time';
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );
    const ref = useRef<TimeInputElement>();
    const handleClick = () => ref.current?.showPicker();

    const { ref: hookFormRef, ...registerParam } = register(
      props.name,
      options
    );
    return (
      <>
        <input
          type="time"
          pattern="[0-9]{2}:[0-9]{2}"
          className={classNameString}
          {...props}
          {...registerParam}
          ref={(element) => {
            hookFormRef(element);
            ref.current = element as TimeInputElement;
          }}
          onClick={handleClick}
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

const DateInput: React.FC<TInputProps> = React.memo(
  ({ validator, additionalClassName, optionalValidationRule, ...props }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = {
      ...getRules(props.name, validator, optionalValidationRule),
      onBlur: props.onBlur,
    };
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = hasError
      ? 'c-input_plane c-input_date is-error'
      : 'c-input_plane c-input_date';
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );

    return (
      <>
        <input
          type="date"
          className={classNameString}
          {...props}
          {...register(props.name, options)}
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

registerLocale('ja', ja);
type TDatePickerProps = {
  name: string;
  validator: TValidator;
  additionalClassName?: string;
  optionalValidationRule?: TValidationRule;
  holidays?: { [date: string]: string };
  onBlur?: (date: string | null | undefined) => void;
  onClear?: () => void;
};
const convertToDate = (value: string | Date | null | undefined) =>
  value ? (typeof value === 'string' ? new Date(value) : value) : null;
const convertToString = (
  value: Date | string | null | undefined,
  spreader = '-'
) => (value ? dayjs(value).format(`YYYY${spreader}MM${spreader}DD`) : '');
const DatePicker: React.FC<TDatePickerProps> = React.memo(
  ({
    validator,
    optionalValidationRule,
    additionalClassName,
    holidays,
    onBlur,
    onClear,
    ...props
  }) => {
    const {
      register,
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useFormContext();
    const [inputDate, setInputDate] = useState<Date | null>(
      convertToDate(getValues(props.name))
    );
    const inputRef = useRef<ReactDatePicker | null>(null);
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = `c-input_datePicker${
      hasError ? ' is-error' : ''
    }`;
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );
    // TODO: holiday
    const highlightDates = [
      {
        'is-holiday': holidays
          ? Object.keys(holidays).map((dateString) => new Date(dateString))
          : [],
      },
    ];
    React.useEffect(() => {
      const subscription = watch((value, { name }) => {
        if (name !== props.name) {
          return;
        }
        let date = value[name];
        // formのname値がxxx.yyy.zzzの様な深い階層の場合に対応する
        if (typeof name === 'string' && name.indexOf('.')) {
          const names = name.split('.');
          names.forEach((hierarchy, index) => {
            value = value[hierarchy];
          });
          date = value;
        }
        // react-hook-formのsetValue()が呼ばれたときにdatePickerの値を更新する
        setInputDate(convertToDate(date));
      });
      return () => subscription.unsubscribe();
    }, [watch, props.name, inputDate]);

    const options = {
      ...getRules(props.name, validator, optionalValidationRule),
    };
    const input = register(props.name, options);

    // NOTE: 見た目はyyyy/mm/dd, postはyyyy-mm-ddで行うために実際にpostする値と操作用のinputを分けてます
    // NOTE: 受け取り側で yyyy/mm/dd のフォーマットを許容できるようになれば、input切り分けは不要です
    return (
      <div className="c-input_datePicker_wrapper">
        <input
          type="date"
          tabIndex={-1}
          className="c-input_datePicker_hidden"
          {...input}
          onFocus={() => {
            inputRef.current?.setFocus();
          }}
        />
        <ReactDatePicker
          locale="ja"
          ref={(e) => (inputRef.current = e)}
          onCalendarClose={() => {
            if (onBlur) {
              onBlur(convertToString(inputDate));
            }
          }}
          onChange={(date) => {
            const cleared = !date && !!inputDate;
            setInputDate(date);
            setValue(props.name, convertToString(date), { shouldDirty: true });
            if (cleared && onClear) {
              onClear();
            }
          }}
          className={classNameString}
          selected={inputDate}
          highlightDates={highlightDates}
          dateFormat="yyyy/MM/dd"
          dateFormatCalendar="yyyy年 MMM"
          placeholderText="年 / 月 / 日"
          showMonthDropdown
          showYearDropdown
          isClearable
          popperProps={{ strategy: 'fixed' }}
          todayButton="今日"
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </div>
    );
  }
);

const MonthInput: React.FC<TInputProps> = React.memo(
  ({ validator, additionalClassName, optionalValidationRule, ...props }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const options = getRules(props.name, validator, optionalValidationRule);
    const hasError = getHasError(props.name, validator, errors);
    const baseClassNameString = hasError
      ? 'c-input_plane c-input_date is-error'
      : 'c-input_plane c-input_date';
    const classNameString = getClassNameString(
      baseClassNameString,
      additionalClassName
    );

    return (
      <>
        <input
          type="month"
          className={classNameString}
          {...props}
          {...register(props.name, options)}
        />
        <FormError name={props.name} errors={errors} validator={validator} />
      </>
    );
  }
);

type TCheckBox = {
  name: string;
  validator: TValidator;
  label: string;
  isInline?: boolean;
};
const CheckBox: React.FC<TCheckBox> = React.memo(
  ({ name, validator, label, isInline }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const checkboxClassName = isInline ? 'c-checkbox_inline' : 'c-checkbox';
    const labelClassName = isInline
      ? 'c-label_checkbox_inline'
      : 'c-label_checkbox small';

    return (
      <>
        <label className="c-checkboxLabel">
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value: currentVal, ref } }) => (
              <input
                name={name}
                className={checkboxClassName}
                type="checkbox"
                onChange={(e) => onChange(e.target.checked ? true : false)}
                checked={currentVal}
                ref={ref}
              />
            )}
          />
          <span className={labelClassName}>{label}</span>
          <FormError name={name} errors={errors} validator={validator} />
        </label>
      </>
    );
  }
);

const SingleCheckBox: React.FC<TCheckBox> = React.memo(
  ({ name, validator, label, isInline }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const checkboxClassName = isInline ? 'c-checkbox_inline' : 'c-checkbox';
    const labelClassName = isInline
      ? 'c-label_checkbox_inline'
      : 'c-label_checkbox small';

    return (
      <>
        <label className="c-checkboxLabel">
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value: currentVal, ref } }) => (
              <input
                name={name}
                className={checkboxClassName}
                type="checkbox"
                onChange={(e) => onChange(e.target.checked ? true : false)}
                checked={currentVal === true ? true : false}
                ref={ref}
              />
            )}
          />
          <span className={labelClassName}>{label}</span>
          <FormError name={name} errors={errors} validator={validator} />
        </label>
      </>
    );
  }
);

type TChoicesProps = {
  name: string;
  validator: TValidator;
  choices: TMayDisabledChoice[];
  isInline?: boolean;
  optionalValidationRule?: TValidationRule;
};
const Checkboxes: React.FC<TChoicesProps> = React.memo(
  ({ name, validator, choices, isInline, optionalValidationRule }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    // ↓データ読み込みエラーなど
    if (!choices.length) {
      return <></>;
    }
    const options = {
      ...getRules(name, validator, optionalValidationRule),
      // 選択されてないfalseになって辛いので、arrayで返す
      setValueAs: (value: false | unknown[]) => (value ? value : []),
    };
    const checkboxClassName = isInline ? 'c-checkbox_inline' : 'c-checkbox';
    const labelClassName = isInline
      ? 'c-label_checkbox_inline'
      : 'c-label_checkbox small';

    return (
      <>
        {choices.map(({ key, value, isDisabled }) => {
          return (
            <label className="c-checkboxLabel" key={key}>
              <input
                className={checkboxClassName}
                type="checkbox"
                value={key}
                {...register(name, options)}
                disabled={isDisabled}
              />
              <span
                className={clsx(labelClassName, { 'is-disabled': isDisabled })}
              >
                {value}
              </span>
            </label>
          );
        })}
        <FormError name={name} errors={errors} validator={validator} />
      </>
    );
  }
);

type TMayDisabledChoice = TChoice & { isDisabled?: boolean };

type TRadioBoxesProps = {
  name: string;
  validator: TValidator;
  isInline?: boolean;
  isDisplayUnselected?: boolean;
  unselectedLabel?: string;
  choices?: TMayDisabledChoice[];
  optionalValidationRule?: TValidationRule;
  render?: (
    renderCheckbox: (choice: TChoice) => React.ReactElement
  ) => React.ReactElement;
};
const RadioBoxes: React.FC<TRadioBoxesProps> = React.memo(
  ({
    name,
    validator,
    isInline,
    isDisplayUnselected,
    unselectedLabel,
    optionalValidationRule,
    ...props
  }) => {
    const {
      formState: { errors },
    } = useFormContext();
    if (props.choices === undefined && props.render === undefined) {
      throw Error('choicesかrenderのいずれかを指定してください');
    }
    const radioBtnClassName = isInline
      ? 'c-label_radioBtn_inline'
      : 'c-label_radioBtn';

    return (
      <>
        <Controller
          name={name}
          defaultValue=""
          rules={getRules(name, validator, optionalValidationRule)}
          render={({ field: { onChange, value: currentValue, ref } }) => {
            const emptyRadioBox = isDisplayUnselected ? (
              <label className="c-radioLabel">
                <input
                  className="c-radioBtn"
                  type="radio"
                  value=""
                  name={name}
                  onChange={(e) => onChange('')}
                  checked={currentValue === ''}
                  ref={ref}
                />
                <span className={radioBtnClassName}>
                  {unselectedLabel ? unselectedLabel : '指定なし'}
                </span>
              </label>
            ) : null;
            const renderRadioBox: (
              choice: TMayDisabledChoice
            ) => React.ReactElement = ({ key, value, isDisabled }) => (
              <label className="c-radioLabel" key={key}>
                <input
                  className={`c-radioBtn ${
                    isDisabled === true ? 'is-disabled' : ''
                  }`}
                  type="radio"
                  value={key}
                  name={name}
                  onChange={(e) => onChange(key)}
                  checked={currentValue === key}
                  disabled={isDisabled || false}
                  ref={ref}
                />
                <span
                  className={`${radioBtnClassName} ${
                    isDisabled === true ? 'is-disabled' : ''
                  }`}
                >
                  {value}
                </span>
              </label>
            );
            return (
              <>
                {props?.choices ? (
                  <>
                    {emptyRadioBox}
                    {props.choices.map((choice) => renderRadioBox(choice))}
                  </>
                ) : props.render ? (
                  props.render(renderRadioBox)
                ) : undefined}
              </>
            );
          }}
        />
        <FormError name={name} errors={errors} validator={validator} />
      </>
    );
  }
);

type TCommonSelectProps = {
  placeholder?: string;
  menuPortalTarget?: HTMLElement | null | undefined;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  windowed?: boolean;
  optionalValidationRule?: TValidationRule;
  isDisabled?: boolean;
};
type TSelectProps = {
  name: string;
  validator: TValidator;
  choices: TChoice[];
} & TCommonSelectProps;
type TGroupedSelectProps = {
  name: string;
  validator: TValidator;
  choices: TGroupedChoice[];
} & TCommonSelectProps;
const SingleSelect: React.FC<TSelectProps> = React.memo((props) => (
  <BaseSelect {...props} />
));
const GroupedSingleSelect: React.FC<TGroupedSelectProps> = React.memo(
  (props) => <BaseSelect isGrouped={true} {...props} />
);
const MultiSelect: React.FC<TSelectProps> = React.memo((props) => (
  <BaseSelect {...props} isMulti={true} />
));
const GroupedMultiSelect: React.FC<TGroupedSelectProps> = React.memo(
  (props) => <BaseSelect isGrouped={true} isMulti={true} {...props} />
);

type TBaseSelectProps = {
  name: string;
  validator: TValidator;
  choices: TChoice[] | TGroupedChoice[];
  isGrouped?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
} & TCommonSelectProps;
type TSelectOption = {
  value: string | number;
  label: string;
};
type TGroupedSelectOption = {
  label: string;
  options: TSelectOption[];
};
const convertTChoicesForReactSelect = (choices: TChoice[]) =>
  choices.map((c) => ({ value: c.key, label: c.value }));
const convertTGroupedChoicesForReactSelect = (choices: TGroupedChoice[]) =>
  choices.map((gc) => ({
    label: gc.groupName,
    options: convertTChoicesForReactSelect(gc.choices),
  }));

const BaseSelect: React.FC<TBaseSelectProps> = React.memo(
  ({
    name,
    validator,
    choices,
    placeholder,
    isGrouped,
    isMulti,
    menuPortalTarget,
    menuPlacement,
    windowed,
    optionalValidationRule,
    isDisabled,
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    // データ読み込みエラーなど
    if (!choices.length) {
      return <></>;
    }

    const rules = {
      ...getRules(name, validator, optionalValidationRule),
    };
    const className = getHasError(name, validator, errors)
      ? 'c-input_multiSelect is-error'
      : 'c-input_multiSelect';

    const options = isGrouped
      ? convertTGroupedChoicesForReactSelect(choices as TGroupedChoice[])
      : convertTChoicesForReactSelect(choices as TChoice[]);
    const flatOptions = isGrouped
      ? (options as TGroupedSelectOption[]).flatMap((o) => o.options)
      : (options as TSelectOption[]);

    const toReactSelectValue = (
      formInputValue: number | string | (number | string)[] | undefined
    ) =>
      formInputValue !== undefined
        ? flatOptions.filter((o) => {
            if (Array.isArray(formInputValue)) {
              return (
                formInputValue.includes(String(o.value)) ||
                formInputValue.includes(Number(o.value))
              );
            }
            return formInputValue === o.value;
          })
        : null;
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, value, ref } }) =>
            isMulti ? (
              <Select
                isMulti
                isDisabled={isDisabled}
                value={toReactSelectValue(value)}
                options={options}
                placeholder={placeholder}
                className={className}
                classNamePrefix="c-input_multiSelect"
                // Object[]でなくstring[] | number[] で返す
                onChange={(value) => onChange(value.map((v) => v.value))}
                menuPortalTarget={menuPortalTarget}
                menuPlacement={menuPlacement}
                ref={ref}
              />
            ) : (
              <Select
                value={toReactSelectValue(value)}
                isDisabled={isDisabled}
                options={options}
                placeholder={placeholder}
                className={className}
                classNamePrefix="c-input_multiSelect"
                onChange={(value) => onChange(value?.value)}
                menuPortalTarget={menuPortalTarget}
                menuPlacement={menuPlacement}
                components={windowed ? { MenuList } : undefined}
                ref={ref}
              />
            )
          }
        />
        <FormError name={name} errors={errors} validator={validator} />
      </>
    );
  }
);

const MenuList: React.FC<MenuListComponentProps<TSelectOption, false>> = (
  props
) => {
  const heightPx = 35;
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * heightPx;
  const childrenOptions = React.Children.toArray(
    children
  ) as React.ReactChild[];
  return (
    <FixedSizeList
      width={'100%'}
      height={maxHeight}
      itemCount={childrenOptions.length}
      itemSize={heightPx}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{childrenOptions[index]}</div>}
    </FixedSizeList>
  );
};

const getClassNameString = (
  defaultClassName: string,
  additionalClassName: string | undefined
): string =>
  defaultClassName + (additionalClassName ? ` ${additionalClassName}` : '');

const getHasError = (
  name: string,
  validator: TValidator,
  errors: FieldErrors
) =>
  !!getError(errors, name) ||
  (validator.hasError && !!getApiErrorMessages(validator.messages, name));

export {
  TextInput,
  TextArea,
  NumberInput,
  NumberLooseInput,
  NullableNumberInput,
  NumbersInput,
  TimeInput,
  DateInput,
  DatePicker,
  MonthInput,
  CheckBox,
  Checkboxes,
  SingleCheckBox,
  RadioBoxes,
  SingleSelect,
  GroupedSingleSelect,
  MultiSelect,
  GroupedMultiSelect,
};
