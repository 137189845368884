import React, { MouseEventHandler, useCallback, useState } from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { ApiErrors } from '@/components/shared/Form/Errors';

import { toMessages, postJson } from '../../../../ts/useApi';
import { errorToast, successToast } from '../../../../ts/toast';
import SaveApiErrors from '../../../shared/SaveApiErrors/App';
import { TBulkHiddenPhotograph, TBulkHiddenPhotographsResponse } from './types';
import { useFormContext } from 'react-hook-form';
import { alertApiError } from '../../../../ts/formValidation';

const Photographs: React.FC<{
  id: number;
  photographs: TBulkHiddenPhotograph[];
  setPhotographs: React.Dispatch<React.SetStateAction<TBulkHiddenPhotograph[]>>;
  setIsDisplayForm: React.Dispatch<React.SetStateAction<boolean>>;
  submittedPhotographNamesText: string;
  setSubmittedPhotographNamesText: React.Dispatch<React.SetStateAction<string>>;
}> = React.memo(
  ({
    id,
    photographs,
    setPhotographs,
    setIsDisplayForm,
    submittedPhotographNamesText,
    setSubmittedPhotographNamesText,
  }) => {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [validator, setValidator] = useState<TValidatorResponse>({
      hasError: false,
      messages: {},
      rules: {},
    });
    const { setValue } = useFormContext();

    const targetPhotographNames = photographs
      .filter((p) => p.errorMessage === null && !p.isDuplicated)
      .map((p) => p.name);
    const targetCount = targetPhotographNames.length;

    const resetForm = useCallback(
      (photographNamesText: string) => {
        setPhotographs([]);
        setIsDisplayForm(true);
        setValue('photographNamesText', photographNamesText);
      },
      [setPhotographs, setIsDisplayForm, setValue]
    );

    const submit: MouseEventHandler = useCallback(async () => {
      try {
        const { validator } = await postJson<TBulkHiddenPhotographsResponse>(
          `/api/events/${id}/bulk_hidden_photographs`,
          { photographNames: targetPhotographNames }
        );
        if (validator?.hasError) {
          setValidator(validator);
          alertApiError();
        } else {
          setErrorMessages([]);
          successToast('写真一括非表示処理を行いました');
          resetForm('');
          setSubmittedPhotographNamesText('');
        }
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('エラーが発生しました');
      }
    }, [id, targetPhotographNames, resetForm, setSubmittedPhotographNamesText]);

    return (
      <>
        <ApiErrors {...validator} />
        <SaveApiErrors messages={errorMessages} />
        <div className="c-frame">
          <table className="c-indexList u-mgb_0">
            <thead>
              <tr>
                <th>写真名（写真番号）</th>
                <th>写真ID</th>
                <th>
                  登録済みデータとの照合結果（有効な写真数合計：{targetCount}/
                  {photographs.length}枚）
                </th>
              </tr>
            </thead>
            <tbody>
              {photographs.map((p, index) => (
                <tr key={`${p.id}_${index}`}>
                  <td className="c-indexList_column_s u-align_right">
                    {p.name}
                  </td>
                  <td className="c-indexList_column_s u-align_right">{p.id}</td>
                  <td className="c-indexList_column_xl">
                    {p.errorMessage && (
                      <span>
                        エラー：{p.errorMessage}
                        <br />
                      </span>
                    )}
                    {p.isDuplicated && (
                      <span>
                        警告：写真が重複しています。（取り込み時には無視されます）
                        <br />
                      </span>
                    )}
                    {!p.errorMessage && !p.isDuplicated && 'OK'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="u-align_center u-mgt_s u-mgb_xs">
            <button
              onClick={() => resetForm(submittedPhotographNamesText)}
              className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
            >
              キャンセル
            </button>
            <button
              onClick={submit}
              className={`c-btn_large c-btn_primary is-arrow c-input_submit${
                targetCount ? '' : ' is-disabled'
              }`}
              type="button"
            >
              {targetCount}枚の写真を非表示にする
            </button>
          </div>
        </div>
      </>
    );
  }
);

export default Photographs;
