import React from 'react';
import { Link } from 'react-router-dom';
import { Items } from './Items';
import Paginator from '@/components/shared/Paginator/App';
import { TPhotographer } from '../types';
import { TPagination } from '@/components/shared/Paginator/types';
import '../style.scss';

export const SearchResult: React.FC<{
  photographers: TPhotographer[];
  pagination: TPagination;
  queryString: string;
}> = ({ photographers, pagination, queryString }) => (
  <div className="c-frame">
    <div className="l-flex_between_center u-mgb_s">
      <p className="c-text_resultNumber">
        <span>{pagination?.total}</span>
        <small>件の結果</small>
      </p>
      <div className="l-flex">
        <div className="c-pagination_upper u-mgr_s">
          <Paginator
            pagination={pagination}
            currentPath="/photographers"
            queryString={queryString}
          />
        </div>
        <div>
          <Link
            className="c-btn_rectangle c-btn_create"
            to="/photographers/create"
          >
            <i className="c-icon c-icon__small c-icon_create"></i>
          </Link>
        </div>
      </div>
    </div>
    <div className="p-photographerIndex_scrollbar">
      <div className="grid_header p-photographerIndex_grid_other_department_container_wrap">
        <div className="p-photographerIndex_grid_other_department_container">
          <div className="p-photographerIndex_grid_row">
            <div
              className="
              p-photographerIndex_grid_caption
              p-photographerIndex_grid_caption_1st
            "
            >
              カメラマン
            </div>
            <div
              className="
              p-photographerIndex_grid_caption
              p-photographerIndex_grid_caption_2nd
            "
            >
              短縮名
            </div>
            <div
              className="
              p-photographerIndex_grid_caption
              p-photographerIndex_grid_caption_3rd
            "
            >
              所属組織
            </div>
            <div
              className="
              p-photographerIndex_grid_caption
              p-photographerIndex_grid_caption_4rd
            "
            >
              権限
            </div>
            <div className="p-photographerIndex_grid_caption">
              適格請求事業者登録番号
            </div>
            <div className="p-photographerIndex_grid_caption">
              適格請求事業者登録屋号
            </div>
            <div className="p-photographerIndex_grid_caption">都道府県</div>
            <div className="p-photographerIndex_grid_caption">市区町村</div>
            <div className="p-photographerIndex_grid_caption">番地・建物</div>
            <div
              className="
              p-photographerIndex_grid_caption
            "
            >
              <i className="c-icon_mail u-fz_s" />
              メールアドレス
            </div>
            <div className="p-photographerIndex_grid_caption">電話番号</div>
            <div className="p-photographerIndex_grid_caption">性別</div>
            <div className="p-photographerIndex_grid_caption">車所有</div>
            <div className="p-photographerIndex_grid_caption">宿泊</div>
          </div>
        </div>
      </div>
      {photographers.map((photographer, key) => (
        <div
          className="p-photographerIndex_grid_other_department_container"
          key={key}
        >
          <Items photographer={photographer} />
        </div>
      ))}
    </div>
    <div className="l-flex_between_center u-mgb_s">
      <p className="c-text_resultNumber">
        <span>{pagination?.total}</span>
        <small>件の結果</small>
      </p>
      <div className="l-flex">
        <div className="c-pagination_upper u-mgr_s">
          <Paginator
            pagination={pagination}
            currentPath="/photographers"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  </div>
);
