import { RegisterOptions } from 'react-hook-form';
import { removeUndefined } from '@/ts/objectTools';
import {
  TOnlyValidationRuleResponse,
  TValidationFailedMessages,
  TValidationRule,
  TValidatorResponse,
} from './types';

const getRegisterOptions = (
  validationRule: TValidationRule,
  validationFailedMessages?: TValidationFailedMessages
): RegisterOptions => {
  const options: RegisterOptions = {
    required: getRequired(validationRule),
    min: getMin(validationRule),
    max: getMax(validationRule),
    minLength: getMinLength(validationRule),
    maxLength: getMaxLength(validationRule),
    pattern: getPattern(validationRule, validationFailedMessages),
    // TODO: 未定義
    validate: undefined,
  };

  // undefinedを除去
  return removeUndefined<RegisterOptions>(options);
};

const getRequired = (validationRule: TValidationRule) =>
  validationRule.required ? '必須項目です' : undefined;

const getMin = (validationRule: TValidationRule) =>
  getMinOrMax(validationRule, 'min');
const getMax = (validationRule: TValidationRule) =>
  getMinOrMax(validationRule, 'max');
const getMinOrMax = (validationRule: TValidationRule, key: 'min' | 'max') => {
  let value: number | string | undefined;
  if (validationRule.between !== undefined) {
    value =
      key === 'min' ? validationRule.between.min : validationRule.between.max;
  } else {
    value = key === 'min' ? validationRule.min : validationRule.max;
  }

  if (value === undefined) {
    return undefined;
  }
  return {
    value,
    message: `${value}${key === 'min' ? '以上' : '以下'}を入力してください`,
  };
};

const getMinLength = (validationRule: TValidationRule) =>
  getMinOrMaxLength(validationRule, 'min');
const getMaxLength = (validationRule: TValidationRule) =>
  getMinOrMaxLength(validationRule, 'max');
const getMinOrMaxLength = (
  validationRule: TValidationRule,
  key: 'min' | 'max'
) => {
  const value =
    key === 'min' ? validationRule.minLength : validationRule.maxLength;

  if (value === undefined) {
    return undefined;
  }
  return {
    value,
    message: `${value}文字${key === 'min' ? '以上' : '以内'}で入力してください`,
  };
};

const getPattern = (
  validationRule: TValidationRule,
  validationFailedMessages: TValidationFailedMessages | undefined
) => {
  const value = validationRule.pattern;
  if (!value) {
    return undefined;
  }
  return {
    value: new RegExp(value),
    message: validationFailedMessages?.pattern || '入力が正しくありません',
  };
};

/**
 * Laravelの hoge.*.field 形式のvalidatorをfrontに適用させるための加工処理
 * 配列形式のフォームの各行に対して処理を呼び出してください。
 * @param arrayFormName 適用するフォームのname。上記sampleの場合'hoge'
 * @param currentIndex  適用するフォームの現在のindex
 * @param orgValidator  responseから取得したvalidator
 */
const convertValidatorForArrayForm = <
  ValidatorType extends TValidatorResponse | TOnlyValidationRuleResponse
>(
  arrayFormName: string,
  currentIndex: number,
  orgValidator: ValidatorType
): ValidatorType => {
  const rules: Record<string, TValidationRule> = {};
  Object.keys(orgValidator.rules)
    .filter((key) => key.startsWith(`${arrayFormName}.`))
    .forEach((key) => {
      const rule = orgValidator.rules[key];
      const newKey = key.replace(
        `${arrayFormName}.*`,
        `${arrayFormName}.${currentIndex}`
      );
      rules[newKey] = rule;
    });
  return {
    ...orgValidator,
    rules,
  };
};

const getRules = (
  name: string,
  validator: TValidatorResponse | TOnlyValidationRuleResponse,
  optionalValidationRule: TValidationRule | undefined
): RegisterOptions => {
  const validatorRules =
    Object.keys(validator.rules).length && validator.rules[name]
      ? validator.rules[name]
      : {};
  const validationRule = {
    ...validatorRules,
    ...(optionalValidationRule ?? {}),
  };
  const validationMessage = validator.failedMessages
    ? Object.keys(validator.failedMessages).length
      ? validator.failedMessages[name]
      : undefined
    : undefined;

  return validationRule !== undefined
    ? getRegisterOptions(validationRule, validationMessage)
    : {};
};

export { getRegisterOptions, convertValidatorForArrayForm, getRules };
