import React, {
  MouseEventHandler,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  SingleSelect,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  TValidationRule,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { postJson } from '../../../../ts/useApi';
import {
  TMailFormat,
  TFormItems,
  TFormInputs,
  TSendOfferResponse,
  TTargetPhotographer,
} from './types';
import {
  errorToast,
  successToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';

const FormItems: React.FC<{
  methods: UseFormReturn<TFormInputs, Record<string, unknown>>;
  validator: TValidatorResponse;
  mailFormats: TMailFormat[];
  formItems: TFormItems;
  canSubmit: boolean;
}> = React.memo(
  ({
    methods,
    validator,
    mailFormats,
    formItems: { mailTypes },
    canSubmit,
  }) => {
    const { getValues, setValue, watch } = methods;
    const [currentMailFormat, setCurrentMailFormat] = useState<TMailFormat>(
      mailFormats[0]
    );

    // mailType変更監視
    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if (name !== 'mailType' && type !== 'change') {
          return;
        }
        if (currentMailFormat.type === value.mailType) {
          return;
        }
        const selectedMailFormat = mailFormats.find(
          (m) => m.type === value.mailType
        )!;
        setCurrentMailFormat(selectedMailFormat);
        // subjectを変更
        setValue('mailSubject', selectedMailFormat.subject);
      });

      return () => subscription.unsubscribe();
    }, [
      watch,
      currentMailFormat.type,
      mailFormats,
      setCurrentMailFormat,
      setValue,
      getValues,
    ]);

    const onClickUrgent: MouseEventHandler<HTMLButtonElement> = useCallback(
      (e) => {
        // subjectに「【急】」をつける
        setValue('mailSubject', '【急】' + getValues('mailSubject'));
      },
      [getValues, setValue]
    );

    return (
      <ul className="l-col_wrap l-col_wrap__isSpMode l-col_wrap__noBorder l-flex_between l-flex__isSpInput">
        <li className="l-col_12">
          <ul className="l-col_wrap l-col_wrap__isSpMode l-col_wrap__noBorder l-flex">
            <li className="c-dataLabel">
              <label>メール種類</label>
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <SingleSelect
                name="mailType"
                choices={mailTypes}
                validator={validator}
              />
            </li>
          </ul>
          <ul className="l-col_wrap l-col_wrap__isSpMode l-flex">
            <li className="c-dataLabel">
              <label>件名</label>
              <small className="c-required">(必須)</small>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="mailSubject"
                placeholder="件名"
                validator={validator}
              />
              <button
                type="button"
                className="c-btn_edit c-btn_small u-fz_s u-mgt_xs"
                onClick={onClickUrgent}
              >
                急ぎの表記にする
              </button>
            </li>
          </ul>
          <ul className="l-col_wrap l-col_wrap__isSpMode l-flex">
            <li className="c-dataLabel">
              <label>自由記入欄</label>
            </li>
            <li className="c-dataValue">
              <TextArea
                name="additionalMailBody"
                placeholder="自由記入欄"
                rows={20}
                validator={validator}
              />
            </li>
          </ul>
          <div className="u-align_center u-mgt_s">
            <input
              className={`c-btn_large c-btn_primary is-arrow c-input_submit${
                canSubmit ? '' : ' is-disabled'
              }`}
              type="submit"
              value="この内容で依頼"
            />
          </div>
        </li>
        <li className="l-col_12">
          <div className="u-mgl_m">
            <strong>※本文のサンプル</strong>
            <br />
            <br />
            <span className="u-pre_wrap">{currentMailFormat.body}</span>
          </div>
        </li>
      </ul>
    );
  }
);

const Form: React.FC<{
  targetPhotographers: TTargetPhotographer[];
  rules: Record<string, TValidationRule | never>;
  mailFormats: TMailFormat[];
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
}> = React.memo(
  ({ targetPhotographers, rules, mailFormats, formItems, queryParams }) => {
    const [canSubmit, setCanSubmit] = useState(true);
    // NOTE: 型定義にobjectが書かれていて怒られるのでRecordに変換している
    const methods = useForm<TFormInputs>({
      defaultValues: {
        ids: queryParams['ids'] as number[],
        mailType: mailFormats[0].type,
        mailSubject: mailFormats[0].subject,
      },
    }) as UseFormReturn<TFormInputs, Record<string, unknown>>;
    const { handleSubmit } = methods;
    const [validator, setValidator] = useState<TValidatorResponse>({
      rules,
      messages: {},
      hasError: false,
    });

    const onSubmit: SubmitHandler<TFormInputs> = useCallback(
      async (formData) => {
        const photographers = targetPhotographers
          .map(
            (t) =>
              `${t.photographerId}: ${t.shortName}（${t.eventRequests
                .map((e) => `${e.eventId}: ${e.eventName}`)
                .join(', ')}）`
          )
          .join('\n');
        if (
          !window.confirm(
            `${targetPhotographers.length}人のカメラマンに依頼を一括送信しますか？\n送信対象：\n${photographers}`
          )
        ) {
          return;
        }
        setCanSubmit(false);
        try {
          const {
            data: { data: response, validator },
          } = await postJson<TSendOfferResponse>(
            '/api/arrange_photographer/send_offer',
            formData
          );

          if (response === 'NG') {
            if (validator) {
              errorToastWithValidatorMessages(
                '入力値が不正です。',
                validator.messages
              );
              setValidator(validator);
            } else {
              errorToast(
                '依頼を送信できませんでした。\n依頼送信対象でないカメラマンが含まれている可能性があります。'
              );
            }
            // NOTE: エラー時はボタンを有効に戻す。依頼は一回しか送れないので、一回送れたら無効のまま。
            setCanSubmit(true);
          } else {
            successToast('依頼を一括送信しました。');
          }
        } catch (error) {
          setCanSubmit(true);
          errorToast('エラーが発生しました。');
        } finally {
        }
      },
      [targetPhotographers]
    );

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <FormItems
            methods={methods}
            validator={validator}
            mailFormats={mailFormats}
            formItems={formItems}
            canSubmit={canSubmit}
          />
        </form>
      </FormProvider>
    );
  }
);

export default Form;
