import React from 'react';

import { HYPHEN } from '../../../../ts/formatTools';
import { TSocietyContainer } from '../types';

const SocietyContainer: React.FC<TSocietyContainer> = React.memo(
  ({
    organization,
    society,
    societyDirect,
    societyStudents,
    requestPhotographers,
  }) => (
    <div className="c-submenu_main">
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">住所</li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                〒
                {`${organization.postcode} ${organization.prefectureName} ${organization.city} ${organization.address}`}
                {!!organization.lat && (
                  <small className="u-mgl_xs">
                    (緯度経度: {organization.lat}, {organization.lng})
                  </small>
                )}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              電話番号
            </li>
            <li className="c-dataValue">
              <span>{organization.telephonenumber}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">FAX</li>
            <li className="c-dataValue">
              <span>{organization.faxnumber}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              団体区分
            </li>
            <li className="c-dataValue">
              <span>{society.fundingTypeName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              団体の種類
            </li>
            <li className="c-dataValue">
              <span>{society.groupTypeName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              メールアドレス
            </li>
            <li className="c-dataValue">
              <span>{organization.mailaddress}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">団体URL</li>
            <li className="c-dataValue">
              {!organization.url ? (
                HYPHEN
              ) : (
                <a className="c-textlink" href={organization.url}>
                  {organization.url}
                </a>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">法人格</li>
            <li className="c-dataValue">
              <span>{society.isLegalEntityName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              お手持ち写真プリント使用可否
            </li>
            <li className="c-dataValue">
              <span>{society.isSalableTsuideName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              所属人数
            </li>
            <li className="c-dataValue">
              <span>{society.numberOfBelonging}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">家庭数</li>
            <li className="c-dataValue">
              <span>{society.numberOfFamily}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">定員数(合計)</li>
            <li className="c-dataValue">
              <span>{society.numberOfChildrenCapacity}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">最寄駅</li>
            <li className="c-dataValue">
              <span>{society.nearestStation}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              所在地への行き方
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">{society.howToGo}</div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              最寄バス停
            </li>
            <li className="c-dataValue">
              <span>{society.nearestBusStop}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              パートナー名
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                千株式会社
                {/* FIXME: テナント実装したらなおす{society.tenantSociety?.tenant.name} */}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              年度開始月
            </li>
            <li className="c-dataValue">{society.startMonthOfFiscalYear}</li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              写真閲覧キー問い合わせ
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {society.autoNoticeCertificationkeyTypeName}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              オープン写真閲覧キー作成
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {society.isEnabledCreateKeyUsableWithoutLoginName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              写真閲覧キー家族招待
            </li>
            <li className="c-dataValue">
              {society.isEnabledCertificationKeyShareableName}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              クラス名リスト
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">{society.classnames}</div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              長期休み情報
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">{society.vacationPrecaution}</div>
            </li>
          </ul>
        </li>
      </ul>
      {!!societyStudents.length && (
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          {/* memo: max2個 */}
          {societyStudents.map((student) => (
            <li
              className={`l-col_12${
                societyStudents.length > 1 ? '' : ' l-col_24'
              }`}
              key={student.id}
            >
              <ul className="l-flex">
                <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                  園児・生徒({student.fiscalYear}年度)
                </li>
                <li className="c-dataValue">
                  <div className="u-pre_wrap">{student.summary}</div>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      )}
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_s">
              責任者承認機能
            </li>
            <li className="c-dataValue">
              {society.isRequiredAcceptanceByLeaderName}
              {!!society.isRequiredAcceptanceByLeader && (
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                    <li className="l-col_12">
                      <ul className="l-flex l-col_24">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                          学校からの質問１
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">{society.question1}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex l-col_24">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                          学校からの質問２
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">{society.question2}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex l-col_24">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                          学校からの質問３
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">{society.question3}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              承認を担当する契約責任者
            </li>
            <li className="c-dataValue">
              (ID: {society.societyContractIdForAcceptance}{' '}
              {society.societyContractIdForAcceptanceTypeName}) (
              {!!society.societyContractIdForAcceptanceRepresentativeName
                ? society.societyContractIdForAcceptanceRepresentativeName
                : '責任者名登録なし'}
              )
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              千作業情報
              <span className="l-relative">
                <span className="c-tooltip u-mgl_xs">
                  <i className="c-icon_help"></i>
                </span>
                <span className="c-tooltip_body p-groupsSummary_tooltip_help">
                  千の団体の場合のみ表示されます
                </span>
              </span>
            </li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray u-mgt_xs">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        イベント期間自動設定の可否
                      </li>
                      <li className="c-dataValue">
                        {societyDirect.isEnabledAutoEventPeriodSettingName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12" />
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        内定者・社カメの研修可否
                      </li>
                      <li className="c-dataValue">
                        {
                          societyDirect.assigningInternalPhotographerTraineeTypeName
                        }
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        相カメの研修可否
                      </li>
                      <li className="c-dataValue">
                        {
                          societyDirect.assigningExternalPhotographerTraineeTypeName
                        }
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        詳細対応者
                      </li>
                      <li className="c-dataValue">
                        {societyDirect.photographerName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        詳細対応者の変更履歴
                      </li>
                      <li className="c-dataValue">
                        {societyDirect.photographerChangeHistory}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex l-col_24">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        カメラマン希望
                      </li>
                      <li className="c-dataValue">
                        {societyDirect.requestedPhotographerMemo}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                        指定カメラマン
                      </li>
                      <li className="c-dataValue">
                        {requestPhotographers.map((photographer) => (
                          <div key={photographer.photographerId}>
                            {photographer.photographerId}：
                            {photographer.photographerName}
                          </div>
                        ))}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    {society.societyContractSalesDepartments?.map(
                      (sales, index) => (
                        <ul className="l-flex l-col_24" key={index}>
                          <li className="c-dataLabel p-groupsSummary_dataLabel_l">
                            No.{index + 1} {sales.contractTypeName}
                          </li>
                          <li className="c-dataValue">
                            <ul>
                              <li>担当営業：{sales.first.name}</li>
                              <li>副担当営業：{sales.second.name}</li>
                            </ul>
                          </li>
                        </ul>
                      )
                    )}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
);

export default SocietyContainer;
