import React, { useEffect } from 'react';
import { get as getAddressByPostalCode } from 'japan-postal-code';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  TextInput,
  SingleSelect,
  GroupedSingleSelect,
  NumberInput,
  TextArea,
  RadioBoxes,
  DateInput,
  CheckBox,
} from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TGroupedChoice } from '@/components/shared/Form/types';

import { TFormInputs, TFormItems } from './types';
import SaveFormPlans from './SaveFormPlans';
import SaveFormSalesManagement from './SaveFormSalesManagement';
import {
  isIncentivePaymentTypeSales,
  isSpecialIncentiveRateTypeL,
  SPECIAL_INCENTIVE_RATE_TYPE_NONE,
} from '../../../../ts/salesManagementConstants';
import {
  isContractTypeDirect,
  isContractTypeTeacher,
  isContracting,
  isGoodsPlan,
  IS_REQUIRED_DELIVERY_ADDRESS_YES,
  IS_ENABLED_PUBLISHING_PERIODS_YES,
  DELIVERY_TYPE_BLUKDELIVERY,
  BULKDELIVERY_ADDRESS_TYPE_OTHER,
  IS_REQUIRED_DELIVERY_ADDRESS_NONE,
  FLYER_PRE_DELIVERY_TYPE_NONE,
  CONTRACT_TYPE_TEACHER,
  REPRESENTATIVE_PARENT,
  NOT_DOWNLOAD_LEADER,
  SOCIETY_CONTRACTS_SELECTED_YES,
} from '../../../../ts/societyContractConstants';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

/**
 * 不要なデータを落とすなど、submit dataを整形
 */
const formatFormData = (formData: TFormInputs): void => {
  const hasGoodsPlan = (formData.societyContractPlans ?? []).some((p) =>
    isGoodsPlan(p.planId)
  );
  if (hasGoodsPlan) {
    formData.isEnabledAlbumSales = 0;
  }
  // 直販契約
  if (isContractTypeDirect(formData.societyContractTypeId)) {
    delete formData.salesManagement;
    delete formData.salesManagementPlans;
    formData.isEnabledDisplayPartnerName = 0;
  }
  // 先生契約
  if (isContractTypeTeacher(formData.societyContractTypeId)) {
    formData.isEnabledDisplayPartnerName = 1;
    // アルバム購入の値を固定
    if (formData.salesManagement) {
      formData.salesManagement.isEnabledAlbumSales =
        formData.isEnabledAlbumSales;
    }
    if (formData.societyContractPlans) {
      formData.societyContractPlans.forEach((p) => {
        delete p.directSalesIncentiveRate;
      });
    }
  }
  // 配送先住所
  if (!formData.isRequiredDeliveryAddress) {
    delete formData.deliveryPostcode;
    delete formData.deliveryPrefectureId;
    delete formData.deliveryCity;
    delete formData.deliveryAddress;
    delete formData.deliveryAddressee;
    delete formData.deliveryTelephonenumber;
  }
  // 掲載日固定
  if (!formData.isEnabledPublishingPeriods) {
    delete formData.publishingStartDay;
    delete formData.publishingEndDay;
  }
  // チラシ
  if (!formData.isRequiredFlyer) {
    formData.isEnabledFlyerPreDelivery = 0;
    formData.numberOfFlyers = 0;
    delete formData.contentsOfFlyer;
  }
  if (!formData.isEnabledFlyerPreDelivery) {
    formData.flyerPreDeliveryType = FLYER_PRE_DELIVERY_TYPE_NONE;
    formData.numberOfBusinessDaysToPreship = 0;
  }
  // 送料
  if (hasGoodsPlan || formData.deliveryType === DELIVERY_TYPE_BLUKDELIVERY) {
    formData.isEnabledPostagePaidForIndividualdelivery = 0;
  }
  // 配送方法(一括配送以外)
  if (formData.deliveryType !== DELIVERY_TYPE_BLUKDELIVERY) {
    // ※「なし」固定
    formData.isRequiredAddressesForBulkdelivery =
      IS_REQUIRED_DELIVERY_ADDRESS_NONE;
    delete formData.bulkdeliveryInfo;
  }
  // 一括配送宛先(その他以外)
  if (
    formData.bulkdeliveryInfo &&
    formData.bulkdeliveryInfo.deliveryAddressType !==
      BULKDELIVERY_ADDRESS_TYPE_OTHER
  ) {
    delete formData.bulkdeliveryInfo.postcode;
    delete formData.bulkdeliveryInfo.prefectureId;
    delete formData.bulkdeliveryInfo.city;
    delete formData.bulkdeliveryInfo.address;
    delete formData.bulkdeliveryInfo.addressee;
    delete formData.bulkdeliveryInfo.telephonenumber;
  }
  // 販売管理プラン
  if (formData.salesManagementPlans && formData.salesManagementPlans.length) {
    formData.salesManagementPlans.forEach((p) => {
      // インセンティブ種類
      if (isIncentivePaymentTypeSales(p.incentivePaymentType)) {
        delete p.wholesaleCommissionRate;
      } else {
        delete p.incentiveRate;
        // ※「なし」固定
        p.specialIncentiveRateType = SPECIAL_INCENTIVE_RATE_TYPE_NONE;
      }
      // 特殊計上(L版でない)
      if (!isSpecialIncentiveRateTypeL(p.specialIncentiveRateType)) {
        delete p.incentiveRateThresholdPrice;
        delete p.incentiveRateWhenLessThanThresholdPrice;
      }
    });
  }
};

const getPrefectureIdByName = (
  prefectureName: string,
  prefectures: TGroupedChoice[]
): number | undefined => {
  const key = prefectures
    .map((area) => area.choices.find((c) => c.value === prefectureName))
    .find((c) => c)?.key;

  return key ? Number(key) : undefined;
};

const SaveForm: React.FC<{
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
  onSubmit: SubmitHandler<TFormInputs>;
  defaultValues: Record<string, unknown>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
  isEditing: boolean;
}> = React.memo(
  ({
    validator,
    formItems,
    defaultValues,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
    isEditing,
  }) => {
    const methods = useForm<TFormInputs>({ defaultValues });
    // 郵便番号検索
    const setAddressByDeliveryPostcode = () => {
      const deliveryPostcode = String(methods.getValues('deliveryPostcode'));
      getAddressByPostalCode(deliveryPostcode, (address) => {
        const prefectureId = getPrefectureIdByName(
          address.prefecture,
          formItems.prefectures
        );
        if (prefectureId) {
          methods.setValue('deliveryPrefectureId', prefectureId);
        }
        methods.setValue('deliveryCity', address.city);
        methods.setValue('deliveryAddress', address.area);
      });
    };
    // 郵便番号検索(一括配送)
    const setAddressBybulkdeliveryInfoPostcode = () => {
      const bulkdeliveryInfoPostcode = String(
        methods.getValues('bulkdeliveryInfo.postcode')
      );
      getAddressByPostalCode(bulkdeliveryInfoPostcode, (address) => {
        const prefectureId = getPrefectureIdByName(
          address.prefecture,
          formItems.prefectures
        );
        if (prefectureId) {
          methods.setValue('bulkdeliveryInfo.prefectureId', prefectureId);
        }
        methods.setValue('bulkdeliveryInfo.city', address.city);
        methods.setValue('bulkdeliveryInfo.address', address.area);
      });
    };
    // 契約形態
    const societyContractTypeId = methods.watch('societyContractTypeId');
    // 契約状態
    const status = methods.watch('status');
    // 配送先住所の指定があるかどうかの値監視
    const isRequiredDeliveryAddress = methods.watch(
      'isRequiredDeliveryAddress'
    );
    // 掲載日固定の値監視
    const isEnabledPublishingPeriods = methods.watch(
      'isEnabledPublishingPeriods'
    );
    // 配送方法
    const deliveryType = methods.watch('deliveryType');
    // 一括配送宛先の値監視
    const bulkdeliveryInfoDeliveryAddressType = methods.watch(
      'bulkdeliveryInfo.deliveryAddressType'
    );
    // チラシ
    const isRequiredFlyer = methods.watch('isRequiredFlyer');
    const isEnabledFlyerPreDelivery = methods.watch(
      'isEnabledFlyerPreDelivery'
    );
    // 物販プランあり
    const hasGoodsPlan = (methods.watch('societyContractPlans') ?? []).some(
      (p) => isGoodsPlan(p.planId)
    );
    // 集合写真フレームの有無
    const frameForGroupPhoto = methods.watch('isEnabledFrameForGroupPhoto');

    // 保護者会の場合責任者DL不可
    if (methods.watch('representativeType') === REPRESENTATIVE_PARENT) {
      methods.setValue('isEnabledDownloadByLeader', NOT_DOWNLOAD_LEADER);
    }

    useEffect(() => {
      if (deliveryType !== DELIVERY_TYPE_BLUKDELIVERY) {
        methods.setValue('isEnabledPostagePaidForIndividualdelivery', 1);
      } else {
        methods.setValue(
          'isRequiredAddressesForBulkdelivery',
          SOCIETY_CONTRACTS_SELECTED_YES
        );
      }
    }, [deliveryType, methods]);

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>契約種類</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {/* NOTE: フォーム上では変更できない */}
                    {
                      formItems.societyContractTypes.find(
                        (ct) => ct.key === societyContractTypeId
                      )!.value
                    }
                    <NumberInput
                      name="societyContractTypeId"
                      validator={validator}
                      additionalClassName="is-hidden"
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>契約締結日</label>
                    {isContracting(status) && (
                      <small className="c-required">(必須)</small>
                    )}
                  </li>
                  <li className="c-dataValue">
                    <DateInput
                      name="concludeDate"
                      placeholder="契約締結日"
                      validator={validator}
                      optionalValidationRule={{
                        required: isContracting(status),
                      }}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>契約状態</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name="status"
                  choices={formItems.societyContract.status}
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>システム管理メールアドレス</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="mailaddress"
                      placeholder="システム管理メールアドレス"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>問合せ受付用メールアドレス</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="inquiryMailaddress"
                      placeholder="問合せ受付用メールアドレス"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>契約者種類</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="representativeType"
                      choices={formItems.societyContract.representativeType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>責任者ダウンロードの可/不可</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledDownloadByLeader"
                      choices={
                        formItems.societyContract.isEnabledDownloadByLeader
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            {/* 責任者 */}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">責任者（契約者）</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>氏名</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativeName"
                            placeholder="氏名"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>氏名(かな)</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativeNamekana"
                            placeholder="氏名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>役職</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativePosition"
                            placeholder="役職"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>電話番号</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativeTelephonenumber"
                            placeholder="03-3123-4567"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>FAX番号</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativeFaxnumber"
                            placeholder="03-3123-4567"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>メールアドレス</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="representativeMailaddress"
                            placeholder="メールアドレス"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            {/* 先方担当者 */}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">先方担当者</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>氏名</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactName"
                            placeholder="氏名"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>氏名(かな)</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactNamekana"
                            placeholder="氏名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>役職</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactPosition"
                            placeholder="役職"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>電話番号</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactTelephonenumber"
                            placeholder="03-3123-4567"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>FAX番号</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactFaxnumber"
                            placeholder="03-3123-4567"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                    <li className="c-label_innerHalf">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          <label>メールアドレス</label>
                        </li>
                        <li className="c-dataValue">
                          <TextInput
                            name="contactMailaddress"
                            placeholder="メールアドレス"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>配送先住所指定</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name="isRequiredDeliveryAddress"
                  choices={formItems.societyContract.isRequiredDeliveryAddress}
                  validator={validator}
                />
              </li>
            </ul>
            {isRequiredDeliveryAddress === IS_REQUIRED_DELIVERY_ADDRESS_YES && (
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">配送先住所</li>
                <li className="c-dataValue">
                  <div className="t-bgBox_gray u-mgt_xs">
                    <ul className="c-label_line l-flex is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>郵便番号</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <div className="l-flex">
                              <div className="c-label_innerHalf__isPostcode">
                                <TextInput
                                  name="deliveryPostcode"
                                  placeholder="123-4567"
                                  validator={validator}
                                  optionalValidationRule={{ required: true }}
                                />
                              </div>
                              <div className="c-label_innerHalf__isPostcode">
                                <input
                                  className="c-btn_large c-btn_Search"
                                  value="検索"
                                  type="button"
                                  onClick={setAddressByDeliveryPostcode}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>都道府県</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <GroupedSingleSelect
                              name="deliveryPrefectureId"
                              choices={formItems.prefectures}
                              placeholder="都道府県"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>
                              住所
                              <br className="sp_off" />
                              <small>（市区町村）</small>
                            </label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <TextInput
                              name="deliveryCity"
                              placeholder="市区町村"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="c-label_line is-sp_input l-flex">
                      <li className="c-dataLabel">
                        <label>
                          住所
                          <br className="sp_off" />
                          <small>（市区町村以降）</small>
                          <small className="c-required">(必須)</small>
                        </label>
                      </li>
                      <li className="c-dataValue">
                        <TextInput
                          name="deliveryAddress"
                          placeholder="市区町村以降"
                          validator={validator}
                          optionalValidationRule={{ required: true }}
                        />
                      </li>
                    </ul>
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>宛名</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <TextInput
                              name="deliveryAddressee"
                              placeholder="宛名"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>電話番号</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <TextInput
                              name="deliveryTelephonenumber"
                              placeholder="03-1234-5678"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            )}
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      Up作業時の
                      <br className="sp_off" />
                      注意点
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="uploadingPrecaution"
                      validator={validator}
                      rows={5}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      撮影時の
                      <br className="sp_off" />
                      注意点
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="photographingPrecaution"
                      validator={validator}
                      rows={5}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>事前確認</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isRequiredPreConfirmation"
                      choices={
                        formItems.societyContract.isRequiredPreConfirmation
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>事前確認方法</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="preConfirmationType"
                      choices={formItems.societyContract.preConfirmationType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>チラシの有無</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name="isRequiredFlyer"
                  choices={formItems.societyContract.isRequiredFlyer}
                  validator={validator}
                />
              </li>
            </ul>
            {!!isRequiredFlyer && (
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">チラシ</li>
                <li className="c-dataValue">
                  <div className="t-bgBox_gray u-mgt_xs">
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>チラシ事前発送</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <RadioBoxes
                              name="isEnabledFlyerPreDelivery"
                              choices={
                                formItems.societyContract
                                  .isEnabledFlyerPreDelivery
                              }
                              validator={validator}
                            />
                          </li>
                        </ul>
                      </li>
                      {!!isEnabledFlyerPreDelivery && (
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>事前発送タイプ</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="flyerPreDeliveryType"
                                // NOTE: 事前発送の場合タイプ「なし」は選択不可
                                choices={formItems.societyContract.flyerPreDeliveryType.filter(
                                  (c) => c.key !== FLYER_PRE_DELIVERY_TYPE_NONE
                                )}
                                validator={validator}
                              />
                              <br />
                              <NumberInput
                                name="numberOfBusinessDaysToPreship"
                                additionalClassName="u-align_right c-input__short"
                                validator={validator}
                                unit="営業日前発送"
                              />
                            </li>
                          </ul>
                        </li>
                      )}
                    </ul>
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>チラシ部数</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <NumberInput
                              name="numberOfFlyers"
                              additionalClassName="u-align_right"
                              placeholder="チラシ部数"
                              validator={validator}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="c-label_line is-sp_input l-flex">
                      <li className="c-dataLabel">
                        <label>チラシの文言</label>
                      </li>
                      <li className="c-dataValue">
                        <small className="t-textColor_sub">
                          ※40文字Ｘ15行まで入力できます
                        </small>
                        <TextArea
                          name="contentsOfFlyer"
                          validator={validator}
                          rows={5}
                        />
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            )}
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      サンプルブック
                      <br className="sp_off" />
                      作成時の注意点
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <div className="u-pre_wrap">
                      <TextArea
                        name="samplebookPrecaution"
                        validator={validator}
                        rows={5}
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>チラシ・SB等配送の注意点</label>
                  </li>
                  <li className="c-dataValue">
                    <div className="u-pre_wrap">
                      <TextArea
                        name="deliveryPrecaution"
                        validator={validator}
                        rows={5}
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      撮影時
                      <br className="sp_off" />
                      備考
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="photographingMemo"
                      validator={validator}
                      rows={5}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      その他の
                      <br className="sp_off" />
                      注意点
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="otherPrecaution"
                      validator={validator}
                      rows={5}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>掲載日固定</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name="isEnabledPublishingPeriods"
                  choices={formItems.societyContract.isEnabledPublishingPeriods}
                  validator={validator}
                />
              </li>
            </ul>
            {isEnabledPublishingPeriods ===
              IS_ENABLED_PUBLISHING_PERIODS_YES && (
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">固定掲載</li>
                <li className="c-dataValue">
                  <div className="t-bgBox_gray u-mgt_xs">
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>開始日</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <NumberInput
                              name="publishingStartDay"
                              placeholder="固定掲載開始日"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>終了日</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <NumberInput
                              name="publishingEndDay"
                              placeholder="固定掲載終了日"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            )}
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>写真の並び順の初期値</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="photoAlignment"
                      choices={formItems.societyContract.photoAlignment}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>認証タイプ</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="authenticationType"
                      choices={formItems.societyContract.authenticationType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>スタッフ撮影</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledStaffPhotographing"
                      choices={
                        formItems.societyContract.isEnabledStaffPhotographing
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>サンプルブックの有無</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isRequiredSamplebook"
                      choices={formItems.societyContract.isRequiredSamplebook}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>アルバム購入</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {hasGoodsPlan ? (
                      <>
                        否<br />
                        <small className="t-textColor_sub">
                          ※物販のプランを含む場合、「可」に設定できません
                        </small>
                      </>
                    ) : (
                      <RadioBoxes
                        name="isEnabledAlbumSales"
                        choices={formItems.societyContract.isEnabledAlbumSales}
                        validator={validator}
                      />
                    )}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      撮影者名の
                      <br className="sp_off" />
                      表示
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {isContractTypeDirect(societyContractTypeId)
                      ? '非表示'
                      : '表示'}
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      FAX申込書
                      <br className="sp_off" />
                      部数
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="numberOfFaxApplicationForms"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    {/* NOTE: 本来は「パートナー画面個人情報表示」であり、パートナーでしか判定に利用されていない */}
                    {/* NOTE: しかし、現行で入力可能であることから、一旦そのままとする */}
                    <label>個人情報の表示</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledDisplayPersonalInformationToPartner"
                      choices={
                        formItems.societyContract
                          .isEnabledDisplayPersonalInformationToPartner
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      顔検索機能使用可否フラグ イベント作成時デフォルト値
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledFaceRecommend"
                      choices={formItems.societyContract.isEnabledFaceRecommend}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      プチパネル
                      <br className="sp_off" />
                      販売可否
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isSalablePhotopanel"
                      choices={formItems.societyContract.isSalablePhotopanel}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>FAX手数料</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledFaxCommission"
                      choices={formItems.societyContract.isEnabledFaxCommission}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      不定期
                      <br className="sp_off" />
                      キャンペーン可/不可
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="campaignType"
                      choices={formItems.societyContract.campaignType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      モデル募集
                      <br className="sp_off" />
                      フォームの
                      <br className="sp_off" />
                      表示/非表示
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledToDisplayModelRecruitmentForm"
                      choices={
                        formItems.societyContract
                          .isEnabledToDisplayModelRecruitmentForm
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      0円価格登録
                      <br className="sp_off" />
                      の可/不可
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledFreePhoto"
                      choices={formItems.societyContract.isEnabledFreePhoto}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              {societyContractTypeId === CONTRACT_TYPE_TEACHER ? (
                <li className="c-label_innerHalf is-hidden">
                  <ul className="l-flex">
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="isEnabledDisplayAgreementForLeader"
                        choices={
                          formItems.societyContract
                            .isEnabledDisplayAgreementForLeader
                        }
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        責任者約款
                        <br className="sp_off" />
                        表示
                      </label>
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="isEnabledDisplayAgreementForLeader"
                        choices={
                          formItems.societyContract
                            .isEnabledDisplayAgreementForLeader
                        }
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      (廃止)
                      <br className="sp_off" />
                      イベントの
                      <br className="sp_off" />
                      自動作成
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledAutoEventCreate"
                      choices={
                        formItems.societyContract.isEnabledAutoEventCreate
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      集合写真の
                      <br className="sp_off" />
                      専用フレームの
                      <br className="sp_off" />
                      有無
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledFrameForGroupPhoto"
                      choices={
                        formItems.societyContract.isEnabledFrameForGroupPhoto
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              {frameForGroupPhoto === 1 && (
                <li className="c-label_innerHalf">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        集合写真の
                        <br className="sp_off" />
                        専用フレーム
                      </label>
                      <small className="c-required">(必須)</small>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="frameForGroupPhotoType"
                        choices={
                          formItems.societyContract.frameForGroupPhotoType
                        }
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>商品配送方法</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="deliveryType"
                      choices={formItems.societyContract.deliveryType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>送料の有無</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {hasGoodsPlan ? (
                      <>
                        無<br />
                        <small className="t-textColor_sub">
                          ※物販のプランを含む場合、「有」に設定できません
                        </small>
                      </>
                    ) : deliveryType === DELIVERY_TYPE_BLUKDELIVERY ? (
                      '無'
                    ) : (
                      <RadioBoxes
                        name="isEnabledPostagePaidForIndividualdelivery"
                        choices={
                          formItems.societyContract
                            .isEnabledPostagePaidForIndividualdelivery
                        }
                        validator={validator}
                      />
                    )}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>高解像度設定</label>
                  </li>
                  <li className="c-dataValue">
                    {isContractTypeDirect(societyContractTypeId) ? (
                      !isEditing || defaultValues.isHighResolution ? (
                        '有効'
                      ) : (
                        '無効'
                      )
                    ) : (
                      <CheckBox
                        name="isHighResolution"
                        validator={validator}
                        label="有効"
                      />
                    )}
                  </li>
                </ul>
              </li>
            </ul>
            {deliveryType === DELIVERY_TYPE_BLUKDELIVERY && (
              <ul className="l-flex_between c-label_line is-sp_input">
                <li className="c-dataLabel">一括配送</li>
                <li className="c-dataValue">
                  <div className="t-bgBox_gray u-mgt_xs">
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>宛名</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <RadioBoxes
                              name="isRequiredAddressesForBulkdelivery"
                              choices={
                                formItems.societyContract
                                  .isRequiredAddressesForBulkdelivery
                              }
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>手数料</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <NumberInput
                              name="bulkdeliveryInfo.carriage"
                              placeholder="一括配送手数料"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                      <li className="c-label_innerHalf">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>一括配送締日</label>
                            <small className="c-required">(必須)</small>
                          </li>
                          <li className="c-dataValue">
                            <NumberInput
                              name="bulkdeliveryInfo.closeDay"
                              placeholder="一括配送締日"
                              validator={validator}
                              optionalValidationRule={{ required: true }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                      <li className="c-dataLabel">
                        <label>宛先</label>
                        <small className="c-required">(必須)</small>
                      </li>
                      <li className="c-dataValue">
                        <SingleSelect
                          name="bulkdeliveryInfo.deliveryAddressType"
                          choices={
                            formItems.bulkdeliveryInfo.deliveryAddressType
                          }
                          placeholder="一括配送宛先"
                          validator={validator}
                          optionalValidationRule={{ required: true }}
                        />
                      </li>
                    </ul>
                    {bulkdeliveryInfoDeliveryAddressType ===
                      BULKDELIVERY_ADDRESS_TYPE_OTHER && (
                      <ul className="l-flex_between c-label_line is-sp_input">
                        <li className="c-dataLabel" />
                        <li className="c-dataValue">
                          <div className="t-bgBox_gray u-mgt_xs">
                            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                              <li className="c-label_innerHalf">
                                <ul className="l-flex">
                                  <li className="c-dataLabel">
                                    郵便番号
                                    <small className="c-required">(必須)</small>
                                  </li>
                                  <li className="c-dataValue">
                                    <div className="l-flex">
                                      <div className="c-label_innerHalf__isPostcode">
                                        <TextInput
                                          name="bulkdeliveryInfo.postcode"
                                          placeholder="123-4567"
                                          validator={validator}
                                          optionalValidationRule={{
                                            required: true,
                                          }}
                                        />
                                      </div>
                                      <div className="c-label_innerHalf__isPostcode">
                                        <input
                                          className="c-btn_large c-btn_Search"
                                          value="検索"
                                          type="button"
                                          onClick={
                                            setAddressBybulkdeliveryInfoPostcode
                                          }
                                        />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                              <li className="c-label_innerHalf">
                                <ul className="l-flex">
                                  <li className="c-dataLabel">
                                    都道府県
                                    <small className="c-required">(必須)</small>
                                  </li>
                                  <li className="c-dataValue">
                                    <GroupedSingleSelect
                                      name="bulkdeliveryInfo.prefectureId"
                                      choices={formItems.prefectures}
                                      placeholder="都道府県"
                                      validator={validator}
                                      optionalValidationRule={{
                                        required: true,
                                      }}
                                    />
                                  </li>
                                </ul>
                              </li>
                              <li className="c-label_innerHalf">
                                <ul className="l-flex">
                                  <li className="c-dataLabel">
                                    住所
                                    <br className="sp_off" />
                                    <small>（市区町村）</small>
                                    <small className="c-required">(必須)</small>
                                  </li>
                                  <li className="c-dataValue">
                                    <TextInput
                                      name="bulkdeliveryInfo.city"
                                      placeholder="市区町村"
                                      validator={validator}
                                      optionalValidationRule={{
                                        required: true,
                                      }}
                                    />
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                              <li className="c-dataLabel">
                                住所
                                <br className="sp_off" />
                                <small>（市区町村以降）</small>
                                <small className="c-required">(必須)</small>
                              </li>
                              <li className="c-dataValue">
                                <TextInput
                                  name="bulkdeliveryInfo.address"
                                  placeholder="市区町村以降"
                                  validator={validator}
                                  optionalValidationRule={{ required: true }}
                                />
                              </li>
                            </ul>
                            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                              <li className="c-label_innerHalf">
                                <ul className="l-flex">
                                  <li className="c-dataLabel">
                                    <label>宛名</label>
                                    <small className="c-required">(必須)</small>
                                  </li>
                                  <li className="c-dataValue">
                                    <TextInput
                                      name="bulkdeliveryInfo.addressee"
                                      placeholder="宛名"
                                      validator={validator}
                                      optionalValidationRule={{
                                        required: true,
                                      }}
                                    />
                                  </li>
                                </ul>
                              </li>
                              <li className="c-label_innerHalf">
                                <ul className="l-flex">
                                  <li className="c-dataLabel">
                                    <label>電話番号</label>
                                    <small className="c-required">(必須)</small>
                                  </li>
                                  <li className="c-dataValue">
                                    <TextInput
                                      name="bulkdeliveryInfo.telephonenumber"
                                      placeholder="03-1234-5678"
                                      validator={validator}
                                      optionalValidationRule={{
                                        required: true,
                                      }}
                                    />
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
              </ul>
            )}
            {/* 千作業情報 ここから */}
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_24">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    千作業情報
                    <span className="l-relative">
                      <span className="c-tooltip u-mgl_xs">
                        <i className="c-icon_help"></i>
                      </span>
                      <span className="c-tooltip_body p-groupsSummary_tooltip_help">
                        千の団体の場合のみ表示されます
                      </span>
                    </span>
                  </li>
                  <li className="c-dataValue">
                    <div className="t-bgBox_gray">
                      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>サンプルブックの有無(事前確認用)</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isRequiredSamplebookForPreConfirmation"
                                choices={
                                  formItems.societyDirectContract
                                    .isRequiredSamplebookForPreConfirmation
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>FAX用紙の有無</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isRequiredFax"
                                choices={
                                  formItems.societyDirectContract.isRequiredFax
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>DVDの有無</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isRequiredDvd"
                                choices={
                                  formItems.societyDirectContract.isRequiredDvd
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>詳細FAX送信可否</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isEnabledDetailedFaxSendingToGroup"
                                choices={
                                  formItems.societyDirectContract
                                    .isEnabledDetailedFaxSendingToGroup
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>フォトマネー購入の可/不可</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isEnabledPointSales"
                                choices={
                                  formItems.societyDirectContract
                                    .isEnabledPointSales
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>撮影確認書の自動作成</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <RadioBoxes
                                name="societyDirectContract.isAutoCreateEventSchedulePdf"
                                choices={
                                  formItems.societyDirectContract
                                    .isAutoCreateEventSchedulePdf
                                }
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>担当営業</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <SingleSelect
                                name="societyDirectContract.salesDepartmentOwnerno"
                                choices={formItems.owneraccounts}
                                placeholder="担当営業"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="c-label_innerHalf">
                          <ul className="l-flex">
                            <li className="c-dataLabel">
                              <label>副担当営業</label>
                              <small className="c-required">(必須)</small>
                            </li>
                            <li className="c-dataValue">
                              <SingleSelect
                                name="societyDirectContract.secondarySalesDepartmentOwnerno"
                                choices={formItems.owneraccounts}
                                placeholder="副担当営業"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
                        <li className="c-dataLabel">担当営業の変更履歴</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">ZOHOに移行されました</div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            {/* 千作業情報 ここまで */}
            <SaveFormSalesManagement
              societyContractTypeId={societyContractTypeId}
              validator={validator}
              formItems={formItems.salesManagement}
            />

            <h3 className="c-section_title">契約プラン</h3>
            <SaveFormPlans
              societyContractTypeId={societyContractTypeId}
              validator={validator}
              formItems={formItems}
            />

            <div className="u-align_center u-mgt_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
export { formatFormData };
