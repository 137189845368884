import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FC } from 'react';
import { Constants } from '../../../type';
import styles from '../../Form.module.scss';

export const Special: FC<{
  index: number;
  constants: Constants;
  validator: TValidator;
}> = ({ index, constants, validator }) => {
  const customValidator: TValidator = {
    ...validator,
    rules: {
      ...validator.rules,
      [`eventRequestPhotographers.${index}.specialFee.price`]: {
        required: true,
        type: 'integer',
        min: 0,
        max: 2147483647,
      },
    },
  };

  return (
    <div className="c-frame">
      <div className="l-flex">
        <div className={styles.requestFeeEditLabel}>
          特別報酬<small className="c-required">（必須）</small>
        </div>
        <div className="c-dataValue">
          <div className="l-flex">
            <div className="t-col_16">
              <div className="l-flex">
                <div>
                  <div className="t-textColor_sub">金額(税込)</div>
                  <TextInput
                    name={`eventRequestPhotographers.${index}.specialFee.price`}
                    validator={customValidator}
                  />
                </div>
              </div>
            </div>
            <div className="t-col_8 u-mgl_m">
              <div className="t-textColor_sub">税率</div>
              <RadioBoxes
                name={`eventRequestPhotographers.${index}.specialFee.consumptionTaxPercent`}
                choices={constants.consumptionTaxPercent}
                validator={validator}
              />
            </div>
          </div>
          <div className="t-textColor_sub">備考</div>
          <TextInput
            name={`eventRequestPhotographers.${index}.specialFee.externalMemo`}
            validator={validator}
          />
          <div className="t-textColor_sub">※明細に表示されません</div>
        </div>
      </div>
    </div>
  );
};
