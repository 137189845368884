import React from 'react';

export const NoticePanel = React.memo(function NoticePanel() {
  return (
    <div>
      <h3>「送信不可」ステータスについて</h3>
      <p>
        sendgridでは一度送信に失敗したアドレスにはメールを送信しない仕組みになっています。
      </p>
      <p>
        （この「送信しないメールアドレスのリスト」をサプレッションリストと呼びます。）
      </p>
      <p>
        これは、送信できない宛先に繰り返し送ることにより、はいチーズ！から送るメールがスパムと判断されることを避けるためです。
      </p>
      <p>
        「送信不可」状態が続いている場合、サプレッションリストにメールが入っている可能性があります。
      </p>
      <p>
        サプレッションリストに載っているかを確認し、再送したい場合には「削除」ボタンを押してリストから取り除いてください。
      </p>
    </div>
  );
});
