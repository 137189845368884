import React from 'react';
import { Link } from 'react-router-dom';
import { kanriUrl } from '../../../../ts/url';
import { TPhotographer } from './types';
import { dateFormatHyphenFromISO8601 } from '../../../../ts/formatTools';
import dayjs from 'dayjs';
import QueryString from 'query-string';

export const MenuLink: React.FC<{
  cameramanId: number;
  photographer: TPhotographer;
}> = ({ cameramanId, photographer }) => {
  const timingTypeEvent = 1;
  const aggregateTypePayment = 1;
  const amountConditionTypeLessThan = 1;
  const salesOtherThenSystem = -1;
  const termStartMonth = 4;

  const termStartDay =
    Number(dayjs().format('M')) < termStartMonth
      ? dayjs().subtract(1, 'y').format('YYYY') + '-04-01'
      : dayjs().format('YYYY') + '-04-01';
  const termEndDay =
    Number(dayjs().format('M')) < termStartMonth
      ? dayjs().format('YYYY') + '-03-31'
      : dayjs().add(1, 'y').format('YYYY') + '-03-31';

  const menuUrl = kanriUrl({
    action_owner_PHOTOGRAPHERRelatedInfo: 'true',
    id: String(cameramanId),
  });
  return (
    <div className="p-photographersShow_partnerMenuLinkArea">
      <div className="p-photographersShow_pageLinkArea">
        <a href={'#p-photographersShow_basic-info'}>
          <div className={'l-flex_start'}>
            <div className={'page_link_icon'}>&#8810;</div>
            <div>基本情報</div>
          </div>
        </a>
        <a href={'#p-photographersShow_detail-info'}>
          <div className={'l-flex_start'}>
            <div className={'page_link_icon'}>&#8810;</div>
            <div>業務情報</div>
          </div>
        </a>
        <a href={'#p-photographersShow_equipment'}>
          <div className={'l-flex_start'}>
            <div className={'page_link_icon'}>&#8810;</div>
            <div>機材情報</div>
          </div>
        </a>
      </div>

      <div className="c-frame">
        <h4>その他のカメラマン情報</h4>
        <ul>
          <li>
            <a href={menuUrl + '#tabs-unrequested'}>未依頼イベント</a>
          </li>
          <li>
            <a href={menuUrl + '#tabs-shuho'}>週報コピペ</a>
          </li>
          <li>
            <a href={menuUrl + '#tabs-groupsNominate'}>指名団体</a>
          </li>
          <li>
            <a href={menuUrl + '#tabs-groupsRefused'}>出入り禁止団体</a>
          </li>
          <li>
            <a href={menuUrl + '#tabs-feedbacks'}>フィードバック</a>
          </li>
          <li>
            <a href={menuUrl + '#tabs-memo'}>納品管理メモ</a>
          </li>
        </ul>
      </div>

      <div className="c-frame">
        <h4>リンク一覧</h4>
        <ul>
          <li>
            <a
              href={kanriUrl({
                action_owner_SHINSEItehailist2: 'true',
                photographer_id: String(cameramanId),
                photographingday_from:
                  dateFormatHyphenFromISO8601(dayjs()) || '',
              })}
            >
              手配リスト
            </a>
          </li>
          <li>
            <a
              href={kanriUrl({
                action_owner_SHINSEItehailist2: 'true',
                photographer_id: String(cameramanId),
                photographingday_from:
                  dateFormatHyphenFromISO8601(dayjs().subtract(1, 'year')) ||
                  '',
                photographingday_to: dateFormatHyphenFromISO8601(dayjs()) || '',
                sortbydesc: '1',
              })}
            >
              過去分手配リスト
            </a>
          </li>
          <Link
            to={`/sales?${QueryString.stringify({
              aggregateType: aggregateTypePayment,
              amountConditionType: amountConditionTypeLessThan,
              dateFrom: termStartDay,
              dateTo: termEndDay,
              photographer: photographer.id,
              primarySales: salesOtherThenSystem,
              timingType: timingTypeEvent,
            })}`}
          >
            売れ行き(今年度分)
          </Link>
          <li>
            <a
              href={kanriUrl({
                action_owner_PHOTOGRAPHERpreferredschedule: 'true',
                photographer_id: String(cameramanId),
              })}
            >
              希望スケジュール
            </a>
          </li>
          <li>
            <a
              href={kanriUrl({
                action_owner_PHOTOGRAPHERTROUBLElist: 'true',
                photographer_id: String(cameramanId),
              })}
            >
              カメラマントラブル一覧
            </a>
          </li>
          <li>
            <a
              href={kanriUrl({
                action_owner_PHOTOGRAPHERFEEDBACKnew: 'true',
                photographer_id: String(cameramanId),
              })}
            >
              フィードバック入力
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
