import React, { useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumbersInput,
  CheckBox,
  Checkboxes,
  MultiSelect,
  RadioBoxes,
  DatePicker,
} from '@/components/shared/Form/Inputs';

import {
  TFormInputs,
  TFormItems,
  TFormName,
  TFormInputDateNames,
} from './types';
import { kanriUrl } from '../../../../ts/url';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: TFormName;
  onSubmit: SubmitHandler<TFormInputs>;
};

const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit, setValue } = methods;
    const [isDetailSearchOpen, setIsDetailSearchOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    // 日付削除リンク
    type TClearDate = {
      nameLabel: string;
      target1: TFormInputDateNames;
      target2: TFormInputDateNames;
    };
    const DateLabels: React.FC<TClearDate> = (prop) => {
      const nameLabel = prop.nameLabel;
      const target1 = prop.target1;
      const target2 = prop.target2;
      const clear = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        name1: TFormInputDateNames,
        name2: TFormInputDateNames
      ) => {
        e.preventDefault();
        setValue(name1, '');
        setValue(name2, '');
      };
      return (
        <>
          <label>{nameLabel}</label>
          <p>
            <a
              href="/"
              className="c-textlink_clear"
              onClick={(e) => clear(e, target1, target2)}
            >
              期間をクリア
            </a>
          </p>
        </>
      );
    };

    /* 項目名 + 日付form「開始日~終了日」or 未定チェックボックス + 日付リセット機能 */
    type TDateForms = {
      fromDateName: TFormInputDateNames;
      toDateName: TFormInputDateNames;
      undefinedDateName: string;
      label: string;
      validator: TValidatorResponse;
    };
    const DateForms: React.FC<TDateForms> = (prop) => {
      const fromDateName = prop.fromDateName;
      const toDateName = prop.toDateName;
      const undefinedDateName = prop.undefinedDateName;
      const label = prop.label;
      const validator = prop.validator;
      return (
        <>
          <li className="c-dataLabel_hasOption">
            <DateLabels
              nameLabel={label} // ex) 撮影日
              target1={fromDateName} // ex) fromPhotographingDay
              target2={toDateName} // ex) toPhotographingDay
            />
          </li>
          <li className="c-dataValue">
            <div>
              <ul className="c-input_dateBlock">
                <li>
                  <DatePicker
                    holidays={holidays}
                    name={fromDateName}
                    validator={validator}
                  />
                </li>
                <li>
                  <DatePicker
                    holidays={holidays}
                    name={toDateName}
                    validator={validator}
                  />
                </li>
              </ul>
            </div>
            <div className="l-flex c-label_line p-eventsIndex_bgBox">
              <CheckBox
                name={undefinedDateName}
                isInline={true}
                validator={validator}
                label="未設定"
              />
            </div>
          </li>
        </>
      );
    };

    type TShipDateForms = {
      fromDateName: TFormInputDateNames;
      toDateName: TFormInputDateNames;
      undefinedDateName: string;
      radioName:
        | 'isFlyerSend'
        | 'isSbSend'
        | 'isFaxSend'
        | 'isDvdSend'
        | 'isAdvanceSbSend';
      label: string;
      validator: TValidatorResponse;
    };
    /* 項目名 + 日付form「開始日~終了日」or 未定チェックボックス + 日付リセット機能 + 発送の済否ラジオボタン */
    const ShipDateForms: React.FC<TShipDateForms> = (prop) => {
      const fromDateName = prop.fromDateName;
      const toDateName = prop.toDateName;
      const undefinedDateName = prop.undefinedDateName;
      const radioName = prop.radioName;
      const label = prop.label;
      const validator = prop.validator;

      return (
        <>
          <li className="c-dataLabel_hasOption">
            <DateLabels
              nameLabel={label}
              target1={fromDateName}
              target2={toDateName}
            />
          </li>
          <li className="c-dataValue">
            <div>
              <ul className="c-input_dateBlock">
                <li>
                  <DatePicker
                    holidays={holidays}
                    name={fromDateName}
                    validator={validator}
                  />
                </li>
                <li>
                  <DatePicker
                    holidays={holidays}
                    name={toDateName}
                    validator={validator}
                  />
                </li>
              </ul>
            </div>
            {/* 発送日 (発送の要済否全 ラジオボタン) */}
            <div className="l-flex c-label_line p-eventsIndex_bgBox">
              <CheckBox
                name={undefinedDateName}
                isInline={true}
                validator={validator}
                label="未設定"
              />
              <RadioBoxes
                name={radioName}
                validator={validator}
                choices={isFlyerSend}
                isInline={true}
              />
            </div>
          </li>
        </>
      );
    };

    // apiから受け取ったformの値を利用する
    const {
      eventOptions,
      planIds,
      partnerPlans,
      eventTypeIds,
      groupTypeIds,
      eigyoOwnerNos,
      photographerIds,
      isFlyerSend,
      flyerPreDeliveryType,
      openFlag,
      hasConv,
      hasCertificationKey,
      hasPrice,
      hasPriorConfirmation,
      removeCancelled,
      holidays,
    } = formItems;

    useEffect(() => {
      Object.values(queryParams).forEach((value) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            setIsSearchOpen(true);
          } else if (!Array.isArray(value)) {
            setIsSearchOpen(true);
          }
        }
      });
    }, [queryParams]);

    // 検索Form群メイン
    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form method="GET" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="t-bgBox_gray p-eventsIndex_searchForm">
            <div className="t-bgBox_gray u-pdt_s u-pdb_s u-mgb_s">
              <div className="l-flex_between_center">
                <b>検索条件</b>
                <div
                  className="c-btn_toggleDetails"
                  onClick={() => {
                    setIsSearchOpen((b) => !b);
                  }}
                >
                  <span className="u-spOff">
                    {isSearchOpen ? '開く' : '閉じる'}
                  </span>
                </div>
              </div>
              <div
                className={`c-accordion_content ${
                  isSearchOpen ? '' : 'is-open'
                }`}
              >
                {/* パートナー・団体・イベント ID && 名前 */}
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_m">
                  <IdNameForm
                    label="パートナー"
                    idsFormName="partnerIds"
                    nameFormName="partnerName"
                    validator={validator}
                  />
                </ul>
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <IdNameForm
                    label="団体"
                    idsFormName="societyIds"
                    nameFormName="societyName"
                    validator={validator}
                  />
                </ul>
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <li className="c-dataLabel">
                    <label>契約</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <NumbersInput
                          name="societyContractIds"
                          placeholder={
                            '契約ID (複数の場合、スペース区切りで入力)'
                          }
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <IdNameForm
                    label="イベント"
                    idsFormName="eventIds"
                    nameFormName="eventName"
                    validator={validator}
                  />
                </ul>
                {/* 申請オプション (複数選択可　チェックボックス) */}
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <li className="c-dataLabel">
                    <label>
                      申請
                      <br />
                      オプション
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <div className="t-bgBox_white">
                      <Checkboxes
                        name="eventOptions"
                        choices={eventOptions}
                        isInline={true}
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
                {/* プラン (複数選択可　チェックボックス) */}
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <li className="c-dataLabel">
                    <label>プラン</label>
                  </li>
                  <li className="c-dataValue">
                    <div className="t-bgBox_white">
                      <Checkboxes
                        name="planIds"
                        choices={planIds}
                        isInline={true}
                        validator={validator}
                      />
                      <Checkboxes
                        name="partnerPlans"
                        choices={partnerPlans}
                        isInline={true}
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
                {/* イベント・団体 種類 */}
                <div className="l-flex_between c-label_line l-flex_between__isSpInput">
                  {/* イベントの種類 (複数選択可・候補検索式) */}
                  <ul className="l-flex c-label_innerHalf">
                    <li className="c-dataLabel">
                      <label>イベント種類</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="eventTypeIds"
                        choices={eventTypeIds}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                  {/* 団体の種別 (複数選択可・候補検索式) */}
                  <ul className="l-flex c-label_innerHalf">
                    <li className="c-dataLabel">
                      <label>団体種類</label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <MultiSelect
                        name="groupTypeIds"
                        choices={groupTypeIds}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </div>
                {/* 担当営業・詳細対応者 */}
                <div className="l-flex_between c-label_line l-flex_between__isSpInput">
                  {/* 担当営業 */}
                  <ul className="l-flex c-label_innerHalf">
                    <li className="c-dataLabel">
                      <label>担当営業</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="eigyoOwnerNos"
                        choices={eigyoOwnerNos}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                  {/* 詳細対応者 */}
                  <ul className="l-flex c-label_innerHalf">
                    <li className="c-dataLabel">
                      <label>詳細対応者</label>
                    </li>
                    <li className="c-dataValue u-pdr_xs u-pdl_xs">
                      <MultiSelect
                        name="photographerIds"
                        choices={photographerIds}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </div>
                {/* 登録日 */}
                <div className="l-flex_between c-label_line l-flex_between__isSpInput">
                  {/* 登録日 */}
                  <ul className="l-flex c-label_innerHalf">
                    <li className="c-dataLabel">
                      <DateLabels
                        nameLabel="登録日"
                        target1="fromEventCreatedDay"
                        target2="toEventCreatedDay"
                      />
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DatePicker
                            holidays={holidays}
                            name={'fromEventCreatedDay'}
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DatePicker
                            holidays={holidays}
                            name={'toEventCreatedDay'}
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="u-line_dot u-mgb_m" />
            <div className="u-pdl_s u-pdr_s u-pdb_s">
              <a
                className="u-mgr_s c-textlink"
                href={kanriUrl({
                  action_owner_EVENTlist: 'true',
                  valuestartday_to: 'today',
                  valueendday_from: 'today',
                  openflag: '1',
                })}
              >
                バリューなう!
              </a>
              <a
                className="u-mgr_s c-textlink"
                href={kanriUrl({
                  action_owner_EVENTlist: 'true',
                  publishendday_from: 'today',
                  openflag: '2',
                  convertinfono: '1',
                })}
              >
                事前確認中
              </a>
            </div>
            <div className="t-bgBox_gray u-pdt_s u-pdb_s u-mgb_s">
              <div className="l-flex_between_center">
                <b>詳細検索</b>
                <div
                  className="c-btn_toggleDetails"
                  onClick={() => {
                    setIsDetailSearchOpen((b) => !b);
                  }}
                >
                  <span className="u-spOff">
                    {isDetailSearchOpen ? '閉じる' : '開く'}
                  </span>
                </div>
              </div>
              <div
                className={`c-accordion_content ${
                  isDetailSearchOpen ? 'is-open' : ''
                }`}
              >
                {/* 詳細1段目 */}
                <div className="c-label_line u-mgt_m">
                  <ul className="l-flex is-sp_input">
                    <li className="c-dataLabel_hasOption">
                      <DateLabels
                        nameLabel={'撮影日'} // ex) 撮影日
                        target1={'fromPhotographingDay'} // ex) fromPhotographingDay
                        target2={'toPhotographingDay'} // ex) toPhotographingDay
                      />
                    </li>
                    <li className="c-dataValue l-flex_center">
                      <ul className="c-input_dateBlock p-eventsIndex_hasOption">
                        <li>
                          <DatePicker
                            holidays={holidays}
                            name={'fromPhotographingDay'}
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DatePicker
                            holidays={holidays}
                            name={'toPhotographingDay'}
                            validator={validator}
                          />
                        </li>
                      </ul>
                      <div className="p-eventsIndex_bgBox u-mgl_s">
                        <Checkboxes
                          name="removeCancelled"
                          choices={removeCancelled}
                          isInline={true}
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                {/* 詳細 2段目 */}
                <div className="l-flex_between c-label_line">
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <DateForms
                      fromDateName="fromValueStartDay"
                      toDateName="toValueStartDay"
                      undefinedDateName="unDecidedValueStartDay"
                      label="掲載開始日"
                      validator={validator}
                    />
                  </ul>
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <DateForms
                      fromDateName="fromPublishEndDay"
                      toDateName="toPublishEndDay"
                      undefinedDateName="unDecidedPublishEndDay"
                      label="掲載終了日"
                      validator={validator}
                    />
                  </ul>
                </div>
                {/* 詳細 3段目 */}
                <div className="l-flex c-label_line">
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <DateForms
                      fromDateName="fromValueEndDay"
                      toDateName="toValueEndDay"
                      undefinedDateName="unDecidedValueEndDay"
                      label="バリュー終了日"
                      validator={validator}
                    />
                  </ul>
                </div>
                {/* 詳細 4段目 */}
                <div className="l-flex_between c-label_line">
                  {/* チラシ発送日 */}
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <li className="c-dataLabel_hasOption">
                      <DateLabels
                        nameLabel={'チラシ発送日'}
                        target1={'fromFlyerSendDay'}
                        target2={'toFlyerSendDay'}
                      />
                    </li>
                    <li className="c-dataValue">
                      <div>
                        <ul className="c-input_dateBlock">
                          <li>
                            <DatePicker
                              holidays={holidays}
                              name={'fromFlyerSendDay'}
                              validator={validator}
                            />
                          </li>
                          <li>
                            <DatePicker
                              holidays={holidays}
                              name={'toFlyerSendDay'}
                              validator={validator}
                            />
                          </li>
                        </ul>
                      </div>
                      {/* 発送日 (発送の要済否全 ラジオボタン) */}
                      <div className="l-flex c-label_line p-eventsIndex_bgBox">
                        <CheckBox
                          name={'unDecidedFlyerSendDay'}
                          isInline={true}
                          validator={validator}
                          label="未設定"
                        />
                        <RadioBoxes
                          name={'isFlyerSend'}
                          validator={validator}
                          choices={isFlyerSend}
                          isInline={true}
                        />
                      </div>
                      <div className="l-flex c-label_line p-eventsIndex_bgBox">
                        <RadioBoxes
                          name={'flyerPreDeliveryType'}
                          validator={validator}
                          choices={flyerPreDeliveryType}
                          isInline={true}
                        />
                      </div>
                    </li>
                  </ul>
                  {/* SB発送日 */}
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <ShipDateForms
                      fromDateName={'fromSbSendDay'}
                      toDateName={'toSbSendDay'}
                      undefinedDateName={'unDecidedSbSendDay'}
                      radioName={'isSbSend'}
                      label={'SB発送日'}
                      validator={validator}
                    />
                  </ul>
                </div>
                {/* 5段目 */}
                <div className="l-flex_between c-label_line">
                  {/* 確認用SB発送日 */}
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <ShipDateForms
                      fromDateName={'fromAdvanceSbSendDay'}
                      toDateName={'toAdvanceSbSendDay'}
                      undefinedDateName={'unDecidedAdvanceSbSendDay'}
                      radioName={'isAdvanceSbSend'}
                      label={'確認用SB発送日'}
                      validator={validator}
                    />
                  </ul>
                  {/* DVD発送 */}
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <ShipDateForms
                      fromDateName={'fromDvdSendDay'}
                      toDateName={'toDvdSendDay'}
                      undefinedDateName={'unDecidedDvdSendDay'}
                      radioName={'isDvdSend'}
                      label={'DVD発送日'}
                      validator={validator}
                    />
                  </ul>
                </div>
                {/* 6段目 */}
                <div className="l-flex_between c-label_line">
                  {/* FAX用紙発送 */}
                  <ul className="l-flex is-sp_input c-label_innerHalf">
                    <ShipDateForms
                      fromDateName={'fromFaxSendDay'}
                      toDateName={'toFaxSendDay'}
                      undefinedDateName={'unDecidedFaxSendDay'}
                      radioName={'isFaxSend'}
                      label={'FAX用紙発送日'}
                      validator={validator}
                    />
                  </ul>
                </div>
                {/* 7段目 */} {/* 公開設定 (複数選択可 チェックボックス) */}
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput">
                  <li className="c-dataLabel">
                    <label>公開設定</label>
                  </li>
                  <li className="c-dataValue">
                    <div className="t-bgBox_white">
                      <Checkboxes
                        name="openFlags"
                        choices={openFlag}
                        isInline={true}
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
                {/* 8段目 */}
                <div className="l-flex_between c-label_line">
                  {/* 写真フォルダの有無 (全有無 ラジオボタン) */}
                  <ul className="l-flex c-label_innerHalf is-sp_input">
                    <li className="c-dataLabel">
                      <label>写真フォルダの有無</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="hasConv"
                        validator={validator}
                        choices={hasConv}
                        isInline={false}
                      />
                    </li>
                  </ul>
                  {/* 価格 (全有無 ラジオボタン) */}
                  <ul className="l-flex c-label_innerHalf is-sp_input">
                    <li className="c-dataLabel">
                      <label>価格の有無</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="hasPrice"
                        validator={validator}
                        choices={hasPrice}
                        isInline={false}
                      />
                    </li>
                  </ul>
                </div>
                {/* 9段目 */}
                <div className="l-flex_between c-label_line">
                  {/* 事前確認の有無 (全有無 ラジオボタン) */}
                  <ul className="l-flex c-label_innerHalf is-sp_input">
                    <li className="c-dataLabel">
                      <label>事前確認の有無</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="hasPriorConfirmation"
                        validator={validator}
                        choices={hasPriorConfirmation}
                        isInline={false}
                      />
                    </li>
                  </ul>
                  {/* 認証キーの有無 (全有無 ラジオボタン) */}
                  <ul className="l-flex c-label_innerHalf is-sp_input">
                    <li className="c-dataLabel">
                      <label>写真閲覧キーの有無</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="hasCertificationKey"
                        validator={validator}
                        choices={hasCertificationKey}
                        isInline={false}
                      />
                    </li>
                  </ul>
                </div>
                {/* 10段目 */} {/* 認証キー (完全一致検索)*/}
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>写真閲覧キー</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <TextInput
                          name="certificationKey"
                          placeholder="例) 8-8888-8888"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            {/* 検索ボタン */}
            <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="この内容で検索"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

type TIdNameForm = {
  label: string;
  idsFormName: string;
  nameFormName: string;
  validator: TValidatorResponse;
};
export const IdNameForm: React.FC<TIdNameForm> = (prop) => {
  const label = prop.label;
  const idsFormName = prop.idsFormName;
  const nameFormName = prop.nameFormName;
  const validator = prop.validator;

  return (
    <>
      <li className="c-dataLabel">
        <label>{label}</label>
      </li>
      <li className="c-dataValue">
        <ul className="l-flex_between">
          <li className="c-label_innerHalf">
            <NumbersInput
              name={idsFormName}
              placeholder={label + 'ID (複数の場合、スペース区切りで入力)'}
              validator={validator}
            />
          </li>
          <li className="c-label_innerHalf">
            <TextInput
              name={nameFormName}
              placeholder={label + '名'}
              validator={validator}
            />
          </li>
        </ul>
      </li>
    </>
  );
};

export default SearchForm;
