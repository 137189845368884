import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { TextArea } from '@/components/shared/Form/Inputs';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type SocietyRequestProps = {
  validator: TValidator;
};

export const SocietyRequest: React.FC<SocietyRequestProps> = ({
  validator,
}) => {
  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.societyRequest.name}
      id={LIST_ITEMS.photography.societyRequest.id}
    >
      <TextArea
        name="societyRequest"
        validator={validator}
        placeholder="団体からの撮影要望を入力してください"
      />
      <div className="t-textColor_sub">
        ※撮影に関して団体から要望が出ている場合や過去の撮影で出ている情報があれば記載してください。
      </div>
    </PhotographyAccordion>
  );
};
