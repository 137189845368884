import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { fetcher } from '../../../../ts/useApi';
import { errorToast, successToast } from '../../../../ts/toast';
import PriceTable from '../../../shared/PriceTable/App';
import { isDefaultPrice } from './constants';
import {
  TUserType,
  TPhotoType,
  TPhotoSize,
} from '../../../shared/PriceTable/types';
import { TPricePattern } from './types';
import BaseModal from '@/components/shared/BaseModal';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { TextArea } from '@/components/shared/Form/Inputs';
import { patchJson } from '@/ts/fetch';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';

type PriceListProps = {
  updatePathPrefix: string;
  pricePatterns: TPricePattern[];
  userTypes: TUserType[];
  photoTypes: TPhotoType[];
  photoSizes: TPhotoSize[];
  sizeNames: string[];
  setBasePrices: (pricePatternId: number) => void;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  onUpdate: () => void;
  showIsCalculatedByTaxIncludedPrice: boolean;
};

const EditModal: React.FC<{
  handleClose: () => void;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  defaultValues: { memo?: string };
  onEditSubmit: (formValues: { memo?: string }) => void;
}> = ({ handleClose, validator, defaultValues, onEditSubmit }) => {
  const methods = useForm({ defaultValues });
  return (
    <BaseModal handleClose={handleClose}>
      <FormProvider {...methods}>
        <h4 className="c-section_title">価格のメモを変更する</h4>
        <hr className="u-line_plane" />
        <ul className="l-flex">
          <li className="c-dataLabel">
            <label>メモ</label>
          </li>
          <li className="c-dataValue">
            <TextArea name="memo" validator={validator} />
          </li>
        </ul>
        <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
          <span
            className="c-btn_large c-btn_cancel u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className="c-btn_large c-btn_primary u-mgr_s"
            onClick={methods.handleSubmit(onEditSubmit)}
          >
            確定
          </span>
        </div>
      </FormProvider>
    </BaseModal>
  );
};

const PricesList: React.FC<PriceListProps> = ({
  updatePathPrefix,
  pricePatterns,
  userTypes,
  photoTypes,
  photoSizes,
  sizeNames,
  setBasePrices,
  validator,
  onUpdate,
  showIsCalculatedByTaxIncludedPrice,
}) => {
  const history = useHistory();

  const onSubmitUpdate = useCallback(
    async (pricepatternno: number) => {
      if (window.confirm('この価格表を基本価格に設定します。')) {
        try {
          await fetcher(`${updatePathPrefix}/prices/${pricepatternno}`, {
            method: 'PATCH',
          });
          successToast('基本価格に設定しました');
          history.go(0);
        } catch (error) {
          errorToast('エラーが発生しました');
        }
      }
    },
    [updatePathPrefix, history]
  );
  const onSubmitDelete = useCallback(
    async (pricepatternno: number) => {
      if (window.confirm('この価格表を削除します。よろしいですか？')) {
        try {
          await fetcher(`/api/prices/${pricepatternno}`, {
            method: 'DELETE',
          });
          successToast('価格を削除しました');
          history.go(0);
        } catch (error) {
          errorToast('エラーが発生しました');
        }
      }
    },
    [history]
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentPricepatternno, setCurrentPricepatterno] = useState<
    number | undefined
  >(undefined);
  const onOpenEditModal = useCallback((pricepatternno: number) => {
    setCurrentPricepatterno(pricepatternno);
    setShowEditModal(true);
  }, []);
  const onEditSubmit = usePreventDuplicateCall(
    useCallback(
      async (formValues: { memo?: string }) => {
        try {
          await patchJson(`/api/prices/${currentPricepatternno}`, formValues);
          successToast('メモを更新しました');
          setShowEditModal(false);
          onUpdate();
        } catch (error) {
          errorToast('エラーが発生しました');
        }
      },
      [currentPricepatternno, onUpdate]
    )
  );

  return (
    <>
      {showEditModal && (
        <EditModal
          handleClose={() => setShowEditModal(false)}
          defaultValues={{
            memo:
              pricePatterns.find(
                (pricePattern) =>
                  pricePattern.pricepatternno === currentPricepatternno
              )?.memo || '',
          }}
          validator={validator}
          onEditSubmit={onEditSubmit}
        />
      )}
      {pricePatterns.map((pricePattern) => (
        <div
          key={pricePattern.pricepatternno}
          className={`${
            isDefaultPrice(pricePattern.defaultpriceflag)
              ? 't-bgBox_orange'
              : 't-bgBox_gray'
          } u-mgt_xs`}
        >
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              価格No. {pricePattern.pricepatternno}
            </p>
            <div className="l-flex_end">
              {!isDefaultPrice(pricePattern.defaultpriceflag) && (
                <button
                  type="button"
                  className="c-btn_rectangle c-btn_manage u-mgr_s"
                  onClick={() => onSubmitUpdate(pricePattern.pricepatternno)}
                >
                  基本価格に設定
                </button>
              )}
              <button
                type="button"
                className="c-btn_rectangle c-btn_create u-mgr_m"
                onClick={() => setBasePrices(pricePattern.pricepatternno)}
              >
                この価格をもとに新規登録
              </button>
              {pricePattern.conditions.canDelete ? (
                <button
                  type="button"
                  className="c-btn_rectangle c-btn_delete"
                  onClick={() => onSubmitDelete(pricePattern.pricepatternno)}
                >
                  <i className="c-icon c-icon_trash" />
                </button>
              ) : (
                <span title="イベントと紐付いているため削除できません">
                  <button
                    type="button"
                    className="c-btn_rectangle c-btn_delete is-disabled"
                  >
                    <i className="c-icon c-icon_trash" />
                  </button>
                </span>
              )}
            </div>
          </div>

          <div className="c-summary t-bgBox_white u-mgt_xs">
            <PriceTable
              showEditMemoButton={true}
              onEditMemo={() => onOpenEditModal(pricePattern.pricepatternno)}
              photoSizes={photoSizes}
              photoTypes={photoTypes}
              userTypes={userTypes}
              sizeNames={sizeNames}
              pricePattern={pricePattern}
              showIsCalculatedByTaxIncludedPrice={
                showIsCalculatedByTaxIncludedPrice
              }
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default PricesList;
