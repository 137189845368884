import clsx from 'clsx';
import styles from './index.module.scss';
import { FaCircleInfo } from 'react-icons/fa6';

type Props = {
  type: 'error' | 'warning';
  title: string;
  children?: React.ReactNode;
};

export const Alert: React.FC<Props> = ({ type, title, children }) => {
  return (
    <div className={`${styles.panel} ${styles[`${type}`]}`}>
      <div
        className={clsx(
          styles.title,
          children !== undefined && styles.titleWithChildren
        )}
      >
        <FaCircleInfo className={styles.icon} />
        {title}
      </div>
      <>{children}</>
    </div>
  );
};
