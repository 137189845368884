const buildQuery = (query: { [k: string]: string }) => {
  if (Object.keys(query).length === 0) {
    return '';
  }
  return '?' + new URLSearchParams(query).toString();
};

// 8122 --
const kanriUrl = (params: { [k: string]: string }): string => {
  return process.env.REACT_APP_8122_URL + 'kanri/' + buildQuery(params);
};
const partnerUrl = (params: { [k: string]: string }): string => {
  return process.env.REACT_APP_8122_URL + 'partner/' + buildQuery(params);
};

// 8122_core --
const ownerUrl = (
  path: string,
  query: { [k: string]: string } = {}
): string => {
  return process.env.REACT_APP_8122_URL + 'owner/' + path + buildQuery(query);
};

export { kanriUrl, ownerUrl, partnerUrl };
