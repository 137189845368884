import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FC, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Constants } from '../../../type';
import { PRICE_CHOICE } from '../../../../constants';
import styles from '../../Form.module.scss';

export const OverTime: FC<{
  index: number;
  constants: Constants;
  validator: TValidator;
}> = ({ index, constants, validator }) => {
  const { watch } = useFormContext();
  const isAutoCalculated = watch(
    `eventRequestPhotographers.${index}.overTimeFee.isAutoCalculated`
  );
  const [prevIsAutoCalculated, setPrevIsAutoCalculated] =
    useState<boolean>(isAutoCalculated);
  const manualChoiceClass =
    isAutoCalculated !== PRICE_CHOICE.MANUAL ? styles.requestFeeDisabled : '';
  useEffect(() => {
    if (!prevIsAutoCalculated && isAutoCalculated) {
      alert(
        '長時間手当を自動計算で保存すると長時間手当の金額・備考は削除されますのでご注意ください'
      );
    }
    setPrevIsAutoCalculated(isAutoCalculated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoCalculated]);

  return (
    <div className="c-frame">
      <div className="l-flex">
        <div className={styles.requestFeeEditLabel}>長時間手当</div>
        <div className="c-dataValue">
          <div className="l-flex">
            <div className="t-col_16">
              <div className="l-flex">
                <div>
                  <div className="t-textColor_sub">加算方法</div>
                  <RadioBoxes
                    name={`eventRequestPhotographers.${index}.overTimeFee.isAutoCalculated`}
                    choices={[
                      { key: PRICE_CHOICE.AUTO, value: '自動計算' },
                      { key: PRICE_CHOICE.MANUAL, value: '手動選択' },
                    ]}
                    validator={validator}
                  />
                </div>
                <div>
                  <div className="t-textColor_sub">金額(税込)</div>
                  <div className={`l-flex ${manualChoiceClass}`}>
                    <RadioBoxes
                      name={`eventRequestPhotographers.${index}.overTimeFee.priceType`}
                      choices={constants.overTimeFeeTypes}
                      validator={validator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="t-col_8 u-mgl_m">
              <div className="t-textColor_sub">税率</div>
              <RadioBoxes
                name={`eventRequestPhotographers.${index}.overTimeFee.consumptionTaxPercent`}
                choices={constants.consumptionTaxPercent}
                validator={validator}
              />
            </div>
          </div>
          <div className="t-textColor_sub">補足</div>
          <div className={`l-flex ${manualChoiceClass}`}>
            <TextInput
              name={`eventRequestPhotographers.${index}.overTimeFee.externalMemo`}
              validator={validator}
            />
          </div>
          <div className="t-textColor_sub">
            ※明細に表示されません。自動計算を選択すると削除されます。
          </div>
        </div>
      </div>
    </div>
  );
};
