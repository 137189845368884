import React from 'react';
import styles from './TabItems.module.scss';
import clsx from 'clsx';

export const TabItems: React.FC<{
  selected: boolean;
}> = ({ children, selected }) => {
  return (
    <li
      className={clsx(styles.tabListItem, {
        [styles.isActive]: selected,
      })}
    >
      {children}
    </li>
  );
};
