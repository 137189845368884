import { ChangeEventHandler, FC, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './FileUpload.module.scss';

export const FileUpload: FC<{
  initialFileName: string;
  onChangeFile: (file: File) => boolean;
  onDeleteFile: () => void;
  name: string;
}> = ({ initialFileName, onChangeFile, onDeleteFile, name }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(initialFileName);
  const showFileSelector = () => fileInput.current && fileInput.current.click();
  const handleDeleteFile = () => {
    onDeleteFile();
    setFileName('');
  };
  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];
    if (onChangeFile(file)) {
      setFileName(file.name);
    }
  };
  return (
    <div className="l-flex">
      <div className={clsx('c-fileUpload', styles.fileUpload)}>{fileName}</div>
      <button
        className="c-btn_rectangle c-btn_delete u-mgr_s"
        type="button"
        onClick={handleDeleteFile}
      >
        <i className="c-icon__xsmall c-icon_trash" />
      </button>
      <button
        type="button"
        className="c-btn_middle c-btn_Search"
        onClick={showFileSelector}
      >
        選択
      </button>
      <input
        className="is-hidden"
        type="file"
        ref={fileInput}
        name={name}
        onChange={handleChangeFile}
      />
    </div>
  );
};
