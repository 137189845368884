import { FC, useState } from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { TIndexResponse } from '@/components/pages/PhotographerPayments/RequestFees/IndexPage/types';
import { TMutate } from '@/ts/useJsonApi';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { putJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError } from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';
import BaseModal from '@/components/shared/BaseModal';
import {
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';

type TRegisterModalFormValues = {
  eventRequestPhotographers: {
    specialFee: {
      price: number;
      externalMemo: string;
    };
  }[];
  consumptionTaxPercent: number;
};

export const RegisterSpecialFeesModal: FC<{
  closeModal: () => void;
  checkedIds: number[];
  bulkSaveValidator: TValidator;
  constants: TIndexResponse['constants'];
  setCheckedIds: (ids: number[]) => void;
  mutate: TMutate<TIndexResponse>;
}> = ({
  closeModal,
  checkedIds,
  bulkSaveValidator,
  constants,
  setCheckedIds,
  mutate,
}) => {
  const [validator, setValidator] = useState<TValidator>(bulkSaveValidator);
  const methods = useForm<TRegisterModalFormValues>({
    defaultValues: {
      consumptionTaxPercent: constants.consumptionTaxPercent,
    },
  });
  const handleBulkSpecialFeesRegister = usePreventDuplicateCall(async () => {
    try {
      if (
        !window.confirm(
          '重要！！！「一括で登録」した場合「一括で取り消す」ことが出来ません、個別で修正する必要があります。金額や備考にお間違いはないでしょうか？'
        )
      ) {
        return;
      }
      await putJson('/api/photographer_payments/request_fees/bulk_update', {
        eventRequestPhotographers: checkedIds.map((id) => {
          return {
            id: id,
            specialFee: {
              price: methods.getValues(
                'eventRequestPhotographers.0.specialFee.price'
              ),
              externalMemo: methods.getValues(
                'eventRequestPhotographers.0.specialFee.externalMemo'
              ),
            },
            rankFee: null,
            baseFee: null,
            busyDayFee: null,
            overTimeFee: null,
            equipmentFee: {
              stairsStandQuantity: 0,
              backPaperStandQuantity: 0,
              monoblockQuantity: 0,
            },
            additionalFees: null,
          };
        }),
      });
      successToast('登録されました');
      mutate();
      setCheckedIds([]);
      closeModal();
    } catch (e) {
      if (isValidationError(e)) {
        setValidator({
          hasError: e.jsonMessage.validator.hasError ?? false,
          messages: e.jsonMessage.validator.messages ?? {},
          rules: validator.rules,
        });
        alertApiError();
      } else {
        setValidator(bulkSaveValidator);
        errorToast('エラーが発生しました');
      }
    }
  });
  return (
    <BaseModal handleClose={closeModal}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">特別報酬一括登録</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m"></div>
      </div>
      <div className="t-textColor_sub u-mgb_s">
        選択した注文金に対して一括で特別報酬の登録を行います。
      </div>
      <FormProvider {...methods}>
        <div className="l-flex">
          <div className="c-dataLabel">特別報酬</div>
          <small className="c-required">(必須)</small>
          <div className="c-dataValue">
            <div className="t-textColor_sub">金額</div>
            <div className="l-flex">
              <div>
                <TextInput
                  name="eventRequestPhotographers.0.specialFee.price"
                  validator={validator}
                />
              </div>
            </div>
            <div className="t-textColor_sub">税率</div>
            <RadioBoxes
              name="consumptionTaxPercent"
              choices={constants.consumptionTaxPercents}
              validator={validator}
            />
            <div className="t-textColor_sub">追加報酬</div>
            <p>なし</p>
            <div className="t-textColor_sub">備考</div>
            <TextArea
              name="eventRequestPhotographers.0.specialFee.externalMemo"
              validator={validator}
            />
          </div>
        </div>
        <hr className="u-line_plane" />
        <div className="u-align_center">
          <button
            type="button"
            className={`c-btn_large c-btn_primary`}
            onClick={handleBulkSpecialFeesRegister}
          >
            登録
          </button>
        </div>
      </FormProvider>
    </BaseModal>
  );
};
