import React from 'react';
import { useHistory } from 'react-router';
import QueryString from 'query-string';

import { kanriUrl, ownerUrl } from '../../ts/url';

const parseAsId = (s: string): number | undefined => {
  const converted = fullWidthToHalfWidth(s);
  if (converted.match(/^[0-9]+$/)) {
    return parseInt(converted, 10);
  }
  return undefined;
};
const fullWidthToHalfWidth = (s: string): string => {
  return s.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

const SearchButtons: React.FC<{
  searchWord: string;
  type: 'global' | 'side';
}> = React.memo(({ searchWord, type }) => {
  const history = useHistory();
  const searchGroup = (e: React.MouseEvent) => {
    e.preventDefault();
    if (parseAsId(searchWord)) {
      window.location.href = kanriUrl({
        action_Owner_GROUPsummary: 'true',
        groupsno: `${parseAsId(searchWord)}`,
      });
    } else {
      history.push(
        `/groups/?${QueryString.stringify({ globalSearch: searchWord })}`
      );
    }
  };
  const searchSociety = (e: React.MouseEvent) => {
    e.preventDefault();
    if (parseAsId(searchWord)) {
      history.push(`/societies/${parseAsId(searchWord)}`);
    } else {
      history.push(
        `/societies/?${QueryString.stringify({ globalSearch: searchWord })}`
      );
    }
  };
  const searchPartner = (e: React.MouseEvent) => {
    e.preventDefault();
    if (parseAsId(searchWord)) {
      history.push(`/partners/${parseAsId(searchWord)}`);
    } else {
      history.push(
        `/partners/?${QueryString.stringify({ globalSearch: searchWord })}`
      );
    }
  };

  const SearchEvent = (e: React.MouseEvent) => {
    e.preventDefault();
    if (parseAsId(searchWord)) {
      window.location.href = kanriUrl({
        action_owner_EVENTsummary: 'true',
        eventno: searchWord,
      });
    } else {
      history.push(
        `/events/?${QueryString.stringify({ globalSearch: searchWord })}`
      );
    }
  };
  const searchUser = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = ownerUrl('global/search/users', {
      input: searchWord,
    });
  };
  const searchOrder = (e: React.MouseEvent) => {
    e.preventDefault();
    const parsed = parseAsId(searchWord);
    const url = parsed
      ? kanriUrl({ o: parsed.toString() })
      : kanriUrl({ action_owner_ORDERlist: 'true', SearchWord: searchWord });
    window.location.href = url;
  };
  const searchPhotograph = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = kanriUrl({
      action_owner_PHOTOsearchdo: 'true',
      SearchWord: searchWord,
    });
  };

  const searchPhotographer = (e: React.MouseEvent) => {
    e.preventDefault();
    const parsed = parseAsId(searchWord);
    const url = parsed
      ? kanriUrl({ cam: parsed.toString() })
      : kanriUrl({
          action_owner_PHOTOGRAPHERlist: 'true',
          SearchWord: searchWord,
        });
    window.location.href = url;
  };

  const itemClassName =
    type === 'global'
      ? 'p-global_search_ListItem p-global_search_ListItem__'
      : 'p-side_search_ListItem ';

  return (
    <>
      <li className={`${itemClassName}society`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_group`}
          type="submit"
          name="group"
          value=""
          id="search_group"
          onClick={searchSociety}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_group"
        >
          団体（直販・先生）検索
        </label>
      </li>
      <li className={`${itemClassName}group`}>
        {/* TODO: 後でアイコン変更 */}
        <input
          className={`p-${type}_search_submit p-${type}_search_group`}
          type="submit"
          name="group"
          value=""
          id="search_group"
          onClick={searchGroup}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_group"
        >
          団体（パートナー）検索
        </label>
      </li>
      <li className={`${itemClassName}event`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_event`}
          type="submit"
          name="event"
          value=""
          id="search_event"
          onClick={SearchEvent}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_event"
        >
          イベント検索
        </label>
      </li>
      <li className={`${itemClassName}partner`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_partner`}
          type="submit"
          name="partner"
          value=""
          id="search_partner"
          onClick={searchPartner}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_partner"
        >
          パートナー検索
        </label>
      </li>
      <li className={`${itemClassName}member`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_member`}
          type="submit"
          name="user"
          value=""
          id="search_member"
          onClick={searchUser}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_member"
        >
          会員検索
        </label>
      </li>
      <li className={`${itemClassName}order`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_order`}
          type="submit"
          name="order"
          value=""
          id="order"
          onClick={searchOrder}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_order"
        >
          注文検索
        </label>
      </li>
      <li className={`${itemClassName}photo`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_photo`}
          type="submit"
          name="photo"
          value=""
          id="photo"
          onClick={searchPhotograph}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_photo"
        >
          写真検索
        </label>
      </li>
      <li className={`${itemClassName}cameraman`}>
        <input
          className={`p-${type}_search_submit p-${type}_search_cameraman`}
          type="submit"
          name="cameraman"
          value=""
          id="cameraman"
          onClick={searchPhotographer}
        />
        <label
          className={`p-${type}_search_label u-pcOff`}
          htmlFor="search_cameraman"
        >
          カメラマン検索
        </label>
      </li>
    </>
  );
});

export default SearchButtons;
