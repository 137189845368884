import React from 'react';
import styles from './index.module.scss';
import { TableColumn } from '../..';

type Props = {
  columns: TableColumn[];
  gridTemplateColumns: string;
};

export const TableHeader = React.memo<Props>(function TableHeader({
  columns,
  gridTemplateColumns,
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.row} style={{ gridTemplateColumns }}>
          {columns.map((column, index) => (
            <div key={`headerCol-${index}`} className={styles.column}>
              {column}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
