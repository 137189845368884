import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import {
  NumberInput,
  RadioBoxes,
  SingleSelect,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { kanriUrl } from '@/ts/url';
import { FC, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { FormField } from './types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import {
  isValidationError,
  renderError,
  toMessages,
  useJsonApi,
} from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';
import SvgLoading from '@/components/shared/Loading/SvgLoading';

type CreateResponse = {
  validator: TValidator;
  data: {
    consumptionTaxPercent: TChoice[];
    event: {
      id: number;
      name: string;
    };
    photographer: {
      photographerId: number;
      photographerSei: string;
      photographerMei: string;
      organizationName: string;
      organizationId: number;
    };
  };
};

const Contents: FC<{
  response: CreateResponse;
  eventRequestPhotographerId: string;
}> = ({ response, eventRequestPhotographerId }) => {
  const photographerOrganizationId = response.data.photographer.organizationId;
  const photographerId = response.data.photographer.photographerId;
  const eventId = response.data.event.id;
  const methods = useForm<FormField>({
    defaultValues: {
      photographerId,
      photographerOrganizationId,
      eventId,
      eventRequestPhotographerId,
      consumptionTaxPercent: response.data.consumptionTaxPercent.find(
        (option) => option?.isDisabled === false
      )?.key as string,
    },
  });
  const history = useHistory();
  const photographerName = `${photographerId} ${response.data.photographer.photographerSei} ${response.data.photographer.photographerMei}`;
  const photographerOrganizationName = `${response.data.photographer.organizationId} ${response.data.photographer.organizationName}`;
  const eventName = `${response.data.event.id} ${response.data.event.name}`;
  const [validator, setValidator] = useState<TValidator>(response.validator);
  const onSubmit: SubmitHandler<FormField> = usePreventDuplicateCall(
    async (formData) => {
      try {
        await postJson('/api/photographer_payments/penalties', formData);
        successToast('違約金を登録しました');
        history.push(`/photographer_payments/penalties`);
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          alertApiError();
        } else {
          setValidator(response.validator);
        }
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
    }
  );
  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '手配リスト',
            path: kanriUrl({
              action_owner_SHINSEItehailist2: 'true',
              photographingday_from: 'today',
            }),
          },
          { id: 3, title: '違約金登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">違約金登録</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <>
            <div className="l-center_wrap">
              <div className="c-frame">
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン</li>
                  <li className="c-dataValue">
                    <SingleSelect
                      isDisabled={true}
                      name="photographerId"
                      choices={[
                        { key: photographerId, value: photographerName },
                      ]}
                      validator={validator}
                    ></SingleSelect>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <SingleSelect
                      isDisabled={true}
                      name="photographerOrganizationId"
                      choices={[
                        {
                          key: photographerOrganizationId,
                          value: photographerOrganizationName,
                        },
                      ]}
                      validator={validator}
                    ></SingleSelect>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">イベント</li>
                  <li className="c-dataValue">
                    <SingleSelect
                      isDisabled={true}
                      name="eventId"
                      choices={[
                        {
                          key: eventId,
                          value: eventName,
                        },
                      ]}
                      validator={validator}
                    ></SingleSelect>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    金額(税込)
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="price"
                      validator={validator}
                    ></NumberInput>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">税率</li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="consumptionTaxPercent"
                      choices={response.data.consumptionTaxPercent}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    違約金発生理由
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextArea name="externalMemo" validator={validator} />
                    <span className="t-textColor_sub">
                      ※カメラマン側に通知される内容です
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="u-mgb_l" />
            <ButtonsFooter>
              <button className="c-btn_large c-btn_primary c-input_submit">
                登録
              </button>
            </ButtonsFooter>
          </>
        </FormProvider>
      </form>
    </div>
  );
};

export const Create: FC = () => {
  const { eventRequestPhotographerId } = QueryString.parse(
    window.location.search
  ) as { eventRequestPhotographerId: string };
  const { data: response, error } = useJsonApi<CreateResponse>(
    `/api/photographer_payments/penalties/create?${QueryString.stringify({
      eventRequestPhotographerId,
    })}`
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Contents
      response={response}
      eventRequestPhotographerId={eventRequestPhotographerId}
    />
  );
};
