import React from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { TConstants } from '@/components/pages/Events/Show/Photography/types';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';
import {
  NullableNumberInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';

export const BirthdayParty: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  return (
    <>
      <EnvironmentType
        validator={validator}
        photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
      />

      <hr className="u-line_plane" />
      <NapPhoto validator={validator} />

      <hr className="u-line_plane" />
      <div className="t-textColor_sub">誕生児個人写真</div>
      <RadioBoxes
        name="hasBirthdayChildrenPhoto"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />

      <div className="t-textColor_sub">誕生児人数</div>
      <div className="l-flex_center nowrap l-col_12">
        <div className="l-col_4">
          <NullableNumberInput
            name="birthdayChildrenCount"
            validator={validator}
          />
        </div>
        <p className="u-mgl_s">名</p>
      </div>
    </>
  );
};
