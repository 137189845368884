import React from 'react';
import { Link } from 'react-router-dom';
import { TPhotographerOrganization } from '../../types';
import '../../style.scss';

type TProps = {
  photographerOrganization: TPhotographerOrganization;
};

export const Items: React.FC<TProps> = ({ photographerOrganization }) => {
  return (
    <div className="p-photographerOrganizationIndex_grid_row">
      <div className="p-photographerOrganizationIndex_grid_value p-photographerOrganizationIndex_grid_value_1st">
        {photographerOrganization.status === 1 ? (
          <span className="p-photographerOrganizationIndex_status not_working">
            {photographerOrganization.statusName}
          </span>
        ) : photographerOrganization.status === 2 ? (
          <span className="p-photographerOrganizationIndex_status reserve">
            {photographerOrganization.statusName}
          </span>
        ) : (
          <span className="p-photographerOrganizationIndex_status working">
            {photographerOrganization.statusName}
          </span>
        )}
        <Link to={'/photographer_organizations/' + photographerOrganization.id}>
          {photographerOrganization.id + ' / ' + photographerOrganization.name}
        </Link>
      </div>
      <div className="p-photographerOrganizationIndex_grid_value p-photographerOrganizationIndex_grid_value_2nd">
        {photographerOrganization.type}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.businessRegistrationNumber}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.businessRegistrationTradeName}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.corporateNumber}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.prefecture}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.photographersCount}
      </div>
      <div className="p-photographerOrganizationIndex_grid_value">
        {photographerOrganization.internalMemo}
      </div>
    </div>
  );
};
