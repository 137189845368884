import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { deleteJson } from '../../../../ts/useApi';
import {
  successToast,
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TRemoveCertificationkeysResponse } from './types';

const Remove: React.FC<{
  bundleId: number;
  certificationkeyId: number;
  refetch: () => void;
}> = React.memo(({ bundleId, certificationkeyId, refetch }) => {
  const onClick: MouseEventHandler = useCallback(async () => {
    if (!window.confirm('写真閲覧キーの紐付けを解除します。よろしいですか？')) {
      return;
    }
    try {
      const { data, validator } =
        await deleteJson<TRemoveCertificationkeysResponse>(
          `/api/key_bundles/${bundleId}/certificationkeys`,
          {
            certificationkeyIds: [certificationkeyId],
          }
        );
      if (data === 'OK') {
        successToast('写真閲覧キーの紐付けを解除しました');
        refetch();
      } else {
        errorToastWithValidatorMessages(
          '写真閲覧キーの紐付け解除に失敗しました',
          validator.messages
        );
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [bundleId, certificationkeyId, refetch]);

  return (
    <button
      className="c-btn_small c-btn_rectangle c-btn_delete"
      onClick={onClick}
    >
      <span className="u-fz_s">紐付け解除</span>
    </button>
  );
});

const canRemoveCertificationkey = (
  canRemoveByKeyBundle: boolean,
  bundleId: number,
  certificationkeyBundleIds: number[]
): boolean =>
  canRemoveByKeyBundle && certificationkeyBundleIds.includes(bundleId);

const RemoveCertificationkeyButton: React.FC<{
  canRemove: boolean;
  bundleId: number;
  certificationkeyId: number;
  refetch: () => void;
}> = React.memo(({ canRemove, ...props }) =>
  canRemove ? (
    <Remove {...props} />
  ) : (
    <button className="c-btn_small c-btn_rectangle c-btn_delete is-disabled">
      <span className="u-fz_s">紐付け解除</span>
    </button>
  )
);

export default RemoveCertificationkeyButton;
export { canRemoveCertificationkey };
