import { Resolver, useForm, UseFormReturn } from 'react-hook-form';
import { SearchParams } from '../../hooks';
import { Props } from '.';
import { createMailLogSearchValidationSchema } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';

type MailLogSearchPanelReturn = {
  open: boolean;
  setOpen: (open: boolean) => void;
  formMethods: UseFormReturn<SearchParams>;
};

export const useMailLogSearchPanel = ({
  email,
}: Props): MailLogSearchPanelReturn => {
  const [open, setOpen] = useState(false);
  const formMethods = useForm<SearchParams>({
    resolver: zodResolver(
      createMailLogSearchValidationSchema
    ) as Resolver<SearchParams>,
    defaultValues: {
      email,
    },
  });

  useEffect(() => {
    if (!email) return;

    formMethods.setValue('email', email.replace(/ /g, '+'));
  }, [email, formMethods]);

  return {
    open,
    setOpen,
    formMethods,
  };
};
