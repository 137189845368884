import React, { useCallback, useRef } from 'react';

import { getCsv } from '@/ts/fetch';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';

import { errorToast } from '../../../../ts/toast';
import { makePathForSearchForm } from '@/ts/makePathForSearchForm';

const Download: React.FC<{
  queryParams: Record<string, unknown>;
}> = React.memo(({ queryParams }) => {
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const download = useCallback(async () => {
    const fileName = 'イベント一覧.csv';
    const tmpLink = downloadLink.current!;

    try {
      const res = await getCsv<{ data: 'NG' }>(
        makePathForSearchForm(`/api/events.csv`, '', queryParams)
      );
      if (typeof res === 'object' && res.data === 'NG') {
        errorToast('データが取得できませんでした');
        return;
      }

      tmpLink.href = makeCsvDownloadUrl(res as string);
      tmpLink.download = fileName;
      tmpLink.click();
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [queryParams]);

  return (
    <>
      <button
        type="button"
        className="c-btn_large c-btn_manage u-mgr_m"
        onClick={download}
      >
        <i className="c-icon_download t-textColor_white u-mgr_xs" />
        <span>CSVダウンロード</span>
      </button>
      <a href="/" download="" className="is-hidden" ref={downloadLink}>
        link
      </a>
    </>
  );
});

const DownloadCsv: React.FC<{
  queryParams: Record<string, unknown>;
  paginatorTotal: undefined | number;
}> = React.memo(({ queryParams, paginatorTotal }) =>
  paginatorTotal && paginatorTotal <= 5000 ? (
    <Download queryParams={queryParams} />
  ) : (
    <span title="検索結果が多すぎるためCSVをダウンロードできません。検索結果5,000件までダウンロード可能です。">
      <button
        type="button"
        className="c-btn_large c-btn_manage u-mgr_m is-disabled"
      >
        <i className="c-icon_download t-textColor_white u-mgr_xs" />
        <span>CSVダウンロード</span>
      </button>
    </span>
  )
);

export default DownloadCsv;
