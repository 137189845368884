import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { TValidator } from '@/components/shared/Form/types';
import { isValidationError, putJson } from '@/ts/useApi';
import { FC, useState } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { alertApiError } from '@/ts/formValidation';
import { Constants, FormInputs, DataResponse } from '../type';
import { Info } from './Info';
import { Payments } from './Payments';
import { createDefaultValues } from './createDefaultValue';
import { REQUEST_FEE_FORM_TYPE } from '../../constants';

export const Form: FC<{
  eventRequestPhotographers: DataResponse[];
  constants: Constants;
  initialValidator: TValidator;
  requestFeeType: number;
}> = ({
  eventRequestPhotographers,
  constants,
  initialValidator,
  requestFeeType,
}) => {
  const defaultValues = createDefaultValues(eventRequestPhotographers);
  const methods = useForm<FormInputs>({ defaultValues: defaultValues });
  const history = useHistory();
  const [validator, setValidator] = useState<TValidator>(initialValidator);
  const handleSubmit = usePreventDuplicateCall(
    async (request: DeepPartial<FormInputs>) => {
      const convertedData = {
        eventRequestPhotographers: request.eventRequestPhotographers?.map(
          (eventRequestPhotographer) => {
            if (!eventRequestPhotographer) {
              return {};
            }
            eventRequestPhotographer.rankFee = null;
            eventRequestPhotographer.baseFee = null;
            if (
              Number(eventRequestPhotographer?.requestFeeType) ===
              REQUEST_FEE_FORM_TYPE.SPECIAL_FEE
            ) {
              eventRequestPhotographer.busyDayFee = null;
              eventRequestPhotographer.overTimeFee = null;
            } else {
              eventRequestPhotographer.specialFee = null;
            }
            if (eventRequestPhotographer.additionalFees) {
              eventRequestPhotographer.additionalFees =
                eventRequestPhotographer.additionalFees
                  .filter(
                    (additional) =>
                      additional &&
                      ((additional.price !== 0 &&
                        additional.price !== undefined) ||
                        (additional.externalMemo &&
                          additional.externalMemo !== undefined))
                  )
                  .map((additionalFee) => ({
                    ...additionalFee,
                    id:
                      additionalFee?.id !== undefined
                        ? additionalFee?.id
                        : null,
                    externalMemo:
                      additionalFee?.externalMemo !== undefined
                        ? additionalFee?.externalMemo
                        : null,
                  }));
            }
            return eventRequestPhotographer;
          }
        ),
      };

      try {
        await putJson(
          '/api/photographer_payments/request_fees/bulk_update',
          convertedData
        );
        successToast('注文金を登録しました');
        if (eventRequestPhotographers.length === 1) {
          history.push(
            `/photographer_payments/request_fees?eventId=${eventRequestPhotographers[0]['eventId']}&photographerIds[]=${eventRequestPhotographers[0]['photographerId']}`
          );
          return;
        }
        history.push(`/photographer_payments/request_fees`);
      } catch (e) {
        if (isValidationError(e)) {
          setValidator({
            hasError: e.jsonMessage.validator.hasError ?? false,
            messages: e.jsonMessage.validator.messages ?? {},
            rules: validator.rules,
          });
          alertApiError();
        } else {
          setValidator(initialValidator);
          errorToast('エラーが発生しました');
        }
      }
    }
  );

  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '注文金一覧',
            path: '/photographer_payments/request_fees',
          },
          { id: 3, title: '注文金登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">注文金登録</h1>
        </div>
      </div>
      <form
        onSubmit={() => {
          return false;
        }}
      >
        <FormProvider {...methods}>
          {eventRequestPhotographers.map((eventRequestPhotographer, index) => (
            <div className="c-frame" key={eventRequestPhotographer.id}>
              <Info eventRequestPhotographer={eventRequestPhotographer} />
              <Payments
                key={eventRequestPhotographer.id}
                index={index}
                validator={validator}
                eventRequestPhotographer={eventRequestPhotographer}
                constants={constants}
                requestFeeType={requestFeeType}
              />
            </div>
          ))}
          <ButtonsFooter>
            <button
              type="button"
              className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
              onClick={() =>
                history.push('/photographer_payments/request_fees')
              }
            >
              戻る
            </button>
            <button
              type="button"
              onClick={methods.handleSubmit(handleSubmit)}
              className="c-btn_large c-btn_primary c-input_submit"
            >
              登録
            </button>
          </ButtonsFooter>
        </FormProvider>
      </form>
    </div>
  );
};
