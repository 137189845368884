import { FC } from 'react';
import styles from './ResultTable.module.scss';
import clsx from 'clsx';

type TCellProps = { colSpan?: number; rowSpan?: number };

export const Table: FC = ({ children }) => {
  return <table className={styles.table}>{children}</table>;
};
export const TableHead: FC = ({ children }) => {
  return <thead className={styles.tableHead}>{children}</thead>;
};
export const TableBody: FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};
export const TableRow: FC<{ highlighted?: boolean; disabled?: boolean }> = ({
  children,
  highlighted,
  disabled,
}) => {
  return (
    <tr
      className={clsx(
        styles.tableRow,
        highlighted && styles.highlighted,
        disabled && styles.disabled
      )}
    >
      {children}
    </tr>
  );
};
export const TableCell: FC<{ colSpan?: number; rowSpan?: number }> = ({
  children,
  colSpan,
  rowSpan,
}) => {
  const cellProps: TCellProps = {};
  if (colSpan && colSpan > 1) {
    cellProps.colSpan = colSpan;
  }
  if (rowSpan && rowSpan > 1) {
    cellProps.rowSpan = rowSpan;
  }
  return (
    <td className={styles.tableCell} {...cellProps}>
      {children}
    </td>
  );
};

export const OpenedDetail: FC = ({ children }) => {
  return (
    <tr>
      <td colSpan={9999} className={styles.openedDetailTableCell}>
        <div className={styles.openedDetailContainer}>{children}</div>
        <div className={styles.openedDetailFooter}></div>
      </td>
    </tr>
  );
};
export const InnerTable: FC = ({ children }) => {
  return <table className={styles.innerTable}>{children}</table>;
};
export const InnerTableHead: FC = ({ children }) => {
  return <thead className={styles.innerTableHead}>{children}</thead>;
};
export const InnerTableBody: FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};
export const InnerTableRow: FC<{ disabled?: boolean }> = ({
  children,
  disabled,
}) => {
  return <tr className={clsx(disabled && styles.disabled)}>{children}</tr>;
};
export const InnerTableCell: FC<{ colSpan?: number; rowSpan?: number }> = ({
  children,
  colSpan,
  rowSpan,
}) => {
  const cellProps: TCellProps = {};
  if (colSpan && colSpan > 1) {
    cellProps.colSpan = colSpan;
  }
  if (rowSpan && rowSpan > 1) {
    cellProps.rowSpan = rowSpan;
  }
  return (
    <td className={styles.innerTableCell} {...cellProps}>
      {children}
    </td>
  );
};
