import React from 'react';
import { Link } from 'react-router-dom';
import { HYPHEN } from '../../../ts/formatTools';
import { kanriUrl } from '../../../ts/url';

import { isTenantTypeSen } from './constants';
import {
  TCertificationkey,
  TPartnerCertificationkey,
  TSenGeneralCertificationkey,
  TSenLeaderStaffCertificationkey,
} from './types';

const PartnerItem: React.FC<{ certificationkey: TPartnerCertificationkey }> =
  React.memo(({ certificationkey }) => (
    <>
      <td className="c-indexList_column_xl">
        <div className="c-indexList_id c-indexList_id__short">
          <Link
            to={'/certificationkeys/' + certificationkey.id}
            className="u-mgr_xs c-textlink"
          >
            {certificationkey.key}
          </Link>
          <small>
            {certificationkey.name ? `(${certificationkey.name})` : ''}
          </small>
        </div>
        <EventsLink keyString={certificationkey.key} />
      </td>
      <td className="c-indexList_column_l">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short u-fz_s">
            <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
            {certificationkey.partnerId}
          </div>
        </div>
        <Link
          to={'/partners/' + certificationkey.partnerId}
          className="c-textlink"
        >
          {certificationkey.partnerName}
        </Link>
      </td>
      <td className="c-indexList_column_l">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
            {certificationkey.groupId}
          </div>
        </div>
        <a
          href={kanriUrl({
            action_owner_GROUPsummary: 'true',
            groupsno: String(certificationkey.groupId),
          })}
          className="c-textlink"
        >
          {certificationkey.groupName}
        </a>
      </td>
      <td className="c-indexList_column_s">{HYPHEN}</td>
    </>
  ));

const SenItemCommon: React.FC<{
  certificationkey:
    | TSenGeneralCertificationkey
    | TSenLeaderStaffCertificationkey;
}> = React.memo(({ certificationkey }) => (
  <>
    <td className="c-indexList_column_l">{certificationkey.tenantName}</td>
    <td className="c-indexList_column_l">
      <div>
        <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
          <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
          {certificationkey.societyId}
        </div>
      </div>
      <Link
        to={'/societies/' + certificationkey.societyId}
        className="c-textlink"
      >
        {certificationkey.societyName}
      </Link>
    </td>
  </>
));

const SenGeneralItem: React.FC<{
  certificationkey: TSenGeneralCertificationkey;
}> = React.memo(({ certificationkey }) => (
  <>
    <td className="c-indexList_column_xl">
      <div className="c-indexList_id c-indexList_id__short">
        <Link
          to={'/certificationkeys/' + certificationkey.id}
          className="u-mgr_xs c-textlink"
        >
          {certificationkey.key}
        </Link>
        <small>
          {certificationkey.name ? `(${certificationkey.name})` : ''}
          {certificationkey.createdSocietyContractTypeName
            ? `(${certificationkey.createdSocietyContractTypeName})`
            : ''}
        </small>
      </div>
      <EventsLink keyString={certificationkey.key} />
    </td>
    <SenItemCommon certificationkey={certificationkey} />
    <td className="c-indexList_column_s">{HYPHEN}</td>
  </>
));

const SenLeaderStaffItem: React.FC<{
  certificationkey: TSenLeaderStaffCertificationkey;
}> = React.memo(({ certificationkey }) => (
  <>
    <td className="c-indexList_column_xl">
      <div className="c-indexList_id c-indexList_id__short">
        <Link
          to={'/certificationkeys/' + certificationkey.id}
          className="u-mgr_xs c-textlink"
        >
          {certificationkey.key}
        </Link>
        <small>
          {certificationkey.name ? `(${certificationkey.name})` : ''}
        </small>
      </div>
      <EventsLink keyString={certificationkey.key} />
    </td>
    <SenItemCommon certificationkey={certificationkey} />
    <td className="c-indexList_column_s">
      <div>
        <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
          <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
          {certificationkey.societyContractId}
        </div>
      </div>
      {/* TODO：契約にリンクさせる */}
      <Link
        to={'/societies/' + certificationkey.societyId}
        className="c-textlink"
      >
        {certificationkey.societyContractTypeName}
      </Link>
    </td>
  </>
));

const EventsLink: React.FC<{
  keyString: string;
}> = React.memo(({ keyString }) => (
  <>
    <i className="c-icon c-icon__xxsmall c-icon_blank u-mgr_xs" />
    <Link
      to={'/events?certificationKey=' + keyString}
      target="_blank"
      className="c-textlink"
    >
      イベントリスト
    </Link>
  </>
));

const SearchResultItem: React.FC<{ certificationkey: TCertificationkey }> =
  React.memo(({ certificationkey }) => (
    <>
      <td className="c-indexList_column_s">{certificationkey.typeName}</td>
      {isTenantTypeSen(certificationkey.tenantType) ? (
        (certificationkey as TSenLeaderStaffCertificationkey)
          .societyContractId ? (
          <SenLeaderStaffItem
            certificationkey={
              certificationkey as TSenLeaderStaffCertificationkey
            }
          />
        ) : (
          <SenGeneralItem
            certificationkey={certificationkey as TSenGeneralCertificationkey}
          />
        )
      ) : (
        <PartnerItem
          certificationkey={certificationkey as TPartnerCertificationkey}
        />
      )}
      <td className="c-indexList_column_m">
        {certificationkey.latestBundleId ? (
          <>
            <Link
              to={'/key_bundles/' + certificationkey.latestBundleId}
              className="c-textlink"
            >
              {certificationkey.latestBundleId}
            </Link>
            {certificationkey.numberOfKeyBundle - 1 >= 1
              ? ` 他${certificationkey.numberOfKeyBundle - 1}件`
              : ''}
          </>
        ) : (
          '登録なし'
        )}
      </td>
    </>
  ));

export default SearchResultItem;
