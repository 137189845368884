import { Link, useHistory, useParams } from 'react-router-dom';
import { renderError, toMessages, useJsonApi } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import TextCopy from '@/components/shared/TextCopy/App';
import dayjs from 'dayjs';
import './style.scss';
import NoImg from '../img/noimage.jpg';
import QueryString from 'query-string';

import {
  TCertificationKey,
  TCopyConvertFolderResponse,
  TIndexData,
  TIndexResponse,
  TJobShowResponse,
  TRequestedPhotographersResponse,
  TSendInfo,
} from './types';
import { kanriUrl } from '@/ts/url';
import { numberFormat } from '@/ts/formatTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { deleteJson, fetcher, postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import SampleBook from './SampleBook';
import CategoryList from './CategoryList';
import Price from './Price';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  RadioBoxes,
  SingleSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { FormProvider, useForm } from 'react-hook-form';
import { TChoice, TValidatorResponse } from '@/components/shared/Form/types';
import { TUpdateSendStatusResponse } from '../../Index/types';
import {
  TYPES_GENERAL,
  TYPE_STAFF,
} from '@/components/shared/Certificationkeys/constants';
import OverlaySpinner from '@/components/shared/OverlaySpinner/App';
import ErrorMessages from '@/components/shared/ErrorMessages';
import Linkify from 'react-linkify';
import { fetchPdf } from '@/ts/fetch';

const DeleteButton: React.FC<{ eventId: string; data: TIndexData }> = ({
  eventId,
  data,
}) => {
  const history = useHistory();
  const handleDelete = usePreventDuplicateCall(async () => {
    if (!window.confirm('イベントを削除します。よろしいですか？')) {
      return;
    }
    try {
      await deleteJson(`/api/events/${eventId}`);
      successToast('イベントを削除しました');
      history.push(
        `/events/?societyIds[]=${data.navigation.society.societyId}`
      );
    } catch (e) {
      errorToast(
        <>
          イベントの削除に失敗しました
          <br />
          {toMessages(e)}
        </>
      );
    }
  });
  return (
    <span
      className="c-btn_rectangle c-btn_delete u-mgl_s"
      onClick={handleDelete}
    >
      <i className="c-icon c-icon__xsmall c-icon_trash" />
    </span>
  );
};

export const Summary: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<TIndexResponse>('/api/events/' + eventId);
  if (error) return renderError(error);
  if (!response) return <SvgLoading />;
  const { navigation } = response.data;
  const formatPhotographingDay = dayjs(
    response.data.main.photographingDay.photographingDay
  ).format('M/D');

  const copyText = `${formatPhotographingDay} ${navigation.tenant.tenantName} ≫ ${navigation.society.societyName}【${response.data.plan.name}】 ≫ ${navigation.event.eventName}\n${window.location.href}`;

  return (
    <div className="c-submenu_main">
      <div className="c-frame">
        <div className="l-flex_between_center p-eventsSummary_Label">
          <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
            <i className="c-icon_event" />
            {eventId}
          </span>
          {/*
                <div className="l-flex">
                  <div className="u-mgr_s">
                    <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                      登録日
                    </span>
                    <span className="u-fz_xs"></span>
                  </div>
                  <div>
                    <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                      更新日
                    </span>
                    <span className="u-fz_xs"></span>
                    <span className="u-fz_xs"></span>
                  </div>
                </div>
                */}
        </div>
        <hr className="u-line_plane" />
        <div className="l-flex_between_center u-mgb_s">
          <div className="p-partnerSummary_name">
            <div className="l-flex_align_base">
              <h4 className="c-section_title u-fz_l">
                {' '}
                {navigation.event.eventName}
              </h4>
              <TextCopy text={copyText} />
            </div>
          </div>
          <div className="l-flex_end">
            {response.data.conditions.canEdit && (
              <Link
                className="c-btn_rectangle c-btn_edit"
                to={`/society_contracts/${response.data.societyContract.id}/plans/${response.data.plan.id}/events/${eventId}/edit`}
              >
                <i className="c-icon c-icon__xsmall c-icon_edit"></i>
              </Link>
            )}
            {response.data.conditions.canDelete && (
              <DeleteButton eventId={eventId} data={response.data} />
            )}
          </div>
        </div>
        <div className="l-flex nowrap">
          <div className="c-submenu_main">
            <Main response={response} handleUpdate={() => mutate()} />
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadEventFlyer: React.FC<{
  eventno: number;
  eventFlyerName: string | null;
  eventFlyerNameEnglish: string | null;
  downloadLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
}> = ({ eventno, eventFlyerName, eventFlyerNameEnglish, downloadLinkRef }) => {
  const downloadEventFlyer = async () => {
    if (eventFlyerName) {
      try {
        const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
          `/api/events/${eventno}/flyer/download`
        );
        if (!(pdfResponse instanceof Blob)) {
          if (pdfResponse.validator.hasError) {
            errorToast(
              'チラシのダウンロードに失敗しました。再ダウンロードを試しても失敗する場合は、開発者にご連絡をください'
            );
          }
          return;
        }
        const link = downloadLinkRef.current!;
        const url = URL.createObjectURL(pdfResponse);
        link.href = url;
        link.download = eventFlyerName;
        link.click();
      } catch (e) {
        errorToast('エラーが発生しました');
      }
    }
  };
  const downloadEventFlyerEnglish = async () => {
    if (eventFlyerNameEnglish) {
      try {
        const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
          `/api/events/${eventno}/flyer/download_english`
        );
        if (!(pdfResponse instanceof Blob)) {
          if (pdfResponse.validator.hasError) {
            errorToast(
              '英語チラシのダウンロードに失敗しました。再ダウンロードを試しても失敗する場合は、開発者にご連絡をください'
            );
          }
          return;
        }
        const link = downloadLinkRef.current!;
        const url = URL.createObjectURL(pdfResponse);
        link.href = url;
        link.download = eventFlyerNameEnglish;
        link.click();
      } catch (e) {
        errorToast('エラーが発生しました');
      }
    }
  };
  return (
    <span>
      <button
        type="button"
        className={`c-btn_edit c-btn_small u-mgl_xs ${
          eventFlyerName ? '' : 'is-disabled'
        }`}
        onClick={downloadEventFlyer}
      >
        <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
        <span>イベントチラシ</span>
      </button>
      <button
        type="button"
        className={`c-btn_edit c-btn_small u-mgl_xs ${
          eventFlyerNameEnglish ? '' : 'is-disabled'
        }`}
        onClick={downloadEventFlyerEnglish}
      >
        <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
        <span>英語イベントチラシ</span>
      </button>
    </span>
  );
};

const Main: React.FC<{
  response: TIndexResponse;
  handleUpdate: (
    f?: (d: TIndexResponse | undefined) => TIndexResponse | undefined
  ) => void;
}> = ({ response, handleUpdate }) => {
  const { data } = response;
  const { navigation, main, marketing, societyContract } = data;
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  return (
    <>
      {data.main.cancelReason !== null && (
        <div className="p-eventShow_cancelBox u-pdb_s u-mgb_m">
          <div className="l-flex_center l-flex_center__isSpInput">
            <span className="c-statusLabel p-eventShow_cancelBoxLabel">
              撮影中止
            </span>
            <div className="t-bgBox_white l-flex_auto">
              {data.main.cancelReason}
            </div>
          </div>
        </div>
      )}
      <div className="t-bgBox_gray u-pdb_s u-mgb_m">ユーザ向け情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              <span className="c-idLink_hasBg">
                <i className="c-icon_partner" />
              </span>
            </li>
            <li className="c-dataValue">
              <span>千株式会社</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-eventShow_societyIdLabel">
              <span className="c-idLink_hasBg">
                <i className="c-icon_groups" />
                {navigation.society.societyId}
              </span>
            </li>
            <li className="c-dataValue">
              <Link
                className="c-textlink"
                to={`/societies/${navigation.society.societyId}`}
              >
                {navigation.society.societyName}
              </Link>
              <br />
              契約ID：{societyContract.id} (契約種別：
              {societyContract.societyContractTypeName})
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">イベント種類</li>
            <li className="c-dataValue">
              <span>{main.eventType}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">撮影日</li>
            <li className="c-dataValue">
              <span>
                {dayjs(main.photographingDay.photographingDay).format(
                  'YYYY/MM/DD'
                )}
                {main.photographingDay.photographingdayTime
                  ? ` (${main.photographingDay.photographingdayTime})`
                  : ''}
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">申請オプション</li>
            <li className="c-dataValue">
              {main.availableEventRequestOptions.map((option) => {
                return (
                  <span
                    className={`c-statusLabel ${
                      main.eventRequestOptions.includes(option.value)
                        ? 'c-statusLabel__positive'
                        : 'c-statusLabel__negative'
                    }`}
                    key={option.key}
                  >
                    {option.value}
                  </span>
                );
              })}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              前年の
              <br />
              同イベント
            </li>
            <li className="c-dataValue">
              {data.previousYearEvent && (
                <>
                  <Link
                    className="c-textlink"
                    to={`/events/${data.previousYearEvent.eventno}`}
                  >
                    {data.previousYearEvent.eventname}
                  </Link>
                  (
                  {dayjs(data.previousYearEvent.photographingday).format(
                    'YYYY-MM-DD'
                  )}{' '}
                  撮影)
                </>
              )}
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          {!!data.societyContractPlan.isEnabledOverlayText && (
            <ul className="l-flex">
              <li className="c-dataLabel">
                スナップ写真の
                <br />
                文字入れ用日付
              </li>
              <li className="c-dataValue">
                <span>{main.overlayText.mojiireDay}</span>
              </li>
            </ul>
          )}
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">掲載期間</li>
            <li className="c-dataValue">
              <PeriodTerms data={data} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">公開設定</li>
            <li className="c-dataValue">
              <span>{main.openFlagName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">年度</li>
            <li className="c-dataValue">
              <span>{main.nendo ? <>{main.nendo}年度</> : <>未定</>}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">写真閲覧キー</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray u-mgt_xs">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <CertificationKey keys={main.certificationKeys} />
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              責任者向け
              <br />
              メール送信
            </li>
            <li className="c-dataValue">
              <span>{main.associationMailSendFlag}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">販売の可否</li>
            <li className="c-dataValue">
              <span>{main.viewOnlyFlag}</span>
            </li>
          </ul>
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">イベント情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              顔検索機能
              <br />
              使用可否
            </li>
            <li className="c-dataValue">
              <span>{main.faceRecommendFlag}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              イベント期間
              <br />
              自動設定の可否
            </li>
            <li className="c-dataValue">
              <span>{main.autoEventTermSettingFlag}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">参加人数</li>
            <li className="c-dataValue">
              <span>{marketing.participants}人</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              写真閲覧キー
              <br />
              登録者数
            </li>
            <li className="c-dataValue">
              <span>{marketing.countCertificationkeyUsers}人</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              ユニーク
              <br />
              訪問者数
            </li>
            <li className="c-dataValue">
              <span>{marketing.countUniqueUserVisited}人</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">売れた写真</li>
            <li className="c-dataValue">
              <span>
                <ForkHasLink
                  text={
                    numberFormat(marketing.sellPhotos.soldInfo.count) +
                    '枚 ' +
                    numberFormat(marketing.sellPhotos.soldInfo.amount) +
                    '円'
                  }
                  url={`/events/${
                    marketing.eventno
                  }/photographs?${QueryString.stringify({
                    filter: 'sold',
                  })}`}
                  hasLink={marketing.hasLink}
                  sameDomain={true}
                />
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">予想売上</li>
            <li className="c-dataValue">
              <span>{data.request && <>{data.request.expectsales}円</>}</span>
            </li>
          </ul>
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">運用上の情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">チラシ部数</li>
            <li className="c-dataValue">
              <div>
                <span>{main.chirashiCount}</span>
                {main.isSendEventFlyer && (
                  <DownloadEventFlyer
                    eventno={data.main.eventNo}
                    eventFlyerName={main.eventFlyerName}
                    eventFlyerNameEnglish={main.eventFlyerNameEnglish}
                    downloadLinkRef={downloadLinkRef}
                  />
                )}
                {main.dateSentEventFlyerMail && (
                  <span className="u-fz_xs u-mgl_xs">
                    送信日時：
                    {dayjs(main.dateSentEventFlyerMail).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                  </span>
                )}
              </div>
              <div>
                {main.isSendEventFlyer &&
                  !main.eventFlyerName &&
                  !main.eventFlyerNameEnglish && (
                    <span className="u-fz_xs u-mgl_m">
                      ※チラシ「発送済み」がDLできない場合、同時公開イベントを確認してください。
                    </span>
                  )}
                {!main.isSendEventFlyer &&
                  !main.eventFlyerName &&
                  !main.eventFlyerNameEnglish && (
                    <span className="u-fz_xs u-mgl_m">
                      ※チラシ「発送済み」のみDLが出来ます。
                    </span>
                  )}
                <a href="/" className="is-hidden" ref={downloadLinkRef}>
                  dummy
                </a>
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">写真の並び順</li>
            <li className="c-dataValue">
              <span>{main.alignmentName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">発送日</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray u-mgt_xs">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">チラシ</li>
                      <li className="c-dataValue">
                        <SendInfo
                          sendInfo={main.chirashiSend}
                          eventId={main.eventNo}
                          statusKey="chirashisendflag"
                          canChangeStatus={main.canChangeSendStatus}
                        />
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">FAX</li>
                      <li className="c-dataValue">
                        <SendInfo
                          sendInfo={main.faxSend}
                          eventId={main.eventNo}
                          statusKey="faxsendflag"
                          canChangeStatus={main.canChangeSendStatus}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">サンプルブック</li>
                      <li className="c-dataValue">
                        <SendInfo
                          sendInfo={main.sbSend}
                          eventId={main.eventNo}
                          statusKey="sbsendflag"
                          canChangeStatus={main.canChangeSendStatus}
                        />
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">DVD</li>
                      <li className="c-dataValue">
                        <SendInfo
                          sendInfo={main.dvdSend}
                          eventId={main.eventNo}
                          statusKey="dvdsendflag"
                          canChangeStatus={main.canChangeSendStatus}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        事前確認用
                        <br />
                        サンプルブック
                      </li>
                      <li className="c-dataValue">
                        <SendInfo
                          sendInfo={main.sbJizenSend}
                          eventId={main.eventNo}
                          statusKey="sbjizensendflag"
                          canChangeStatus={main.canChangeSendStatus}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">自動掲載</li>
            <li className="c-dataValue">
              <AutoPost data={data} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">自動写植</li>
            <li className="c-dataValue">
              <AutoTypeSetting
                data={data}
                handleExecuted={() =>
                  handleUpdate((d) =>
                    d
                      ? {
                          ...d,
                          data: {
                            ...d.data,
                            buttonLinks: {
                              ...d.data.buttonLinks,
                              autoPhotoTypeSetting: {
                                ...d.data.buttonLinks.autoPhotoTypeSetting,
                                executed: true,
                              },
                            },
                          },
                        }
                      : undefined
                  )
                }
              />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              メモ <br />
              (OPへの依頼は全てバックログでお願いいたします)
            </li>
            <li className="c-dataValue">
              <Linkify>
                <div className="u-pre_wrap">{main.memo}</div>
              </Linkify>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">OP用メモ</li>
            <li className="c-dataValue">
              <Linkify>
                <div className="u-pre_wrap">{main.opMemo}</div>
              </Linkify>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              昆布
              <br />
              ダウンロード
            </li>
            <li className="c-dataValue">
              <ZippedConvertinfo data={data} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              コンバート
              <br />
              フォルダ
            </li>
            <li className="c-dataValue">
              <Convertinfo data={data} handleUpdate={handleUpdate} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">高解像度設定</li>
            <li className="c-dataValue">
              {data.main.isActivatedHighResolution ? '有効' : '無効'}
            </li>
          </ul>
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">サンプルブック情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">サンプルブック</li>
            <li className="c-dataValue">
              <SampleBook data={data} />
            </li>
          </ul>
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">価格情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24 priceTableOverwrite">
          {/*TODO: よりちゃんとした判定方法 */}
          {(data.price?.pricePattern.pricepatterncategories.length || 0) > 0 ? (
            <Price data={data} />
          ) : (
            '価格未定'
          )}
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">掲載情報</div>

      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">事前確認</li>
            <li className="c-dataValue">
              {!!data.societyContract.isRequiredPreConfirmation
                ? data.societyContract.preConfirmationTypeName
                : '無'}
            </li>
          </ul>
        </li>
      </ul>
      {!!data.userComments.priorConfirmComplete && (
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_24">
            <ul className="l-flex">
              <li className="c-dataLabel">
                事前確認
                <br />
                コメント
              </li>
              <li className="c-dataValue">
                <div className="u-pre_wrap">
                  {data.userComments.priorConfirmComplete}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      )}
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              UP作業時の
              <br />
              注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{data.societyContract.uploadingPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              集合写真の
              <br />
              専用フレーム
            </li>
            <li className="c-dataValue">
              {data.societyContract.isEnabledFrameForGroupPhoto
                ? `有（${data.societyContract.frameForGroupPhotoTypeName}）`
                : '無'}
            </li>
          </ul>
        </li>
      </ul>

      <div className="t-bgBox_gray u-pdb_s u-mgb_m">手配カメラマン情報</div>

      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              手配
              <br />
              カメラマン
            </li>
            <li className="c-dataValue">
              <Photographers data={data} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">人数指定</li>
            <li className="c-dataValue">
              {!!data.request ? (
                <>
                  <span className="t-bgBox_gray u-mgl_m u-mgt_xs u_mgb_xs">
                    ◎：{data.request.cameramanOutside}人
                  </span>
                  <span className="t-bgBox_gray u-mgl_m u-mgt_xs u_mgb_xs">
                    △：{data.request.cameramanInside}人
                  </span>
                </>
              ) : (
                <>イベント申請がありません</>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">日報</li>
            <li className="c-dataValue">
              <Nippo data={data} />
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">納品報告</li>
            <li className="c-dataValue">
              <UploadReport data={data} />
            </li>
          </ul>
        </li>
      </ul>
      {data.buttonLinks.hasConv && (
        <PhotoInfo response={response} handleUpdate={handleUpdate} />
      )}
    </>
  );
};

// 認証キー項目生成
export const CertificationKey: React.FC<{ keys: TCertificationKey[] }> = ({
  keys,
}) => {
  const staffKey = keys.find((key) => Number(key.type) === TYPE_STAFF);
  const generalKey = keys.find((key) =>
    TYPES_GENERAL.includes(Number(key.type))
  );
  return (
    <>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">関係者用キー</li>
          <li className="c-dataValue">
            {staffKey ? (
              <Link
                className="c-textlink"
                to={`/certificationkeys/${staffKey.id}`}
              >
                {staffKey.key}
              </Link>
            ) : (
              <>未定</>
            )}
          </li>
        </ul>
      </li>
      <li className="l-col_12">
        <ul className="l-flex">
          <li className="c-dataLabel">一般用キー</li>
          <li className="c-dataValue">
            {generalKey ? (
              <>
                <Link
                  className="c-textlink"
                  to={`/certificationkeys/${generalKey.id}`}
                >
                  {generalKey.key}
                </Link>
                <>{generalKey.name ? ` (${generalKey.name})` : ''}</>
              </>
            ) : (
              <>未定</>
            )}
          </li>
        </ul>
      </li>
    </>
  );
};

// コンバートフォルダ項目生成
const Convertinfo: React.FC<{ data: TIndexData; handleUpdate: () => void }> = ({
  data,
  handleUpdate,
}) => {
  const [isCopying, setIsCopying] = useState(false);
  const hasConv: boolean = data.main.convertInfo.directoryName !== null;
  const PHOTONAME_UNIQUE = '2';
  const isUnique: boolean =
    data.main.convertInfo.freePhotoName === PHOTONAME_UNIQUE;
  const handleCopy = usePreventDuplicateCall(async () => {
    try {
      setIsCopying(true);
      const response = await postJson<TCopyConvertFolderResponse>(
        `/api/events/${data.main.eventNo}/copy_convertfolder`
      );
      if (!response.data.async) {
        // 非同期化前のコード、非同期化のリリースが完了したら削除する
        successToast('昆布をコピーしました');
        window.location.href = kanriUrl({
          co: response.data.convertinfono!.toString(),
        });
        return;
      }
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const jobResponse = await fetcher<TJobShowResponse>(
          `/api/jobs/${response.data.jobId!}`
        );
        if (jobResponse.data.status === 'failed') {
          errorToast(
            <>
              昆布のコピーに失敗しました
              <br />
              {jobResponse.data.message}
            </>
          );
          return;
        } else if (jobResponse.data.status === 'success') {
          successToast('昆布をコピーしました');
          window.location.href = kanriUrl({
            co: jobResponse.data.result.convertinfono.toString(),
          });
          return;
        }
      }
    } catch (e) {
      errorToast(
        <>
          昆布のコピーに失敗しました
          <br />
          {toMessages(e)}
        </>
      );
    } finally {
      setIsCopying(false);
    }
  });
  const handleCreateEmptyConvertFolder = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(`アップロードツールが正常に動作しない場合の回避策になります。
通常の場合はアップロードツールか自動掲載をご利用ください。

空のコンバートフォルダを紐つけしますか？`)
    ) {
      return;
    }
    try {
      await postJson(
        `/api/events/${data.main.eventNo}/create_empty_convertfolder`
      );
      successToast('空のコンバートフォルダを作成しました');
      handleUpdate();
    } catch (e) {
      errorToast(
        <>
          空のコンバートフォルダの作成に失敗しました
          <br />
          {toMessages(e)}
        </>
      );
    }
  });
  if (!hasConv) {
    return (
      <div className="t-bgBox_gray">
        コンバートフォルダはありません
        <br />
        <button
          type="button"
          className="c-btn c-btn_small c-btn_manage u-mgr_s"
          onClick={handleCreateEmptyConvertFolder}
        >
          空のコンバートフォルダを紐つける
        </button>
        ※写真フォルダの紐つけは、原則として自動掲載またはアップロードツールを使用してください。
      </div>
    );
  }
  return (
    <>
      {isCopying && <OverlaySpinner />}
      <div className="t-bgBox_gray">
        {data.main.convertInfo.directoryName}
        <span className="u-fz_s u-mgl_s">
          {isUnique
            ? 'このイベントの写真はコピーできません。'
            : 'コピーするとサーバ代が月額10円-100円増加します'}
        </span>
        <br />
        <button
          type="button"
          className={`c-btn c-btn_manage c-btn_small u-mgt_xs ${
            isUnique ? 'is-disabled' : ''
          }`}
          onClick={() => handleCopy()}
        >
          コピーする
        </button>
      </div>
      <span className="p-eventShow_dataTitle">convertinfono：</span>
      {data.main.convertInfo.convertInfoNo}
      <span className="u-mgr_m" />
      <span className="p-eventShow_dataTitle">顔検索用インデックス：</span>
      {data.main.convertInfo.faceindexCopiedFlag}
      <span className="u-mgr_m" />
      <span className="p-eventShow_dataTitle">取り込み時刻：</span>
      {dayjs(data.photosInfo.convertinfo.createdday).format(
        'YYYY-MM-DD HH:mm:ss'
      )}
    </>
  );
};

type ForkHasLinkType = {
  url: string;
  text: string;
  hasLink: boolean;
  sameDomain?: boolean;
};
export const ForkHasLink: React.FC<ForkHasLinkType> = (props) => {
  if (props.hasLink) {
    if (props.sameDomain) {
      return (
        <span className="listLink">
          <Link className="c-textlink" to={props.url}>
            {props.text}
          </Link>
        </span>
      );
    } else {
      return (
        <span className="listLink">
          <a className="c-textlink" href={props.url}>
            {props.text}
          </a>
        </span>
      );
    }
  } else {
    return <span className="listLink">{props.text}</span>;
  }
};

const Photographers: React.FC<{ data: TIndexData }> = ({ data }) => {
  return (
    <div style={{ width: '100%' }}>
      <table className="p-eventShow_photographersTable">
        <tbody>
          {data.requestPhotographers.map((photographer) => (
            <tr key={photographer.id}>
              <th>
                <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                  ID: {photographer.id}
                </span>
              </th>
              <th>
                <a
                  href={kanriUrl({ cam: photographer.id.toString() })}
                >{`${photographer.sei} ${photographer.mei}`}</a>
              </th>
              <th>ランク：{photographer.rankName}</th>
              <th>社内外：{photographer.isshanaiName}</th>
              <th>補正：{photographer.nohoseiflagName}</th>
              <th>
                <Link
                  className="c-textlink"
                  to={`/sales?${QueryString.stringify({
                    photographer: photographer.id,
                    eventId: data.main.eventNo,
                    dateFrom: dayjs(
                      data.main.photographingDay.photographingDay
                    ).format('YYYY-MM-DD'),
                  })}`}
                >
                  売れ行き集計
                </Link>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Nippo: React.FC<{ data: TIndexData }> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!data.main.nippoBodies) {
    return <>なし</>;
  }
  return (
    <>
      <div className="l-flex_between_center">
        <span
          className={`c-btn_toggleDetails ${isOpen && 'is-open'}`}
          onClick={() => {
            setIsOpen((b) => !b);
          }}
        >
          あり
        </span>
      </div>
      <div
        className={`p-eventShow_nippoAccordionContent c-accordion_content ${
          isOpen ? 'is-open' : ''
        }`}
      >
        <p className="u-pre_wrap">{data.main.nippoBodies}</p>
        {data.main.attachedFiles.length > 0 && (
          <div className="t-bgBox_gray u-mgt_s">
            <div className="l-flex">
              <i className="c-icon c-icon_small c-icon_clip" />
              <div className="u-mgl_s">
                {data.main.attachedFiles.map((attachedFile) => (
                  <div
                    key={`${attachedFile.photographerId}/${attachedFile.fileName}`}
                  >
                    カメラマンID:{attachedFile.photographerId}
                    <span className="u-mgl_s" />
                    {attachedFile.photographerName}
                    <a
                      className="u-mgl_s"
                      href={attachedFile.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {attachedFile.fileName}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const UploadReport: React.FC<{ data: TIndexData }> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!data.photographerUploadReport) {
    console.log(data.photographerUploadReport);
    return <>なし</>;
  }
  return (
    <>
      <div className="l-flex_between_center">
        <span
          className={`c-btn_toggleDetails ${isOpen && 'is-open'}`}
          onClick={() => {
            setIsOpen((b) => !b);
          }}
        >
          あり
        </span>
      </div>
      <div className={`c-accordion_content ${isOpen ? 'is-open' : ''}`}>
        {data.photographerUploadReport.map((uploadReport) => (
          <div className="t-bgBox_gray u-mgt_s">
            <div className="l-flex">
              <div className="u-mgl_s">
                <div>
                  <p>
                    ----- {uploadReport.sei}
                    {uploadReport.mei} ({uploadReport.id}) -----
                  </p>
                  <p>
                    NG写真が含まれている可能性はありますか？ (
                    {uploadReport.hasngphoto})
                  </p>
                </div>
                <div className="u-mgt_s">
                  <p>
                    ----- どのカテゴリに含まれていそうかお知らせください -----
                  </p>
                  <p className="u-pre_wrap">{uploadReport.aboutngphoto}</p>
                </div>
                <div className="u-mgt_s">
                  <p>
                    ----- ※DVD納品の方は、発送方法・追跡番号をご記入ください
                    -----
                  </p>
                  <p className="u-pre_wrap">{uploadReport.memo}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ShuffleButton: React.FC<{ data: TIndexData }> = ({ data }) => {
  const url: string = kanriUrl({
    action_owner_CONVshufflethumbnails: String(true),
    convertinfono: String(data.photosInfo.convertinfo.convertinfono),
    eventno: String(data.main.eventNo),
    mypage: 'EVENTsummary',
  });
  const shuffleThumnail = () => {
    window.location.href = url;
  };
  const hasThumbnail = !!data.photosInfo.convertinfo.eventThumbnail;
  return (
    <button
      type="button"
      className="c-btn c-btn_manage c-btn_small"
      onClick={shuffleThumnail}
    >
      {hasThumbnail ? '代表写真をシャッフル' : '代表写真をセット'}
    </button>
  );
};

const SendInfo: React.FC<
  TSendInfo & {
    eventId: number;
    statusKey: string;
    canChangeStatus: boolean;
  }
> = ({ canChangeStatus, sendInfo, statusKey, eventId }) => {
  const methods = useForm({
    defaultValues: {
      [statusKey]: sendInfo.sendFlag ? 1 : 0,
    },
  });
  const sendDate = sendInfo !== undefined ? sendInfo.sendDate : '';
  const choices: TChoice[] = [
    { key: 0, value: '未発送' },
    { key: 1, value: '発送済み' },
  ];
  const { setValue, getValues } = methods;
  const handleSubmit = useCallback(
    async (data: unknown) => {
      try {
        const response = await postJson<TUpdateSendStatusResponse>(
          `/api/events/${eventId}/update_send_status`,
          data
        );
        if (response.validator.hasError) {
          if (response.validator.messages[statusKey]) {
            setValue(statusKey, 0);
            errorToast(response.validator.messages[statusKey].toString());
          } else {
            errorToast('イベントの更新に失敗しました');
          }
          return;
        }
        successToast('イベントの更新に成功しました');
      } catch (e) {
        setValue(statusKey, getValues(statusKey) === 1 ? 0 : 1);
        errorToast('イベントの更新に失敗しました');
      }
    },
    [eventId, setValue, getValues, statusKey]
  );
  useEffect(() => {
    const subscription = methods.watch((data, { type }) => {
      if (type === 'change') {
        handleSubmit(data);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, handleSubmit]);
  if (!sendInfo.isRequire) {
    return <>発送不要</>;
  }
  return (
    <FormProvider {...methods}>
      {canChangeStatus ? (
        <RadioBoxes
          choices={choices}
          isInline={true}
          name={statusKey}
          validator={{ rules: {} }}
        />
      ) : (
        <span className="u-mgr_s">
          {choices[sendInfo.sendFlag ? 1 : 0].value}
        </span>
      )}
      発送日：{sendDate}
    </FormProvider>
  );
};

type TAutoTypeSettingForm = {
  frame?: string;
  text?: string;
};

const AutoTypeSetting: React.FC<{
  data: TIndexData;
  handleExecuted: () => void;
}> = ({ data, handleExecuted }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const NENDO_UNDEFINED = 0;
  const handleTypeSetting = usePreventDuplicateCall(
    async (formData: Record<string, unknown>) => {
      if (!formData['text']) {
        alert('写植文字が入力されていません');
        return;
      }
      if (!formData['frame']) {
        alert('写植サイズが選択されていません');
        return;
      }
      if (window.confirm('写植を実行します。')) {
        try {
          await postJson(
            `/api/events/${data.main.eventNo}/auto_type_setting_photograph`,
            {
              frame: formData['frame'],
              text: formData['text'],
            }
          );
          successToast('自動写植を実行しました。');
          handleExecuted();
        } catch (e) {
          errorToast(
            <>
              自動写植の実行に失敗しました。
              <br />
              {toMessages(e)}
            </>
          );
        }
      }
    }
  );
  const defaultFrameSize = data.buttonLinks.autoPhotoTypeSetting.defaultSize[0];
  const methods = useForm<TAutoTypeSettingForm>({
    defaultValues: {
      frame: defaultFrameSize,
    },
  });
  const format = data.buttonLinks.autoPhotoTypeSetting.format;
  const formats: { key: string; value: string; requireNendo?: boolean }[] = [
    {
      key: '西暦',
      value: `${format.groupName}　${format.eventName}　${format.parsePhotographingday}`,
    },
    {
      key: '和暦',
      value: `${format.groupName}　${format.eventName}　${format.parseJapanesePhotographingday}`,
    },
    {
      key: 'リハーサル',
      value: `${format.groupName}　${format.nendo}年度　${format.eventName}`,
      requireNendo: true,
    },
  ];
  return (
    <>
      <div className="u-pre_wrap u-mgb_s">{data.main.typeSettingLog}</div>
      <div className="t-bgBox_gray">
        <FormProvider {...methods}>
          <div className="l-flex l-col nowrap">
            <div className="l-col_12">
              <TextInput name="text" validator={{ rules: {} }} ref={inputRef} />
            </div>
            <div className="l-col_12">
              <div className="l-flex">
                {formats.map((format) => {
                  return (
                    <button
                      key={format.key}
                      type="button"
                      className="c-btn c-btn_edit c-btn_small u-mgl_s"
                      onClick={() => {
                        if (
                          format.requireNendo &&
                          data.buttonLinks.autoPhotoTypeSetting.format.nendo ===
                            NENDO_UNDEFINED
                        ) {
                          alert('イベントの年度が設定されていません。');
                          return;
                        }
                        methods.setValue('text', format.value);
                        inputRef.current?.select();
                      }}
                    >
                      {format.key}
                    </button>
                  );
                })}
                <div className="p-eventShow_autoTypeSetting_frameSelect">
                  <SingleSelect
                    name="frame"
                    validator={{ rules: {} }}
                    choices={Object.keys(
                      data.buttonLinks.autoPhotoTypeSetting.selectableSizes
                    ).map((s) => ({
                      key: s,
                      value:
                        data.buttonLinks.autoPhotoTypeSetting.selectableSizes[
                          s
                        ],
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className={`c-btn c-btn_manage c-btn_small ${
              data.buttonLinks.autoPhotoTypeSetting.executed ||
              !data.buttonLinks.autoPhotoTypeSetting.isAbleExecute
                ? 'is-disabled'
                : ''
            }`}
            onClick={methods.handleSubmit(handleTypeSetting)}
          >
            {data.buttonLinks.autoPhotoTypeSetting.executed
              ? '自動写植実行中'
              : !data.buttonLinks.autoPhotoTypeSetting.isAbleExecute
              ? '自動写植は実行できません'
              : '自動写植する'}
          </button>
        </FormProvider>
      </div>
    </>
  );
};

const PeriodTerms: React.FC<{ data: TIndexData }> = ({ data }) => {
  const { main } = data;
  if (!main.periodTerms.valueStartDay) {
    return <>未定</>;
  }
  return (
    <div className="t-bgBox_gray u-mgt_xs">
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">掲載開始日</li>
            <li className="c-dataValue">
              {dayjs(main.periodTerms.valueStartDay).format('YYYY/MM/DD')}
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          {!!data.societyContractPlan.isEnabledOutOfPeriodSales && (
            <ul className="l-flex">
              <li className="c-dataLabel">バリュー終了日</li>
              <li className="c-dataValue">
                {dayjs(main.periodTerms.valueEndDay).format('YYYY/MM/DD')}
              </li>
            </ul>
          )}
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">掲載終了日</li>
            <li className="c-dataValue">
              {dayjs(main.periodTerms.publishEndDay).format('YYYY/MM/DD')}
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">責任者終了日</li>
            <li className="c-dataValue">
              {!!main.periodTerms.leaderEndDay
                ? dayjs(main.periodTerms.leaderEndDay).format('YYYY/MM/DD')
                : '未定'}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const ZippedConvertinfo: React.FC<{ data: TIndexData }> = ({ data }) => {
  const handleCreateZip = usePreventDuplicateCall(async () => {
    try {
      await postJson(`/api/events/${data.main.eventNo}/create_zip`);
      successToast('ZIPファイルの作成を開始しました');
    } catch (e) {
      errorToast(
        <>
          ZIPファイルの作成開始に失敗しました
          <br />
          {toMessages(e)}
        </>
      );
    }
  });
  if (data.zippedConvertinfo) {
    return (
      <div className="t-bgBox_gray u-mgt_xs">
        <div className="c-section_title">ダウンロードの準備が整いました。</div>
        <div className="u-fz_xs">
          このzipファイルは4日間で削除されます。（ディスク容量削減のため）
          <br />
          4日以内にダウンロードをしてください。
          <br />
          回転、差し替え、カテゴリ名変更等をした場合は再作成ボタンを押してください。
          <br />
          しばらく待っていただくと、新しいzipファイルが作成されます。
        </div>
        <a
          className="c-btn c-btn_create c-btn_small"
          href={data.zippedConvertinfo.url}
          download
        >
          ダウンロードする
        </a>
        作成日時：
        {dayjs(data.zippedConvertinfo.createdday).format('YYYY-MM-DD HH:mm:ss')}
        <button
          type="button"
          className="c-btn c-btn_create c-btn_small u-mgl_m"
          onClick={handleCreateZip}
        >
          再作成する
        </button>
      </div>
    );
  } else {
    return (
      <div className="t-bgBox_gray u-mgt_xs">
        <div className="c-section_title">
          昆布をダウンロードするためにZipファイルを作成します
        </div>
        <div className="u-fz_xs">
          Zip作成には写真の枚数に応じた時間がかかるため、右のボタンをクリックしたのち、最大一時間程度待った上で再読み込みしてください。
          <br />
          一時間程度経過した後にこのページを再読み込みしてもダウンロードリンクが作成されていなかった場合、Zip作成に失敗している可能性があるので、開発Gまでお問い合わせください。
        </div>
        <button
          type="button"
          className="c-btn c-btn_create c-btn_small"
          onClick={handleCreateZip}
        >
          ZIP作成する
        </button>
      </div>
    );
  }
};

const PhotoInfo: React.FC<{
  response: TIndexResponse;
  handleUpdate: () => void;
}> = ({ response, handleUpdate }) => {
  const { data } = response;
  const { photosInfo } = data;
  return (
    <>
      <div className="t-bgBox_gray u-pdb_s u-mgb_m">写真情報</div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">写真番号</li>
            <li className="c-dataValue">
              {photosInfo.convertinfo.minPhotoName}〜
              {photosInfo.convertinfo.maxPhotoName}
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">写真枚数</li>
            <li className="c-dataValue">
              {photosInfo.convertinfo.photosCount}枚（内非表示
              {photosInfo.convertinfo.hiddenPhotosCount}枚)
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              掲載
              <br />
              カメラマン
            </li>
            <li className="c-dataValue">
              <table className="p-eventShow_photographersTable">
                <tbody>
                  {photosInfo.photographers.map((photographer) => (
                    <tr key={photographer.id}>
                      <th>
                        <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                          ID: {photographer.id}
                        </span>
                      </th>
                      <th>
                        {!!photographer.sei && (
                          <a
                            href={kanriUrl({ cam: photographer.id.toString() })}
                          >{`${photographer.sei} ${photographer.mei}`}</a>
                        )}
                      </th>
                      <th>
                        {!!photographer.sei && (
                          <Link
                            className="c-textlink"
                            to={`/sales?${QueryString.stringify({
                              photographer: photographer.id,
                              eventId: data.main.eventNo,
                              dateFrom: dayjs(
                                data.main.photographingDay.photographingDay
                              ).format('YYYY-MM-DD'),
                            })}`}
                          >
                            売れ行き集計
                          </Link>
                        )}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode ">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">代表写真</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray u-mgt_s l-flex_center">
                {/* 写真 */}
                <img
                  src={
                    photosInfo.convertinfo.eventThumbnail?.thumbnailImgUrl ||
                    NoImg
                  }
                  className="p-eventShow_thumbnailImage u-mgr_s"
                  alt="サムネイル"
                />
                <ShuffleButton data={data} />
              </div>
            </li>
          </ul>
          {!photosInfo.convertinfo.eventThumbnail && (
            <div className="c-alert_text">
              イベント代表写真がセットされていません。
              <br />
              この状態では、ユーザ側には「準備中」と表示されます。
              <br />
              下記ボタンをクリックして代表写真をセットしてください。
            </div>
          )}
          <div>代表写真のシャッフルは、会員画面にも反映されます。</div>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode ">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel"></li>
            <li className="c-dataValue">
              <CategoryList
                data={data}
                formItems={response.formItems}
                validators={response.validators}
                handleUpdate={handleUpdate}
              />
              <div className="t-bgBox_brown u-mgt_xs">
                ・親カテゴリが非表示の場合は、配下のカテゴリ・写真は全て非表示扱いになります。
                <br />
                ・カテゴリが非表示の場合は、配下の写真は全て非表示扱いになります。
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

const AutoPost: React.FC<{ data: TIndexData }> = ({ data }) => {
  const { main } = data;
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const handleAutoPost = usePreventDuplicateCall(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!window.confirm('自動掲載処理を開始しますか？')) {
        return;
      }
      try {
        setErrorMessages([]);
        const eventId = data.main.eventNo;
        const { data: requestedPhotographers } =
          await fetcher<TRequestedPhotographersResponse>(
            `/api/events/${eventId}/requested_photographers`
          );
        if (requestedPhotographers.length === 0) {
          errorToast('撮影したカメラマンがいません');
          return;
        }
        const unreportedPhotographers = requestedPhotographers.filter(
          (photographer) => photographer.numberofphoto === null
        );
        if (unreportedPhotographers.length > 0) {
          if (
            !window.confirm(
              `以下のカメラマンが納品報告していません\n${unreportedPhotographers
                .map(
                  (photographer) =>
                    `ID:${photographer.id} ${photographer.sei} ${photographer.mei}`
                )
                .join('\n')}\n実行しますか？`
            )
          ) {
            return;
          }
        }
        await postJson(`/api/events/${eventId}/auto_post_photographs`);
        successToast('自動掲載処理を開始しました。');
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('自動掲載の開始に失敗しました');
      }
    }
  );
  return (
    <>
      <ErrorMessages messages={errorMessages} />
      <span>
        {!!main.autoPostStatus && (
          <div className="u-pre_wrap">{main.autoPostStatus}</div>
        )}
        {data.buttonLinks.isAutoPostExecuted ? (
          <>自動掲載は実行中です</>
        ) : (
          data.buttonLinks.isAbleAutoPost && (
            <p>
              実行できます
              <br />
              <button
                type="button"
                className="c-btn c-btn_manage c-btn_small"
                onClick={handleAutoPost}
              >
                自動掲載を実行する
              </button>
            </p>
          )
        )}
      </span>
    </>
  );
};
