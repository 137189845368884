import { TValidator } from '@/components/shared/Form/types';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  InnerTable,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import clsx from 'clsx';
import { FiPlus } from 'react-icons/fi';
import { Alert } from '@/components/shared/Alert';
import { useParams } from 'react-router-dom';
import { ownerUrl } from '@/ts/url';
import {
  HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_FALSE,
  HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_TRUE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_CANCEL,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_HAS_CHANGE,
  RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_NO_CHANGE,
  RAINY_DAY_ACTION_ID_NO_CANCEL,
  RAINY_DAY_ACTION_ID_CANCEL,
  RAINY_DAY_ACTION_ID_POSTPONE,
} from '@/components/pages/Events/Show/Photography/const';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { LIST_ITEMS } from '../../listItem';

type RouteParams = {
  eventId: string;
};

export const RainyDayAction: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
}> = ({ photography, constants, validator }) => {
  const methods = useFormContext<TFormInputs>();
  const { eventId } = useParams<RouteParams>();
  const { control, watch } = methods;
  const { append, remove } = useFieldArray({
    control,
    name: 'rainyPhotographingPlaces',
  });
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'rainyDayActionForPhotographerId' && type === 'change') {
        if (
          value.rainyDayActionForPhotographerId !==
          RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE
        ) {
          methods.setValue('rainyPhotographingPlaces', [
            { name: '', address: '' },
          ]);
          methods.setValue('rainyChangePoint', '');
          methods.setValue('hasMeetingPlaceChangeWhenRainy', 0);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [methods]);
  const places = methods.watch('rainyPhotographingPlaces');

  const addPlace = () => {
    append({ name: '', address: '' });
  };

  const deletePlace = (index: number) => {
    remove(index);
  };
  const rainyMeetingPlace =
    photography.rainyMeetingPlace === '園'
      ? '園'
      : photography.rainyMeetingPlaceMemo;
  const rainyPhotographingPlace =
    photography.rainyPhotographingPlace === '園'
      ? '園'
      : photography.rainyPhotographingPlaceMemo;
  return (
    <PhotographyAccordion
      id={LIST_ITEMS.dealingWithUnexpectedSituations.rainyDayAction.id}
      title={LIST_ITEMS.dealingWithUnexpectedSituations.rainyDayAction.name}
    >
      <div className="t-bgBox_gray u-fz_m u_mgb_s">
        {photography.rainyDayActionName ?? '未定'}
        {photography.rainyDayActionId === RAINY_DAY_ACTION_ID_POSTPONE && (
          <>
            （延期日：
            {photography.rainyPostponementDays.length > 0
              ? photography.rainyPostponementDays
                  .map((day) => dayjs(day).format('YYYY/MM/DD'))
                  .join(', ')
              : '未定'}
            ）
          </>
        )}
      </div>

      <div className="t-textColor_sub u-fz_s">
        ※申請サマリの登録情報を表示しています。
      </div>

      <Alert
        type="error"
        title="決行／延期（延期日）／中止の情報は詳細メールには表示されません。"
      />
      <hr className="u-line_plane" />
      <div className="t-textColor_sub">対応方針</div>
      <RadioBoxes
        name="rainyDayActionForPhotographerId"
        choices={[
          {
            key: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_NO_CHANGE,
            value: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_NO_CHANGE,
            isDisabled:
              photography.rainyDayActionId !== RAINY_DAY_ACTION_ID_NO_CANCEL,
          },
          {
            key: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE,
            value: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_HAS_CHANGE,
            isDisabled:
              photography.rainyDayActionId === RAINY_DAY_ACTION_ID_CANCEL,
          },
          {
            key: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_CANCEL,
            value: RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_TEXT_CANCEL,
            isDisabled:
              photography.rainyDayActionId === RAINY_DAY_ACTION_ID_NO_CANCEL,
          },
        ]}
        validator={validator}
      />
      {watch('rainyDayActionForPhotographerId') ===
        RAINY_DAY_ACTION_FOR_PHOTOGRAPHER_ID_HAS_CHANGE && (
        <>
          <div className="t-textColor_sub">変更点</div>
          <TextArea
            name="rainyChangePoint"
            validator={validator}
            placeholder="雨天時変更点を入力してください"
            rows={7}
          />
          <div className="t-textColor_sub u-fz_s">
            ※タイムスケジュールなど変更が入る場合は記載してください。
          </div>

          <hr className="u-line_plane" />
          <div className="t-textColor_sub">集合場所</div>
          <RadioBoxes
            name="hasMeetingPlaceChangeWhenRainy"
            choices={[
              {
                key: 1,
                value: HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_TRUE,
              },
              {
                key: 0,
                value: HAS_MEETING_PLACE_CHANGE_WHEN_RAINY_TEXT_FALSE,
              },
            ]}
            validator={validator}
          />
          {rainyMeetingPlace && watch('hasMeetingPlaceChangeWhenRainy') === 1 && (
            <div>
              <div className="t-bgBox_gray">{rainyMeetingPlace}</div>
              <div className="t-textColor_sub u-fz_s">
                ※申請サマリの登録情報を表示しています。
              </div>
            </div>
          )}

          <hr className="u-line_plane" />
          <div className="t-textColor_sub">撮影場所</div>
          {rainyPhotographingPlace && (
            <div>
              <div className="t-bgBox_gray">{rainyPhotographingPlace}</div>
              <div className="t-textColor_sub u-fz_s">
                ※申請サマリの登録情報を表示しています。
              </div>
            </div>
          )}
          <Alert type="error" title="場所の複数登録について">
            <p>
              撮影場所の名前が変更になった場合は申請サマリ編集より対応してください。複数場所がある場合は名称のみを申請サマリに登録し、住所は当画面で必要な数だけ項目を追加して登録してください。
            </p>
            <a
              className="c-btn c-btn_middle_wide c-btn_black"
              href={ownerUrl(`events/requests/${eventId}`)}
              target="_blank"
              rel="noreferrer"
            >
              申請サマリ編集
            </a>
          </Alert>
          <InnerTable>
            <InnerTableHead>
              {places.map((_, index) => (
                <InnerTableRow key={index}>
                  <InnerTableCell>
                    <span>施設名</span>
                    <TextInput
                      name={`rainyPhotographingPlaces.${index}.name`}
                      validator={validator}
                    />
                    <span>住所</span>
                    <TextInput
                      name={`rainyPhotographingPlaces.${index}.address`}
                      validator={validator}
                    />
                  </InnerTableCell>
                  <InnerTableCell>
                    <div className="u-align_center">
                      <button
                        type="button"
                        className={clsx(
                          'c-btn_small c-btn_delete c-btn_middle_wide',
                          { 'is-disabled': index === 0 }
                        )}
                        onClick={() => deletePlace(index)}
                      >
                        削除
                      </button>
                    </div>
                  </InnerTableCell>
                </InnerTableRow>
              ))}
            </InnerTableHead>
          </InnerTable>
          <button
            type="button"
            className="c-btn c-btn_large c-btn_gray_outline u-mgt_s"
            onClick={addPlace}
          >
            <FiPlus />
            追加
          </button>
        </>
      )}
    </PhotographyAccordion>
  );
};
