import dayjs from 'dayjs';

export const currentFiscalYearParam = (
  startMonthOfFiscalYear = 4
): Record<string, unknown> => {
  const now = dayjs();
  const from = (
    now.month() >= startMonthOfFiscalYear ? now : now.subtract(1, 'year')
  )
    .month(startMonthOfFiscalYear - 1) // dayjsのmonthは0-originなので
    .startOf('month');
  const to = from.add(1, 'year').subtract(1, 'day');
  return {
    dateFrom: from.format('YYYY-MM-DD'),
    dateTo: to.format('YYYY-MM-DD'),
    photographingDayFrom: from.format('YYYY-MM-DD'),
    photographingDayTo: to.format('YYYY-MM-DD'),
  };
};
