import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import {
  toggleCheck,
  toggleCheckAll,
} from '../../../../ts/toggleAllSelectableCheckboxes';
import { TAllSelectableChecks } from '../../../../types';
import {
  numberOfArranged,
  sortDecidedPhotographers,
} from '../requestPhotographer';
import Photographers from '../Photographers';
import { TEvent, TEventPhotographersCheck } from './types';
import { TPhotographerShort } from '../types';

const Events: React.FC<{
  events: TEvent[];
  selectedEvents: TEvent[];
  setSelectedEvents: Dispatch<SetStateAction<TEvent[]>>;
  eventChecks: TAllSelectableChecks;
  setEventChecks: React.Dispatch<React.SetStateAction<TAllSelectableChecks>>;
  eventPhotographersChecks: TEventPhotographersCheck[];
}> = React.memo(
  ({
    events,
    selectedEvents,
    setSelectedEvents,
    eventChecks,
    setEventChecks,
    eventPhotographersChecks,
  }) => {
    const onChangeCheckAll: ChangeEventHandler = useCallback(() => {
      toggleCheckAll(setEventChecks);
    }, [setEventChecks]);
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        toggleCheck(e.target, setEventChecks);
      },
      [setEventChecks]
    );
    const showEventPhotographers = useCallback(
      (event: TEvent) => {
        setSelectedEvents((selectedEvents) => {
          if (selectedEvents.some((e) => e.id === event.id)) {
            // イベント選択済み→選択解除（該当イベントの一覧を非表示にする）
            return selectedEvents.filter((e) => e.id !== event.id);
          } else {
            // NOTE: 現時点のカメラマン選択状態をキャッシュして渡す
            // フォームのeventChecksと親component(eventPhotographersChecks)のstateを相互依存させないようにするため
            event.selectedPhotographerIds =
              eventPhotographersChecks
                .find((ec) => ec.eventId === event.id)
                ?.photographerChecks.filter((pc) => pc.checked)
                .map((pc) => pc.id) ?? [];
            if (selectedEvents.length >= 2) {
              // 選択したイベントを2個目のイベントと入れ替え
              return [selectedEvents[0], event];
            } else {
              // イベント選択追加選択（該当イベントの一覧を表示する）
              return [...selectedEvents, event];
            }
          }
        });
      },
      [setSelectedEvents, eventPhotographersChecks]
    );

    return (
      <>
        <div className="l-flex_between_center p-recommendedPhotographers_events_header">
          <div className="l-flex_auto">
            <label className="c-checkbox_listbox">
              <input
                type="checkbox"
                className="c-checkbox_list"
                name="check-all"
                value="all"
                checked={eventChecks.checkedAll}
                onChange={onChangeCheckAll}
              />
              {/* NOTE: レイアウトの都合で必要な空のelement */}
              <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
            </label>
          </div>
          <div className="l-flex_align_end">
            ({eventChecks.checks.filter((c) => !c.disabled && c.checked).length}{' '}
            / {eventChecks.checks.length} 選択)
          </div>
        </div>
        <ul className="p-recommendedPhotographers_events">
          {events.map((event) => {
            const check = eventChecks.checks.find((c) => c.id === event.id)!;
            const arrangedPhotographers = event.arrangedPhotographers.filter(
              (p) => !p.isTemporary && !p.isTrainee
            );
            const photographerChecks = eventPhotographersChecks
              .find((epc) => epc.eventId === event.id)!
              .photographerChecks.filter((pc) => pc.checked);

            return (
              <li
                key={event.id}
                className={
                  'p-recommendedPhotographers_events_event' +
                  (selectedEvents[0]?.id === event.id
                    ? ' p-recommendedPhotographers_events_event__selected_first'
                    : selectedEvents[1]?.id === event.id
                    ? ' p-recommendedPhotographers_events_event__selected_second'
                    : '')
                }
              >
                <div className="l-flex_between_center">
                  <div className="p-recommendedPhotographers_events_event_check">
                    <label className="c-checkbox_listbox">
                      <input
                        type="checkbox"
                        className="c-checkbox_list"
                        name="eventChecks[]"
                        value={check.id}
                        checked={check.checked}
                        onChange={onChangeCheck}
                      />
                      {/* NOTE: レイアウトの都合で必要な空のelement */}
                      <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
                    </label>
                  </div>
                  <div className="p-recommendedPhotographers_events_event_info">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        showEventPhotographers(event);
                      }}
                    >
                      <p>
                        {event.name} (
                        {numberOfArranged(event.arrangedPhotographers)} /{' '}
                        {event.numberOfRequiredPhotographers})
                      </p>
                      <Photographers
                        photographers={[
                          ...(photographerChecks as unknown as TPhotographerShort[]),
                          ...sortDecidedPhotographers(arrangedPhotographers),
                        ]}
                      />
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
);

export default Events;
