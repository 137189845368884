import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import {
  TConditions,
  TEvents,
  TFormItems,
  TSendInfos,
  TUpdateSendStatusResponse,
} from './types';
import { kanriUrl } from '../../../../ts/url';
import { DatePicker } from '@/components/shared/Form/Inputs';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '../../../../ts/toast';

type TProps = {
  events: TEvents;
  conditions: TConditions;
  formItems: TFormItems;
};
const OPEN_STATUS_CLOSE = 0;
const OPEN_STATUS_NOT_POSTED = 5;
const OPEN_STATUS_EDIT_WORKING = 6;

const isClose = (status: number) =>
  status === OPEN_STATUS_CLOSE ||
  status === OPEN_STATUS_NOT_POSTED ||
  status === OPEN_STATUS_EDIT_WORKING;

const Switch: React.FC<{ name: string }> = ({ name }) => {
  const { register } = useFormContext();
  return (
    <div className="p-eventsIndex_switchBox">
      未発送
      <label className="c-switch_label c-switch_label__small u-mgl_xs u-mgr_xs">
        <input
          type="checkbox"
          className="c-switch_input"
          {...register(name)}
          data-testid={name}
        />
        <span className="c-switch_content"></span>
        <span className="c-switch_circle"></span>
      </label>
      発送済み
    </div>
  );
};

const DateCell: React.FC<{
  conditions: TConditions;
  sendInfo: TSendInfos;
  eventId: number;
  isSendName: string;
  dateName: string;
  holidays: Record<string, string>;
}> = ({ conditions, sendInfo, eventId, isSendName, dateName, holidays }) => {
  const methods = useForm({
    defaultValues: {
      [isSendName]: sendInfo.isSend,
      [dateName]: sendInfo.sendDate,
    },
  });
  const { watch, setValue } = methods;
  const onSubmit: SubmitHandler<{ [a: string]: unknown }> =
    usePreventDuplicateCall(
      useCallback(
        async (data) => {
          const day = dayjs(data[dateName] as string);
          if (
            day.day() === 0 ||
            day.day() === 6 ||
            holidays[data[dateName] as string]
          ) {
            setValue(dateName, sendInfo.sendDate);
            errorToast('祝日または土日の発送日設定はできません');
            return;
          }
          try {
            const response = await postJson<TUpdateSendStatusResponse>(
              `/api/events/${eventId}/update_send_status`,
              {
                ...data,
                [isSendName]: data[isSendName] ? 1 : 0,
              }
            );
            if (response.data[isSendName]) {
              setValue(isSendName, parseInt(response.data[isSendName]));
            }
            if (response.validator.hasError) {
              errorToast(response.validator.messages[isSendName].toString());
              return;
            }
            successToast('イベントを更新しました');
          } catch (e) {
            errorToast('エラーが発生しました');
          }
        },
        [eventId, isSendName, setValue, sendInfo, holidays, dateName]
      )
    );
  // watchするだけだとdatepicker上で月を移動した時にも走ってしまうのでdebounceする
  const debouncedSubmit = useDebouncedCallback((data) => onSubmit(data), 1000);
  useEffect(() => () => debouncedSubmit.flush());
  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      // 手でsetValueした場合と区別するためにtypeを見る
      if (name === isSendName && type === 'change') {
        debouncedSubmit(methods.getValues());
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [debouncedSubmit, onSubmit, watch, isSendName, methods]);
  if (!sendInfo.required) {
    return <></>;
  }
  if (conditions.showDateEditForm) {
    return (
      <FormProvider {...methods}>
        <form>
          <div className="l-flex_center_line nowrap">
            <div>
              <DatePicker
                holidays={holidays}
                validator={{ rules: {} }}
                name={dateName}
                additionalClassName="c-input_datePicker__xsmall u-fz_xs"
                onBlur={(_) => debouncedSubmit(methods.getValues())}
                onClear={() => debouncedSubmit(methods.getValues())}
              />
              <Switch name={isSendName} />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  } else {
    return (
      <>
        {sendInfo.sendDate}
        <br />
        {sendInfo.isSendStatusName}
      </>
    );
  }
};

const SearchResultItem: React.FC<TProps> = ({
  events,
  conditions,
  formItems: { holidays },
}) => (
  <>
    <div
      className={`p-eventsIndex_grid_row${
        isClose(events.openStatusId) ? ' p-eventsIndex_grid_row__close' : ''
      }`}
    >
      {/* パートナー */}
      <div className="p-eventsIndex_grid_value p-eventsIndex_grid_value_1st">
        <div>
          <span className="c-idLink_hasBg u-mgb_xs l-flex_center">
            <i className="c-icon_partner" />
            <span className="c-textOmit">{events.partnerId ?? ''}</span>
          </span>
          <div className="c-textOmit">
            {events.partnerId ? (
              <Link to={'/partners/' + events.partnerId}>
                {events.partnerName}
              </Link>
            ) : (
              <>千株式会社</>
            )}
          </div>
        </div>
      </div>
      {/* 団体 */}
      <div className="p-eventsIndex_grid_value p-eventsIndex_grid_value_2nd">
        <div>
          <span className="c-idLink_hasBg u-mgb_xs l-flex_center">
            <i className="c-icon_groups" />
            <span className="c-textOmit">
              {events.societyId ?? events.groupId}
            </span>
          </span>
          <div className="p-eventsIndex_grid_wordBreak">
            {events.societyId ? (
              <Link to={'/societies/' + events.societyId}>
                {events.societyName}
              </Link>
            ) : (
              <a
                href={kanriUrl({
                  action_owner_GROUPsummary: 'true',
                  groupsno: String(events.groupId),
                })}
              >
                {events.groupName}
              </a>
            )}
          </div>
        </div>
      </div>
      {/* 撮影日 */}
      <div className="p-eventsIndex_grid_value p-eventsIndex_grid_value_3rd">
        <div className="p-eventsIndex_pdt_xs">
          {dayjs(events.photographingday).format('YYYY/MM/DD')}
        </div>
      </div>
      {/* イベント */}
      <div className="p-eventsIndex_grid_value p-eventsIndex_grid_value_4th">
        <div>
          <div className="l-flex_between u-mgb_xs">
            <span className="c-idLink_hasBg l-flex_center">
              <i className="c-icon_event" />
              <span className="c-textOmit">{events.eventId}</span>
            </span>
          </div>
          <div className="l-flex_between u-w_100">
            <p className="u-mgr_s u-w_85">
              {!!events.cancelFlag && (
                <span className="t-textColor_base">
                  【撮影中止】
                  <br />
                </span>
              )}
              {!!events.societyId ? (
                <Link to={'/events/' + events.eventId}>{events.eventName}</Link>
              ) : (
                <a
                  href={kanriUrl({
                    action_owner_EVENTsummary: 't',
                    eventno: events.eventId.toString(),
                  })}
                >
                  {events.eventName}
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
      {/* 種類 */}
      <div className="p-eventsIndex_grid_value">{events.eventTypeName}</div>
      {/* 申請 */}
      <div className="p-eventsIndex_grid_value">
        <>
          {Object.values(events.requestOptions).map((info, key) => (
            <p key={key}>{info}</p>
          ))}
        </>
      </div>
      {/* 公開設定 */}
      <div className="p-eventsIndex_grid_value">{events.openStatusName}</div>
      {/* 事前確認依頼メール */}
      <div className="p-eventsIndex_grid_value">
        <div>
          <span>{events.isSendAssociationMailName}</span>
        </div>
      </div>
      {/* フォルダ */}
      <div className="p-eventsIndex_grid_value">
        {events.convertFolderName ? '○' : 'なし'}
      </div>
      {/* 認証キー */}
      <div className="p-eventsIndex_grid_value">
        {events.certificationKey.map((keyInfo) => (
          <p key={keyInfo.key}>
            <Link to={`/certificationkeys/${keyInfo.id}`}>{keyInfo.key}</Link>
          </p>
        ))}
      </div>
      {/* 価格No. */}
      <div className="p-eventsIndex_grid_value">
        {events.pricePatternNo !== 0 ? events.pricePatternNo : ''}
      </div>
      {/* 掲載開始日 */}
      <div className="p-eventsIndex_grid_value">
        {events.valueStartDay
          ? dayjs(events.valueStartDay).format('YYYY/MM/DD')
          : ''}
      </div>
      {/* バリュー終了日 */}
      <div className="p-eventsIndex_grid_value">
        {events.valueEndDay
          ? dayjs(events.valueEndDay).format('YYYY/MM/DD')
          : ''}
      </div>
      {/* 掲載終了日 */}
      <div className="p-eventsIndex_grid_value">
        {events.publishEndDay
          ? dayjs(events.publishEndDay).format('YYYY/MM/DD')
          : ''}
      </div>
      {/* 責任者終了日 */}
      <div className="p-eventsIndex_grid_value">
        {events.leaderEndDay
          ? dayjs(events.leaderEndDay).format('YYYY/MM/DD')
          : ''}
      </div>
      {/* チラシ */}
      <div className="p-eventsIndex_grid_value">
        <DateCell
          holidays={holidays}
          sendInfo={events.flyer}
          conditions={conditions}
          eventId={events.eventId}
          isSendName="chirashisendflag"
          dateName="chirashisenddate"
        />
      </div>
      {/* SB */}
      <div className="p-eventsIndex_grid_value">
        <DateCell
          holidays={holidays}
          sendInfo={events.sb}
          conditions={conditions}
          eventId={events.eventId}
          isSendName="sbsendflag"
          dateName="sbsenddate"
        />
      </div>
      {/* FAX用紙 */}
      <div className="p-eventsIndex_grid_value">
        <DateCell
          holidays={holidays}
          sendInfo={events.fax}
          conditions={conditions}
          eventId={events.eventId}
          isSendName="faxsendflag"
          dateName="faxsenddate"
        />
      </div>
      {/* DVD */}
      <div className="p-eventsIndex_grid_value">
        <DateCell
          holidays={holidays}
          sendInfo={events.dvd}
          conditions={conditions}
          eventId={events.eventId}
          isSendName="dvdsendflag"
          dateName="dvdsenddate"
        />
      </div>
      {/* 確認用SB */}
      <div className="p-eventsIndex_grid_value">
        <DateCell
          holidays={holidays}
          sendInfo={events.confirmSb}
          conditions={conditions}
          eventId={events.eventId}
          isSendName="sbjizensendflag"
          dateName="sbjizensenddate"
        />
      </div>
      {/* 一括配送入金締め日 */}
      <div className="p-eventsIndex_grid_value">
        {events.bulkDeliveryCloseDay
          ? dayjs(events.bulkDeliveryCloseDay).format('YYYY/MM/DD')
          : '-'}
      </div>
      {/* プラン */}
      <div className="p-eventsIndex_grid_value">{events.planName}</div>
      {/* 担当営業 */}
      <div className="p-eventsIndex_grid_value">{events.primarySalesName}</div>
      {/* カメラマン */}
      <div className="p-eventsIndex_grid_value">
        {events.primaryPhotographerName}
      </div>
    </div>
  </>
);

export default SearchResultItem;
