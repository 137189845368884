import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import SaveForm from './SaveForm';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { data, error } = useJsonApi<TCreateResponse>('/api/societies/create');

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const formItems = data.formItems;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const { validator, data } = (await fetcher('/api/societies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removeEmpty(formData)),
      })) as TSaveResponse;
      setErrorMessages([]);
      if (validator.hasError) {
        setValidator(validator);
        alertApiError();
        return;
      }
      successToast('登録しました');
      history.push(`/societies/${data.id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const defaultValues = {
    isLegalEntity: 1,
    isSalableTsuide: 1,
    isEnabledAutoEventPeriodSetting: 0,
    assigningExternalPhotographerTraineeType: 0,
    assigningInternalPhotographerTraineeType: 0,
    photographerId: 0,
    isRequiredAcceptanceByLeader: 0,
    autoNoticeCertificationkeyType: 0,
    isEnabledCreateKeyUsableWithoutLogin: 0,
    startMonthOfFiscalYear: 4,
    howToGo:
      '－－－－－－－－－－－\n【 : 】○○駅 集合\n↓徒歩 ○分orバス○分\nまたは\n↓バス停「○○」より徒歩○分\n【 : 】園到着\n↓\n－－－－－－－－－－－',
    vacationPrecaution:
      '■長期休み情報（20●●年度）\n　夏休み（〇～〇）\n　冬休み（〇～〇）\n　春休み（〇～〇）\n　その他（〇～〇）',
    isEnabledCertificationKeyShareable: 1,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">団体 - 新規登録</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          validator={validator}
          formItems={formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="登録する"
          cancelLink="/societies"
        />
      </div>
    </div>
  );
};

export default App;
