import { TValidatorResponse } from '@/components/shared/Form/types';
import { Dispatch, SetStateAction } from 'react';
import { alertApiError } from '../../../../ts/formValidation';

import { successToast, errorToast } from '../../../../ts/toast';
import { fetcher } from '../../../../ts/useApi';
import { STATUS_UNAPPROVED, STATUS_VERIFIED } from '../constants';
import { getApiPath } from '../getApiPath';
import { TTarget } from '../types';
import {
  THtmlFormInputs,
  TIncentivePayment,
  TPdfFormInputs,
  TPostResponse,
  TGroupedIncentivePaymentEvent,
  TPartnerGroupedIncentivePaymentEvent,
  TGroupedIncentivePaymentOther,
} from './types';

const submitForm = async (
  target: TTarget,
  formData: THtmlFormInputs | TPdfFormInputs,
  incentivePaymentId: number,
  setValidator: Dispatch<SetStateAction<TValidatorResponse>>,
  type: 'HTML' | 'PDF'
): Promise<void> => {
  const msg =
    formData.status === STATUS_UNAPPROVED
      ? '内容を保存し、承認依頼を出します。よろしいですか？'
      : formData.status === STATUS_VERIFIED
      ? '内容を「確認済み」として保存します。よろしいですか？'
      : '内容を下書き保存します。よろしいですか？';
  // TODO: UI
  if (!window.confirm(msg)) {
    return;
  }
  try {
    const actionType = type === 'HTML' ? 'html' : 'pdf';
    const res = await fetcher<TPostResponse>(
      getApiPath(target, `${incentivePaymentId}/${actionType}`),
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }
    );
    if (res.data === 'OK') {
      successToast('保存しました');
      window.location.reload();
    } else {
      setValidator(res.validator);
      alertApiError('保存に失敗しました');
    }
  } catch (e) {
    errorToast('エラーが発生しました');
  }
};

const convertGroupedIncentivePaymentOthersToEdit = (
  taxRate: number | null,
  orgGroupedIncentivePaymentOthers: TGroupedIncentivePaymentOther[]
): TGroupedIncentivePaymentOther[] =>
  orgGroupedIncentivePaymentOthers.some((go) => go.taxRate === taxRate)
    ? orgGroupedIncentivePaymentOthers
    : [
        ...orgGroupedIncentivePaymentOthers,
        {
          taxRate,
          totalPayment: 0,
          incentivePaymentOthers: [],
        },
      ];

const makeHtmlFormInputsDefault = (
  incentivePayment: TIncentivePayment,
  groupedIncentivePaymentEvents: TGroupedIncentivePaymentEvent[],
  groupedIncentivePaymentOthers: TGroupedIncentivePaymentOther[]
): THtmlFormInputs => ({
  status: incentivePayment.status,
  privateMemo: incentivePayment.privateMemo,
  publicMemo: incentivePayment.publicMemo,
  incentivePaymentEvents: groupedIncentivePaymentEvents.flatMap((ge) =>
    ge.incentivePaymentEvents.map((pe) => ({
      id: pe.id,
      incentiveRate: pe.incentiveRate,
      isUsed: pe.isUsed,
      // NOTE: 以下計算に必要なので仕方なく追加
      taxRate: ge.taxRate,
      totalSale: pe.totalSale,
      // NOTE: 先生プランの場合は存在しないが、集計で必要なのでダミー値を埋める
      groupId: (ge as TPartnerGroupedIncentivePaymentEvent).groupId ?? 0,
    }))
  ),
  incentivePaymentOthers: groupedIncentivePaymentOthers.flatMap(
    (go) => go.incentivePaymentOthers
  ),
});

export {
  submitForm,
  makeHtmlFormInputsDefault,
  convertGroupedIncentivePaymentOthersToEdit,
};
