import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { TParentCategory } from './types';

const QueryLink: React.FC<{
  query: Record<string, string | undefined>;
  selected?: boolean;
}> = ({ query, selected, children }) => {
  const location = useLocation();
  const currentQuery = QueryString.parse(location.search);
  const link = `${location.pathname}?${QueryString.stringify({
    ...currentQuery,
    ...query,
  })}`;
  selected =
    selected === undefined
      ? Object.keys(query).every((key) => query[key] === currentQuery[key])
      : selected;
  return (
    <span
      className={`p-photographs_linkStr ${
        selected ? 'p-photographs_linkStr__selected' : ''
      }`}
    >
      {selected ? children : <Link to={link}>{children}</Link>}
    </span>
  );
};

export const Filter: React.FC<{ showSoldFilter: boolean }> = ({
  showSoldFilter,
}) => {
  const location = useLocation();
  const currentQuery = QueryString.parse(location.search);
  return (
    <div className="p-photographs_filter">
      フィルタ
      {showSoldFilter && (
        <QueryLink query={{ filter: 'sold' }}>売れた写真のみ</QueryLink>
      )}
      <QueryLink query={{ filter: 'hidden' }}>非表示写真のみ</QueryLink>
      <QueryLink query={{ filter: 'jizen' }}>責任者削除のみ</QueryLink>
      {'|'}
      {!!currentQuery['categoryno'] ? (
        <QueryLink query={{ filter: undefined }}>Off</QueryLink>
      ) : (
        <>Off</>
      )}
    </div>
  );
};
export const CategoryMenu: React.FC<{
  parentCategories: TParentCategory[];
  isShowParentCat: boolean;
  hasAllSoldLink: boolean;
}> = ({ parentCategories, isShowParentCat, hasAllSoldLink }) => {
  const [isOpen, setIsOpen] = useState(true);
  const showDisplayToggle =
    parentCategories.length >= 3 ||
    parentCategories
      .map((parentCategory) => parentCategory.categories.length)
      .reduce((acc, n) => acc + n, 0) >= 10;
  const showAllCategories = isOpen || !showDisplayToggle;
  return (
    <>
      <p>
        {hasAllSoldLink && (
          <QueryLink query={{ categoryno: undefined, filter: 'sold' }}>
            イベント全体の売れた写真
          </QueryLink>
        )}
        <QueryLink query={{ categoryno: undefined, filter: 'all' }}>
          イベント全体の写真
        </QueryLink>
      </p>
      <div className="p-photographs_categoryList t-bgBox_brown">
        <div>
          <div className="l-flex_between">
            <span>カテゴリ一覧</span>
          </div>
          <div
            className={`p-photographs_categoryMenuWrapper ${
              showAllCategories ? 'is-open' : 'is-close'
            }`}
          >
            <table className="p-photographs_categoryMenu u-mgt_s">
              <tbody>
                {parentCategories.map((parentCategory) => (
                  <tr key={parentCategory.id}>
                    {isShowParentCat && (
                      <td className="p-photographs_categoryMenu_cell">
                        {('0' + parentCategory.viewno).slice(-2)}{' '}
                        {parentCategory.name}
                      </td>
                    )}

                    <td className="p-photographs_categoryMenu_cell">
                      {parentCategory.categories.map((category) => (
                        <QueryLink
                          key={category.id}
                          query={{ categoryno: category.id.toString() }}
                        >
                          {('0' + category.viewno).slice(-2)} {category.name}
                        </QueryLink>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showDisplayToggle && (
              <div
                className={`p-photographs_categoryMenuButtonWrapper ${
                  isOpen ? 'is-open' : 'is-close'
                }`}
              >
                <span
                  className={`c-btn_toggleDetails ${
                    isOpen ? 'is-open' : ''
                  } u-mgt_s`}
                  onClick={() => setIsOpen((b) => !b)}
                >
                  {isOpen ? '閉じる' : '全てのカテゴリを表示'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const SortMenu: React.FC<{ currentSort: string }> = ({
  currentSort,
}) => {
  return (
    <div className="p-photographs_sortMenu">
      並び順
      <QueryLink
        selected={currentSort === 'photographno'}
        query={{ sort: 'photographno' }}
      >
        名前（写真番号）
      </QueryLink>
      <QueryLink
        selected={currentSort === 'datetimeoriginal'}
        query={{ sort: 'datetimeoriginal' }}
      >
        撮影日時
      </QueryLink>
    </div>
  );
};
