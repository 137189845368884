import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import { FC, useMemo } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  Checkboxes,
  DateInput,
  MultiSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import { useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { IndexResponse } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import QueryString from 'query-string';
import { TMutate } from '@/ts/useJsonApi';

const SearchForm: FC<{
  handleSubmit: (formData: unknown) => Promise<void>;
  currentQuery: Partial<FieldValues>;
  response: IndexResponse;
  validator: TValidator;
}> = ({ handleSubmit, currentQuery, response, validator }) => {
  const methods = useForm({ defaultValues: currentQuery });
  const photographerChoices = useMemo(
    () =>
      response.formItems.photographers.map((photographer) => ({
        key: photographer.id,
        value: `${photographer.id} / ${photographer.sei} ${photographer.mei}`,
      })),
    [response]
  );
  const photographerOrganizationChoices = useMemo(
    () =>
      response.formItems.photographerOrganizations.map(
        (photographerOrganization) => ({
          key: photographerOrganization.id,
          value: `${photographerOrganization.id} / ${photographerOrganization.name}`,
        })
      ),
    [response]
  );
  const societyChoices = useMemo(
    () =>
      response.formItems.societies.map((society) => ({
        key: society.id,
        value: `${society.id} / ${society.name}`,
      })),
    [response]
  );
  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <div className="c-searchForm">
          <div className="c-frame">
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerIds"
                      validator={validator}
                      choices={photographerChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerOrganizationIds"
                      validator={validator}
                      choices={photographerOrganizationChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">団体</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="societyIds"
                      validator={validator}
                      choices={societyChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            <ul className="l-flex">
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">イベント</li>
                  <li className="c-dataValue">
                    <TextInput
                      name="eventId"
                      validator={validator}
                      placeholder="IDまたはイベント名を入力"
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">撮影日</li>
                  <li className="c-dataValue">
                    <ul className="c-input_dateBlock">
                      <li>
                        <DateInput
                          name="photographingDayStartAt"
                          validator={validator}
                        />
                      </li>
                      <li>
                        <DateInput
                          name="photographingDayEndAt"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex">
              <li className="l-col_24">
                <ul className="l-flex">
                  <li className="c-dataLabel">ステータス</li>
                  <li className="c-dataValue">
                    <Checkboxes
                      name="statuses"
                      validator={validator}
                      choices={response.formItems.statuses}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="検索"
              />
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export const Content: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
}> = ({ response, mutate }) => {
  const history = useHistory();
  const currentQueryString = useLocation().search;
  // TODO: バリデーション
  const handleSubmit = usePreventDuplicateCall(async (formData) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/penalties',
      currentQueryString,
      removeEmpty(formData)
    );
    history.push(newPath);
  });
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          { id: 3, title: '違約金一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">違約金一覧</h1>
        </div>
        <SearchForm
          validator={response.validator}
          response={response}
          handleSubmit={handleSubmit}
          currentQuery={convertQueryToFormData(currentQueryString)}
        />
        <Results
          response={response}
          editValidator={response.editValidator}
          mutate={mutate}
        />
      </div>
    </IndexPageLayout>
  );
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[1-9][0-9]*$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/penalties?${query}`;
};

export const IndexPage: FC = () => {
  const queryString = useLocation().search;
  const queryParams = removeEmpty(convertQueryToFormData(queryString));
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));
  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as IndexResponse}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return <Content response={response} mutate={mutate} />;
};
