import React, { useContext } from 'react';

export type TPhotographIndexState = {
  handleApiSuccess: (s: string) => void;
  handleApiError: (e: unknown, s?: string) => string[];
  showMultipleUpdate: boolean;
  showValidationError: boolean;
  showIndividualHide: boolean;
};

export const context = React.createContext<TPhotographIndexState>({
  handleApiError: (e) => [],
  handleApiSuccess: (s) => undefined,
  showMultipleUpdate: true,
  showValidationError: true,
  showIndividualHide: true,
});

export const usePhotographIndexState = (): TPhotographIndexState =>
  useContext(context);
