import { UseFormReturn } from 'react-hook-form';
import {
  EventRequestPhotographer,
  TEditFormInputs,
} from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';
import { useEffect } from 'react';
import {
  CAR,
  HIGHWAY,
} from '@/ts/photographerPayments/payment_transportation_types';
import { getTransportationPaymentPrice } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/Results/onBlurChangePriceFromPlace/onBlurChangePriceFromPlace';

export const useInputPriceFromTransportationType = (
  methods: UseFormReturn<TEditFormInputs>,
  eventRequestPhotographer: EventRequestPhotographer
): void => {
  useEffect(() => {
    const subscription = methods.watch(async (value, { name, type }) => {
      if (
        name === undefined ||
        type !== 'change' ||
        !name.includes('paymentTransportationType')
      ) {
        return;
      }
      const index = Number(name.split('.')[1]);

      const paymentTransportationType = methods.getValues(
        `photographerPayments.${index}.paymentTransportationType`
      );

      let fromLocation: string | null = '';
      let toLocation: string | null = '';
      if (
        paymentTransportationType === CAR ||
        paymentTransportationType === HIGHWAY
      ) {
        fromLocation = eventRequestPhotographer.photographerAddress;
        toLocation = eventRequestPhotographer.eventAddress;
      } else {
        fromLocation = eventRequestPhotographer.photographerStation;
        toLocation = eventRequestPhotographer.eventStation;
      }

      const price = await getTransportationPaymentPrice(
        paymentTransportationType,
        fromLocation,
        toLocation
      );

      if (
        price !== 0 &&
        !methods.getValues(`photographerPayments.${index}.paymentPrice`)
      ) {
        methods.setValue(
          `photographerPayments.${index}.paymentPrice`,
          Number(price)
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, eventRequestPhotographer]);
};
