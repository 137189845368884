import React from 'react';
import dayjs from 'dayjs';
import StatusLabel from '../../../shared/IncentivePayments/StatusLabel';
import ChangeType from '../../../shared/IncentivePayments/Show/ChangeType';
import DownloadCsv from './DownloadCsv';
import { dateFormatFromISO8601 } from '@/ts/formatTools';

const Header: React.FC<{
  type: 'HTML' | 'PDF';
  canEdit: boolean;
  incentivePaymentId: number;
  partnerName: string;
  status: number;
  statusName: string;
  issuedOn: string;
  isShowCsvDownloadLink: boolean;
  businessRegistrationNumber: string | null;
  businessRegistrationTradeName: string | null;
  tradingStartDay: string;
  tradingEndDay: string;
}> = React.memo(
  ({
    type,
    canEdit,
    incentivePaymentId,
    partnerName,
    status,
    statusName,
    issuedOn,
    isShowCsvDownloadLink,
    businessRegistrationNumber,
    businessRegistrationTradeName,
    tradingStartDay,
    tradingEndDay,
  }) => (
    <>
      <div className="l-flex_between_center">
        <div className="u-mgb_s">
          <h4 className="c-section_title u-fz_l u-mgt_s">{partnerName}様</h4>
          {businessRegistrationNumber && (
            <div>
              <span>
                <strong>登録番号：{businessRegistrationNumber}</strong>
              </span>
            </div>
          )}
          {businessRegistrationTradeName && (
            <div>
              <span>
                <strong>
                  適格請求書発行事業者名：{businessRegistrationTradeName}
                </strong>
              </span>
            </div>
          )}
          <div>
            <StatusLabel status={status} statusName={statusName} />
            <span>
              <strong>
                取引年月日 ({dateFormatFromISO8601(tradingStartDay)}~
                {dateFormatFromISO8601(tradingEndDay)})
                <span className="p-partnerIncentivePayment-tradingMonth">
                  {dayjs(issuedOn).format('YYYY年M月')}発行
                </span>
              </strong>
            </span>
          </div>
        </div>
        <div className="l-flex_end">
          <div className="u-mgr_m">
            {canEdit && (
              <ChangeType
                target="PARTNER"
                typeToChange={type === 'HTML' ? 'PDF' : 'HTML'}
                incentivePaymentId={incentivePaymentId}
              />
            )}
          </div>
          <div className="t-bgBox_gray u-fz_s u-mgr_s">
            <i className="c-icon_blank u-mgr_xs u-fz_xs" />
            {/* TODO: Link to 売れ行き集計 */}
            <a
              href="/"
              className="c-textlink is-disabled"
              // href="{{ OwnerHelper::kanriUrl($incentivePayment->salesSearchParamsForOwner()) }}"
              target="_blank"
            >
              売れ行き集計を表示
            </a>
          </div>
          {isShowCsvDownloadLink && (
            <DownloadCsv incentivePaymentId={incentivePaymentId} />
          )}
        </div>
      </div>
      <hr className="u-line_plane" />
    </>
  )
);

export default Header;
