import { FC, useState } from 'react';
import styles from './Results.module.scss';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import {} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import clsx from 'clsx';
import {
  IndexResponse,
  PaymentGroupedByOrganization,
  PaymentGroupedByPhotographers,
} from '../types';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import Paginator from '@/components/shared/Paginator/App';

const PHOTOGRAPHER_PAYMENTS_TYPE_PENALTY = 1;
const PHOTOGRAPHER_PAYMENTS_TYPE_AWARD = 2;
const PHOTOGRAPHER_PAYMENTS_TYPE_NON_EVENT_RELATED_EXPENSES = 3;
const PHOTOGRAPHER_PAYMENTS_TYPE_REQUEST_FEE = 4;
const PHOTOGRAPHER_PAYMENTS_TYPE_COMPENSATIONS = 5;
const PHOTOGRAPHER_PAYMENTS_TYPE_EVENT_RELATED_EXPENSES = 6;
const PHOTOGRAPHER_PAYMENTS_TYPE_TRANSPORTATION_EXPENSES = 7;

const ALL_PAYMENTS_STATUS_WORKING = 1;
const ALL_PAYMENTS_STATUS_CLOSED = 2;

const PhotographerPaymentsStatusLabel: FC<{
  allPaymentsStatus: number;
  allPaymentsStatusName: string;
}> = ({ allPaymentsStatus, allPaymentsStatusName }) => {
  const className =
    allPaymentsStatus === ALL_PAYMENTS_STATUS_WORKING
      ? styles.working
      : allPaymentsStatus === ALL_PAYMENTS_STATUS_CLOSED
      ? styles.closed
      : styles.canceled;
  return (
    <span className={clsx(styles.paymentsStatusLabel, className)}>
      {allPaymentsStatusName}
    </span>
  );
};

const TypeLink: FC<{
  typeId: number;
  typeName: string;
  photographerId: number;
  eventId: number | null;
  photographingDay: string;
}> = ({ typeId, typeName, photographerId, eventId, photographingDay }) => {
  let path;
  switch (typeId) {
    case PHOTOGRAPHER_PAYMENTS_TYPE_PENALTY:
      path = `/photographer_payments/penalties?eventIds[]=${eventId}&photographerIds[]=${photographerId}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_AWARD:
      path = `/photographer_payments/awards?photographerIds[]=${photographerId}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_NON_EVENT_RELATED_EXPENSES:
      path = `/photographer_payments/non_event_related_expenses?photographerIds[]=${photographerId}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_REQUEST_FEE:
      path = `/photographer_payments/request_fees?eventId=${eventId}&photographerIds[]=${photographerId}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_COMPENSATIONS:
      path = `/photographer_payments/compensations?eventId=${eventId}&photographerIds[]=${photographerId}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_TRANSPORTATION_EXPENSES:
      path = `/photographer_payments/transportation_expenses?isEmptyExpenses=false&eventId=${eventId}&photographerIds[]=${photographerId}&photographingDayStartAt=${dayjs(
        photographingDay
      ).format('YYYY-MM-DD')}&photographingDayEndAt=${dayjs(
        photographingDay
      ).format('YYYY-MM-DD')}`;
      break;
    case PHOTOGRAPHER_PAYMENTS_TYPE_EVENT_RELATED_EXPENSES:
      path = `/photographer_payments/expenses?eventId=${eventId}&photographerIds[]=${photographerId}`;
      break;
    default:
      path = null;
  }
  if (path) {
    return (
      <Link to={`${path}`} className="c-textlink">
        {typeName}
      </Link>
    );
  } else {
    return <>{typeName}</>;
  }
};

const ItemDetail: FC<{
  paymentsGroupedByPhotographer: PaymentGroupedByPhotographers;
  photographingDay: string;
}> = ({ paymentsGroupedByPhotographer, photographingDay }) => {
  const rowSize = paymentsGroupedByPhotographer.payments.length + 1;
  return (
    <>
      <span className="c-error_text">
        {paymentsGroupedByPhotographer.message}
      </span>
      <InnerTable>
        <InnerTableHead>
          <InnerTableRow>
            <InnerTableCell>カメラマン</InnerTableCell>
            <InnerTableCell>詳細区分</InnerTableCell>
            <InnerTableCell>詳細状況</InnerTableCell>
            <InnerTableCell>金額</InnerTableCell>
            <InnerTableCell>税率</InnerTableCell>
            <InnerTableCell>源泉徴収</InnerTableCell>
            <InnerTableCell>補足/備考</InnerTableCell>
          </InnerTableRow>
        </InnerTableHead>
        <InnerTableBody>
          {paymentsGroupedByPhotographer.photographerName && (
            <InnerTableRow>
              <td className={styles.innerTableCell} rowSpan={rowSize}>
                {paymentsGroupedByPhotographer.photographerName}
              </td>
            </InnerTableRow>
          )}
          {paymentsGroupedByPhotographer.payments.map((payment) => (
            <InnerTableRow>
              <InnerTableCell>
                <TypeLink
                  typeId={payment.typeId}
                  typeName={payment.typeName}
                  photographerId={payment.photographerId}
                  eventId={payment.eventId}
                  photographingDay={photographingDay}
                ></TypeLink>
              </InnerTableCell>
              <InnerTableCell>{payment.statusName}</InnerTableCell>
              <InnerTableCell>{payment.price.toLocaleString()}</InnerTableCell>
              <InnerTableCell>
                {payment.consumptionTaxPercent > 0
                  ? `課税${payment.consumptionTaxPercent}%`
                  : '非課税'}
              </InnerTableCell>
              <InnerTableCell>
                {payment.hasWithholdingTax ? '必要' : '不要'}
              </InnerTableCell>
              <InnerTableCell>
                <div className={styles.paymentsIndexExternalMemo}>
                  {payment.externalMemo}
                </div>
              </InnerTableCell>
            </InnerTableRow>
          ))}
        </InnerTableBody>
      </InnerTable>
      <div className="u-mgb_s"></div>
    </>
  );
};

const Item: FC<{
  payment: PaymentGroupedByOrganization;
  mutate: () => void;
  editValidator: TValidator;
}> = ({ payment }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <div>
            <span onClick={() => setOpened(!opened)} className={styles.arrow}>
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <PhotographerPaymentsStatusLabel
            allPaymentsStatus={payment.allPaymentsStatus}
            allPaymentsStatusName={payment.allPaymentsStatusName}
          />
          {`${payment.organizationId} / ${payment.organizationName}`}
        </TableCell>
        <TableCell>
          {payment.eventId && (
            <Link to={`/events/${payment.eventId}`} className="c-textlink">
              {`${payment.eventId} / ${payment.eventName}`}
            </Link>
          )}
        </TableCell>
        <TableCell>
          {dayjs(payment.photographingDay).format('YYYY/MM/DD')}
        </TableCell>
        <TableCell>{payment.societyName}</TableCell>
        <TableCell>{payment.price.toLocaleString()}</TableCell>
      </TableRow>
      {opened && (
        <OpenedDetail>
          {payment.paymentsGroupedByPhotographers.map(
            (paymentsGroupedByPhotographer) => (
              <ItemDetail
                paymentsGroupedByPhotographer={paymentsGroupedByPhotographer}
                photographingDay={payment.photographingDay}
              />
            )
          )}
          {payment.canAddAdjustmentPay && (
            <>
              <br />
              <div className="l-flex_center_line">
                <Link
                  className="c-btn_large c-btn_Search c-input_submit"
                  to={
                    payment.eventId
                      ? `/photographer_payments/adjustment/create?photographerOrganizationId=${payment.organizationId}&eventId=${payment.eventId}&paymentId=${payment.paymentId}`
                      : `/photographer_payments/adjustment/create?photographerOrganizationId=${payment.organizationId}&paymentId=${payment.paymentId}`
                  }
                >
                  調整金登録
                </Link>
              </div>
            </>
          )}
          <span className={styles.closeLinkWrapper}>
            <span className={styles.closeLink} onClick={() => setOpened(false)}>
              <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
              <span>閉じる</span>
            </span>
          </span>
        </OpenedDetail>
      )}
    </>
  );
};

export const Results: FC<{
  response: IndexResponse;
  payments: PaymentGroupedByOrganization[];
  mutate: () => void;
  editValidator: TValidator;
}> = ({ response, payments, mutate, editValidator }) => {
  const location = useLocation();
  if (payments.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={location.search}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>
              撮影日/
              <br />
              取引日
            </TableCell>
            <TableCell>団体</TableCell>
            <TableCell>
              合計金額
              <br />
              (税込)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <Item
              key={payment.organizationId}
              payment={payment}
              mutate={mutate}
              editValidator={editValidator}
            />
          ))}
        </TableBody>
      </Table>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={location.search}
      />
    </>
  );
};
