const TENANT_TYPE_SEN = 1;
const TYPE_LEADER = 0;
const TYPE_STAFF = 3;
const TYPE_EVENT = 8;
const TYPE_YEAR = 9;
const TYPES_GENERAL = [TYPE_EVENT, TYPE_YEAR];
const LEADER_APPROVAL_STATUS_APPROVED = 0;
const TAB_DOUBLE = 0;
const TAB_DIRECT = 1;
const TAB_PARTNER = 2;
const TAB_OLD = 9;
const TABS = [
  {
    title: '両方(直販・先生/パートナー)',
    type: TAB_DOUBLE,
    description: '千・パートナー両方の団体を対象に検索を行います',
  },
  {
    title: '直販・先生',
    type: TAB_DIRECT,
    description: '千の団体（直販・先生プラン）を対象に検索を行います',
  },
  {
    title: 'パートナー',
    type: TAB_PARTNER,
    description: 'パートナーの団体を対象に検索を行います',
  },
  {
    title: '旧直販・先生',
    type: TAB_OLD,
    description:
      '旧団体データを利用して、千・パートナー両方の団体を対象に検索を行います',
  },
];

const isTenantTypeSen = (tenantType: number): boolean =>
  tenantType === TENANT_TYPE_SEN;

const isKeyTypeGeneral = (keyType: number): boolean =>
  TYPES_GENERAL.includes(keyType);

const isKeyTypeLeader = (keyType: number): boolean => keyType === TYPE_LEADER;

export {
  isTenantTypeSen,
  isKeyTypeGeneral,
  isKeyTypeLeader,
  TYPE_STAFF,
  TYPE_EVENT,
  TYPE_YEAR,
  TYPES_GENERAL,
  LEADER_APPROVAL_STATUS_APPROVED,
  TAB_DOUBLE,
  TAB_DIRECT,
  TAB_PARTNER,
  TAB_OLD,
  TABS,
};
