import React, { useState, useEffect, useLayoutEffect } from 'react';
import searchIcon from '../../images/header_icon_search.png';
import spMenuOpen from '../../images/sp_btn_menuOpen.png';
import spMenuClose from '../../images/sp_btn_menuClose.png';
import SearchBox from './SearchBox';
import MenuList from './MenuList';
import './sidemenu.scss';

function useIsPCView() {
  const isPC = () => window.innerWidth > 767;
  const [isPCView, setIsPCView] = useState(isPC());
  useEffect(() => {
    const listner = () => setIsPCView(isPC());
    window.addEventListener('resize', listner);
    return () => {
      window.removeEventListener('resize', listner);
    };
  }, []);
  return isPCView;
}

const App: React.FC = () => {
  const [isSideMenuSPVisible, setIsSideMenuSPVisible] = useState(false);
  const [isSearchBoxSPVisible, setIsSearchBoxSPVisible] = useState(false);
  const isPCView = useIsPCView();
  const toggleSideMenuSP = () => setIsSideMenuSPVisible(!isSideMenuSPVisible);
  const toggleSearchBoxSP = () =>
    setIsSearchBoxSPVisible(!isSearchBoxSPVisible);
  const isSearchBoxVisible = isPCView || (!isPCView && isSearchBoxSPVisible);
  useLayoutEffect(() => {
    if (isSideMenuSPVisible) {
      document.body.classList.add('is-open');
    } else {
      document.body.classList.remove('is-open');
    }
  }, [isSideMenuSPVisible]);
  return (
    <div className="l-side_wrap">
      <div className="p-side_titleWrap">
        <h2 className="p-side_title">管理画面</h2>
        {/* TODO:transitionを追加 */}
        {isSearchBoxVisible && <SearchBox />}
        <span className="p-side_searchBtn_sp u-pcOff">
          <img
            className="p-side_menuOpeneBtn_image_sp"
            src={searchIcon}
            onClick={toggleSearchBoxSP}
            alt="search"
          />
        </span>
        <span className="p-side_menuBtn_sp u-pcOff">
          <img
            className="p-side_menuOpeneBtn_image_sp"
            src={spMenuOpen}
            onClick={toggleSideMenuSP}
            alt="menu"
          />
        </span>
      </div>
      <div
        className={'p-side_menuWrap ' + (isSideMenuSPVisible ? 'is-open' : '')}
      >
        <span
          className="p-side_menuCloseBtn_sp u-pcOff"
          onClick={toggleSideMenuSP}
        >
          <img
            className="p-side_menuCloseBtn_image_sp"
            src={spMenuClose}
            alt="close"
          />
        </span>
        <MenuList />
      </div>
    </div>
  );
};
export default App;
