import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import {
  EVENT_TYPE_ID_CHRISTMAS,
  EVENT_TYPE_ID_DAILY_CHILDCARE,
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_FIELD_TRIPS,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  EVENT_TYPE_ID_HALLOWEEN,
  EVENT_TYPE_ID_MOCHI_POUND,
  EVENT_TYPE_ID_OTHER,
  EVENT_TYPE_ID_POOL,
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  EVENT_TYPE_ID_SLEEPOVER,
  EVENT_TYPE_ID_SPORTS_DAY,
  EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
  EVENT_TYPE_ID_SUMMER_FESTIVAL,
  EVENT_TYPE_ID_THROWING_BEANS,
} from '@/components/pages/Events/Show/Photography/const';
import {
  getBasic,
  getChristmas,
  getDailyChildcare,
  getDealingWithUnexpectedSituations,
  getEntranceCeremony,
  getFieldTrip,
  getGraduationCeremony,
  getHalloween,
  getMeetingPlace,
  getMochiPound,
  getOther,
  getPhotographingPlace,
  getPhotographyCaution,
  getPhotographyPhotography,
  getPhotographySubject,
  getPool,
  getRecital,
  getSleepover,
  getSportsDay,
  getSummerFestival,
  getThrowingBeans,
} from '@/components/pages/Events/Show/Photography/Shared/getPhotographyMailText/sections';
import { stripNewLineForBlankTemplate } from '@/components/pages/Events/Show/Photography/Shared/getPhotographyMailText/stripNewLineForBlankTemplate';
import { toRadioBtnText } from '@/components/pages/Events/Show/Photography/Shared/getPhotographyMailText/convertText/toRadioBtnText/toRadioBtnText';
import { shouldRenderPhotographerExpense } from '@/components/pages/Events/Show/Photography/Shared/shouldRenderPhotographyContent';

export const getPhotographyMailText = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  return `${getBasic(photography, inputValues)}
${getMeetingPlace(photography, inputValues, constants)}
${getPhotographingPlace(inputValues, photography)}
${getPhotographySubject(inputValues, photography, constants)}
${getEvent(inputValues, photography, constants)}
${getPhotographyPhotography(inputValues, photography, constants)}
${getDealingWithUnexpectedSituations(inputValues, photography, constants)}
${getPhotographyCaution(inputValues)}`;
};

const getEvent = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  // TODO: ここにイベントにおける情報の他の項目を入れる
  return stripNewLineForBlankTemplate`---◇ イベントにおける情報 ◇---

${getEventTypeSpecific(inputValues, photography, constants)}

${getEventTypeCommon(inputValues, photography)}`;
};

const getEventTypeSpecific = (
  inputValues: TFormInputs,
  photography: TPhotography,
  constants: TConstants
): string => {
  if (photography.eventTypeId === EVENT_TYPE_ID_SUMMER_FESTIVAL) {
    return getSummerFestival(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_MOCHI_POUND) {
    return getMochiPound(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_CHRISTMAS) {
    return getChristmas(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_HALLOWEEN) {
    return getHalloween(inputValues, photography);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_OTHER) {
    return getOther(inputValues, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_POOL) {
    return getPool(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_DAILY_CHILDCARE) {
    return getDailyChildcare(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER) {
    return getSleepover(inputValues, constants, photography);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_ENTRANCE_CEREMONY) {
    return getEntranceCeremony(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_GRADUATION_CEREMONY) {
    return getGraduationCeremony(inputValues, photography, constants);
  }
  if (
    photography.eventTypeId === EVENT_TYPE_ID_SPORTS_DAY ||
    photography.eventTypeId === EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL
  ) {
    return getSportsDay(inputValues, photography, constants);
  }
  if (
    photography.eventTypeId === EVENT_TYPE_ID_RECITAL ||
    photography.eventTypeId === EVENT_TYPE_ID_RECITAL_REHEARSAL
  ) {
    return getRecital(inputValues, photography, constants);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_THROWING_BEANS) {
    return getThrowingBeans(inputValues, photography);
  }
  if (photography.eventTypeId === EVENT_TYPE_ID_FIELD_TRIPS) {
    return getFieldTrip(inputValues, photography, constants);
  }
  return '';
};

const getEventTypeCommon = (
  inputValues: TFormInputs,
  photography: TPhotography
): string => {
  return stripNewLineForBlankTemplate`■タイムスケジュール
${inputValues.timeSchedule !== '' ? inputValues.timeSchedule : 'なし'}

■当日の緊急連絡先
${
  inputValues.emergencyContacts.length === 0
    ? 'なし'
    : inputValues.emergencyContacts
        .filter(
          (contact) =>
            (contact.name ?? '') !== '' ||
            (contact.telephoneNumber ?? '') !== ''
        )
        .map(
          (contact) =>
            `・電話番号 : ${contact.telephoneNumber ?? ''}\n・なまえ : ${
              contact.name ?? ''
            }`
        )
        .join('\n\n') || 'なし'
}

■当日の撮影担当者
${
  inputValues.teachersInChargeOfPhotography.length === 0
    ? 'なし'
    : inputValues.teachersInChargeOfPhotography
        .filter((teacher) => (teacher ?? '') !== '')
        .map((teacher) => `・なまえ : ${teacher ?? ''}`)
        .join('\n') || 'なし'
}

${
  shouldRenderPhotographerExpense(photography)
    ? stripNewLineForBlankTemplate`■カメラマン負担金額 : ${toRadioBtnText(
        'hasPhotographerExpense',
        inputValues.hasPhotographerExpense
      )}
${
  inputValues.photographerExpenseRemark
    ? `□詳細\n${inputValues.photographerExpenseRemark}`
    : ''
}`
    : ''
}

■その他情報
${inputValues.senNote !== '' ? inputValues.senNote : 'なし'}
`;
};
