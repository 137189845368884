import { z } from '@/validation';

export const createPhotoNameSearchValidationSchema = z
  .object({
    names: z
      .array(
        z.object({
          value: z
            .string()
            .regex(new RegExp(/^[\w\-+]+$/), {
              message: '写真ファイル名を正しく入力してください',
            })
            .optional()
            .or(z.literal('')),
        })
      )
      .refine((data) => {
        return (
          (data[0].value ?? '').length > 0 || (data[1].value ?? '').length > 0
        );
      }),
    societyId: z.coerce.number().optional(),
    groupId: z.coerce.number().optional(),
    eventId: z.coerce.number().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.societyId === 0 && data.groupId === 0 && data.eventId === 0) {
      ['societyId', 'groupId', 'eventId'].forEach((v) =>
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '団体IDまたはイベントIDを入力してください',
          path: [v],
        })
      );
    }
  });
