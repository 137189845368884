import React from 'react';
import BaseModal from '../BaseModal';
import { TParentCategory } from './Index/types';
import { TCategory } from './types';
import { changableCategories } from './changableCategories';
import { Checkboxes, SingleSelect } from '../Form/Inputs';
import { FormProvider, useForm } from 'react-hook-form';
import { TChoice } from '../Form/types';
import ErrorMessages from '../ErrorMessages';

type TPhotograph = {
  id: number;
  photoname: string;
};

const Photographs: React.FC<{ photographs: TPhotograph[] }> = ({
  photographs,
}) => (
  <ul>
    {photographs.map((photograph) => (
      <li key={photograph.id}>
        {photograph.photoname} [{photograph.id}]
      </li>
    ))}
  </ul>
);

const emptyValidator = {
  rules: {},
};

export type TShowManyModalProps = {
  handleClose: () => void;
  selectedPhotographs: TPhotograph[];
  handleConfirm: () => void;
  errorMessages: string[];
};

export const ShowManyModal: React.FC<TShowManyModalProps> = ({
  handleClose,
  selectedPhotographs,
  handleConfirm,
  errorMessages,
}) => {
  return (
    <BaseModal handleClose={handleClose}>
      <h4 className="c-section_title">まとめて表示</h4>
      <hr className="u-line_plane" />
      <ErrorMessages messages={errorMessages} />
      <h5 className="c-section_title">表示にする写真</h5>
      <Photographs photographs={selectedPhotographs} />
      （※すでに表示の写真は除外されます。）
      <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
        <span
          className="c-btn_large c-btn_cancel u-mgr_s"
          onClick={handleClose}
        >
          キャンセル
        </span>
        <span
          className="c-btn_large c-btn_primary u-mgr_s"
          onClick={() => handleConfirm()}
        >
          表示にする
        </span>
      </div>
    </BaseModal>
  );
};

export type THideManyModalProps = {
  handleClose: () => void;
  selectedPhotographs: TPhotograph[];
  handleConfirm: (reasons: number[]) => void;
  hiddenReasons: TChoice[];
  errorMessages: string[];
};
export const HideManyModal: React.FC<THideManyModalProps> = ({
  handleClose,
  selectedPhotographs,
  handleConfirm,
  hiddenReasons,
  errorMessages,
}) => {
  const methods = useForm();
  const { watch } = methods;
  return (
    <BaseModal handleClose={handleClose}>
      <FormProvider {...methods}>
        <h4 className="c-section_title">まとめて非表示</h4>
        <hr className="u-line_plane" />
        <ErrorMessages messages={errorMessages} />
        <h5 className="c-section_title">非表示にする写真</h5>
        <Photographs photographs={selectedPhotographs} />
        （※すでに非表示の写真は除外されます。）
        <h5 className="c-section_title u-mgt_xs">
          非表示の理由を1つ以上選択してください
        </h5>
        <Checkboxes
          name="reasons"
          choices={hiddenReasons}
          validator={emptyValidator}
        />
        <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
          <span
            className="c-btn_large c-btn_cancel u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className={`c-btn_large c-btn_primary u-mgr_s ${
              (watch('reasons') || []).length > 0 ? '' : 'is-disabled'
            }`}
            onClick={() => handleConfirm(watch('reasons'))}
          >
            非表示にする
          </span>
        </div>
      </FormProvider>
    </BaseModal>
  );
};

export type TChangeCategoryModalProps = {
  handleClose: () => void;
  selectedPhotographs: TPhotograph[];
  currentCategory: TCategory;
  parentCategories: TParentCategory[];
  handleConfirm: (categoryId: number) => void;
  errorMessages: string[];
};

export const ChangeCategoryModal: React.FC<TChangeCategoryModalProps> = ({
  handleClose,
  selectedPhotographs,
  currentCategory,
  parentCategories,
  handleConfirm,
  errorMessages,
}) => {
  const targetCategories = changableCategories(
    currentCategory,
    parentCategories
  );
  const methods = useForm();
  const { watch } = methods;
  return (
    <BaseModal handleClose={handleClose}>
      <FormProvider {...methods}>
        {/* 枚数によってタイトルを変更 */}
        <h4 className="c-section_title">まとめて移動</h4>
        <hr className="u-line_plane" />
        <ErrorMessages messages={errorMessages} />
        <h5 className="c-section_title">移動する写真</h5>
        <Photographs photographs={selectedPhotographs} />
        <h5 className="c-section_title u-mgt_xs">移動元</h5>
        <span>
          {currentCategory.parentcategoryName} - {currentCategory.categoryname}
        </span>
        {targetCategories.length > 0 ? (
          <>
            <h5 className="c-section_title u-mgt_xs">移動先</h5>
            <SingleSelect
              name="categoryId"
              validator={{
                rules: {},
              }}
              choices={targetCategories}
              menuPortalTarget={document.body}
              menuPlacement="auto"
            />
          </>
        ) : (
          <div className="c-errorText">※移動できるカテゴリがありません</div>
        )}
        <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
          <span
            className="c-btn_large c-btn_cancel u-mgr_s"
            onClick={handleClose}
          >
            キャンセル
          </span>
          <span
            className={`c-btn_large c-btn_primary u-mgr_s ${
              watch('categoryId') ? '' : 'is-disabled'
            }`}
            onClick={() => handleConfirm(watch('categoryId'))}
          >
            移動する
          </span>
        </div>
      </FormProvider>
    </BaseModal>
  );
};

export type TReverseViewFlagModalProps = {
  handleClose: () => void;
  selectedPhotographs: TPhotograph[];
  handleConfirm: () => void;
  errorMessages: string[];
};

export const ReverseViewFlagModal: React.FC<TReverseViewFlagModalProps> = ({
  handleClose,
  selectedPhotographs,
  handleConfirm,
  errorMessages,
}) => {
  return (
    <BaseModal handleClose={handleClose}>
      <h4 className="c-section_title">まとめて反転</h4>
      <hr className="u-line_plane" />
      <ErrorMessages messages={errorMessages} />
      <h5 className="c-section_title">反転する写真</h5>
      <Photographs photographs={selectedPhotographs} />
      <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
        <span
          className="c-btn_large c-btn_cancel u-mgr_s"
          onClick={handleClose}
        >
          キャンセル
        </span>
        <span
          className="c-btn_large c-btn_primary u-mgr_s"
          onClick={() => handleConfirm()}
        >
          反転する
        </span>
      </div>
    </BaseModal>
  );
};
