import React, {
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
} from 'react';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { TValidatorResponse } from '@/components/shared/Form/types';

import Help from '../../../shared/Help/App';
import { getApiPath } from '../../../shared/IncentivePayments/getApiPath';
import { TCheck } from './types';
import { update } from './update';

type TActionsProps = {
  target: 'TEACHER' | 'PARTNER';
  checks: TCheck[];
  enabledMultipleSetPaidAt: boolean;
  enabledMultipleRequestApprove: boolean;
};
const Actions: React.FC<TActionsProps> = React.memo(
  ({
    target,
    checks,
    enabledMultipleSetPaidAt,
    enabledMultipleRequestApprove,
  }) => {
    const [paidAt, setPaidAt] = useState('');
    const [validator, setValidator] = useState<TValidatorResponse>({
      messages: {},
      hasError: false,
      rules: {},
    });

    const onChangePaidAt: ChangeEventHandler<HTMLInputElement> = (e) => {
      setPaidAt(e.target.value);
    };

    const checkedIds = checks.filter((c) => c.checked).map((c) => c.id);
    const checked = checkedIds.length > 0;
    const enabledRequestApprove = enabledMultipleRequestApprove && checked;
    const enabledSetPaidAt = enabledMultipleSetPaidAt && checked;

    const submit = useCallback(
      async (actionName: string, path: string, data: Record<string, unknown>) =>
        await update(actionName, path, data, validator, setValidator),
      [validator]
    );
    const submitPaidAt: MouseEventHandler = useCallback(
      async (e) => {
        e.preventDefault();
        if (!paidAt) {
          // TODO: alert UI
          window.alert('振込日を入力してください。');
          return;
        }
        await submit(
          `${checkedIds.length}件の振込日一括登録`,
          getApiPath(target, 'update_paid_at'),
          {
            ids: checkedIds,
            paidAt,
          }
        );
      },
      [target, checkedIds, paidAt, submit]
    );
    const submitRequestApprove: MouseEventHandler = useCallback(
      async (e) => {
        e.preventDefault();
        await submit(
          `${checkedIds.length}件の一括承認依頼`,
          getApiPath(target, 'update_status_to_unapproved'),
          {
            ids: checkedIds,
          }
        );
      },
      [target, checkedIds, submit]
    );

    // NOTE: status名固定にしないほうが良いが、当分変わらないと判断し一旦固定
    return (
      <div className="c-frame">
        <ApiErrors {...validator} />
        <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
          <li className="l-col_12 u-mgb_xs">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>
                  一括承認依頼
                  <Help>
                    管理部ステータス＝「確認済」で検索すると機能が有効になります。
                  </Help>
                </label>
              </li>
              <li className="c-dataValue">
                <button
                  className={
                    'c-btn_large c-btn_manage u-fz_m u-mgr_s' +
                    (enabledRequestApprove ? '' : ' is-disabled')
                  }
                  type="button"
                  onClick={submitRequestApprove}
                >
                  承認依頼する
                </button>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>
                  振込日一括登録
                  <Help isLarge={true}>
                    管理部ステータス＝「振込依頼」「振込済」で検索すると機能が有効になります。
                    <br />
                    口座情報に不備がある場合、振込日の登録はできません。
                  </Help>
                </label>
              </li>
              <li className="c-dataValue">
                <input
                  type="date"
                  className={
                    'c-input_plane c-input_date l-col_12 u-mgr_s' +
                    (enabledMultipleSetPaidAt ? '' : ' is-disabled')
                  }
                  placeholder="振込日"
                  onChange={onChangePaidAt}
                  value={paidAt}
                />
                <button
                  className={
                    'c-btn_large c-btn_manage u-fz_m u-mgr_s' +
                    (enabledSetPaidAt && paidAt ? '' : ' is-disabled')
                  }
                  type="button"
                  onClick={submitPaidAt}
                >
                  一括登録する
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
);

export default Actions;
