import React from 'react';
import { JvisPrintLog as PrintLog } from '../../../Show/types';
import { format } from 'date-fns';
import styles from './index.module.scss';

type Props = {
  orderInfoId: number;
  orderDeliveryInfoId: number;
  printLog: PrintLog;
};

const columns = [
  'ID',
  '注文ID',
  '注文配送先no',
  '作成・送信日',
  '配送伝票番号（日本郵便）',
  '配送日',
  '発送しましたメール',
  '一括配送ID',
  '一括配送締め日',
  '一括配送データ送信日時',
];

export const JvisPrintLog = React.memo<Props>(
  ({ orderInfoId, orderDeliveryInfoId, printLog }) => {
    return (
      <div className={styles.container}>
        <div className="l-flex_between_center u-mgb_s">
          <h4 className="c-section_title">JVIS</h4>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={`column-${index}`}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="right">{printLog.id}</td>
              <td align="right">{orderInfoId}</td>
              <td align="right">{orderDeliveryInfoId}</td>
              <td>
                {format(new Date(printLog.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </td>
              <td align="right">
                {printLog.bulkDelivery?.invoiceNo ?? printLog.invoiceNo}
              </td>
              <td>
                {printLog.bulkDelivery?.deliveredAt
                  ? format(
                      new Date(printLog.bulkDelivery.deliveredAt),
                      'yyyy/MM/dd HH:mm:ss'
                    )
                  : printLog.deliveredAt
                  ? format(
                      new Date(printLog.deliveredAt),
                      'yyyy/MM/dd HH:mm:ss'
                    )
                  : '-'}
              </td>
              <td align="center">
                {printLog.mailSentFlag === 0 ? '未送信' : '送信済み'}
              </td>
              <td align="right">{printLog.bulkDelivery?.id}</td>
              <td>
                {printLog.bulkDelivery?.closedAt
                  ? format(
                      new Date(printLog.bulkDelivery.closedAt),
                      'yyyy/MM/dd'
                    )
                  : ''}
              </td>
              <td>
                {printLog.bulkDelivery?.uploadAt
                  ? format(
                      new Date(printLog.bulkDelivery.uploadAt),
                      'yyyy/MM/dd HH:mm:ss'
                    )
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);
