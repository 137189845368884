import { ContentPanel } from '@/components/shared/ContentPanel';
import React from 'react';
import { FormItem } from '@/components/shared/Form/FormItem';
import { FormProvider, SubmitHandler, useFieldArray } from 'react-hook-form';
import styles from './PhotoIdSearchPanel.module.scss';
import { Button } from '@/components/shared/Button';
import { FormActions } from '@/components/shared/Form/FormActions';
import Stack from '@/components/shared/Stack';
import clsx from 'clsx';
import { PhotoIdSearchFormParams } from '../../hooks';
import { usePhotoIdSearch } from './hooks';

export type Props = {
  doSubmit: SubmitHandler<PhotoIdSearchFormParams>;
  ids?: number[];
};

export const PhotoIdSearchPanel = React.memo<Props>(function PhotoIdSearchPanel(
  props
) {
  const { formMethods } = usePhotoIdSearch(props);

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = formMethods;
  const { fields } = useFieldArray({
    name: 'ids',
    control,
  });

  return (
    <ContentPanel title="写真ユニークIDで検索">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(props.doSubmit)}>
          <FormItem title="写真ID">
            <Stack
              direction="column"
              spacing={0}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Stack direction="row" spacing={0} justifyContent="flex-start">
                {fields.map((field, index) => (
                  <Stack key={field.id}>
                    <input
                      type="text"
                      {...register(`ids.${index}.value`, {
                        required: index === 0,
                        validate: {
                          integer: (value) => {
                            return value ? parseInt(value) > 0 : true;
                          },
                        },
                      })}
                      className={clsx(
                        'c-input_plane',
                        errors.ids && errors.ids[index] && 'is-error'
                      )}
                      placeholder="写真ID"
                    />
                    {index < fields.length - 1 && (
                      <div className={styles.separator}>〜</div>
                    )}
                  </Stack>
                ))}
              </Stack>
              <div className="c-inputError">
                <p className="c-inputError_item">
                  {errors.ids && '写真IDには数字(整数)を入力して下さい'}
                </p>
              </div>
            </Stack>
          </FormItem>
          <div className={styles.example}>例: 1234567</div>
          <FormActions>
            <Button value="検索" type="submit" variant="search" />
          </FormActions>
        </form>
      </FormProvider>
    </ContentPanel>
  );
});
