import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';
import { LIST_ITEMS } from '../../listItem';

type PromptingProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const Prompting: React.FC<PromptingProps> = ({ methods, validator }) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'canPrompt' && type === 'change') {
        if (value.canPrompt === ID_UNCONFIRMED) {
          setValue('promptRemark', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.prompting.name}
      id={LIST_ITEMS.photography.prompting.id}
    >
      <RadioBoxes
        name="canPrompt"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: '可能' },
          { key: 0, value: '不可' },
        ]}
      />
      {watch('canPrompt') !== ID_UNCONFIRMED && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextArea name="promptRemark" validator={validator} />
        </>
      )}
    </PhotographyAccordion>
  );
};
