import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { getCsv } from '@/ts/fetch';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { ApiErrors } from '@/components/shared/Form/Errors';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';
import { errorToast } from '@/ts/toast';
import { toMessages } from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};
const SearchForm: React.FC = () => {
  const downloadLink = useRef<HTMLAnchorElement | null>(null);
  const methods = useForm();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const lastDayOfPreviousMonth = dayjs()
    .startOf('month')
    .subtract(1, 'day')
    .format('YYYY年MM月DD日');

  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);

  const downloadCsv = usePreventDuplicateCall(async () => {
    const path = `/api/photographer_payments/finance/csv`;
    try {
      const res = await getCsv<{ validator: TValidatorResponse }>(path);
      setErrorMessages([]);
      if (typeof res === 'object' && res.validator.hasError) {
        setValidator(res.validator);
        alertApiError();
        return;
      } else {
        setValidator(emptyValidator);
      }

      const tmpLink = downloadLink.current!;
      const url = makeCsvDownloadUrl(res as string);
      tmpLink.href = url;
      tmpLink.download = `photographer_finance_${dayjs().format(
        'YYYYMMDD_HHmmss'
      )}.csv`;
      tmpLink.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      errorToast('CSVの生成に失敗しました');
      setErrorMessages(toMessages(e));
    }
  });
  return (
    <FormProvider {...methods}>
      <div className="c-frame c-searchForm">
        <ApiErrors {...validator} />
        <ErrorMessages messages={errorMessages} />
        <ul className="l-flex l-col_wrap">
          <li className="l-col_24">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">機能説明</li>
              <li className="c-dataValue">
                {lastDayOfPreviousMonth}
                付で締められている締め済データを仕掛品管理用に集計しCSV出力します。
              </li>
            </ul>
          </li>
        </ul>
        <div className="u-align_center">
          <button
            className="c-btn_large c-btn_Search c-input_submit u-mgr_m"
            type="button"
            onClick={methods.handleSubmit(downloadCsv)}
          >
            CSVダウンロード
          </button>
        </div>
        <a href="/" download="" className="is-hidden" ref={downloadLink}>
          dummy link
        </a>
      </div>
    </FormProvider>
  );
};

export default SearchForm;
