import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { TextArea } from '@/components/shared/Form/Inputs';

import SaveApiErrors from '../../../shared/SaveApiErrors/App';
import { toMessages, postJson } from '../../../../ts/useApi';
import { errorToast } from '../../../../ts/toast';
import {
  TBulkHiddenConfirmResponse,
  TBulkHiddenPhotograph,
  TFormInputs,
} from './types';
import { alertApiError } from '../../../../ts/formValidation';

const Form: React.FC<{
  id: number;
  validator: TOnlyValidationRuleResponse;
  setPhotographs: React.Dispatch<React.SetStateAction<TBulkHiddenPhotograph[]>>;
  setIsDisplayForm: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmittedPhotographNamesText: React.Dispatch<React.SetStateAction<string>>;
}> = React.memo(
  ({
    id,
    validator: orgValidator,
    setPhotographs,
    setIsDisplayForm,
    setSubmittedPhotographNamesText,
  }) => {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [validator, setValidator] = useState<
      TOnlyValidationRuleResponse | TValidatorResponse
    >(orgValidator);

    const methods = useFormContext<TFormInputs>();
    const { handleSubmit } = methods;

    const onSubmit: SubmitHandler<TFormInputs> = useCallback(
      async (formData) => {
        setSubmittedPhotographNamesText(formData.photographNamesText);
        try {
          const { data: responsePhotographs, validator } =
            await postJson<TBulkHiddenConfirmResponse>(
              `/api/events/${id}/bulk_hidden_photographs/confirm`,
              formData
            );
          setErrorMessages([]);
          if (validator?.hasError) {
            setValidator(validator);
            setPhotographs([]);
            alertApiError();
          } else {
            setPhotographs(responsePhotographs as TBulkHiddenPhotograph[]);
            setIsDisplayForm(false);
          }
        } catch (e) {
          setErrorMessages(toMessages(e));
          setPhotographs([]);
          errorToast('エラーが発生しました');
        }
      },
      [id, setPhotographs, setIsDisplayForm, setSubmittedPhotographNamesText]
    );

    return (
      <div className="c-searchForm">
        <ApiErrors {...validator} />
        <SaveApiErrors messages={errorMessages} />
        <div className="c-frame">
          <form
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <TextArea
              name="photographNamesText"
              placeholder={
                'ここに非表示にしたい写真の写真名（例：0001-8004）を入力してください。' +
                '\n写真名は続けて入力することができます。' +
                '\n写真名を続けて入力する場合の区切り文字は改行、タブ、カンマ、半角スペースに対応しています。'
              }
              validator={validator}
              rows={15}
              additionalClassName="u-fz_l"
            />
            <div className="u-align_center u-mgt_s u-mgb_xs">
              <Link
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                to={`/events/${id}`}
              >
                キャンセル
              </Link>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value="確認する"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
);

export default Form;
