import React, { useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import QueryString from 'query-string';
import { convertQueryToFormData } from '@/ts/makePathForSearchForm';

import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { TYPES_GENERAL } from '../../../shared/Certificationkeys/constants';
import { TAB_DOUBLE } from '../../../shared/Certificationkeys/constants';
import { TIndexResponse } from '../../../shared/Certificationkeys/types';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { TShowResponse } from './types';
import Certificationkeys from './Certificationkeys';
import DeleteButton from './DeleteButton';
import './style.scss';

const makeFetchCertificationkeysQueryParams = (
  queryParams: Record<string, unknown>,
  bundleId: number
): Record<string, unknown> => {
  const bundlesParam =
    queryParams.bundles === 'all'
      ? {}
      : queryParams.bundles === 'notBundled'
      ? { notBundledBundleId: bundleId }
      : { bundleId: bundleId };

  return {
    ...queryParams,
    ...bundlesParam,
    // default: 「両方」
    type: queryParams.type ? queryParams.type : TAB_DOUBLE,
    // 年度キー・イベントキーのみ
    keytypes: queryParams.keytypes ? queryParams.keytypes : TYPES_GENERAL,
  };
};

const App: React.FC = () => {
  // TODO: componentごとにloadするUIがないので一旦ココでまとめて取っています。あとでなおしたい
  // キー束
  const { bundleId: paramBundleId, certificationkeyId } =
    useParams<{ bundleId: string; certificationkeyId?: string }>();
  const {
    data: keyBundleData,
    error: keyBundleError,
    mutate: mutateKeyBundle,
  } = useJsonApi<TShowResponse>('/api/key_bundles/' + paramBundleId);

  // 認証キー一覧
  const searchString = useLocation().search;
  const queryParams = {
    type: TAB_DOUBLE,
    bundles: 'bundled',
    ...convertQueryToFormData(searchString),
  };
  const fetchQueryParams = makeFetchCertificationkeysQueryParams(
    queryParams,
    Number(paramBundleId)
  );
  const {
    data: certificationkeysData,
    error: certificationkeysError,
    mutate: mutateCertificationkeys,
  } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/certificationkeys',
    '?' + QueryString.stringify(fetchQueryParams, { arrayFormat: 'bracket' })
  );

  const refetch = useCallback(() => {
    mutateKeyBundle();
    mutateCertificationkeys();
  }, [mutateKeyBundle, mutateCertificationkeys]);

  if (keyBundleError) {
    return renderError(keyBundleError);
  } else if (certificationkeysError) {
    return renderError(certificationkeysError);
  }
  if (!keyBundleData || !certificationkeysData) {
    return <SvgLoading />;
  }

  const {
    data: { bundleId, certificationkeyIds },
    conditions: { canDelete, canRemoveCertificationkeys },
  } = keyBundleData;

  return (
    <>
      {!!certificationkeyId && (
        <Link
          className="c-page_back"
          to={`/certificationkeys/${certificationkeyId}`}
        >
          写真閲覧キーサマリに戻る
        </Link>
      )}
      <h1 className="l-flex_center u-mgb_m c-page_title">キー束サマリ</h1>

      <div className="l-center_wrap">
        <div className="c-frame">
          <div className="l-flex_between_center">
            <div>
              <span>キー束番号</span>
              <span className="u-mgl_s c-page_title">{bundleId}</span>
            </div>
            {certificationkeyId ? (
              <DeleteButton
                bundleId={bundleId}
                canDelete={canDelete}
                certificationkeyId={Number(certificationkeyId)}
              />
            ) : (
              <DeleteButton bundleId={bundleId} canDelete={canDelete} />
            )}
          </div>
        </div>
      </div>

      <Certificationkeys
        canRemoveCertificationkeys={canRemoveCertificationkeys}
        bundleId={bundleId}
        bundledCertificationkeyIds={certificationkeyIds}
        queryParams={queryParams}
        data={certificationkeysData}
        refetch={refetch}
      />
    </>
  );
};

export default App;
