export const REQUEST_FEE_TYPE = {
  SPECIAL_FEE: 1,
  NORMAL_FEE: 2,
};

export const REQUEST_FEE_FORM_TYPE = {
  SPECIAL_FEE: 1,
  RANK_FEE: 2,
  BASE_FEE: 3,
};

export const CONSUMPTION_TAX_PERCENT = 10;

export const PRICE_CHOICE = {
  MANUAL: 0,
  AUTO: 1,
};

export const EVENT_ASSIGN_STATUS = {
  DECIDED: 0,
  UNDECIDED: 1,
  TEMPORARY: 2,
  DECLINED: 9,
};
