import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import {
  DateInput,
  NumberInput,
  RadioBoxes,
  SingleSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { getCsv } from '@/ts/fetch';
import { removeEmpty } from '@/ts/objectTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { ApiErrors } from '@/components/shared/Form/Errors';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';

import { errorToast } from '../../../../ts/toast';
import { toMessages } from '../../../../ts/useApi';
import { TFormInputs, TFormItems } from './types';
import { alertApiError } from '../../../../ts/formValidation';

const SearchForm: React.FC<{
  formItems: TFormItems;
  validator: TValidatorResponse;
  defaultValues: TFormInputs;
  handleShow: (data: unknown) => void;
}> = ({ formItems, validator: searchValidator, defaultValues, handleShow }) => {
  const downloadLink = useRef<HTMLAnchorElement | null>(null);
  const methods = useForm({ defaultValues });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] =
    useState<TValidatorResponse>(searchValidator);
  useEffect(() => {
    setValidator(searchValidator);
  }, [searchValidator]);
  const downloadCsv = usePreventDuplicateCall(
    async (data: Record<string, unknown>) => {
      const path = `/api/sales/csv?${QueryString.stringify(removeEmpty(data))}`;
      try {
        const res = await getCsv<{ validator: TValidatorResponse }>(path);
        setErrorMessages([]);
        if (typeof res === 'object' && res.validator.hasError) {
          setValidator(res.validator);
          alertApiError();
          return;
        } else {
          setValidator(searchValidator);
        }
        const tmpLink = downloadLink.current!;
        const url = makeCsvDownloadUrl(res as string);
        tmpLink.href = url;
        tmpLink.download = `Sales_${dayjs().format('YYYYMMDD_HHmmss')}.csv`;
        tmpLink.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        errorToast('CSVの生成に失敗しました');
        setErrorMessages(toMessages(e));
      }
    }
  );
  const showPrevYearSales = (e: React.MouseEvent) => {
    e.preventDefault();
    const formValues = methods.getValues();
    const currentDateFrom = dayjs(formValues.dateFrom as string);
    const currentDateTo = dayjs(formValues.dateTo as string);
    const newFormValues = {
      ...formValues,
      dateFrom: (currentDateFrom.isValid()
        ? currentDateFrom
        : dayjs().startOf('month')
      )
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
      dateTo: (currentDateTo.isValid() ? currentDateTo : dayjs())
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
    };
    const photographingDayFrom = dayjs(formValues.photographingDayFrom);
    const photographingDayTo = dayjs(formValues.photographingDayTo);
    if (photographingDayFrom.isValid()) {
      newFormValues['photographingDayFrom'] = photographingDayFrom
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
    }
    if (photographingDayTo.isValid()) {
      newFormValues['photographingDayTo'] = photographingDayTo
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
    }
    const path = `/sales?${QueryString.stringify(removeEmpty(newFormValues))}`;
    window.open(path, '_blank');
  };
  return (
    <FormProvider {...methods}>
      <div className="c-frame c-searchForm">
        <ApiErrors {...validator} />
        <ErrorMessages messages={errorMessages} />
        <ul className="l-col_wrap l-flex_between">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">集計方法</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    name="aggregateType"
                    choices={formItems.aggregateType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">種別</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    name="timingType"
                    choices={formItems.timingType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="l-col_wrap l-flex_between">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">注文タイプ</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    isDisplayUnselected={true}
                    unselectedLabel="全て"
                    name="orderType"
                    choices={formItems.orderType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">販売価格</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    isDisplayUnselected={true}
                    unselectedLabel="全て"
                    name="priceType"
                    choices={formItems.priceType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="l-col_wrap l-flex_between">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">ユーザー種別</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    isDisplayUnselected={true}
                    unselectedLabel="全て"
                    name="userType"
                    choices={formItems.userType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">契約種別</li>
              <li className="c-dataValue">
                <div className="t-bgBox_gray">
                  <RadioBoxes
                    isDisplayUnselected={true}
                    unselectedLabel="全て"
                    name="societyContractType"
                    choices={formItems.societyContractType}
                    validator={validator}
                    isInline={true}
                  />
                </div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="l-flex l-col_wrap">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">イベント</li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="p-salesIndex_idField">
                    <NumberInput
                      name="eventId"
                      validator={validator}
                      placeholder="ID"
                    />
                  </li>
                  <li className="p-salesIndex_nameField">
                    <TextInput
                      name="eventName"
                      validator={validator}
                      placeholder="イベント名"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">団体</li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="p-salesIndex_societyIdField">
                    <NumberInput
                      name="societyId"
                      validator={validator}
                      placeholder="ID"
                    />
                  </li>
                  <li className="p-salesIndex_societyContractIdField">
                    <NumberInput
                      name="societyContractId"
                      validator={validator}
                      placeholder="契約ID"
                    />
                  </li>
                  <li className="p-salesIndex_societyNameField">
                    <TextInput
                      name="societyName"
                      validator={validator}
                      placeholder="団体名"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">営業担当名</li>
              <li className="c-dataValue">
                <SingleSelect
                  name="primarySales"
                  choices={formItems.primarySales}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">詳細対応者</li>
              <li className="c-dataValue">
                <SingleSelect
                  name="primaryPhotographer"
                  choices={formItems.primaryPhotographer}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-flex l-col_wrap u-mgt_s">
          <li className="l-col_12">
            <ul className="l-flex l-col_wrap">
              <li className="c-dataLabel">カメラマンID</li>
              <li className="c-dataValue">
                <NumberInput name="photographer" validator={validator} />
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex l-col_wrap">
              <li className="c-dataLabel">金額</li>
              <li className="c-dataValue">
                <ul className="l-flex">
                  <li className="p-salesIndex_amountField">
                    <NumberInput name="amount" validator={validator} />
                  </li>
                  <li className="p-salesIndex_amountConditionField">
                    <RadioBoxes
                      name="amountConditionType"
                      validator={validator}
                      choices={formItems.amountConditionType}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-flex l-col_wrap">
          <li className="l-col_12">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">入金・注文日</li>
              <li className="c-dataValue">
                <ul className="c-input_dateBlock">
                  <li>
                    <DateInput name="dateFrom" validator={validator} />
                  </li>
                  <li>
                    <DateInput name="dateTo" validator={validator} />
                  </li>
                </ul>
                <a href="/" onClick={showPrevYearSales}>
                  同じ条件で前年度の売れ行きを見る
                </a>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">撮影日</li>
              <li className="c-dataValue">
                <ul className="c-input_dateBlock">
                  <li>
                    <DateInput
                      name="photographingDayFrom"
                      validator={validator}
                    />
                  </li>
                  <li>
                    <DateInput
                      name="photographingDayTo"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div className="u-align_center">
          <button
            className="c-btn_large c-btn_Search c-input_submit u-mgr_m"
            type="button"
            onClick={methods.handleSubmit(handleShow)}
          >
            表示
          </button>
          <button
            className="c-btn_large c-btn_Search c-input_submit u-mgr_m"
            type="button"
            onClick={methods.handleSubmit(downloadCsv)}
          >
            CSVダウンロード
          </button>
        </div>
        <a href="/" download="" className="is-hidden" ref={downloadLink}>
          dummy link
        </a>
      </div>
    </FormProvider>
  );
};

export default SearchForm;
