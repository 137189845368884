import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FC } from 'react';
import { Constants } from '../../../type';
import { useFormContext } from 'react-hook-form';
import styles from '../../Form.module.scss';

export const Rank: FC<{
  index: number;
  constants: Constants;
  validator: TValidator;
}> = ({ index, constants, validator }) => {
  const { register } = useFormContext();
  return (
    <div className="c-frame">
      <div className="l-flex">
        <div className={styles.requestFeeEditLabel}>ランク報酬</div>
        <div className="c-dataValue">
          <div className="l-flex">
            <div className="t-col_16">
              <div className="l-flex">
                <div>
                  <div className="t-textColor_sub">金額(税込)</div>
                  <input
                    className="c-input_plane is-disabled"
                    {...register(
                      `eventRequestPhotographers.${index}.rankFee.price`
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="t-col_8 u-mgl_m">
              <div className="t-textColor_sub">税率</div>
              <RadioBoxes
                name={`eventRequestPhotographers.${index}.rankFee.consumptionTaxPercent`}
                choices={constants.consumptionTaxPercent}
                validator={validator}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
