import React from 'react';
import styles from './Stack.module.scss';
import clsx from 'clsx';
type Props = {
  direction?: 'row' | 'column';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  spacing?: number;
  children?: React.ReactNode;
};

const Stack: React.FC<Props> = ({
  direction = 'row',
  alignItems = 'center',
  justifyContent = 'center',
  spacing = 2,
  children,
}) => {
  const getName = (name: string): string => {
    return name
      .split('-')
      .map((item, index) => {
        return item.charAt(0).toUpperCase() + item.slice(1);
      })
      .join('');
  };

  const directionValue = 'direction' + getName(direction);
  const alignItemsValue = 'alignItems' + getName(alignItems);
  const justifyContentValue = 'justifyContent' + getName(justifyContent);
  const spacingMode = direction.includes('row') ? 'row' : 'column';
  const spacingValue =
    spacing === 0 ? undefined : `${spacingMode}Spacing${spacing}`;

  return (
    <div
      className={clsx([
        styles.base,
        styles[directionValue],
        styles[alignItemsValue],
        styles[justifyContentValue],
        spacingValue && styles[spacingValue],
      ])}
    >
      {children}
    </div>
  );
};

export default Stack;
