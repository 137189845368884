import React from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';

type Props = {
  value: string;
  type: 'submit' | 'button';
  variant?: 'search' | 'outline'; // TODO: 見た目の種類を増やす
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};
export const Button = React.memo<Props>(function Button({
  value,
  type,
  variant,
  onClick,
}) {
  return (
    <input
      className={clsx(styles.submit, variant ? styles[variant] : undefined)}
      type={type}
      value={value}
      onClick={onClick}
    />
  );
});
