import React, { ChangeEvent, useCallback } from 'react';

const SearchInput: React.FC<{
  searchWord: string;
  onSearchWordChange: (w: string) => void;
}> = ({ searchWord, onSearchWordChange }) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onSearchWordChange(e.target.value),
    [onSearchWordChange]
  );
  return (
    <input
      type="text"
      className="p-side_search_input"
      name="input"
      value={searchWord}
      onChange={onChange}
    />
  );
};

export default SearchInput;
