import React from 'react';
import styles from './index.module.scss';
import { BundleOrderInfo } from '../../types';
import { dateTimeFormat } from '@/ts/formatTools';
import { numberFormat } from '@/ts/formatTools';
import { Link } from 'react-router-dom';

const columns = [
  '注文ID',
  'コンビニお支払番号',
  'ステータス',
  '注文日時',
  'コンビニ支払期限',
  '入金日',
  '注文金額',
  '現金支払',
  'クーポン利用',
  'フォトマネー使用',
  '送料込',
];

type Props = {
  bundleOrderInfos: BundleOrderInfo[];
};

export const OrderTable = React.memo<Props>(function OrderTable({
  bundleOrderInfos,
}) {
  return (
    <div className={styles.container}>
      <p>以下の注文がまとめられています</p>

      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={`order-column-${index}`}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bundleOrderInfos.map((bundleOrderInfo) => (
            <tr key={bundleOrderInfo.id}>
              <td>
                <Link to={'/order_infos/' + bundleOrderInfo.id}>
                  {bundleOrderInfo.id}
                </Link>
              </td>
              <td>
                {bundleOrderInfo.paymentTypeMasterNo === 2 ? (
                  <>
                    {bundleOrderInfo.conveniencePaymentNumTyped1}(デイリー)
                    <br />
                    {bundleOrderInfo.conveniencePaymentNumTyped2}(セブン)
                    <br />
                    {bundleOrderInfo.conveniencePaymentNumTyped3}(ファミマ)
                    <br />
                    {bundleOrderInfo.conveniencePaymentNumTyped4}(他)
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td>{bundleOrderInfo.paymentStatusName}</td>
              <td>{dateTimeFormat(bundleOrderInfo.created)}</td>
              <td>
                {bundleOrderInfo.paymentTypeMasterNo === 2
                  ? dateTimeFormat(bundleOrderInfo.paymentLimitDay)
                  : '-'}
              </td>
              <td>{dateTimeFormat(bundleOrderInfo.paymentDay)}</td>
              <td align="right">{numberFormat(bundleOrderInfo.totalSum)}円</td>
              <td align="right">
                {numberFormat(bundleOrderInfo.totalPayment)}円
              </td>
              <td align="right">{bundleOrderInfo.couponDiscount}</td>
              <td align="right">{bundleOrderInfo.usePoint}</td>
              <td align="center">
                {bundleOrderInfo.hasCarriageFee ? '○' : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
