import { TCheck } from '../types';

/**
 * @duplicated toggleAllSelectableCheckboxes を使ってください
 */
const toggleCheckAll = (
  currentCheckAll: boolean,
  currentChecks: TCheck[],
  setCheckAll: (value: React.SetStateAction<boolean>) => void,
  setChecks: (value: React.SetStateAction<TCheck[]>) => void
): void => {
  setCheckAll((checkAll) => !checkAll);

  const checkedAll = !currentCheckAll;
  const tmpChecks = currentChecks.map((c) => ({
    ...c,
    checked: c.disabled ? false : checkedAll,
  }));
  setChecks(tmpChecks);
};

/**
 * @duplicated toggleAllSelectableCheckboxes を使ってください
 */
const toggleCheck = (
  changedInput: HTMLInputElement,
  currentChecks: TCheck[],
  setCheckAll: (value: React.SetStateAction<boolean>) => void,
  setChecks: (value: React.SetStateAction<TCheck[]>) => void
): void => {
  const value = changedInput.value;
  const tmpChecks = (currentChecks: TCheck[]) =>
    currentChecks.map((c) => {
      const checked = String(c.id) === value ? !c.checked : c.checked;
      return {
        ...c,
        checked,
      };
    });
  // checkAll 更新
  setCheckAll(
    tmpChecks(currentChecks)
      .filter((c) => !c.disabled)
      .every((c) => c.checked)
  );
  // check更新
  setChecks(tmpChecks);
};

export { toggleCheckAll, toggleCheck };
