import React from 'react';

const Download: React.FC<{
  s3TmpUrl: string;
}> = ({ s3TmpUrl }) => {
  return (
    <a
      type="button"
      className={`c-btn_edit c-btn_small u-mgr_s`}
      href={s3TmpUrl}
    >
      <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
      <span>イベントサンプルブック</span>
    </a>
  );
};

export default Download;
