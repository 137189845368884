import { TChoice } from '@/components/shared/Form/types';
import { TPhotoSize } from '../../../shared/PriceTable/types';
import { TPriceDefaultPrices, TPriceInput, TPriceIsEnabledList } from './types';

export const initializePriceInput = (
  isEnabledList: TPriceIsEnabledList,
  defaultPrices: TPriceDefaultPrices,
  defaultPricesTaxIncluded: TPriceDefaultPrices,
  isCalculatedByTaxIncludedPrice: boolean,
  photoSizes: TPhotoSize[],
  defaultSizeName: string,
  userTypeId: number,
  photoTypeId: number,
  photoSizeId: number,
  teacherFlag: boolean
): TPriceInput => {
  const photoSizeName = getPhotoSizeNameById(photoSizes, photoSizeId)!;
  const isEnabled =
    findIsEnabled(isEnabledList, photoTypeId, defaultSizeName, photoSizeName) ??
    false;
  const price = isEnabled
    ? findDefaultPrice(
        isCalculatedByTaxIncludedPrice
          ? defaultPricesTaxIncluded
          : defaultPrices,
        userTypeId,
        photoTypeId,
        photoSizeName
      )
    : undefined;
  return {
    usertypemasterno: userTypeId,
    phototypemasterno: photoTypeId,
    photosizemasterno: photoSizeId,
    // 初期値を入れる
    price,
    // 有効無効
    isEnabled,
    // 先生プランかどうかのフラグ
    teacherFlag,
  };
};

export const makeDefaultSizeChoices = (
  photoSizes: TPhotoSize[],
  defaultSizeNames: string[]
): TChoice[] =>
  photoSizes
    .filter((photoSize) => defaultSizeNames.includes(photoSize.sizename))
    .map((photoSize) => ({
      key: photoSize.photosizemasterno,
      value: photoSize.nameJp,
    }));

export const getPhotoSizeNameById = (
  photoSizes: TPhotoSize[],
  id: number
): string | undefined =>
  photoSizes.find((photoSize) => photoSize.photosizemasterno === id)?.sizename;

// ---
// ユーザー種類＋写真種類によりデフォルト価格
// ---

/**
 * デフォルト価格を取得
 */
export const findDefaultPrice = (
  defaultPrices: TPriceDefaultPrices,
  userTypeId: number,
  photoTypeId: number,
  photoSizeName: string
): number | undefined => {
  const prices = findDefaultPrices(defaultPrices, userTypeId, photoTypeId);
  return prices ? prices[photoSizeName] : undefined;
};
const findDefaultPrices = (
  defaultPrices: TPriceDefaultPrices,
  userTypeId: number,
  photoTypeId: number
): Record<string, number> | undefined =>
  defaultPrices[userTypeId]
    ? defaultPrices[userTypeId][photoTypeId]
    : undefined;

// ---
// 基本価格＋写真種類による、サイズごとのinput有効無効
// ---

/**
 * 基本価格＋写真種類によりサイズごとのinput有効無効を取得
 */
export const findIsEnabled = (
  isEnabledList: TPriceIsEnabledList,
  photoTypeId: number,
  defaultPhotoSizeName: string,
  photoSizeName: string
): boolean | undefined => {
  const isEnables = findIsEnabledList(
    isEnabledList,
    photoTypeId,
    defaultPhotoSizeName
  );
  return isEnables ? isEnables[photoSizeName] : undefined;
};
/**
 * 基本価格
 */
export const getEnabledPhotoSizes = (
  isEnabledList: TPriceIsEnabledList,
  photoTypeId: number,
  defaultPhotoSizeName: string
): string[] => {
  const isEnables = findIsEnabledList(
    isEnabledList,
    photoTypeId,
    defaultPhotoSizeName
  );
  return isEnables
    ? Object.entries(isEnables)
        .filter(([_, isEnabled]) => isEnabled)
        .map(([photoSizeName]) => photoSizeName)
    : [];
};

const findIsEnabledList = (
  isEnabledList: TPriceIsEnabledList,
  photoTypeId: number,
  defaultPhotoSizeName: string
): Record<string, boolean> | undefined =>
  isEnabledList[photoTypeId]
    ? isEnabledList[photoTypeId][defaultPhotoSizeName]
    : undefined;
