import { PageContainer } from '@/components/shared/PageContainer';
import React from 'react';
import { SearchPanel } from './components/SearchPanel';
import { useMailLogSearch } from './hooks';
import { ResultPanel } from './components/ResultPanel';

type Props = {
  email?: string;
};

export const MailLog = React.memo<Props>(function MailLog({ email }) {
  const { logs, searchParams, handleSubmit } = useMailLogSearch();

  return (
    <PageContainer title="メール送信ログ検索">
      <SearchPanel email={searchParams?.email} doSubmit={handleSubmit} />
      <ResultPanel logs={logs} />
    </PageContainer>
  );
});
