import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import { TextInput, NumberInput } from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs } from './types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap">
                <li className="c-searchForm_convertInfo">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>コンバートフォルダID</label>
                    </li>
                    <li className="c-dataValue">
                      <NumberInput
                        name="convertinfono"
                        placeholder="コンバートフォルダID"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="c-searchForm_convertName">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>コンバートフォルダ名</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="directoryname"
                        placeholder="コンバートフォルダ名"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
