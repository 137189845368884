import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumberInput,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs } from './types';
import { TUseraccountsFormItems } from '../types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TUseraccountsFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    const bundles: TChoice[] = [
      { key: 'all', value: 'すべて' },
      { key: 'bundled', value: '済' },
      { key: 'notBundled', value: '未実施' },
    ];

    const { isActives, approvalStatuses } = formItems;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="c-certificationkeys_searchForm_modelInfo l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>会員</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-flex">
                        <li className="c-certificationkeys_searchForm_modelInfo_id">
                          <NumberInput
                            name="id"
                            placeholder="ID"
                            validator={validator}
                          />
                        </li>
                        <li className="c-certificationkeys_searchForm_modelInfo_name">
                          <TextInput
                            name="name"
                            placeholder="会員名"
                            validator={validator}
                          />
                        </li>
                        <li className="c-certificationkeys_searchForm_modelInfo_kana">
                          <TextInput
                            name="namekana"
                            placeholder="会員名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>責任者承認状況</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="approvalStatus"
                        choices={approvalStatuses}
                        isInline
                        validator={validator}
                        isDisplayUnselected
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        メール
                        <br className="sp_off" />
                        アドレス
                      </label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="mailaddress"
                        placeholder="メールアドレス"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>登録状況</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="isActive"
                        choices={isActives}
                        isInline
                        validator={validator}
                        isDisplayUnselected
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>電話番号</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="telephonenumber"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>紐付け</label>
                    </li>
                    <li className="c-dataValue">
                      <RadioBoxes
                        name="bundles"
                        choices={bundles}
                        validator={validator}
                        isInline
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
