import Encoding from 'encoding-japanese';

export const makeCsvDownloadUrl = (csvData: string): string => {
  const data = new Blob([encodeToSJIS(csvData)], { type: 'text/csv' });
  return window.URL.createObjectURL(data);
};

const encodeToSJIS = (csvData: string): Uint8Array => {
  const unicodeList = Encoding.stringToCode(csvData);
  const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
  return new Uint8Array(shiftJisCodeList);
};
