import React, { FC, useMemo } from 'react';
import { IndexResponse, TFormInputs } from '../types';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  DateInput,
  MultiSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import {
  TRANSFER_STATUS_CLOSED,
  TRANSFER_STATUS_TRANSFERRED,
  TRANSFER_STATUS_UNCLOSED,
} from '@/ts/photographerPaymentTransfers/status_constants';

export const SearchForm: FC<{
  response: IndexResponse;
  onSubmit: (form: TFormInputs) => void;
  methods: UseFormReturn<TFormInputs>;
  statusWatch: number;
}> = ({ response, onSubmit, methods, statusWatch }) => {
  const validator: TValidator = response.validator;
  const organizationIdsChoices = useMemo(
    () =>
      response.formItems.photographerOrganizations.map((organization) => ({
        key: organization.id,
        value: `${organization.id} / ${organization.name}`,
      })),
    [response]
  );

  const businessRegistrationNumberChoices = useMemo(
    () =>
      response.formItems.photographerOrganizations
        .filter(
          (organization) => organization.business_registration_number !== null
        )
        .map((organization) => ({
          key: organization.business_registration_number,
          value: `${organization.business_registration_number} / ${organization.name}`,
        })),
    [response]
  );

  const corporateNumberChoices = useMemo(
    () =>
      response.formItems.photographerOrganizations
        .filter((organization) => organization.corporate_number !== null)
        .map((organization) => ({
          key: Number(organization.corporate_number),
          value: `${organization.corporate_number} / ${organization.name}`,
        })),
    [response]
  );
  const handleStatusChange = (status: number) => {
    methods.setValue('status', status);
    if (
      status === TRANSFER_STATUS_CLOSED ||
      status === TRANSFER_STATUS_UNCLOSED
    ) {
      methods.setValue('transferredStartAt', undefined);
      methods.setValue('transferredEndAt', undefined);
    }
    if (status === TRANSFER_STATUS_UNCLOSED) {
      methods.setValue('minPrice', undefined);
      methods.setValue('maxPrice', undefined);
    }
    methods.handleSubmit((data) => {
      data.status = status;
      onSubmit(data);
    })();
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="c-searchForm">
          <div className="c-frame">
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">適格事業者番号</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="businessRegistrationNumbers"
                      validator={validator}
                      choices={businessRegistrationNumberChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">法人番号</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="corporateNumbers"
                      validator={validator}
                      choices={corporateNumberChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerOrganizationIds"
                      validator={validator}
                      choices={organizationIdsChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              {statusWatch === TRANSFER_STATUS_TRANSFERRED && (
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel l-col_12 ">支払日</li>
                    <li className="c-dataValue l-col_12">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="transferredStartAt"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="transferredEndAt"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              )}
              {statusWatch !== TRANSFER_STATUS_UNCLOSED && (
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">金額</li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <TextInput name="minPrice" validator={validator} />
                        </li>
                        <li>
                          <TextInput name="maxPrice" validator={validator} />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <div className="l-flex_center_line">
              <button className="c-btn_large c-btn_Search c-input_submit">
                検索
              </button>
            </div>
          </div>
        </div>
        {response.formItems.statuses.map((status, idx) => (
          <label key={idx} className="c-radioLabel">
            <input
              className="c-radioBtn"
              type="radio"
              name="status"
              value={status.key}
              defaultChecked={
                Number(methods.getValues('status')) === status.key
              }
              onClick={() => handleStatusChange(Number(status.key))}
            />
            <span className="c-label_radioBtn">{status.value}</span>
          </label>
        ))}
      </form>
    </FormProvider>
  );
};
