import React, { ChangeEventHandler } from 'react';
import { numberFormat, dateFormat } from '../../../../ts/formatTools';
import { TCheck } from '../../../../types';
import Accordion from '../../../shared/Accordion/App';
import { TPhotographerShort } from '../types';
import { TPhotographer } from './types';

const PhotographerHeaderItems: React.FC = React.memo(() => (
  <>
    <th>氏名</th>
    <th>
      住所
      <br />
      詳細条件
    </th>
    <th>性別</th>
    <th>
      ランク
      <br />
      報酬
    </th>
    <th title="直近一年の依頼回数と撮影回数">
      撮影/依頼
      <br />
      回数
    </th>
  </>
));

const PhotographerItems: React.FC<{
  photographer: TPhotographer;
  societyRequestPhotographers: TPhotographerShort[];
}> = React.memo(({ photographer, societyRequestPhotographers }) => (
  <>
    <td className="c-indexList_column_s">
      <div>
        <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
          <i className="c-icon c-icon__xxsmall c-icon_person u-mgr_xs"></i>
          {photographer.id}
        </div>
      </div>
      {photographer.shortName}
      {societyRequestPhotographers.some((p) => p.id === photographer.id) && (
        <i className="c-icon c-icon__xsmall c-icon_star u-mgl_xs" />
      )}
    </td>
    <td className="c-indexList_column_xl">
      {photographer.addressAll}
      <div className="u-mgt_s">
        <strong>詳細</strong>
      </div>
      <Accordion labelOpen="詳細を表示" labelClose="詳細を非表示">
        直線距離: {Math.floor(photographer.distance * 10) / 10}km / 最終撮影日:{' '}
        {dateFormat(photographer.latestPhotographingDay)} / 種類:{' '}
        {photographer.isEmployeeName} / 車: {photographer.hasCarName} / 宿泊:{' '}
        {photographer.isEnabledStayNightName}
        <br />
        機材: <br />
        <span className="u-pre_wrap">{photographer.equipment}</span>
      </Accordion>
    </td>
    <td className="c-indexList_column_s">{photographer.genderName}</td>
    <td className="c-indexList_column_m">
      {photographer.rankName}
      <br />({numberFormat(photographer.price)}円)
    </td>
    <td className="c-indexList_column_s">
      {photographer.numberOfArrangeDecided} /{photographer.numberOfArranged}
      <br />
      団体: {photographer.numberOfArrangedToSociety}
      <br />
    </td>
  </>
));

const SearchResultItem: React.FC<{
  photographer: TPhotographer;
  check: TCheck | undefined;
  onChangeCheck: ChangeEventHandler<HTMLInputElement>;
  societyRequestPhotographers: TPhotographerShort[];
}> = React.memo(
  ({ photographer, check, onChangeCheck, societyRequestPhotographers }) => (
    <tr>
      <td className="c-indexList_column_xxs u-align_center">
        <label className="c-checkbox_listbox">
          {check && (
            <input
              type="checkbox"
              className="c-checkbox_list"
              name="checks[]"
              value={check.id}
              checked={check.checked}
              onChange={onChangeCheck}
            />
          )}
          {/* NOTE: レイアウトの都合で必要な空のelement */}
          <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
        </label>
      </td>
      <PhotographerItems
        photographer={photographer}
        societyRequestPhotographers={societyRequestPhotographers}
      />
    </tr>
  )
);

export default SearchResultItem;
export { PhotographerHeaderItems, PhotographerItems };
