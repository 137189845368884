import React, { useEffect } from 'react';
import { ID_UNCONFIRMED } from '../../const';
import { TValidator } from '@/components/shared/Form/types';
import { useFormContext } from 'react-hook-form';
import { TConstants, TFormInputs } from '../../types';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';

export const Video: React.FC<{ validator: TValidator; constants: TConstants }> =
  ({ validator, constants }) => {
    const { watch, setValue } = useFormContext<TFormInputs>();
    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if (name === 'hasVideo' && type === 'change') {
          if (value.hasVideo !== 1) {
            setValue('videographerTypeId', ID_UNCONFIRMED);
            setValue('videoRemark', '');
          }
        }
        if (name === 'videographerTypeId' && type === 'change') {
          if (value.videographerTypeId === ID_UNCONFIRMED) {
            setValue('videoRemark', '');
          }
        }
      });
      return () => subscription.unsubscribe();
    }, [watch, setValue]);
    return (
      <>
        <div className="t-textColor_sub">動画撮影</div>
        <RadioBoxes
          name="hasVideo"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />
        {watch('hasVideo') === 1 && (
          <>
            <div className="t-textColor_sub">撮影者</div>
            <RadioBoxes
              name="videographerTypeId"
              validator={validator}
              choices={[
                { key: ID_UNCONFIRMED, value: '未確認' },
                ...constants.videographerTypes,
              ]}
            />
            {watch('videographerTypeId') !== ID_UNCONFIRMED && (
              <>
                <div className="t-textColor_sub">詳細</div>
                <TextArea
                  name="videoRemark"
                  validator={validator}
                  placeholder="動画撮影の詳細を入力してください"
                />
              </>
            )}
          </>
        )}
      </>
    );
  };
