import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import { ID_UNCONFIRMED } from '../../const';
import { TFormInputs, TPhotography } from '../../types';
import { RadioBoxes, TextInput } from '@/components/shared/Form/Inputs';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { Alert } from '@/components/shared/Alert';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

export const Halloween: React.FC<{
  photography: TPhotography;
  validator: TValidator;
}> = ({ photography, validator }) => {
  const { watch, setValue } = useFormContext<TFormInputs>();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasFashionShow' && type === 'change') {
        if (value.hasFashionShow !== 1) {
          setValue('fashionShowWalkStyle', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  return (
    <>
      <PhotographyAccordion
        title={`${photography.eventTypeName}情報`}
        id={LIST_ITEMS.event.halloween.id}
      >
        <div className="t-textColor_sub">カメラマンの仮装</div>
        <RadioBoxes
          name="isPhotographerCostumeAvailable"
          validator={validator}
          choices={[
            { key: 1, value: 'おまかせ' },
            { key: 0, value: '不要' },
          ]}
        />
        <Alert type="warning" title="おまかせの場合">
          「仮装は強制ではありませんが、何か身につけると喜ばれます。」の注釈文がコピーの際に追加されます。
        </Alert>
        <hr className="u-line_plane" />
        <div className="t-textColor_sub">ファッションショー</div>
        <RadioBoxes
          name="hasFashionShow"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />
        {watch('hasFashionShow') === 1 && (
          <>
            <div className="t-textColor_sub">歩き方詳細</div>
            <TextInput name="fashionShowWalkStyle" validator={validator} />
          </>
        )}
      </PhotographyAccordion>
    </>
  );
};
