import React, { FC, useState } from 'react';
import { IndexResponse, Payment } from '../types';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import styles from './Results.module.scss';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { TextArea } from '@/components/shared/Form/Inputs';
import { FormProvider, useForm } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { numberFormat } from '@/ts/formatTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson, putJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import BaseModal from '@/components/shared/BaseModal';
import { isValidationError, toMessages } from '@/ts/useApi';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
  STATUS_CLOSED,
  STATUS_REJECTED,
  STATUS_WAITING_APPROVAL,
} from '@/ts/photographerPayments/constants';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TPagination } from '@/components/shared/Paginator/types';
import Paginator from '@/components/shared/Paginator/App';
import QueryString from 'query-string';
import { TMutate } from '@/ts/useJsonApi';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';
import heic2any from 'heic2any';
import OverlaySpinner from '@/components/shared/OverlaySpinner/App';

const BULK_EDIT_THRESHOLD = 10;

const RejectModal: FC<{
  handleClose: () => void;
  ids: number[];
  handleRejected: () => void;
}> = ({ handleClose, ids, handleRejected }) => {
  const defaultValidator = { rules: {} };
  const [validator, setValidator] = useState<TValidator>(defaultValidator);
  const methods = useForm();
  const handleRegister = usePreventDuplicateCall(
    async (value: Record<string, unknown>) => {
      try {
        await postJson('/api/photographer_payments/expenses/bulk_reject', {
          ...value,
          ids,
        });
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          errorToast('入力に誤りがあります');
        } else {
          setValidator(defaultValidator);
          errorToast(`差し戻しに失敗しました。 ${toMessages(e)}`);
        }
        return;
      }
      successToast('差し戻しされました');
      handleRejected();
    }
  );
  return (
    <BaseModal handleClose={handleClose}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">中止金登録</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m"></div>
      </div>
      <FormProvider {...methods}>
        <div className="t-textColor_sub u-mgb_s">
          現在下記理由により差し戻しを行います。
          <br />
          差し戻し理由はカメラマンへ通知する内容です。
        </div>
        <div className="l-flex">
          <div className="c-dataLabel">
            差戻し理由
            <small className="c-required">(必須)</small>
          </div>
          <div className="c-dataValue">
            <TextArea name="reason" validator={validator} />
          </div>
        </div>
      </FormProvider>
      <hr className="u-line_plane" />
      <p className="u-align_center">
        <button
          type="button"
          className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
          onClick={handleClose}
        >
          キャンセル
        </button>
        <button
          type="button"
          className="c-btn_large c-btn_primary c-input_submit"
          onClick={methods.handleSubmit(handleRegister)}
        >
          登録
        </button>
      </p>
    </BaseModal>
  );
};

const InternalMemo: FC<{ internalMemo: string; id: number }> = ({
  internalMemo,
  id,
}) => {
  const methods = useForm({
    defaultValues: {
      internalMemo,
    },
  });
  const [validator, setValidator] = useState<TValidator>({ rules: {} });
  const handleSubmit = usePreventDuplicateCall(async (formValues) => {
    try {
      await putJson(
        `/api/photographer_payments/expenses/${id}/internal_memo`,
        formValues
      );
      successToast('社内メモを更新しました');
    } catch (e) {
      if (isValidationError(e)) {
        setValidator(e.jsonMessage.validator);
        errorToast('入力に誤りがあります');
      } else {
        errorToast(`社内メモの更新に失敗しました：${toMessages(e)}`);
      }
      return;
    }
  });
  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <div className={styles.internalMemoBox}>
          <div className={styles.internalMemoTitle}>社内メモ</div>
          <div className={styles.internalMemoText}>
            <TextArea name="internalMemo" validator={validator} />
          </div>
          <button className={styles.internalMemoButton}>保存</button>
        </div>
      </FormProvider>
    </form>
  );
};

const ResultRow: FC<{
  payment: Payment;
  setChecked: (checked: boolean) => void;
  checked: boolean;
  mutate: () => void;
}> = ({ payment, checked, setChecked, mutate }) => {
  const [opened, setOpened] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleCancelApproval = usePreventDuplicateCall(async () => {
    if (!window.confirm('経費を承認前に戻します。\nよろしいですか？')) {
      return;
    }
    setOpened(false);
    try {
      await postJson(
        '/api/photographer_payments/expenses/bulk_cancel_approval',
        {
          ids: [payment.id],
        }
      );
    } catch (e) {
      errorToast(`承認前に戻すのに失敗しました: ${toMessages(e)}`);
      return;
    }
    successToast('承認前に戻しました');
    mutate();
  });
  const handleCancelRejection = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(
        '経費の差し戻しを取り下げて、承認済みにします。\nよろしいですか？'
      )
    ) {
      return;
    }
    setOpened(false);
    try {
      await postJson(
        '/api/photographer_payments/expenses/bulk_cancel_rejection',
        {
          ids: [payment.id],
        }
      );
    } catch (e) {
      errorToast(`差し戻し取り下げに失敗しました: ${toMessages(e)}`);
      return;
    }
    successToast('差し戻しを取り下げました');
    mutate();
  });
  const handleUndoCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
      return;
    }
    if (
      !window.confirm(
        '経費の取り消しを解除して、取り消し前のステータスに戻します。\nよろしいですか？'
      )
    ) {
      return;
    }
    setOpened(false);
    try {
      await postJson('/api/photographer_payments/bulk_undo_cancel', {
        ids: [payment.id],
      });
    } catch (e) {
      errorToast(`取り消し解除に失敗しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消し解除しました');
    mutate();
  });

  const handleShowExpenseImgUrl = async () => {
    setIsDownloading(true);
    const blob = await fetch(payment.fileUrl).then((res) => res.blob());
    const errorMsg =
      '画像の読み込みに失敗しました。恐れ入りますが、画面を再度読み込みをお願いします。';
    if (blob.type !== 'image/heic') {
      setIsDownloading(false);
      window.open(payment.fileUrl as string, '_blank', 'noreferrer');
      return;
    }

    const conversionResult = await heic2any({
      blob,
      toType: 'image/png',
    }).catch((e) => {
      setIsDownloading(false);
      errorToast(errorMsg);
    });

    if (!conversionResult) {
      setIsDownloading(false);
      errorToast(errorMsg);
      return;
    }

    new Promise((resolve, reject) => {
      const url = URL.createObjectURL(conversionResult as Blob);
      resolve(url);
    })
      .then((url: string | unknown) => {
        setIsDownloading(false);
        window.open(url as string, '_blank', 'noreferrer');
      })
      .catch((error) => {
        setIsDownloading(false);
        errorToast(errorMsg);
      });
  };

  return (
    <>
      {isDownloading && <OverlaySpinner />}
      <TableRow
        highlighted={checked}
        disabled={payment.statusId === STATUS_CANCELED}
      >
        <TableCell>
          {canDisplayCheckbox(payment) && (
            <SmallCheckBox checked={checked} setChecked={setChecked} />
          )}
        </TableCell>
        <TableCell>
          <div>
            <span onClick={() => setOpened(!opened)} className={styles.arrow}>
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell>{payment.typeName}</TableCell>
        <TableCell>
          {`${payment.photographerSei} ${payment.photographerMei}`}
        </TableCell>
        <TableCell>{payment.organizationName}</TableCell>
        <TableCell>
          <Link to={`/events/${payment.eventId}`} className="c-textlink">
            {`${payment.eventId} / ${payment.eventName}`}
          </Link>
        </TableCell>
        <TableCell>
          <span className="c-textlink" onClick={handleShowExpenseImgUrl}>
            {payment.fileName}
          </span>
        </TableCell>
        <TableCell>
          {dayjs(payment.photographingDay).format('YYYY/MM/DD')}
        </TableCell>
        <TableCell>{payment.statusName}</TableCell>
        <TableCell>{numberFormat(payment.price)}</TableCell>
      </TableRow>
      {opened && (
        <OpenedDetail>
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow>
                <InnerTableCell>備考</InnerTableCell>
                {payment.approvedAt && (
                  <>
                    <InnerTableCell>承認日</InnerTableCell>
                    <InnerTableCell>承認者</InnerTableCell>
                  </>
                )}
                {payment.rejectedAt && (
                  <>
                    <InnerTableCell>差戻し日</InnerTableCell>
                    <InnerTableCell>登録者</InnerTableCell>
                    <InnerTableCell>差し戻し理由</InnerTableCell>
                  </>
                )}
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              <InnerTableRow disabled={payment.statusId === STATUS_CANCELED}>
                <InnerTableCell>{payment.externalMemo}</InnerTableCell>
                {payment.approvedAt && (
                  <>
                    <InnerTableCell>
                      {dayjs(payment.approvedAt).format('YYYY-MM-DD')}
                    </InnerTableCell>
                    <InnerTableCell>{payment.approvedBy}</InnerTableCell>
                  </>
                )}
                {payment.rejectedAt && (
                  <>
                    <InnerTableCell>
                      {dayjs(payment.rejectedAt).format('YYYY-MM-DD')}
                    </InnerTableCell>
                    <InnerTableCell>{payment.rejectedBy}</InnerTableCell>
                    <InnerTableCell>{payment.rejectedReason}</InnerTableCell>
                  </>
                )}
              </InnerTableRow>
            </InnerTableBody>
          </InnerTable>
          <InternalMemo internalMemo={payment.internalMemo} id={payment.id} />
          <div className={styles.closeLinkContainer}>
            {payment.statusId === STATUS_APPROVED && (
              <button
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleCancelApproval()}
              >
                承認前に戻す
              </button>
            )}
            {payment.statusId === STATUS_REJECTED && (
              <button
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleCancelRejection()}
              >
                差し戻し取り下げ
              </button>
            )}
            {payment.statusId === STATUS_CANCELED && (
              <button
                className="c-btn_large c-btn_delete u-mgb_s"
                onClick={() => handleUndoCancel()}
              >
                取り消し解除
              </button>
            )}
            <span className={styles.closeLinkWrapper}>
              <span
                className={styles.closeLink}
                onClick={() => setOpened(false)}
              >
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                閉じる
              </span>
            </span>
          </div>
        </OpenedDetail>
      )}
    </>
  );
};

const canDisplayCheckbox = (payment: Payment) =>
  payment.statusId !== STATUS_CANCELED &&
  payment.statusId !== STATUS_APPROVED &&
  payment.statusId !== STATUS_CLOSED;

export const Results: FC<{
  pagination: TPagination;
  payments: Payment[];
  mutate: TMutate<IndexResponse>;
  queryParams: Record<string, unknown>;
}> = ({ pagination, payments, mutate, queryParams }) => {
  const history = useHistory();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [rejectModalOpened, setRejectModalOpened] = useState(false);
  const location = useLocation();
  const isOnlyContainsWaitingApproval = checkedIds.every(
    (id) =>
      payments.find((payment) => payment.id === id)?.statusId ===
      STATUS_WAITING_APPROVAL
  );
  const isOnlyContainsEditable = checkedIds.every(
    (id) =>
      payments.find((payment) => payment.id === id)?.statusId ===
        STATUS_WAITING_APPROVAL ||
      payments.find((payment) => payment.id === id)?.statusId ===
        STATUS_REJECTED
  );
  const handleBulkApprove = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(
        '選択した経費を承認します。\n承認した経費は支払い対象になります。'
      )
    ) {
      return;
    }
    try {
      await postJson('/api/photographer_payments/expenses/bulk_approve', {
        ids: checkedIds,
      });
    } catch (e) {
      errorToast(`承認に失敗しました: ${toMessages(e)}`);
      return;
    }
    successToast('承認しました');
    setCheckedIds([]);
    mutate(changeStatus(checkedIds, STATUS_APPROVED, '確定'));
  });
  const changeStatus =
    (
      checkedIds: number[],
      statusId: number,
      statusName: string
    ): Parameters<TMutate<IndexResponse>>[0] =>
    (response) =>
      response && {
        ...response,
        data: {
          ...response.data,
          payments: response.data.payments?.map((payment) => ({
            ...payment,
            ...(checkedIds.includes(payment.id)
              ? { statusId, statusName }
              : {}),
          })),
        },
      };
  const handleBulkCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    try {
      await postJson('/api/photographer_payments/bulk_cancel', {
        ids: checkedIds,
      });
    } catch (e) {
      errorToast(`取り消しに失敗しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消ししました');
    setCheckedIds([]);
    mutate(changeStatus(checkedIds, STATUS_CANCELED, '取り消し'));
  });
  const handleBulkReject = () => setRejectModalOpened(true);
  const handleEdit = () => {
    history.push(
      `/photographer_payments/expenses/edit?${checkedIds
        .map((id) => `ids[]=${id}`)
        .join('&')}`
    );
  };
  const paginatorQueryString = QueryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });
  const checkableIds = payments
    ?.filter((payment) => canDisplayCheckbox(payment))
    .map((payment) => payment.id);
  if (payments.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={pagination}
          currentPath={location.pathname}
          queryString={paginatorQueryString}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {checkableIds?.length > 0 && (
                <SmallCheckBox
                  checked={checkedIds?.length === checkableIds.length}
                  setChecked={(checked) =>
                    checked
                      ? setCheckedIds(checkableIds || [])
                      : setCheckedIds([])
                  }
                />
              )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>詳細区分</TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>添付ファイル</TableCell>
            <TableCell>撮影日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>合計金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <ResultRow
              key={payment.id}
              payment={payment}
              checked={checkedIds.includes(payment.id)}
              setChecked={(checked) =>
                checked
                  ? setCheckedIds([...checkedIds, payment.id])
                  : setCheckedIds(checkedIds.filter((id) => id !== payment.id))
              }
              mutate={() => mutate(undefined, { keepCurrentData: true })}
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={pagination}
        currentPath={location.pathname}
        queryString={paginatorQueryString}
      />
      <ButtonsFooter>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_cancel',
            'u-pdt_s',
            'u-pdb_s',
            checkedIds.length > 0 && isOnlyContainsWaitingApproval
              ? ''
              : 'is-disabled'
          )}
          onClick={handleBulkCancel}
        >
          取り消し
        </button>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_primary',
            checkedIds.length > 0 && isOnlyContainsWaitingApproval
              ? ''
              : 'is-disabled'
          )}
          onClick={handleBulkApprove}
        >
          承認
        </button>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_secondary',
            checkedIds.length > 0 && isOnlyContainsWaitingApproval
              ? ''
              : 'is-disabled'
          )}
          onClick={handleBulkReject}
        >
          差し戻し
        </button>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_secondary',
            checkedIds.length > 0 &&
              isOnlyContainsEditable &&
              checkedIds.length < BULK_EDIT_THRESHOLD
              ? ''
              : 'is-disabled'
          )}
          onClick={handleEdit}
        >
          代理編集
        </button>
      </ButtonsFooter>
      <div className="u-mgt_s"></div>
      {rejectModalOpened && (
        <RejectModal
          handleClose={() => setRejectModalOpened(false)}
          ids={checkedIds}
          handleRejected={() => {
            mutate(undefined, { keepCurrentData: true });
            setCheckedIds([]);
            setRejectModalOpened(false);
          }}
        />
      )}
    </>
  );
};
