import { TextArea } from '@/components/shared/Form/Inputs';
import React from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';

const Memos: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  isHtml: boolean;
  privateMemo: string;
  publicMemo: string;
  hasPaymentCosts: boolean;
}> = React.memo(
  ({
    canEdit,
    validator,
    isHtml,
    privateMemo,
    publicMemo,
    hasPaymentCosts,
  }) => (
    <>
      <div className={isHtml ? 'u-mgb_m' : 'c-label_innerHalf'}>
        <label>
          <strong className="u-mgr_s">備考</strong>
          <span className="t-textColor_sub u-fz_xs">
            備考はパートナーも閲覧可能です
          </span>
        </label>
        <div className="u-pre_wrap">
          {canEdit ? (
            <TextArea
              name="publicMemo"
              placeholder="備考"
              validator={validator}
            />
          ) : (
            publicMemo || '（登録されていません）'
          )}
        </div>
      </div>
      <div className={isHtml ? 'u-mgb_m' : 'c-label_innerHalf'}>
        <label>
          <strong className="u-mgr_s">メモ</strong>
          <span className="t-textColor_sub u-fz_xs">
            メモは社内ユーザーのみ閲覧可能です
          </span>
        </label>
        <div className="u-pre_wrap">
          {canEdit ? (
            <TextArea
              name="privateMemo"
              placeholder="メモ"
              validator={validator}
            />
          ) : (
            privateMemo || '（登録されていません）'
          )}
        </div>
      </div>
      {isHtml && hasPaymentCosts && (
        <>
          <div className="u-mgb_s">
            【請求明細の注意事項】
            <br />
            団体別・イベント別の支払額小計（1）と、本明細の支払額合計（2）は、手数料を計算する際に発生する小数点の取り扱いが異なるため、一致しない場合がございます。予めご了承ください。
          </div>
          <div className="u-mgb_s">
            各集計用データは「請求明細をダウンロード」からご確認ください。
            <br />
            1.団体・イベント別集計（本明細の団体別・イベント別の支払額小計はこちらの計算結果を反映）
            <br />
            2.価格・サイズ別集計（本明細の支払額合計はこちらの計算結果を反映）
          </div>
        </>
      )}
    </>
  )
);

export default Memos;
