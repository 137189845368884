import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  renderError,
  useJsonApi,
  toMessages,
  patchJson,
} from '../../../../ts/useApi';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import { TFormInputs, TSaveResponse } from '../Create/types';
import { dateFormatHyphen } from '../../../../ts/formatTools';
import SaveForm, { formatFormData } from '../Create/SaveForm';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TEditResponse } from './types';
import { errorToast, successToast } from '../../../../ts/toast';
import SocietyContainer from '../../Societies/Show/SocietyContainer';
import SocietyHeader from '../../Societies/Show/SocietyHeader';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TEditResponse>(
    '/api/society_contracts/' + id + '/edit'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  const onSubmit: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
    async (formData) => {
      try {
        formatFormData(formData);
        const saveResponse = await patchJson<TSaveResponse>(
          `/api/society_contracts/${id}`,
          formData
        );
        setErrorMessages([]);
        if (saveResponse.validator.hasError) {
          setValidator(saveResponse.validator);
          alertApiError();
          return;
        }
        successToast('更新しました');
        history.push(`/societies/${saveResponse.data.id}`);
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('エラーが発生しました');
      }
    }
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const {
    formItems,
    data: {
      society,
      organization,
      societyStudents,
      requestPhotographers,
      societyDirect,
      societyContract,
      bulkdeliveryInfo,
      societyDirectContract,
      societyContractPlans: orgSocietyContractPlans,
      salesManagement,
      salesManagementPlans: orgSalesManagementPlans,
    },
  } = data;
  // NOTE: 並びを揃えるため、planでソートしておく
  const societyContractPlans = orgSocietyContractPlans.sort((p) => p.planId);
  const salesManagementPlans = orgSalesManagementPlans.sort((p) => p.planId);

  const defaultValues = {
    societyContractTypeId: societyContract.societyContractTypeId,
    status: societyContract.status,
    concludeDate: dateFormatHyphen(societyContract.concludeDate),
    representativeType: societyContract.representativeType,
    mailaddress: societyContract.mailaddress,
    inquiryMailaddress: societyContract.inquiryMailaddress,
    isEnabledDownloadByLeader: societyContract.isEnabledDownloadByLeader,
    isEnabledAlbumSales: societyContract.isEnabledAlbumSales,
    representativeName: societyContract.representativeName,
    representativeNamekana: societyContract.representativeNamekana,
    representativePosition: societyContract.representativePosition,
    representativeTelephonenumber:
      societyContract.representativeTelephonenumber,
    representativeFaxnumber: societyContract.representativeFaxnumber,
    representativeMailaddress: societyContract.representativeMailaddress,
    contactName: societyContract.contactName,
    contactNamekana: societyContract.contactNamekana,
    contactPosition: societyContract.contactPosition,
    contactTelephonenumber: societyContract.contactTelephonenumber,
    contactFaxnumber: societyContract.contactFaxnumber,
    contactMailaddress: societyContract.contactMailaddress,
    isRequiredDeliveryAddress: societyContract.isRequiredDeliveryAddress,
    deliveryPostcode: societyContract.deliveryPostcode,
    deliveryPrefectureId: societyContract.deliveryPrefectureId,
    deliveryCity: societyContract.deliveryCity,
    deliveryAddress: societyContract.deliveryAddress,
    deliveryAddressee: societyContract.deliveryAddressee,
    deliveryTelephonenumber: societyContract.deliveryTelephonenumber,
    uploadingPrecaution: societyContract.uploadingPrecaution,
    photographingPrecaution: societyContract.photographingPrecaution,
    isRequiredPreConfirmation: societyContract.isRequiredPreConfirmation,
    preConfirmationType: societyContract.preConfirmationType,
    isRequiredFlyer: societyContract.isRequiredFlyer,
    isEnabledFlyerPreDelivery: societyContract.isEnabledFlyerPreDelivery,
    flyerPreDeliveryType: societyContract.flyerPreDeliveryType,
    numberOfBusinessDaysToPreship:
      societyContract.numberOfBusinessDaysToPreship,
    numberOfFlyers: societyContract.numberOfFlyers,
    contentsOfFlyer: societyContract.contentsOfFlyer,
    samplebookPrecaution: societyContract.samplebookPrecaution,
    deliveryPrecaution: societyContract.deliveryPrecaution,
    otherPrecaution: societyContract.otherPrecaution,
    isEnabledPublishingPeriods: societyContract.isEnabledPublishingPeriods,
    publishingStartDay: societyContract.publishingStartDay,
    publishingEndDay: societyContract.publishingEndDay,
    photoAlignment: societyContract.photoAlignment,
    authenticationType: societyContract.authenticationType,
    isEnabledStaffPhotographing: societyContract.isEnabledStaffPhotographing,
    isRequiredSamplebook: societyContract.isRequiredSamplebook,
    isEnabledDisplayPartnerName: societyContract.isEnabledDisplayPartnerName,
    numberOfFaxApplicationForms: societyContract.numberOfFaxApplicationForms,
    isEnabledDisplayPersonalInformationToPartner:
      societyContract.isEnabledDisplayPersonalInformationToPartner,
    isEnabledFaceRecommend: societyContract.isEnabledFaceRecommend,
    isSalablePhotopanel: societyContract.isSalablePhotopanel,
    isEnabledFaxCommission: societyContract.isEnabledFaxCommission,
    isEnabledPostagePaidForIndividualdelivery:
      societyContract.isEnabledPostagePaidForIndividualdelivery,
    campaignType: societyContract.campaignType,
    isEnabledToDisplayModelRecruitmentForm:
      societyContract.isEnabledToDisplayModelRecruitmentForm,
    isEnabledFreePhoto: societyContract.isEnabledFreePhoto,
    isEnabledDisplayAgreementForLeader:
      societyContract.isEnabledDisplayAgreementForLeader,
    photographingMemo: societyContract.photographingMemo,
    isEnabledAutoEventCreate: societyContract.isEnabledAutoEventCreate,
    isEnabledFrameForGroupPhoto: societyContract.isEnabledFrameForGroupPhoto,
    frameForGroupPhotoType: societyContract.frameForGroupPhotoType,
    deliveryType: societyContract.deliveryType,
    isRequiredAddressesForBulkdelivery:
      societyContract.isRequiredAddressesForBulkdelivery,
    bulkdeliveryInfo: {
      carriage: bulkdeliveryInfo?.carriage,
      closeDay: bulkdeliveryInfo?.closeDay,
      deliveryAddressType: bulkdeliveryInfo?.deliveryAddressType,
      prefectureId: bulkdeliveryInfo?.prefectureId,
      postcode: bulkdeliveryInfo?.postcode,
      city: bulkdeliveryInfo?.city,
      address: bulkdeliveryInfo?.address,
      addressee: bulkdeliveryInfo?.addressee,
      telephonenumber: bulkdeliveryInfo?.telephonenumber,
    },
    societyDirectContract: {
      isRequiredSamplebookForPreConfirmation:
        societyDirectContract?.isRequiredSamplebookForPreConfirmation,
      isRequiredFax: societyDirectContract?.isRequiredFax,
      isRequiredDvd: societyDirectContract?.isRequiredDvd,
      isEnabledDetailedFaxSendingToGroup:
        societyDirectContract?.isEnabledDetailedFaxSendingToGroup,
      isEnabledPointSales: societyDirectContract?.isEnabledPointSales,
      isAutoCreateEventSchedulePdf:
        societyDirectContract?.isAutoCreateEventSchedulePdf,
      salesDepartmentOwnerno: societyDirectContract?.salesDepartmentOwnerno,
      secondarySalesDepartmentOwnerno:
        societyDirectContract?.secondarySalesDepartmentOwnerno,
      salesDepartmentStaffChangeHistory:
        societyDirectContract?.salesDepartmentStaffChangeHistory,
    },
    societyContractPlans: societyContractPlans,
    salesManagement: salesManagement,
    salesManagementPlans: salesManagementPlans,
    isHighResolution: societyContract.isHighResolution,
  };

  return (
    <div className="p-groupsSummary c-summary">
      <h1 className="l-flex_center u-mgb_m c-page_title">団体契約 - 編集</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <SocietyHeader society={society} organization={organization} />
            <div className="u-mgt_s l-flex nowrap">
              <SocietyContainer
                society={society}
                organization={organization}
                societyDirect={societyDirect}
                societyStudents={societyStudents}
                requestPhotographers={requestPhotographers}
              />
            </div>
          </div>
          <div className="l-flex_between_center u-mgb_s">
            <h4 className="c-section_title">契約情報</h4>
          </div>
          <SaveForm
            validator={validator}
            formItems={formItems}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            errorMessages={errorMessages}
            submitButton="更新する"
            cancelLink={`/societies/${society.id}`}
            isEditing={true}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
