import React from 'react';
import {
  TextInput,
  NumberInput,
  Checkboxes,
} from '@/components/shared/Form/Inputs';
import { TSearchFormTabProps } from './types';

const TabDirect: React.FC<TSearchFormTabProps> = React.memo(
  ({ validator, formItems }) => {
    const { societyPlanIds } = formItems;
    return (
      <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel c-label_line">
              <label>団体</label>
            </li>
            <li className="c-dataValue">
              <ul className="l-flex">
                <li className="c-certificationkeys_searchForm_modelInfo_id">
                  <NumberInput
                    name="societyId"
                    placeholder="団体ID"
                    validator={validator}
                  />
                </li>
                <li className="c-certificationkeys_searchForm_modelInfo_name">
                  <TextInput
                    name="societyName"
                    placeholder="団体名/表示名"
                    validator={validator}
                  />
                </li>
                <li className="c-certificationkeys_searchForm_modelInfo_kana">
                  <TextInput
                    name="societyNamekana"
                    placeholder="団体名(かな)"
                    validator={validator}
                  />
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel c-label_line">
              <label>プラン</label>
            </li>
            <li className="c-dataValue">
              <Checkboxes
                name="societyPlanIds"
                choices={societyPlanIds}
                validator={validator}
              />
            </li>
          </ul>
        </li>
      </ul>
    );
  }
);

export default TabDirect;
