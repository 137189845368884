import { ContentPanel } from '@/components/shared/ContentPanel';
import React from 'react';
import { FormProvider, SubmitHandler, useFieldArray } from 'react-hook-form';
import { FormItem } from '@/components/shared/Form/FormItem';
import styles from './PhotoNameSearchPanel.module.scss';
import { FormActions } from '@/components/shared/Form/FormActions';
import { Button } from '@/components/shared/Button';
import { PhotoNameSearchFormParams } from '../../hooks';
import Stack from '@/components/shared/Stack';
import clsx from 'clsx';
import { usePhotoNameSearch } from './hooks';

export type Props = {
  doSubmit: SubmitHandler<PhotoNameSearchFormParams>;
  names?: string[];
  societyId?: number;
  groupId?: number;
  eventId?: number;
};

export const PhotoNameSearchPanel = React.memo<Props>(
  function PhotoNameSearchPanel(props) {
    const { formMethods } = usePhotoNameSearch(props);
    const {
      handleSubmit,
      control,
      register,
      formState: { errors },
    } = formMethods;

    const { fields } = useFieldArray({
      name: 'names',
      control,
    });

    return (
      <ContentPanel title="写真ファイル名で検索">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(props.doSubmit)}>
            <FormItem title="写真ファイル名" wideTitle={true}>
              <Stack
                direction="column"
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Stack direction="row" spacing={0} justifyContent="flex-start">
                  {fields.map((field, index) => (
                    <Stack key={field.id} spacing={0}>
                      <div>
                        <input
                          type="text"
                          {...register(`names.${index}.value`, {
                            required: index === 0,
                          })}
                          className={clsx(
                            'c-input_plane',
                            errors.names && errors.names[index] && 'is-error'
                          )}
                          placeholder="写真ファイル名"
                        />
                        <div className="c-inputError">
                          <p className="c-inputError_item">
                            {errors.names &&
                              Array.isArray(errors.names) &&
                              errors.names[index] &&
                              errors.names[index].value?.message}
                          </p>
                        </div>
                      </div>
                      {index < fields.length - 1 && (
                        <div className={styles.separator}>〜</div>
                      )}
                    </Stack>
                  ))}
                </Stack>
                <div className="c-inputError">
                  <p className="c-inputError_item">
                    {errors.names &&
                      !Array.isArray(errors.names) &&
                      '写真ファイル名を入力してください'}
                  </p>
                </div>
              </Stack>
            </FormItem>
            <div className={styles.example}>
              (数字ファイル名なら数字順、それ以外は文字順)
            </div>
            <FormItem title="直販・先生団体ID" wideTitle={true}>
              <Stack
                direction="column"
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <input
                  type="text"
                  {...register('societyId')}
                  className={clsx(
                    'c-input_plane',
                    errors.societyId && 'is-error'
                  )}
                  placeholder="直販・先生団体ID"
                />
                <div className="c-inputError">
                  <p className="c-inputError_item">
                    {errors.societyId?.message}
                  </p>
                </div>
              </Stack>
            </FormItem>
            <FormItem title="パートナー団体ID" wideTitle={true}>
              <Stack
                direction="column"
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <input
                  type="text"
                  {...register('groupId')}
                  className={clsx(
                    'c-input_plane',
                    errors.groupId && 'is-error'
                  )}
                  placeholder="パートナー団体ID"
                />
                <div className="c-inputError">
                  <p className="c-inputError_item">{errors.groupId?.message}</p>
                </div>
              </Stack>
            </FormItem>
            <FormItem title="イベントID" wideTitle={true}>
              <Stack
                direction="column"
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <input
                  type="text"
                  {...register('eventId')}
                  className={clsx(
                    'c-input_plane',
                    errors.eventId && 'is-error'
                  )}
                  placeholder="イベントID"
                />
                <div className="c-inputError">
                  <p className="c-inputError_item">{errors.eventId?.message}</p>
                </div>
              </Stack>
            </FormItem>
            <FormActions>
              <Button value="検索" type="submit" variant="search" />
            </FormActions>
          </form>
        </FormProvider>
      </ContentPanel>
    );
  }
);
