import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { TEvents, TFormItems, TPeriodFormInputs } from './types';
import {
  Checkboxes,
  DatePicker,
  RadioBoxes,
} from '@/components/shared/Form/Inputs';
import { kanriUrl } from '@/ts/url';

import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import styles from './style.module.scss';
import { handleValueStartDayChanged, chirashiSendDateCheck } from './handlers';

type TProps = {
  events: TEvents;
  formItems: TFormItems;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  methods: UseFormReturn<TPeriodFormInputs>;
};
const OPEN_STATUS_CLOSE = 0;
const OPEN_STATUS_NOT_POSTED = 5;
const AUTHENTICATION_TYPE_YEAR = 0;
const AUTHENTICATION_TYPE_EVENT = 1;
const CERTIFICATION_NOT_SELECT = 9;

const isClose = (status: number) =>
  status === OPEN_STATUS_CLOSE || status === OPEN_STATUS_NOT_POSTED;

const PeriodResultItem: React.FC<TProps> = ({
  events,
  formItems: { holidays },
  validator,
  methods,
}) => {
  return (
    <>
      <div
        className={`${styles.grid_row}${
          isClose(events.openStatusId) ? ' ' + styles.grid_row__close : ''
        }`}
      >
        {/* チェックボックス */}
        <div className={styles.grid_value}>
          <Checkboxes
            name={`event.${events.eventId}.check`}
            choices={[{ key: 'true', value: '' }]}
            validator={validator}
          />
        </div>
        {/* 団体 */}
        <div className={styles.grid_value}>
          <div>
            {events.partnerId ? (
              <Link to={'/partners/' + events.partnerId}>
                {events.partnerName}
              </Link>
            ) : (
              <>千株式会社</>
            )}
            <span
              className={`${styles.idLink_hasBg} " u-mgb_xs l-flex_center"`}
            >
              <i className="c-icon_groups" />
              <span className="c-textOmit">
                {events.societyId ?? events.groupId}
              </span>
            </span>
            <div className={styles.grid_wordBreak}>
              {events.societyId ? (
                <Link to={'/societies/' + events.societyId}>
                  {events.societyName}
                </Link>
              ) : (
                <a
                  href={kanriUrl({
                    action_owner_GROUPsummary: 'true',
                    groupsno: String(events.groupId),
                  })}
                >
                  {events.groupName}
                </a>
              )}
            </div>
          </div>
        </div>
        {/* {事前確認} */}
        <div className={styles.grid_value}>
          {events.societyContract.isRequiredPreConfirmation ? (
            <span className={styles.tooltip}>{'必要'}</span>
          ) : (
            ''
          )}
        </div>
        {/* その他の注意点 */}
        <div className={styles.grid_value}>
          {events.societyContractOtherPrecaution ? (
            <span className={styles.tooltip}>
              {'メモあり'}
              <span className={styles.tooltip_fixed}>
                {events.societyContractOtherPrecaution
                  .split('\n')
                  .map((text) => (
                    <span key={events.eventId}>
                      {text}
                      <br />
                    </span>
                  ))}
              </span>
            </span>
          ) : (
            ''
          )}
        </div>
        {/* プラン */}
        <div className={styles.grid_value}>{events.planName}</div>
        {/* 撮影日 */}
        <div className={styles.grid_value}>
          <div className={styles.pdt_xs}>
            {dayjs(events.photographingday).format('YYYY/MM/DD')}
          </div>
        </div>
        {/* イベント */}
        <div className={styles.grid_value}>
          <div>
            <div className="l-flex_between">
              <span className={`${styles.idLink_hasBg}`}>
                <i className="c-icon_event" />
                <span className="c-textOmit">{events.eventId}</span>
              </span>
            </div>
            <div className="l-flex_between">
              <p>
                {!!events.cancelFlag && (
                  <span className="t-textColor_base">
                    【撮影中止】
                    <br />
                  </span>
                )}
                {!!events.societyId ? (
                  <Link to={'/events/' + events.eventId}>
                    {events.eventName}
                  </Link>
                ) : (
                  <a
                    href={kanriUrl({
                      action_owner_EVENTsummary: 't',
                      eventno: events.eventId.toString(),
                    })}
                  >
                    {events.eventName}
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* OP用メモ */}
        <div className={styles.grid_value}>
          {events.eventOpMemo ? (
            <span className={styles.tooltip}>
              {'メモあり'}
              <span className={styles.tooltip_fixed}>
                {events.eventOpMemo.split('\n').map((text) => (
                  <span>
                    {text}
                    <br />
                  </span>
                ))}
              </span>
            </span>
          ) : (
            ''
          )}
        </div>
        {/* 種類 */}
        <div className={styles.grid_value}>{events.eventTypeName}</div>
        {/* 申請 */}
        <div className={styles.grid_value}>
          <>
            {Object.values(events.requestOptions).map((info, key) => (
              <p key={key}>{info}</p>
            ))}
          </>
        </div>
        {/* 公開設定 */}
        <div className={styles.grid_value}>{events.openStatusName}</div>
        {/* 認証タイプ */}
        <div className={styles.grid_value}>{events.authenticationTypeName}</div>
        {/* 認証キー */}
        <div className={styles.grid_value}>
          {events.certificationKey.map((keyInfo) => (
            <p key={keyInfo.key}>
              <Link to={`/certificationkeys/${keyInfo.id}`}>{keyInfo.key}</Link>
            </p>
          ))}
          {events.certificationKey.length < 2 ? (
            <li className="c-dataValue">
              <RadioBoxes
                name={`event.${events.eventId}.certificationType`}
                isInline
                choices={[
                  { key: AUTHENTICATION_TYPE_YEAR, value: '年度' },
                  { key: AUTHENTICATION_TYPE_EVENT, value: 'イベント' },
                  { key: CERTIFICATION_NOT_SELECT, value: '未定' },
                ]}
                validator={validator}
              />
            </li>
          ) : (
            ''
          )}
        </div>
        {/* 固定掲載 */}
        <div className={styles.grid_value}>
          {events.societyContract.isEnabledPublishingPeriods
            ? events.societyContract.publishingStartDay + '日'
            : ''}
          {events.societyContract.isEnabledPublishingPeriods ? <br /> : ''}
          {events.societyContract.isEnabledPublishingPeriods
            ? '-' + events.societyContract.publishingEndDay + '日'
            : ''}
        </div>
        {/* 掲載開始日 */}
        <div className={styles.grid_value}>
          {events.valueStartDay
            ? dayjs(events.valueStartDay).format('YYYY/MM/DD')
            : ''}
          <DatePicker
            holidays={holidays}
            validator={validator}
            name={`event.${events.eventId}.valuestartday`}
            onBlur={(date) => {
              handleValueStartDayChanged(date || '', events, methods, holidays);
            }}
          />
        </div>
        {/* バリュー終了日 */}
        <div className={styles.grid_value}>
          {events.valueEndDay
            ? dayjs(events.valueEndDay).format('YYYY/MM/DD')
            : ''}
          {events.societyContractPlan.isEnabledOutOfPeriodSales ? (
            <DatePicker
              holidays={holidays}
              validator={validator}
              name={`event.${events.eventId}.valueendday`}
            />
          ) : (
            ''
          )}
        </div>
        {/* 掲載終了日 */}
        <div className={styles.grid_value}>
          {events.publishEndDay
            ? dayjs(events.publishEndDay).format('YYYY/MM/DD')
            : ''}
          <DatePicker
            holidays={holidays}
            validator={validator}
            name={`event.${events.eventId}.publishendday`}
          />
        </div>
        {/* 責任者終了日 */}
        <div className={styles.grid_value}>
          {events.leaderEndDay
            ? dayjs(events.leaderEndDay).format('YYYY/MM/DD')
            : ''}
        </div>
        {/* チラシの有無 */}
        <div className={styles.grid_value}>
          {events.societyContract.isRequiredFlyerName}
        </div>
        {/* チラシ発送指定日の有無 */}
        <div className={styles.grid_value}>
          {events.societyContract.isEnabledFlyerPreDeliveryName} /
          {events.societyContract.flyerPreDeliveryTypeName}
          <br />
          {events.societyContract.numberOfBusinessDaysToPreship} 日前発送
        </div>
        {/* チラシ */}
        <div className={styles.grid_value}>
          {events.flyer.sendDate
            ? dayjs(events.flyer.sendDate).format('YYYY/MM/DD')
            : ''}
          {events.flyer.required ? (
            <DatePicker
              holidays={holidays}
              validator={validator}
              name={`event.${events.eventId}.chirashisenddate`}
              onBlur={(date) => {
                chirashiSendDateCheck(date || '', events, methods);
              }}
            />
          ) : (
            ''
          )}
        </div>
        {/* SB */}
        <div className={styles.grid_value}>
          {events.sb.sendDate
            ? dayjs(events.sb.sendDate).format('YYYY/MM/DD')
            : ''}
          {events.sb.required ? (
            <DatePicker
              holidays={holidays}
              validator={validator}
              name={`event.${events.eventId}.sbsenddate`}
            />
          ) : (
            ''
          )}
        </div>
        {/* FAX用紙 */}
        <div className={styles.grid_value}>
          {events.fax.sendDate
            ? dayjs(events.fax.sendDate).format('YYYY/MM/DD')
            : ''}
          {events.fax.required ? (
            <DatePicker
              holidays={holidays}
              validator={validator}
              name={`event.${events.eventId}.faxsenddate`}
            />
          ) : (
            ''
          )}
        </div>
        {/* DVD */}
        <div className={styles.grid_value}>
          {events.dvd.sendDate
            ? dayjs(events.dvd.sendDate).format('YYYY/MM/DD')
            : ''}
          {events.dvd.required ? (
            <DatePicker
              holidays={holidays}
              validator={validator}
              name={`event.${events.eventId}.dvdsenddate`}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
export default PeriodResultItem;
