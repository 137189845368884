import BaseModal from '../../../BaseModal';
import { TPhotograph } from '../types';
import { TQualityAlertSummary } from './types';
import styles from './QualityAlertSummaryModal.module.scss';
import clsx from 'clsx';
import React from 'react';

export type TQualityAlertSummaryModalProps = {
  handleClose: () => void;
  photographs: TPhotograph[];
  latestVersion: string;
};

const formatSummaryTitle = (latestVersion: string): string => {
  return '写真品質判定サマリ（品質判定仕様Version' + latestVersion + '）';
};

const formatTotalNumber = (totalNumber: number): string => {
  return totalNumber + '枚';
};

const formatPhotographerId = (
  photographerId: number | null
): string | number => {
  if (photographerId === null) {
    return '';
  }
  return photographerId === 0 ? '--' : photographerId;
};

const formatPhotographerName = (
  photographerId: number | null,
  photographerName: string | null
): string => {
  if (photographerId === null) {
    return '';
  }
  return photographerId === 0 || !photographerName ? '不明' : photographerName;
};

const summary = (photographs: TPhotograph[]): TQualityAlertSummary[] => {
  const qualityAlertSummaryRows: TQualityAlertSummary[] = [];
  const totalNumberRow: TQualityAlertSummary = {
    photographerId: null,
    photographerName: null,
    displayTotalNumber: 0,
    isOverallDarkTotalNumber: 0,
    isSubjectChildrenTooSmallTotalNumber: 0,
    hasNoChildrenTotalNumber: 0,
    isTotalNumberRaw: true,
  };
  photographs.forEach((photograph) => {
    if (!photograph.isQualityAlertTarget) {
      return;
    }
    const isOverallDarkNumber = photograph.qualityAlert
      ? Number(photograph.qualityAlert.isOverallDark)
      : 0;
    const isSubjectChildrenTooSmallNumber = photograph.qualityAlert
      ? Number(photograph.qualityAlert.isSubjectChildrenTooSmall)
      : 0;
    const hasNoChildrenNumber = photograph.qualityAlert
      ? Number(photograph.qualityAlert.hasNoChildren)
      : 0;
    // カメラマンIDが0以下の場合はカメラマンは設定されておらず、不明として扱い、サマリの1行目に表示させるため配列のKeyを0にしています
    const photographerId =
      photograph.photographerId > 0 ? photograph.photographerId : 0;
    if (!qualityAlertSummaryRows[photographerId]) {
      qualityAlertSummaryRows[photographerId] = {
        photographerId: photographerId,
        photographerName: photograph.photographername,
        displayTotalNumber: 1,
        isOverallDarkTotalNumber: isOverallDarkNumber,
        isSubjectChildrenTooSmallTotalNumber: isSubjectChildrenTooSmallNumber,
        hasNoChildrenTotalNumber: hasNoChildrenNumber,
        isTotalNumberRaw: false,
      };
    } else {
      qualityAlertSummaryRows[photographerId].displayTotalNumber += 1;
      qualityAlertSummaryRows[photographerId].isOverallDarkTotalNumber +=
        isOverallDarkNumber;
      qualityAlertSummaryRows[
        photographerId
      ].isSubjectChildrenTooSmallTotalNumber += isSubjectChildrenTooSmallNumber;
      qualityAlertSummaryRows[photographerId].hasNoChildrenTotalNumber +=
        hasNoChildrenNumber;
    }
    totalNumberRow.displayTotalNumber += 1;
    totalNumberRow.isOverallDarkTotalNumber += isOverallDarkNumber;
    totalNumberRow.isSubjectChildrenTooSmallTotalNumber +=
      isSubjectChildrenTooSmallNumber;
    totalNumberRow.hasNoChildrenTotalNumber += hasNoChildrenNumber;
  });
  qualityAlertSummaryRows.push(totalNumberRow);
  return qualityAlertSummaryRows;
};

export const QualityAlertSummaryModal: React.FC<TQualityAlertSummaryModalProps> =
  ({ handleClose, photographs, latestVersion }) => {
    return (
      <BaseModal handleClose={handleClose}>
        <h4 className={styles.summaryTitle}>
          {formatSummaryTitle(latestVersion)}
        </h4>
        <div>
          <table className={styles.summaryTable}>
            <thead>
              <tr>
                <th>カメラマンID</th>
                <th>カメラマン名</th>
                <th>一覧表示枚数（スナップ）</th>
                <th>
                  <strong className={clsx('t-textColor_red')}>(暗)</strong>
                  全体的に暗すぎる
                </th>
                <th>
                  <strong className={clsx('t-textColor_red')}>(小)</strong>
                  被写体が小さすぎる
                </th>
                <th>
                  <strong className={clsx('t-textColor_red')}>(顔無)</strong>
                  子供の顔が写っていない
                </th>
              </tr>
            </thead>
            <tbody>
              {summary(photographs).map(
                (qualityAlertSummary: TQualityAlertSummary) => (
                  <tr key={qualityAlertSummary.photographerId}>
                    {qualityAlertSummary.isTotalNumberRaw ? (
                      <td colSpan={2}>合計</td>
                    ) : (
                      <>
                        <td>
                          {formatPhotographerId(
                            qualityAlertSummary.photographerId
                          )}
                        </td>
                        <td>
                          {formatPhotographerName(
                            qualityAlertSummary.photographerId,
                            qualityAlertSummary.photographerName
                          )}
                        </td>
                      </>
                    )}
                    <td className={styles.totalNumberCell}>
                      {formatTotalNumber(
                        qualityAlertSummary.displayTotalNumber
                      )}
                    </td>
                    <td className={styles.totalNumberCell}>
                      {formatTotalNumber(
                        qualityAlertSummary.isOverallDarkTotalNumber
                      )}
                    </td>
                    <td className={styles.totalNumberCell}>
                      {formatTotalNumber(
                        qualityAlertSummary.isSubjectChildrenTooSmallTotalNumber
                      )}
                    </td>
                    <td className={styles.totalNumberCell}>
                      {formatTotalNumber(
                        qualityAlertSummary.hasNoChildrenTotalNumber
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.summaryCloseButton}>
          <span
            className={clsx('c-btn_large', 'c-btn_cancel')}
            onClick={handleClose}
          >
            閉じる
          </span>
        </div>
      </BaseModal>
    );
  };
