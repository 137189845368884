import React from 'react';
import { TPhotographerOrganization } from '../types';
import { dateFormatFromISO8601 } from '../../../../../ts/formatTools';
import Help from '../../../../shared/Help/App';

const TableCell: React.FC<
  React.PropsWithChildren<{
    title: string;
    helpText?: string;
  }>
> = ({ children, title, helpText }) => {
  return (
    <td className="l-col_24">
      <ul className="l-flex">
        <li className="p-photographerOrganizationShow_label">
          {title}
          {helpText ? <Help>{helpText}</Help> : <></>}
        </li>
        <li className="c-dataValue u-pre_wrap">{children}</li>
      </ul>
    </td>
  );
};

const BaseInfo: React.FC<{
  photographerOrganization: TPhotographerOrganization;
}> = ({ photographerOrganization }) => {
  return (
    <div className="p-photographerOrganizationShow_frame">
      <div className="u-pdb_s u-mgb_m">
        <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
          <h2>基本情報</h2>
        </div>
        <table className="p-photographerOrganizationsShow_Table">
          <tbody>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'カメラマン組織ID'}>
                {photographerOrganization.id}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'カメラマン組織名'}>
                {photographerOrganization.name}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'組織タイプ'}>
                {photographerOrganization.type}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'ステータス'}>
                {photographerOrganization.status}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'法人番号'}>
                {photographerOrganization.corporateNumber}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者登録番号'}>
                {photographerOrganization.businessRegistrationNumber}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者登録確認日'}>
                {dateFormatFromISO8601(
                  photographerOrganization.businessRegistrationCheckDate
                )}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者登録屋号'}>
                {photographerOrganization.businessRegistrationTradeName}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者登録日'}>
                {dateFormatFromISO8601(
                  photographerOrganization.businessRegistrationCreateDate
                )}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者更新日'}>
                {dateFormatFromISO8601(
                  photographerOrganization.businessRegistrationUpdateDate
                )}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者取消日'}>
                {dateFormatFromISO8601(
                  photographerOrganization.businessRegistrationDisposalDate
                )}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'適格請求書発行事業者失効日'}>
                {dateFormatFromISO8601(
                  photographerOrganization.businessRegistrationExpireDate
                )}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'都道府県'}>
                {photographerOrganization.prefecture}
              </TableCell>
            </tr>
            <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <TableCell title={'社内メモ'}>
                {photographerOrganization.internalMemo}
              </TableCell>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const BankInfo: React.FC<{
  photographerOrganization: TPhotographerOrganization;
}> = ({ photographerOrganization }) => (
  <div className="p-photographerOrganizationShow_frame">
    <div className="u-pdb_s u-mgb_m">
      <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
        <h2>口座情報</h2>
      </div>
      <table className="p-photographerOrganizationsShow_Table">
        <tbody>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="銀行名">
              {photographerOrganization.bankName}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="銀行番号">
              {photographerOrganization.bankId}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="支店名">
              {photographerOrganization.branchName}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="支店番号">
              {photographerOrganization.branchId}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="口座種別">
              {photographerOrganization.accountTypeName}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="口座番号">
              {photographerOrganization.accountNumber}
            </TableCell>
          </tr>
          <tr className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <TableCell title="口座名義">
              {photographerOrganization.accountHolder}
            </TableCell>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export const Detail: React.FC<{
  photographerOrganization: TPhotographerOrganization;
}> = ({ photographerOrganization }) => (
  <>
    <BaseInfo photographerOrganization={photographerOrganization} />
    <BankInfo photographerOrganization={photographerOrganization} />
  </>
);
