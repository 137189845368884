import React, { ChangeEventHandler } from 'react';
import { Link } from 'react-router-dom';

import { dateFormat, numberFormat } from '../../../../ts/formatTools';
import { TCheck } from '../../../../types';
import {
  isDecided,
  isDeclined,
  isUndecided,
  numberOfArranged,
  sortDecidedPhotographers,
} from '../requestPhotographer';
import Photographers from '../Photographers';
import { TEvent } from './types';

const SearchResultItem: React.FC<{
  event: TEvent;
  check: TCheck;
  onChangeCheck: ChangeEventHandler<HTMLInputElement>;
  checksForSendOffer: TCheck[];
  onChangeCheckForSendOffer: ChangeEventHandler<HTMLInputElement>;
}> = ({
  event,
  check,
  onChangeCheck,
  checksForSendOffer,
  onChangeCheckForSendOffer,
}) => {
  const decidedPhotographers = sortDecidedPhotographers(
    event.requestPhotographers.filter((p) => isDecided(p.requestStatus))
  );
  const allUndecidedPhotographers = event.requestPhotographers.filter(
    (p) => isUndecided(p.requestStatus) && !p.isTemporary
  );
  const undecidedPhotographers = allUndecidedPhotographers.filter(
    (p) => !p.requestSentDate
  );
  const requestSentPhotographers = allUndecidedPhotographers.filter(
    (p) => !!p.requestSentDate
  );
  const temporaryPhotographers = event.requestPhotographers.filter(
    (p) => p.isTemporary
  );
  const declinedPhotographers = event.requestPhotographers.filter((p) =>
    isDeclined(p.requestStatus)
  );
  return (
    <tr
      className={
        'c-indexList_row' +
        (event.isCanceled ? ' c-indexList_row__canceled' : '')
      }
    >
      <td className="c-indexList_column_xxs u-align_center">
        {check.disabled ? (
          '×'
        ) : (
          <label className="c-checkbox_listbox">
            <input
              type="checkbox"
              className="c-checkbox_list"
              name="checks[]"
              value={check.id}
              checked={check.checked}
              onChange={onChangeCheck}
            />
            {/* NOTE: レイアウトの都合で必要な空のelement */}
            <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
          </label>
        )}
      </td>
      <td className="c-indexList_column_l">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
            {event.id}
          </div>
        </div>
        <Link className="c-textlink" to={`/events/${event.id}`}>
          {event.name}
          {!!event.isCanceled && '【撮影中止】'}
        </Link>
      </td>
      <td className="c-indexList_column_s">
        <p>{dateFormat(event.photographingDay)}</p>
        <p className="u-mgt_xs">
          {event.photographingTimeFrom} - {event.photographingTimeTo}
        </p>
      </td>
      <td className="c-indexList_column_l">
        <div>
          <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
            <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
            {event.societyId}
          </div>
          【{event.societySegment}】
        </div>
        <Link className="c-textlink" to={`/societies/${event.societyId}`}>
          {event.societyName}
        </Link>
        <div>{event.societyAddress}</div>
      </td>
      <td className="c-indexList_column_m u-align_right">
        <p>{numberFormat(event.expectSales)}円</p>
        <p className="u-mgt_xs">({numberFormat(event.grossProfit)}円)</p>
      </td>
      <td className="c-indexList_column_s u-align_right">
        {event.numberOfParticipants}
      </td>
      <td className="c-indexList_column_s u-align_right">
        {numberOfArranged(decidedPhotographers)}/
        {event.numberOfRequiredPhotographers}
      </td>
      <td className="c-indexList_column_m">
        <Photographers photographers={temporaryPhotographers} />
      </td>
      <td className="c-indexList_column_m">
        <Photographers
          photographers={undecidedPhotographers}
          checks={checksForSendOffer}
          onChangeCheck={onChangeCheckForSendOffer}
        />
      </td>
      <td className="c-indexList_column_m">
        <Photographers photographers={requestSentPhotographers} />
      </td>
      <td className="c-indexList_column_m">
        <Photographers photographers={decidedPhotographers} />
      </td>
      <td className="c-indexList_column_m">
        <Photographers photographers={declinedPhotographers.slice(0, 4)} />
        {declinedPhotographers.length > 4 && (
          <p
            title={declinedPhotographers
              .slice(4)
              .map((p) => p.shortName)
              .join('\n')}
          >
            (他 {declinedPhotographers.length - 4}人)
          </p>
        )}
      </td>
    </tr>
  );
};

export default SearchResultItem;
