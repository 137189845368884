import React from 'react';

import { TValidatorResponse } from '@/components/shared/Form/types';
import GeneralGroupedInventivePaymentEvent from '../../../shared/IncentivePayments/Show/GroupedIncentivePaymentEvent';
import { TGroupedIncentivePaymentEvent } from './types';

const GroupedIncentivePaymentEvent: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  groupedIncentivePaymentEvent: TGroupedIncentivePaymentEvent;
}> = React.memo((props) => <GeneralGroupedInventivePaymentEvent {...props} />);

export default GroupedIncentivePaymentEvent;
