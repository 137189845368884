import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormItems, TFormInputs } from './types';
import {
  Checkboxes,
  GroupedMultiSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { ApiErrors } from '@/components/shared/Form/Errors';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form
          method="GET"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-col_wrap l-col_wrap__isSpMode l-flex l-flex__isSpInput">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>団体</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-col_wrap has-gutter l-flex is-sp_mode">
                      <li className="l-col_12 u-pdr_s">
                        <TextInput
                          name="societyName"
                          placeholder="団体名"
                          validator={validator}
                        />
                      </li>
                      <li className="l-col_12 u-pdr_l">
                        <TextInput
                          name="societyNameKana"
                          placeholder="団体名(かな)"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="l-col_6">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>電話番号</label>
                  </li>
                  <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-mgr_l">
                    <div className="l-col_auto">
                      <TextInput
                        name="telephone"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li className="l-col_6">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>FAX番号</label>
                  </li>
                  <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-mgr_l">
                    <div className="l-col_auto">
                      <TextInput
                        name="fax"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap c-label_line is-sp_input l-flex">
              <li className="l-col_5">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>都道府県</label>
                  </li>
                  <li className="c-dataValue u-pdr_l">
                    <GroupedMultiSelect
                      name="prefecture"
                      choices={formItems.prefectures}
                      placeholder="選択してください"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_7">
                <ul className="l-flex">
                  <li className="p-groupsSocieties_searchForm_address u-mgr_s">
                    <label>
                      住所
                      <br />
                      (市区町村以下)
                    </label>
                  </li>
                  <li className="c-dataValue l-col_wrap has-gutter is-sp_mode u-pdr_l">
                    <div className="l-col_auto">
                      <TextInput
                        name="address"
                        placeholder="住所(市区町村以下)"
                        validator={validator}
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>契約状況</label>
                  </li>
                  <li className="c-dataValue u-pdr_xs">
                    <Checkboxes
                      name="contractStatus"
                      choices={formItems.contractStatus}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap c-label_line is-sp_input l-flex">
              <li className="l-col_5">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>メールアドレス</label>
                  </li>
                  <li className="c-dataValue u-pdr_xs">
                    <TextInput
                      name="mailaddress"
                      placeholder="test@example.com"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp p-groupsSocieties_search_btn">
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="検索"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SearchForm;
