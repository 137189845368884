import React from 'react';
import { Link } from 'react-router-dom';

import { dateFormat } from '../../../../ts/formatTools';
import { isTenantTypeSen } from '../../../shared/Certificationkeys/constants';
import Recreate from './Recreate';
import Name from './Name';
import {
  TCertificationkey,
  TConditions,
  TPartnerCertificationkey,
  TSenGeneralCertificationkey,
  TSenLeaderStaffCertificationkey,
} from './types';
import { kanriUrl } from '../../../../ts/url';

const Partner: React.FC<{
  certificationkey: TPartnerCertificationkey;
}> = React.memo(
  ({ certificationkey: { partnerId, partnerName, groupId, groupName } }) => (
    <>
      <li className="l-col_8">
        <ul className="l-flex">
          <li className="c-dataLabel">パートナー</li>
          <li className="c-dataValue">
            <Link className="c-textlink" to={`/partners/${partnerId}`}>
              {partnerName}
            </Link>
          </li>
        </ul>
      </li>
      <li className="l-col_8">
        <ul className="l-flex">
          <li className="c-dataLabel">団体</li>
          <li className="c-dataValue">
            <a
              className="c-textlink"
              href={kanriUrl({
                action_owner_GROUPsummary: 'true',
                groupsno: String(groupId),
              })}
            >
              {groupName}
            </a>
          </li>
        </ul>
      </li>
    </>
  )
);

const SenGeneral: React.FC<{
  certificationkey: TSenGeneralCertificationkey;
}> = React.memo(
  ({
    certificationkey: {
      societyId,
      societyName,
      createdSocietyContractTypeName,
    },
  }) => (
    <li className="l-col_8">
      <ul className="l-flex">
        <li className="c-dataLabel">団体</li>
        <li className="c-dataValue">
          <Link className="c-textlink u-mgr_xs" to={`/societies/${societyId}`}>
            {societyName}
          </Link>
          {!!createdSocietyContractTypeName && (
            <small>({createdSocietyContractTypeName})</small>
          )}
        </li>
      </ul>
    </li>
  )
);

const SenLeaderStaff: React.FC<{
  certificationkey: TSenLeaderStaffCertificationkey;
}> = React.memo(
  ({
    certificationkey: {
      societyId,
      societyName,
      societyContractId,
      societyContractTypeName,
    },
  }) => (
    <>
      <li className="l-col_8">
        <ul className="l-flex">
          <li className="c-dataLabel">団体</li>
          <li className="c-dataValue">
            <Link className="c-textlink" to={`/societies/${societyId}`}>
              {societyName}
            </Link>
          </li>
        </ul>
      </li>
      <li className="l-col_8">
        <ul className="l-flex">
          <li className="c-dataLabel">契約</li>
          <li className="c-dataValue">
            {/* TODO: link to 契約 */}
            <Link
              className="c-textlink"
              to={`/societies/${societyId}?societyContractId=${societyContractId}`}
            >
              {societyContractTypeName}
            </Link>
          </li>
        </ul>
      </li>
    </>
  )
);

const Certificationkey: React.FC<{
  certificationkey: TCertificationkey;
  conditions: TConditions;
}> = React.memo(
  ({ certificationkey, conditions: { canEditName, canRecreate } }) => (
    <div className="l-center_wrap">
      <div className="c-frame">
        <div className="u-mgb_xs l-flex_between_center">
          <div>
            <span className="c-label c-label__monotone u-fz_xs">
              写真閲覧キーID：{certificationkey.id}
            </span>
          </div>
          <div>
            <span className="c-label c-label__monotone u-fz_xs u-mgr_xs">
              登録日
            </span>
            <span className="u-fz_xs u-mgr_s">
              {dateFormat(certificationkey.createdday)}
            </span>
            <span className="c-label c-label__monotone u-fz_xs u-mgr_xs">
              更新日
            </span>
            <span className="u-fz_xs">
              {dateFormat(certificationkey.updatedday)}
              {certificationkey.updatedUserName !== null &&
                `（${certificationkey.updatedUserName}）`}
            </span>
          </div>
        </div>
        <div className="u-mgb_s l-flex_between_center">
          <h2 className="c-page_title">{certificationkey.key}</h2>
          <Recreate
            id={certificationkey.id}
            type={certificationkey.type}
            keyString={certificationkey.key}
            canRecreate={canRecreate}
          />
          <span>
            <i className="c-icon c-icon__xxsmall c-icon_blank u-mgr_xs" />
            <Link
              to={`/events?certificationKey=${certificationkey.key}`}
              target="_blank"
              className="c-textlink"
            >
              イベント一覧
            </Link>
          </span>
        </div>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          {isTenantTypeSen(certificationkey.tenantType) ? (
            (certificationkey as TSenLeaderStaffCertificationkey)
              .societyContractId ? (
              <SenLeaderStaff
                certificationkey={
                  certificationkey as TSenLeaderStaffCertificationkey
                }
              />
            ) : (
              <SenGeneral
                certificationkey={
                  certificationkey as TSenGeneralCertificationkey
                }
              />
            )
          ) : (
            <Partner
              certificationkey={certificationkey as TPartnerCertificationkey}
            />
          )}
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_8">
            <ul className="l-flex">
              <li className="c-dataLabel">キー名</li>
              <li className="c-dataValue">
                <Name
                  id={certificationkey.id}
                  name={certificationkey.name}
                  canEdit={canEditName}
                />
              </li>
            </ul>
          </li>
          <li className="l-col_8">
            <ul className="l-flex">
              <li className="c-dataLabel">キー種類</li>
              <li className="c-dataValue">{certificationkey.typeName}</li>
            </ul>
          </li>
          {certificationkey.fiscalYear !== null && (
            <li className="l-col_8">
              <ul className="l-flex">
                <li className="c-dataLabel">年度</li>
                <li className="c-dataValue">{certificationkey.fiscalYear}</li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
);

export default Certificationkey;
