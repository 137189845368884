import React, { useMemo, useCallback, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { ApiErrors } from '@/components/shared/Form/Errors';
import AlertMessages from '../../../shared/IncentivePayments/Show/AlertMessages';
import BankAccount from '../../../shared/IncentivePayments/Show/BankAccount';
import Memos from './Memos';
import SubmitButtons from '../../../shared/IncentivePayments/Show/SubmitButtons';
import TotalTable from './TotalTable';
import {
  TGroupedIncentivePaymentCost,
  TGroupedIncentivePaymentEvent,
  TGroupedIncentivePaymentOther,
  THtmlFormInputs,
  TShowHtmlData,
  TSubmit,
} from './types';
import GroupedIncentivePaymentCost from './GroupedIncentivePaymentCost';
import GroupedIncentivePaymentEvent from './GroupedIncentivePaymentEvent';
import GroupedIncentivePaymentOther from './GroupedIncentivePaymentOther';
import Header from './Header';
import {
  convertGroupedIncentivePaymentOthersToEdit,
  makeHtmlFormInputsDefault,
} from '../../../shared/IncentivePayments/Show/update';

const Payments: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  includedTaxRates: (number | null)[];
  taxRate: number | null;
  groupedIncentivePaymentEvents: TGroupedIncentivePaymentEvent[];
  groupedIncentivePaymentCosts: TGroupedIncentivePaymentCost[];
  groupedIncentivePaymentOthers: TGroupedIncentivePaymentOther[];
}> = React.memo(
  ({
    canEdit,
    validator,
    includedTaxRates,
    taxRate,
    groupedIncentivePaymentEvents: allGroupedIncentivePaymentEvents,
    groupedIncentivePaymentCosts: allGroupedIncentivePaymentCosts,
    groupedIncentivePaymentOthers,
  }) => {
    const hasEvents = !!allGroupedIncentivePaymentEvents.length;
    const hasCosts = !!allGroupedIncentivePaymentCosts.length;
    return (
      <div>
        {/* ※「＊＊手数料明細」のsticky制御のため、空のdiv着せてます */}
        {hasEvents && !hasCosts && (
          <p className="c-incentivePayment_typeTitle">
            <strong>販売手数料請求明細</strong>
          </p>
        )}
        {!hasEvents && hasCosts && (
          <p className="c-incentivePayment_typeTitle">
            <strong>原価ベース請求明細</strong>
          </p>
        )}
        {includedTaxRates.map((taxRate) => {
          const groupedIncentivePaymentEvents =
            allGroupedIncentivePaymentEvents.filter(
              (p) => p.taxRate === taxRate
            );
          const groupedIncentivePaymentCosts =
            allGroupedIncentivePaymentCosts.filter(
              (p) => p.taxRate === taxRate
            );
          return (
            <React.Fragment key={taxRate}>
              {!!groupedIncentivePaymentEvents.length && (
                <div>
                  {/* ※「＊＊手数料明細」のsticky制御のため、空のdiv着せてます */}
                  {hasEvents && hasCosts && (
                    <p className="c-incentivePayment_typeTitle">
                      <strong>販売手数料請求明細</strong>
                    </p>
                  )}
                  <h5 className="c-section_subTitle u-mgb_s u-fz_default">
                    請求明細 -{' '}
                    <strong>
                      {taxRate
                        ? `税抜売上（税率${taxRate}%時点の注文分）`
                        : '税込売上'}
                    </strong>
                  </h5>
                  {groupedIncentivePaymentEvents.map((e) => (
                    <GroupedIncentivePaymentEvent
                      canEdit={canEdit}
                      validator={validator}
                      key={e.groupId}
                      groupedIncentivePaymentEvent={e}
                    />
                  ))}
                </div>
              )}
              {!!groupedIncentivePaymentCosts.length && (
                <div>
                  {/* ※「＊＊手数料明細」のsticky制御のため、空のdiv着せてます */}
                  {hasEvents && hasCosts && (
                    <p className="c-incentivePayment_typeTitle">
                      <strong>原価ベース請求明細</strong>
                    </p>
                  )}
                  <h5 className="c-section_subTitle u-mgb_s u-fz_default">
                    請求明細 -{' '}
                    <strong>
                      {taxRate
                        ? `税抜売上（税率${taxRate}%時点の注文分）`
                        : '税込売上'}
                    </strong>
                  </h5>
                  {groupedIncentivePaymentCosts.map((c) => (
                    <GroupedIncentivePaymentCost
                      key={c.groupId}
                      groupedIncentivePaymentCost={c}
                    />
                  ))}
                </div>
              )}
            </React.Fragment>
          );
        })}
        {includedTaxRates.map((currentTaxRate) => {
          const groupedIncentivePaymentOther =
            groupedIncentivePaymentOthers.find(
              (p) => p.taxRate === currentTaxRate
            );
          return (
            <React.Fragment key={currentTaxRate}>
              {groupedIncentivePaymentOther && (
                <GroupedIncentivePaymentOther
                  canEdit={canEdit && currentTaxRate === taxRate}
                  validator={validator}
                  groupedIncentivePaymentOther={groupedIncentivePaymentOther}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
);

const Details: React.FC<{
  validator: TValidatorResponse;
  canEdit: boolean;
  totalPayment: number;
  data: TShowHtmlData;
}> = React.memo(
  ({
    canEdit,
    validator,
    totalPayment,
    data: {
      includedTaxRates,
      taxRate,
      incentivePayment,
      groupedIncentivePaymentEvents,
      groupedIncentivePaymentCosts,
      groupedIncentivePaymentOthers,
      subTotals,
    },
  }) => {
    return (
      <>
        <Payments
          canEdit={canEdit}
          validator={validator}
          includedTaxRates={includedTaxRates}
          taxRate={taxRate}
          groupedIncentivePaymentEvents={groupedIncentivePaymentEvents}
          groupedIncentivePaymentCosts={groupedIncentivePaymentCosts}
          groupedIncentivePaymentOthers={groupedIncentivePaymentOthers}
        />

        <div className="l-flex_between">
          <div className="c-incentivePayment_remarksBox">
            <Memos
              validator={validator}
              isHtml={true}
              canEdit={canEdit}
              publicMemo={incentivePayment.publicMemo}
              privateMemo={incentivePayment.privateMemo}
            />
          </div>
          <div
            className={`c-incentivePayment_totalBox${
              canEdit ? ' is-edit' : ''
            }`}
          >
            <TotalTable
              canEdit={canEdit}
              subTotals={subTotals}
              isShowPaymentCosts={groupedIncentivePaymentCosts.length > 0}
              isShowPaymentSales={groupedIncentivePaymentEvents.length > 0}
              isShowPaymentOthers={groupedIncentivePaymentOthers.length > 0}
              totalPayment={totalPayment}
            />
          </div>
        </div>
      </>
    );
  }
);

const Edit: React.FC<{
  validator: TValidatorResponse;
  data: TShowHtmlData;
  submit: TSubmit;
}> = React.memo(({ validator: orgValidator, data, submit }) => {
  const {
    partner,
    incentivePayment,
    taxRate,
    groupedIncentivePaymentEvents,
    groupedIncentivePaymentCosts,
    groupedIncentivePaymentOthers: orgGroupedIncentivePaymentOthers,
    bankAccount,
    conditions: { canEditBankAccountHasMistake },
    tradingRange,
  } = data;

  const [validator, setValidator] = useState(orgValidator);

  // NOTE: 編集では、その他請求明細は全税率分表示する
  const groupedIncentivePaymentOthers = useMemo(
    () =>
      convertGroupedIncentivePaymentOthersToEdit(
        taxRate,
        orgGroupedIncentivePaymentOthers
      ),
    [taxRate, orgGroupedIncentivePaymentOthers]
  );

  // form用に値を変換して格納
  const defaultValues = makeHtmlFormInputsDefault(
    incentivePayment,
    groupedIncentivePaymentEvents,
    groupedIncentivePaymentOthers
  );
  const methods = useForm<THtmlFormInputs>({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<THtmlFormInputs> = useCallback(
    async (formData) => {
      await submit(formData, incentivePayment.id, setValidator, 'HTML');
    },
    [submit, incentivePayment.id]
  );

  return (
    <FormProvider {...methods}>
      <ApiErrors {...validator} />
      <form method="POST" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="c-frame">
          <Header
            type="HTML"
            canEdit={true}
            incentivePaymentId={incentivePayment.id}
            partnerName={partner.name}
            status={incentivePayment.status}
            statusName={incentivePayment.statusName}
            issuedOn={incentivePayment.issuedOn}
            isShowCsvDownloadLink={groupedIncentivePaymentCosts.length > 0}
            businessRegistrationNumber={partner.businessRegistrationNumber}
            businessRegistrationTradeName={
              partner.businessRegistrationTradeName
            }
            tradingStartDay={tradingRange.startDay}
            tradingEndDay={tradingRange.endDay}
          />
          <Details
            canEdit={true}
            validator={validator}
            data={{
              ...data,
              groupedIncentivePaymentOthers,
            }}
            totalPayment={incentivePayment.totalPayment}
          />
        </div>
        <div className="c-frame">
          <BankAccount
            canEditHasMistake={canEditBankAccountHasMistake}
            bankAccount={bankAccount}
          />
        </div>
        <SubmitButtons />
      </form>
    </FormProvider>
  );
});

const Show: React.FC<{
  validator: TValidatorResponse;
  data: TShowHtmlData;
}> = React.memo(({ validator, data }) => {
  const {
    partner,
    incentivePayment,
    groupedIncentivePaymentCosts,
    bankAccount,
    conditions: { messageWhenUneditable, canEditBankAccountHasMistake },
    tradingRange,
  } = data;
  return (
    <>
      <div className="c-frame">
        <Header
          type="HTML"
          canEdit={false}
          incentivePaymentId={incentivePayment.id}
          partnerName={partner.name}
          status={incentivePayment.status}
          statusName={incentivePayment.statusName}
          issuedOn={incentivePayment.issuedOn}
          isShowCsvDownloadLink={groupedIncentivePaymentCosts.length > 0}
          businessRegistrationNumber={partner.businessRegistrationNumber}
          businessRegistrationTradeName={partner.businessRegistrationTradeName}
          tradingStartDay={tradingRange.startDay}
          tradingEndDay={tradingRange.endDay}
        />
        <Details
          canEdit={false}
          validator={validator}
          data={data}
          totalPayment={incentivePayment.totalPayment}
        />
      </div>
      <div className="c-frame">
        <BankAccount
          canEditHasMistake={canEditBankAccountHasMistake}
          bankAccount={bankAccount}
        />
      </div>
      <AlertMessages messageWhenUneditable={messageWhenUneditable} />
    </>
  );
});

const HtmlDetails: React.FC<{
  validator: TValidatorResponse;
  data: TShowHtmlData;
  submit: TSubmit;
}> = React.memo(({ validator, data, submit }) =>
  data.conditions.canEdit ? (
    <Edit validator={validator} data={data} submit={submit} />
  ) : (
    <Show validator={validator} data={data} />
  )
);

export default HtmlDetails;
