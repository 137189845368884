import React from 'react';
import styles from './FormActions.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const FormActions = React.memo<Props>(function FormActions({
  children,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
});
