import { TFormInputs } from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { RadioBoxes, TextArea } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { useEffect } from 'react';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type LunchPhotoProps = {
  methods: UseFormReturn<TFormInputs>;
  validator: TValidator;
};

export const LunchPhoto: React.FC<LunchPhotoProps> = ({
  methods,
  validator,
}) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasLunchPhoto' && type === 'change') {
        if (value.hasLunchPhoto !== 1) {
          setValue('lunchPhotoRemark', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.lunchPhoto.name}
      id={LIST_ITEMS.photography.lunchPhoto.id}
    >
      <RadioBoxes
        name="hasLunchPhoto"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasLunchPhoto') === 1 && (
        <>
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="lunchPhotoRemark"
            validator={validator}
            placeholder="昼食撮影の詳細を入力してください"
          />
        </>
      )}
    </PhotographyAccordion>
  );
};
