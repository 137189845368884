import BaseModal from '../../BaseModal';
import { TPhotograph } from './types';

export type TPhotographInfoModalProps = {
  handleClose: () => void;
  selectedPhotographs: TPhotograph[];
};

const PhotographInfoModal: React.FC<TPhotographInfoModalProps> = ({
  handleClose,
  selectedPhotographs,
}) => {
  return (
    <BaseModal handleClose={handleClose}>
      <h4 className="c-section_title">情報を確認</h4>
      <table className="u-mgt_m">
        <thead>
          <tr className="p-photographs_tableRow">
            <td className="p-photographs_tableCaption">サムネイル</td>
            <td className="p-photographs_tableCaption">掲載No</td>
            <td className="p-photographs_tableCaption">元ファイル名</td>
          </tr>
        </thead>
        <tbody>
          {selectedPhotographs.map((photograph) => (
            <tr key={photograph.id} className="p-photographs_tableRow">
              <td className="p-photographs_tableCell">
                <img
                  src={photograph.url}
                  alt=""
                  className="p-photographs_photo"
                />
              </td>
              <td className="p-photographs_tableCell">
                {photograph.photoname}
              </td>
              <td className="p-photographs_tableCell">
                {photograph.clientPath ?? '元ファイル名が保存されていません'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="btnBox u-align_center l-flex_center_line u-mgt_s">
        <span
          className="c-btn_large c-btn_cancel u-mgr_s"
          onClick={handleClose}
        >
          閉じる
        </span>
      </div>
    </BaseModal>
  );
};

export default PhotographInfoModal;
