const POSSIBLE_NAMES: string[] = [
  'isCryFacePermittedForSantaArrival',
  'isBonDancePhotographyPermittedInCircle',
  'canPrompt',
  'isPhotographerTransportationOnboardPermitted',
  'isStrobePermitted',
  'isTripodPermitted',
  'isRentalStepladderAvailable',
  'isCryFacePermittedForOgreArrival',
  'isPhotographyInsideTrackPermitted',
]; // 可能・不可
const POSSIBLE_NAMES_SHORT: string[] = [
  'isStaffParticipantPhotographyPermitted',
  'isCurrentPreschoolersParticipantPhotographyPermitted',
  'isGraduationPreschoolersParticipantPhotographyPermitted',
  'isParentParticipantPhotographyPermitted',
  'isExternalInstructorParticipantPhotographyPermitted',
  'isExternalParticipantPhotographyPermitted',
  'isOtherParticipantPhotographyPermitted',
]; // 可

const NECESSARY_NAMES: string[] = ['requireOwnTripod']; // 必要・不要
const OPTIONAL_NAMES: string[] = ['isPhotographerCostumeAvailable']; // お任せ・不要

export const toRadioBtnText = (
  name: string,
  value: number | undefined | null
): string => {
  switch (value) {
    case 1:
      return convertTrueText(name, value);
    case 0:
      return convertFalseText(name, value);
    default:
      return '未確認';
  }
};

const convertTrueText = (name: string, value: number | undefined): string => {
  if (POSSIBLE_NAMES.includes(name)) {
    return '可能';
  }
  if (POSSIBLE_NAMES_SHORT.includes(name)) {
    return '可';
  }
  if (NECESSARY_NAMES.includes(name)) {
    return '必要';
  }
  if (OPTIONAL_NAMES.includes(name)) {
    return 'おまかせ';
  }
  return 'あり';
};

const convertFalseText = (name: string, value: number | undefined): string => {
  if (POSSIBLE_NAMES.includes(name) || POSSIBLE_NAMES_SHORT.includes(name)) {
    return '不可';
  }
  if (NECESSARY_NAMES.includes(name) || OPTIONAL_NAMES.includes(name)) {
    return '不要';
  }
  return 'なし';
};
