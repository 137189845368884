import React from 'react';
import { TPhotographer } from '../types';
import { Link } from 'react-router-dom';
import { dateFormatFromISO8601 } from '@/ts/formatTools';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

const BasicProfile: React.FC<{
  photographer: TPhotographer;
}> = ({ photographer }) => {
  const [showEmergency, setShowEmergency] = React.useState(false);
  const showEmergencyContact = () => {
    setShowEmergency(true);
  };
  return (
    <div className="c-frame">
      <div className="u-pdb_s u-mgb_m">
        <div className="u-pdt_xs u-pdb_xs u-mgb_s l-flex_between">
          <h2 id="p-photographersShow_basic-info">基本情報</h2>
          <Link
            className="c-btn_rectangle c-btn_small c-btn_primary u-mgr_s"
            to={`/photographers/${photographer.id}/edit`}
          >
            編集
          </Link>
        </div>
        <table className="p-photographersShow_photographerTable">
          <tbody>
            <TableRow>
              <TableCell title={'権限'}>{photographer.authorityName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'氏名'}>
                {photographer.sei &&
                photographer.mei &&
                photographer.seiKana &&
                photographer.meiKana
                  ? `${photographer.sei}${photographer.mei}(${photographer.seiKana}${photographer.meiKana})`
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'短縮名'}>{photographer.shortName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'戸籍名'}>
                {photographer.familyRegisterName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'顔写真'}>
                {photographer.photographerImageUrl ? (
                  <img src={photographer.photographerImageUrl} alt="" />
                ) : (
                  <div className="p-photographersShow_facePhoto p-photographersShow_facePhotoNothing">
                    写真がありません
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'性別'}>{photographer.genderName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'生年月日'}>
                {dateFormatFromISO8601(photographer.birthdate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'カメラマン組織'}>
                {photographer.photographerOrganizationId &&
                photographer.photographerOrganizationName ? (
                  <Link
                    to={`/photographer_organizations/${photographer.photographerOrganizationId}`}
                  >
                    {(photographer.photographerOrganizationId ?? '') +
                      ' / ' +
                      (photographer.photographerOrganizationName ?? '')}
                  </Link>
                ) : (
                  <p className={'c-no_data_text'}>設定されていません。</p>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'郵便番号'}>{photographer.postCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'住所'}>
                {photographer.prefectureName +
                  photographer.city +
                  photographer.address}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'緯度経度'} helpText={'自動アサイン処理で使用'}>
                {photographer.lng && photographer.lat ? (
                  `緯度: ${photographer.lat} 経度: ${photographer.lng}`
                ) : (
                  <p className={'c-no_data_text'}>設定されていません。</p>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'最寄駅'}>{photographer.station}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'メールアドレス'}>
                {photographer.mailAddresses.map((mailAddress, index) => {
                  return <p key={index}>{mailAddress}</p>;
                })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'電話番号'}>{photographer.telephone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell title={'緊急連絡先'}>
                {showEmergency ? (
                  <>
                    <ul className="l-flex l-col_12">
                      <li className="c-dataLabel">名前</li>
                      <li className="c-dataValue">
                        {' : ' +
                          photographer.emergencyFirstName +
                          ' ' +
                          photographer.emergencyLastName}
                      </li>
                    </ul>
                    <ul className="l-flex l-col_12">
                      <li className="c-dataLabel">電話番号</li>
                      <li className="c-dataValue">
                        {' : ' + photographer.emergencyTelephone}
                      </li>
                    </ul>
                    <ul className="l-flex l-col_12">
                      <li className="c-dataLabel">続柄</li>
                      <li className="c-dataValue">
                        {' : ' + photographer.emergencyRelationship}
                      </li>
                    </ul>
                  </>
                ) : (
                  <button
                    onClick={showEmergencyContact}
                    className="c-btn_small c-btn_rectangle c-btn_drawer u-mgt_s"
                    type="button"
                  >
                    緊急連絡先を表示
                  </button>
                )}
              </TableCell>
            </TableRow>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BasicProfile;
