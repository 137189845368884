import { UseFormReturn } from 'react-hook-form';
import {
  TConstants,
  TFormInputs,
} from '@/components/pages/Events/Show/Photography/types';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import {
  Checkboxes,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';
import { Alert } from '@/components/shared/Alert';
import { useEffect } from 'react';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type NgPhotographySubjectProps = {
  methods: UseFormReturn<TFormInputs>;
  constants: TConstants;
  validator: TValidator;
};

export const NgPhotographySubject: React.FC<NgPhotographySubjectProps> = ({
  methods,
  constants,
  validator,
}) => {
  const { watch, setValue } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasNgPhotographySubject' && type === 'change') {
        if (value.hasNgPhotographySubject !== 1) {
          setValue('ngPhotographySubjectTypeIds', []);
          setValue('ngPhotographySubjectSenNote', '');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <PhotographyAccordion
      title={LIST_ITEMS.photography.ngPhotographySubject.name}
      id={LIST_ITEMS.photography.ngPhotographySubject.id}
    >
      <RadioBoxes
        name="hasNgPhotographySubject"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
      {watch('hasNgPhotographySubject') === 1 && (
        <>
          <div className="t-textColor_sub">対象詳細</div>
          <Checkboxes
            name="ngPhotographySubjectTypeIds"
            validator={validator}
            choices={constants.ngPhotographySubjectTypes}
          />
          <div className="t-textColor_sub">詳細</div>
          <TextArea
            name="ngPhotographySubjectSenNote"
            validator={validator}
            placeholder="撮影NG対象の詳細を入力してください"
          />
        </>
      )}
      <Alert type="warning" title="対象児について">
        <ul>
          <li>
            ・撮影NG対象のお子さんがいる場合は、目印や人数などを詳細に記載してください。
          </li>
          <li>
            ・名札・ロッカーなど顔と名前が一致する状態での撮影NGがある場合は、「対象児あり」を選択して詳細に記載してください。
          </li>
        </ul>
      </Alert>
    </PhotographyAccordion>
  );
};
