import React from 'react';
import { TIndexData } from './types';
import PriceTable from '@/components/shared/PriceTable/App';

const Price: React.FC<{ data: TIndexData }> = ({ data }) => {
  const price = data.price;
  if (!price) {
    // フリーDLの分岐
    return <p>価格未定</p>;
  }
  return (
    <>
      <div className="t-bgBox_gray u-mgt_xs">
        <PriceTable {...price} showId={true} />
      </div>
      <div className="t-bgBox_brown u-mgt_xs">
        ・括弧内は税込価格です。
        <br />
        ・価格が表示されていない写真は、販売しておりません。
        <br />
        ・高解像度イベントではデータ小は販売されません。
      </div>
    </>
  );
};

export default Price;
