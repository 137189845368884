import React from 'react';

const SaveApiErrors: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) =>
    messages.length ? (
      <div className="c-error_block">
        {messages.map((message, index) => (
          <ul className="c-error_list" key={index}>
            <li className="c-error_listItem">{message}</li>
          </ul>
        ))}
      </div>
    ) : null
);

export default SaveApiErrors;
