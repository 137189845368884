import React, { useRef, useState, ChangeEventHandler } from 'react';
import dayjs from 'dayjs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TextInput, TextArea } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import { errorToast, successToast } from '@/ts/toast';
import { fetcher, toMessages } from '@/ts/useApi';
import Download from '../Download';
import { TContainer, TFormInputs, TMail } from '../types';
import '../flyer.scss';

const SendApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSendFormProps = {
  eventno: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  data: TContainer;
};

const SendForm: React.FC<TSendFormProps> = React.memo(
  ({ eventno, validator, data }) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isSalesMail, setIsSalesMail] = useState(!!data.salesMail);
    const [photographerMails, setPhotographerMails] = useState(
      data.photographerMails
    );
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = (ev) => {
      const newMails: TMail = {};
      for (const mail of Object.keys(photographerMails)) {
        newMails[mail] = photographerMails[mail];
        if (ev.target.dataset.mail === mail) {
          newMails[mail] = ev.target.checked;
        }
      }
      setPhotographerMails(newMails);
    };
    const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
      if (!isSalesMail) {
        formData.salesMail = null;
      }
      formData.photographerMails = photographerMails;
      if (window.confirm('チラシを送付します。よろしいですか？')) {
        try {
          await fetcher(`/api/events/${eventno}/flyer/mail/send`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          successToast('送信しました');
          history.push(`/events/${eventno}/eventflyer`);
        } catch (e) {
          setErrorMessages(toMessages(e));
          errorToast('エラーが発生しました');
        }
      }
    };
    const dateFormat = (date: string, format: string) =>
      dayjs(date).format(format);
    const PeriodSales = data.isEnabledOutOfPeriodSales
      ? `※バリュー期間は${dateFormat(
          data.valueendday,
          'M月D日(ddd)'
        )}までです。バリュー期間終了後は価格が25％アップします。\n`
      : '';
    const PeriodSalesMessage = data.isEnabledOutOfPeriodSales
      ? `※バリュー期間は${dateFormat(
          data.valueendday,
          'M月D日(ddd)'
        )}までです。バリュー期間終了後は価格が25％アップします。\n`
      : '';

    const mailContent = [
      `${data.societyName}　ご担当者様`,
      ``,
      `いつもお世話になっております。`,
      `はいチーズ！フォト サポートグループでございます。`,
      ``,
      `${dateFormat(
        data.valuestartday,
        'M月D日(ddd)'
      )}から公開予定のイベントのご連絡です。`,
      `チラシ配布にて、保護者様へ販売のご案内をお願いいたします。`,
      ``,
      `■ イベント名`,
      data.events.map((ev) => `${ev.eventname}`).join('\n'),
      ``,
      `■ 写真公開日`,
      `${dateFormat(data.valuestartday, 'M月D日(ddd)')}`,
      PeriodSales,
      `■ 保護者様向け案内チラシ`,
      `本メール添付のPDFをご活用ください。`,
      `※テキストでもご案内できる場合は、下記をコピーしてご活用ください。`,
      ``,
      `■ お願い事項`,
      `・できるだけ写真公開当日に保護者様にご案内ください。`,
      `・園にご所属の保護者さま専用のご案内となります。外部への共有はおやめください。`,
      ``,
      `＝＝＝＝＝＝＝＝＝＝（保護者様向け案内テキスト　ここから）＝＝＝＝＝＝＝＝＝＝`,
      ``,
      `はいチーズ！写真販売のお知らせ`,
      ``,
      `はいチーズ！にて、写真販売が始まります。お見逃しなくご覧ください。`,
      ``,
      `◆イベント`,
      data.events
        .map(
          (ev) =>
            `${ev.eventname}(${dateFormat(ev.photographingday, 'M/D')}撮影)`
        )
        .join('\n'),
      ``,
      `◆写真はコチラ`,
      `https://8122.jp/?utm_source=manualmail&utm_medium=email&utm_campaign=textmail`,
      ``,
      `◆写真閲覧キー`,
      `${data.certificationkey}`,
      ``,
      `◆販売開始日`,
      `${dateFormat(data.valuestartday, 'M月D日(ddd)')}`,
      PeriodSalesMessage,
      `◆対象の写真が見れない方へ`,
      `会員登録や写真閲覧キーの登録がお済みでない方は写真の閲覧ができません。`,
      `写真の閲覧ができない場合は、下記URLからアクセスをお試しください。`,
      `https://8122.jp/?certificationkey=${data.certificationkey}&utm_source=manualmail&utm_medium=email&utm_campaign=textmail`,
      ``,
      `写真の公開まで楽しみにお待ちください★`,
      ``,
      `＝＝＝＝＝＝＝＝＝＝（保護者様向け案内テキスト　ここまで）＝＝＝＝＝＝＝＝＝＝`,
      ``,
      `どうぞよろしくお願いいたします。`,
      `はいチーズ！フォト（千株式会社）`,
      `URL：https://8122.jp/`,
      `お問い合わせ：こちらのメールにご返信ください`,
      ``,
      `下記の時期は、通常よりも早めにチラシをお届けしています。`,
      `※「夏期・冬期休暇」がない団体様は通常通りのお届けとなります。`,
      `　　■夏期休暇前・・・6月下旬～7月上旬にお届け`,
      `　　■冬期休暇前・・・11月下旬～12月中旬にお届け`,
      `　　■卒園・卒業シーズン前・・・2月中旬～3月中旬にお届け`,
      ``,
    ].join('\n');

    const eventFlyerName = `${data.flyerFileName}.pdf`;
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    const defaultValues = {
      fileName: eventFlyerName,
      subject: `【はいチーズ！フォト】チラシ配布のお願い | ${dayjs(
        data.valuestartday
      ).format('M月D日(ddd)')}から公開予定のイベントがあります`,
      leaderMail: data.leaderMail,
      salesMail: data.salesMail,
      photographerMails: data.photographerMails,
      mailContent: mailContent,
    };
    const methods = useForm<TFormInputs>({ defaultValues });

    return (
      <FormProvider {...methods}>
        <SendApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>ファイル</label>
            </li>
            <li className="c-dataValue">
              <Download
                eventno={eventno}
                eventFlyerName={eventFlyerName}
                downloadLinkRef={downloadLinkRef}
              />
              <span>({eventFlyerName})</span>
              <input type="hidden" name="fileName" value={eventFlyerName} />
              <a href="/" className="is-hidden" ref={downloadLinkRef}>
                dummy
              </a>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>宛先</label>
            </li>
            <li className="c-dataValue">
              <div className="l-flex nowrap u-mgb_xs">
                {data.leaderMail ? (
                  <span
                    className="t-bgBox_org u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <${data.leaderMail}>`}</span>
                ) : (
                  <span
                    className="t-bgBox_gray u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <none>`}</span>
                )}
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>CC</label>
            </li>
            <li className="c-dataValue">
              <div className="nowrap u-mgb_xs">
                <label className="c-checkboxLabel">
                  <input
                    name={`salesMail`}
                    className={`c-checkbox`}
                    type="checkbox"
                    defaultChecked={!!data.salesMail}
                    disabled={!data.salesMail}
                    onChange={() => {
                      setIsSalesMail((c) => !c);
                    }}
                  />
                  <span className="c-label_checkbox small">
                    {`担当営業 <${data.salesMail ?? '設定なし'}>`}
                  </span>
                </label>
                {Object.keys(photographerMails).length <= 0 ? (
                  <div className="l-flex nowrap u-mgb_xs">
                    <label className="c-checkboxLabel">
                      <span className="c-label_checkbox small">
                        担当カメラマン &lt;設定なし&gt;
                      </span>
                    </label>
                  </div>
                ) : (
                  Object.keys(photographerMails).map((mail, idx) => (
                    <div key={idx} className="nowrap u-mgb_xs">
                      <label className="c-checkboxLabel">
                        <input
                          type="checkbox"
                          name={`photographerMails[]`}
                          className={`c-checkbox`}
                          defaultChecked={true}
                          onChange={onChangeCheck}
                          data-mail={mail}
                        />
                        <span className="c-label_checkbox small">
                          {`担当カメラマン <${mail}>`}
                        </span>
                      </label>
                    </div>
                  ))
                )}
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>イベント</label>
            </li>
            <li className="c-dataValue">
              {data.events.map((ev, idx) => (
                <div key={idx} className="l-flex nowrap u-mgb_xs">
                  <Link to={`/events/${ev.id}`} target="_blank">
                    {ev.id}
                  </Link>
                  <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
                  <span>{ev.eventname}</span>
                </div>
              ))}
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>件名</label>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="subject"
                validator={validator}
                additionalClassName="p-flyer_titleInput"
              />
            </li>
          </ul>

          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">本文</li>
            <li className="c-dataValue">
              <TextArea
                name="mailContent"
                validator={validator}
                rows={20}
                cols={10}
              />
            </li>
          </ul>
          <p className="u-align_center l-flex_center_line">
            <Link
              className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-linkLabel"
              to={`/events/${eventno}/eventflyer`}
            >
              キャンセル
            </Link>
            {data.leaderMail ? (
              <input
                className="c-btn_large c-btn_edit is-arrow c-input_submit"
                type="submit"
                value={'送信する'}
              />
            ) : (
              <span className="u-fz_xs u-mgl_m c-alert_text">
                責任者が設定されていません
              </span>
            )}
            {data.dateSentMail && (
              <span className="u-fz_xs u-mgl_m">
                送信日時：
                {dayjs(data.dateSentMail).format('YYYY-MM-DD HH:mm')}
              </span>
            )}
          </p>
          <a href="/" className="is-hidden" ref={downloadLinkRef}>
            dummy
          </a>
        </form>
      </FormProvider>
    );
  }
);

export default SendForm;
