import React from 'react';
import { Photograph } from '../../hooks';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { Table } from '@/components/shared/Table';
import { useResultPanel } from './hooks';

type Props = {
  photographs?: Photograph[];
};

const columns = [
  'サムネイル',
  '団体',
  'イベント',
  'カテゴリ',
  '写真ユニークID',
  'ファイル名',
  'カメラマン[ID]',
  '属性',
  '取り込み日',
  'リンク',
];
const columnsParam = [
  '200px',
  'minmax(100px, 1fr)',
  'minmax(100px, 1fr)',
  '100px',
  '100px',
  '100px',
  '110px',
  '100px',
  '100px',
  '100px',
];

export const ResultPanel = React.memo<Props>(function ResultPanel({
  photographs,
}) {
  const { rows } = useResultPanel({ photographs });
  if (!rows || rows?.length === 0) {
    return null;
  }

  return (
    <ContentPanel marginTop="large">
      <Table columns={columns} rows={rows} gridTemplateColumns={columnsParam} />
    </ContentPanel>
  );
});
