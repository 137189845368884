import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import SearchButtons from '../Globalmenu/SearchButtons';
import SearchInput from './SearchInput';

const SearchBox: React.FC = () => {
  const [searchWord, setSearchWord] = useState('');
  const location = useLocation();
  useEffect(() => {
    const currentSearch = QueryString.parse(location.search)['globalSearch'];
    setSearchWord(typeof currentSearch === 'string' ? currentSearch : '');
  }, [location]);
  const searchWordChanged = useCallback((s: string) => setSearchWord(s), []);
  return (
    <div className="p-side_searchBox">
      <form name="globalSearch" method="GET">
        <div className="l-relative">
          <SearchInput
            searchWord={searchWord}
            onSearchWordChange={searchWordChanged}
          />
          <span className="p-side_search_icon t-textColor_sub">
            <i className="c-icon_search"></i>
          </span>
        </div>
        <div className="p-side_search_mask_sp">
          <ul className="p-side_search_submitList c-clearfix">
            <SearchButtons searchWord={searchWord} type="side" />
          </ul>
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
