import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import { FC } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import { useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { IndexResponse, TFormInputs } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import dayjs from 'dayjs';
import QueryString from 'query-string';
import { SearchForm } from './SearchForm';
import { TMutate } from '@/ts/useJsonApi';

const convertStringToBoolean = (
  object: Record<string, unknown>
): Record<string, unknown> => {
  const copied = { ...object };
  Object.keys(copied).forEach((key) => {
    if (copied[key] === 'true') {
      copied[key] = true;
    } else if (copied[key] === 'false') {
      copied[key] = false;
    }
  });
  return copied;
};
const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(
    convertStringToBoolean(convertQueryToFormData(queryString))
  );
  const dayStartAtParam = dayjs().subtract(1, 'month').format('YYYY-MM-01');
  const dayEndAtParam = dayjs().endOf('month').format('YYYY-MM-DD');
  if (
    Object.keys(queryParam).filter((name) => name === 'photographingDayStartAt')
      .length === 0
  ) {
    queryParam['photographingDayStartAt'] = dayStartAtParam;
  }
  if (
    Object.keys(queryParam).filter((name) => name === 'photographingDayEndAt')
      .length === 0
  ) {
    queryParam['photographingDayEndAt'] = dayEndAtParam;
  }
  if (
    Object.keys(queryParam).filter((name) => name === 'isEmptyExpenses')
      .length === 0
  ) {
    queryParam['isEmptyExpenses'] = true;
  }
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (queryParam['isEmptyExpenses'] === false) {
    delete queryParam['isEmptyExpenses'];
  }
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[0-9]+$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/transportation_expenses?${query}`;
};

export const Content: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
}> = ({ response, mutate }) => {
  const history = useHistory();
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const onSubmit = (formData: TFormInputs) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/transportation_expenses',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          { id: 3, title: '交通費一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_between">
          <h1 className="l-flex_center c-page_title">交通費一覧</h1>
          <div className="l-flex_center">
            ガソリン代 : {response.formItems.perKmGasolinePrice}円/ 1km計算
          </div>
        </div>
        <SearchForm
          response={response}
          onSubmit={onSubmit}
          queryParams={queryParams}
        />
        <Results
          response={response}
          editValidator={response.editValidator}
          mutate={mutate}
          queryParams={queryParams}
        />
      </div>
    </IndexPageLayout>
  );
};
export const IndexPage: FC = () => {
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));

  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as IndexResponse}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return <Content response={response} mutate={mutate} />;
};
