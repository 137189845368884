import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import Header from '../Header';
import SaveForm from './SaveForm';
import { TFormInputs, TSaveResponse, TCreateResponse } from './types';
import './bankAccountCreate.scss';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data, error } = useJsonApi<TCreateResponse>(
    `/api/society_contracts/${contractId}/bank_accounts/create`
  );
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const {
    data: { society, societyContract },
    formItems,
  } = data;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher(
        `/api/society_contracts/${contractId}/bank_accounts`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }

      successToast('更新しました');
      history.push(`/society_contracts/${contractId}/bank_accounts`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  return (
    <div className="App">
      <h1 className="l-flex_center u-mgb_m c-page_title">
        口座情報 - 新規登録
      </h1>
      <Header society={society} societyContract={societyContract} />
      <div className="l-center_wrap">
        <SaveForm
          validator={validator}
          types={formItems}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="登録する"
          cancelLink={`/societies/${society.id}`}
        />
      </div>
    </div>
  );
};

export default App;
