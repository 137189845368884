import React from 'react';
import styles from './index.module.scss';
import { TableRow } from '../..';

type Props = {
  rows: TableRow[];
  gridTemplateColumns: string;
  columnStyle?: React.CSSProperties;
};

export const TableBody = React.memo<Props>(function TableBody({
  rows,
  gridTemplateColumns,
  columnStyle,
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {rows.map((row, index) => (
          <div
            key={`tableRow-${index}`}
            className={styles.row}
            style={{ gridTemplateColumns }}
          >
            {row.map((column, colIndex) => (
              <div
                key={`tableColumn-${index}-${colIndex}`}
                className={styles.column}
                style={columnStyle}
              >
                {column}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
});
