import { Table } from '@/components/shared/Table';
import React from 'react';
import styles from './index.module.scss';

const columns = ['event', 'event(sendgrid type)', '備考'];

const columnsParam = ['130px', '170px', '370px'];

const rows = [
  [
    '処理済み',
    'processed',
    '送信処理の実行完了',
    '送信不可',
    'dropped',
    '何らかの理由でメールの受信をブロックされている 詳細はサプレッションリスト参照',
    '送信完了',
    'delivered',
    '送信成功（受信の検知）',
    '時的な送信失敗（リトライあり）',
    'deferred',
    '',
    '送信失敗',
    'bounce',
    '詳細はサプレッションリスト参照',
    '開封',
    'open',
    'メールの開封',
    'URLクリック',
    'click',
    'メールのURLを押下された際に検知されたもの',
  ],
];

export const LegendTable = React.memo(function LegendTable() {
  return (
    <div className={styles.content}>
      <Table columns={columns} rows={rows} gridTemplateColumns={columnsParam} />
    </div>
  );
});
