import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { errorToast, successToast } from '../../../../ts/toast';
import { kanriUrl } from '../../../../ts/url';
import { putJson } from '../../../../ts/useApi';
import { isTenantTypeSen } from '../../../shared/Certificationkeys/constants';
import { TPartnerCertificationkey, TSenCertificationkey } from '../../../types';
import { TKeyBundle } from './types';

const KeyGroup: React.FC<{
  certificationkey: TPartnerCertificationkey;
}> = React.memo(({ certificationkey: { groupId, groupName } }) => (
  <a
    className="c-textlink"
    href={kanriUrl({
      action_owner_GROUPsummary: 'true',
      groupsno: String(groupId),
    })}
  >
    {groupName}
  </a>
));
const KeySociety: React.FC<{
  certificationkey: TSenCertificationkey;
}> = React.memo(({ certificationkey: { societyId, societyName } }) => (
  <Link className="c-textlink" to={`/societies/${societyId}`}>
    {societyName}
  </Link>
));

const KeyBundle: React.FC<{
  keyBundle: TKeyBundle;
}> = React.memo(({ keyBundle: { bundleId, certificationkeys } }) => {
  return (
    <tr>
      <td className="c-indexList_column_s">
        <Link className="c-textlink" to={'/key_bundles/' + bundleId}>
          {bundleId}
        </Link>
      </td>
      <td className="c-indexList_column_xl">
        {certificationkeys.map((c) => (
          <ul
            key={c.id}
            className="l-flex p-certificationkeysShow_keyBundles_certificationkeys"
          >
            <li className="u-mgr_s">{c.typeName}</li>
            <li className="u-mgr_s">
              <Link className="c-textlink" to={'/certificationkeys/' + c.id}>
                {c.key}
              </Link>
              {!!c.name && <small>({c.name})</small>}
            </li>
            <li>
              {isTenantTypeSen(c.tenantType) ? (
                <KeySociety certificationkey={c as TSenCertificationkey} />
              ) : (
                <KeyGroup certificationkey={c as TPartnerCertificationkey} />
              )}
            </li>
          </ul>
        ))}
      </td>
    </tr>
  );
});

const Create: React.FC<{
  id: number;
}> = React.memo(({ id }) => {
  const history = useHistory();
  const createKeyBundle = useCallback(async () => {
    if (!window.confirm('キー束を新規登録します。よろしいですか？')) {
      return;
    }
    try {
      const { data } = await putJson<{ data: { bundleId: number } }>(
        `/api/certificationkeys/${id}/key_bundles`
      );
      successToast('キー束を登録しました');
      history.push(`/key_bundles/${data.bundleId}`);
    } catch (error) {
      errorToast('エラーが発生しました');
    }
  }, [id, history]);

  return (
    <button className="c-btn_rectangle c-btn_create" onClick={createKeyBundle}>
      <i className="c-icon_xxsmall c-icon_create" />
    </button>
  );
});

const CreateButton: React.FC<{
  id: number;
  canAddToKeyBundles: boolean;
}> = React.memo(({ id, canAddToKeyBundles }) =>
  canAddToKeyBundles ? (
    <Create id={id} />
  ) : (
    <button className="c-btn_rectangle c-btn_create is-disabled">
      <i className="c-icon_xxsmall c-icon_create" />
    </button>
  )
);

const KeyBundles: React.FC<{
  id: number;
  keyBundles: TKeyBundle[];
  canAddToKeyBundles: boolean;
}> = React.memo(({ id, keyBundles, canAddToKeyBundles }) => (
  <div className="c-frame">
    <div className="l-flex_between_center u-mgb_s">
      <h2 className="c-section_title">キー束一覧 (全{keyBundles.length}件)</h2>
      <CreateButton id={id} canAddToKeyBundles={canAddToKeyBundles} />
    </div>
    {keyBundles.length ? (
      <table className="c-indexList">
        <thead>
          <tr>
            <th>キー束番号</th>
            <th>
              キー<small>（最大3件表示）</small>
            </th>
          </tr>
        </thead>
        <tbody>
          {keyBundles.map((keyBundle) => (
            <KeyBundle key={keyBundle.bundleId} keyBundle={keyBundle} />
          ))}
        </tbody>
      </table>
    ) : (
      <>
        <hr className="u-line_plane" />
        <div className="c-emptyState_box">キー束が登録されていません</div>
      </>
    )}
  </div>
));

export default KeyBundles;
