import React from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { RadioBoxes } from '@/components/shared/Form/Inputs';
import { ID_UNCONFIRMED } from '@/components/pages/Events/Show/Photography/const';

export const NapPhoto: React.FC<{
  validator: TValidator;
}> = ({ validator }) => {
  return (
    <>
      <div className="t-textColor_sub">午睡撮影</div>
      <RadioBoxes
        name="hasNapPhoto"
        validator={validator}
        choices={[
          { key: ID_UNCONFIRMED, value: '未確認' },
          { key: 1, value: 'あり' },
          { key: 0, value: 'なし' },
        ]}
      />
    </>
  );
};
