import React from 'react';
import {
  OrderDeliveryInfo as DeliveryInfo,
  OrderInfo,
  PhotoSummary as PhotoSummaryEntity,
  PrintLog as PrintLogEntity,
} from '../types';
import { PrintLog } from '../../components/PrintLog';
import { PhotoSummary } from './PhotoSummary';

type Props = {
  orderInfo: OrderInfo;
  deliveryInfo: DeliveryInfo;
  printLog: PrintLogEntity;
  summary: PhotoSummaryEntity;
};
export const OrderDeliveryInfo = React.memo<Props>(
  ({ orderInfo, deliveryInfo, printLog, summary }) => {
    return (
      <div className="c-frame">
        <div className="l-flex_between_center p-groupsSummary_Label">
          <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
            odino: {deliveryInfo.id}
          </span>
        </div>
        <div className="l-flex_between_center u-mgb_s">
          <h4 className="c-section_title">配送情報</h4>
          <div className="l-flex">
            <div className="u-mgr_s">
              <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                {deliveryInfo.groupName ? '一括配送' : '個別配送'}
              </span>
              <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                印刷会社: {deliveryInfo.printCompanyName}
              </span>
            </div>
          </div>
        </div>
        <div className="u-mgt_s u-mgb_m">
          <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
            <li className="l-col_24">
              <ul className="l-flex">
                <li className="c-dataLabel">配送先</li>
                <li className="c-dataValue">
                  {deliveryInfo.groupName && (
                    <>
                      {deliveryInfo.groupName + '様'}
                      <br />
                      [年度:クラス名]{deliveryInfo.nendo}:
                      {deliveryInfo.className}
                      <br />
                    </>
                  )}
                  {deliveryInfo.firstName} {deliveryInfo.secondName}　様 <br />
                  〒{deliveryInfo.postalCode} {deliveryInfo.prefectureName}
                  {deliveryInfo.city} {deliveryInfo.address}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <PrintLog
          orderInfoId={orderInfo.id}
          orderDeliveryInfoId={deliveryInfo.id}
          printLog={printLog}
        />

        <PhotoSummary orderInfo={orderInfo} summary={summary} />
      </div>
    );
  }
);
