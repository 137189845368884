import React from 'react';
import { Link } from 'react-router-dom';

import { incentiveRateToString } from '../../../../ts/formatTools';
import * as constants from '../constants';
import StatusLabel from '../../../shared/StatusLabel/App';
import { TPartner } from './types';

type TProps = { partner: TPartner };

const IncentiveRates: React.FC<TProps> = ({ partner }) => {
  if (partner.plan === constants.PLAN_BOTH) {
    const fullIncentive =
      partner.incentiveRateFullOfBoth !== null
        ? `販売 ${incentiveRateToString(partner.incentiveRateFullOfBoth)}%`
        : `原価 ${incentiveRateToString(
            partner.wholesaleCommissionRateFullOfBoth
          )}%`;
    const selfIncentive =
      partner.incentiveRateSelfOfBoth !== null
        ? `販売 ${incentiveRateToString(partner.incentiveRateSelfOfBoth)}%`
        : `原価 ${incentiveRateToString(
            partner.wholesaleCommissionRateSelfOfBoth
          )}%`;
    return (
      <>
        {fullIncentive}
        {' (フル)'}
        <br />
        {selfIncentive}
        {' (セルフ)'}
      </>
    );
  } else {
    const incentive =
      partner.incentiveRate !== null
        ? `販売 ${incentiveRateToString(partner.incentiveRate)}%`
        : `原価 ${incentiveRateToString(partner.wholesaleCommissionRate)}%`;
    return <>{incentive}</>;
  }
};

const SearchResultItem: React.FC<TProps> = ({ partner }) => (
  <tr>
    <td className="c-indexList_column_l">
      <div>
        <div className="c-idLink_hasBg c-indexList_id c-indexList_id__short">
          <i className="c-icon c-icon__xxsmall c-icon_partner u-mgr_xs"></i>
          {partner.id}
        </div>
      </div>
      <Link className="c-textlink" to={`/partners/${partner.id}`}>
        {partner.name}（{partner.viewname}）
      </Link>
    </td>
    <td className="c-indexList_column_s">{partner.planName}</td>
    <td className="c-indexList_column_l">
      {'〒'}
      {partner.postcode}
      <br />
      {`${partner.prefectureName}${partner.city}${partner.address}`}
    </td>
    <td className="c-indexList_column_m">{partner.telephonenumber}</td>
    <td className="c-indexList_column_m">{partner.salesDepartmentOwnerName}</td>
    <td className="c-indexList_column__nowrap">
      <StatusLabel
        isPositive={partner.status === constants.STATUS_ACTIVE}
        statusName={partner.statusName}
      />
    </td>
    <td className="c-indexList_column_m c-indexList_column__nowrap">
      <IncentiveRates partner={partner} />
    </td>
    <td className="c-indexList_column__nowrap">
      {incentiveRateToString(partner.goodsIncentiveRate)}%
    </td>
    <td className="c-indexList_column__nowrap">
      {partner.printingCompanyPhotoCorrectionType ===
      constants.PRINTING_COMPANY_PHOTO_CORRECTION_TYPE_CAMERAMAN
        ? '依存'
        : partner.printingCompanyPhotoCorrectionTypeName}
    </td>
    <td className="c-indexList_column__nowrap">
      {partner.isEnabledSellByFaxName}
    </td>
  </tr>
);

export default SearchResultItem;
