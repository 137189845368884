import React from 'react';
import { Link } from 'react-router-dom';
import { TFormInputs, TFormItems } from './types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TextInput, SingleSelect } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TBankAccount } from '../../types';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSaveFormProps = {
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  bankAccount: TBankAccount;
  types: TFormItems;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    validator,
    bankAccount,
    types,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const methods = useForm<TFormInputs>({
      defaultValues: bankAccount,
    });

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>金融機関名</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="bankName"
                      placeholder="金融機関名"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>支店名</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="branchName"
                      placeholder="支店名"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>口座種類</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="type"
                      choices={types.type}
                      placeholder="口座種類"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>口座番号</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="number"
                      placeholder="1234567"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>口座名義</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="holder"
                      placeholder="カ）ハイチーズ"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <div className="u-align_center u-mgb_m">
              <Link
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                to={cancelLink}
              >
                キャンセル
              </Link>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
