import React, { useCallback, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { NumberInput } from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';

import { numberFormat } from '../../../../ts/formatTools';
import { TPdfFormInputs, TShowPdfData, TSubmit } from './types';
import AlertMessages from './AlertMessages';
import BankAccount from './BankAccount';
import Memos from './Memos';
import SubmitButtons from './SubmitButtons';
import UploadPdfFile from './UploadPdfFile';
import { TTarget } from '../types';

const Details: React.FC<{
  target: TTarget;
  canEdit: boolean;
  validator: TValidatorResponse;
  data: TShowPdfData;
}> = React.memo(
  ({
    target,
    canEdit,
    validator,
    data: { isPdfSaved, pdfFilename, pdfUrl, incentivePayment },
  }) => (
    <>
      <h5 className="c-section_subTitle_br u-mgb_m u-fz_default">
        <strong>PDF請求書</strong>
      </h5>
      <div className="l-flex_between_center u-mgb_m">
        {canEdit ? (
          <UploadPdfFile
            target={target}
            incentivePaymentId={incentivePayment.id}
            pdfFilename={pdfFilename}
            pdfUrl={pdfUrl}
            isPdfSaved={isPdfSaved}
          />
        ) : isPdfSaved ? (
          <div className="l-flex_center">
            <i className="c-icon_blank u-mgr_s" />
            <a
              href={pdfUrl}
              target="_blank"
              className="c-textlink"
              rel="noreferrer"
            >
              {pdfFilename}
            </a>
          </div>
        ) : (
          '（PDFが登録されていません）'
        )}
      </div>
      <h5 className="c-section_subTitle_br u-mgb_m u-fz_default">
        <strong>金額</strong>
      </h5>
      <div className="l-flex_between">
        {/* NOTE: 余白調整空コンテナ */}
        <div className="c-incentivePayment_remarksBox" />
        <div className="c-incentivePayment_totalBox">
          <ul className="u-mgb_s">
            <li className="t-bgBox_org u-mgb_s">
              <dl className="l-flex_between_center">
                <dt className="c-incentivePayment_totalCaption">
                  <strong>総売上額</strong>
                </dt>
                <dd
                  className={`c-incentivePayment_totalItem${
                    canEdit ? '' : ' c-incentivePayment_totalItem_box'
                  }`}
                >
                  {canEdit ? (
                    <NumberInput
                      name="totalSale"
                      validator={validator}
                      unit="円"
                      additionalClassName="u-align_right"
                    />
                  ) : (
                    <strong>
                      {numberFormat(incentivePayment.totalSale)}
                      <small>&nbsp;円</small>
                    </strong>
                  )}
                </dd>
              </dl>
            </li>
            <li className="t-bgBox_org u-mgb_s">
              <dl className="l-flex_between_center">
                <dt className="c-incentivePayment_totalCaption">
                  <strong>総支払額</strong>
                </dt>
                <dd
                  className={`c-incentivePayment_totalItem${
                    canEdit ? '' : ' c-incentivePayment_totalItem_box'
                  }`}
                >
                  {canEdit ? (
                    <NumberInput
                      name="totalPayment"
                      validator={validator}
                      unit="円"
                      additionalClassName="u-align_right"
                    />
                  ) : (
                    <strong>
                      {numberFormat(incentivePayment.totalPayment)}
                      <small>&nbsp;円</small>
                    </strong>
                  )}
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
);

const Contents: React.FC<{
  target: TTarget;
  Header: JSX.Element;
  validator: TValidatorResponse;
  data: TShowPdfData;
}> = React.memo(({ target, Header, validator, data }) => {
  const {
    incentivePayment,
    bankAccount,
    conditions: {
      canEdit,
      messageWhenUneditable,
      canEditBankAccountHasMistake,
    },
  } = data;

  return (
    <>
      <div className="c-frame">
        {Header}
        <Details
          target={target}
          canEdit={canEdit}
          validator={validator}
          data={data}
        />
        <hr className="u-line_plane" />
        <div className="l-flex_between">
          <Memos
            validator={validator}
            isHtml={false}
            canEdit={canEdit}
            publicMemo={incentivePayment.publicMemo}
            privateMemo={incentivePayment.privateMemo}
            hasPaymentCosts={false}
          />
        </div>
      </div>
      <div className="c-frame">
        <BankAccount
          canEditHasMistake={canEditBankAccountHasMistake}
          bankAccount={bankAccount}
        />
      </div>
      {canEdit ? (
        <SubmitButtons />
      ) : (
        <AlertMessages messageWhenUneditable={messageWhenUneditable} />
      )}
    </>
  );
});

const Edit: React.FC<{
  target: TTarget;
  Header: JSX.Element;
  validator: TValidatorResponse;
  data: TShowPdfData;
  submit: TSubmit;
}> = React.memo(({ validator: orgValidator, submit, ...props }) => {
  const { incentivePayment } = props.data;
  const [validator, setValidator] = useState(orgValidator);

  const methods = useForm<TPdfFormInputs>({
    defaultValues: {
      privateMemo: incentivePayment.privateMemo,
      publicMemo: incentivePayment.publicMemo,
      totalSale: incentivePayment.totalSale,
      totalPayment: incentivePayment.totalPayment,
    },
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<TPdfFormInputs> = useCallback(
    async (formData) =>
      await submit(formData, incentivePayment.id, setValidator, 'PDF'),
    [submit, incentivePayment.id]
  );

  return (
    <FormProvider {...methods}>
      <ApiErrors {...validator} />
      <form method="POST" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Contents {...props} validator={validator} />
      </form>
    </FormProvider>
  );
});

const Show: React.FC<{
  target: TTarget;
  Header: JSX.Element;
  validator: TValidatorResponse;
  data: TShowPdfData;
}> = React.memo((props) => <Contents {...props} />);

const PdfDetails: React.FC<{
  target: TTarget;
  Header: JSX.Element;
  validator: TValidatorResponse;
  data: TShowPdfData;
  submit: TSubmit;
}> = React.memo(({ submit, ...props }) =>
  props.data.conditions.canEdit ? (
    <Edit {...props} submit={submit} />
  ) : (
    <Show {...props} />
  )
);

export default PdfDetails;
