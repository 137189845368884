import { useJsonApi } from '@/ts/useJsonApi';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { renderError } from '@/ts/useApi';
import { Header } from './components/Header';
import { UserInfo } from './components/UserInfo';
import { BundleOrder } from './types';
import { OrderInfo } from './components/OrderInfo';
import { OrderTable } from './components/OrderTable';
import { PhotoTable } from '../components/PhotoTable';

export const OrderInfoBundle = React.memo(function OrderInfoBundle() {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<BundleOrder>(
    '/api/order_info/bundle/' + id
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  return (
    <div className="p-groupsSummary c-summary">
      <Link className="c-page_back" to="/order_infos">
        一覧に戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">まとめ発送詳細</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <Header id={id} />
            <UserInfo user={data.user} />
          </div>
          <div className="c-frame">
            {data.orderInfo && (
              <OrderInfo orderInfo={data.orderInfo} printLog={data.printLog} />
            )}
            {data.bundleOrderInfos && (
              <OrderTable bundleOrderInfos={data.bundleOrderInfos} />
            )}
          </div>
          <div className="c-frame">
            <PhotoTable
              photos={data.photos}
              originalPhotos={data.originalPhotos}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
