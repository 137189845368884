import React from 'react';
import { PrintLog as PrintLogEntity } from '../../Show/types';
import { HokuryoPrintLog } from './components/HokuryoPrintLog';
import { JvisPrintLog } from './components/JvisPrintLog';
import { MachineTechPrintLog } from './components/MachineTechPrintLog';

type Props = {
  orderInfoId: number;
  orderDeliveryInfoId: number;
  printLog: PrintLogEntity;
};

export const PrintLog = React.memo<Props>(
  ({ orderInfoId, orderDeliveryInfoId, printLog }) => {
    return (
      <div>
        {printLog.jvis && (
          <JvisPrintLog
            orderInfoId={orderInfoId}
            orderDeliveryInfoId={orderDeliveryInfoId}
            printLog={printLog.jvis}
          />
        )}
        {printLog.hokuryo && (
          <HokuryoPrintLog
            orderInfoId={orderInfoId}
            orderDeliveryInfoId={orderDeliveryInfoId}
            printLog={printLog.hokuryo}
          />
        )}
        {printLog.machineTech && (
          <MachineTechPrintLog
            orderInfoId={orderInfoId}
            orderDeliveryInfoId={orderDeliveryInfoId}
            printLog={printLog.machineTech}
          />
        )}
      </div>
    );
  }
);
