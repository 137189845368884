import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { MeetingPlace } from '@/components/pages/Events/Show/Photography/MeetingPlace';
import { TValidator } from '@/components/shared/Form/types';
import { PhotographingPlace } from '@/components/pages/Events/Show/Photography/PhotographingPlace';
import { BasicInformation } from '@/components/pages/Events/Show/Photography/BasicInformation';
import { PhotographySubject } from '@/components/pages/Events/Show/Photography/PhotographySubject';
import { PhotographyEvent } from '@/components/pages/Events/Show/Photography/PhotographyEvent';
import { PhotographyPhotography } from '@/components/pages/Events/Show/Photography/PhotographyPhotography';
import { DealingWithUnexpectedSituations } from '@/components/pages/Events/Show/Photography/DealingWithUnexpectedSituations';
import { PhotographyCaution } from '@/components/pages/Events/Show/Photography/PhotographyCaution';
import { useEffect } from 'react';
import { CheckList } from './CheckList';

type SaveFormProps = {
  onSubmit: SubmitHandler<TFormInputs>;
  onFormChange: () => void;
  copyToClipboard: SubmitHandler<TFormInputs>;
  defaultValues: TFormInputs;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

const BUTTON_ID_COPY = 'copy';

export const SaveForm: React.FC<SaveFormProps> = ({
  onSubmit,
  onFormChange,
  copyToClipboard,
  defaultValues,
  photography,
  constants,
  validator,
}) => {
  const methods = useForm<TFormInputs>({ defaultValues: defaultValues });
  useEffect(() => {
    const subscription = methods.watch((data, { type }) => {
      if (type === 'change') {
        onFormChange();
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, onFormChange]);
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          if ((e.nativeEvent as SubmitEvent).submitter?.id === BUTTON_ID_COPY) {
            methods.handleSubmit(copyToClipboard)(e);
          } else {
            methods.handleSubmit(onSubmit)(e);
          }
        }}
      >
        <BasicInformation
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <MeetingPlace
          methods={methods}
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <PhotographingPlace
          methods={methods}
          photography={photography}
          validator={validator}
        />
        <PhotographySubject
          methods={methods}
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <PhotographyEvent
          methods={methods}
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <PhotographyPhotography
          methods={methods}
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <DealingWithUnexpectedSituations
          methods={methods}
          photography={photography}
          constants={constants}
          validator={validator}
        />
        <PhotographyCaution validator={validator} />
        <ButtonsFooter>
          <button
            type="submit"
            className="c-btn_large c-btn_secondary"
            id={BUTTON_ID_COPY}
          >
            撮影情報コピー
          </button>
          <button type="submit" className="c-btn_large c-btn_primary">
            保存
          </button>
          {process.env.REACT_APP_FEATURE_PHOTOGRAPHY_CHECKLIST === 'true' && (
            <CheckList photography={photography} />
          )}
        </ButtonsFooter>
      </form>
    </FormProvider>
  );
};
