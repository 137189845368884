import {
  BUS,
  CAR,
  HIGHWAY,
  TRAIN,
} from '@/ts/photographerPayments/payment_transportation_types';
import { fetcher } from '@/ts/fetch';
import { UseFormReturn } from 'react-hook-form';
import { TEditFormInputs } from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';

export const getTransportationPaymentPrice = async (
  paymentTransportationType: number | null,
  paymentTransportationPhotographerPlace: string | null,
  paymentTransportationEventPlace: string | null
): Promise<number> => {
  if (
    paymentTransportationPhotographerPlace === null ||
    paymentTransportationEventPlace === null ||
    paymentTransportationType === null
  ) {
    return 0;
  }

  let price = 0;

  if (paymentTransportationType === CAR) {
    price = await fetcher(
      `/api/photographer_payments/transportation_expenses/gasoline_input_options?fromLocation=${paymentTransportationPhotographerPlace}&toLocation=${paymentTransportationEventPlace}`
    );
  }
  if (paymentTransportationType === HIGHWAY) {
    price = await fetcher(
      `/api/photographer_payments/transportation_expenses/highway_input_options?fromLocation=${paymentTransportationPhotographerPlace}&toLocation=${paymentTransportationEventPlace}`
    );
  }

  if (paymentTransportationType === TRAIN) {
    price = await fetcher(
      `/api/photographer_payments/transportation_expenses/train_input_options?fromLocation=${paymentTransportationPhotographerPlace}&toLocation=${paymentTransportationEventPlace}`
    );
  }
  if (paymentTransportationType === BUS) {
    price = await fetcher(
      `/api/photographer_payments/transportation_expenses/bus_input_options?fromLocation=${paymentTransportationPhotographerPlace}&toLocation=${paymentTransportationEventPlace}`
    );
  }
  return price;
};

export const onBlurChangePriceFromPlace = async (
  methods: UseFormReturn<TEditFormInputs>,
  index: number
): Promise<void> => {
  const paymentTransportationPhotographerPlace = methods.getValues(
    `photographerPayments.${index}.paymentTransportationPhotographerPlace`
  );
  const paymentTransportationEventPlace = methods.getValues(
    `photographerPayments.${index}.paymentTransportationEventPlace`
  );
  const paymentTransportationType = methods.getValues(
    `photographerPayments.${index}.paymentTransportationType`
  );

  const price = await getTransportationPaymentPrice(
    paymentTransportationType,
    paymentTransportationPhotographerPlace,
    paymentTransportationEventPlace
  );

  if (
    price !== 0 &&
    !methods.getValues(`photographerPayments.${index}.paymentPrice`)
  ) {
    methods.setValue(
      `photographerPayments.${index}.paymentPrice`,
      Number(price)
    );
  }
};
