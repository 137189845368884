import {
  Checkboxes,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import {
  ID_UNCONFIRMED,
  TRANSPORTATION_TYPE_BICYCLE,
  TRANSPORTATION_TYPE_CAR,
  TRANSPORTATION_TYPE_MOTORCYCLE,
} from '@/components/pages/Events/Show/Photography/const';
import { LIST_ITEMS, PARENT_LIST_ITEMS } from '../listItem';
import {
  shouldRenderMeetingPlaceBicycleParkingLot,
  shouldRenderMeetingPlaceCarParkingLot,
} from '../Shared/shouldRenderPhotographyContent';

type MeetingPlaceProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const MeetingPlace: React.FC<MeetingPlaceProps> = ({
  methods,
  photography,
  constants,
  validator,
}) => {
  const PRESENT_CHOICES: TChoice[] = [
    { key: ID_UNCONFIRMED, value: '未確認' },
    { key: 1, value: 'あり' },
    { key: 0, value: 'なし' },
  ];

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name === 'meetingPlaceTransportationTypeIds' && type === 'change') {
        if (
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_CAR
          ) &&
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
        ) {
          methods.setValue('hasCarParkingLotAtMeetingPlace', ID_UNCONFIRMED);
          methods.setValue('meetingPlaceCarParkingLotRemark', '');
        }
        if (
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_BICYCLE
          ) &&
          !value.meetingPlaceTransportationTypeIds?.includes(
            TRANSPORTATION_TYPE_MOTORCYCLE
          )
        ) {
          methods.setValue(
            'hasBicycleParkingLotAtMeetingPlace',
            ID_UNCONFIRMED
          );
          methods.setValue('meetingPlaceBicycleParkingLotRemark', '');
        }
      }
      if (
        name === 'hasCarParkingLotAtMeetingPlace' &&
        type === 'change' &&
        value.hasCarParkingLotAtMeetingPlace === ID_UNCONFIRMED
      ) {
        methods.setValue('meetingPlaceCarParkingLotRemark', '');
      }
      if (
        name === 'hasBicycleParkingLotAtMeetingPlace' &&
        type === 'change' &&
        value.hasBicycleParkingLotAtMeetingPlace === ID_UNCONFIRMED
      ) {
        methods.setValue('meetingPlaceBicycleParkingLotRemark', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [methods]);

  return (
    <div className="c-frame">
      <div className="c-section_title" id={PARENT_LIST_ITEMS.meetingPlace.id}>
        {PARENT_LIST_ITEMS.meetingPlace.name}
      </div>
      <hr className="u-line_plane" />
      <PhotographyAccordion
        title={LIST_ITEMS.meetingPlace.meetingPlaceDetail.name}
        id={LIST_ITEMS.meetingPlace.meetingPlaceDetail.id}
      >
        <div>{photography.meetingPlace ?? '園'}</div>
        {photography.meetingPlaceMemo && (
          <>
            <div className="t-bgBox_gray">{photography.meetingPlaceMemo}</div>
            <div className="t-textColor_sub">
              ※申請サマリの登録情報を表示しています。
            </div>
          </>
        )}
      </PhotographyAccordion>
      <PhotographyAccordion
        title={LIST_ITEMS.meetingPlace.meetingPlaceAccess.name}
        id={LIST_ITEMS.meetingPlace.meetingPlaceAccess.id}
      >
        <TextArea
          name="meetingPlaceAccess"
          placeholder="行き方を入力してください"
          rows={3}
          validator={validator}
        />
      </PhotographyAccordion>
      <PhotographyAccordion
        title={LIST_ITEMS.meetingPlace.meetingPlaceTransportationType.name}
        id={LIST_ITEMS.meetingPlace.meetingPlaceTransportationType.id}
      >
        <Checkboxes
          name="meetingPlaceTransportationTypeIds"
          validator={validator}
          choices={constants.photographyTransportationTypes}
        />
      </PhotographyAccordion>
      {shouldRenderMeetingPlaceCarParkingLot(methods.watch()) && (
        <PhotographyAccordion
          title={LIST_ITEMS.meetingPlace.meetingPlaceCarParkingLot.name}
          id={LIST_ITEMS.meetingPlace.meetingPlaceCarParkingLot.id}
        >
          <RadioBoxes
            name="hasCarParkingLotAtMeetingPlace"
            validator={validator}
            choices={PRESENT_CHOICES}
          />
          {methods.watch('hasCarParkingLotAtMeetingPlace') !==
            ID_UNCONFIRMED && (
            <>
              <div className="t-textColor_sub">詳細</div>
              <TextArea
                name="meetingPlaceCarParkingLotRemark"
                placeholder="詳細を入力してください"
                rows={3}
                validator={validator}
              />
            </>
          )}
        </PhotographyAccordion>
      )}
      {shouldRenderMeetingPlaceBicycleParkingLot(methods.watch()) && (
        <PhotographyAccordion
          title={LIST_ITEMS.meetingPlace.meetingPlaceBicycleParkingLot.name}
          id={LIST_ITEMS.meetingPlace.meetingPlaceBicycleParkingLot.id}
        >
          <RadioBoxes
            name="hasBicycleParkingLotAtMeetingPlace"
            validator={validator}
            choices={PRESENT_CHOICES}
          />
          {methods.watch('hasBicycleParkingLotAtMeetingPlace') !==
            ID_UNCONFIRMED && (
            <>
              <div className="t-textColor_sub">詳細</div>
              <TextArea
                name="meetingPlaceBicycleParkingLotRemark"
                placeholder="詳細を入力してください"
                rows={3}
                validator={validator}
              />
            </>
          )}
        </PhotographyAccordion>
      )}
    </div>
  );
};
