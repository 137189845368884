import {
  GeneralError,
  InternalError,
  NotFoundError,
  AuthError,
} from '../components/Error';
import { Not200Error } from '@/ts/fetch';
import { TValidator } from '@/components/shared/Form/types';

export function renderError(error: Error): JSX.Element {
  if (error instanceof Not200Error) {
    if (error.status === 404) {
      return <NotFoundError />;
    } else if (error.status >= 500 && error.status <= 599) {
      return <InternalError />;
    } else if (error.status === 403) {
      return <AuthError />;
    } else {
      return (
        <GeneralError
          code={error.status.toString()}
          message={
            error.jsonMessage.message ||
            '不明なエラーが発生しました。システム担当者にご連絡ください。'
          }
        />
      );
    }
  } else if (error instanceof SyntaxError) {
    // レスポンスがJSONでない場合
    return (
      <GeneralError
        code={''}
        message="サーバーが予期しないレスポンスを返しました。システム担当者にご連絡ください。"
      />
    );
  }
  // fetchがエラーを返すのはネットワークエラーのときに限るので
  return (
    <GeneralError
      code=""
      message="ネットワークエラーが発生しました。ネットワーク接続を確認してください。"
    />
  );
}

export function isValidationError(
  error: unknown
): error is Not200Error<{ validator: TValidator }> {
  return error instanceof Not200Error && error.status === 422;
}

export function toMessages(error: unknown): string[] {
  if (error instanceof Not200Error) {
    return [error.jsonMessage?.message || '不明なエラーが発生しました'];
  } else if (error instanceof SyntaxError) {
    // レスポンスがJSONでない場合
    return [
      'サーバーが予期しないレスポンスを返しました。システム担当者にご連絡ください。',
    ];
  }
  // fetchがエラーを返すのはネットワークエラーのときに限るので
  return [
    'ネットワークエラーが発生しました。ネットワーク接続を確認の上再度お試しください',
  ];
}

export {
  fetcher,
  Not200Error,
  postJson,
  patchJson,
  putJson,
  deleteJson,
} from '@/ts/fetch';
export { useJsonApi } from '@/ts/useJsonApi';
export type { TUseJsonApiResponse } from '@/ts/useJsonApi';
