import { TUseraccount } from '../types';
import { TCertificationkeyUseraccount, TMergedUseraccount } from './types';

const mergeUseraccounts = (
  useraccounts: TUseraccount[],
  certificationkeyUseraccounts: TCertificationkeyUseraccount[],
  isRequiredAcceptanceByLeader: boolean
): TMergedUseraccount[] =>
  useraccounts.map((user) => {
    const keyUser = certificationkeyUseraccounts.find(
      (cu) => cu.id === user.id
    );
    const conditions = {
      canLinkToCertificationkeys: user.conditions.canLinkToCertificationkeys,
      canRemoveFromCertificationkey: keyUser
        ? keyUser.conditions.canDelete
        : false,
    };
    if (!isRequiredAcceptanceByLeader) {
      return {
        ...user,
        certificationkeyLinkedAt: keyUser?.certificationkeyLinkedAt,
        conditions,
      };
    }
    return {
      ...user,
      ...keyUser,
      conditions,
    };
  });

export { mergeUseraccounts };
