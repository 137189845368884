import React, { useRef, useState } from 'react';

import { getCsv } from '@/ts/fetch';
import { makeCsvDownloadUrl } from '@/ts/makeCsvDownloadUrl';

import { errorToast } from '../../../../ts/toast';
import { makePathForSearchForm } from '@/ts/makePathForSearchForm';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';

const Download: React.FC<{
  queryParams: Record<string, unknown>;
}> = React.memo(({ queryParams }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const downloadLink = useRef<HTMLAnchorElement>(null);

  const download = usePreventDuplicateCall(async () => {
    const fileName = '団体一覧.csv';
    const tmpLink = downloadLink.current!;

    setIsDisabled(true);
    try {
      const res = await getCsv<{ data: 'NG' }>(
        makePathForSearchForm(`/api/societies.csv`, '', queryParams)
      );
      if (typeof res === 'object' && res.data === 'NG') {
        errorToast('データが取得できませんでした');
        return;
      }

      tmpLink.href = makeCsvDownloadUrl(res as string);
      tmpLink.download = fileName;
      tmpLink.click();
    } catch (e) {
      errorToast('エラーが発生しました');
    } finally {
      setIsDisabled(false);
    }
  });

  return (
    <>
      {isDisabled ? (
        <button
          type="button"
          className="c-btn_large c-btn_manage u-mgr_m is-disabled"
        >
          <i className="c-icon_download t-textColor_white u-mgr_xs" />
          <span>CSVダウンロード中</span>
        </button>
      ) : (
        <>
          <button
            type="button"
            className="c-btn_large c-btn_manage u-mgr_m"
            onClick={download}
          >
            <i className="c-icon_download t-textColor_white u-mgr_xs" />
            <span>CSVダウンロード</span>
          </button>
        </>
      )}
      <a href="/" download="" className="is-hidden" ref={downloadLink}>
        link
      </a>
    </>
  );
});

const DownloadCsv: React.FC<{
  queryParams: Record<string, unknown>;
  paginatorTotal: undefined | number;
}> = React.memo(({ queryParams, paginatorTotal }) =>
  paginatorTotal && paginatorTotal <= 5000 ? (
    <Download queryParams={queryParams} />
  ) : (
    <span title="検索結果が多すぎるためCSVをダウンロードできません。検索結果5,000件までダウンロード可能です。">
      <button
        type="button"
        className="c-btn_large c-btn_manage u-mgr_m is-disabled"
      >
        <i className="c-icon_download t-textColor_white u-mgr_xs" />
        <span>CSVダウンロード</span>
      </button>
    </span>
  )
);

export default DownloadCsv;
