import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  NumberInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import {
  isValidationError,
  postJson,
  renderError,
  toMessages,
} from '@/ts/useApi';
import { useJsonApi } from '@/ts/useJsonApi';
import React, { FC, useState } from 'react';
import {
  DeepPartial,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { alertApiError } from '@/ts/formValidation';
import { kanriUrl } from '@/ts/url';

type EventRequestPhotographer = {
  eventRequestPhotographerId: number;
  photographerId: number;
  photographerSei: string;
  photographerMei: string;
  photographerOrganizationId: number;
  photographerOrganizationName: string;
  eventId: number;
  eventName: string;
  photographerPaymentId: number | null;
  price: number | null;
  externalMemo: string | null;
  requestFee: number;
};
type EditResponse = {
  data: {
    eventRequestPhotographers: EventRequestPhotographer[];
    consumptionTaxPercent: TChoice[];
    predefinedCompensationPrices: number[];
  };
};

type FormFields = {
  updates: {
    eventRequestPhotographerId: number;
    priceChoice: number;
    price: number;
    consumptionTaxPercent: number;
    externalMemo: string;
  }[];
};

const PRICE_CHOICE_MANUAL = 0;

const Form: FC<{
  index: number;
  validator: TValidator;
  eventRequestPhotographer: EventRequestPhotographer;
  consumptionTaxPercent: TChoice[];
  predefinedCompensationPrices: number[];
}> = ({
  index,
  validator,
  eventRequestPhotographer,
  consumptionTaxPercent,
  predefinedCompensationPrices,
}) => {
  const methods = useFormContext();
  const priceChoices = [
    ...predefinedCompensationPrices.map((price) => ({
      key: price,
      value: `${price}円`,
    })),
    { key: PRICE_CHOICE_MANUAL, value: '手動入力' },
  ];
  return (
    <div className="c-frame">
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">カメラマン</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.photographerId} / ${eventRequestPhotographer.photographerSei} ${eventRequestPhotographer.photographerMei}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">カメラマン組織</div>
        <div className="c-dataValue">
          <div className="l-flex l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.photographerOrganizationId} / ${eventRequestPhotographer.photographerOrganizationName}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">イベント</div>
        <div className="c-dataValue">
          <div className="l-flex_center l-col_wrap">
            <div className="l-col_16">
              <input
                className="c-input_plane is-disabled"
                defaultValue={`${eventRequestPhotographer.eventId} / ${eventRequestPhotographer.eventName}`}
              />
            </div>
            <div className="l-col_8">
              <div className="l-flex u-mgl_s">
                <a
                  className="c-textlink"
                  href={kanriUrl({
                    action_owner_SHOUSAIinput: 't',
                    eventno: eventRequestPhotographer.eventId.toString(),
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  イベント詳細メールを見る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="l-flex c-label_line">
        <div className="c-dataLabel">依頼金額合計（税込）</div>
        <div className="c-dataValue">{eventRequestPhotographer.requestFee}</div>
      </div>
      <div className="l-flex">
        <div className="c-dataLabel">
          中止金額
          <small className="c-required">(必須)</small>
        </div>
        <div className="c-dataValue">
          <div className="l-flex">
            <div className="t-col_16">
              <div className="l-flex">
                <div>
                  <div className="t-textColor_sub">金額</div>
                  <RadioBoxes
                    name={`updates.${index}.priceChoice`}
                    choices={priceChoices}
                    validator={validator}
                  />
                </div>
                <div>
                  <div className="t-textColor_sub">手動入力</div>
                  <NumberInput
                    name={`updates.${index}.price`}
                    validator={validator}
                    isDisabled={
                      methods.watch(`updates.${index}.priceChoice`) !==
                      PRICE_CHOICE_MANUAL
                    }
                  />
                </div>
              </div>
            </div>
            <div className="t-col_8 u-mgl_m">
              <div className="t-textColor_sub">税率</div>
              <RadioBoxes
                name={`updates.${index}.consumptionTaxPercent`}
                choices={consumptionTaxPercent}
                validator={validator}
              />
            </div>
          </div>
          <div className="t-textColor_sub u-mgb_s">
            &lowast;往復交通費を含める場合や、依頼金額の半額支払いまたは全額支払いの場合は「手動入力」を選択して金額を入力してください。
          </div>
          <div className="t-textColor_sub">備考</div>
          <TextArea
            name={`updates.${index}.externalMemo`}
            validator={validator}
          />
          <div className="t-textColor_sub">※明細に表示されます</div>
        </div>
      </div>
    </div>
  );
};

const Content: FC<{ response: EditResponse }> = ({ response }) => {
  const defaultValues: DeepPartial<FormFields> = {
    updates: response.data.eventRequestPhotographers.map(
      (eventRequestPhotographer) => ({
        eventRequestPhotographerId:
          eventRequestPhotographer.eventRequestPhotographerId,
        ...(eventRequestPhotographer.price === null
          ? {
              price: Math.round(eventRequestPhotographer.requestFee / 2),
            }
          : response.data.predefinedCompensationPrices.includes(
              eventRequestPhotographer.price
            )
          ? {
              priceChoice: eventRequestPhotographer.price,
              price: Math.round(eventRequestPhotographer.requestFee / 2),
            }
          : {
              priceChoice: PRICE_CHOICE_MANUAL,
              price: eventRequestPhotographer.price,
            }),
        externalMemo: eventRequestPhotographer.externalMemo || undefined,
        consumptionTaxPercent: response.data.consumptionTaxPercent.find(
          (option) => option.isDisabled !== true
        )?.key as number,
      })
    ),
  };
  const methods = useForm({ defaultValues });
  const history = useHistory();
  const initialValidator: TValidator = { rules: {} }; // TODO: APIから値とってくる
  const [validator, setValidator] = useState<TValidator>(initialValidator);
  const handleSubmit = usePreventDuplicateCall(
    async (formData: DeepPartial<FormFields>) => {
      const convertedData = {
        updates: formData.updates?.map((update) => {
          if (!update) {
            return update;
          }
          if (update?.priceChoice !== PRICE_CHOICE_MANUAL) {
            return {
              ...update,
              price: update?.priceChoice,
            };
          }
          return update;
        }),
      };
      try {
        await postJson(
          '/api/photographer_payments/compensations/bulk_update',
          convertedData
        );
        successToast('中止金を登録しました');
        history.push(`/photographer_payments/compensations`);
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          alertApiError();
        } else {
          setValidator(initialValidator);
          errorToast(`エラーが発生しました: ${toMessages(e)}`);
        }
      }
    }
  );
  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '中止金一覧',
            path: '/photographer_payments/compensations',
          },
          { id: 3, title: '中止金登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">中止金登録</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <FormProvider {...methods}>
          {response.data.eventRequestPhotographers.map(
            (eventRequestPhotographer, index) => (
              <Form
                validator={validator}
                index={index}
                eventRequestPhotographer={eventRequestPhotographer}
                consumptionTaxPercent={response.data.consumptionTaxPercent}
                key={eventRequestPhotographer.eventRequestPhotographerId}
                predefinedCompensationPrices={
                  response.data.predefinedCompensationPrices
                }
              />
            )
          )}
          <ButtonsFooter>
            <Link
              className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
              to="/photographer_payments/compensations"
            >
              キャンセル
            </Link>
            <button className="c-btn_large c-btn_primary c-input_submit">
              登録
            </button>
          </ButtonsFooter>
        </FormProvider>
      </form>
    </div>
  );
};

export const Edit: FC = () => {
  const location = useLocation();
  const { data: response, error } = useJsonApi<EditResponse>(
    `/api/photographer_payments/compensations/edit${location.search}`
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return <Content response={response} />;
};
