import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import QueryString from 'query-string';
import { kanriUrl, ownerUrl } from '../../../../ts/url';
import { currentFiscalYearParam } from '../../../../ts/salesIndexUrl';
import { TAB_DIRECT } from '../../../shared/Certificationkeys/constants';
import { TCreateUpdateInfo, TOrganization, TSociety } from '../../types';

import './style.scss';

type TProps = {
  isPopup: boolean;
  society: TSociety & TCreateUpdateInfo;
  organization: TOrganization;
};
type TLinksProps = TProps & {
  isPopup: false;
};
type TPopupProps = TProps & {
  isPopup: true;
  society: TSociety & TCreateUpdateInfo;
  organization: TOrganization;
  onClickClose: MouseEventHandler;
  isOpen: boolean;
};

const SubMenuLinks: React.FC<TProps> = React.memo(
  ({ isPopup, society, organization }) => {
    const componentClassName = isPopup
      ? 'c-popup_submenuList'
      : 'c-submenu_linkList';
    const itemClassName = `${componentClassName}_item`;
    const tehaiListfromDay = dayjs().subtract(1, 'year').format('YYYY-MM-DD');

    return (
      <ul className={componentClassName}>
        <li className={itemClassName}>
          <a
            href={kanriUrl({
              action_owner_SHINSEItehailist2: 'true',
              photographingday_from: dayjs().format('YYYY-M-D'),
              society_id: society.id.toString(),
              society_name: organization.name,
            })}
            className="c-textlink"
          >
            手配リスト
          </a>
        </li>
        <li className={itemClassName}>
          <a
            href={kanriUrl({
              action_owner_SHINSEItehailist2: 'true',
              photographingday_from: tehaiListfromDay,
              photographingday_to: dayjs().format('YYYY-M-D'),
              society_id: society.id.toString(),
              society_name: organization.name,
              sortbydesc: '1',
            })}
            className="c-textlink"
          >
            過去分手配リスト
          </a>
        </li>
        <li className={itemClassName}>
          <a
            href={ownerUrl('events/requests', {
              society_id: society.id.toString(),
            })}
            className="c-textlink"
          >
            申請リスト
          </a>
        </li>
        <li className={itemClassName}>
          <Link
            className="c-textlink"
            to={`/events?societyIds[]=${society.id}`}
          >
            イベント一覧
          </Link>
        </li>
        <li className={itemClassName}>
          <Link
            to={`/sales?${QueryString.stringify({
              societyId: society.id,
              ...currentFiscalYearParam(),
            })}`}
            className="c-textlink"
          >
            売れ行き（今年度分）
          </Link>
        </li>
        <li className={itemClassName}>
          <Link
            to={`/certificationkeys/?${QueryString.stringify({
              societyId: society.id,
              type: TAB_DIRECT,
            })}`}
            className="c-textlink"
          >
            写真閲覧キー一覧
          </Link>
        </li>
        <li className={itemClassName}>
          <Link
            to={`/societies/${society.id}/sales_forecasts`}
            className="c-textlink"
          >
            売上予測
          </Link>
        </li>
      </ul>
    );
  }
);

const SubMenu: React.FC<TLinksProps | TPopupProps> = React.memo((props) => {
  // links
  if (!props.isPopup) {
    return (
      <div className="c-submenu_sub u-spOff">
        <SubMenuLinks {...props} />
      </div>
    );
  }

  // popup
  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="c-popup_details c-popup_submenuWrap">
      <SubMenuLinks {...props} />
      <p className="c-popup_closeBtn" onClick={props.onClickClose}>
        <i className="c-icon_small c-icon_close"></i>閉じる
      </p>
    </div>
  );
});

export default SubMenu;
