import React from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { NumberInput, NumberLooseInput } from '@/components/shared/Form/Inputs';
import { convertValidatorForArrayForm } from '@/components/shared/Form/validation';
import { dateFormat, numberFormat } from '../../../../ts/formatTools';
import Help from '../../../shared/Help/App';
import { calcTotalPaymentSales, calcTotalSaleSales } from './calc';
import {
  TGroupedIncentivePaymentEvent,
  THtmlFormInputs,
  TIncentivePaymentEvent,
} from './types';

import GroupHeader from './GroupHeader';

const TableHeader: React.FC = React.memo(() => (
  <>
    <th className="c-incentivePayment_table_id">
      <div className="c-incentivePayment_tableCaption">イベントID</div>
    </th>
    <th className="c-incentivePayment_table_eventName">
      <div className="c-incentivePayment_tableCaption u-align_left">
        イベント名
      </div>
    </th>
    <th className="c-incentivePayment_table_shootingDay">
      <div className="c-incentivePayment_tableCaption">撮影日</div>
    </th>
    <th className="c-incentivePayment_table_incentive">
      <div className="c-incentivePayment_tableCaption">販売手数料</div>
    </th>
    <th className="c-incentivePayment_table_sales">
      <div className="c-incentivePayment_tableCaption">売上額</div>
    </th>
  </>
));
const TableTotalSaleHeader: React.FC = React.memo(() => (
  <>
    <td></td>
    <td></td>
    <td></td>
    <td className="u-align_right">
      <strong>売上額小計</strong>
    </td>
  </>
));
const TableTotalPaymentHeader: React.FC = React.memo(() => (
  <>
    <td></td>
    <td></td>
    <td></td>
    <td className="c-incentivePayment_unitTotalBox u-align_right">
      <div className="c-incentivePayment_unitTotal l-relative">
        <span className="c-tooltip u-mgr_xs">
          <i className="c-icon-help"></i>
        </span>
        <strong>支払額小計</strong>
        <Help>売上額小計 - 販売手数料</Help>
      </div>
    </td>
  </>
));

const IncentivePaymentEventEdit: React.FC<{
  validator: TValidatorResponse;
  index: number;
  control: Control<THtmlFormInputs>;
  incentivePaymentEvent: TIncentivePaymentEvent;
}> = React.memo(
  ({
    validator: orgValidator,
    index,
    control,
    incentivePaymentEvent: {
      eventId,
      eventName,
      photographingDay,
      totalSale,
      isUsed,
    },
  }) => {
    const validator = convertValidatorForArrayForm(
      'incentivePaymentEvents',
      index,
      orgValidator
    );

    return (
      <tr key={eventId} className={isUsed ? '' : 'is-notTarget'}>
        <td>{eventId}</td>
        <td>{eventName}</td>
        <td className="u-align_center">{dateFormat(photographingDay)}</td>
        <td>
          <NumberLooseInput
            name={`incentivePaymentEvents.${index}.incentiveRate`}
            unit="%"
            step={0.01}
            validator={validator}
            additionalClassName="u-align_right"
          />
        </td>
        <td className="u-align_right">{numberFormat(totalSale)} 円</td>
        <td>
          <NumberInput
            name={`incentivePaymentEvents.${index}.id`}
            validator={validator}
            additionalClassName="is-hidden"
          />
          <label className="c-switch_label c-switch_label__small">
            <Controller
              name={`incentivePaymentEvents.${index}.isUsed`}
              control={control}
              render={({ field: { onChange, value: currentVal, name } }) => (
                <input
                  name={name}
                  className="c-switch_input"
                  type="checkbox"
                  onChange={(e) => onChange(e.target.checked ? 1 : 0)}
                  checked={!!currentVal}
                />
              )}
            />
            <span className="c-switch_content"></span>
            <span className="c-switch_circle"></span>
          </label>
        </td>
      </tr>
    );
  }
);

const IncentivePaymentEventsEdit: React.FC<{
  taxRate: number | null;
  validator: TValidatorResponse;
  incentivePaymentEvents: TIncentivePaymentEvent[];
}> = React.memo(({ taxRate, validator, incentivePaymentEvents }) => {
  const { control, watch } = useFormContext<THtmlFormInputs>();

  const watchFieldArray = watch('incentivePaymentEvents');
  const controlledFields = incentivePaymentEvents.map((paymentEvent) => {
    const index = watchFieldArray.findIndex((f) => f.id === paymentEvent.id);
    return {
      index,
      ...paymentEvent,
      ...watchFieldArray[index],
    };
  });

  // sum total
  const totalPayment = calcTotalPaymentSales(taxRate, controlledFields);
  const totalSale = calcTotalSaleSales(taxRate, controlledFields);

  return (
    <table className="c-incentivePayment_table is-edit">
      <thead>
        <tr>
          <TableHeader />
          <th className="c-incentivePayment_table_target">
            <div className="c-incentivePayment_tableCaption">対象</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {controlledFields.map((fields) => (
          <IncentivePaymentEventEdit
            key={fields.id}
            index={fields.index}
            control={control}
            validator={validator}
            incentivePaymentEvent={fields}
          />
        ))}
        <tr>
          <TableTotalSaleHeader />
          <td className="u-align_right">{numberFormat(totalSale)} 円</td>
          <td />
        </tr>
        <tr>
          <TableTotalPaymentHeader />
          <td className="c-incentivePayment_unitTotalBox u-align_right">
            <div className="c-incentivePayment_unitTotal">
              <strong>{numberFormat(totalPayment)} 円</strong>
            </div>
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  );
});

const IncentivePaymentEventsShow: React.FC<{
  totalSale: number;
  totalPayment: number;
  incentivePaymentEvents: TIncentivePaymentEvent[];
}> = React.memo(({ totalSale, totalPayment, incentivePaymentEvents }) => (
  <table className="c-incentivePayment_table">
    <thead>
      <tr>
        <TableHeader />
      </tr>
    </thead>
    <tbody>
      {incentivePaymentEvents.map(
        ({
          eventId,
          eventName,
          photographingDay,
          incentiveRate,
          totalSale: eventTotalSale,
          isUsed,
        }) => (
          <tr key={eventId} className={isUsed ? '' : 'is-notTarget'}>
            <td>{eventId}</td>
            <td>{eventName}</td>
            <td className="u-align_center">{dateFormat(photographingDay)}</td>
            <td className="u-align_right">
              {numberFormat(incentiveRate, 2)} %
            </td>
            <td className="u-align_right">{numberFormat(eventTotalSale)} 円</td>
          </tr>
        )
      )}
      <tr>
        <TableTotalSaleHeader />
        <td className="u-align_right">{numberFormat(totalSale)} 円</td>
      </tr>
      <tr>
        <TableTotalPaymentHeader />
        <td className="c-incentivePayment_unitTotalBox u-align_right">
          <div className="c-incentivePayment_unitTotal">
            <strong>{numberFormat(totalPayment)} 円</strong>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
));

const GroupedIncentivePaymentEvent: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  groupedIncentivePaymentEvent: TGroupedIncentivePaymentEvent;
}> = React.memo(
  ({
    canEdit,
    validator,
    groupedIncentivePaymentEvent: {
      taxRate,
      incentivePaymentEvents,
      totalPayment,
      totalSale,
      isBulkDelivery,
      groupName,
      planName,
    },
  }) => {
    return (
      <>
        <GroupHeader
          isBulkDelivery={isBulkDelivery}
          groupName={groupName}
          planName={planName}
        />
        <div className="l-relative u-mgb_m">
          {canEdit ? (
            <IncentivePaymentEventsEdit
              validator={validator}
              taxRate={taxRate}
              incentivePaymentEvents={incentivePaymentEvents}
            />
          ) : (
            <IncentivePaymentEventsShow
              totalPayment={totalPayment}
              totalSale={totalSale}
              incentivePaymentEvents={incentivePaymentEvents}
            />
          )}
        </div>
      </>
    );
  }
);

export default GroupedIncentivePaymentEvent;
