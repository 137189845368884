// 公開設定
export const OPENFLAG_CLOSE = 0;
export const OPENFLAG_OPEN = 1;
export const OPENFLAG_JIZENSTART = 2;
export const OPENFLAG_LEADEROPEN = 3;
export const OPENFLAG_JIZENCOMP = 4;
export const OPENFLAG_NO_PUBLISH = 5;

// 申請オプション
export const EVENTOPTION_LEADEROPEN = '11';
export const EVENTOPTION_TEACHERTAKE = '12';

// 掲載日固定フラグ
export const DISABLED_PUBLISHING_PRRIOD = 0;
export const ENABLED_PUBLISHING_PERIOD = 1;

// 団体責任者の公開終了年
export const PUBLISHPERIODYEAR = 6;
