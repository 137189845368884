import { ChangeEventHandler } from 'react';
import { TPhotographerShort, TRequestPhotographerShort } from './types';
import { TCheck } from '../../../types';

const Photographers: React.FC<{
  photographers: (TPhotographerShort & Partial<TRequestPhotographerShort>)[];
  checks?: TCheck[];
  onChangeCheck?: ChangeEventHandler<HTMLInputElement>;
}> = ({ photographers, checks, onChangeCheck }) => (
  <>
    {/* TODO: 状態で色分けとか？？？ */}
    {photographers.map((p, index) => {
      const check = checks
        ?.filter((c) => !c.disabled)
        .find((c) => c.id === p.eventRequestPhotographerId);
      const photographer = (
        <>
          {!!p.isTrainee && (
            <i
              title="研修"
              className="c-icon c-icon__xxsmall c-icon_bubble u-mgr_xs"
            />
          )}
          {p.isEmployee ? '' : '(社)'}
          {p.shortName}
        </>
      );
      const photographerContainerClass =
        'c-arrangePhotographers_photographer' +
        (p.isDoingArrange
          ? ' c-arrangePhotographers_photographer__doingArrange'
          : '');

      return check !== undefined ? (
        <label
          key={index}
          className="c-checkboxLabel"
          title={`${p.id}: ${p.shortName} (${p.rankName})`}
        >
          <input
            type="checkbox"
            className="c-checkbox"
            name="checks[]"
            value={check.id}
            checked={check.checked}
            onChange={onChangeCheck}
          />
          <span
            className={`${photographerContainerClass} c-arrangePhotographers_photographer__checkbox`}
          >
            {photographer}
          </span>
        </label>
      ) : (
        <span
          key={index}
          className={photographerContainerClass}
          title={`${p.id}: ${p.shortName} (${p.rankName})`}
        >
          {photographer}
        </span>
      );
    })}
  </>
);

export default Photographers;
