import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

type Props = {
  variant?: 'default' | 'no-frame';
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
};

export const SpitContentPanel = React.memo<Props>(function SpitContentPanel({
  variant = 'default',
  leftContent,
  rightContent,
}) {
  const [panel, setPanel] = useState<'panel' | 'noFramePanel'>('panel');

  useEffect(() => {
    if (variant === 'no-frame') {
      setPanel('noFramePanel');
    } else {
      setPanel('panel');
    }
  }, [variant]);

  return (
    <div className={styles.container}>
      <div className={styles[panel]}>{leftContent}</div>
      <div className={styles[panel]}>{rightContent}</div>
    </div>
  );
});
