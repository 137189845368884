import { TRequestPhotographerShort } from './types';

const STATUS_DECIDED = 0;
const STATUS_UNDECIDED = 1;
const STATUS_DECLINED = 9;

const isDecided = (status: number): boolean => status === STATUS_DECIDED;
const isUndecided = (status: number): boolean => status === STATUS_UNDECIDED;
const isDeclined = (status: number): boolean => status === STATUS_DECLINED;

/**
 * アサイン済み人数を返す（研修は含まない）
 */
const numberOfArranged = (photographers: TRequestPhotographerShort[]): number =>
  photographers.filter((p) => isDecided(p.requestStatus) && !p.isTrainee)
    .length;

const sortDecidedPhotographers = <T extends TRequestPhotographerShort>(
  photographers: T[]
): T[] =>
  photographers.sort((a, b) =>
    a.isTrainee === b.isTrainee
      ? a.id > b.id
        ? 1
        : -1
      : a.isTrainee > b.isTrainee
      ? 1
      : -1
  );

export {
  isDecided,
  isUndecided,
  isDeclined,
  numberOfArranged,
  sortDecidedPhotographers,
};
