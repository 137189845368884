import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import './style.scss';
import {
  Controller,
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  TFormInputs,
  TFormItems,
  TIndexResponse,
  TOrderInfos,
  TPointOrderInfos,
} from './types';
import { TValidator, TValidatorResponse } from '@/components/shared/Form/types';
import {
  ApiErrors,
  FormError,
  getApiErrorMessages,
  getError,
} from '@/components/shared/Form/Errors';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import Paginator from '@/components/shared/Paginator/App';
import {
  Checkboxes,
  DateInput,
  NumberInput,
  NumbersInput,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { useJsonApiForSearchForm } from '@/ts/useJsonApiForSearchForm';
import { transformBoolToNumber } from '@/ts/transformBoolToNumber';
import { renderError } from '@/ts/useApi';
import { dateFormat, dateTimeFormat } from '@/ts/formatTools';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};

type TTextsProps = {
  name: string;
  validator: TValidator;
  placeholder?: string;
};
const getHasError = (
  name: string,
  validator: TValidator,
  errors: FieldErrors
) =>
  !!getError(errors, name) ||
  (validator.hasError && !!getApiErrorMessages(validator.messages, name));

const TextsInput: React.FC<TTextsProps> = React.memo(
  ({ name, placeholder, validator }) => {
    const {
      control,
      getValues,
      formState: { errors },
    } = useFormContext();
    const rules = {
      validate: (a: unknown) => {
        return a === 'VALIDATION_FAILED'
          ? 'スペース区切りの数字で入力してください'
          : true;
      },
    };

    const className = getHasError(name, validator, errors)
      ? 'c-input_plane is-error'
      : 'c-input_plane';
    // 配列でデフォルト値を受け取る
    const formInputValue = getValues()[name];

    const defaultVal = Array.isArray(formInputValue)
      ? formInputValue.join(' ')
      : formInputValue;
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, ref } }) => (
            <input
              placeholder={placeholder}
              className={className}
              type="text"
              name={name}
              defaultValue={defaultVal}
              onChange={(e) =>
                onChange(
                  e.target.value
                    ? e.target.value.match(/^.((.|\s)*)+$/)
                      ? e.target.value.split(/\s+/)
                      : 'VALIDATION_FAILED'
                    : []
                )
              }
              ref={ref}
            />
          )}
        />
        <FormError name={name} errors={errors} validator={validator} />
      </>
    );
  }
);

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;

  const { data: response, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/order_info/',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(
        '/order_infos/',
        queryString,
        removeEmpty(transformBoolToNumber(formData))
      );
      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }

  const { orderInfos, pagination, formItems, validator } = response;

  const queryParams: TFormInputs = convertQueryToFormData(
    queryString,
    validator
  );

  return (
    <div>
      <h1 className="l-flex_center u-mgb_m">
        <span className="c-page_title">注文一覧</span>
      </h1>
      <SearchForm
        formItems={formItems}
        queryParams={queryParams}
        onSubmit={onSubmit}
        validator={validator}
      />
      {orderInfos.length > 0 && (
        <>
          <div className="c-pagination_upper u-mgr_s">
            <Paginator
              pagination={pagination}
              currentPath="/order_infos"
              queryString={queryString}
            />
          </div>
          <div>
            <ResultList orderInfo={orderInfos} />
          </div>
        </>
      )}
    </div>
  );
};

const ResultList: React.FC<{
  orderInfo: TOrderInfos[] | TPointOrderInfos[];
}> = ({ orderInfo }) => (
  <>
    <div className="p-orderInfoIndex_scrollbar">
      {/* グリッドヘッダ */}
      <div className="grid_header p-orderInfoIndex_grid_other_department_container_wrap">
        <div className="p-orderInfoIndex_grid_container_header">
          <div className="p-orderInfoIndex_grid_row">
            <div className="p-orderInfoIndex_grid_caption">注文ID</div>
            <div className="p-orderInfoIndex_grid_caption">
              まとめ
              <br />
              発送番号
            </div>
            <div className="p-orderInfoIndex_grid_caption">
              まとめ
              <br />
              期限日
            </div>
            <div className="p-orderInfoIndex_grid_caption">購入商品</div>
            <div className="p-orderInfoIndex_grid_caption">会員ID</div>
            <div className="p-orderInfoIndex_grid_caption">会員名</div>
            <div className="p-orderInfoIndex_grid_caption">クラス</div>
            <div className="p-orderInfoIndex_grid_caption">注文日時</div>
            <div className="p-orderInfoIndex_grid_caption">入金日時</div>
            <div className="p-orderInfoIndex_grid_caption">支払状況</div>
            <div className="p-orderInfoIndex_grid_caption">支払い方法</div>
            <div className="p-orderInfoIndex_grid_caption">
              コンビニ支払期限
            </div>
            <div className="p-orderInfoIndex_grid_caption">
              コンビニ支払い番号
            </div>
            <div className="p-orderInfoIndex_grid_caption">住所</div>
            <div className="p-orderInfoIndex_grid_caption">電話番号</div>
            <div className="p-orderInfoIndex_grid_caption">メールアドレス</div>
            <div className="p-orderInfoIndex_grid_caption">合計金額</div>
            <div className="p-orderInfoIndex_grid_caption">
              現金
              <br />
              支払い金額
            </div>
            <div className="p-orderInfoIndex_grid_caption">
              ﾌｫﾄﾏﾈｰ
              <br />
              使用金額
            </div>
            <div className="p-orderInfoIndex_grid_caption">
              ｸｰﾎﾟﾝ
              <br />
              使用金額
            </div>
          </div>
        </div>
      </div>
      {/* 詳細情報 */}
      {orderInfo.map((e: TOrderInfos | TPointOrderInfos, key: number) => (
        <div
          className="p-orderInfoIndex_grid_other_department_container"
          key={key}
        >
          {'orderinfono' in e ? (
            <SearchResultItem orderInfo={e} />
          ) : (
            <SearchResultItemPhotoMoney orderInfo={e} />
          )}
        </div>
      ))}
    </div>
  </>
);

const SearchResultItem: React.FC<{ orderInfo: TOrderInfos }> = ({
  orderInfo,
}) => (
  <>
    <div className="p-orderInfoIndex_grid_row">
      <div className="p-orderInfoIndex_grid_value">
        <Link to={'/order_infos/' + orderInfo.orderinfono}>
          {orderInfo.orderinfono}
        </Link>
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.orderdeliveryinfoBundleId && (
          <Link
            to={'/order_infos/bundle/' + orderInfo.orderdeliveryinfoBundleId}
          >
            {orderInfo.orderdeliveryinfoBundleId}
          </Link>
        )}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {dateFormat(orderInfo.bundleLimitDay)}
      </div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.photosizes}</div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.useraccountno}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.firstname} {orderInfo.secondname}
      </div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.classname}</div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.orderInfoCreatedday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.paymentday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.paymentstatusmastername}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.paymenttypemastername}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.paymentlimitday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.conveniencepaymentnumtyped1}
        <br />
        {orderInfo.conveniencepaymentnumtyped2}
        <br />
        {orderInfo.conveniencepaymentnumtyped3}
        <br />
        {orderInfo.conveniencepaymentnumtyped4}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.postcode} {orderInfo.prefecture} {orderInfo.city}{' '}
        {orderInfo.address}
      </div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.telephone}</div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.mailaddress}</div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.orderTotalPayment}円
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.totalPayment}円
      </div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.usepoint}円</div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.couponDiscount}円
      </div>
    </div>
  </>
);

const SearchResultItemPhotoMoney: React.FC<{ orderInfo: TPointOrderInfos }> = ({
  orderInfo,
}) => (
  <>
    <div className="p-orderInfoIndex_grid_row">
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.pointorderinfono}
      </div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value">フォトマネー</div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.useraccountno}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.firstname} {orderInfo.secondname}
      </div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.createdday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.paymentday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.paymentstatusmastername}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.paymenttypemastername}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {dateTimeFormat(orderInfo.paymentlimitday)}
      </div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.conveniencepaymentnumtyped1}
        <br />
        {orderInfo.conveniencepaymentnumtyped2}
        <br />
        {orderInfo.conveniencepaymentnumtyped3}
        <br />
        {orderInfo.conveniencepaymentnumtyped4}
      </div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value">{orderInfo.telephone}</div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value">
        {orderInfo.pointprice}円
      </div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value"></div>
      <div className="p-orderInfoIndex_grid_value"></div>
    </div>
  </>
);

export const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({
      defaultValues: {
        ...queryParams,
      },
    });
    const { handleSubmit } = methods;
    const [isSearchOpen, setIsSearchOpen] = useState(true);

    useEffect(() => {
      Object.values(queryParams).forEach((value) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            setIsSearchOpen(true);
          } else if (!Array.isArray(value)) {
            setIsSearchOpen(true);
          }
        }
      });
    }, [queryParams]);

    return (
      <div className="p-orderInfoIndex_searchForm">
        <div className="c-searchForm">
          <div className="c-frame">
            <FormProvider {...methods}>
              <ApiErrors {...validator} />
              <form
                method="GET"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div>
                  <div className="l-flex_between_center">
                    <b>検索条件</b>
                  </div>
                  <div>
                    <div className="c-searchForm_orderInfo">
                      <ul className="l-flex">
                        <li className="p-searchForm_useraccount">
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>会員ID</label>
                            </li>
                            <li className="c-dataValue">
                              <NumberInput
                                name="useraccountno"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>会員名</label>
                            </li>
                            <li className="c-dataValue">
                              <TextInput
                                name="useraccountname"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="p-searchForm_society">
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>団体ID</label>
                            </li>
                            <li className="c-dataValue">
                              <NumberInput
                                name="society_id"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>団体名</label>
                            </li>
                            <li className="c-dataValue">
                              <TextInput
                                name="society_name"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                        <li className="p-searchForm_event">
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>イベントID</label>
                            </li>
                            <li className="c-dataValue">
                              <NumberInput
                                name="eventno"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>イベント名</label>
                            </li>
                            <li className="c-dataValue">
                              <TextInput
                                name="eventname"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex">
                        <li className="p-searchForm_createdday">
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>注文日</label>
                            </li>
                            <li className="c-dataValue">
                              <ul className="c-input_dateBlock">
                                <li>
                                  <DateInput
                                    name="orderinfo_createdday_start"
                                    placeholder="yyyy/mm/dd"
                                    validator={validator}
                                  />
                                </li>
                                <li>
                                  <DateInput
                                    name="orderinfo_createdday_end"
                                    placeholder="yyyy/mm/dd"
                                    validator={validator}
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="p-searchForm_paymentday">
                          <ul className="l-flex">
                            <li className="p-orderInfo_dataLabel">
                              <label>入金日</label>
                            </li>
                            <li className="c-dataValue">
                              <ul className="c-input_dateBlock">
                                <li>
                                  <DateInput
                                    name="paymentday_start"
                                    placeholder="yyyy/mm/dd"
                                    validator={validator}
                                  />
                                </li>
                                <li>
                                  <DateInput
                                    name="paymentday_end"
                                    placeholder="yyyy/mm/dd"
                                    validator={validator}
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="p-btn_toggle">
                      <div
                        className="c-btn_toggleDetails"
                        onClick={() => {
                          setIsSearchOpen((b) => !b);
                        }}
                      >
                        <span className="u-spOff">
                          {isSearchOpen ? '詳細検索' : '閉じる'}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`c-accordion_content ${
                        isSearchOpen ? '' : 'is-open'
                      }`}
                    >
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>注文ID</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <NumbersInput
                                name="orderinfo_ids"
                                placeholder="(複数の場合、スペース区切りで入力)"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>写真ID</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <NumbersInput
                                name="photographnos"
                                placeholder="(複数の場合、スペース区切りで入力)"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>コンビニ支払い番号</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <TextsInput
                                name="convenience_payment_num"
                                placeholder=""
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>購入商品</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <Checkboxes
                                name="photosize"
                                choices={formItems.orderPhotoType}
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>購入商品</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <Checkboxes
                                name="order_types"
                                choices={formItems.orderType}
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>発送方法</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <Checkboxes
                                name="shipping_type"
                                choices={formItems.deliveryType}
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>まとめ発送番号</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <NumbersInput
                                name="bundle_ids"
                                placeholder="(複数の場合、スペース区切りで入力)"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>メールアドレス</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <TextsInput
                                name="mailaddress"
                                placeholder="(複数の場合、スペース区切りで入力)"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="l-flex_between">
                        <li className="c-dataLabel">
                          <label>電話番号</label>
                        </li>
                        <li className="c-dataValue">
                          <ul className="l-flex_between">
                            <li className="c-label_innerHalf">
                              <TextsInput
                                name="telephones"
                                placeholder="(複数の場合、スペース区切りで入力)"
                                validator={validator}
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                  <input
                    className="c-btn_large c-btn_Search c-input_submit"
                    type="submit"
                    value="この内容で検索"
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    );
  }
);

export default App;
