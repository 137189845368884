import { UseFormReturn } from 'react-hook-form';
import {
  EventRequestPhotographer,
  TEditFormInputs,
} from '@/components/pages/PhotographerPayments/TransportationExpenses/IndexPage/types';
import { useEffect } from 'react';
import {
  BUS,
  CAR,
  HIGHWAY,
  TRAIN,
} from '@/ts/photographerPayments/payment_transportation_types';

export const useInputPlaceFromTransportationType = (
  methods: UseFormReturn<TEditFormInputs>,
  eventRequestPhotographer: EventRequestPhotographer
): void => {
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (
        name === undefined ||
        type !== 'change' ||
        !name.includes('paymentTransportationType')
      ) {
        return;
      }
      const index = Number(name.split('.')[1]);

      const paymentTransportationType = methods.getValues(
        `photographerPayments.${index}.paymentTransportationType`
      );

      if (
        paymentTransportationType === CAR ||
        paymentTransportationType === HIGHWAY
      ) {
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationPhotographerPlace`,
          eventRequestPhotographer.photographerAddress
        );
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationEventPlace`,
          eventRequestPhotographer.eventAddress
        );
      }

      if (paymentTransportationType === BUS) {
        // バスだけ団体の最寄駅から団体のバス停までの金額を入力するため
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationPhotographerPlace`,
          eventRequestPhotographer.eventStation
        );
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationEventPlace`,
          eventRequestPhotographer.societyNearestBusStop
        );
      }

      if (paymentTransportationType === TRAIN) {
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationPhotographerPlace`,
          eventRequestPhotographer.photographerStation
        );
        methods.setValue(
          `photographerPayments.${index}.paymentTransportationEventPlace`,
          eventRequestPhotographer.eventStation
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, eventRequestPhotographer]);
};
