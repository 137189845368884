import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';

import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';
import EventHeader from '../EventHeader';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import FormVariations from './FormVariations';
import './app.scss';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { society_contract_id, plan_id } =
    useParams<{ society_contract_id: string; plan_id: string }>();

  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/society_contracts/' +
      society_contract_id +
      '/plans/' +
      plan_id +
      '/events/create' +
      useLocation().search
  );
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  const onSubmit: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
    async (formData) => {
      try {
        const saveResponse = (await fetcher('/api/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        })) as TSaveResponse;

        setErrorMessages([]);

        if (saveResponse.validator.hasError) {
          setValidator(saveResponse.validator);
          alertApiError();
          return;
        }
        successToast('登録しました');
        history.push(`/events/${saveResponse.data.eventno}`);
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('エラーが発生しました');
      }
    }
  );

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const formItems = data.formItems;

  const { editable, societyInfo } = data.data;

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">イベント - 新規登録</span>
      </h3>
      <EventHeader
        society={{
          id: societyInfo.society.id,
          name: societyInfo.society.name,
        }}
      />
      <div className="l-center_wrap">
        <FormVariations
          societyContractId={society_contract_id}
          planId={plan_id}
          validator={validator}
          formItems={formItems}
          editable={editable}
          societyInfo={societyInfo}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
        />
      </div>
    </div>
  );
};

export default App;
