import { DeepPartial } from 'react-hook-form';
import { TAdditionalFee, FormInputs, DataResponse } from '../../type';
import { CONSUMPTION_TAX_PERCENT, PRICE_CHOICE } from '../../../constants';

export const createDefaultValues = (
  eventRequestPhotographers: DataResponse[]
): DeepPartial<FormInputs> => {
  const defaultAdditionalFee = {
    id: undefined as number | undefined | null,
    rowNumber: undefined,
    price: 0,
    externalMemo: undefined as string | undefined | null,
    consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
  };

  const createAdditionalFeesArray = (
    additionalFees: TAdditionalFee[] | null
  ) => {
    let additionalFeesArray = Array.from({ length: 20 }, (_, index) => ({
      ...defaultAdditionalFee,
      rowNumber: index,
    }));

    if (additionalFees && additionalFees.length > 0) {
      additionalFeesArray = additionalFeesArray.map((item, index) =>
        additionalFees[index]
          ? { ...item, ...additionalFees[index], rowNumber: index }
          : item
      );
    }
    return additionalFeesArray;
  };

  return {
    eventRequestPhotographers: eventRequestPhotographers.map(
      (eventRequestPhotographer) => ({
        id: eventRequestPhotographer.id,
        specialFee: eventRequestPhotographer.specialFee
          ? {
              price: eventRequestPhotographer.specialFee.price,
              externalMemo: eventRequestPhotographer.specialFee.externalMemo,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              price: 0,
              externalMemo: undefined,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        rankFee: eventRequestPhotographer.rankFee
          ? {
              price: eventRequestPhotographer.rankFee.price,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              price: 0,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        baseFee: eventRequestPhotographer.baseFee
          ? {
              price: eventRequestPhotographer.baseFee.price,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              price: 0,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        busyDayFee: eventRequestPhotographer.busyDayFee
          ? {
              price: eventRequestPhotographer.busyDayFee.price,
              externalMemo: eventRequestPhotographer.busyDayFee.externalMemo,
              isAutoCalculated: eventRequestPhotographer.busyDayFee
                .isAutoCalculated
                ? 1
                : 0,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              price: 0,
              externalMemo: undefined,
              isAutoCalculated: PRICE_CHOICE.AUTO,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        overTimeFee: eventRequestPhotographer.overTimeFee
          ? {
              priceType: eventRequestPhotographer.overTimeFee.priceType,
              externalMemo: eventRequestPhotographer.overTimeFee.externalMemo,
              isAutoCalculated: eventRequestPhotographer.overTimeFee
                .isAutoCalculated
                ? 1
                : 0,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              priceType: 1,
              externalMemo: undefined,
              isAutoCalculated: PRICE_CHOICE.AUTO,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        equipmentFee: eventRequestPhotographer.equipmentFee
          ? {
              stairsStandQuantity:
                eventRequestPhotographer.equipmentFee.stairsStandQuantity,
              backPaperStandQuantity:
                eventRequestPhotographer.equipmentFee.backPaperStandQuantity,
              monoblockQuantity:
                eventRequestPhotographer.equipmentFee.monoblockQuantity,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            }
          : {
              stairsStandQuantity: 0,
              backPaperStandQuantity: 0,
              monoblockQuantity: 0,
              consumptionTaxPercent: CONSUMPTION_TAX_PERCENT,
            },
        additionalFees: createAdditionalFeesArray(
          eventRequestPhotographer.additionalFees
        ),
      })
    ),
  };
};
