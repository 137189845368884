import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  bundleId: number;
};

export const BundleLink = React.memo<Props>(({ bundleId }) => {
  return (
    <div className="l-center_wrap">
      <div className="l-center_wrap">
        <div className="c-frame">
          <p>
            本注文は
            <Link to={'/order_infos/bundle/' + bundleId}>まとめ発送</Link>
            に含まれています。
          </p>
          <p>
            (ダウンロード販売, 物販, 一括配送分はまとめ発送に含まれません。)
          </p>
        </div>
      </div>
    </div>
  );
});
