import React from 'react';
import SearchForm from './SearchForm';

export const IndexPage: React.FC = () => {
  return (
    <>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">財務管理</span>
      </h3>
      <SearchForm />
    </>
  );
};
