import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { postJson } from '../../../../ts/useApi';
import { TRecreateResponse } from './types';
import { successToast, errorToast } from '../../../../ts/toast';
import {
  TYPE_STAFF,
  TYPE_YEAR,
} from '../../../shared/Certificationkeys/constants';
import { useHistory } from 'react-router-dom';

const EnabledButton: React.FC<{
  id: number;
  type: number;
  keyString: string;
}> = React.memo(({ id, type, keyString }) => {
  const history = useHistory();
  const recreate: MouseEventHandler = useCallback(async () => {
    let path: string;
    let confirmMsg: string;
    if (type === TYPE_STAFF) {
      path = `/api/certificationkeys/${id}/recreate_staff`;
      confirmMsg =
        `${keyString}を削除し、再発行します。よろしいですか？\n` +
        '会員への紐付けは解除され、新しいキーへの紐付け変更は行われません。適宜処理を行ってください。';
    } else if (type === TYPE_YEAR) {
      path = `/api/certificationkeys/${id}/recreate_year`;
      confirmMsg =
        `${keyString}を廃番処理し、再発行します。よろしいですか？\n` +
        'キー束・イベント・会員など関連データへの紐付け解除・紐付けは行われません。適宜処理を行ってください。';
    } else {
      // canRecreateで正しく弾けばナイはず
      window.alert('この種類の写真閲覧キーは再発行できません');
      return;
    }
    // TODO: UI実装
    if (!window.confirm(confirmMsg)) {
      return;
    }
    try {
      const { data } = await postJson<TRecreateResponse>(path);
      successToast('写真閲覧キーを再発行しました');
      history.push(`/certificationkeys/${data.id}`);
    } catch (error) {
      errorToast('エラーが発生しました');
    }
  }, [id, type, keyString, history]);

  return (
    <button
      type="button"
      className="c-btn_rectangle c-btn_manage"
      onClick={recreate}
    >
      <span>再発行</span>
    </button>
  );
});

const Recreate: React.FC<{
  canRecreate: boolean;
  id: number;
  type: number;
  keyString: string;
}> = React.memo(({ canRecreate, ...props }) =>
  canRecreate ? <EnabledButton {...props} /> : null
);

export default Recreate;
