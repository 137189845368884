import React, { FC } from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { RadioBoxes } from '@/components/shared/Form/Inputs';

const ARMBAND = 1;
const TRAINING = 2;
const SHIPPING = 3;

export const WithholdingTaxChoices: FC<{
  validator: TValidator;
  setValue: (value: number) => void;
  watchType: number;
}> = ({ validator, setValue, watchType }) => {
  let isHiddenWithholdingTax = false;
  if (watchType !== undefined) {
    setValue(0);
    switch (watchType) {
      case ARMBAND:
      case TRAINING:
        isHiddenWithholdingTax = true;
        break;
      case SHIPPING:
        isHiddenWithholdingTax = true;
        setValue(1);
        break;
      default:
        isHiddenWithholdingTax = false;
    }
  }
  return (
    <RadioBoxes
      name="hasWithholdingTax"
      choices={[
        {
          key: 1,
          value: '必要',
          isDisabled: isHiddenWithholdingTax,
        },
        {
          key: 0,
          value: '不要',
          isDisabled: isHiddenWithholdingTax,
        },
      ]}
      validator={validator}
    />
  );
};
