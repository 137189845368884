import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UpdateShowData, TFormInputs, TSaveResponse } from './types';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import SaveForm from './SaveForm';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, error } = useJsonApi<UpdateShowData>(
    '/api/corporations/' + id + '/edit'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher('/api/corporations/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('更新しました');
      history.push(`/corporations/${saveResponse.id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const formInputs = {
    ...data.data.organization,
    ...data.data.corporation,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">法人 - 編集</span>
      </h3>
      <div className="l-center_wrap">
        <div className="c-frame">
          <SaveForm
            validator={validator}
            formInputs={formInputs}
            errorMessages={errorMessages}
            onSubmit={onSubmit}
            formItems={data.formItems}
            cancelLink={'/corporations/' + id}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
