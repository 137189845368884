import React, { FC, useState } from 'react';
import styles from './Results.module.scss';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import {
  NumberInput,
  SingleSelect,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
} from '@/ts/photographerPayments/constants';
import { IndexResponse, Payment } from '../types';
import { postJson, putJson } from '@/ts/fetch';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { errorToast, successToast } from '@/ts/toast';
import { isValidationError, toMessages } from '@/ts/useApi';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import Paginator from '@/components/shared/Paginator/App';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { useLocation } from 'react-router-dom';
import { numberFormat } from '@/ts/formatTools';
import QueryString from 'query-string';
import { WithholdingTaxChoices } from '../../Form/WithholdingTaxChoices';
import { TMutate } from '@/ts/useJsonApi';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';

type RowStatus = 'closed' | 'opened' | 'editing';

const ResultDetail: FC<{
  isEditing: boolean;
  payment: Payment;
  formItems: IndexResponse['formItems'];
  editValidator: TValidator;
  handleClose: () => void;
  handleEndEdit: () => void;
}> = ({
  payment,
  formItems,
  isEditing,
  handleClose,
  handleEndEdit,
  editValidator,
}) => {
  const [validator, setValidator] = useState<TValidator>(editValidator);
  const methods = useForm({
    defaultValues: {
      status: payment.status,
      hasWithholdingTax: payment.hasWithholdingTax ? 1 : 0,
      consumptionTaxPercent: Number(payment.consumptionTaxPercent),
      type: payment.type,
      price: payment.price,
      externalMemo: payment.externalMemo,
    },
  });

  const handleClickUndoCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
      return;
    }
    try {
      handleClose();
      await postJson(`/api/photographer_payments/bulk_undo_cancel`, {
        ids: [payment.id],
      });
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消しを解除しました。');
    handleEndEdit();
  });
  const handleSubmit = usePreventDuplicateCall(async (data) => {
    try {
      handleClose();
      await putJson(
        `/api/photographer_payments/non_event_related_expenses/${payment.id}`,
        data
      );
      successToast('イベント外経費が更新されました。');
      handleEndEdit();
    } catch (e) {
      if (isValidationError(e)) {
        errorToast(`入力に誤りがあります`);
        setValidator(e.jsonMessage.validator);
      } else {
        errorToast(`エラーが発生しました: ${toMessages(e)}`);
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <OpenedDetail>
        <form
          className={styles.detailForm}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow disabled={payment.status === STATUS_CANCELED}>
                <InnerTableCell>カメラマン</InnerTableCell>
                <InnerTableCell>詳細区分</InnerTableCell>
                <InnerTableCell>税率</InnerTableCell>
                <InnerTableCell>源泉徴収</InnerTableCell>
                <InnerTableCell>金額</InnerTableCell>
                <InnerTableCell>備考 ※明細に表示されます</InnerTableCell>
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              <InnerTableRow disabled={payment.status === STATUS_CANCELED}>
                <InnerTableCell>{`${payment.photographerSei} ${payment.photographerMei}`}</InnerTableCell>
                <InnerTableCell>
                  {isEditing ? (
                    <div className={styles.selectBoxMinWidth}>
                      <SingleSelect
                        name="type"
                        choices={formItems.types}
                        validator={validator}
                      />
                    </div>
                  ) : (
                    <>{`${payment.typeName}`}</>
                  )}
                </InnerTableCell>
                <InnerTableCell>
                  {isEditing ? (
                    <div className={styles.selectBoxMinWidth}>
                      <SingleSelect
                        name="consumptionTaxPercent"
                        choices={formItems.consumptionTaxPercent}
                        validator={validator}
                      />
                    </div>
                  ) : (
                    <>
                      {payment.consumptionTaxPercent === '0'
                        ? '非課税'
                        : payment.consumptionTaxPercent + '%'}
                    </>
                  )}
                </InnerTableCell>
                <InnerTableCell>
                  {isEditing ? (
                    <div className={styles.selectBoxMinWidth}>
                      <WithholdingTaxChoices
                        validator={validator}
                        setValue={(value: number) =>
                          methods.setValue('hasWithholdingTax', value)
                        }
                        watchType={methods.watch('type')}
                      />
                    </div>
                  ) : (
                    <>{payment.hasWithholdingTax ? '必要' : '不要'}</>
                  )}
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_right">
                    {isEditing ? (
                      <NumberInput name="price" validator={validator} />
                    ) : (
                      numberFormat(payment.price)
                    )}
                  </div>
                </InnerTableCell>
                <InnerTableCell>
                  {isEditing ? (
                    <TextArea name="externalMemo" validator={validator} />
                  ) : (
                    <span className="u-pre_wrap">{payment.externalMemo}</span>
                  )}
                </InnerTableCell>
              </InnerTableRow>
            </InnerTableBody>
          </InnerTable>
          <div className={styles.innerTableButtons}>
            {payment.status === STATUS_APPROVED && isEditing ? (
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="更新"
              />
            ) : payment.status === STATUS_CANCELED && payment.isChangeable ? (
              <button
                className="c-btn_large c-btn_delete"
                type="button"
                onClick={handleClickUndoCancel}
              >
                取り消し解除
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.innerTableButtons}>
            <span className={styles.closeDetailLinkWrapper}>
              <span className={styles.closeDetailLink} onClick={handleClose}>
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                {isEditing ? 'キャンセル' : '閉じる'}
              </span>
            </span>
          </div>
        </form>
      </OpenedDetail>
    </FormProvider>
  );
};

const ResultRow: FC<{
  payment: Payment;
  formItems: IndexResponse['formItems'];
  mutate: () => void;
  editValidator: TValidator;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ payment, formItems, mutate, editValidator, checked, setChecked }) => {
  const [status, setStatus] = useState<RowStatus>('closed');
  const opened = status !== 'closed';
  const handleClickEdit = () => {
    setStatus('editing');
  };
  return (
    <>
      <TableRow
        highlighted={checked}
        disabled={payment.status === STATUS_CANCELED}
      >
        <TableCell>
          {payment.status === STATUS_APPROVED && (
            <SmallCheckBox checked={checked} setChecked={setChecked} />
          )}
        </TableCell>
        <TableCell>
          <div className={styles.resultTableButtonsCell}>
            <span
              className={styles.arrow}
              onClick={() => setStatus(opened ? 'closed' : 'opened')}
            >
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
            <button
              className={clsx('c-btn_rectangle', 'c-btn_edit', {
                'is-disabled':
                  status === 'editing' || payment.status !== STATUS_APPROVED,
              })}
              onClick={handleClickEdit}
            >
              <i className="c-icon c-icon__xsmall c-icon_edit"></i>
            </button>
          </div>
        </TableCell>
        <TableCell>{payment.typeName}</TableCell>
        <TableCell>{`${payment.photographerSei} ${payment.photographerMei}`}</TableCell>
        <TableCell>{payment.organizationName}</TableCell>
        <TableCell>{payment.tradingDate}</TableCell>
        <TableCell>{payment.statusName}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {numberFormat(payment.price)}
          </div>
        </TableCell>
      </TableRow>
      {opened && (
        <ResultDetail
          payment={payment}
          formItems={formItems}
          isEditing={status === 'editing'}
          editValidator={editValidator}
          handleClose={() => setStatus('closed')}
          handleEndEdit={() => {
            setStatus('closed');
            mutate();
          }}
        />
      )}
    </>
  );
};

export const Results: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
  editValidator: TValidator;
  queryParams: Record<string, unknown>;
}> = ({ response, mutate, editValidator, queryParams }) => {
  const location = useLocation();
  const [checkedIds, setCheckedIds] = useState<{ paymentId: number }[]>([]);
  const checkableIds = response.data.payments.filter(
    (payment) => payment.status === STATUS_APPROVED
  );
  if (response.data.payments.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }

  const handleClickBulkCancel = async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    const paymentIds = checkedIds.map((object) => object.paymentId);
    try {
      await postJson(`/api/photographer_payments/bulk_cancel`, {
        ids: paymentIds,
      });
    } catch (e) {
      errorToast(`エラーが発生しました: ${toMessages(e)}`);
      return;
    }
    successToast('取り消しました。');
    setCheckedIds([]);
    mutate(
      (response) =>
        response && {
          ...response,
          data: {
            ...response.data,
            payments: response.data.payments?.map((payment) => ({
              ...payment,
              ...(paymentIds.includes(payment.id)
                ? { status: STATUS_CANCELED, statusName: '取り消し' }
                : {}),
            })),
          },
        }
    );
  };
  const paginatorQueryString = QueryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={paginatorQueryString}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {checkableIds.length > 0 && (
                <SmallCheckBox
                  checked={checkedIds.length === checkableIds.length}
                  setChecked={(allChecked: boolean) => {
                    allChecked
                      ? setCheckedIds(
                          checkableIds.map((payment) => ({
                            paymentId: payment.id,
                          }))
                        )
                      : setCheckedIds([]);
                  }}
                />
              )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>詳細区分</TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>取引日</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.data.payments.map((payment) => (
            <ResultRow
              key={payment.id}
              payment={payment}
              formItems={response.formItems}
              mutate={() => mutate(undefined, { keepCurrentData: true })}
              editValidator={editValidator}
              checked={
                checkedIds.find((row) => row.paymentId === payment.id) !==
                undefined
              }
              setChecked={(checked) =>
                checked
                  ? setCheckedIds([
                      ...checkedIds,
                      {
                        paymentId: payment.id,
                      },
                    ])
                  : setCheckedIds(
                      checkedIds.filter((row) => row.paymentId !== payment.id)
                    )
              }
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={paginatorQueryString}
      />
      <ButtonsFooter>
        <button
          onClick={handleClickBulkCancel}
          className={clsx(
            'c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit',
            {
              'is-disabled': checkedIds.length === 0,
            }
          )}
          disabled={checkedIds.length === 0}
        >
          取り消し
        </button>
      </ButtonsFooter>
    </>
  );
};
