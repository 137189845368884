import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  CheckBox,
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { fetchPdf } from '@/ts/fetch';
import { useJsonApi } from '@/ts/useJsonApi';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { errorToast } from '../../../../ts/toast';
import { renderError, toMessages } from '../../../../ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { TResponse, TForm, TSelectableEvent } from './types';
import './flyer.scss';
import { convertValidatorForArrayForm } from '@/components/shared/Form/validation';
import { kanriUrl } from '../../../../ts/url';
import { alertApiError } from '../../../../ts/formValidation';
import { useAuthInfo } from '../../../AuthProvider';
import Linkify from 'react-linkify';

const DeliveryAddress: React.FC<{
  postcode: string | null;
  prefecture: string | null;
  city: string | null;
  address: string | null;
  to: string | null;
}> = ({ postcode, prefecture, city, address, to }) => {
  return (
    <div>
      <ul className="l-flex_between c-label_line">
        <li className="c-dataLabel">
          <label>宛先</label>
        </li>
        <li className="c-dataValue">{to ?? 'データ無し'}</li>
      </ul>
      <ul className="l-flex_between c-label_line">
        <li className="c-dataLabel">
          <label>住所</label>
        </li>
        <li className="c-dataValue">
          〒 {postcode}　{prefecture}
          {city}
          {address}
        </li>
      </ul>
    </div>
  );
};

const SelectableEventsForm: React.FC<{
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  selectableEvent: TSelectableEvent;
  key: number;
  index: number;
}> = ({ validator, selectableEvent, index }) => {
  const prefix = 'events';
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div key={index}>
      <div className="l-flex nowrap u-mgb_xs">
        <CheckBox
          name={`${prefix}.${index}.checked`}
          validator={convertValidatorForArrayForm(prefix, 0, validator)}
          label=""
        />
        <div className="p-flyer_textInputWrapper">
          <TextInput
            name={`${prefix}.${index}.name`}
            validator={convertValidatorForArrayForm(prefix, 0, validator)}
          />
        </div>
        <div className="u-mgl_xs">
          <span
            className={`c-btn_toggleDetails ${isOpen && 'is-open'}`}
            onClick={() => {
              setIsOpen((b) => !b);
            }}
          >
            詳細
          </span>
        </div>
      </div>
      <div className={`c-accordion_content ${isOpen ? 'is-open' : ''}`}>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>ID</label>
          </li>
          <li className="c-dataValue">
            <Link to={`/events/${selectableEvent.id}`} target="_blank">
              {selectableEvent.id}
            </Link>
            <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>参加人数</label>
          </li>
          <li className="c-dataValue">{selectableEvent.participants ?? 0}</li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>OPメモ</label>
          </li>
          <li className="c-dataValue u-pre_wrap u-mgb_m">
            {selectableEvent.opMemo ?? 'OPメモ無し'}
          </li>
        </ul>
      </div>
    </div>
  );
};

const Form: React.FC<{
  response: TResponse;
  eventno: string;
  canOperation: boolean;
}> = ({ response, eventno, canOperation }) => {
  const selectableEvents: TSelectableEvent[] = [];
  response.data.events.forEach((event) => {
    const eventValue = {
      id: event.id,
      checked: true,
      name: event.name,
      participants: event.participants,
      opMemo: event.opMemo,
    };
    selectableEvents.push(eventValue);
  });

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const methods = useForm<TForm>({
    defaultValues: {
      showQr: 1,
      showCertificationkey: 1,
      title: response.data.title,
      events: selectableEvents,
      freeText: response.data.defaultFreeText,
      fileName: response.data.fileName,
      fileNameEnglish: response.data.fileNameEnglish,
    },
  });
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(response.validator);
  useEffect(() => {
    if (response) {
      setValidator(response.validator);
    }
  }, [response]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const handleSubmit = async (data: TForm) => {
    const uniqEventNames = Array.from(
      new Set(
        data.events.filter((event) => event.checked).map((event) => event.name)
      )
    );
    if (
      uniqEventNames.length !==
      data.events.filter((event) => event.checked).length
    ) {
      alert(
        '選択したイベントに同名のイベントが含まれています。問題ないことを確認して下さい。'
      );
    }
    try {
      const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
        '/api/events/flyer/eventflyer',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
      if (!(pdfResponse instanceof Blob)) {
        if (pdfResponse.validator.hasError) {
          setValidator(pdfResponse.validator);
          alertApiError('チラシのダウンロードに失敗しました');
        }
        return;
      }
      setErrorMessages([]);
      setValidator(response.validator);
      const link = downloadLinkRef.current!;
      const url = URL.createObjectURL(pdfResponse);
      link.href = url;
      link.download = response.data.fileName;
      link.click();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  const handleEnglishSubmit = async (data: TForm) => {
    const uniqEventNames = Array.from(
      new Set(
        data.events.filter((event) => event.checked).map((event) => event.name)
      )
    );
    if (
      uniqEventNames.length !==
      data.events.filter((event) => event.checked).length
    ) {
      alert(
        '選択したイベントに同名のイベントが含まれています。問題ないことを確認して下さい。'
      );
    }
    try {
      const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
        `/api/events/flyer/eventflyer_english`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
      if (!(pdfResponse instanceof Blob)) {
        if (pdfResponse.validator.hasError) {
          setValidator(pdfResponse.validator);
          alertApiError('チラシのダウンロードに失敗しました');
        }
        return;
      }
      setErrorMessages([]);
      setValidator(response.validator);
      const link = downloadLinkRef.current!;
      const url = URL.createObjectURL(pdfResponse);
      link.href = url;
      link.download = response.data.fileNameEnglish;
      link.click();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  const handleMoveToFax = (data: TForm) => {
    const checkedIds = data.events
      .filter((event) => event.checked)
      .map((event) => event.id);
    window.location.href = kanriUrl({
      action_owner_EVENTchirashiold: 'true',
      eventno1: checkedIds[0]?.toString(),
      eventno2: checkedIds[1]?.toString() || '',
      eventno3: checkedIds[2]?.toString() || '',
    });
  };
  const dateFormat = (date: string, format: string) =>
    dayjs(date).format(format);
  return (
    <FormProvider {...methods}>
      <form>
        <ErrorMessages messages={errorMessages} />
        <ApiErrors {...validator} />
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>団体名</label>
          </li>
          <li className="c-dataValue">
            <TextInput
              name="title"
              placeholder="団体名"
              validator={validator}
              additionalClassName="p-flyer_titleInput"
            />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>団体ID</label>
          </li>
          <li className="c-dataValue">
            <Link to={`/societies/${response.data.societyId}`} target="_blank">
              {response.data.societyId}
            </Link>
            <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>配送先住所指定</label>
          </li>
          <li className="c-dataValue">
            {response.data.isRequiredDeliveryAddress ? (
              <DeliveryAddress
                postcode={response.data.deliveryAddressPostcode}
                prefecture={response.data.deliveryAddressPrefecture}
                city={response.data.deliveryAddressCity}
                address={response.data.deliveryAddressAddress}
                to={response.data.deliveryAddressTo}
              />
            ) : (
              '指定無し'
            )}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>期間</label>
          </li>
          <li className="c-dataValue">
            {dateFormat(response.data.events[0].valuestartday, 'M/D (ddd)')}
            &nbsp;〜&nbsp;
            {dateFormat(response.data.events[0].valueendday, 'M/D (ddd)')}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>責任者メール</label>
          </li>
          <li className="c-dataValue">
            {response.data.leaderMailaddress ?? '登録無し'}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>写真閲覧キー</label>
          </li>
          <li className="c-dataValue">
            {response.data.certificationkey}【{response.data.authenticationType}
            】
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>写真閲覧キーの有無</label>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name="showCertificationkey"
              validator={validator}
              choices={[
                { key: 0, value: 'なし' },
                { key: 1, value: 'あり' },
              ]}
            />
            （写真閲覧キー無しの場合、QRコードは表示されません）
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>イベント</label>
          </li>
          <li className="c-dataValue">
            {selectableEvents.map((event, index) => (
              <SelectableEventsForm
                validator={validator}
                selectableEvent={event}
                index={index}
                key={event.id}
              />
            ))}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>QRコードの有無</label>
          </li>
          <li className="c-dataValue">
            <RadioBoxes
              name="showQr"
              validator={validator}
              choices={[
                { key: 0, value: 'なし' },
                { key: 1, value: 'あり' },
              ]}
            />
          </li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>チラシ部数</label>
          </li>
          <li className="c-dataValue">{response.data.numberOfFlyers}</li>
        </ul>
        <ul className="l-flex_between c-label_line">
          <li className="c-dataLabel">
            <label>園児・生徒の情報</label>
          </li>
          <li className="c-dataValue">
            {response.data.studentSummaries.length &&
              response.data.studentSummaries.map((summary, index) => (
                <div className="u-pre_wrap u-mgb_m" key={index}>
                  {summary.length ? summary : '無し'}
                </div>
              ))}
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>チラシ・SB配送の注意点</label>
          </li>
          <li className="c-dataValue u-pre_wrap">
            <Linkify>{response.data.deliveryPrecaution}</Linkify>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">
            <label>その他の注意点</label>
          </li>
          <li className="c-dataValue u-pre_wrap">
            <Linkify>{response.data.otherPrecaution}</Linkify>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input">
          <li className="c-dataLabel">お知らせ</li>
          <li className="c-dataValue">
            <TextArea
              name="freeText"
              validator={validator}
              rows={validator.rules?.freeText?.maxRows}
              cols={validator.rules?.freeText?.maxCols}
            />
          </li>
        </ul>
        <input type="hidden" name="fileName" value={response.data.fileName} />
        <input
          type="hidden"
          name="fileNameEnglish"
          value={response.data.fileNameEnglish}
        />
        <p className="u-align_center l-flex_center_line">
          <span
            className="c-btn_large c-btn_primary is-arrow c-input_submit u-mgr_s"
            onClick={methods.handleSubmit(handleSubmit)}
          >
            チラシをダウンロード
          </span>
          <span
            className="c-btn_large c-btn_primary is-arrow c-input_submit u-mgr_s"
            onClick={methods.handleSubmit(handleEnglishSubmit)}
          >
            英語チラシをダウンロード
          </span>
          <Link
            className="c-btn_large c-btn_edit is-arrow c-input_submit"
            to={`/events/${eventno}/cover_letter`}
          >
            送付状作成画面へ
          </Link>
          <span
            className="c-btn_large c-btn_edit is-arrow c-input_submit u-mgl_s"
            onClick={methods.handleSubmit(handleMoveToFax)}
          >
            FAX用紙作成画面へ
          </span>
          {canOperation && (
            <Link
              className="c-btn_large c-btn_edit is-arrow c-input_submit u-mgl_s"
              to={`/events/${eventno}/flyer/mail/create`}
            >
              チラシ送信画面へ
            </Link>
          )}
        </p>
        <a href="/" className="is-hidden" ref={downloadLinkRef}>
          dummy
        </a>
      </form>
    </FormProvider>
  );
};

const App: React.FC = () => {
  const authInfo = useAuthInfo();
  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<TResponse>(
    `/api/events/flyer/event_list_flyer?eventId=${id}`
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }

  return (
    <>
      <Link className="c-page_back" to={`/events/${id}`}>
        イベントサマリに戻る
      </Link>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">PDFチラシ作成画面</span>
      </h3>
      <div className="c-frame">
        <Form
          response={response}
          eventno={id}
          canOperation={authInfo.authOperation}
        />
      </div>
    </>
  );
};

export default App;
