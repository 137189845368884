import React, { useCallback } from 'react';
import { MouseEventHandler } from 'react-select';

import { deleteJson } from '../../../../ts/useApi';
import { successToast, errorToast } from '../../../../ts/toast';
import { useHistory } from 'react-router-dom';

const Delete: React.FC<{
  bundleId: number;
  certificationkeyId?: number;
}> = React.memo(({ bundleId, certificationkeyId }) => {
  const history = useHistory();

  const onClick: MouseEventHandler = useCallback(async () => {
    if (
      !window.confirm(
        `キー束を削除します。紐付けられている写真閲覧キーすべての紐付けが解除されますが、よろしいですか？`
      )
    ) {
      return;
    }
    try {
      await deleteJson<{ data: 'OK' }>(`/api/key_bundles/${bundleId}`);
      successToast('写真閲覧キーを削除しました');
      if (certificationkeyId) {
        history.push(`/certificationkeys/${certificationkeyId}`);
      } else {
        history.push('/certificationkeys');
      }
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  }, [bundleId, certificationkeyId, history]);

  return (
    <button className="c-btn_rectangle c-btn_delete" onClick={onClick}>
      <i className="c-icon__xsmall c-icon_trash" />
    </button>
  );
});

const DeleteButton: React.FC<{
  canDelete: boolean;
  bundleId: number;
  certificationkeyId?: number;
}> = React.memo(({ canDelete, ...props }) =>
  canDelete ? (
    <Delete {...props} />
  ) : (
    <button className="c-btn_rectangle c-btn_delete is-disabled">
      <i className="c-icon__xsmall c-icon_trash" />
    </button>
  )
);

export default DeleteButton;
