import { TValidator } from '@/components/shared/Form/types';
import { PhotographyAccordion } from '@/components/pages/Events/Show/Photography/Shared/Accordion/App';
import { TextArea } from '@/components/shared/Form/Inputs';
import { Alert } from '@/components/shared/Alert';
import { TPhotography } from '@/components/pages/Events/Show/Photography/types';
import { Fragment, useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import styles from './style.module.scss';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { LIST_ITEMS } from '../../listItem';

type TimeScheduleProps = {
  photography: TPhotography;
  validator: TValidator;
};

const TimeScheduleAccordion: React.FC<{ photography: TPhotography }> = ({
  photography,
}) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div className={styles.background}>
      <div
        className={styles.clickableBlock}
        onClick={() => setOpened(!isOpened)}
      >
        <div className={styles.title}>
          <FaCircleInfo className={styles.icon} />【{photography.eventTypeName}
          】記載例
        </div>
        <div className={styles.arrow}>
          {isOpened ? (
            <RiArrowUpSLine size="24px" color="#A3A7AC" />
          ) : (
            <RiArrowDownSLine size="24px" color="#A3A7AC" />
          )}
        </div>
      </div>
      {isOpened && (
        <div className="u-mgt_s">
          <p className="c-alert_text">
            ※テキストコピー後、貼り付けてお使いいただけます。
          </p>
          {photography.timeScheduleExample?.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export const TimeSchedule: React.FC<TimeScheduleProps> = ({
  photography,
  validator,
}) => {
  return (
    <PhotographyAccordion
      title={LIST_ITEMS.event.timeSchedule.name}
      id={LIST_ITEMS.event.timeSchedule.id}
    >
      <TimeScheduleAccordion photography={photography} />
      <TextArea
        name="timeSchedule"
        validator={validator}
        rows={15}
        placeholder="タイムスケジュールを入力してください"
      />
      <Alert
        type="error"
        title="複数カメラマンでタイムスケジュールが異なる場合"
      >
        <p>
          同じ記入欄にそれぞれ記載してください。その場合、誰のスケジュールか名前を記載してください。
          <br />
          例：▼田中さんのスケジュール
        </p>
      </Alert>
    </PhotographyAccordion>
  );
};
