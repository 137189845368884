import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

type Props = {
  type: 'error' | 'warning';
  message: string;
};

const params = {
  block: {
    error: clsx(styles.baseNotice, styles.errorBlock),
    warning: clsx(styles.baseNotice, styles.warningBlock),
  },
  listItem: {
    error: clsx(styles.baseListItem, styles.errorListItem),
    warning: clsx(styles.baseListItem, styles.warningListItem),
  },
};

export const Notice: React.FC<Props> = React.memo(({ type, message }) => {
  return (
    <div className={params.block[type]}>
      <ul className={`c-${type}_list`}>
        <li className={params.listItem[type]}>{message}</li>
      </ul>
    </div>
  );
});
