import React from 'react';
import {
  OrderTypeLabel,
  HokuryoPrintLog as PrintLog,
} from '../../../Show/types';
import { format } from 'date-fns';
import styles from './index.module.scss';

type Props = {
  orderInfoId: number;
  orderDeliveryInfoId: number;
  printLog: PrintLog;
};

const columns = [
  'ID',
  '注文ID',
  '注文タイプ',
  'エラー内容',
  'TSV作成日', //createdday
  '注文配送先no', //orderdeliveryinfono
  '北菱フォトでの受取日', //printedday
  '配送伝票番号', //invoiceno
  '配送番号発行日',
  '発送しましたメール',
];

export const HokuryoPrintLog = React.memo<Props>(
  ({ orderInfoId, orderDeliveryInfoId, printLog }) => {
    return (
      <div className={styles.container}>
        <div className="l-flex_between_center u-mgb_s">
          <h4 className="c-section_title">HokuryoTSV</h4>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={`column-${index}`}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="right">{printLog.id}</td>
              <td align="right">{orderInfoId}</td>
              <td align="center">{OrderTypeLabel[printLog.orderType]}</td>
              <td>{printLog.error}</td>
              <td>
                {format(new Date(printLog.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </td>
              <td align="right">{orderDeliveryInfoId}</td>
              <td>
                {printLog.printedAt &&
                  format(new Date(printLog.printedAt), 'yyyy-MM-dd HH:mm:ss')}
              </td>
              <td align="right">{printLog.invoiceNo}</td>
              <td>
                {printLog.invoicedAt &&
                  format(new Date(printLog.invoicedAt), 'yyyy-MM-dd HH:mm:ss')}
              </td>
              <td align="center">{printLog.mailSentFlag && '送信済'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);
