import { TValidator } from '@/components/shared/Form/types';
import { TConstants, TFormInputs, TPhotography } from '../../types';
import { PhotographyAccordion } from '../../Shared/Accordion/App';
import {
  RadioBoxes,
  TextArea,
  TextInput,
} from '@/components/shared/Form/Inputs';
import {
  BREAK_FIRST_MEAL_TYPE_ID,
  DINNER_MEAL_TYPE_ID,
  ID_NONE,
  ID_UNCONFIRMED,
  LUNCH_MEAL_TYPE_ID,
} from '../../const';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import React, { Fragment } from 'react';
import { ownerUrl } from '@/ts/url';
import { Alert } from '@/components/shared/Alert';
import { useParams } from 'react-router-dom';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
} from '@/components/shared/ResultTable';
import clsx from 'clsx';
import { FiPlus } from 'react-icons/fi';
import { LIST_ITEMS } from '@/components/pages/Events/Show/Photography/listItem';

type RouteParams = {
  eventId: string;
};

export const Sleepover: React.FC<{
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
  methods: UseFormReturn<TFormInputs>;
}> = ({ validator, constants, photography, methods }) => {
  const { eventId } = useParams<RouteParams>();
  const { control } = methods;
  const { append, remove } = useFieldArray({
    control,
    name: 'photographerHotelPlaces',
  });
  const places = methods.watch('photographerHotelPlaces');
  const addPlace = () => {
    append({ name: '', address: '' });
  };

  const deletePlace = (index: number) => {
    remove(index);
  };
  const sleepoverMeals = methods.getValues('sleepoverMeals');
  return (
    <>
      <PhotographyAccordion
        title={`${photography.eventTypeName}情報`}
        id={LIST_ITEMS.event.sleepover.id}
      >
        <div className="t-textColor_sub">宿泊</div>
        <div>{photography.canSleepover ? 'あり' : 'なし'}</div>
        <div className="t-textColor_sub">
          ※申請サマリの登録情報を表示しています。
        </div>
        {photography.canSleepover && (
          <>
            <hr className="u-line_plane" />
            <div className="u-mgb_s" />
            <div>場所詳細</div>
            <div className="t-bgBox_gray">{photography.sleepoverPlace}</div>
          </>
        )}
        <Alert type="error" title="宿泊について">
          <p className="u-mgb_s">
            宿泊有無や場所の名称が変更になった場合は申請サマリ編集より対応してください。複数場所がある場合は名称のみを申請サマリに登録し、住所は当画面で必要な数だけ項目を追加をして登録してください。
          </p>
          <a
            className="c-btn c-btn_middle_wide c-btn_black"
            href={ownerUrl(`events/requests/${eventId}`)}
            target="_blank"
            rel="noreferrer"
          >
            申請サマリ編集
          </a>
        </Alert>

        {photography.canSleepover && (
          <>
            <InnerTable>
              <InnerTableBody>
                {places.map((_, index) => (
                  <InnerTableRow key={index}>
                    <InnerTableCell>
                      <span>施設名</span>
                      <TextInput
                        name={`photographerHotelPlaces.${index}.name`}
                        validator={validator}
                      />
                      <span>住所</span>
                      <TextInput
                        name={`photographerHotelPlaces.${index}.address`}
                        validator={validator}
                      />
                    </InnerTableCell>
                    <InnerTableCell>
                      <div className="u-align_center">
                        <button
                          type="button"
                          className={clsx(
                            'c-btn_small c-btn_delete c-btn_middle_wide',
                            { 'is-disabled': index === 0 }
                          )}
                          onClick={() => deletePlace(index)}
                        >
                          削除
                        </button>
                      </div>
                    </InnerTableCell>
                  </InnerTableRow>
                ))}
              </InnerTableBody>
            </InnerTable>
            <button
              type="button"
              className="c-btn c-btn_large c-btn_gray_outline u-mgt_s"
              onClick={addPlace}
            >
              <FiPlus />
              追加
            </button>
          </>
        )}
        <div className="u-mgb_s" />
        <hr className="u-line_plane" />

        <div className="t-textColor_sub">寝顔撮影</div>
        <RadioBoxes
          name="hasSleepingFacePhoto"
          validator={validator}
          choices={[
            { key: ID_UNCONFIRMED, value: '未確認' },
            { key: 1, value: 'あり' },
            { key: 0, value: 'なし' },
          ]}
        />
        <Alert type="warning" title="寝顔撮影のフラッシュについて">
          ストロボ持参のイベントについては「機材」でフラッシュ可否の登録ができます。
          <br />
          寝顔撮影のフラッシュについては、一律「当日相談してください」の注意書きが表示されます。
          <br />
          ※共有事項があれば、機材のストロボ共有事項に記載してください。
        </Alert>
        {photography.canSleepover && (
          <div className="l-flex">
            {sleepoverMeals?.map((meal, index) => (
              <Fragment key={index}>
                {index === 0 && <div className="l-col_24">1日目食事</div>}
                {index === 2 && <div className="l-col_24">2日目食事</div>}
                <div className="l-col_12">
                  <InnerTable>
                    <InnerTableHead>
                      <InnerTableRow>
                        {meal.mealTypeId === BREAK_FIRST_MEAL_TYPE_ID && (
                          <InnerTableCell>朝</InnerTableCell>
                        )}
                        {meal.mealTypeId === LUNCH_MEAL_TYPE_ID && (
                          <InnerTableCell>昼</InnerTableCell>
                        )}
                        {meal.mealTypeId === DINNER_MEAL_TYPE_ID && (
                          <InnerTableCell>夜</InnerTableCell>
                        )}
                      </InnerTableRow>
                    </InnerTableHead>
                    <InnerTableBody>
                      <InnerTableRow>
                        <InnerTableCell>
                          <RadioBoxes
                            name={`sleepoverMeals.${index}.preparingMealTypeId`}
                            validator={validator}
                            choices={[
                              { key: ID_UNCONFIRMED, value: '未確認' },
                              ...constants.photographyPreparingMealTypes,
                              { key: ID_NONE, value: 'なし' },
                            ]}
                          />
                        </InnerTableCell>
                      </InnerTableRow>
                    </InnerTableBody>
                  </InnerTable>
                </div>
              </Fragment>
            ))}
          </div>
        )}

        <hr className="u-line_plane" />

        <div className="t-textColor_sub">補足事項</div>
        <TextArea
          name="sleepoverSenNote"
          validator={validator}
          placeholder="お泊まり情報の詳細を入力してください"
        />
      </PhotographyAccordion>
    </>
  );
};
