import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { kanriUrl, ownerUrl } from '../../../ts/url';
import { TSalesOwner } from './types';
import QueryString from 'query-string';

const SalesOwners: React.FC<{ salesOwners: TSalesOwner[] }> = ({
  salesOwners,
}) => {
  const input = useRef<HTMLInputElement | null>(null);
  const [opened, setOpened] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('');
  const currentSalesOwners = salesOwners.filter((salesOwners) =>
    currentFilter
      .split(/\s+/)
      .some(
        (filter) => salesOwners.name.replace(/\s+/, '').indexOf(filter) >= 0
      )
  );
  return (
    <>
      <h4 className="c-section_subTitle_gry l-flex_between_center u-mgb_m u-fz_default">
        <b>営業情報</b>
        <div
          className={`c-btn_toggleDetails ${opened && 'is-open'}`}
          onClick={() => setOpened((b) => !b)}
        >
          <span className="sp_off">{opened ? '閉じる' : '開く'}</span>
        </div>
      </h4>
      {opened && (
        <div>
          <div className="c-searchOption_box c-label_innerHalf">
            <div className="l-relative">
              <input
                className="c-input_plane c-searchKeyword_input"
                placeholder="千 太郎"
                type="text"
                ref={input}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setCurrentFilter(input.current?.value || '');
                    e.preventDefault();
                  }
                }}
              />
              <i className="c-icon_search p-searchKeyword_icon t-textColor_sub"></i>
            </div>
            <div className="c-spSearchBtn_Box">
              <input
                className="c-btn_large c-btn_Search u-w_60_sp"
                type="submit"
                value="検索"
                onClick={(e) => setCurrentFilter(input.current?.value || '')}
              />
            </div>
          </div>
          <div className="p-index_scrollbar">
            {currentSalesOwners.map(({ id, name }) => (
              <div className="l-flex_between_center p-index_ListItem" key={id}>
                <h4 className="c-textOmit">{name}</h4>
                <ul className="l-flex is-sp_input u-fz_xs">
                  <li className="u-mgr_m p-index_linkstitle">
                    <b>詳細</b>
                  </li>
                  <li>
                    <ul className="p-index_links l-flex">
                      <li>
                        <Link
                          className="c-textlink"
                          to={`/sales?${QueryString.stringify({
                            primarySales: id,
                            dateFrom: dayjs()
                              .startOf('month')
                              .format('YYYY-MM-DD'),
                            dateTo: dayjs().format('YYYY-MM-DD'),
                          })}`}
                        >
                          今月の売上（直販・先生プラン）
                        </Link>
                        <i className="c-icon_arrowRight u-mgl_xs t-textColor_blue"></i>
                      </li>
                      <li>
                        <a
                          className="c-textlink"
                          href={kanriUrl({
                            action_owner_SALESsearch: 'true',
                            eigyoownerno: id.toString(),
                            date_from: dayjs()
                              .startOf('month')
                              .format('YYYY-MM-DD'),
                            date_to: dayjs().format('YYYY-MM-DD'),
                            outputmode: 'html',
                          })}
                        >
                          今月の売上（パートナー）
                        </a>
                        <i className="c-icon_arrowRight u-mgl_xs t-textColor_blue"></i>
                      </li>
                      <li>
                        <a
                          className="c-textlink"
                          href={ownerUrl('events/requests', {
                            'primary_sales[]': id.toString(),
                          })}
                        >
                          申請リスト
                        </a>
                        <i className="c-icon_arrowRight u-mgl_xs t-textColor_blue"></i>
                      </li>
                      <li>
                        <a
                          className="c-textlink"
                          href={kanriUrl({
                            action_owner_SHINSEItehailist2: 'true',
                            eigyoownerno: id.toString(),
                            photograhingday_from: dayjs().format('YYYY-MM-DD'),
                          })}
                        >
                          手配リスト
                        </a>
                        <i className="c-icon_arrowRight u-mgl_xs t-textColor_blue"></i>
                      </li>
                      <li>
                        <Link
                          className="c-textlink"
                          to={`/events?${QueryString.stringify({
                            toValueStartDay: dayjs().format('YYYY-MM-DD'),
                            fromValueEndDay: dayjs().format('YYYY-MM-DD'),
                            'openFlags[]': '1',
                            'eigyoOwnerNos[]': id.toString(),
                          })}`}
                        >
                          バリューなう！
                        </Link>
                        <i className="c-icon_arrowRight u-mgl_xs t-textColor_blue"></i>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SalesOwners;
