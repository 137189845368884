import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  Checkboxes,
  GroupedMultiSelect,
  MultiSelect,
  NumbersInput,
  SingleSelect,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';
import Accordion from '../../../shared/Accordion/App';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({
    validator,
    formItems: { types, ranks, prefectures },
    queryParams,
    onSubmit,
  }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit } = methods;

    return (
      <div className="c-searchForm">
        <div className="c-frame">
          <h5 className="c-section_title u-fz_m">検索条件</h5>
          <Accordion>
            <FormProvider {...methods}>
              <ApiErrors {...validator} />
              <form
                method="GET"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>カメラマンID</label>
                      </li>
                      <li className="c-dataValue">
                        <NumbersInput
                          name="photographerIds"
                          placeholder="スペース区切りで入力してください"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>社カメ・相カメ</label>
                      </li>
                      <li className="c-dataValue">
                        <Checkboxes
                          name="types"
                          choices={types}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>性別</label>
                      </li>
                      <li className="c-dataValue">
                        <SingleSelect
                          name="gender"
                          choices={[
                            { key: '', value: 'すべて' },
                            { key: 'm', value: '男性' },
                            { key: 'f', value: '女性' },
                          ]}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>自動アサイン</label>
                      </li>
                      <li className="c-dataValue">
                        <SingleSelect
                          name="isOnlyAutoArrangementTarget"
                          choices={[
                            { key: 0, value: 'すべて' },
                            { key: 1, value: '自動アサイン対象のみ' },
                          ]}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>都道府県</label>
                      </li>
                      <li className="c-dataValue">
                        <GroupedMultiSelect
                          name="prefectureIds"
                          choices={prefectures}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        <label>ランク</label>
                      </li>
                      <li className="c-dataValue">
                        <MultiSelect
                          name="rankIds"
                          choices={ranks}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                  <input
                    className="c-btn_large c-btn_Search c-input_submit"
                    type="submit"
                    value="この内容で検索"
                  />
                </div>
              </form>
            </FormProvider>
          </Accordion>
        </div>
      </div>
    );
  }
);

export default SearchForm;
