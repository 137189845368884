import React from 'react';

const Help: React.FC<{ isLarge?: true }> = ({ children, isLarge }) => (
  <span className="c-help l-relative u-mgl_xs">
    <i className="c-icon c-icon__xsmall c-icon_help"></i>
    <span
      className={'c-help_tooltip' + (isLarge ? ' c-help_tooltip__large' : '')}
    >
      {children}
    </span>
  </span>
);

export default Help;
