import React, { Fragment } from 'react';
import { TOverviewResponse } from '@/components/pages/Events/Show/Overview/types';
import { Link } from 'react-router-dom';
import { ListItem } from '@/components/pages/Events/Show/Overview/ListItem';

export const AssignedPhotographers: React.FC<{ response: TOverviewResponse }> =
  ({ response }) => {
    return (
      <div className="u-pdb_s u-mgb_m">
        <h2>撮影カメラマン</h2>
        {response.photographers.map((photographer, index) => (
          <Fragment key={index}>
            <ListItem>
              <ul>
                <li>氏名</li>
                <li>
                  <Link to={'/photographers/' + photographer.photographerId}>
                    {`${photographer.photographerId} / ${photographer.photographerName} (${photographer.photographerNameKana})【${photographer.shortName}】`}
                  </Link>
                </li>
              </ul>
              <ul>
                <li>性別</li>
                <li>{photographer.genderName}</li>
              </ul>
              <ul>
                <li>社内外</li>
                <li>{photographer.isShanaiName}</li>
              </ul>
              <ul>
                <li>ランク</li>
                <li>{photographer.rankName}</li>
              </ul>
              <ul>
                <li>車所持</li>
                <li>{photographer.hasCarName}</li>
              </ul>
              <ul>
                <li>ステータス</li>
                <li>{photographer.eventRequestStatusName}</li>
              </ul>
              <ul>
                <li>連絡先</li>
                <li>{photographer.telephone}</li>
              </ul>
            </ListItem>
          </Fragment>
        ))}
      </div>
    );
  };
