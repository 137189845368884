import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RadioBoxes, SingleSelect } from '@/components/shared/Form/Inputs';
import {
  TValidatorResponse,
  TOnlyValidationRuleResponse,
  TChoice,
} from '@/components/shared/Form/types';

import { TFormInputs, TFormItems } from './types';
import {
  DELIVERY_TYPE_BLUKDELIVERY,
  PRINTING_TYPE_JVIS,
  isContractTypeDirect,
  isContractTypeTeacher,
  isGoodsPlan,
  PRINTING_TYPE_HOKURYO,
  SOCIETY_CONTRACTS_SELECTED_NO,
  SOCIETY_CONTRACTS_SELECTED_YES,
  IS_ENABLED_OUT_OF_PERIOD_SALES_NONE,
} from '../../../../ts/societyContractConstants';
import SaveFormSalesManagementPlan from './SaveFormSalesManagementPlan';
import { NumberInput, NumberLooseInput } from '@/components/shared/Form/Inputs';
import {
  INCENTIVE_PAYMENT_TYPE_SALES,
  SPECIAL_INCENTIVE_RATE_TYPE_NONE,
} from '../../../../ts/salesManagementConstants';
import { convertValidatorForArrayForm } from '@/components/shared/Form/validation';

const Plan: React.FC<{
  societyContractTypeId: number;
  index: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  planName: string | undefined;
  formItems: TFormItems;
  remove: (index: number) => void;
}> = React.memo(
  ({
    societyContractTypeId,
    index,
    validator: orgValidator,
    planName,
    formItems: { publishterms, societyContractPlan, salesManagementPlan },
    remove,
  }) => {
    const { watch, setValue, getValues } = useFormContext<TFormInputs>();
    if (watch('deliveryType') === DELIVERY_TYPE_BLUKDELIVERY) {
      if (
        getValues(`societyContractPlans.${index}.printingCompanyType`) !==
        PRINTING_TYPE_JVIS
      ) {
        setValue(
          `societyContractPlans.${index}.printingCompanyType`,
          PRINTING_TYPE_JVIS
        );
      }
    }

    const JVIS: TChoice[] = [
      {
        key: PRINTING_TYPE_JVIS,
        value: 'JVIS',
      },
    ];
    const PERIOD_SALES_NONE: TChoice[] = [
      {
        key: IS_ENABLED_OUT_OF_PERIOD_SALES_NONE,
        value: '無',
      },
    ];

    const validator = convertValidatorForArrayForm(
      'societyContractPlans',
      index,
      orgValidator
    );
    const salesManagementPlanValidator = convertValidatorForArrayForm(
      'salesManagementPlans',
      index,
      orgValidator
    );
    return (
      <div className="t-bgBox_brown u-pdb_xs">
        <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">プラン種類</li>
              <li className="c-dataValue">
                {planName ?? '契約種類を選択してください'}
                <NumberInput
                  name={`societyContractPlans.${index}.planId`}
                  placeholder="プラン種類"
                  validator={validator}
                  additionalClassName="is-hidden"
                />
              </li>
            </ul>
          </li>
          <li className="c-label_innerHalf l-clearfix">
            <button
              className="c-btn_rectangle c-btn_delete l-float_r"
              type="button"
              onClick={() => remove(index)}
            >
              <i className="c-icon__xsmall c-icon_trash" />
            </button>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>有効/無効</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name={`societyContractPlans.${index}.isActive`}
                  choices={societyContractPlan.isActive}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>文字入れ機能利用</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name={`societyContractPlans.${index}.isEnabledOverlayText`}
                  choices={societyContractPlan.isEnabledOverlayText}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>期間外販売の有無</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                {isContractTypeDirect(societyContractTypeId) ? (
                  <RadioBoxes
                    name={`societyContractPlans.${index}.isEnabledOutOfPeriodSales`}
                    choices={societyContractPlan.isEnabledOutOfPeriodSales}
                    validator={validator}
                  />
                ) : (
                  <RadioBoxes
                    name={`societyContractPlans.${index}.isEnabledOutOfPeriodSales`}
                    choices={PERIOD_SALES_NONE}
                    validator={validator}
                  />
                )}
              </li>
            </ul>
          </li>
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>バリュー期間 or 掲載期間</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <SingleSelect
                  name={`societyContractPlans.${index}.publishtermno`}
                  choices={publishterms}
                  placeholder="バリュー期間 or 掲載期間"
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>閲覧専用かどうか</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name={`societyContractPlans.${index}.isViewonly`}
                  choices={societyContractPlan.isViewonly}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>プリント会社</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                {watch('deliveryType') !== DELIVERY_TYPE_BLUKDELIVERY ? (
                  <RadioBoxes
                    name={`societyContractPlans.${index}.printingCompanyType`}
                    choices={societyContractPlan.printingCompanyType}
                    validator={validator}
                  />
                ) : (
                  <RadioBoxes
                    name={`societyContractPlans.${index}.printingCompanyType`}
                    choices={JVIS}
                    validator={validator}
                  />
                )}
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>データ小販売</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name={`societyContractPlans.${index}.isEnabledSellDataS`}
                  choices={societyContractPlan.isEnabledSellDataS}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
          <li className="c-label_innerHalf">
            <ul className="l-flex">
              <li className="c-dataLabel">
                <label>FAX販売</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <RadioBoxes
                  name={`societyContractPlans.${index}.isEnabledSellByFax`}
                  choices={societyContractPlan.isEnabledSellByFax}
                  validator={validator}
                />
              </li>
            </ul>
          </li>
        </ul>
        {isContractTypeDirect(societyContractTypeId) && (
          <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
            <li className="c-label_innerHalf">
              <ul className="l-flex">
                <li className="c-dataLabel">直販のインセンティブ率</li>
                <li className="c-dataValue">
                  <NumberLooseInput
                    name={`societyContractPlans.${index}.directSalesIncentiveRate`}
                    placeholder="0.00"
                    additionalClassName="u-align_right"
                    validator={validator}
                    step={0.01}
                    unit="%"
                    optionalValidationRule={{ required: true }}
                  />
                </li>
              </ul>
            </li>
            <li className="c-label_innerHalf" />
          </ul>
        )}
        <SaveFormSalesManagementPlan
          societyContractTypeId={societyContractTypeId}
          index={index}
          formItems={salesManagementPlan}
          validator={salesManagementPlanValidator}
        />
      </div>
    );
  }
);

const SaveFormPlans: React.FC<{
  societyContractTypeId: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
}> = React.memo(({ societyContractTypeId, validator, formItems }) => {
  const { control, watch } = useFormContext<TFormInputs>();
  const {
    fields: societyContractPlans,
    append: appendSocietyContractPlan,
    remove: removeSocietyContractPlan,
  } = useFieldArray({
    name: 'societyContractPlans',
    control,
  });
  const {
    append: appendSalesManagementPlan,
    remove: removeSalesManagementPlan,
  } = useFieldArray({
    name: 'salesManagementPlans',
    control,
  });
  const isTeacher = isContractTypeTeacher(societyContractTypeId);
  // 追加
  const appendByPlanId = useCallback(
    (planId: number) => {
      const isEnabledPostagePaidForIndividualdelivery = watch(
        'isEnabledPostagePaidForIndividualdelivery'
      );
      if (isEnabledPostagePaidForIndividualdelivery && isGoodsPlan(planId)) {
        if (
          !window.confirm(
            '物販のプランを追加すると、送料「無」、アルバム購入「不可」に固定されます。よろしいですか？\n（※送料「有」、アルバム購入「可」に設定したい場合、物販の契約は別途登録してください。）'
          )
        ) {
          return;
        }
      }
      const salesManagement = watch('salesManagement');
      const isEnabledSellDataS = isTeacher
        ? salesManagement?.isEnabledSellDataS
        : SOCIETY_CONTRACTS_SELECTED_NO;
      const isEnabledSellByFax = isTeacher
        ? salesManagement?.isEnabledSellByFax
        : SOCIETY_CONTRACTS_SELECTED_YES;
      const isEnabledOverlayText = isTeacher
        ? SOCIETY_CONTRACTS_SELECTED_NO
        : SOCIETY_CONTRACTS_SELECTED_YES;
      appendSocietyContractPlan({
        planId,
        isActive: 1,
        isEnabledOverlayText: isEnabledOverlayText,
        isEnabledOutOfPeriodSales: 0,
        publishtermno: 2,
        isViewonly: 0,
        printingCompanyType: PRINTING_TYPE_HOKURYO,
        isEnabledSellDataS,
        isEnabledSellByFax,
        directSalesIncentiveRate: 0,
      });
      if (isTeacher) {
        appendSalesManagementPlan({
          planId,
          incentiveRate: 0,
          incentivePaymentType: INCENTIVE_PAYMENT_TYPE_SALES,
          specialIncentiveRateType: SPECIAL_INCENTIVE_RATE_TYPE_NONE,
        });
      }
    },
    [appendSocietyContractPlan, appendSalesManagementPlan, watch, isTeacher]
  );
  // 削除
  const remove = useCallback(
    (index: number) => {
      removeSocietyContractPlan(index);
      if (isTeacher) {
        removeSalesManagementPlan(index);
      }
    },
    [removeSocietyContractPlan, removeSalesManagementPlan, isTeacher]
  );

  const { planDirects, planTeachers } = formItems;
  // NOTE: 契約種類未定の場合空
  const planChoices = isContractTypeDirect(societyContractTypeId)
    ? planDirects
    : isContractTypeTeacher(societyContractTypeId)
    ? planTeachers
    : [];
  // NOTE: 各プラン1つのみなので、登録ボタンを押せるかどうかで制御
  const notCreatedPlans = planChoices.filter(
    (c) => !societyContractPlans.some((scp) => scp.planId === c.key)
  );

  return (
    <>
      {societyContractPlans.map((item, index) => {
        const planName = planChoices.find(
          (pc) => pc.key === item.planId
        )?.value;
        return (
          <Plan
            key={item.planId}
            societyContractTypeId={societyContractTypeId}
            index={index}
            validator={validator}
            planName={planName}
            formItems={formItems}
            remove={remove}
          />
        );
      })}
      {societyContractPlans.length === 0 &&
        notCreatedPlans.map(({ key: planId, value: planName }) => (
          <input
            key={planId}
            className="c-btn_large c-btn_Search u-mgr_s"
            value={`+ ${planName}プランを追加`}
            type="button"
            onClick={() => appendByPlanId(Number(planId))}
          />
        ))}
    </>
  );
});

export default SaveFormPlans;
