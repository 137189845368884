import { TValidatorResponse } from '@/components/shared/Form/types';
import { fetcher, toMessages } from '../../../../ts/useApi';
import { successToast, errorToast } from '../../../../ts/toast';
import { TUpdateResponse } from './types';
import { alertApiError } from '../../../../ts/formValidation';

// TODO: async中のloading
const update = async (
  actionName: string,
  path: string,
  data: Record<string, unknown>,
  validator: TValidatorResponse,
  setValidator: React.Dispatch<React.SetStateAction<TValidatorResponse>>
): Promise<void> => {
  // TODO: confirm UI
  if (!window.confirm(`${actionName}を行いますか？`)) {
    return;
  }
  try {
    const saveResponse = await fetcher<TUpdateResponse>(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (saveResponse.validator.hasError) {
      setValidator(saveResponse.validator);
      alertApiError(`${actionName}に失敗しました。`);
      return;
    }
    // reload
    successToast(`${actionName}を行いました。`);
    // TODO: 実装変えるかも
    window.location.reload();
  } catch (e) {
    errorToast('エラーが発生しました。');
    setValidator({
      ...validator,
      messages: {
        ex: toMessages(e as Error),
      },
    });
  }
};

export { update };
