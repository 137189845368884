import { isValidationError, renderError, useJsonApi } from '@/ts/useApi';
import { FC } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { IndexPageLayout } from '../../IndexPageLayout';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { useHistory, useLocation } from 'react-router-dom';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import { IndexResponse } from './types';
import { Results } from './Results';
import { SearchForm } from './SearchForm';
import { TFormInputs } from './types';
import { TMutate } from '@/ts/useJsonApi';

const Content: FC<{
  response: IndexResponse;
  queryParams: Record<string, unknown>;
  onSubmit: (form: TFormInputs) => void;
  mutate: TMutate<IndexResponse>;
}> = ({ response, queryParams, onSubmit, mutate }) => {
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          { id: 3, title: '申請経費一覧', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start u-mgb_m">
          <h1 className="l-flex_center c-page_title">申請経費一覧</h1>
        </div>
      </div>
      <SearchForm
        response={response}
        queryParams={queryParams}
        onSubmit={onSubmit}
      />
      {response.data?.payments !== undefined && (
        <Results
          pagination={response.pagination}
          payments={response.data.payments}
          mutate={mutate}
          queryParams={queryParams}
        />
      )}
    </IndexPageLayout>
  );
};

const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(convertQueryToFormData(queryString));
  if (Object.keys(queryParam).filter((name) => name !== 'page').length === 0) {
    return {
      photographingDayStartAt: dayjs().format('YYYY-MM-01'),
    };
  }
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[1-9][0-9]*$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/expenses?${query}`;
};

export const IndexPage: FC = () => {
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));
  const history = useHistory();
  const onSubmit = (formData: unknown) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments/expenses',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };
  if (error) {
    if (isValidationError(error)) {
      return (
        <Content
          response={error.jsonMessage as unknown as IndexResponse}
          queryParams={queryParams}
          onSubmit={onSubmit}
          mutate={mutate}
        />
      );
    }
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <Content
      response={response}
      queryParams={queryParams}
      onSubmit={onSubmit}
      mutate={mutate}
    />
  );
};
