import React, { useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { TValidatorResponse } from '@/components/shared/Form/types';
import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumbersInput,
  RadioBoxes,
  Checkboxes,
} from '@/components/shared/Form/Inputs';
import { TFormInputs, TFormItems } from '../types';
type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};

export const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({
      defaultValues: {
        type: formItems.typesDefaultValue,
        ...queryParams,
      },
    });
    const { handleSubmit } = methods;
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    useEffect(() => {
      Object.values(queryParams).forEach((value) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            setIsSearchOpen(true);
          } else if (!Array.isArray(value)) {
            setIsSearchOpen(true);
          }
        }
      });
    }, [queryParams]);

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <form
            method="GET"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div className="t-bgBox_gray p-photographerOrganizationIndex_searchForm">
              <div className="l-flex_between_center">
                <b>検索条件</b>
                <div
                  className="c-btn_toggleDetails"
                  onClick={() => {
                    setIsSearchOpen((b) => !b);
                  }}
                >
                  <span className="u-spOff">
                    {isSearchOpen ? '開く' : '閉じる'}
                  </span>
                </div>
              </div>
              <div
                className={`p-photographerOrganizationIndex_accordion_content ${
                  isSearchOpen ? '' : 'is-open'
                }`}
              >
                <ul className="l-flex_between">
                  <li className="c-dataLabel">
                    <label>ID</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <NumbersInput
                          name="ids"
                          placeholder="(複数の場合、スペース区切りで入力)"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-flex_between">
                  <li className="c-dataLabel">
                    <label>組織名</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <TextInput
                          name="name"
                          placeholder="（入力例：株式会社〇〇）"
                          validator={validator}
                        />
                      </li>
                      <li className="l-col_8">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            <label>事業者種別</label>
                          </li>
                          <li className="c-dataValue">
                            <RadioBoxes
                              name="type"
                              choices={formItems.types}
                              validator={validator}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-flex_between">
                  <li className="c-dataLabel">
                    <label>適格請求事業者情報</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <TextInput
                          name="business_registration_number"
                          placeholder="番号（入力例：T+13桁数字）"
                          validator={validator}
                        />
                      </li>
                      <li className="c-label_innerHalf">
                        <TextInput
                          name="business_registration_trade_name"
                          placeholder="屋号（国税庁に登録された屋号）"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-flex_between">
                  <li className="c-dataLabel">
                    <label>法人番号</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <TextInput
                          name="corporate_number"
                          placeholder="（入力例：13桁数字）"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-flex_between c-label_line l-flex_between__isSpInput u-mgt_s">
                  <li className="c-dataLabel">
                    <label>稼動状況</label>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex_between">
                      <li className="c-label_innerHalf">
                        <Checkboxes
                          name="statuses"
                          choices={formItems.statuses}
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
              <input
                className="c-btn_large c-btn_Search c-input_submit"
                type="submit"
                value="この内容で検索"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    );
  }
);
