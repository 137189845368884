const DOCUMENT_LIST_ITEMS = {
  documentInformationFromGroup: {
    name: '団体からの連携情報',
    id: 'documentInformationFromGroup',
  },
} as const;

const BASIC_LIST_ITEMS = {
  eventName: {
    name: 'イベント名',
    id: 'eventName',
  },
  requestOptions: {
    name: '申請オプション',
    id: 'requestOption',
  },
  photographingDayCount: {
    name: '撮影日数',
    id: 'photographingDayCount',
  },
  photographingDay: {
    name: '撮影日',
    id: 'photographingDay',
  },
  photographingTime: {
    name: '撮影時間',
    id: 'photographingTime',
  },
  meetingTime: {
    name: '集合時間',
    id: 'meetingTime',
  },
} as const;

const MEETING_PLACE_ITEMS = {
  meetingPlaceDetail: {
    name: '場所詳細',
    id: 'meetingPlaceDetail',
  },
  meetingPlaceAccess: {
    name: '行き方',
    id: 'meetingPlaceAccess',
  },
  meetingPlaceTransportationType: {
    name: '交通機関',
    id: 'meetingPlaceTransportationType',
  },
  meetingPlaceCarParkingLot: {
    name: '駐車場',
    id: 'meetingPlaceCarParkingLot',
  },
  meetingPlaceBicycleParkingLot: {
    name: '駐輪場',
    id: 'meetingPlaceBicycleParkingLot',
  },
} as const;

const PHOTOGRAPHING_PLACE_ITEMS = {
  photographingPlaceDetail: {
    name: '場所詳細',
    id: 'photographingPlaceDetail',
  },
} as const;

const PHOTOGRAPHY_SUBJECTS_ITEMS = {
  photographySubjects: {
    name: '撮影対象者',
    id: 'photographySubjectsItem',
  },
  participantTypes: {
    name: '撮影対象者以外の参加者',
    id: 'participantTypes',
  },
  participantCount: {
    name: '参加者数',
    id: 'participantCount',
  },
  subjectMark: {
    name: '目印',
    id: 'subjectMark',
  },
} as const;

const EVENT_LIST_ITEMS = {
  eventType: {
    name: 'イベント種類',
    id: 'eventType',
  },
  recital: {
    id: 'recital',
  },
  graduationCeremony: {
    id: 'graduationCeremony',
  },
  sportsDay: {
    id: 'sportsDay',
  },
  entranceCeremony: {
    id: 'entranceCeremony',
  },
  halloween: {
    id: 'halloween',
  },
  christmas: {
    id: 'christmas',
  },
  throwingBeans: {
    id: 'throwingBeans',
  },
  mochiPound: {
    id: 'mochiPound',
  },
  summerFestival: {
    id: 'summerFestival',
  },
  pool: {
    id: 'pool',
  },
  sleepover: {
    id: 'sleepover',
  },
  dailyChildcare: {
    id: 'dailyChildcare',
  },
  fieldTrip: {
    id: 'fieldTrip',
  },
  other: {
    id: 'other',
  },
  timeSchedule: {
    name: 'タイムスケジュール',
    id: 'timeSchedule',
  },
  emergencyContacts: {
    name: '当日の緊急連絡先',
    id: 'emergencyContacts',
  },
  teachersInChargeOfPhotography: {
    name: '当日の撮影担当者',
    id: 'teachersInChargeOfPhotography',
  },
  photographerExpense: {
    name: '当日のカメラマンが負担する金額',
    id: 'photographerExpense',
  },
  senNote: {
    name: 'その他情報',
    id: 'senNote',
  },
} as const;

const PHOTOGRAPHY_LIST_ITEMS = {
  prompting: {
    name: '声かけ',
    id: 'prompting',
  },
  arrivalAndDeparturePhoto: {
    name: '登降時撮影',
    id: 'arrivalAndDeparturePhoto',
  },
  lunchPhoto: {
    name: '昼食撮影',
    id: 'lunchPhoto',
  },
  signboardPhoto: {
    name: '看板前撮影',
    id: 'signboardPhoto',
  },
  groupPhoto: {
    name: '集合写真撮影',
    id: 'groupPhoto',
  },
  transportation: {
    name: '撮影中の交通機関での移動',
    id: 'transportation',
  },
  specifiedPosition: {
    name: '撮影立ち位置指定',
    id: 'specifiedPosition',
  },
  ngPhotographySubject: {
    name: '撮影NG対象',
    id: 'ngPhotographySubject',
  },
  rest: {
    name: '休憩（昼食）',
    id: 'rest',
  },
  albumOption: {
    name: 'アルバム用撮影',
    id: 'albumOption',
  },
  equipments: {
    name: '機材',
    id: 'equipments',
  },
  belongings: {
    name: '持ち物',
    id: 'belongings',
  },
  tripod: {
    name: '三脚使用',
    id: 'tripodPermitted',
  },
  rentalStepladder: {
    name: '脚立・踏み台の貸し出し',
    id: 'rentalStepladderAvailable',
  },
  societyRequest: {
    name: '団体からの撮影要望',
    id: 'societyRequest',
  },
} as const;

const DEALING_WITH_UNEXPECTED_SITUATIONS_ITEMS = {
  rainyDayAction: {
    name: '雨天時の対応',
    id: 'rainyDayAction',
  },
  cancelDecision: {
    name: '中止／撮影内容変更 連絡',
    id: 'cancelDecision',
  },
} as const;

export const LIST_ITEMS = {
  document: DOCUMENT_LIST_ITEMS,
  basic: BASIC_LIST_ITEMS,
  meetingPlace: MEETING_PLACE_ITEMS,
  photographingPlace: PHOTOGRAPHING_PLACE_ITEMS,
  photographySubjects: PHOTOGRAPHY_SUBJECTS_ITEMS,
  event: EVENT_LIST_ITEMS,
  photography: PHOTOGRAPHY_LIST_ITEMS,
  dealingWithUnexpectedSituations: DEALING_WITH_UNEXPECTED_SITUATIONS_ITEMS,
} as const;

export const PARENT_LIST_ITEMS = {
  document: {
    name: '資料',
    id: 'document',
  },
  basic: {
    name: '基本情報',
    id: 'basic',
  },
  meetingPlace: {
    name: '集合場所',
    id: 'meetingPlace',
  },
  photographingPlace: {
    name: '撮影場所',
    id: 'photographingPlace',
  },
  photographySubjects: {
    name: '撮影対象者',
    id: 'photographySubjects',
  },
  event: {
    name: 'イベントにおける情報',
    id: 'event',
  },
  photography: {
    name: '撮影における情報',
    id: 'photography',
  },
  dealingWithUnexpectedSituations: {
    name: '不測の事態の対応情報',
    id: 'dealingWithUnexpectedSituations',
  },
  photographyCaution: {
    name: '撮影時の注意事項',
    id: 'photographyCaution',
  },
} as const;
