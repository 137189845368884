import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';

import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import { TFormInputs, TSaveResponse } from '../Create/types';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import SaveForm from '../Create/SaveForm';
import { TEditResponse, TValidateResponse } from './types';
import { errorToast, successToast } from '../../../../ts/toast';
import { dateFormatHyphen } from '../../../../ts/formatTools';
import { OPENFLAG_CLOSE, OPENFLAG_NO_PUBLISH } from '../constants';
import EventHeader from '../EventHeader';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { society_contract_id, plan_id, event_no } = useParams<{
    society_contract_id: string;
    plan_id: string;
    event_no: string;
  }>();
  const { data, error } = useJsonApi<TEditResponse>(
    '/api/society_contracts/' +
      society_contract_id +
      '/plans/' +
      plan_id +
      '/events/' +
      event_no +
      '/edit'
  );
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  const requireValidatePhotographs = (
    formData: Partial<TFormInputs>
  ): boolean =>
    formData.openflag !== event.openflag &&
    formData.openflag !== OPENFLAG_CLOSE &&
    formData.openflag !== OPENFLAG_NO_PUBLISH;

  const validatePhotographs = async (): Promise<boolean> => {
    try {
      const response = await fetcher<TValidateResponse>(
        `/api/events/${event_no}/photographs/validate`
      );
      if (response.data.invalid) {
        return window.confirm(
          'チェックでNGになった写真が' +
            response.data.invalidCount +
            '点あります。\n' +
            '本当にステータスを変更してよろしいですか？\n' +
            '※NG内容の詳細はフォトビューワーでご確認ください。'
        );
      }
      return true;
    } catch (e) {
      errorToast('写真バリデーション処理でエラーが発生しました');
      setErrorMessages(toMessages(e));
      return false;
    }
  };

  const onSubmit: SubmitHandler<TFormInputs> = usePreventDuplicateCall(
    async (formData) => {
      try {
        if (requireValidatePhotographs(formData)) {
          if (!(await validatePhotographs())) {
            return;
          }
        }
        const saveResponse = (await fetcher(
          `/api/society_contracts/${society_contract_id}/plans/${plan_id}/events/${event_no}/update`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          }
        )) as TSaveResponse;
        setErrorMessages([]);
        if (saveResponse.validator.hasError) {
          setValidator(saveResponse.validator);
          alertApiError();
          return;
        }
        successToast('更新しました');
        history.push(`/events/${saveResponse.data.eventno}`);
      } catch (e) {
        setErrorMessages(toMessages(e));
        errorToast('エラーが発生しました');
      }
    }
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const {
    event,
    certificationEventHas,
    eventRequestOptions,
    editable,
    societyInfo,
  } = data.data;

  // イベントの価格が未定かつ団体の基本価格があれば基本価格をデフォルトにする
  const defaultPricePattern = data.formItems.pricepattern.pricePatterns.find(
    (e) => e.defaultpriceflag === '1'
  );
  const defaultPrice =
    String(event.pricepatternno) === '0' &&
    data.formItems.pricepattern.pricePatterns.length !== 0
      ? String(defaultPricePattern?.pricepatternno)
      : String(event.pricepatternno);

  const defaultValues: Partial<TFormInputs> = {
    previousyeareventno: event.previousyeareventno,
    eventno: event.eventno,
    eventname: event.eventname,
    eventtype: event.eventtype,
    photographingday: dayjs(event.photographingday).format('YYYY-MM-DD'),
    eventrequestoptions: eventRequestOptions,
    cancelflag: Number(event.cancelflag),
    cancelShootingReason: event.cancel_shooting_reason,
    cancelShootingReasonDetail: event.cancel_shooting_reason_detail,
    cancelShootingReasonDetailOther: event.cancel_shooting_reason_detail_other,
    openflag: Number(event.openflag),
    mojiireday: event.mojiireday ?? undefined,
    valuestartday: dateFormatHyphen(event.valuestartday, null) ?? undefined,
    valueendday: dateFormatHyphen(event.valueendday, null) ?? undefined,
    publishendday: dateFormatHyphen(event.publishendday, null) ?? undefined,
    leaderendday: dateFormatHyphen(event.leaderendday, null) ?? undefined,
    convertinfono: event.convertinfono,
    certificationkeyno:
      certificationEventHas === undefined ? '0' : certificationEventHas,
    participants: event.participants,
    chirashicount: event.chirashicount,
    chirashisenddate:
      dateFormatHyphen(event.chirashisenddate, null) ?? undefined,
    sbsenddate: dateFormatHyphen(event.sbsenddate, null) ?? undefined,
    faxsenddate: dateFormatHyphen(event.faxsenddate, null) ?? undefined,
    dvdsenddate: dateFormatHyphen(event.dvdsenddate, null) ?? undefined,
    sbjizensenddate: dateFormatHyphen(event.sbjizensenddate, null) ?? undefined,
    alignment: Number(event.alignment),
    faceRecommendFlag: Number(event.face_recommend_flag),
    pricepatternno: defaultPrice,
    memo: event.memo,
    opMemo: event.op_memo,
    nippobodies: event.nippobodies,
    autoeventtermsettingflag: Number(event.autoeventtermsettingflag),
    openEditPageTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">イベント - 編集</span>
      </h3>
      <EventHeader
        society={{
          id: societyInfo.society.id,
          name: societyInfo.society.name,
        }}
        event={{
          id: event.eventno,
          name: event.eventname,
        }}
        isDisplayEventAsText
      />
      <div className="l-center_wrap">
        <SaveForm
          eventno={event.eventno}
          validator={validator}
          formItems={data.formItems}
          defaultValues={defaultValues}
          editable={editable}
          societyInfo={societyInfo}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="更新する"
          cancelLink={`/events/${event_no}`}
        />
      </div>
    </div>
  );
};

export default App;
