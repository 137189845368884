import { get as getPostalCode } from 'japan-postal-code';
import React, { useEffect } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  TextInput,
  SingleSelect,
  GroupedSingleSelect,
  NumberInput,
  TextArea,
  RadioBoxes,
  GroupedMultiSelect,
} from '@/components/shared/Form/Inputs';
import {
  TChoice,
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TFormInputs, TFormItems } from './types';

const month: TChoice[] = [
  { key: 1, value: '1月' },
  { key: 2, value: '2月' },
  { key: 3, value: '3月' },
  { key: 4, value: '4月' },
  { key: 5, value: '5月' },
  { key: 6, value: '6月' },
  { key: 7, value: '7月' },
  { key: 8, value: '8月' },
  { key: 9, value: '9月' },
  { key: 10, value: '10月' },
  { key: 11, value: '11月' },
  { key: 12, value: '12月' },
];

const AUTO_NOTICE_CERTIFICATIONKEY_TYPE_ENABLE = 1;

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSaveFormProps = {
  societyId?: number;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
  defaultValues: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    societyId,
    validator,
    formItems,
    defaultValues,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const methods = useForm<Required<TFormInputs>>({ defaultValues });
    const { fields, append, remove } = useFieldArray({
      name: 'studentInfos',
      control: methods.control,
    });
    const setAddressByPostcode = () => {
      const postcode = String(methods.getValues('postcode'));
      getPostalCode(postcode, (address) => {
        formItems.prefectures.some((area) => {
          return area.choices.some((prefecture) => {
            if (prefecture.value === address.prefecture) {
              methods.setValue('prefectureId', Number(prefecture.key));
              return true;
            }
            return false;
          });
        });
        methods.setValue('city', address.city);
        methods.setValue('address', address.area);
      });
    };
    const isRequiredAcceptanceByLeader = methods.watch(
      'isRequiredAcceptanceByLeader'
    );
    const watchSocietyName = methods.watch('name');
    const autoNoticeCertificationkeyType = methods.watch(
      'autoNoticeCertificationkeyType'
    );

    useEffect(() => {
      if (methods.formState.dirtyFields.name) {
        methods.setValue('viewname', watchSocietyName);
      }
    }, [watchSocietyName, methods]);
    useEffect(() => {
      if (
        autoNoticeCertificationkeyType ===
        AUTO_NOTICE_CERTIFICATIONKEY_TYPE_ENABLE
      ) {
        methods.setValue('isEnabledCreateKeyUsableWithoutLogin', 1);
      }
    }, [autoNoticeCertificationkeyType, methods]);

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            {societyId && (
              <>
                <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                  団体ID: {societyId}
                </span>
                <hr className="u-line_plane" />
              </>
            )}
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>団体名</label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="name"
                  placeholder="団体名"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>団体名(かな)</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="namekana"
                  placeholder="団体名(かな)"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input">
              <li className="c-dataLabel">
                <label>表示名称</label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="viewname"
                  placeholder="表示名称"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>団体区分</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="fundingTypeId"
                      choices={formItems.society.fundingType}
                      placeholder="団体区分"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>団体種類</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="groupTypeId"
                      choices={formItems.society.groupType}
                      placeholder="団体種類"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>郵便番号</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <div className="l-flex">
                      <div className="c-label_innerHalf__isPostcode">
                        <TextInput
                          name="postcode"
                          placeholder="123-4567"
                          validator={validator}
                        />
                      </div>
                      <div className="c-label_innerHalf__isPostcode">
                        <input
                          className="c-btn_large c-btn_Search"
                          value="検索"
                          type="button"
                          onClick={setAddressByPostcode}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>都道府県</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <GroupedSingleSelect
                      name="prefectureId"
                      choices={formItems.prefectures}
                      placeholder="都道府県"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>緯度/経度</label>
                    <br />
                    <small>※手配自動アサイン処理で使用します</small>
                  </li>
                  <li className="c-dataValue">
                    <ul className="l-flex">
                      <li className="u-pdr_s">
                        {/* NOTE: 小数点について細かい制御をしたくないのでTextにしてます */}
                        <TextInput
                          name="lat"
                          placeholder="緯度"
                          validator={validator}
                        />
                      </li>
                      <li>
                        <TextInput
                          name="lng"
                          placeholder="経度"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  住所
                  <br className="sp_off" />
                  <small>（市区町村）</small>
                  <small className="c-required">(必須)</small>
                </label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="city"
                  placeholder="市区町村"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  住所
                  <br className="sp_off" />
                  <small>（市区町村以降）</small>
                  <small className="c-required">(必須)</small>
                </label>
              </li>
              <li className="c-dataValue">
                <TextInput
                  name="address"
                  placeholder="市区町村以降"
                  validator={validator}
                />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>電話番号</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="telephonenumber"
                      placeholder="03-1234-5678"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>FAX番号</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="faxnumber"
                      placeholder="03-1234-5678"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      メール
                      <br className="sp_off" />
                      アドレス
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="mailaddress"
                      placeholder="メールアドレス"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>団体URL</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="url"
                      placeholder="「http」または「https」から始まるURLのみを入力してください"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>法人格フラグ</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isLegalEntity"
                      choices={formItems.society.isLegalEntity}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>お手持ち写真プリント使用可否</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isSalableTsuide"
                      choices={formItems.society.isSalableTsuide}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>年度開始月</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="startMonthOfFiscalYear"
                      choices={month}
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>最寄り駅</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="nearestStation"
                      placeholder=""
                      validator={validator}
                      additionalClassName="c-input_withUnit"
                    />
                    駅
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>最寄バス停</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="nearestBusStop"
                      placeholder=""
                      validator={validator}
                      additionalClassName="c-input_withUnit"
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  所在地への
                  <br className="sp_off" />
                  行き方
                </label>
              </li>
              <li className="c-dataValue">
                <TextArea name="howToGo" validator={validator} rows={10} />
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>所属人数</label>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="numberOfBelonging"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>家庭数</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="numberOfFamily"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>定員数(合計)</label>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="numberOfChildrenCapacity"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>写真閲覧キー問い合わせ</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="autoNoticeCertificationkeyType"
                      choices={formItems.society.autoNoticeCertificationkeyType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>オープン写真閲覧キー作成</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {autoNoticeCertificationkeyType ===
                    AUTO_NOTICE_CERTIFICATIONKEY_TYPE_ENABLE ? (
                      '可'
                    ) : (
                      <RadioBoxes
                        name="isEnabledCreateKeyUsableWithoutLogin"
                        choices={
                          formItems.society.isEnabledCreateKeyUsableWithoutLogin
                        }
                        validator={validator}
                      />
                    )}
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>イベント期間自動設定</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledAutoEventPeriodSetting"
                      choices={
                        formItems.societyDirects.isEnabledAutoEventPeriodSetting
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      写真閲覧キー
                      <br className="sp_off" />
                      家族招待
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="isEnabledCertificationKeyShareable"
                      choices={
                        formItems.societyShareable
                          .isEnabledCertificationKeyShareableStatuses
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  責任者承認
                  <br className="sp_off" />
                  機能
                </label>
                <small className="c-required">(必須)</small>
              </li>
              <li className="c-dataValue">
                {societyId ? (
                  <RadioBoxes
                    name="isRequiredAcceptanceByLeader"
                    choices={formItems.society.isRequiredAcceptanceByLeader}
                    validator={validator}
                  />
                ) : (
                  <>
                    不要
                    <br />
                    <small className="t-textColor_sub">
                      ※責任者承認を「必要」に設定する場合、契約の登録が必要です。
                    </small>
                  </>
                )}
              </li>
            </ul>
            {!!isRequiredAcceptanceByLeader && (
              <div>
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>学校からの質問1</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="question1"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>学校からの質問2</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="question2"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>学校からの質問3</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="question3"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="c-label_line is-sp_input l-flex">
                  <li className="c-dataLabel">
                    <label>承認を担当する契約責任者</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {!!formItems.contracts ? (
                      formItems.contracts.length ? (
                        <SingleSelect
                          name="societyContractIdForAcceptance"
                          choices={formItems.contracts}
                          placeholder=""
                          validator={validator}
                          optionalValidationRule={{ required: true }}
                        />
                      ) : (
                        '契約がありません'
                      )
                    ) : (
                      '登録できません'
                    )}
                  </li>
                </ul>
              </div>
            )}
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      クラス名
                      <br className="sp_off" />
                      リスト
                    </label>
                    {!!isRequiredAcceptanceByLeader && (
                      <small className="c-required">(必須)</small>
                    )}
                  </li>
                  <li className="c-dataValue">
                    <span className="t-textColor_sub">
                      ※1クラスごとに改行してください、1行あたり10文字以内で入力してください
                    </span>
                    <TextArea
                      name="classnames"
                      validator={validator}
                      rows={10}
                      // optionalValidationRule={{
                      //   required: !!isRequiredAcceptanceByLeader,
                      // }}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>長期休み情報</label>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="vacationPrecaution"
                      validator={validator}
                      rows={10}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      内定者・
                      <br className="sp_off" />
                      社カメの研修
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="assigningInternalPhotographerTraineeType"
                      choices={
                        formItems.societyDirects
                          .assigningInternalPhotographerTraineeType
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>相カメの研修</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="assigningExternalPhotographerTraineeType"
                      choices={
                        formItems.societyDirects
                          .assigningExternalPhotographerTraineeType
                      }
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_between c-label_line is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>詳細対応者</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="photographerId"
                      choices={formItems.internalPhotographers}
                      placeholder="詳細対応者"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>
                      詳細対応者
                      <br className="sp_off" />
                      変更履歴
                    </label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="photographerChangeHistory"
                      placeholder=""
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  カメラマン
                  <br className="sp_off" />
                  希望
                </label>
              </li>
              <li className="c-dataValue">
                <TextArea
                  name="requestedPhotographerMemo"
                  validator={validator}
                  rows={10}
                />
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  指定
                  <br className="sp_off" />
                  カメラマン
                </label>
              </li>
              <li className="c-dataValue">
                <GroupedMultiSelect
                  name="desiredPhotographerIds"
                  choices={formItems.groupedPhotographers}
                  placeholder="指定カメラマン（複数可）"
                  validator={validator}
                  windowed={true}
                />
              </li>
            </ul>
            <ul className="c-label_line is-sp_input l-flex">
              <li className="c-dataLabel">
                <label>
                  園児・
                  <br className="sp_off" />
                  生徒情報
                </label>
              </li>
              <li className="c-dataValue">
                {fields.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <div className="t-bgBox_brown u-pdb_xs">
                      <ul>
                        <li className="c-dataValue">
                          <div className="l-flex_center l-col_wrap is-sp_mode">
                            <div className="l-col_6 u-mgr_s">
                              <SingleSelect
                                name={`studentInfos.${index}.fiscalYear`}
                                choices={formItems.society.fiscalYear}
                                placeholder="年度"
                                validator={validator}
                              />
                            </div>
                            <button
                              className="c-btn_rectangle c-btn_delete u-mgr_s"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <i className="c-icon_Xsmall c-icon_trash" />
                            </button>
                          </div>
                        </li>
                      </ul>
                      <div className="c-label_line">
                        <TextArea
                          name={`studentInfos.${index}.summary`}
                          validator={validator}
                          rows={5}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                <input
                  className="c-btn_large c-btn_Search"
                  value="+ 生徒情報を追加"
                  type="button"
                  onClick={() => append({ summary: '' })}
                />
              </li>
            </ul>
            <div className="u-align_center u-mgb_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
