import React from 'react';
import { OrderInfo as OrderInfoData, PhotoSummary } from '../types';
import { dateTimeFormat, numberFormat } from '@/ts/formatTools';
import { format } from 'date-fns';

type Props = {
  orderInfo: OrderInfoData;
  summary: PhotoSummary;
};

export const OrderInfo = React.memo<Props>(({ orderInfo, summary }) => {
  return (
    <>
      <div className="l-flex_between_center u-mgb_s">
        <h4 className="c-section_title">注文情報</h4>
        <div className="l-flex">
          <div className="u-mgr_s">
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              注文日
            </span>
            <span className="u-fz_xs">
              {dateTimeFormat(orderInfo.createdAt)}
            </span>
          </div>
        </div>
      </div>

      <div className="u-mgt_s u-mgb_m">
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">会員ID</li>
              <li className="c-dataValue">{orderInfo.userAccountNo}</li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">名前</li>
              <li className="c-dataValue">
                {orderInfo.firstName} {orderInfo.secondName}様
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">注文日</li>
              <li className="c-dataValue">
                {format(new Date(orderInfo.createdAt), 'yyyy/MM/dd HH:mm:ss')}
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">入金日</li>
              <li className="c-dataValue">
                {format(new Date(orderInfo.paymentDate), 'yyyy/MM/dd HH:mm:ss')}
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">決済方法</li>
              <li className="c-dataValue">{orderInfo.paymentTypeName}</li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">ステータス</li>
              <li className="c-dataValue">{orderInfo.paymentStatusName}</li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">写真</li>
              <li className="c-dataValue">
                合計　{summary.count}枚<br />
                内訳
                <br />
                {Object.keys(summary.countBySize).map((photoSum: string) => (
                  <div key={photoSum}>
                    {photoSum.toUpperCase()} {summary.countBySize[photoSum]}枚
                  </div>
                ))}
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">写真小計</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.photoSum)}円
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">送料</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.carriage)}円
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">税金額</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.taxAmount)}円
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">代引手数料</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.collectCarriage)}円
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">値引き</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.discount)}円
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">合計金額</li>
              <li className="c-dataValue">{numberFormat(orderInfo.total)}円</li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">クーポン値引き額</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.couponDiscount)}円
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">フォトマネー使用</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.usePoint)}
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">お支払金額</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.totalPayment)}円
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">還元フォトマネー(翌日に反映)</li>
              <li className="c-dataValue">
                {numberFormat(orderInfo.reductionPoint)}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
});
