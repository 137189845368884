import React, { useCallback, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import {
  TIndexResponse,
  TSalesForecastSocietySales,
  TSalesForecastResponse,
  TGroupSalesInfo,
  TSalesForecasts,
} from './types';
import SalesForecastTable from './SalesForecastTable';
import SocietyHeader from '../SocietyHeader';
import './salesForecastIndex.scss';
import SvgLoading from '../../../shared/Loading/SvgLoading';
import LoadingImg from '../../../shared/Loading/loading.svg';
import '../../../shared/Loading/loading.scss';
import dayjs from 'dayjs';

const GraphArea: React.FC<{
  quickSightUrl: string;
}> = React.memo(({ quickSightUrl }) => {
  const [isShow, setCount] = useState(false);
  const handleClick = useCallback((isShow: boolean) => setCount(isShow), []);

  return (
    <div>
      <p>
        <button onClick={() => handleClick(!isShow)} disabled>
          {isShow ? 'グラフを閉じる' : 'グラフを表示する'}
        </button>
        ※準備中のためまだ表示できません。
      </p>
      <iframe
        width="960"
        height="720"
        src={quickSightUrl}
        title="quicksight"
        style={{ display: isShow ? '' : 'none' }}
      />
    </div>
  );
});

const SvgLoadingForContractView: React.FC = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        style={{
          marginTop: '2em',
          width: '5%',
          height: '5%',
        }}
        src={LoadingImg}
        alt="Icon"
      />
      <div className="loading-text">loading...</div>
    </div>
  );
};

const SocietyContractView: React.FC<{
  groupSalesInfo: TGroupSalesInfo;
  activeTabIndex: number;
  selectedFiscalYear: string;
}> = React.memo(({ groupSalesInfo, activeTabIndex, selectedFiscalYear }) => {
  const { data: result, error } = useJsonApi<TSalesForecastResponse>(
    `/api/societies/society_contracts/${groupSalesInfo.societyContractPlans[activeTabIndex].societyContractId}/plans/${groupSalesInfo.societyContractPlans[activeTabIndex].planId}/sales_forecasts/detail/${selectedFiscalYear}`
  );
  if (error) {
    return renderError(error);
  }
  if (!result) {
    return <SvgLoadingForContractView />;
  }

  const { societyContracts } = result.data;

  return (
    <div className="tab-content">
      {groupSalesInfo.societyContractPlans.map(
        (salesForecastContainer, index) =>
          activeTabIndex === index && (
            <div key={index}>
              <div className="l-flex_align_start l-flex_between u-mgt_s">
                <div>
                  <h4 className="c-section_title">
                    NO.{index + 1}_
                    {salesForecastContainer.planId === 1 ? '直販' : ''}
                  </h4>
                  <p>
                    ※昨年度イベント実績がない場合は、都道府県平均で売上予測を算出しています。
                  </p>
                </div>
                {societyContracts.salesForecasts.length >= 1 && (
                  <SocietyContractForecastSummary
                    salesForecasts={societyContracts.salesForecasts}
                    isSmall={true}
                  />
                )}
              </div>

              <SalesForecastTable
                salesForecasts={societyContracts.salesForecasts}
                societyContractId={societyContracts.societyContractId}
                planId={societyContracts.planId}
              />
            </div>
          )
      )}
    </div>
  );
});

const ForecastSummary: React.FC<{
  salesForecasts: TSalesForecastSocietySales;
  isSmall?: boolean;
}> = React.memo(({ salesForecasts, isSmall }) => {
  return (
    <div
      className={
        'c-frame p-salesForecastsIndex-bg-gray p-totalForecastsArea u-mgb_0 ' +
        (isSmall ? 'p-totalForecastsArea__small' : '')
      }
    >
      <div className="u-mgr_m">
        <p className="p-totalForecastsArea_title">昨年度総実績</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(salesForecasts.previousYearGroupAnnualSales)}
        </p>
      </div>
      <div className="u-mgr_m">
        <p className="p-totalForecastsArea_title">予想合計(手入力)</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(salesForecasts.currentYearManuallyEnteredSalesForecast)}
        </p>
      </div>
      <div className="u-mgr_s">
        <p className="p-totalForecastsArea_title">予想合計(自動)</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(
            salesForecasts.currentYearAutomaticallyCalculatedSalesForecast
          )}
        </p>
      </div>
    </div>
  );
});

const SocietyContractForecastSummary: React.FC<{
  salesForecasts: TSalesForecasts[];
  isSmall?: boolean;
}> = React.memo(({ salesForecasts, isSmall }) => {
  return (
    <div
      className={
        'c-frame p-salesForecastsIndex-bg-gray p-totalForecastsArea u-mgb_0 ' +
        (isSmall ? 'p-totalForecastsArea__small' : '')
      }
    >
      <div className="u-mgr_m">
        <p className="p-totalForecastsArea_title">昨年度総実績</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(
            societyContractPreviousYearSalesForecastTotal(salesForecasts)
          )}
        </p>
      </div>
      <div className="u-mgr_m">
        <p className="p-totalForecastsArea_title">予想合計(手入力)</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(
            societyContractCurrentYearManuallyEnteredSalesForecastTotal(
              salesForecasts
            )
          )}
        </p>
      </div>
      <div className="u-mgr_s">
        <p className="p-totalForecastsArea_title">予想合計(自動)</p>
        <p className="p-totalForecastsArea_price">
          {formatMoney(
            societyCurrentYearCostRateForecastWithManuallyEnteredSalesForecastTotal(
              salesForecasts
            )
          )}
        </p>
      </div>
    </div>
  );
});

const societyContractPreviousYearSalesForecastTotal = (
  salesForecasts: TSalesForecasts[]
): number => {
  const previousYearDeduplicatedSalesForecasts = salesForecasts.filter(
    (salesForecast, index, array) =>
      array.findIndex(
        (targetSalesForecast) =>
          targetSalesForecast.previousYearEventNo ===
          salesForecast.previousYearEventNo
      ) === index
  );
  return previousYearDeduplicatedSalesForecasts.reduce(
    (sum, salesForecast) =>
      !salesForecast.previousYearEventNo
        ? sum
        : sum + Number(salesForecast.previousYearSales),
    0
  );
};

const societyContractCurrentYearManuallyEnteredSalesForecastTotal = (
  salesForecasts: TSalesForecasts[]
): number => {
  return salesForecasts.reduce(
    (sum, salesForecast) =>
      !salesForecast.currentYearEventNo
        ? sum
        : sum + Number(salesForecast.currentYearManuallyEnteredSalesForecast),
    0
  );
};

const societyCurrentYearCostRateForecastWithManuallyEnteredSalesForecastTotal =
  (salesForecasts: TSalesForecasts[]): number => {
    return salesForecasts.reduce(
      (sum, salesForecast) =>
        !salesForecast.currentYearEventNo
          ? sum
          : sum +
            Number(
              salesForecast.currentYearAutomaticallyCalculatedSalesForecast
            ),
      0
    );
  };

export const formatMoney = (num: number | null): string => {
  if (num === null) {
    return '';
  }
  if (num < 0) {
    return '△¥' + Number(Math.abs(num)).toLocaleString();
  } else {
    return '¥' + Number(num).toLocaleString();
  }
};

const App: React.FC = () => {
  const { societyId } = useParams<{
    societyId: string;
  }>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const now = dayjs();
  const currentFiscalYear = now.subtract(3, 'month').year();
  const nextFiscalYear = currentFiscalYear + 1;

  /**
   * @see https://hoiku-ict.backlog.com/view/8122PHOTO-3683
   */
  const defaultFiscalYear =
    now.month() === 2 ? nextFiscalYear : currentFiscalYear;

  const [selectedFiscalYear, setSelectedFiscalYear] = useState(
    defaultFiscalYear.toString()
  );

  const { data: result, error } = useJsonApi<TIndexResponse>(
    `/api/societies/${societyId}/sales_forecasts/${selectedFiscalYear}`
  );
  if (error) {
    return renderError(error);
  }
  if (!result) {
    return <SvgLoading />;
  }

  const { society, groupSalesInfo, quickSightUrl } = result.data;

  return (
    <div className="p-groupsSummary">
      <Link className="c-page_back" to={`/societies/${societyId}`}>
        団体サマリへ
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">売上予測画面</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <div className="l-flex_align_start l-flex_between">
              <div className="u-mgb_m">
                <SocietyHeader
                  societyId={society.id}
                  societyName={society.name}
                  selectedFiscalYear={selectedFiscalYear}
                  setSelectedFiscalYear={setSelectedFiscalYear}
                />
              </div>
              <ForecastSummary salesForecasts={groupSalesInfo} />
            </div>

            <div className="u-mgt_m">
              <GraphArea
                quickSightUrl={quickSightUrl + `#p.societyId=${societyId}`}
              />
            </div>

            <ul
              role="tablist"
              className="c-tabList p-societies_contractTab u-mgt_m"
            >
              {groupSalesInfo.societyContractPlans.map(
                (salesForecastContainer, index) => (
                  <li
                    key={index}
                    className={
                      'p-contractTabListItem ' +
                      (activeTabIndex === index && 'is-active')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTabIndex(index);
                    }}
                  >
                    <b>
                      NO.{index + 1}_
                      {salesForecastContainer.societyContractTypeName}
                    </b>
                  </li>
                )
              )}
            </ul>
            <SocietyContractView
              groupSalesInfo={groupSalesInfo}
              activeTabIndex={activeTabIndex}
              selectedFiscalYear={selectedFiscalYear}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
