import React, { useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import QueryString from 'query-string';

import { convertQueryToFormData } from '@/ts/makePathForSearchForm';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { TShowShortResponse, TUseraccountsResponse } from '../types';
import Useraccounts from './Useraccounts';

const PER_PAGE = 100;

const App: React.FC = () => {
  // TODO: componentごとにloadするUIがないので一旦ココでまとめて取っています。あとでなおしたい
  const searchString = useLocation().search;

  // certificationkey
  const { id } = useParams<{ id: string }>();
  const {
    data: certificationkeyData,
    error: certificationkeyError,
    mutate: mutateCertificationkey,
  } = useJsonApi<TShowShortResponse>(`/api/certificationkeys/${id}/short`);

  // useraccounts
  const initialQueryParams = convertQueryToFormData(searchString);
  initialQueryParams.perPage = PER_PAGE;
  if (!initialQueryParams.bundles) {
    initialQueryParams.bundles = 'bundled';
  }
  const queryString =
    '?' + QueryString.stringify(initialQueryParams, { arrayFormat: 'bracket' });
  const {
    data: useraccountsData,
    error: useraccountsError,
    mutate: mutateUseraccounts,
  } = useJsonApiForSearchForm<TUseraccountsResponse>(
    `/api/certificationkeys/${id}/useraccounts`,
    queryString
  );

  const refetch = useCallback(() => {
    mutateCertificationkey();
    mutateUseraccounts();
  }, [mutateCertificationkey, mutateUseraccounts]);

  if (certificationkeyError) {
    return renderError(certificationkeyError);
  } else if (useraccountsError) {
    return renderError(useraccountsError);
  }
  if (!certificationkeyData || !useraccountsData) {
    return <SvgLoading />;
  }

  const {
    data: certificationkey,
    conditions: { isRequiredAcceptanceByLeader, canLinkToUsers },
  } = certificationkeyData;

  return (
    <>
      <Link className="c-page_back" to={`/certificationkeys/${id}`}>
        写真閲覧キーサマリに戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">
        写真閲覧キー会員一覧
      </h1>

      <div className="l-center_wrap">
        <div className="c-frame">
          <div className="l-flex_between_center">
            <div>
              <span className="c-label c-label__monotone u-fz_xs">
                写真閲覧キーID：{certificationkey.id}
              </span>
              <span className="u-mgl_s c-page_title">
                {certificationkey.key}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Useraccounts
        id={certificationkey.id}
        type={certificationkey.type}
        isRequiredAcceptanceByLeader={isRequiredAcceptanceByLeader}
        canLinkToUsers={canLinkToUsers}
        queryString={queryString}
        useraccountsData={useraccountsData}
        refetch={refetch}
      />
    </>
  );
};

export default App;
