import {
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACKGROUND_PAPER_STAND,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_MONOBLOCK,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
} from '@/components/pages/Events/Show/Photography/const';

export const getUnchangeableEquipmentTypes = (): number[] => [
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_MONOBLOCK,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACKGROUND_PAPER_STAND,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE,
];
