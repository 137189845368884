import React, { MouseEventHandler } from 'react';

const useCopyToClipboard = (
  text: string
): [boolean, MouseEventHandler<HTMLAnchorElement>] => {
  const copyToClipboard = (text: string) => {
    // 隠しelementを挿入
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    // 元のフォーカスをbackup
    let selected: Range | false = false;
    if (
      document.getSelection() !== null &&
      document.getSelection()!.rangeCount > 0
    ) {
      selected = document.getSelection()!.getRangeAt(0);
    }
    // copy
    el.select();
    const success = document.execCommand('copy');
    // element削除
    document.body.removeChild(el);
    if (selected) {
      document.getSelection()!.removeAllRanges();
      document.getSelection()!.addRange(selected);
    }
    return success;
  };

  const [copied, setCopied] = React.useState(false);
  const copy = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();
      if (copied) {
        setCopied(false);
      } else {
        setCopied(copyToClipboard(text));
      }
    },
    [text, copied]
  );
  // TODO: いらなそうなのでコメントアウトしておきます。必要なら戻して
  // React.useEffect(() => () => setCopied(false), [text]);

  return [copied, copy];
};

type TProps = {
  text: string;
};
const TextCopy: React.FC<TProps> = ({ text }) => {
  const [copied, copy] = useCopyToClipboard(text);

  return (
    <a className="c-clipboardCopy" onClick={copy} href="/">
      <input type="checkbox" />
      {copied ? (
        <span className="c-clipboardCopy__isChecked">
          <i className="c-icon_success"></i>
          <span className="c-clipboardCopy_tooltip">コピーしました</span>
        </span>
      ) : (
        <i className="c-icon_copy"></i>
      )}
    </a>
  );
};

export default TextCopy;
