import React from 'react';
import { TInvitationMailForm } from '../types';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { TextArea } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { useHistory } from 'react-router-dom';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSaveFormProps = {
  ownerno: string;
  confirmflag: boolean;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  defaultValues: Record<string, unknown>;
  onSubmit: SubmitHandler<TInvitationMailForm>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
};

const MAIL_BODY_ROWS = 30;

const InputSubject: React.FC<{
  name: string;
  disabled: boolean;
}> = ({ name, disabled }) => {
  const { register } = useFormContext();
  return (
    <>
      <input
        type="text"
        className="c-input_plane"
        placeholder="件名"
        disabled={disabled}
        {...register(name)}
      />
    </>
  );
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    ownerno,
    confirmflag,
    validator,
    defaultValues,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const methods = useForm<Required<TInvitationMailForm>>({ defaultValues });
    const history = useHistory();
    const watchTitle = methods.watch('subject');
    const watchBody = methods.watch('body');

    const movetToInput = function () {
      history.push({
        pathname: `/owneraccounts/${ownerno}/invitation_mail/input`,
        state: { subject: watchTitle, body: watchBody },
      });
    };

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label htmlFor="subject">件名</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <InputSubject name="subject" disabled={confirmflag} />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">
                    <label>本文</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextArea
                      name="body"
                      placeholder="本文"
                      validator={validator}
                      disabled={confirmflag}
                      rows={MAIL_BODY_ROWS}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <div className="u-align_center u-mgb_m">
              {confirmflag ? (
                <button
                  type="button"
                  className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                  onClick={movetToInput}
                >
                  戻る
                </button>
              ) : (
                <a
                  className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                  href={cancelLink}
                >
                  キャンセル
                </a>
              )}
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
