import React from 'react';
import { TOverviewResponse } from '@/components/pages/Events/Show/Overview/types';
import { Link } from 'react-router-dom';
import { ListItem } from '@/components/pages/Events/Show/Overview/ListItem';
import {
  RAINY_DAY_ACTION_ID_NO_CANCEL,
  RAINY_DAY_ACTION_ID_POSTPONE,
} from '@/components/pages/Events/Show/Photography/const';
import dayjs from 'dayjs';

export const Arrangement: React.FC<{ response: TOverviewResponse }> = ({
  response,
}) => {
  return (
    <div className="u-pdb_s u-mgb_m">
      <h2>手配関連</h2>
      <ListItem>
        <ul>
          <li>希望人数</li>
          <li>
            <p>&#9678; : {response.arrangement.cameramanOutside}人</p>
            <p>&#9651; : {response.arrangement.cameramanInside}人</p>
            {(response.arrangement.numberOfTrainees || 0) > 0 && (
              <p>研修カメラマン : {response.arrangement.numberOfTrainees}人</p>
            )}
            <div className="t-textColor_sub u-mgt_s">
              ※申請サマリの情報を表示しています。
            </div>
          </li>
        </ul>
        <ul>
          <li>メモ</li>
          <li>
            {response.arrangement.memo && (
              <>
                <p className="u-pre_wrap">{response.arrangement.memo}</p>
                <div className="t-textColor_sub u-mgt_s">
                  ※イベントサマリの情報を表示しています。
                </div>
              </>
            )}
          </li>
        </ul>
        <ul>
          <li>場所等</li>
          <li>
            {response.arrangement.meetingPlaceName && (
              <p>
                集合場所 :
                {response.arrangement.meetingPlaceMemo ? (
                  <> {response.arrangement.meetingPlaceMemo}</>
                ) : (
                  <> {response.arrangement.meetingPlaceName}</>
                )}
              </p>
            )}
            {response.arrangement.photographingPlaceName && (
              <p>
                撮影場所 :
                {response.arrangement.photographingPlaceMemo ? (
                  <> {response.arrangement.photographingPlaceMemo}</>
                ) : (
                  <> {response.arrangement.photographingPlaceName}</>
                )}
              </p>
            )}
            {response.arrangement.thankYouPartyName && (
              <>
                <p>謝恩会 : {response.arrangement.thankYouPartyName}</p>
                {response.arrangement.thankYouPartyPlace && (
                  <p>
                    謝恩会場所 :
                    {response.arrangement.thankYouPartyPlaceMemo ? (
                      <> {response.arrangement.thankYouPartyPlaceMemo}</>
                    ) : (
                      <> {response.arrangement.thankYouPartyPlace}</>
                    )}
                  </p>
                )}
              </>
            )}
            {response.arrangement.photographingDayCount && (
              <p>撮影日数 : {response.arrangement.photographingDayCount}日間</p>
            )}
            <p>雨天時 : {response.arrangement.rainyCancellationTypeName}</p>
            {response.arrangement.rainyCancellationType ===
              RAINY_DAY_ACTION_ID_POSTPONE && (
              <>
                {response.arrangement.rainyPostponementDays !== undefined &&
                  response.arrangement.rainyPostponementDays.length > 0 && (
                    <p>
                      {`延期日 : ${
                        response.arrangement.isRainyPostponementDayUndecided
                          ? '延期日未定'
                          : response.arrangement.rainyPostponementDays
                              .map((day) =>
                                dayjs(day).format('YYYY/MM/DD（ddd）')
                              )
                              ?.join(', ')
                      }`}
                    </p>
                  )}
              </>
            )}
            {response.arrangement.rainyCancellationType ===
              RAINY_DAY_ACTION_ID_NO_CANCEL && (
              <>
                {response.arrangement.rainyMeetingPlaceName && (
                  <p>
                    {`雨天時の集合場所 : ${
                      response.arrangement.rainyMeetingPlaceMemo
                        ? response.arrangement.rainyMeetingPlaceMemo
                        : response.arrangement.rainyMeetingPlaceName
                    }`}
                  </p>
                )}
                {response.arrangement.rainyPhotographingPlaceName && (
                  <p>
                    {`雨天時の撮影場所 : ${
                      response.arrangement.rainyPhotographingPlaceMemo
                        ? response.arrangement.rainyPhotographingPlaceMemo
                        : response.arrangement.rainyPhotographingPlaceName
                    }`}
                  </p>
                )}
              </>
            )}
            {response.arrangement.sleepoverPlace && (
              <p>{`宿泊場所 : ${response.arrangement.sleepoverPlace}`}</p>
            )}
            <div className="t-textColor_sub u-mgt_s">
              ※申請サマリの情報を表示しています。
            </div>
          </li>
        </ul>
        <ul>
          <li>機材等</li>
          <li>
            {response.arrangement.photographingAmbientName && (
              <p>撮影環境 : {response.arrangement.photographingAmbientName}</p>
            )}
            {response.arrangement.dressCodeName && (
              <p>正装 : {response.arrangement.dressCodeName}</p>
            )}
            {response.arrangement.videoCompanyName && (
              <p>動画撮影 : {response.arrangement.videoCompanyName}</p>
            )}
            {response.arrangement.standName && (
              <p>
                ひな壇 : {response.arrangement.standName}
                {response.arrangement.stand === '1' && (
                  <>
                    <> {response.arrangement.standTypeName}</>
                    {response.arrangement.standTypeMemo && (
                      <> {response.arrangement.standTypeMemo}</>
                    )}
                    <> {response.arrangement.standCount}</>
                    {response.arrangement.standCountMemo && (
                      <> {response.arrangement.standCountMemo}</>
                    )}
                  </>
                )}
              </p>
            )}
            {response.arrangement.strobeAvailableName && (
              <p>ストロボ : {response.arrangement.strobeAvailableName}</p>
            )}
            {response.arrangement.monoblockStrobeName && (
              <p>モノブロック : {response.arrangement.monoblockStrobeName}</p>
            )}
            {response.arrangement.backgroundPaperWhoseName && (
              <p>バック紙 : {response.arrangement.backgroundPaperWhoseName}</p>
            )}
            {response.arrangement.backgroundPaperStandName && (
              <p>スタンド : {response.arrangement.backgroundPaperStandName}</p>
            )}
            {response.arrangement.additionalEquipmentName && (
              <p>
                その他特殊機材 :
                {response.arrangement.additionalEquipmentMemo ? (
                  <> {response.arrangement.additionalEquipmentMemo}</>
                ) : (
                  <> {response.arrangement.additionalEquipmentName}</>
                )}
              </p>
            )}
            {response.arrangement.forSaleName && (
              <p>一般販売 : {response.arrangement.forSaleName}</p>
            )}
            {response.arrangement.groupPhotoName && (
              <p>集合写真 : {response.arrangement.groupPhotoName}</p>
            )}
            <div className="t-textColor_sub u-mgt_s">
              ※申請サマリの情報を表示しています。
            </div>
          </li>
        </ul>
        <ul>
          <li>カメラマン希望</li>
          <li>
            {response.arrangement.desiredPhotographerMemoFromSociety && (
              <>
                <p className="u-pre_wrap">
                  {response.arrangement.desiredPhotographerMemoFromSociety}
                </p>
                <div className="t-textColor_sub u-mgt_s">
                  ※団体サマリの情報を表示しています。
                </div>
              </>
            )}
          </li>
        </ul>
        <ul>
          <li>指定カメラマン</li>
          <li>
            {response.arrangement.desiredPhotographers.map(
              (desiredPhotographer, index) => (
                <p key={index}>
                  <Link to={'/photographers/' + desiredPhotographer.id}>
                    {desiredPhotographer.id} / {desiredPhotographer.name}
                  </Link>
                </p>
              )
            )}
            {response.arrangement.desiredPhotographers.length > 0 && (
              <div className="t-textColor_sub u-mgt_s">
                ※団体サマリの情報を表示しています。
              </div>
            )}
          </li>
        </ul>
      </ListItem>
    </div>
  );
};
