import React from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { TConstants } from '@/components/pages/Events/Show/Photography/types';
import { EnvironmentType } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/EnvironmentType';
import { NapPhoto } from '@/components/pages/Events/Show/Photography/PhotographyEvent/Shared/NapPhoto';
import { NullableNumberInput, TextArea } from '@/components/shared/Form/Inputs';

export const PlayingShop: React.FC<{
  validator: TValidator;
  constants: TConstants;
}> = ({ validator, constants }) => {
  return (
    <>
      <EnvironmentType
        validator={validator}
        photographyEnvironmentTypes={constants.photographyEnvironmentTypes}
      />

      <hr className="u-line_plane" />
      <NapPhoto validator={validator} />

      <hr className="u-line_plane" />
      <div className="t-textColor_sub">買い手・売り手</div>
      <TextArea
        name="buyersSellersRemark"
        placeholder="買い手・売り手の詳細を入力してください"
        rows={3}
        validator={validator}
      />
      <p className="t-textColor_sub u-mgb_s">
        ※撮り漏れを防止の為、買い手・売り手の入れ替わるタイミングを当日カメラマンへお伝え頂くようお願いいたします。
      </p>
      <div className="t-textColor_sub">店舗数</div>
      <div className="l-flex_center nowrap l-col_12">
        <div className="l-col_4">
          <NullableNumberInput name="shopCount" validator={validator} />
        </div>
        <p className="u-mgl_s">店舗</p>
      </div>

      <hr className="u-line_plane" />
      <div className="t-textColor_sub">実施場所(会場)</div>
      <TextArea
        name="shopPhotographyVenue"
        placeholder="実施場所(会場)の詳細を入力してください"
        rows={3}
        validator={validator}
      />
      <p className="t-textColor_sub">
        ※園庭では〇〇を行う、ホールでは〇〇行うなど、会場内容の記載をお願いいたします。
      </p>
    </>
  );
};
