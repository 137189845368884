import { TAllSelectableCheck, TAllSelectableChecks } from '../types';

// NOTE: 有効＆画面に表示されているチェックボックスのみ選択可
const isSelectableCheck = (check: TAllSelectableCheck) =>
  !check.disabled && !check.hide;

const isDisplayAllChecks = (checks: TAllSelectableCheck[]): boolean =>
  checks.some((c) => isSelectableCheck(c));

const isCheckedAllChecks = (checks: TAllSelectableCheck[]): boolean =>
  checks.filter((c) => isSelectableCheck(c)).every((c) => c.checked);

const changeCheckAll = (
  currentChecks: TAllSelectableChecks
): TAllSelectableChecks => {
  const checkedAll = !currentChecks.checkedAll;
  return {
    checkedAll,
    checks: currentChecks.checks.map((c) => ({
      ...c,
      checked: isSelectableCheck(c) ? checkedAll : c.checked,
    })),
  };
};
const toggleCheckAll = (
  setChecks: (value: React.SetStateAction<TAllSelectableChecks>) => void
): void => setChecks(changeCheckAll);

const changeCheck = (
  changedInput: HTMLInputElement,
  currentChecks: TAllSelectableChecks
): TAllSelectableChecks => {
  const checks = currentChecks.checks.map((c) => ({
    ...c,
    checked:
      String(c.id) === changedInput.value && isSelectableCheck(c)
        ? !c.checked
        : c.checked,
  }));

  return {
    checkedAll: isCheckedAllChecks(checks),
    checks,
  };
};
const toggleCheck = (
  changedInput: HTMLInputElement,
  setChecks: (value: React.SetStateAction<TAllSelectableChecks>) => void
): void =>
  setChecks((currentChecks) => changeCheck(changedInput, currentChecks));

export {
  isCheckedAllChecks,
  isDisplayAllChecks,
  changeCheckAll,
  toggleCheckAll,
  changeCheck,
  toggleCheck,
};
