import React, { useState } from 'react';
import { useParams } from 'react-router';
import { fetcher, postJson } from '@/ts/fetch';
import { getMd5FromFile } from '../md5';
import ErrorMessages from '../../ErrorMessages';
import { parallelExec } from './parallelExec';
import { SingleSelect } from '../../Form/Inputs';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TPrepareResponse,
  TStartRequest,
  TStatusResponse,
  TResponse,
} from './types';
import { MIN_UPLOAD_FILE_SIZE } from '../const';

type TState = 'INITIAL' | 'DRAGGING' | 'UPLOADING' | 'CONVERTING' | 'COMPLETED';

const UPLOAD_MAX_WORKER_NUM = 6;

const Progress: React.FC<{ max: number; value: number }> = ({ max, value }) => (
  <div className="u-mgt_s">
    <progress max={max} value={value} className="u-mgr_s" />
    {value} / {max}
  </div>
);

const App: React.FC<{
  response: TResponse;
  handleApiError: (e: unknown, s: string) => string[];
}> = ({ response, handleApiError }) => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [state, setState] = useState<TState>('INITIAL');
  const [processed, setProcessed] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const methods = useForm();
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (state === 'INITIAL') {
      setState('DRAGGING');
    }
  };
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    if (state === 'DRAGGING') {
      setState('INITIAL');
    }
  };
  const handleDeletePhotographer = () => {
    methods.setValue('photographerId', null);
  };
  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    if (state !== 'DRAGGING') {
      return;
    }
    setState('UPLOADING');
    try {
      setErrors([]);
      const files = e.dataTransfer.files;
      setFileCount(files.length);
      const fileNames = Array.from(files).map((file) => file.name);
      if (
        fileNames.find((name) => !name.match(/\.(jpe|jpeg|jpg)$/i)) !==
        undefined
      ) {
        setState('INITIAL');
        alert('拡張子がJPEGまたはJPGまたはJPEでないファイルが存在します');
        return;
      }
      if (
        Array.from(files).find((file) => file.size < MIN_UPLOAD_FILE_SIZE) !==
        undefined
      ) {
        setState('INITIAL');
        alert('1KB以下のファイルはアップロードできません');
        return;
      }
      const prepareResponse = (await postJson(
        `/api/categories/${categoryId}/photographs/insert_prepare`,
        { fileNames }
      )) as TPrepareResponse;
      const key = prepareResponse.data.key;
      const requestFiles: TStartRequest['files'] = await parallelExec(
        Array.from(files).map((file, i) => async () => {
          const uploadUrl = prepareResponse.data.files[i].uploadUrl;
          await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
          });
          const checksum = await getMd5FromFile(file);
          setProcessed((cnt) => cnt + 1);
          return {
            srcKey: prepareResponse.data.files[i].srcKey,
            checksum,
            clientPath: file.name,
          };
        }),
        UPLOAD_MAX_WORKER_NUM
      );
      await postJson(`/api/categories/${categoryId}/photographs/insert_start`, {
        files: requestFiles,
        photographerId: methods.getValues('photographerId'),
      } as TStartRequest);
      setState('CONVERTING');
      setProcessed(0);
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const statusResponse = (await fetcher(
          `/api/categories/${categoryId}/photographs/insert_status?key=${key}`
        )) as TStatusResponse;
        setProcessed(statusResponse.data.succeedPhotoNames.length);
        if (
          statusResponse.data.succeedPhotoNames.length +
            statusResponse.data.failedPhotoNames.length >=
          files.length
        ) {
          break;
        }
      }
      // TODO: 完了したら写真一覧をリロードするやつ
      setState('COMPLETED');
    } catch (e) {
      setErrors(
        handleApiError(e, '写真追加に失敗しました。再度お試しください。')
      );
      setState('INITIAL');
    }
  };
  return (
    <FormProvider {...methods}>
      {/* TODO: breadNavi? */}
      <ErrorMessages messages={errors} />
      {!!response.formItems.photographers && (
        <ul className="l-flex_between l-flex_center">
          <li className="p-photographInsert_photographerLabel">カメラマン：</li>
          <li className="p-photographInsert_photographerSelect">
            <SingleSelect
              name="photographerId"
              validator={{ rules: {} }}
              choices={response.formItems.photographers}
              windowed={true}
              placeholder="カメラマンID or カメラマン名を入力してください"
            />
          </li>
          <li>
            <span
              className="c-btn_rectangle c-btn_delete u-mgl_s"
              onClick={handleDeletePhotographer}
            >
              選択をクリア
            </span>
          </li>
        </ul>
      )}
      <div
        className={`p-photographInsert_dropArea ${
          state === 'DRAGGING' ? 'is-dragging' : ''
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <span
          className={`p-photographInsert_dropAreaText ${
            state === 'INITIAL' || state === 'DRAGGING'
              ? 'is_waitingUpload'
              : ''
          }`}
        >
          {state === 'UPLOADING' ? (
            <>
              アップロード中です
              <Progress value={processed} max={fileCount} />
            </>
          ) : state === 'CONVERTING' ? (
            <>
              サイズ変換処理をしています
              <br />
              しばらくお待ち下さい
              <Progress value={processed} max={fileCount} />
            </>
          ) : state === 'COMPLETED' ? (
            <>完了しました</>
          ) : (
            <>
              ここに写真ファイルをドロップ
              <br />
              <span className="p-photographInsert_dropAreaText_notice">
                ※フォルダ追加はできません
              </span>
            </>
          )}
        </span>
      </div>
      写真の番号や順序の指定・変更はできません。ご了承ください。
    </FormProvider>
  );
};

export const useApiUrl = (): string => {
  const { categoryId } = useParams<{ categoryId: string }>();
  return `/api/categories/${categoryId}/photographs/insert`;
};
export default App;
