import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { getFiscalYear } from '@/ts/fiscalYear';
import { beforeBusinessDay } from '@/ts/businessDay';
import { TEvents, TPeriodFormInputs } from './types';
import { PUBLISHPERIODYEAR } from '../constants';

// チラシ発送のタイミング
const FLYER_PRE_DELIVERY_TYPE_BEFORE_SHOOTING = 1;
const FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY = 2;

const addPublishTermValue = (day: dayjs.Dayjs, termValue: string) => {
  const dayMatch = termValue.match(/(\d+)d/);
  if (dayMatch) {
    return day.add(Number(dayMatch[1]) - 1, 'day');
  }
  const monthMatch = termValue.match(/(\d+)m/);
  if (monthMatch) {
    return day.add(Number(monthMatch[1]), 'month');
  }
  return day;
};

const setPublishEndDayFromPhotographingDay = (
  events: TEvents,
  methods: UseFormReturn<TPeriodFormInputs>
) => {
  const photographingday = methods.getValues(
    `event.${events.eventId}.photographingday`
  );
  if (!photographingday) {
    window.alert(
      '撮影日が入力されていないため、掲載終了日は自動で入力されません\n自動で掲載終了日を入力する場合は、撮影日を入力してください'
    );
    return;
  }
  const fiscalYear = getFiscalYear(
    dayjs(photographingday),
    events.societyStartMonthOfFiscalYear
  );
  if (events.societyStartMonthOfFiscalYear === 4) {
    methods.setValue(
      `event.${events.eventId}.publishendday`,
      dayjs('' + (fiscalYear + 1) + '-05-31').format('YYYY-MM-DD')
    );
  } else {
    methods.setValue(
      `event.${events.eventId}.publishendday`,
      dayjs(
        '' +
          (fiscalYear + 1) +
          '-' +
          events.societyStartMonthOfFiscalYear +
          '-01'
      )
        .endOf('month')
        .format('YYYY-MM-DD')
    );
  }
};

const setChirashiSendDate = (
  valueStartDay: string,
  events: TEvents,
  holidays: Record<string, string>,
  methods: UseFormReturn<TPeriodFormInputs>
) => {
  const chirashiSendDate = beforeBusinessDay(
    valueStartDay,
    holidays,
    Number(events.societyContract.numberOfBusinessDaysToPreship)
  );
  methods.setValue(
    `event.${events.eventId}.chirashisenddate`,
    chirashiSendDate
  );
};

export const handleValueStartDayChanged = (
  valuestartday: string,
  events: TEvents,
  methods: UseFormReturn<TPeriodFormInputs>,
  holidays: Record<string, string>
): void => {
  // 妥当な日付になっていない場合、処理を抜ける
  if (!valuestartday.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    methods.setValue(`event.${events.eventId}.publishendday`, '');
    return;
  }
  // 掲載日固定フラグが1:固定の場合の処理
  if (events.societyContract.isEnabledPublishingPeriods) {
    // 固定掲載日と今入力している掲載開始日の日が一致しているかの判定
    const isPublishingStartDayMatch =
      events.societyContract.publishingStartDay === dayjs(valuestartday).date();
    // 一致していない場合はバリュー終了日と掲載終了日を空にする
    if (!isPublishingStartDayMatch) {
      methods.setValue(`event.${events.eventId}.publishendday`, '');
      methods.setValue(`event.${events.eventId}.valueendday`, '');
      // チラシ有りかつ公開前送付の場合、チラシ発送日をセットする
      if (
        events.societyContract.isRequiredFlyer &&
        events.societyContract.flyerPreDeliveryType ===
          FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY
      ) {
        // 公開前に送付する日付の計算
        setChirashiSendDate(valuestartday, events, holidays, methods);
      }
      alert(
        '通常設定している公開開始日と異なるため、終了日は自動で入力されません。\n終了日を手動で入力してください。'
      );
      return;
    }
    // 一致している場合は終了日を作る
    const endDay = dayjs(valuestartday)
      .set(
        'date',
        Math.min(
          dayjs(valuestartday).daysInMonth(),
          events.societyContract.publishingEndDay
        )
      )
      .add(
        events.societyContract.publishingEndDay <=
          events.societyContract.publishingStartDay
          ? 1
          : 0,
        'month'
      )
      .format('YYYY-MM-DD');
    // 期間外販売がある場合はバリュー終了日と掲載終了日をセットする
    if (events.societyContractPlan.isEnabledOutOfPeriodSales) {
      methods.setValue(`event.${events.eventId}.valueendday`, endDay);
      setPublishEndDayFromPhotographingDay(events, methods);
    } else {
      methods.setValue(`event.${events.eventId}.valueendday`, endDay);
      methods.setValue(`event.${events.eventId}.publishendday`, endDay);
    }
  } else {
    // 掲載日固定フラグが0:しないの場合の処理
    // 期間外販売が有りの場合
    if (events.societyContractPlan.isEnabledOutOfPeriodSales) {
      // バリュー終了日と掲載終了日（基本的に翌年度の5月末）をセットする
      methods.setValue(
        `event.${events.eventId}.valueendday`,
        addPublishTermValue(
          dayjs(valuestartday),
          events.publishterm.publishtermvalue
        ).format('YYYY-MM-DD')
      );
      setPublishEndDayFromPhotographingDay(events, methods);
    } else {
      // 期間外販売が無しの場合
      // バリュー終了日と掲載終了日（バリュー終了日=掲載終了日）をセットする
      methods.setValue(
        `event.${events.eventId}.valueendday`,
        addPublishTermValue(
          dayjs(valuestartday),
          events.publishterm.publishtermvalue
        ).format('YYYY-MM-DD')
      );
      methods.setValue(
        `event.${events.eventId}.publishendday`,
        addPublishTermValue(
          dayjs(valuestartday),
          events.publishterm.publishtermvalue
        ).format('YYYY-MM-DD')
      );
    }
  }

  // チラシ有りかつ公開前送付の場合、チラシ発送日をセットする
  if (
    events.societyContract.isRequiredFlyer &&
    events.societyContract.flyerPreDeliveryType ===
      FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY
  ) {
    // 公開前に送付する日付の計算
    setChirashiSendDate(valuestartday, events, holidays, methods);
  }
  // 責任者終了日を設定する
  const fiscalYear = getFiscalYear(dayjs(valuestartday), 4);
  methods.setValue(
    `event.${events.eventId}.leaderendday`,
    dayjs('' + (fiscalYear + PUBLISHPERIODYEAR) + '-05-31').format('YYYY-MM-DD')
  );
};

export const chirashiSendDateCheck = (
  date: string,
  events: TEvents,
  methods: UseFormReturn<TPeriodFormInputs>
): void => {
  if (
    events.societyContract.flyerPreDeliveryType ===
    FLYER_PRE_DELIVERY_TYPE_BEFORE_SHOOTING
  ) {
    const photograpingDay = dayjs(
      methods.getValues(`event.${events.eventId}.photographingday`)
    );
    const chirashiSendDate = dayjs(date as string);
    if (
      photograpingDay.isValid() &&
      chirashiSendDate.isValid() &&
      chirashiSendDate.isAfter(photograpingDay)
    ) {
      window.alert('チラシ発送日が撮影日よりも後になっています');
    }
  } else if (
    events.societyContract.flyerPreDeliveryType ===
    FLYER_PRE_DELIVERY_TYPE_BEFORE_STARTDAY
  ) {
    const valueStartDay = dayjs(
      methods.getValues(`event.${events.eventId}.valuestartday`)
    );
    const chirashiSendDate = dayjs(date as string);
    if (
      valueStartDay.isValid() &&
      chirashiSendDate.isValid() &&
      chirashiSendDate.isSameOrAfter(valueStartDay)
    )
      window.alert(
        'チラシ発送日が掲載開始日と同日か掲載開始日より後になっています'
      );
  }
};
