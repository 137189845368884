import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormItems, TFormInputs } from './types';
import {
  GroupedMultiSelect,
  NumberInput,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { ApiErrors } from '@/components/shared/Form/Errors';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });

    return (
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <div className="c-frame">
          <form
            method="GET"
            onSubmit={methods.handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div>
              <div className="l-flex_between l-col_wrap c-label_line is-sp_input">
                <div className="l-col_auto">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel">
                      <label>法人</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                        <li className="l-col_6">
                          <NumberInput
                            name="id"
                            placeholder="法人ID"
                            validator={validator}
                          />
                        </li>
                        <li className="l-col_8">
                          <TextInput
                            name="name"
                            placeholder="法人名/表示名"
                            validator={validator}
                          />
                        </li>
                        <li className="l-col_8">
                          <TextInput
                            name="namekana"
                            placeholder="法人名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="l-col_6 l-col_wrap">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel">
                      <label>法人代表者名</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="representativeName"
                        placeholder="代表者名"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="l-flex_between l-col_wrap c-label_line is-sp_input">
                <div className="l-col_6">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                        <li className="l-col_auto">
                          <GroupedMultiSelect
                            name="prefecture"
                            choices={formItems.prefectures}
                            placeholder="クリックで候補表示（複数可）"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="l-col_6">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel">
                      <label>
                        住所(市区町村
                        <br />
                        以下)
                      </label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                        <li className="l-col_auto">
                          <TextInput
                            name="address"
                            placeholder="住所(市区町村以下)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="l-col_6">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel">
                      <label>電話番号</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                        <li className="l-col_auto">
                          <TextInput
                            name="telephone"
                            placeholder="03-1234-5678"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="l-col_6">
                  <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                    <li className="c-dataLabel l-col_8">
                      <label>FAX</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-col_wrap__noBorder l-has_gutter l-flex is-sp_mode">
                        <li className="l-col_auto">
                          <TextInput
                            name="fax"
                            placeholder="03-1234-5678"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
              <input
                type="submit"
                value="検索"
                className="c-btn_large c-btn_Search c-input_submit"
              ></input>
            </div>
          </form>
        </div>
      </FormProvider>
    );
  }
);

export default SearchForm;
