import { renderError, useJsonApi } from '@/ts/useApi';
import { FC } from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { useHistory, useLocation } from 'react-router-dom';
import { Results } from './Results';
import { IndexResponse, TFormInputs } from './types';
import { IndexPageLayout } from '../../IndexPageLayout';
import { SearchForm } from './SearchForm';
import QueryString from 'query-string';
import { removeEmpty } from '@/ts/objectTools';

const toQueryParam = (queryString: string): Record<string, unknown> => {
  const queryParam = removeEmpty(convertQueryToFormData(queryString));
  return queryParam;
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  if (
    typeof queryParam['eventId'] === 'string' &&
    !queryParam['eventId'].match(/^[0-9]+$/)
  ) {
    queryParam['eventName'] = queryParam['eventId'];
    delete queryParam['eventId'];
  }
  const query = QueryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/?${query}`;
};

export const IndexPage: FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(toApiPath(queryParams));
  const onSubmit = (formData: TFormInputs) => {
    const newPath = makePathForSearchForm(
      '/photographer_payments',
      queryString,
      formData as Record<string, unknown>
    );
    history.push(newPath);
  };
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  return (
    <IndexPageLayout>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          { id: 2, title: '報酬管理', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start u-mgb_m">
          <h1 className="l-flex_center c-page_title">報酬管理</h1>
        </div>
        <SearchForm
          response={response}
          onSubmit={onSubmit}
          queryParams={queryParams}
        />
        <Results
          response={response}
          payments={response.data.payments}
          editValidator={response.editValidator}
          mutate={() => mutate(undefined, { keepCurrentData: true })}
        />
      </div>
    </IndexPageLayout>
  );
};
