import React from 'react';
import Img from '../images/errors/sorry.png';
import ImgSp from '../images/errors/sorry_sp.png';

const GeneralError: React.FC<{ code: string; message: string }> = ({
  code,
  message,
}) => {
  return (
    <div className="l-content">
      <div className="l-center_wrap">
        <div className="c-errorBox">
          <div className="c-errorImage">
            <img className="u-spOff" src={Img} alt="sorry" />
            <img className="u-pcOff" src={ImgSp} alt="sorry" />
          </div>
          <p className="c-errorCode">{code}</p>
          <p className="c-errorText">{message}</p>
        </div>
      </div>
    </div>
  );
};

const NotFoundError: React.FC = () => (
  <GeneralError code="404" message="ページが見つかりません" />
);

const AuthError: React.FC = () => (
  <GeneralError code="403" message="権限がありません" />
);
const InternalError: React.FC = () => (
  <GeneralError
    code="500"
    message="エラーが発生しました。システム担当者にご連絡ください。"
  />
);

export { NotFoundError, InternalError, AuthError, GeneralError };
