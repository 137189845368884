import React from 'react';
import { User } from '../../types';

type Props = {
  user: User;
};
export const UserInfo = React.memo<Props>(({ user }) => {
  return (
    <div className="u-mgt_s">
      <h4 className="c-section_title">注文者情報</h4>
      <div className="u-mgt_s u-mgb_m">
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">会員ID</li>
              <li className="c-dataValue">{user.id}</li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">会員名</li>
              <li className="c-dataValue">
                {user.firstName} {user.secondName} ({user.firstNameKana}{' '}
                {user.secondNameKana})
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">電話番号</li>
              <li className="c-dataValue">
                <div className="u-pre_wrap">{user.phoneNumber}</div>
              </li>
            </ul>
          </li>
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">メールアドレス</li>
              <li className="c-dataValue">
                <span>{user.email}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
});
