import { FC, useState } from 'react';
import { IndexPageLayout } from '../../IndexPageLayout';
import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import clsx from 'clsx';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { fetcher, postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import { useJsonApi } from '@/ts/useJsonApi';
import { renderError, toMessages } from '@/ts/useApi';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import dayjs from 'dayjs';
import styles from './IndexPage.module.scss';
import SaveApiErrors from '@/components/shared/SaveApiErrors/App';

type IndexResponse = {
  data: {
    closings: {
      targetMonth: string;
      closedAt: string | null;
    }[];
  };
};

type CloseResponse = {
  data: {
    jobId: string;
  };
};

type JobShowResponse = {
  data:
    | {
        status: 'failed';
        message: string;
      }
    | {
        status: 'success';
      }
    | Record<string, never>;
};

export const IndexPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const {
    data: response,
    error,
    mutate,
  } = useJsonApi<IndexResponse>('/api/photographer_payments/monthly_closings');
  const handleCloseBooks = usePreventDuplicateCall(async () => {
    if (
      !window.confirm(
        '締めを行い、支払いに進みますがよろしいでしょうか？\n※締めた後、支払い一覧の支払い前より組織ごとに締め解除が可能です。'
      )
    ) {
      return;
    }
    try {
      const {
        data: { jobId },
      } = await postJson<CloseResponse>(
        '/api/photographer_payments/close_monthly_books'
      );
      setIsLoading(true);
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        try {
          const jobResponse = await fetcher<JobShowResponse>(
            `/api/jobs/${jobId}`
          );
          if (jobResponse.data.status === 'failed') {
            errorToast('月締めに失敗しました');
            setErrorMessages(jobResponse.data.message.split('\n'));
            return;
          } else if (jobResponse.data.status === 'success') {
            successToast('月締めが完了しました');
            mutate();
            setErrorMessages([]);
            return;
          }
        } catch (e) {
          errorToast(
            '月締めの進捗状況の取得に失敗しました。しばらく待ってこの画面を再読み込みし、月締めが完了していなければ、再度月締めをやり直してください'
          );
          setErrorMessages([]);
          return;
        }
      }
    } catch (e) {
      errorToast(`締めに失敗しました： ${toMessages(e)}`);
      return;
    } finally {
      setIsLoading(false);
    }
  });
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <SvgLoading />;
  }
  if (isLoading) {
    return (
      <SvgLoading
        loadingText={
          <>
            月締め処理には数分かかります
            <br />
            その間、画面を閉じずにこのままお待ちください
          </>
        }
      />
    );
  }
  return (
    <>
      <IndexPageLayout>
        <BreadCrumb
          pagesInfo={[
            { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
            { id: 2, title: '月締め一覧', path: undefined },
          ]}
        />
        <div className="nowrap u-mgb_m">
          <div className="l-flex_start u-mgb_m">
            <h1 className="l-flex_center c-page_title">月締め一覧</h1>
          </div>
        </div>
        <SaveApiErrors messages={errorMessages} />
        <table className={styles.table}>
          <thead className={styles.tableHeadRow}>
            <tr>
              <th className={styles.tableCell}>対象イベント</th>
              <th className={styles.tableCell}>締め実行日時</th>
            </tr>
          </thead>
          <tbody>
            {response.data.closings.map((closing) => (
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>
                  {closing.closedAt !== null && (
                    <span className={styles.closedLabel}>締め</span>
                  )}
                  {`${dayjs(closing.targetMonth).format(
                    'YYYY年MM月'
                  )}末日までのイベントに対して`}
                </td>
                <td className={styles.tableCell}>
                  {closing.closedAt ? (
                    <>{dayjs(closing.closedAt).format('YYYY/MM/DD HH:mm:ss')}</>
                  ) : (
                    <span className={styles.notYetClosed}>締め前</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ButtonsFooter>
          <button
            className={clsx('c-btn_large', 'c-btn_primary')}
            onClick={() => handleCloseBooks()}
          >
            締め
          </button>
        </ButtonsFooter>
      </IndexPageLayout>
    </>
  );
};
