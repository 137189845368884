import { FC, useState } from 'react';
import {
  InnerTable,
  InnerTableBody,
  InnerTableCell,
  InnerTableHead,
  InnerTableRow,
  OpenedDetail,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/shared/ResultTable';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';
import styles from './Results.module.scss';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import { SmallCheckBox } from '@/components/shared/Form/SmallCheckBox';
import clsx from 'clsx';
import { IndexResponse, EventRequestPhotographer } from '../types';
import Paginator from '@/components/shared/Paginator/App';
import queryString from 'query-string';
import BaseModal from '@/components/shared/BaseModal';
import { FormProvider, useForm } from 'react-hook-form';
import {
  NumberInput,
  RadioBoxes,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import {
  STATUS_APPROVED,
  STATUS_CANCELED,
} from '@/ts/photographerPayments/constants';
import { numberFormat } from '@/ts/formatTools';
import { isValidationError, toMessages } from '@/ts/useApi';
import QueryString from 'query-string';
import { TMutate } from '@/ts/useJsonApi';
import {
  CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL,
  CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL,
} from '@/ts/photographerPayments/messages';

const EVENT_ASSIGN_STATUS_DECIDED = 0;
const EVENT_ASSIGN_STATUS_TRAINEE = 3;
const INDIVIDUAL_EDIT_THRESHOLD = 10;

const EventAssignStatusLabel: FC<{
  eventAssignStatus: number;
  eventAssignStatusName: string;
}> = ({ eventAssignStatus, eventAssignStatusName }) => {
  const className =
    eventAssignStatus === EVENT_ASSIGN_STATUS_DECIDED
      ? styles.decided
      : eventAssignStatus === EVENT_ASSIGN_STATUS_TRAINEE
      ? styles.trainee
      : '';
  return (
    <span className={clsx(styles.assignStatusLabel, className)}>
      {eventAssignStatusName}
    </span>
  );
};

const STATUS_UNREGISTERED = 1;

type TRegisterModalFormValues = {
  price: number;
  priceChoice: number;
  externalMemo: string;
  consumptionTaxPercent: number;
};

const RegisterModal: React.FC<{
  register: (value: { price: number; externalMemo: string }) => void;
  closeModal: () => void;
  predefinedCompensationPrices: number[];
  consumptionTaxPercent: TChoice[];
  validator: TValidator;
}> = ({
  register,
  closeModal,
  predefinedCompensationPrices,
  consumptionTaxPercent,
  validator,
}) => {
  const methods = useForm<TRegisterModalFormValues>({
    defaultValues: {
      consumptionTaxPercent: consumptionTaxPercent.find(
        (choice) => choice.isDisabled !== true
      )?.key as number,
    },
  });
  const PRICE_CHOICE_MANUAL = 0;
  const priceChoices = [
    ...predefinedCompensationPrices.map((price) => ({
      key: price,
      value: `${price}円`,
    })),
    { key: PRICE_CHOICE_MANUAL, value: '手動入力' },
  ];
  // TODO: 後で、APIから取ってくる後そもそも無税
  const handleRegister = () => {
    register({
      price:
        methods.getValues('priceChoice') === PRICE_CHOICE_MANUAL
          ? methods.getValues('price')
          : methods.getValues('priceChoice'),
      externalMemo: methods.getValues('externalMemo'),
    });
  };
  // TODO: 変更できない行にはチェックボックスを表示しない
  return (
    <BaseModal handleClose={closeModal}>
      <div className="c-modal_textBox">
        <h4 className="c-section_title sp_off">中止金登録</h4>
        <hr className="u-line_plane" />
        <div className="u-mgb_m"></div>
      </div>
      <FormProvider {...methods}>
        <div className="t-textColor_sub u-mgb_s">
          選択した中止金に対して一括で変更を行います。
          <br />
          往復交通費を含める場合や、依頼金額の半額または全額支払いの場合は、「その他」を選択して金額を入力してください
        </div>
        <div className="l-flex">
          <div className="c-dataLabel">
            中止金額
            <small className="c-required">(必須)</small>
          </div>
          <div className="c-dataValue">
            <div className="l-flex">
              <div>
                <div className="t-textColor_sub">金額</div>
                <RadioBoxes
                  name="priceChoice"
                  choices={priceChoices}
                  validator={validator}
                />
              </div>
              <div>
                <div className="t-textColor_sub">手動入力</div>
                <NumberInput
                  name="price"
                  validator={validator}
                  isDisabled={
                    methods.watch('priceChoice') !== PRICE_CHOICE_MANUAL
                  }
                />
              </div>
            </div>
            <div className="t-textColor_sub">税率</div>
            <RadioBoxes
              name="consumptionTaxPercent"
              choices={consumptionTaxPercent}
              validator={validator}
            />
            <div className="t-textColor_sub">備考</div>
            <TextArea name="externalMemo" validator={validator} />
            <div className="t-textColor_sub">※明細に表示されます</div>
          </div>
        </div>
      </FormProvider>
      <hr className="u-line_plane" />
      <p className="u-align_center">
        <button
          type="button"
          className="c-btn_large c-btn_cancel c-input_submit u-mgr_s"
          onClick={closeModal}
        >
          キャンセル
        </button>
        <button
          type="button"
          className="c-btn_large c-btn_primary c-input_submit"
          onClick={handleRegister}
        >
          登録
        </button>
      </p>
    </BaseModal>
  );
};

const ResultRow: FC<{
  eventRequestPhotographer: EventRequestPhotographer;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  handleUndoCancel: () => void;
}> = ({ eventRequestPhotographer, checked, setChecked, handleUndoCancel }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <TableRow
        highlighted={checked}
        disabled={eventRequestPhotographer.status === STATUS_CANCELED}
      >
        <TableCell>
          {canDisplayCheckbox(eventRequestPhotographer) && (
            <SmallCheckBox checked={checked} setChecked={setChecked} />
          )}
        </TableCell>
        <TableCell>
          <div>
            <span onClick={() => setOpened(!opened)} className={styles.arrow}>
              {opened ? (
                <RiArrowDownSLine size="32px" color="#ff8e42" />
              ) : (
                <RiArrowRightSLine size="32px" color="#ff8e42" />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <EventAssignStatusLabel
            eventAssignStatus={eventRequestPhotographer.eventAssignStatus}
            eventAssignStatusName={
              eventRequestPhotographer.eventAssignStatusName
            }
          />
          {`${eventRequestPhotographer.photographerSei} ${eventRequestPhotographer.photographerMei}`}
        </TableCell>
        <TableCell>
          {eventRequestPhotographer.photographerOrganizationName}
        </TableCell>
        <TableCell>
          <Link
            to={`/events/${eventRequestPhotographer.eventId}`}
            className="c-textlink"
          >
            {`${eventRequestPhotographer.eventId} / ${eventRequestPhotographer.eventName}`}
          </Link>
        </TableCell>
        <TableCell>{eventRequestPhotographer.photographingDay}</TableCell>
        <TableCell>{eventRequestPhotographer.societyName}</TableCell>
        <TableCell>{eventRequestPhotographer.statusName}</TableCell>
        <TableCell>
          <div className="u-align_right u-mgr_s">
            {eventRequestPhotographer.price !== null
              ? numberFormat(eventRequestPhotographer.price)
              : ''}
          </div>
        </TableCell>
      </TableRow>
      {opened && (
        <OpenedDetail>
          <InnerTable>
            <InnerTableHead>
              <InnerTableRow>
                <InnerTableCell>税率</InnerTableCell>
                <InnerTableCell>源泉徴収</InnerTableCell>
                <InnerTableCell>金額</InnerTableCell>
                <InnerTableCell>備考(明細に記載されます)</InnerTableCell>
              </InnerTableRow>
            </InnerTableHead>
            <InnerTableBody>
              <InnerTableRow
                disabled={eventRequestPhotographer.status === STATUS_CANCELED}
              >
                <InnerTableCell>
                  {eventRequestPhotographer.consumptionTaxPercent === '0'
                    ? '非課税'
                    : eventRequestPhotographer.consumptionTaxPercent
                    ? `${eventRequestPhotographer.consumptionTaxPercent}%`
                    : ''}
                </InnerTableCell>
                <InnerTableCell>
                  {eventRequestPhotographer.hasWithholdingTax === null
                    ? ''
                    : eventRequestPhotographer.hasWithholdingTax
                    ? '必要'
                    : '不要'}
                </InnerTableCell>
                <InnerTableCell>
                  <div className="u-align_right">
                    {eventRequestPhotographer.price !== null
                      ? numberFormat(eventRequestPhotographer.price)
                      : ''}
                  </div>
                </InnerTableCell>
                <InnerTableCell>
                  <span className="u-pre_wrap">
                    {eventRequestPhotographer.externalMemo}
                  </span>
                </InnerTableCell>
              </InnerTableRow>
            </InnerTableBody>
          </InnerTable>
          <div className={styles.closeLinkContainer}>
            {eventRequestPhotographer.status === STATUS_CANCELED &&
              eventRequestPhotographer.isChangeable && (
                <button
                  className="c-btn_large c-btn_delete u-mgb_s"
                  onClick={() => handleUndoCancel()}
                >
                  取り消し解除
                </button>
              )}
            <span className={styles.closeLinkWrapper}>
              <span
                className={styles.closeLink}
                onClick={() => setOpened(false)}
              >
                <RiCloseLine size="24px" color="rgba(166,166,166,0.6)" />
                閉じる
              </span>
            </span>
          </div>
        </OpenedDetail>
      )}
    </>
  );
};

const canDisplayCheckbox = (
  eventRequestPhotographer: EventRequestPhotographer
) =>
  eventRequestPhotographer.status === STATUS_APPROVED ||
  eventRequestPhotographer.status === STATUS_UNREGISTERED;

export const Results: FC<{
  response: IndexResponse;
  mutate: TMutate<IndexResponse>;
  queryParams: Record<string, unknown>;
}> = ({ response, mutate, queryParams }) => {
  // TODO: 取り消しボタンの挙動を追加
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const location = useLocation();
  const history = useHistory();
  const eventRequestPhotographers =
    response.data.eventRequestPhotographers || [];
  const [bulkRegisterModalOpened, setBulkRegisterModalOpened] = useState(false);
  const handleClickIndividual = () => {
    const query = queryString.stringify(
      {
        eventRequestPhotographerIds: checkedIds,
      },
      { arrayFormat: 'bracket' }
    );
    history.push(`/photographer_payments/compensations/edit?${query}`);
  };
  const handleClickBulk = () => {
    setBulkRegisterModalOpened(true);
  };
  const [validator, setValidator] = useState<TValidator>(response.validator);
  const register = usePreventDuplicateCall(
    async (value: { price: number; externalMemo: string }) => {
      try {
        await postJson('/api/photographer_payments/compensations/bulk_update', {
          updates: checkedIds.map((id) => ({
            eventRequestPhotographerId: id,
            ...value,
          })),
        });
        successToast('登録されました');
        mutate();
        setCheckedIds([]);
        setBulkRegisterModalOpened(false);
      } catch (e) {
        if (e.jsonMessage.validator.messages.updates[0]) {
          e.jsonMessage.validator.messages =
            e.jsonMessage.validator.messages.updates[0];
        }
        if (isValidationError(e)) {
          errorToast(`入力に誤りがあります`);
          setValidator(e.jsonMessage.validator);
        } else {
          errorToast(`エラーが発生しました: ${toMessages(e)}`);
        }
      }
    }
  );
  const handleBulkCancel = usePreventDuplicateCall(async () => {
    if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_BULK_CANCEL)) {
      return;
    }
    const filteredEventRequestPhotographers = checkedIds.map((id) =>
      response.data.eventRequestPhotographers?.find(
        (eventRequestPhotographer) =>
          eventRequestPhotographer.eventRequestPhotographerId === id
      )
    ) as EventRequestPhotographer[];
    if (
      filteredEventRequestPhotographers.find(
        (eventRequestPhotographer) =>
          eventRequestPhotographer.status !== STATUS_APPROVED
      ) !== undefined
    ) {
      errorToast('未登録のものは取り消せません');
      return;
    }
    const checkedPhotographerPaymentIds = checkedIds.map(
      (id) =>
        response.data.eventRequestPhotographers?.find(
          (eventRequestPhotographer) =>
            eventRequestPhotographer.eventRequestPhotographerId === id
        )?.photographerPaymentId
    );
    try {
      await postJson('/api/photographer_payments/bulk_cancel', {
        ids: checkedPhotographerPaymentIds,
      });
    } catch (e) {
      errorToast('取り消しに失敗しました');
      return;
    }
    successToast('取り消ししました');
    setCheckedIds([]);
    mutate(
      (response) =>
        response && {
          ...response,
          data: {
            ...response.data,
            eventRequestPhotographers:
              response.data.eventRequestPhotographers?.map(
                (eventRequestPhotographer) => ({
                  ...eventRequestPhotographer,
                  ...(checkedPhotographerPaymentIds.includes(
                    eventRequestPhotographer.photographerPaymentId
                  )
                    ? {
                        status: STATUS_CANCELED,
                        statusName: '取り消し',
                      }
                    : {}),
                })
              ),
          },
        }
    );
  });
  const handleUndoCancel = usePreventDuplicateCall(
    async (paymentId: number) => {
      if (!window.confirm(CONFIRM_PAYMENT_MESSAGE_UNDO_CANCEL)) {
        return;
      }
      try {
        await postJson('/api/photographer_payments/bulk_undo_cancel', {
          ids: [paymentId],
        });
      } catch (e) {
        errorToast('取り消し解除に失敗しました');
        return;
      }
      successToast('取り消し解除しました');
      mutate(undefined, { keepCurrentData: true });
    }
  );
  if (eventRequestPhotographers.length === 0) {
    return (
      <div className="c-emptyState_box">
        条件を満たす結果が存在しませんでした
      </div>
    );
  }
  const paginatorQueryString = QueryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });
  const checkableIds = response.data.eventRequestPhotographers
    ?.filter((eventRequestPhotographer) =>
      canDisplayCheckbox(eventRequestPhotographer)
    )
    .map(
      (eventRequestPhotographers) =>
        eventRequestPhotographers.eventRequestPhotographerId
    );
  return (
    <>
      <div className="c-pagination_upper u-mgb_s">
        <Paginator
          pagination={response.pagination}
          currentPath={location.pathname}
          queryString={paginatorQueryString}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {checkableIds?.length !== 0 && (
                <SmallCheckBox
                  checked={checkedIds.length === checkableIds?.length}
                  setChecked={(checked) =>
                    checked
                      ? setCheckedIds(checkableIds || [])
                      : setCheckedIds([])
                  }
                />
              )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>カメラマン</TableCell>
            <TableCell>カメラマン組織</TableCell>
            <TableCell>イベント</TableCell>
            <TableCell>撮影日</TableCell>
            <TableCell>団体</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>合計金額(税込)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventRequestPhotographers.map((eventRequestPhotographer) => (
            <ResultRow
              key={eventRequestPhotographer.eventRequestPhotographerId}
              eventRequestPhotographer={eventRequestPhotographer}
              handleUndoCancel={() =>
                handleUndoCancel(eventRequestPhotographer.photographerPaymentId)
              }
              checked={checkedIds.includes(
                eventRequestPhotographer.eventRequestPhotographerId
              )}
              setChecked={(checked) =>
                checked
                  ? setCheckedIds([
                      ...checkedIds,
                      eventRequestPhotographer.eventRequestPhotographerId,
                    ])
                  : setCheckedIds(
                      checkedIds.filter(
                        (id) =>
                          id !==
                          eventRequestPhotographer.eventRequestPhotographerId
                      )
                    )
              }
            />
          ))}
        </TableBody>
      </Table>
      <div className="u-mgt_s"></div>
      <Paginator
        pagination={response.pagination}
        currentPath={location.pathname}
        queryString={paginatorQueryString}
      />
      <ButtonsFooter>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_cancel',
            'u-pdt_s',
            'u-pdb_s',
            checkedIds.length === 0 ? 'is-disabled' : undefined
          )}
          onClick={handleBulkCancel}
        >
          取り消し
        </button>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_primary',
            checkedIds.length === 0 ? 'is-disabled' : undefined
          )}
          onClick={handleClickBulk}
        >
          一括登録
        </button>
        <button
          className={clsx(
            'c-btn_large',
            'c-btn_primary',
            checkedIds.length === 0 ||
              checkedIds.length > INDIVIDUAL_EDIT_THRESHOLD
              ? 'is-disabled'
              : undefined
          )}
          onClick={handleClickIndividual}
        >
          個々に登録
        </button>
      </ButtonsFooter>
      {bulkRegisterModalOpened && (
        <RegisterModal
          register={register}
          closeModal={() => setBulkRegisterModalOpened(false)}
          consumptionTaxPercent={response.formItems.consumptionTaxPercent}
          predefinedCompensationPrices={
            response.formItems.predefinedCompensationPrices
          }
          validator={validator}
        />
      )}
    </>
  );
};
