import React from 'react';
import { numberFormat } from '../../../ts/formatTools';
import { TPricePatternCategory, TPhotoSize } from './types';

const PRICE_NOT_FOR_SALE = -1;

const Price: React.FC<{
  pricePatternCategory: TPricePatternCategory | undefined;
  photoSize: TPhotoSize;
}> = React.memo(({ pricePatternCategory, photoSize }) => {
  // NOTE: これらはデータ不整合のケース
  if (!pricePatternCategory || !photoSize) {
    return null;
  }
  const price = pricePatternCategory.prices.find(
    (p) =>
      p.priceInInteger !== PRICE_NOT_FOR_SALE &&
      photoSize.photosizemasterno === p.photosizemasterno
  );
  if (!price) {
    return null;
  }
  const isDefaultSize =
    photoSize.photosizemasterno === pricePatternCategory.photosizemasterno;

  return (
    <>
      <p className={isDefaultSize ? 'p-priceIndex_primary' : ''}>
        ¥{numberFormat(price.priceInInteger)}
      </p>
      <p className={isDefaultSize ? 'p-priceIndex_primary' : ''}>
        (¥{numberFormat(price.taxInPrice)})
      </p>
    </>
  );
});

export default Price;
