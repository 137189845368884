import React from 'react';
import { statuses } from './constants';

type TStatusLabelProps = {
  status: number;
  statusName: string;
};
const StatusLabel: React.FC<TStatusLabelProps> = React.memo(
  ({ status, statusName }) => {
    const className =
      'c-statusLabel c-statusLabel__small' +
      ` c-incentivePayment_statusLabel__${
        status in statuses ? statuses[status] : 'default'
      }`;
    return <span className={className}>{statusName}</span>;
  }
);

export default StatusLabel;
