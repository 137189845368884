import { FC } from 'react';
import styles from './IndexPageLayout.module.scss';
import { RiLink } from 'react-icons/ri';
import { kanriUrl, ownerUrl } from '@/ts/url';
import dayjs from 'dayjs';
import { useAuthInfo } from '@/components/AuthProvider';
import { LinkText } from '@/components/shared/LinkText';

const changeRequestUrl = (query: Record<string, string>) =>
  ownerUrl('events/change_requests', {
    photographing_day_from: dayjs().format('YYYY-MM-DD'),
    groupSelection: 'id',
    chargeSelection: 'sales',
    completeflag: '0',
    relation: 'changes',
    approval_type: 'all',
    status: '1',
    ...query,
  });

const SideMenu: FC = () => {
  const authInfo = useAuthInfo();
  return (
    <div className={styles.sideMenu}>
      <div className={styles.sideMenuLinkGroup}>
        <div>
          <RiLink />
          報酬関連
        </div>
        <div>
          {authInfo.authTehai ? (
            <>
              <LinkText to="/photographer_payments">報酬管理</LinkText>
              <LinkText to="/photographer_payments/request_fees">
                注文金一覧
              </LinkText>
              <LinkText to="/photographer_payments/compensations">
                中止金一覧
              </LinkText>
              <LinkText to="/photographer_payments/expenses">
                申請経費一覧
              </LinkText>
              <LinkText to="/photographer_payments/transportation_expenses">
                交通費一覧
              </LinkText>
              <LinkText to="/photographer_payments/penalties">
                違約金一覧
              </LinkText>
              <LinkText to="/photographer_payments/non_event_related_expenses">
                イベント外経費一覧
              </LinkText>
              <LinkText to="/photographer_payments/awards">表彰金一覧</LinkText>
              <LinkText to="/photographer_payments/adjustment">
                調整金一覧
              </LinkText>
            </>
          ) : (
            <>
              <div>報酬管理</div>
              <div>注文金一覧</div>
              <div>中止金一覧</div>
              <div>申請経費一覧</div>
              <div>交通費一覧</div>
              <div>違約金一覧</div>
              <div>イベント外経費一覧</div>
              <div>表彰金一覧</div>
              <div>調整金一覧</div>
            </>
          )}
          {authInfo.authPhotographerPaymentsClose ? (
            <LinkText to="/photographer_payments/monthly_closings">
              月締め一覧
            </LinkText>
          ) : (
            <div>月締め一覧</div>
          )}
          {authInfo.authTehai || authInfo.authAdmin ? (
            <LinkText to="/photographer_payments/transfers">支払一覧</LinkText>
          ) : (
            <div>支払一覧</div>
          )}
        </div>
      </div>
      <div className={styles.sideMenuLinkGroup}>
        <div>
          <RiLink />
          その他関連
        </div>
        <div>
          <a
            className="c-textlink"
            href={kanriUrl({
              action_owner_SHINSEItehailist2: 'true',
              photographingday_from: 'today',
            })}
          >
            手配リスト
          </a>
          <br />
          <a
            className="c-textlink"
            href={changeRequestUrl({
              change_item: 'cancelflag',
            })}
          >
            イベント変更申請（撮影中止）
          </a>
          <br />
          <a
            className="c-textlink"
            href={changeRequestUrl({
              change_item: 'photographingday',
            })}
          >
            イベント変更申請（撮影日）
          </a>
          <br />
          <a
            className="c-textlink"
            href={changeRequestUrl({
              change_item: 'photographingtime',
            })}
          >
            イベント変更申請（撮影時間）
          </a>
          <br />
          <a
            className="c-textlink"
            href={changeRequestUrl({
              change_item: 'meeting_place',
            })}
          >
            イベント変更申請（集合場所）
          </a>
        </div>
      </div>
    </div>
  );
};

export const IndexPageLayout: FC = ({ children }) => {
  return (
    <div className="l-flex l-col_wrap">
      <div className="l-col_18">{children}</div>
      <div className="l-col_6">
        <SideMenu />
      </div>
    </div>
  );
};
