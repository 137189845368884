import { TChoice } from '@/components/shared/Form/types';
import { ID_UNCONFIRMED } from '../../../../const';

/**
 * @example
 * toConstantsText(
 *     constants.photographyTransportationTypes,
 *     photography.meetingPlaceTransportationTypeIds
 * )
 */
export const toConstantsText = (
  choices: TChoice[],
  selectedValue: number | number[] | null,
  noneText = '未確認',
  delimiter = ' , '
): string => {
  if (
    selectedValue === ID_UNCONFIRMED ||
    selectedValue === null ||
    (Array.isArray(selectedValue) && selectedValue.length === 0)
  ) {
    return noneText;
  }
  return choices
    .filter((item) => {
      if (Array.isArray(selectedValue)) {
        return selectedValue.includes(Number(item.key));
      }
      return selectedValue === Number(item.key);
    })
    .map((item) => item.value)
    .join(delimiter);
};
