import React from 'react';
import HiddenPhotograph from './HiddenPhotograph';
import { THiddenPhotograph } from './types';

const Photographs: React.FC<{
  eventId: number;
  photographs: THiddenPhotograph[];
}> = React.memo(({ eventId, photographs }) => (
  <>
    <div className="u-mgb_s">
      非表示の写真が{' '}
      <span className="t-textColor_danger">{photographs.length}枚</span>{' '}
      あります
    </div>
    <table className="c-indexList u-mgb_0">
      <thead>
        <tr>
          <th>カテゴリ</th>
          <th>サムネイル</th>
          <th>写真ID</th>
          <th>写真番号</th>
          <th>カメラマンID</th>
          <th>責任者削除</th>
          <th>
            コンバート
            <br />
            失敗
          </th>
          <th>非表示理由</th>
        </tr>
      </thead>
      <tbody>
        {photographs.map((p) => (
          <HiddenPhotograph key={p.id} eventId={eventId} {...p} />
        ))}
      </tbody>
    </table>
  </>
));

const HiddenPhotographs: React.FC<{
  eventId: number;
  photographs: THiddenPhotograph[];
}> = React.memo(({ eventId, photographs }) => (
  <div className="l-center_wrap">
    <div className="c-frame l-flex_center">
      {photographs.length ? (
        <Photographs eventId={eventId} photographs={photographs} />
      ) : (
        '非表示の写真はありません'
      )}
    </div>
  </div>
));

export default HiddenPhotographs;
