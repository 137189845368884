/**
 * undefinedを除去
 */
const removeUndefined = <TypeOfObject extends Record<string, unknown>>(
  object: TypeOfObject
): Partial<TypeOfObject> => {
  const copied = { ...object };
  Object.keys(copied).forEach((key) => {
    const k = key as keyof TypeOfObject;
    if (copied[k] === undefined) {
      delete copied[k];
    }
  });

  return copied as Partial<TypeOfObject>;
};

/**
 * emptyに該当する値を除去
 */
const removeEmpty = <TypeOfObject extends Record<string, unknown>>(
  object: TypeOfObject
): Partial<TypeOfObject> => {
  const copied = { ...object };
  Object.keys(copied).forEach((key) => {
    const k = key as keyof TypeOfObject;
    const v = copied[k];
    // TODO: 取り急ぎ必要そうなものだけ定義したので、Formに足らんかったら足してください
    if (
      v === undefined ||
      v === null ||
      v === '' ||
      (typeof v === 'number' && isNaN(v as number)) ||
      (Array.isArray(v) && v.length === 0)
    ) {
      delete copied[k];
    }
  });

  return copied as Partial<TypeOfObject>;
};

export { removeUndefined, removeEmpty };
