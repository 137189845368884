import React from 'react';
import { Link } from 'react-router-dom';
import { TOwneraccount } from '../../types';
import dayjs from 'dayjs';
import { getProxyLoginUrl } from '../../../../ts/legacyUrl';
import { useAuthInfo } from '../../../AuthProvider';

const CONSTRACT_IS_ACTIVE = 2;

const AdministratorList: React.FC<{
  owneraccounts: TOwneraccount[];
  society_contract_id: number;
  society_contract_status: number;
  canCreateOwneraccount: boolean;
}> = (props) => {
  const authInfo = useAuthInfo();
  const hasNoOwnersElement = (
    <div className="c-emptyState_box u-fz_s">
      <p>登録がありません。</p>
    </div>
  );

  return (
    <div>
      <ul className="l-col_wrap l-flex l-flex__isSpInput">
        <li className="l-col_24">
          <div className="l-flex_between_center u-mgb_s">
            <div className="l-flex_left">
              <h4 className="c-section_title u-mgb_s u-mgt_s">管理者一覧</h4>
            </div>
            <div className="l-flex_end">
              {props.society_contract_status === CONSTRACT_IS_ACTIVE &&
              props.canCreateOwneraccount ? (
                <Link
                  className="c-btn_rectangle c-btn_create"
                  to={`/society_contracts/${props.society_contract_id}/owneraccounts/create`}
                >
                  <i className="c-icon c-icon__small c-icon_create" />
                </Link>
              ) : (
                <Link
                  className="c-btn_rectangle c-btn_create is-disabled"
                  to="/"
                >
                  <i className="c-icon_create"></i>
                </Link>
              )}
            </div>
          </div>
        </li>
        <li className="l-col_24">
          <ul>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder s-owneraccount_ul">
                  {(!props.owneraccounts ||
                    Object.keys(props.owneraccounts).length === 0) &&
                    hasNoOwnersElement}
                  {props.owneraccounts &&
                    props.owneraccounts.map((owneraccount: TOwneraccount) => (
                      <li
                        className="l-col_12 s-owneraccounts-li"
                        key={owneraccount.ownerno}
                      >
                        <div className="l-flex is-sp_input">
                          <div className="u-mgr_s">
                            <ul className="a-administrator-labelList">
                              <li>
                                <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                                  <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                                  管理者ID
                                </div>
                                {owneraccount.ownerno}
                              </li>
                              <li>
                                <div className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                                  <i className="c-icon c-icon__xxsmall c-icon_groups u-mgr_xs"></i>
                                  登録日
                                </div>
                                {dayjs(owneraccount.createdday).format(
                                  'YYYY/MM/DD'
                                )}
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="p-groupsList_detailBox s-owneraccount-detailBox">
                            <div className="l-flex_between p-groupsList_upper">
                              <div className="p-groupsList_names">
                                <ul className="s-administrator-infoList">
                                  <li>
                                    <h4 className="c-textOmit">
                                      {owneraccount.ownername}
                                    </h4>
                                  </li>
                                  <li>
                                    <i className="c-icon_mail u-mgr_xs"></i>
                                    {owneraccount.mailaddress === null ? (
                                      <span className="c-nullMessage_text">
                                        未登録
                                      </span>
                                    ) : (
                                      <span>{owneraccount.mailaddress}</span>
                                    )}
                                  </li>
                                </ul>
                              </div>
                              <div className="l-flex_end">
                                <a
                                  className={`c-btn_manage c-btn_middle_wide u-mgr_s ${
                                    owneraccount.conditions.isEnabledProxyLogin
                                      ? ''
                                      : 'is-disabled'
                                  }`}
                                  href={
                                    owneraccount.conditions.isEnabledProxyLogin
                                      ? getProxyLoginUrl(
                                          authInfo.mailaddress,
                                          owneraccount.ownerno
                                        )
                                      : '/'
                                  }
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  ログイン
                                </a>
                                <a
                                  className={`c-btn_manage c-btn_middle_wide u-mgr_s ${
                                    owneraccount.conditions
                                      .isEnabledSendingInvitationMail
                                      ? ''
                                      : 'is-disabled'
                                  }`}
                                  href={
                                    owneraccount.conditions
                                      .isEnabledSendingInvitationMail
                                      ? '/owneraccounts/' +
                                        owneraccount.ownerno +
                                        '/invitation_mail/input'
                                      : '/'
                                  }
                                >
                                  招待メール
                                </a>
                                <Link
                                  className={`c-btn_rectangle c-btn_edit ${
                                    owneraccount.conditions.isEditable
                                      ? ''
                                      : 'is-disabled'
                                  }`}
                                  to={`/owneraccounts/${owneraccount.ownerno}/edit`}
                                >
                                  <i className="c-icon c-icon__xsmall c-icon_edit" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AdministratorList;
