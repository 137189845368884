import React from 'react';
import { OrderInfo as OrderInformation } from '../../types';
import { dateFormat, dateTimeFormat } from '@/ts/formatTools';
import { PrintLog as PrintLogEntity } from '../../../Show/types';
import { PrintLog } from '../../../components/PrintLog';

type Props = {
  orderInfo: OrderInformation;
  printLog?: PrintLogEntity;
};

export const OrderInfo = React.memo<Props>(({ orderInfo, printLog }) => {
  return (
    <>
      <div className="l-flex_between_center u-mgb_s">
        <h4 className="c-section_title">注文情報</h4>
        <div className="l-flex">
          <div className="u-mgr_s">
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              {orderInfo.createdAt ? '発送開始日時' : 'まとめ有効期限	'}
            </span>
            <span className="u-fz_xs">
              {orderInfo.createdAt
                ? dateTimeFormat(orderInfo.createdAt)
                : dateFormat(orderInfo.dueDate)}
            </span>
          </div>
        </div>
      </div>
      {orderInfo.dueDate && (
        <div className="l-flex_between_center u-mgb_s">
          <p>お客様が発送開始を実行していません。</p>
        </div>
      )}
      <div className="u-mgt_m u-mgb_m">
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">お届け先</li>
              <li className="c-dataValue">
                {orderInfo.firstName} {orderInfo.secondName} 様<br />〒
                {orderInfo.postalCode}
                <br />
                {orderInfo.prefectureName}
                {orderInfo.city}
                {orderInfo.address}
              </li>
            </ul>
          </li>
        </ul>
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_12">
            <ul className="l-flex">
              <li className="c-dataLabel">電話番号</li>
              <li className="c-dataValue">{orderInfo.telephone}</li>
            </ul>
          </li>
        </ul>
      </div>
      {printLog && orderInfo.orderInfoId && orderInfo.orderDeliveryInfoId && (
        <PrintLog
          orderInfoId={orderInfo.orderInfoId}
          orderDeliveryInfoId={orderInfo.orderDeliveryInfoId}
          printLog={printLog}
        />
      )}
    </>
  );
});
