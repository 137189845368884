import {
  TSubTotal,
  TInputIncentivePaymentEvent,
  TInputIncentivePaymentOther,
} from './types';

const reCalcSubTotal = (
  orgSubTotal: TSubTotal,
  inputEvents: TInputIncentivePaymentEvent[],
  inputOthers: TInputIncentivePaymentOther[]
): TSubTotal => {
  const { taxRate, totalPaymentCosts, totalSalesWithoutTax } = orgSubTotal;
  const totalPaymentSales = calcTotalPaymentSales(taxRate, inputEvents);
  const totalPaymentOthers = calcTotalPaymentOthers(taxRate, inputOthers);

  const total = totalPaymentSales + totalPaymentCosts + totalPaymentOthers;
  const tax = taxRate ? Math.round((total * taxRate) / 100) : 0;

  return {
    taxRate,
    totalPaymentSales,
    totalPaymentCosts,
    totalPaymentOthers,
    tax,
    totalPayment: total + tax,
    totalSalesWithoutTax,
  };
};

const groupByGroupId = (
  inputEvents: TInputIncentivePaymentEvent[]
): [number, TInputIncentivePaymentEvent[]][] =>
  Array.from(
    inputEvents.reduce((map, cur) => {
      const { groupId } = cur;
      const list = map.get(groupId);
      if (list) {
        list.push(cur);
      } else {
        map.set(groupId, [cur]);
      }
      return map;
    }, new Map<number, TInputIncentivePaymentEvent[]>())
  );

// ※団体ごとにsum -> 四捨五入 -> sum
const calcTotalPaymentSales = (
  taxRate: number | null,
  inputEvents: TInputIncentivePaymentEvent[]
): number =>
  groupByGroupId(
    inputEvents.filter((o) => o.taxRate === taxRate && o.isUsed)
  ).reduce((total, [_, events]) => {
    const groupsTotal = events.reduce((total, e) => {
      const totalPayment =
        e.totalSale && e.incentiveRate
          ? e.totalSale * (e.incentiveRate / 100)
          : 0;
      return total + totalPayment;
    }, 0);
    // インセンティブ率の設定が小数点第二位くらいまで許容されているのを考慮し、第四位くらいで丸める
    return total + Math.round(Math.round(groupsTotal * 10000) / 10000);
  }, 0);
const calcTotalSaleSales = (
  taxRate: number | null,
  inputEvents: TInputIncentivePaymentEvent[]
): number =>
  inputEvents
    .filter((e) => e.taxRate === taxRate && e.isUsed)
    .reduce((total, e) => total + (e.totalSale ? e.totalSale : 0), 0);

const calcTotalPaymentOthers = (
  taxRate: number | null,
  inputOthers: TInputIncentivePaymentOther[]
): number =>
  inputOthers
    .filter((o) => o.taxRate === taxRate)
    .reduce(
      (total, o) =>
        total + (o.unitPrice && o.quantity ? o.unitPrice * o.quantity : 0),
      0
    );

const calcTotalPayment = (subTotals: TSubTotal[]): number =>
  subTotals.reduce((total, t) => total + t.totalPayment, 0);

export {
  reCalcSubTotal,
  calcTotalPaymentSales,
  calcTotalSaleSales,
  calcTotalPaymentOthers,
  calcTotalPayment,
};
