import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import { getTaiwanInvoiceCsvUrl } from '../../../../ts/legacyUrl';
import { kanriUrl } from '../../../../ts/url';
import QueryString from 'query-string';
import { currentFiscalYearParam } from '../../../../ts/salesIndexUrl';

type TProps = {
  isPopup: boolean;
  isSen: boolean;
  isAsp: boolean;
  isTaiwan: boolean;
  partnerID: number;
  salesDepartmentOwnerno: number;
};
type TLinksProps = TProps & {
  isPopup: false;
};
type TPopupProps = TProps & {
  isPopup: true;
  onClickClose: MouseEventHandler;
  isOpen: boolean;
};
const SubMenuLinks: React.FC<TProps> = React.memo(
  ({ isPopup, isSen, isAsp, isTaiwan, partnerID, salesDepartmentOwnerno }) => {
    const componentClassName = isPopup
      ? 'c-popup_submenuList'
      : 'c-submenu_linkList';
    const itemClassName = `${componentClassName}_item`;

    const senDirectPlan = 1;
    const senDirectGoodsPlan = 2;
    const sakusakuPlan = 12;
    const teacherPlan = 11;

    const timingTypeEvent = 1;
    const aggregateTypePayment = 1;
    const amountCoonditionTypeLessThan = 1;
    const primarySalesToBeDecided = 0;

    const taiwanInvoiceCsvUrl = isTaiwan
      ? getTaiwanInvoiceCsvUrl(partnerID)
      : null;

    return (
      <ul className={componentClassName}>
        <li className={itemClassName}>
          {!isSen && !isAsp ? (
            <Link
              className="c-textlink"
              to={`/groups?${QueryString.stringify({
                partnerId: partnerID.toString(),
              })}`}
            >
              団体一覧
            </Link>
          ) : (
            <Link className="c-textlink" to={`/societies/`}>
              団体一覧
            </Link>
          )}
        </li>
        {!isSen && !isAsp && (
          <li className={itemClassName}>
            <a
              className="c-textlink"
              href={kanriUrl({
                action_owner_GROUPnew: 'true',
                agencyaccountno: partnerID.toString(),
              })}
            >
              団体登録
            </a>
          </li>
        )}
        <li className={itemClassName}>
          {!isSen ? (
            <Link
              className="c-textlink"
              to={`/events?${QueryString.stringify({
                'partnerIds[]': partnerID.toString(),
              })}`}
            >
              イベント一覧
            </Link>
          ) : (
            <Link
              className="c-textlink"
              to={`/events?${QueryString.stringify({
                'planIds[]': [
                  senDirectPlan,
                  senDirectGoodsPlan,
                  sakusakuPlan,
                  teacherPlan,
                ],
              })}`}
            >
              イベント一覧
            </Link>
          )}
        </li>
        <li className={itemClassName}>
          {isSen ? (
            <Link
              className="c-textlink"
              to={`/sales?${QueryString.stringify({
                timingType: timingTypeEvent,
                aggregateType: aggregateTypePayment,
                amountCoonditionType: amountCoonditionTypeLessThan,
                primarySales: primarySalesToBeDecided,
                ...currentFiscalYearParam(),
              })}`}
            >
              売れ行き（直販・先生）
            </Link>
          ) : (
            <a
              className="c-textlink"
              href={kanriUrl({
                action_owner_SALESsearch: 'true',
                agencyaccountno: partnerID.toString(),
                eigyoownerno: salesDepartmentOwnerno.toString(),
                search_period: 'this_nendo',
                outputmode: 'html',
              })}
            >
              売れ行き（今年度分）
            </a>
          )}
        </li>
        <li className={itemClassName}>
          <Link
            className="c-textlink"
            to={`/convertinfos/unbundled?partnerId=${partnerID}`}
          >
            アップ済みフォルダ
          </Link>
        </li>
        {taiwanInvoiceCsvUrl && (
          <li className={itemClassName}>
            <a className="c-textlink" href={taiwanInvoiceCsvUrl}>
              発票CSV
            </a>
          </li>
        )}
      </ul>
    );
  }
);

const SubMenu: React.FC<TLinksProps | TPopupProps> = React.memo((props) => {
  // links
  if (!props.isPopup) {
    return (
      <div className="c-submenu_sub u-spOff">
        <SubMenuLinks {...props} />
      </div>
    );
  }

  // popup
  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="c-popup_details c-popup_submenuWrap">
      <SubMenuLinks {...props} />
      <p className="c-popup_closeBtn" onClick={props.onClickClose}>
        <i className="c-icon_small c-icon_close"></i>閉じる
      </p>
    </div>
  );
});

export default SubMenu;
