import React from 'react';

import { dateFormat } from '../../../../ts/formatTools';
import {
  getProxyLoginUrl,
  getSendingInvitationMailUrl,
} from '../../../../ts/legacyUrl';
import { ownerUrl } from '../../../../ts/url';
import { AuthInfo } from '../../../AuthProvider';
import Accordion from '../../../shared/Accordion/App';
import StatusLabel from '../../../shared/StatusLabel/App';
import { TAreas, TOwner, TSenEmployeeInformation } from './types';

const OwnerAreas: React.FC<{ area: number; areas: TAreas }> = React.memo(
  ({ area, areas }) => (
    <span className="p-partnerSummary_adminUser_authoritySwitch">
      {areas.map((a) => (
        <span
          className={a.key === area ? 'is-authority_on' : 'is-authority_off'}
          key={a.key}
        >
          {a.value}
        </span>
      ))}
    </span>
  )
);

const SenEmployeeInformation: React.FC<{
  senEmployeeInformation: TSenEmployeeInformation | null;
  areas: TAreas;
}> = React.memo(({ senEmployeeInformation, areas }) => {
  if (senEmployeeInformation === null) {
    return null;
  }

  return (
    <>
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleSales}
        statusName="売上"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleAdmin}
        statusName="管理"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleDelivery}
        statusName="手配"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleReport}
        statusName="レポート"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleToReadUserPrivacy}
        statusName="個人情報"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRolePartnerSupport}
        statusName="サポート"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleOperation}
        statusName="業務"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.hasRoleCorporationPlanning}
        statusName="経企"
      />
      <StatusLabel
        isPositive={!!senEmployeeInformation.isSalesDepartment}
        statusName="営業"
      />
      <OwnerAreas area={senEmployeeInformation.area} areas={areas} />
      {senEmployeeInformation.photographerID && (
        <span className="c-label p-is-authority_camera">
          <i className="c-icon_cameraman p-is-authority_icon_camera u-mgr_xs"></i>
          <span>{senEmployeeInformation.photographerID}</span>
        </span>
      )}
    </>
  );
});

const Owner: React.FC<{
  authInfo: AuthInfo;
  owner: TOwner;
  areas: TAreas;
  isSen: boolean;
}> = React.memo(({ authInfo, owner, areas, isSen }) => (
  <div className="l-flex_between l-flex_between__isSpInput l-relative u-mgt_0">
    <div className="p-partnerSummary_adminUser_line">
      <div className="p-partnerSummary_adminUser_authorityList l-flex l-flex__isSpInput u-mgb_xs">
        <div>
          <span className="u-mgr_s t-textColor_sub">
            <span className="c-label c-label__monotone u-mgr_xs">管理者ID</span>
            <span>{owner.id}</span>
          </span>
          <span className="u-mgr_s t-textColor_sub">
            <span className="c-label c-label__monotone u-mgr_xs">登録日</span>
            <span>{dateFormat(owner.createdday)}</span>
          </span>
        </div>
        <SenEmployeeInformation
          senEmployeeInformation={owner.senEmployeeInformation}
          areas={areas}
        />
      </div>
      <div className="l-flex_between l-flex_between__isSpInput">
        <div className="l-flex l-frex__isSpInput u-w_100">
          <strong className="u-mgr_s p-partnerSummary_adminUser_name">
            {owner.name}
          </strong>
          {isSen && (
            <p className="p-partnerSummary_adminUser_tel">
              <i className="c-icon_tel u-mgr_xs"></i>
              {owner.telephonenumber ? (
                <span>{owner.telephonenumber}</span>
              ) : (
                <span className="c-nullMessage_text">未登録</span>
              )}
            </p>
          )}
          <p className="p-partnerSummary_adminUser_mail">
            <i className="c-icon_mail u-mgr_xs"></i>
            {owner.mailaddress ? (
              <span>{owner.mailaddress}</span>
            ) : (
              <span className="c-nullMessage_text">未登録</span>
            )}
          </p>
          {!isSen && (
            <div>
              <div className="u-fz_s l-flex_end l-flex_end__isSpInput u-mgb_xs">
                <a
                  className={`c-btn_manage c-btn_middle_wide u-mgr_s${
                    owner.conditions.isEnabledProxyLogin ? '' : ' is-disabled'
                  }`}
                  href={
                    owner.conditions.isEnabledProxyLogin
                      ? getProxyLoginUrl(authInfo.mailaddress, owner.id)
                      : '/'
                  }
                >
                  ログイン
                </a>
                <a
                  className={`c-btn_manage c-btn_middle_wide u-mgr_s${
                    owner.conditions.isEnabledSendingInvitationMail
                      ? ''
                      : ' is-disabled'
                  }`}
                  href={
                    owner.conditions.isEnabledSendingInvitationMail
                      ? getSendingInvitationMailUrl(owner.id)
                      : '/'
                  }
                >
                  招待メール
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="p-partnerSummary_adminUser_authorityEdit">
      {owner.conditions.isEditable ? (
        <a
          className="c-btn_rectangle c-btn_edit"
          href={ownerUrl(`owners/${owner.id}/edit`)}
        >
          <i className="c-icon_edit"></i>
        </a>
      ) : (
        <a className="c-btn_rectangle c-btn_edit is-disabled" href="/">
          <i className="c-icon_edit"></i>
        </a>
      )}
    </div>
  </div>
));

const Owners: React.FC<{
  authInfo: AuthInfo;
  owners: TOwner[];
  areas: TAreas;
  isSen: boolean;
  partnerId: number;
}> = React.memo(({ authInfo, owners, areas, isSen, partnerId }) => {
  const hasNoOwnersElement = (
    <div className="c-emptyState_box u-fz_s">
      <p>登録がありません。</p>
    </div>
  );
  const ownerElements = (
    <ul className="c-indexList u-mgt_s">
      {owners.map((owner) => (
        <li className="c-indexListItem" key={owner.id}>
          <Owner
            authInfo={authInfo}
            owner={owner}
            areas={areas}
            isSen={isSen}
          />
        </li>
      ))}
    </ul>
  );

  const roles = [
    { role: '売上', description: '売れ行き集計を閲覧できる権限' },
    { role: '管理', description: 'パートナーと管理者の操作ができる権限' },
    { role: '手配', description: '手配作業ができる権限' },
    { role: 'レポート', description: 'DBレポートを閲覧できる権限' },
    { role: '個人情報', description: '会員情報と注文情報を閲覧できる権限' },
    {
      role: 'サポート',
      description: '全パートナーで指定管理者でのログインができる権限',
    },
    { role: '業務', description: '業務部に許可されている操作ができる権限' },
    { role: '経企', description: '経営企画室に許可されている操作ができる権限' },
    { role: '営業', description: '営業に許可されている操作ができる権限' },
  ];
  const RoleDescriptions = (
    <Accordion labelClose="権限とは" labelOpen="権限とは">
      <div className="t-bgBox_gray">
        {[...Array(Math.ceil(roles.length / 2))].map((_, i) => (
          <ul className="c-label_line l-flex_between" key={i}>
            {roles.slice(2 * i, 2 * (i + 1)).map((r, index) => (
              <li className="c-label_innerHalf" key={index}>
                <ul className="l-flex">
                  <li className="c-dataLabel">{r.role}</li>
                  <li className="c-dataValue">{r.description}</li>
                </ul>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </Accordion>
  );
  const ownerCreateUrl = ownerUrl('owners/create', {
    partner_id: partnerId.toString(),
  });
  return (
    <div className="c-frame c-ownerList">
      <div className="l-flex_between_center u-mgb_s">
        <h4 className="c-section_title">管理者一覧</h4>
        <a className="c-btn_rectangle c-btn_create" href={ownerCreateUrl}>
          <i className="c-icon_create"></i>
        </a>
      </div>
      {isSen && RoleDescriptions}
      {/* TODO: orderは現状も動いてないのでomit
      <template v-if="partner.isSenDirect">
        <hr className="u-line_plane u-mgb_0" />
        <div className="l-flex_between_center l-flex_between_center__isSpInput">
          <ul className="l-flex">
            <li className="u-mgt_s u-mgr_xs" v-for="(data, key) in sortColumns"><a className="c-btn_middle_wide c-btn_toggle_sort u-fz_xs" @click="onSortColumnClicked(key, data.sortType, data.isSelected)" :className="{ 'is-desc' : data.isSelected }">{ data.name }</a></li>
          </ul>
          <div className="l-relative p-partnerSummary_adminUser_explainLink">
            <help-authority></help-authority>
          </div>
        </div>
      </template>
      */}
      {owners.length ? ownerElements : hasNoOwnersElement}
    </div>
  );
});

export default Owners;
