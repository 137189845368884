export type MailLog = {
  sgEventId: string;
  createdAt: string;
  email: string;
  event: Event;
  response?: string;
  reason?: string;
  status?: string;
  ip?: string;
};

export const event = [
  'processed',
  'dropped',
  'delivered',
  'deferred',
  'bounce',
  'open',
  'click',
  'spamreport',
] as const;

export type Event = typeof event[number];

export const eventLabel: { [key in Event]: string } = {
  processed: '処理済み',
  dropped: '送信不可',
  delivered: '送信完了',
  deferred: '時的な送信失敗（リトライあり）',
  bounce: '送信失敗',
  open: '開封',
  click: 'URLクリック',
  spamreport: 'スパム',
};
