import React from 'react';

type Props = {
  id: number;
};
export const Header = React.memo<Props>(function Header({ id }) {
  return (
    <div className="l-flex_between_center p-groupsSummary_Label">
      <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
        注文ID: {id}
      </span>
    </div>
  );
});
