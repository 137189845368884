import {
  NumberInput,
  RadioBoxes,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { useEffect } from 'react';
import { TValidator } from '@/components/shared/Form/types';
import { FC, useState } from 'react';
import { DataResponse, Constants, TAdditionalFee } from '../../../type';
import { useFormContext } from 'react-hook-form';
import styles from '../../Form.module.scss';

const Additional: FC<{
  additional: TAdditionalFee;
  index: number;
  constants: Constants;
  validator: TValidator;
  handleDeleteClick: (index: number) => void;
}> = ({ additional, index, constants, validator, handleDeleteClick }) => {
  return (
    <div>
      <div className="l-flex">
        <div className="t-col_8">
          <div className="t-textColor_sub">ID</div>
          <NumberInput
            name={`eventRequestPhotographers.${index}.additionalFees.${additional.rowNumber}.id`}
            validator={validator}
            isDisabled={true}
          />
        </div>
        <div className="t-col_16 u-mgl_m">
          <div className="l-flex">
            <div>
              <div className="t-textColor_sub">金額(税込)</div>
              <TextInput
                name={`eventRequestPhotographers.${index}.additionalFees.${additional.rowNumber}.price`}
                validator={validator}
              />
            </div>
          </div>
        </div>
        <div className="t-col_8 u-mgl_m">
          <div className="t-textColor_sub">税率</div>
          <RadioBoxes
            name={`eventRequestPhotographers.${index}.additionalFees.${additional.rowNumber}.consumptionTaxPercent`}
            choices={constants.consumptionTaxPercent}
            validator={validator}
          />
        </div>
        <div className="t-col_8 u-mgl_m">
          <div className="t-textColor_sub">行削除ボタン</div>
          <div
            className={styles.requestFeeDeleteButton}
            onClick={() => handleDeleteClick(additional.rowNumber)}
          >
            -削除
          </div>
        </div>
      </div>
      <div className="t-textColor_sub">備考</div>
      <TextInput
        name={`eventRequestPhotographers.${index}.additionalFees.${additional.rowNumber}.externalMemo`}
        validator={validator}
      />
      <div className="t-textColor_sub">※明細に表示されません</div>
      <div className={styles.requestFeeDottedLine}></div>
    </div>
  );
};

export const Additionals: FC<{
  eventRequestPhotographer: DataResponse;
  index: number;
  constants: Constants;
  validator: TValidator;
}> = ({ eventRequestPhotographer, index, constants, validator }) => {
  const methods = useFormContext();
  const emptyAdditional: TAdditionalFee = {
    id: undefined,
    rowNumber: 0,
    price: 0,
    externalMemo: undefined,
    consumptionTaxPercent: 10,
  };

  const [maxRowNumber, setMaxRowNumber] = useState<number>(0);
  const [additionalFees, setAdditionalFees] = useState<TAdditionalFee[]>(
    eventRequestPhotographer.additionalFees ?? [emptyAdditional]
  );

  useEffect(() => {
    methods.setValue(
      `eventRequestPhotographers.${index}.additionalFees`,
      additionalFees
    );
    const maxRowNumberDefault = additionalFees.reduce(
      (max, fee) => Math.max(max, fee.rowNumber),
      0
    );
    setMaxRowNumber(maxRowNumberDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  additionalFees.forEach((additionalFee, addIndex) => {
    if (!additionalFee.rowNumber) {
      additionalFee.rowNumber = addIndex;
    }
  });

  const handleAddClick = () => {
    if (additionalFees.length < 10) {
      const newAdditionalFee = {
        ...emptyAdditional,
        rowNumber: maxRowNumber + 1,
      };
      setMaxRowNumber(maxRowNumber + 1);
      setAdditionalFees([...additionalFees, newAdditionalFee]);
    } else {
      alert('これ以上追加できません。');
    }
  };

  const handleDeleteClick = (rowNumber: number) => {
    const updatedFees = additionalFees.filter(
      (fee) => fee.rowNumber !== rowNumber
    );
    methods.setValue(
      `eventRequestPhotographers.${index}.additionalFees.${rowNumber}`,
      null
    );
    setAdditionalFees(updatedFees);
  };

  return (
    <div className="c-frame">
      <div className="l-flex">
        <div className={styles.requestFeeEditLabel}>追加報酬(その他)</div>
        <div className="c-dataValue">
          {additionalFees.map((additionalFee) => (
            <Additional
              key={additionalFee.rowNumber}
              index={index}
              additional={additionalFee}
              constants={constants}
              validator={validator}
              handleDeleteClick={handleDeleteClick}
            />
          ))}
          <div className={styles.requestFeeAddButton} onClick={handleAddClick}>
            +追加
          </div>
        </div>
      </div>
    </div>
  );
};
