import React, { FC, useMemo } from 'react';
import { TIndexResponse, TFormInputs } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Checkboxes,
  DateInput,
  MultiSelect,
  TextInput,
} from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import Accordion from '@/components/shared/Accordion/App';
import { FieldValues } from 'react-hook-form';

export const SearchForm: FC<{
  response: TIndexResponse;
  onSubmit: (form: TFormInputs) => void;
  queryParams: Partial<FieldValues>;
}> = ({ response, onSubmit, queryParams }) => {
  const methods = useForm<TFormInputs>({ defaultValues: queryParams });
  const validator: TValidator = { rules: {} };
  const photographerChoices = useMemo(
    () =>
      response.constants.photographers.map((photographer) => ({
        key: photographer.id,
        value: `${photographer.id} / ${photographer.sei} ${photographer.mei}`,
      })),
    [response]
  );
  const photographerOrganizationChoices = useMemo(
    () =>
      response.constants.photographerOrganizations.map(
        (photographerOrganization) => ({
          key: photographerOrganization.id,
          value: `${photographerOrganization.id} / ${photographerOrganization.name}`,
        })
      ),
    [response]
  );
  const eventAssignStatusesChoices = useMemo(
    () =>
      response.constants.eventAssignStatuses.map((eventAssignStatus) => ({
        key: eventAssignStatus.key,
        value: `${eventAssignStatus.value}`,
      })),
    [response]
  );

  const societiesChoices = useMemo(
    () =>
      response.constants.societies.map((society) => ({
        key: society.id,
        value: `${society.id} / ${society.name}`,
      })),
    [response]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="c-searchForm">
          <div className="c-frame">
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerIds"
                      validator={validator}
                      choices={photographerChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="photographerOrganizationIds"
                      validator={validator}
                      choices={photographerOrganizationChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">団体</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="societyIds"
                      validator={validator}
                      choices={societiesChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="l-flex_start l-col_wrap">
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">イベント</li>
                  <li className="c-dataValue">
                    <TextInput
                      name="eventId"
                      validator={validator}
                      placeholder="IDまたはイベント名を入力"
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_8">
                <ul className="l-flex">
                  <li className="c-dataLabel">アサイン状況</li>
                  <li className="c-dataValue">
                    <MultiSelect
                      name="eventAssignStatuses"
                      validator={validator}
                      choices={eventAssignStatusesChoices}
                      windowed={true}
                    />
                  </li>
                </ul>
              </li>
              <li className="l-col_12">
                <ul className="l-flex">
                  <li className="c-dataLabel">撮影日</li>
                  <li className="c-dataValue">
                    <ul className="c-input_dateBlock">
                      <li>
                        <DateInput
                          name="photographingDayStartAt"
                          validator={validator}
                        />
                      </li>
                      <li>
                        <DateInput
                          name="photographingDayEndAt"
                          validator={validator}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <Accordion
              labelClose="詳細検索"
              labelOpen="詳細検索"
              isDisableScroll={true}
            >
              <ul className="l-flex_start l-col_wrap">
                <li className="l-col_24">
                  <ul className="l-flex">
                    <li className="c-dataLabel">ステータス</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="statuses"
                        validator={validator}
                        choices={response.constants.statuses}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex">
                    <li className="c-dataLabel">カメラマン都道府県</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="prefectures"
                        validator={validator}
                        choices={response.constants.prefectures}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex">
                    <li className="c-dataLabel">報酬種類</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="requestFeeTypes"
                        validator={validator}
                        choices={response.constants.requestFeeTypes}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex">
                    <li className="c-dataLabel">拘束時間</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="overTimeFeeTypes"
                        validator={validator}
                        choices={response.constants.overTimeFeeTypes}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex">
                    <li className="c-dataLabel">イベント種類</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="eventTypes"
                        validator={validator}
                        choices={response.constants.eventTypes}
                      />
                    </li>
                  </ul>
                  <ul className="l-flex">
                    <li className="c-dataLabel">備品</li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="isMonoblock"
                        validator={validator}
                        choices={[{ key: 1, value: 'モノブロック有' }]}
                      />
                      <Checkboxes
                        name="isBackPaperStand"
                        validator={validator}
                        choices={[{ key: 1, value: 'バック紙スタンド有' }]}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </Accordion>
            <div className="l-flex_center_line">
              <button className="c-btn_large c-btn_Search c-input_submit">
                検索
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
