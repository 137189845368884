import { NumberInput, RadioBoxes } from '@/components/shared/Form/Inputs';
import { TValidator } from '@/components/shared/Form/types';
import { FC } from 'react';
import { Constants } from '../../../type';
import styles from '../../Form.module.scss';

export const Equipment: FC<{
  index: number;
  constants: Constants;
  validator: TValidator;
}> = ({ index, constants, validator }) => {
  return (
    <div className="c-frame">
      <div className="l-flex">
        <div className={styles.requestFeeEditLabel}>追加報酬(機材)</div>
        <div className="c-dataValue">
          <div className="l-flex">
            <div className="t-col_16">
              <div className="l-flex">
                <div>
                  <div className="t-textColor_sub">ひな壇(個数)</div>
                  <NumberInput
                    name={`eventRequestPhotographers.${index}.equipmentFee.stairsStandQuantity`}
                    validator={validator}
                  />
                </div>
                <div className="t-col_8 u-mgl_m">
                  <div className="t-textColor_sub">スタンド(個数)</div>
                  <NumberInput
                    name={`eventRequestPhotographers.${index}.equipmentFee.backPaperStandQuantity`}
                    validator={validator}
                  />
                </div>
                <div className="t-col_8 u-mgl_m">
                  <div className="t-textColor_sub">モノブロック(個数)</div>
                  <NumberInput
                    name={`eventRequestPhotographers.${index}.equipmentFee.monoblockQuantity`}
                    validator={validator}
                  />
                </div>
              </div>
            </div>
            <div className="t-col_8 u-mgl_m">
              <div className="t-textColor_sub">税率</div>
              <RadioBoxes
                name={`eventRequestPhotographers.${index}.equipmentFee.consumptionTaxPercent`}
                choices={constants.consumptionTaxPercent}
                validator={validator}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
