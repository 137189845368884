const transformBoolToNumber = (
  object: Record<string, unknown>
): Record<string, unknown> => {
  const copied = { ...object };
  Object.keys(copied).forEach((key) => {
    if (copied[key] === true) {
      copied[key] = 1;
    } else if (copied[key] === false) {
      copied[key] = undefined;
    }
  });
  return copied;
};

export { transformBoolToNumber };
