import { TChoice } from '../Form/types';
import { TCategory } from './types';
import { TParentCategory } from './Index/types';

export const changableCategories = (
  currentCategory: TCategory,
  parentCategories: TParentCategory[]
): TChoice[] =>
  parentCategories.flatMap((parentcategory) =>
    parentcategory.categories
      .filter(
        (category) =>
          category.id !== currentCategory.id &&
          category.phototypemasterno === currentCategory.phototypemasterno
      )
      .map((category) => ({
        key: category.id,
        value: `${parentcategory.name} - ${category.name}`,
      }))
  );
