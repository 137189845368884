import React from 'react';
import { Link } from 'react-router-dom';
import { ownerUrl } from '../../../ts/url';
import { TEventHeaderProps, TShortModel } from './types';

const Model: React.FC<{
  model: TShortModel | null | undefined;
  modelName: string;
  path: string;
  isLinkTo: boolean;
  isLinkToOwner?: boolean;
}> = React.memo(({ model, modelName, path, isLinkTo, isLinkToOwner }) =>
  model ? (
    // divはレイアウト用
    <div className="l-flex_center u-mgr_m">
      <span className="c-label c-label__monotone u-fz_xs">
        {modelName}ID：{model.id}
      </span>
      <span className="u-fz_l">
        {isLinkTo ? (
          isLinkToOwner ? (
            <a className="c-textlink" href={ownerUrl(`${path}/${model.id}`)}>
              {model.name}
            </a>
          ) : (
            <Link className="c-textlink" to={`/${path}/${model.id}`}>
              {model.name}
            </Link>
          )
        ) : (
          model.name
        )}
      </span>
    </div>
  ) : null
);

const EventHeader: React.FC<TEventHeaderProps> = React.memo(
  ({ event, group, society, isDisplayEventAsText }) => (
    <div className="l-center_wrap">
      <div className="c-frame l-flex_center">
        {/* 団体は society or group がどちらか取得され、表示される */}
        <Model model={group} modelName="団体" path="groups" isLinkTo />
        <Model model={society} modelName="団体" path="societies" isLinkTo />
        <Model
          model={event}
          modelName="イベント"
          path="events"
          isLinkTo={!isDisplayEventAsText}
          isLinkToOwner={!!group}
        />
      </div>
    </div>
  )
);

export default EventHeader;
