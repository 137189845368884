import React, { useState, MouseEventHandler } from 'react';
import dayjs from 'dayjs';
import { TValidatorResponse } from '@/components/shared/Form/types';

import Help from '../../Help/App';
import { dateFormat } from '../../../../ts/formatTools';
import { getApiPath } from '../../../shared/IncentivePayments/getApiPath';
import { canSetPaidAt, STATUS_PAID } from '../constants';
import { update } from './update';
import { TTarget } from './types';

type TPaidAtProps = {
  target: TTarget;
  canEdit: boolean;
  id: number;
  paidAt: string | null;
  status: number;
  bankAccountHasMistake: number | null;
};
const SearchResultItemPaidAt: React.FC<TPaidAtProps> = React.memo(
  ({ target, canEdit, id, paidAt, status, bankAccountHasMistake }) => {
    const [inputPaidAt, setInputPaidAt] = useState(
      paidAt ? dayjs(paidAt).format('YYYY-MM-DD') : ''
    );
    const [validator, setValidator] = useState<TValidatorResponse>({
      messages: {},
      hasError: false,
      rules: {},
    });
    const canInputPaidAt =
      canEdit && canSetPaidAt(status, bankAccountHasMistake, target);

    const submitPaidAt: MouseEventHandler = async (e) => {
      e.preventDefault();
      if (!inputPaidAt) {
        // TODO: alert UI
        window.alert('振込日を入力してください。');
        return;
      }
      await update(
        '振込日登録',
        getApiPath(target, 'update_paid_at'),
        {
          ids: [id],
          paidAt: inputPaidAt,
        },
        validator,
        setValidator
      );
    };

    if (!canInputPaidAt) {
      return (
        <>
          {dateFormat(paidAt)}
          {!!bankAccountHasMistake && (
            <Help>口座情報に不備があるため、振込日が入力できません。</Help>
          )}
        </>
      );
    }

    return (
      <>
        <input
          type="date"
          className={`c-input_plane c-input_date u-mgr_s c-incentivePayments_paidAt${
            status === STATUS_PAID ? '' : ' c-incentivePayments_paidAt__unpaid'
          }`}
          placeholder="振込日"
          onChange={(e) => setInputPaidAt(e.target.value)}
          value={inputPaidAt}
        />
        <button
          className={
            'c-btn_middle c-btn_primary' +
            (canInputPaidAt && inputPaidAt ? '' : ' is-disabled')
          }
          type="button"
          onClick={submitPaidAt}
        >
          登録する
        </button>
      </>
    );
  }
);

export default SearchResultItemPaidAt;
