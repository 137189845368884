import React from 'react';
import SearchBox from './SearchBox';
import MenuList from './MenuList';
import './globalmenu.scss';

const App: React.FC = () => {
  return (
    <div className="l-global_wrap">
      <h2 className="p-global_title">管理画面</h2>
      <MenuList />
      <SearchBox />
    </div>
  );
};
export default App;
