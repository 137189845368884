import React, { MouseEventHandler, useState } from 'react';
import { TFormInputs, TFormItems, TDeleteResponse } from './types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  TextInput,
  RadioBoxes,
  Checkboxes,
  SingleSelect,
} from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { Link, useHistory } from 'react-router-dom';
import { fetcher, toMessages } from '../../../../ts/useApi';
import './app.scss';

const SaveApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

const DeleteApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSaveFormProps = {
  ownerno?: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  formItems: TFormItems;
  defaultValues: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
  errorMessages: string[];
  submitButton: string;
  cancelLink: string;
};

const SaveForm: React.FC<TSaveFormProps> = React.memo(
  ({
    ownerno,
    validator,
    formItems,
    defaultValues,
    onSubmit,
    errorMessages,
    submitButton,
    cancelLink,
  }) => {
    const methods = useForm<Required<TFormInputs>>({ defaultValues });

    const [deleteErrorMessages, setDeleteErrorMessages] = useState<string[]>(
      []
    );
    const history = useHistory();
    const openDeleteModal: MouseEventHandler = async (e) => {
      e.preventDefault();
      const ok = window.confirm('管理者を削除してよろしいですか？');
      if (ok) {
        try {
          const deleteResponse = (await fetcher(
            `/api/owneraccounts/${ownerno}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )) as TDeleteResponse;
          if (!deleteResponse.data) {
            setDeleteErrorMessages(toMessages(new Error('削除に失敗しました')));
            return;
          }
          setDeleteErrorMessages([]);
          history.push('/administrator');
        } catch (e) {
          setDeleteErrorMessages(toMessages(e));
        }
      }
    };

    const watchEmployeeType = methods.watch('employeetype');

    return (
      <FormProvider {...methods}>
        <SaveApiError messages={errorMessages} />
        <DeleteApiError messages={deleteErrorMessages} />
        <ApiValidationErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="c-frame">
            <span className="c-statusLabel c-statusLabel__monotone u-fz_xs o-owneraccount-statusLabel o-owneraccounts-statusLabel">
              管理者ID {ownerno}
            </span>
            {ownerno && (
              <div className="l-flex_end">
                <button
                  className="c-btn_rectangle c-btn_delete u-mgr_s"
                  onClick={openDeleteModal}
                >
                  <i className="c-icon_Xsmall c-icon_trash" />
                </button>
              </div>
            )}
            <hr className="u-line_plane o-owneraccounts-line_plane" />

            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>管理者名</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="ownername"
                      placeholder="管理者名"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>電話番号</label>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="telephone"
                      placeholder="03-1234-5678"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
            </ul>

            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>
                      メール
                      <br className="sp_off" />
                      アドレス
                    </label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextInput
                      name="mailaddress"
                      placeholder="メールアドレス"
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf"></li>
            </ul>

            {/* パスワード */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>パスワード</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    {ownerno ? (
                      <Link
                        className=""
                        to={`/administrator/${ownerno}/change_passwd`}
                      >
                        パスワードを変更する
                      </Link>
                    ) : (
                      <span>
                        新規登録時は自動的に割り当てられます。
                        <br />
                        初回ログイン前にパスワードを再設定してください
                      </span>
                    )}
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf"></li>
            </ul>

            {/* 社員区分 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>社員区分</label>
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="employeetype"
                      choices={formItems.administratoroption.employeeType}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf">
                {watchEmployeeType === 1 && (
                  <SingleSelect
                    name="photographer_id"
                    choices={formItems.internalPhotographers}
                    placeholder="カメラマン"
                    validator={validator}
                  />
                )}
              </li>
            </ul>

            {/* 権限 */}
            <ul className="l-flex_between c-label_line l-flex is-sp_input u-mgb_s">
              <li className="c-label_innerHalf">
                <ul className="l-flex_between c-label_line is-sp_input">
                  <li className="c-dataLabel">
                    <label>権限</label>
                  </li>
                  <li className="c-dataValue">
                    <Checkboxes
                      name="authority"
                      choices={formItems.administratoroption.authority}
                      validator={validator}
                    />
                  </li>
                </ul>
              </li>
              <li className="c-label_innerHalf"></li>
            </ul>
            <div className="u-align_center u-mgb_m">
              <a
                className="c-btn_large c-btn_cancel u-mgr_m c-input_submit"
                href={cancelLink}
              >
                キャンセル
              </a>
              <input
                className="c-btn_large c-btn_primary is-arrow c-input_submit"
                type="submit"
                value={submitButton}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }
);

export default SaveForm;
