import { FC } from 'react';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import styles from './SmallCheckBox.module.scss';

export const SmallCheckBox: FC<{
  checked: boolean;
  setChecked: (checked: boolean) => void;
}> = ({ checked, setChecked }) => {
  return (
    <label className={styles.label}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked ? true : false)}
        style={{ display: 'none' }}
      />
      {checked ? (
        <RiCheckboxFill size="24px" color="#ff8e42" />
      ) : (
        <RiCheckboxBlankLine size="24px" />
      )}
    </label>
  );
};
