import React, { useState } from 'react';
import { fetcher, postJson } from '@/ts/fetch';
import { TPhotograph, TValidateMessages } from './types';
import { getMd5FromFile } from '../md5';
import { Link } from 'react-router-dom';
import { usePhotographIndexState } from './context';
import { MIN_UPLOAD_FILE_SIZE } from '../const';

type TRotateResponse = {
  data: {
    url: string;
  };
};

type TReplaceResultResponse = {
  data: {
    url: string;
    validateMessages: TValidateMessages;
  };
};

type TReplacePrepareResponse = {
  data: {
    postUrl: string;
    srcKey: string;
    headUrl: string;
  };
};

const JIZEN_HIDDEN = 1;

const ValidationError: React.FC<{ validateMessages: TValidateMessages }> = ({
  validateMessages,
}) => {
  const validationErrorNum =
    validateMessages.error.length + validateMessages.warn.length;
  const validationMessage = ([] as string[])
    .concat(
      validateMessages.error.length > 0 ? ['【エラー】'] : [],
      validateMessages.error,
      validateMessages.warn.length > 0 ? ['【注意】'] : [],
      validateMessages.warn
    )
    .join('\n');
  return (
    <div className="u-align_center">
      <span className="p-photographs_validationError" title={validationMessage}>
        【！】{validationErrorNum}
      </span>
    </div>
  );
};

const Photograph: React.FC<{
  photograph: TPhotograph;
  handlePhotographChange: (photograph: TPhotograph) => void;
  handleEventThumbnailChanged: () => void;
  canChangeCategory: boolean;
  handleChecked: (b: boolean) => void;
  checked: boolean;
  handleCategoryChange: () => void;
  isDirectPhotoPlan: boolean;
}> = ({
  photograph,
  handlePhotographChange,
  canChangeCategory,
  handleEventThumbnailChanged,
  handleChecked,
  checked,
  handleCategoryChange,
  isDirectPhotoPlan,
}) => {
  // TODO: 二重クリック禁止
  const [rotated, setRotated] = useState(false);
  const {
    handleApiError,
    showMultipleUpdate,
    showValidationError,
    showIndividualHide,
  } = usePhotographIndexState();
  const displayedPhotograph = `${photograph.id}[${photograph.photoname}]`;
  const show = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await postJson(`/api/photographs/${photograph.id}/show`);
      handlePhotographChange({ ...photograph, isHide: false });
    } catch (e) {
      handleApiError(
        e,
        `${displayedPhotograph}の写真を表示にするのに失敗しました`
      );
    }
  };
  const hide = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await postJson(`/api/photographs/${photograph.id}/hide`);
      handlePhotographChange({ ...photograph, isHide: true });
    } catch (e) {
      handleApiError(
        e,
        `${displayedPhotograph}の写真を非表示にするのに失敗しました`
      );
    }
  };
  const rotateLeft = async (e: React.MouseEvent) => {
    e.preventDefault();
    setRotated(true);
    try {
      const {
        data: { url },
      } = (await postJson(
        `/api/photographs/${photograph.id}/rotate_left`
      )) as TRotateResponse;
      handlePhotographChange({ ...photograph, url });
    } catch (e) {
      handleApiError(
        e,
        `${displayedPhotograph}の写真を左回転するのに失敗しました`
      );
    }
  };
  const rotateRight = async (e: React.MouseEvent) => {
    e.preventDefault();
    setRotated(true);
    try {
      const {
        data: { url },
      } = (await postJson(
        `/api/photographs/${photograph.id}/rotate_right`
      )) as TRotateResponse;
      handlePhotographChange({ ...photograph, url });
    } catch (e) {
      handleApiError(
        e,
        `${displayedPhotograph}の写真を右回転するのに失敗しました`
      );
    }
  };
  const changeCategory = (e: React.MouseEvent) => {
    e.preventDefault();
    handleCategoryChange();
  };
  const setEventThumbnail = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await postJson(`/api/photographs/${photograph.id}/set_event_thumbnail`);
      handleEventThumbnailChanged();
    } catch (e) {
      handleApiError(
        e,
        `${displayedPhotograph}の写真をイベントサムネイルに設定するのに失敗しました`
      );
    }
  };
  const handleReplace = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const getETag = async (url: string) => {
      const result = await fetch(url, { method: 'HEAD' });
      return result.headers.get('etag');
    };
    try {
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }
      if (file.size < MIN_UPLOAD_FILE_SIZE) {
        alert('1KB以下のファイルはアップロードできません');
        return;
      }
      const checksum: string = await getMd5FromFile(file);
      const {
        data: { postUrl, srcKey, headUrl },
      } = (await postJson(
        `/api/photographs/${photograph.id}/replace_prepare`
      )) as TReplacePrepareResponse;
      await fetch(postUrl, {
        method: 'PUT',
        body: file,
      });
      const startEtag = await getETag(headUrl);
      await postJson(`/api/photographs/${photograph.id}/replace_start`, {
        srcKey,
        checksum,
        clientPath: file.name,
      });
      for (let i = 0; i < 20; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const currentEtag = await getETag(headUrl);
        if (currentEtag !== startEtag) {
          const { data } = (await fetcher(
            `/api/photographs/${photograph.id}/replace_result`
          )) as TReplaceResultResponse;
          handlePhotographChange({ ...photograph, ...data });
          return;
        }
      }
      // TODO: タイムアウトしたときの処理
    } catch (e) {
      // TODO: エラー処理
      console.log(e);
    }
  };
  const hasValidationError =
    photograph.validateMessages.error.length > 0 ||
    photograph.validateMessages.warn.length > 0;
  return (
    <div
      className={`p-photographs_photoContainer ${
        photograph.isMainEventThumbnail
          ? 'p-photographs_photoContainer__mainThumbnail'
          : photograph.isHide
          ? 'p-photographs_photoContainer__hide'
          : ''
      } ${
        hasValidationError
          ? 'p-photographs_photoContainer__validationError'
          : ''
      }`}
      data-testid={`photograph-${photograph.id}`}
    >
      {/* TODO: lazy load */}
      <Link
        to={`/photographs/${photograph.id}`}
        className="p-photographs_photoLink"
        target="_blank"
      >
        <img src={photograph.url} className="p-photographs_photo" alt="" />
      </Link>
      <div className="u-align_center u-mgt_s">
        {showMultipleUpdate ? (
          <label className="c-checkboxLabel u-align_center">
            <input
              className="c-checkbox"
              type="checkbox"
              onChange={(e) => handleChecked(e.target.checked)}
              checked={checked}
            />
            <span className="c-label_checkbox small">
              {photograph.photoname}
              <br />[{photograph.id}]
            </span>
          </label>
        ) : (
          <span>
            {photograph.photoname}
            <br />[{photograph.id}]
          </span>
        )}
      </div>
      <div className="p-photographs_photographername">
        {photograph.photographername}
      </div>
      {photograph.soldCount > 0 && (
        <div className="p-photographs_soldCount">
          {photograph.soldLinkUrl ? (
            <a href={photograph.soldLinkUrl} className="u-mgl_xs">
              {photograph.soldCount}
            </a>
          ) : (
            <>{photograph.soldCount}</>
          )}
        </div>
      )}
      {showValidationError && hasValidationError && (
        <ValidationError validateMessages={photograph.validateMessages} />
      )}
      {photograph.jizenngflag === JIZEN_HIDDEN && (
        <div className="p-photographs_jizenngflag">責任者削除：D</div>
      )}
      <div className="p-photographs_photoControls">
        {photograph.isHide && (
          <a href="." onClick={show} className="p-photographs_photoControl">
            表示
          </a>
        )}
        {showIndividualHide &&
          !photograph.isMainEventThumbnail &&
          !photograph.isHide && (
            <a href="." onClick={hide} className="p-photographs_photoControl">
              非表示
            </a>
          )}
        {rotated ? (
          <span
            title="回転を連続で実施することはできません。画面をリロードしてください。"
            className="p-photographs_photoControl p-photographs_photoControl__disabled"
          >
            左回
          </span>
        ) : (
          <a
            href="."
            onClick={rotateLeft}
            title="左90度回転する"
            className="p-photographs_photoControl"
          >
            左回
          </a>
        )}
        {rotated ? (
          <span
            title="回転を連続で実施することはできません。画面をリロードしてください。"
            className="p-photographs_photoControl p-photographs_photoControl__disabled"
          >
            右回
          </span>
        ) : (
          <a
            href="."
            onClick={rotateRight}
            title="右90度回転する"
            className="p-photographs_photoControl"
          >
            右回
          </a>
        )}
        {canChangeCategory && (
          <a
            href="."
            title="他のカテゴリに移動する"
            onClick={changeCategory}
            className="p-photographs_photoControl"
          >
            移動
          </a>
        )}
        {!photograph.isCategoryHide &&
          !photograph.isHide &&
          !photograph.isMainEventThumbnail && (
            <a
              href="."
              title="イベント代表写真にする"
              onClick={setEventThumbnail}
            >
              代表
            </a>
          )}
        <br />
        <input type="file" onChange={handleReplace} />
        <div className="p-photographs_labelRow">
          {photograph.isHide && (
            <span className="c-statusLabel p-photographs_label__photoIsHidden">
              非表示
            </span>
          )}
          {photograph.isMainEventThumbnail && (
            <span className="c-statusLabel p-photographs_label__photoIsEventThumbnail">
              代表
            </span>
          )}
        </div>
      </div>
      {isDirectPhotoPlan && (
        <div className="u-align_center">
          {photograph.isQualityAlertTarget &&
            photograph.qualityAlert &&
            photograph.qualityAlert.isOverallDark && (
              <strong className="t-textColor_red">(暗)</strong>
            )}
          {photograph.isQualityAlertTarget &&
            photograph.qualityAlert &&
            photograph.qualityAlert.hasNoChildren && (
              <strong className="t-textColor_red">(顔無)</strong>
            )}
          {photograph.isQualityAlertTarget &&
            photograph.qualityAlert &&
            photograph.qualityAlert.isSubjectChildrenTooSmall && (
              <strong className="t-textColor_red">(小)</strong>
            )}
        </div>
      )}
    </div>
  );
};

export default Photograph;
