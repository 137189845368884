import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.locale('ja');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

const HYPHEN = '−';

/**
 * インセンティブ率の表示
 * null："−"、整数：整数、小数：小数点下2桁
 */
const incentiveRateToString = (rate: number | null, digit = 2): string => {
  if (rate === null) {
    return HYPHEN;
  }
  return String(Number.isInteger(rate) ? rate : rate.toFixed(digit));
};

/**
 * 日付文字列のフォーマット（YYYY/MM/DD）
 */
const dateFormat = (date?: dayjs.ConfigType, whenEmpty = HYPHEN): string =>
  date ? dayjs(date).format('YYYY/MM/DD') : whenEmpty;

/**
 * ISO8601形式日付文字列のフォーマット（YYYY/MM/DD）
 */
const dateFormatFromISO8601 = (
  date?: dayjs.ConfigType,
  whenEmpty = HYPHEN,
  format?: string
): string =>
  date
    ? dayjs(date)
        .tz()
        .format(format ? format : 'YYYY/MM/DD')
    : whenEmpty;

/**
 * 日付文字列のフォーマット（YYYY/MM/DD(ddd)）
 * ISO8601形式 JST(yyyy-MM-ddTHH:mm:ss+09:00)の場合
 * 端末環境のTZによっては正しくフォーマットされない可能性があるため、
 * dateFormatWithDayOfWeekFromISO8601を使用するようにしてください
 */
const dateFormatWithDayOfWeek = (
  date?: dayjs.ConfigType,
  whenEmpty = HYPHEN
): string => (date ? dayjs(date).format('YYYY/MM/DD(ddd)') : whenEmpty);

/**
 * ISO8601形式日付文字列のフォーマット（YYYY/MM/DD(ddd)）
 */
const dateFormatWithDayOfWeekFromISO8601 = (
  date?: dayjs.ConfigType,
  whenEmpty = HYPHEN
): string => (date ? dayjs(date).tz().format('YYYY/MM/DD(ddd)') : whenEmpty);

/**
 * 日付文字列のフォーマット・ハイフン区切り（YYYY-MM-DD）
 */
const dateFormatHyphen = (
  date?: dayjs.ConfigType,
  whenEmpty: string | null = HYPHEN
): string | null => (date ? dayjs(date).format('YYYY-MM-DD') : whenEmpty);

/**
 * ISO8601形式日付文字列のフォーマット・ハイフン区切り（YYYY-MM-DD）
 */
const dateFormatHyphenFromISO8601 = (
  date?: dayjs.ConfigType,
  whenEmpty: string | null = HYPHEN
): string | null => (date ? dayjs(date).tz().format('YYYY-MM-DD') : whenEmpty);

/**
 * 日時文字列のフォーマット（YYYY/MM/DD HH:ii:ss）
 */
const dateTimeFormat = (date?: dayjs.ConfigType, whenEmpty = HYPHEN): string =>
  date ? dayjs(date).format('YYYY/MM/DD HH:mm:ss') : whenEmpty;

const dateTimeFormatWithoutSeconds = (
  date?: dayjs.ConfigType,
  whenEmpty = HYPHEN
): string => (date ? dayjs(date).format('YYYY/MM/DD HH:mm') : whenEmpty);

/**
 * 数値フォーマット (1,234,567.89)
 */
const numberFormat = (number: number, maximumFractionDigits = 0): string =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits,
  }).format(number);

export {
  HYPHEN,
  incentiveRateToString,
  dateFormat,
  dateFormatFromISO8601,
  dateFormatWithDayOfWeek,
  dateFormatWithDayOfWeekFromISO8601,
  dateFormatHyphen,
  dateFormatHyphenFromISO8601,
  dateTimeFormat,
  dateTimeFormatWithoutSeconds,
  numberFormat,
};
