import React from 'react';
import { useFormContext } from 'react-hook-form';
import { numberFormat } from '../../../../ts/formatTools';
import { calcTotalPayment, reCalcSubTotal } from './calc';
import { THtmlFormInputs, TSubTotal } from './types';
import './style.scss';

const InTaxSubTotal: React.FC<{
  subTotal: TSubTotal;
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    subTotal: {
      totalPaymentSales,
      totalPaymentCosts,
      totalPaymentOthers,
      totalPayment,
      totalSalesWithoutTax,
    },
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) => (
    <>
      <li className="c-section_subTitle u-mgb_s u-fz_default">
        <strong>税込</strong>
      </li>
      {(isShowPaymentCosts || isShowPaymentSales) && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              売上額合計
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalSalesWithoutTax)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
          <hr className="u-line_plane u-mgt_s u-mgb_s" />
        </li>
      )}
      {isShowPaymentCosts && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              支払額合計<small>(原価)</small>
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentCosts)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      {isShowPaymentSales && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              支払額合計<small>(販売手数料)</small>
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentSales)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      {isShowPaymentOthers && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              その他請求小計
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentOthers)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      <li>
        <dl className="l-flex_between_center">
          <dt className="p-partnerIncentivePayment-totalCaption">
            <strong>支払額合計</strong>
          </dt>
          <dd className="p-partnerIncentivePayment-totalItem">
            <strong>
              {numberFormat(totalPayment)}
              <small className="u-mgl_xs">円</small>
            </strong>
          </dd>
          <dd className="p-partnerIncentivePayment-totalItem"></dd>
        </dl>
      </li>
    </>
  )
);

const OutTaxSubTotal: React.FC<{
  subTotal: TSubTotal;
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    subTotal: {
      taxRate,
      totalPaymentSales,
      totalPaymentCosts,
      totalPaymentOthers,
      tax,
      totalPayment,
      totalSalesWithoutTax,
    },
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) => (
    <>
      <li className="c-section_subTitle u-mgb_s u-fz_default">
        <dl className="l-flex_between_center">
          <dt className="p-partnerIncentivePayment-totalCaption">
            <strong>{`税率（${taxRate}%）`}</strong>
          </dt>
          <dd className="p-partnerIncentivePayment-totalItem">
            <strong>税抜額</strong>
          </dd>
          <dd className="p-partnerIncentivePayment-totalItem">
            <strong>{`消費税額`}</strong>
          </dd>
        </dl>
      </li>
      {(isShowPaymentCosts || isShowPaymentSales) && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              売上額合計
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalSalesWithoutTax)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
          <hr className="u-line_plane u-mgt_s u-mgb_s" />
        </li>
      )}

      <li>
        <dl className="l-flex_between_center">
          <dt className="p-partnerIncentivePayment-totalCaption">販売手数料</dt>
          <dd className="p-partnerIncentivePayment-totalItem">
            {numberFormat(
              totalSalesWithoutTax - totalPaymentCosts - totalPaymentSales
            )}
            <small className="u-mgl_xs">円</small>
          </dd>
          <dd className="p-partnerIncentivePayment-totalItem">
            {numberFormat(
              (totalSalesWithoutTax - totalPaymentCosts - totalPaymentSales) /
                (taxRate ? taxRate : 1.1)
            )}
            <small className="u-mgl_xs">円</small>
          </dd>
        </dl>
        <hr className="u-line_plane u-mgt_s u-mgb_s" />
      </li>

      {isShowPaymentCosts && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              支払額（Ａ）
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentCosts)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      {isShowPaymentSales && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              支払額（Ｂ）
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentSales)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      {isShowPaymentOthers && (
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              その他精算小計
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem">
              {numberFormat(totalPaymentOthers)}
              <small className="u-mgl_xs">円</small>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      )}
      <li>
        <dl className="l-flex_between_center">
          <dt className="p-partnerIncentivePayment-totalCaption">
            <strong>支払額合計</strong>
          </dt>
          <dd className="p-partnerIncentivePayment-totalItem">
            <strong>
              {numberFormat(totalPayment - tax)}
              <small className="u-mgl_xs">円</small>
            </strong>
          </dd>
          <dd className="p-partnerIncentivePayment-totalItem">
            <strong>
              {numberFormat(tax)}
              <small className="u-mgl_xs">円</small>
            </strong>
          </dd>
        </dl>
      </li>
    </>
  )
);

const Contents: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    subTotals,
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) => (
    <div>
      <ul className="t-bgBox_org u-mgb_s">
        {subTotals.map((subTotal) =>
          subTotal.tax > 0 ? (
            <OutTaxSubTotal
              key={subTotal.taxRate}
              subTotal={subTotal}
              isShowPaymentCosts={isShowPaymentCosts}
              isShowPaymentSales={isShowPaymentSales}
              isShowPaymentOthers={isShowPaymentOthers}
            />
          ) : (
            <InTaxSubTotal
              key={subTotal.taxRate}
              subTotal={subTotal}
              isShowPaymentCosts={isShowPaymentCosts}
              isShowPaymentSales={isShowPaymentSales}
              isShowPaymentOthers={isShowPaymentOthers}
            />
          )
        )}
      </ul>
      <ul className="t-bgBox_org u-mgb_s">
        <li>
          <dl className="l-flex_between_center">
            <dt className="p-partnerIncentivePayment-totalCaption">
              <strong>総支払額(税込)</strong>
            </dt>
            <dd className="p-partnerIncentivePayment-totalItem c-incentivePayment_totalItem_box">
              <strong>
                {numberFormat(calcTotalPayment(subTotals))}
                <small className="u-mgl_xs">円</small>
              </strong>
            </dd>
            <dd className="p-partnerIncentivePayment-totalItem"></dd>
          </dl>
        </li>
      </ul>
    </div>
  )
);

const Show: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    subTotals,
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) => (
    <Contents
      subTotals={subTotals}
      isShowPaymentCosts={isShowPaymentCosts}
      isShowPaymentSales={isShowPaymentSales}
      isShowPaymentOthers={isShowPaymentOthers}
    />
  )
);

const Edit: React.FC<{
  subTotals: TSubTotal[];
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(({ subTotals: orgSubTotals, ...conditions }) => {
  const { watch } = useFormContext<THtmlFormInputs>();
  const inputIncentivePaymentEvents = watch('incentivePaymentEvents');
  const inputIncentivePaymentOthers = watch('incentivePaymentOthers');
  const subTotals = orgSubTotals.map((orgSubTotal) =>
    reCalcSubTotal(
      orgSubTotal,
      inputIncentivePaymentEvents,
      inputIncentivePaymentOthers
    )
  );

  return <Contents subTotals={subTotals} {...conditions} />;
});

const TotalTable: React.FC<{
  canEdit: boolean;
  subTotals: TSubTotal[];
  totalPayment: number;
  isShowPaymentCosts: boolean;
  isShowPaymentSales: boolean;
  isShowPaymentOthers: boolean;
}> = React.memo(
  ({
    canEdit,
    subTotals,
    isShowPaymentCosts,
    isShowPaymentSales,
    isShowPaymentOthers,
  }) =>
    canEdit ? (
      <Edit
        subTotals={subTotals}
        isShowPaymentCosts={isShowPaymentCosts}
        isShowPaymentSales={isShowPaymentSales}
        isShowPaymentOthers={isShowPaymentOthers}
      />
    ) : (
      <Show
        subTotals={subTotals}
        isShowPaymentCosts={isShowPaymentCosts}
        isShowPaymentSales={isShowPaymentSales}
        isShowPaymentOthers={isShowPaymentOthers}
      />
    )
);

export default TotalTable;
