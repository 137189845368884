import React, { ReactEventHandler } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiErrors } from '@/components/shared/Form/Errors';
import {
  TextInput,
  NumberInput,
  DateInput,
  Checkboxes,
  MultiSelect,
  GroupedMultiSelect,
} from '@/components/shared/Form/Inputs';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { TFormInputs, TFormItems } from './types';

type TSearchFormProps = {
  validator: TValidatorResponse;
  formItems: TFormItems;
  queryParams: Record<string, unknown>;
  onSubmit: SubmitHandler<TFormInputs>;
};
const SearchForm: React.FC<TSearchFormProps> = React.memo(
  ({ validator, formItems, queryParams, onSubmit }) => {
    // MEMO: validation error があるとformがsubmitされない
    const methods = useForm<TFormInputs>({ defaultValues: queryParams });
    const { handleSubmit, setValue } = methods;

    const clearCreateddayRange: ReactEventHandler = (e) => {
      e.preventDefault();
      setValue('createddayStart', '');
      setValue('createddayEnd', '');
    };
    const {
      plans,
      prefectures,
      salesDepartmentOwners,
      statuses,
      isEnabledPhotoEdits,
    } = formItems;

    return (
      <div className="c-searchForm">
        <FormProvider {...methods}>
          <ApiErrors {...validator} />
          <div className="c-frame">
            <form
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="c-searchForm_partnerInfo">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>パートナー</label>
                    </li>
                    <li className="c-dataValue">
                      <ul className="l-flex">
                        <li className="c-searchForm_partnerInfo_id">
                          <NumberInput
                            name="id"
                            placeholder="パートナーID"
                            validator={validator}
                          />
                        </li>
                        <li className="c-searchForm_partnerInfo_name">
                          <TextInput
                            name="name"
                            placeholder="パートナー名／表示名"
                            validator={validator}
                          />
                        </li>
                        <li className="c-searchForm_partnerInfo_kana">
                          <TextInput
                            name="namekana"
                            placeholder="パートナー名(かな)"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="c-searchForm_tel">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>電話番号</label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="telephonenumber"
                        placeholder="03-1234-5678"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>
                        メール
                        <br className="sp_off" />
                        アドレス
                      </label>
                    </li>
                    <li className="c-dataValue">
                      <TextInput
                        name="mailaddress"
                        placeholder="管理者／カスタマーセンター"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>登録日</label>
                      <p>
                        <a
                          className="c-textlink_clear"
                          onClick={clearCreateddayRange}
                          href="/"
                        >
                          期間をクリア
                        </a>
                      </p>
                    </li>
                    <li className="c-dataValue">
                      <ul className="c-input_dateBlock">
                        <li>
                          <DateInput
                            name="createddayStart"
                            placeholder="登録日"
                            validator={validator}
                          />
                        </li>
                        <li>
                          <DateInput
                            name="createddayEnd"
                            placeholder="登録日"
                            validator={validator}
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>プラン</label>
                    </li>
                    <li className="c-dataValue">
                      <div className="t-bgBox_gray">
                        <Checkboxes
                          name="plans"
                          choices={plans}
                          isInline={true}
                          validator={validator}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="l-col_5">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>編集代行</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="isEnabledPhotoEdits"
                        choices={isEnabledPhotoEdits}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
                <li className="l-col_7">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>稼動状況</label>
                    </li>
                    <li className="c-dataValue">
                      <Checkboxes
                        name="statuses"
                        choices={statuses}
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_24">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>都道府県</label>
                    </li>
                    <li className="c-dataValue">
                      <GroupedMultiSelect
                        name="prefectures"
                        choices={prefectures}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-col_wrap__isSpMode l-flex_between l-flex__isSpInput">
                <li className="l-col_24">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      <label>担当営業</label>
                    </li>
                    <li className="c-dataValue">
                      <MultiSelect
                        name="salesDepartmentOwnernos"
                        choices={salesDepartmentOwners}
                        placeholder="クリックで候補表示（複数可）"
                        validator={validator}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="u-align_center u-mgt_s u-mgb_xs c-stickyBtnBox_search_sp">
                <input
                  className="c-btn_large c-btn_Search c-input_submit"
                  type="submit"
                  value="この内容で検索"
                />
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    );
  }
);

export default SearchForm;
